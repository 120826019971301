import { mergeStyles, PrimaryButton } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { DealRoomComment } from '@meetingflow/common/Api/data-contracts';
// import { RemoveFromTrashIcon } from '@fluentui/react-icons-mdl2';

type ConversationsElementProps = {
  children?: React.ReactNode;
  isComment?: boolean;
  conversation: DealRoomComment;
  convClick?: Function;
  convDelete?: Function;
  timeAgo: string;
  timeAgoNotification?: string;
  notifications?: number | null;
};

export const ConversationsElement = ({
  children,
  isComment = false,
  conversation,
  convClick,
  convDelete,
  timeAgo,
  timeAgoNotification,
  notifications,
}: ConversationsElementProps) => {
  const convElementButtonStyles = mergeStyles({
    padding: '0.75rem 1rem',
    borderRadius: '0.25rem',
    border: '1px solid ' + DEALROOMS_COLORS.buttonBorder,
    backgroundColor: DEALROOMS_COLORS.white,
    color: DEALROOMS_COLORS.darkerGray,
    minHeight: 'auto',
    ':hover': {
      backgroundColor: DEALROOMS_COLORS.neutralLighter,
      border: '1px solid ' + DEALROOMS_COLORS.buttonBorder,
      color: DEALROOMS_COLORS.darkerGray,
    },
    ':focus': {
      outline: '1px solid ' + DEALROOMS_COLORS.neutralSecondary,
    },
    ':active': {
      backgroundColor: DEALROOMS_COLORS.neutralLight,
      color: DEALROOMS_COLORS.darkerGray,
      border: '1px solid transparent',
    },
    '.deleteConversation': {
      padding: '0 0.3rem 0 0.5rem',
      width: '1rem',
      height: '1rem',
    },
    '>span': {
      width: '100%',
    },
  });

  const convContentStyle = mergeStyles({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.75rem',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    ...(isComment
      ? {
          padding: '0 0 1rem 0',
        }
      : {}),
    img: {
      width: '2.5rem',
      borderRadius: '50%',
    },
    '>div:nth-child(2)': {
      flexGrow: '1',
      '>div:nth-child(1)': {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'row',
      },
      '>div:nth-child(2)': {
        textAlign: 'left',
      },
    },
    '>div:nth-child(3)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
      '>div:nth-child(1)': {
        // Time ago
        minWidth: '5rem',
        color: DEALROOMS_COLORS.themeSecondary,
      },
      '>div:nth-child(2)': {
        // Time ago
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.2rem 0',
      },
    },
  });

  const elementContent = () => (
    <div className={convContentStyle}>
      <img
        src={
          conversation.user?.avatarFileUrl ||
          conversation.user?.avatarUrl ||
          undefined
        }
        alt="Avatar"
      />
      <div>
        <div>
          <div style={{ color: DEALROOMS_COLORS.themeSecondary }}>
            {conversation.user?.name}
          </div>
          {isComment && (
            <div
              style={{
                minWidth: '5rem',
                padding: '0 0 0 1rem',
                color: DEALROOMS_COLORS.themeSecondary,
              }}
            >
              {timeAgo}
            </div>
          )}
        </div>
        <div>{children ? children : conversation.commentText}</div>
      </div>
      {!isComment && (
        <div>
          <div
            style={{
              ...(timeAgoNotification
                ? { color: DEALROOMS_COLORS.notificationBubble }
                : {}),
            }}
          >
            {timeAgo}
          </div>
          <div>
            {!!notifications && (
              <div
                style={{
                  backgroundColor: DEALROOMS_COLORS.notificationBubble,
                  color: DEALROOMS_COLORS.white,
                  borderRadius: '0.7rem',
                  padding: '0.08rem 0.3rem',
                  lineHeight: '1rem',
                  fontWeight: '600',
                }}
              >
                {notifications}
              </div>
            )}
            {/*<RemoveFromTrashIcon
              className="deleteConversation"
              // iconProps={{ iconName: 'RemoveFromTrash' }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (convDelete) convDelete(conversation.id);
              }}
            />*/}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      {isComment ? (
        elementContent()
      ) : (
        <PrimaryButton
          className={convElementButtonStyles}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (convClick) convClick(conversation.id);
          }}
        >
          {elementContent()}
        </PrimaryButton>
      )}
    </>
  );
};

export default ConversationsElement;
