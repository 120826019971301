import { Stack, Text, mergeStyles, FontIcon, IStyle } from '@fluentui/react';
import { DEFAULT_STACK_TOKENS } from '../../../../Helpers/Layout';
import { OrganizationDomains } from '../OrganizationDomains';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import {
  settingsIndentedControlClass,
  settingsRootStackStyles,
  settingsSectionDescriptionClass,
  settingsSectionDescriptionClassDark,
  settingsSectionDescriptionIconClass,
  settingsSubHeadClass,
} from '../SettingsPageLayout';

export const DomainsTabContent = () => {
  const { isDark } = useLightOrDarkMode();

  const { slug: orgSlug } = useOrganization();

  return (
    <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
      <h2 className={settingsSubHeadClass}>Workspace Domains</h2>
      <div className={settingsIndentedControlClass}>
        <Text
          className={
            isDark
              ? mergeStyles(settingsSectionDescriptionClassDark as IStyle)
              : mergeStyles(settingsSectionDescriptionClass as IStyle)
          }
        >
          <FontIcon
            iconName="Info"
            className={settingsSectionDescriptionIconClass}
          />
          Meetingflow uses your organization's domain names to determine which
          events and contacts are internal vs. external. Add any domain names
          that are internal to your organization.
        </Text>
        <OrganizationDomains organizationSlug={orgSlug!} />
      </div>
    </Stack>
  );
};
