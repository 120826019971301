// stub out a react function component in the same style as the rest of this app
import { useAuth0 } from '@auth0/auth0-react';
import {
  ContextualMenuItemType,
  DefaultButton,
  DirectionalHint,
  FontIcon,
  FontSizes,
  FontWeights,
  IButtonStyles,
  IContextualMenuItem,
  IContextualMenuProps,
  ISearchBox,
  mergeStyles,
  NeutralColors,
  SearchBox,
  Spinner,
  TooltipHost,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { CalendarEvent } from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { EMPTY_ARRAY } from '../../Constants';
import { isAxiosErrorResponse } from '../../Helpers/AxiosHelpers';
import useBreakpoints from '../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import {
  MeetingPlanQuery,
  OrganizationMeetingPlansQuery,
  OrganizationMeetingsHappeningSoon,
  OrganizationUpcomingMeetings,
} from '../../QueryNames';
import {
  EventsApiClient,
  MeetingflowsApiClient,
  TextClient,
} from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { AsyncLink } from '../HOC/AsyncLink';
import EventCard from '../Organization/Library/EventCard';
import { useOrganization } from '../../Hooks/useOrganization';
import { useUserProfile } from '../../Hooks/useProfile';
import {
  CreateMeetingflowModalOptions,
  CreateMeetingflowResult,
} from '../../Hooks/Modals/useNewMeetingflowDialog';
import { DeferredPromise } from '../../Helpers/DeferredPromise';

interface NewMeetingflowButtonProps {
  organizationSlug: string;
  createMeetingflowDeferred?: (
    ...context: [] | [CreateMeetingflowModalOptions | undefined]
  ) => DeferredPromise<
    CreateMeetingflowResult | undefined,
    CreateMeetingflowModalOptions | undefined
  >;
  showDialog?: boolean;
  event?: CalendarEvent;
}

const EVENTS_RECENT_QUERY_LIMIT = 5;
const EVENTS_SEARCH_QUERY_LIMIT = 10;

export const NewMeetingflowButton = ({
  organizationSlug,
  createMeetingflowDeferred,
  showDialog = true,
  event,
}: NewMeetingflowButtonProps) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const searchBoxRef = React.useRef<ISearchBox>(null);
  const contextualMenuRef = React.useRef<HTMLDivElement>(null);
  const contextualMenuCalloutRef = React.useRef<HTMLDivElement>(null);
  const buttonId = useId('newMeetingflowButton');
  const { isDark } = useLightOrDarkMode();
  const navigate = useNavigate();
  const client = useQueryClient();
  const appInsights = useAppInsightsContext();
  const breakpoints = useBreakpoints();
  const {
    canCreatePlans,
    organization,
    refetchBackground: refetchOrgRole,
  } = useOrganization(organizationSlug);
  const { userId } = useUserProfile();

  const now = DateTime.now();

  const {
    data: eventsData,
    isLoading: eventsLoading,
    refetch: refetchEvents,
    isError: eventsErrored,
  } = useQuery(
    OrganizationUpcomingMeetings(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return EventsApiClient.listEvents(
        {
          organizationSlug: organizationSlug!,
          q: searchTerm || undefined,
          minDate: now.startOf('day').toISO()!,
          maxDate: searchTerm
            ? undefined
            : now.plus({ days: 7 }).endOf('day').toISO()!,
          limit: searchTerm
            ? EVENTS_SEARCH_QUERY_LIMIT
            : EVENTS_RECENT_QUERY_LIMIT,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 15 minutes
      refetchInterval: 900000,
      staleTime: 900000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      retry: 1,
      enabled: !!organizationSlug && !event,
      queryHash: `${OrganizationUpcomingMeetings(
        organizationSlug!,
      )}_NewMeetingflowButton_${searchTerm}_${now
        .startOf('week')
        .startOf('day')
        .toISO()}`,
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(debounce(refetchEvents, 500), [
    refetchEvents,
  ]);

  useEffect(() => {
    debouncedRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, organizationSlug]);

  const events = useMemo(
    () => eventsData?.data || EMPTY_ARRAY,
    [eventsData?.data],
  );

  const contextualMenuOptionClass = mergeStyles({
    display: 'block',
    width: '100%',
    padding: '2px 0',
  });

  const addHocMeetingflowButtonClass = mergeStyles({
    padding: '.25rem .5rem 0 .5rem',
    height: 'calc(2.5rem + 6px)',
    lineHeight: '2.5rem',
    fontWeight: FontWeights.semibold,
    width: '100%',
    backgroundColor: isDark
      ? NeutralColors.gray180
      : MEETINGFLOW_COLORS.purpleGrey,
    textAlign: 'center',
    transition: '.3s all ease-in-out',
    border: `3px solid ${
      isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleGrey
    } !important`,
    textDecoration: 'none !important',
    span: {
      transition: '.3s all ease-in-out',
    },
    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.purpleTertiary,
      border: `3px solid ${MEETINGFLOW_COLORS.purpleTertiary} !important`,
      color: `${MEETINGFLOW_COLORS.white} !important`,
      borderRadius: '.25rem',
      span: {
        color: `${MEETINGFLOW_COLORS.white} !important`,
      },
    },
    ':focus': {
      border: `3px solid ${MEETINGFLOW_COLORS.orange} !important`,
      width: 'calc(100%)',
      boxShadow: 'none !important',
      outline: 'none !important',
      borderRadius: '.25rem',
      ':after': {
        outline: 'none !important',
        boxShadow: 'none !important',
        border: 'none !important',
      },
    },
  });

  const renderSearchBoxMenuItem = useCallback(
    (
      option?: IContextualMenuItem,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dismissMenu?: (ev?: any, dismissAll?: boolean) => void,
    ) => {
      if (!option) return null;

      return (
        <SearchBox
          className="search-calendar"
          componentRef={searchBoxRef}
          autoFocus
          placeholder="Search by title or attendee..."
          onClear={() => {
            setSearchTerm(undefined);
          }}
          onKeyUp={(e) => {
            e.preventDefault();
            if (e.key === 'ArrowDown') {
              // Navigate to the first event item
              contextualMenuRef?.current
                ?.querySelector('.event-option button')
                // @ts-ignore
                ?.focus();
            }
          }}
          onChange={(e, nv) => {
            setSearchTerm(nv || '');
          }}
          styles={{
            root: {
              borderColor: `${MEETINGFLOW_COLORS.orange} !important`,
              ':after': {
                borderColor: MEETINGFLOW_COLORS.orange,
                outlineColor: MEETINGFLOW_COLORS.orange,
              },
            },
            field: {
              backgroundColor: isDark ? NeutralColors.gray190 : undefined,
            },
            iconContainer: {
              backgroundColor: isDark ? NeutralColors.gray190 : undefined,
            },
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contextualMenuOptionClass, organizationSlug],
  );

  const renderEventMenuItem = useCallback(
    (
      option?: IContextualMenuItem,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dismissMenu?: (ev?: any, dismissAll?: boolean) => void,
    ) => {
      if (!option) return null;
      const item = option?.data;
      const itemKey = option?.key?.toString();

      if (!itemKey) {
        return null;
      }

      return (
        <div
          className={classNames(contextualMenuOptionClass, 'event-option')}
          key={itemKey}
          style={{ width: '100%' }}
          onClick={(e) => {
            e.stopPropagation();
            dismissMenu?.();
            createOrViewMeetingflow(item);
          }}
        >
          <EventCard
            organizationSlug={organizationSlug!}
            event={item}
            showCompanies
            onClick={() => {}} // Styling hack
            disableCreateViewMeetingflowOnClick
          />
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contextualMenuOptionClass, organizationSlug],
  );

  const renderLoadingMenuItem = useCallback(
    (
      option?: IContextualMenuItem,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dismissMenu?: (ev?: any, dismissAll?: boolean) => void,
    ) => {
      return <Spinner styles={{ root: { margin: '1rem 0' } }} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contextualMenuOptionClass, organizationSlug],
  );

  const renderAdHocMeetingflowMenuItem = useCallback(
    (
      option?: IContextualMenuItem,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dismissMenu?: (ev?: any, dismissAll?: boolean) => void,
    ) => {
      if (!option) return null;

      const item = option?.data;

      return (
        <AsyncLink
          className={addHocMeetingflowButtonClass}
          data-is-focusable
          onClick={async () => {
            dismissMenu?.();
            await createOrViewMeetingflow(item);
          }}
        >
          <FontIcon
            iconName="NewAnalyticsQuery"
            style={{
              fontSize: '1.5rem',
              float: 'left',
              position: 'relative',
              top: '-.25rem',
            }}
          />
          <span
            style={{
              display: 'block',
              lineHeight: '1.25rem',
              fontSize: FontSizes.medium,
              color: isDark
                ? MEETINGFLOW_COLORS.white
                : MEETINGFLOW_COLORS.black,
            }}
          >
            Create Ad-hoc Meetingflow
          </span>
          <span
            style={{
              position: 'relative',
              top: '-.25rem',
              display: 'block',
              lineHeight: '1.25rem',
              fontSize: FontSizes.mini,
              color: NeutralColors.gray110,
              fontWeight: FontWeights.regular,
            }}
          >
            Not associated with a calendar event
          </span>
        </AsyncLink>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contextualMenuOptionClass, organizationSlug],
  );

  const menuProps: IContextualMenuProps = {
    ref: contextualMenuRef,
    shouldFocusOnMount: false,
    styles: {
      root: {
        backgroundColor: isDark
          ? NeutralColors.gray200
          : MEETINGFLOW_COLORS.purpleUltraSuperLight,
      },
      container: {
        overflow: 'hidden',
      },
    },
    calloutProps: {
      calloutWidth: 250,
      ref: contextualMenuCalloutRef,
      dismissOnTargetClick: true,
      target: contextualMenuRef.current,
      styles: {
        root: {
          padding: '.25rem',
          backgroundColor: isDark
            ? NeutralColors.gray200
            : MEETINGFLOW_COLORS.purpleUltraSuperLight,
        },
        calloutMain: {
          overflow: 'auto',
          maxHeight: '70vh !important',
        },
      },
    },
    items: [
      // Hard-coded item for the search box
      {
        key: 'search-calendar',
        itemType: ContextualMenuItemType.Normal,
        onRender: (
          item: IContextualMenuItem,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dismissMenu: (_ev?: any, dismissAll?: boolean) => void,
        ) => renderSearchBoxMenuItem(item, dismissMenu),
      },
      // Dynamically generated items for the events
      ...events.map((event: CalendarEvent) => ({
        key: `event-${event.iCalUid}`,
        text: event.title,
        data: event,
        onRender: (
          item: IContextualMenuItem,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dismissMenu: (_ev?: any, dismissAll?: boolean) => void,
        ) => renderEventMenuItem(item, dismissMenu),
      })),
      // Hard-coded item for the loading spinner
      {
        key: 'loading',
        itemType: ContextualMenuItemType.Normal,
        onRender: (
          item: IContextualMenuItem,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dismissMenu: (_ev?: any, dismissAll?: boolean) => void,
        ) => renderLoadingMenuItem(item, dismissMenu),
      },
      // Hard-coded item for the ad-hoc meetingflow button
      {
        key: 'ad-hoc-meetingflow',
        itemType: ContextualMenuItemType.Normal,
        onRender: (
          item: IContextualMenuItem,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dismissMenu: (_ev?: any, dismissAll?: boolean) => void,
        ) => renderAdHocMeetingflowMenuItem(item, dismissMenu),
      },
    ] as IContextualMenuItem[],
    directionalHintFixed: true,
  };

  // Remove the loading spinner menu item if we're not loading
  if (!eventsLoading) {
    menuProps.items = menuProps.items.filter(
      (option) => option.key !== 'loading',
    );
  }

  const createOrViewMeetingflow = async (event?: CalendarEvent | undefined) => {
    if (!!event?.meetingplanId) {
      navigate(`/organization/${organizationSlug}/plan/${event.meetingplanId}`);
    } else {
      const token = await getAccessTokenSilently();

      let reqBody = {};
      if (event) {
        reqBody = {
          source: event.source,
          eventId: event.externalId,
          eventTime: event.startTime,
        };
      }

      await toast.promise(
        MeetingflowsApiClient.postMeetingflow(organizationSlug, reqBody, {
          headers: { Authorization: `Bearer ${token}` },
          validateStatus: (code) => code === 201 || code === 302,
        }),
        {
          loading: 'Creating Meetingflow',
          success: (result) => {
            Promise.all([
              client.invalidateQueries(
                OrganizationMeetingPlansQuery(organizationSlug),
              ),
              client.invalidateQueries(
                OrganizationMeetingsHappeningSoon(organizationSlug),
              ),
              client.invalidateQueries(
                OrganizationUpcomingMeetings(organizationSlug),
              ),
            ]);

            client.setQueryData(
              MeetingPlanQuery(organizationSlug, result.data.id),
              result,
            );

            navigate(
              `/organization/${organizationSlug}/plan/${result.data.id}`,
            );

            if (result.status === 201) {
              appInsights.trackEvent({
                name: `CREATE_PLAN${event ? '_FOR_EVENT' : '_ADHOC'}`,
                properties: {
                  organizationSlug,
                  source: event ? event?.source : '',
                  eventId: event ? event?.externalId : '',
                  createdFromGlobalButton: true,
                },
              });

              return `A Meetingflow ${
                event ? `for ${event?.title}` : ''
              } has been created!`;
            } else if (result.status === 302) {
              return `A Meetingflow ${
                event ? `for ${event?.title}` : ''
              } has been created!`;
            }

            return null;
          },
          error: (err) => {
            if (err && isAxiosErrorResponse(err, 403)) {
              return `You don't have permission to create Meetingflows in this workspace`;
            }

            appInsights.trackException({
              exception: err instanceof Error ? err : new Error(err),
              properties: {
                organizationSlug,
                eventSource: event?.source,
                eventId: event?.externalId,
                status: isAxiosErrorResponse(err)
                  ? err.response?.status
                  : undefined,
                statusText: isAxiosErrorResponse(err)
                  ? err.response?.statusText
                  : undefined,
              },
            });

            appInsights.trackEvent({
              name: 'CREATE_PLAN_FAILED',
              properties: {
                organizationSlug,
                source: event?.source,
                eventId: event?.externalId,
                status: isAxiosErrorResponse(err)
                  ? err.response?.status
                  : undefined,
                statusText: isAxiosErrorResponse(err)
                  ? err.response?.statusText
                  : undefined,
              },
            });

            return 'Something went wrong creating the Meetingflow, please try again';
          },
        },
      );
    }

    setSearchTerm(undefined);
  };

  const requestCreatorMembership = async () => {
    const token = await getAccessTokenSilently();

    const result = await toast.promise(
      TextClient.put(
        `/organization/${organizationSlug}/member/${userId}/role`,
        'CREATOR',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          validateStatus: (status) => [200, 201, 409].includes(status),
        },
      ),
      {
        loading: 'Requesting an upgrade to the creator role',
        success: (r) => {
          switch (r.status) {
            case 200: {
              return `Your account has been upgraded to the creator role`;
            }
            case 201: {
              return `A request for your ${
                organization?.name || organizationSlug
              } account to be upgraded to the Creator role has been sent to your workspace admin.`;
            }
            // Conflict, already exists
            case 409: {
              return `There is already a pending request to upgrade your role to Creator.`;
            }
            default: {
              return ``;
            }
          }
        },
        error: (err) => {
          appInsights.trackEvent({
            name: 'REQUEST_CREATOR_ROLE_FAILED',
            properties: {
              organizationId: organization?.id,
              status: isAxiosErrorResponse(err)
                ? err.response?.status
                : undefined,
              statusText: isAxiosErrorResponse(err)
                ? err.response?.statusText
                : undefined,
            },
          });

          return `Something went wrong requesting a creator role, please try again`;
        },
      },
    );

    switch (result.status) {
      case 200: {
        await refetchOrgRole();
        appInsights.trackEvent({
          name: 'REQUEST_CREATOR_ROLE',
          properties: {
            organizationSlug,
            email: user?.email,
            role: 'CREATOR',
          },
        });
        break;
      }
      case 201: {
        appInsights.trackEvent({
          name: 'REQUEST_CREATOR_ROLE',
          properties: {
            organizationSlug,
            email: user?.email,
            role: 'CREATOR',
          },
        });
        break;
      }
      // Conflict, already exists
      case 409:
      default: {
      }
    }
  };

  const buttonClass = mergeStyles({
    display: 'inline-block',
  });

  const buttonStyles = {
    root: {
      position: 'relative',
      display: 'inline-block',
      top: '.275rem',
      left: '.5rem',
      padding: 0,
      minWidth: breakpoints.xl ? '85px' : '32px',
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.purpleDark
        : MEETINGFLOW_COLORS.purpleMedium,
      height: '34px',
      borderRadius: '20px',
      border: 'none !important',
      transition: '.3s ease-in-out all',

      '.ms-Button-menuIcon': {
        marginRight: '1rem',
        color: 'white !important',
      },
    },
    rootHovered: {
      '.ms-Button-textContainer': {
        color: 'white !important',
      },
      '.ms-Button-icon': {
        color: 'white !important',
      },
      'span.ms-Button-label': {
        color: MEETINGFLOW_COLORS.white,
      },
      backgroundColor: `${
        isDark ? MEETINGFLOW_COLORS.purpleDarker : MEETINGFLOW_COLORS.purpleDark
      } !important`,
    },
    rootExpanded: {
      i: {
        color: 'white !important',
      },
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.purpleDark
        : MEETINGFLOW_COLORS.purpleMedium,
    },
    rootFocused: {
      i: {
        color: 'white !important',
      },
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.purpleDark
        : MEETINGFLOW_COLORS.purpleMedium,
    },
    rootDisabled: {
      i: {
        color: isDark ? NeutralColors.gray100 : NeutralColors.gray30,
      },
      'span.ms-Button-label': {
        color: isDark ? NeutralColors.gray100 : NeutralColors.gray30,
      },
      backgroundColor: isDark ? NeutralColors.gray160 : NeutralColors.gray60,
      cursor: 'not-allowed',
    },
    flexContainer: {
      margin: '0',
    },
    icon: {
      position: 'relative',
      left: '-1px',
      fontSize: '25px',
      fontWeight: 500,
      color: MEETINGFLOW_COLORS.white,
    },
    textContainer: {
      fontSize: '14px !important',
      display: !breakpoints.lg ? 'none' : 'inline',
      paddingRight: '.5rem',
      'span.ms-Button-label': {
        color: MEETINGFLOW_COLORS.white,
      },
      color: MEETINGFLOW_COLORS.white,
      textAlign: 'left',
    },
  } as IButtonStyles;

  const Button = (
    <DefaultButton
      id={buttonId}
      className={buttonClass}
      onClick={() => {
        if (createMeetingflowDeferred && showDialog) {
          createMeetingflowDeferred().promise.then((result) => {});
        } else {
          !eventsLoading && eventsErrored
            ? createOrViewMeetingflow()
            : undefined;
        }
      }}
      iconProps={{
        iconName: 'NewAnalyticsQuery',
      }}
      styles={buttonStyles}
      menuProps={eventsErrored || showDialog ? undefined : menuProps}
      disabled={eventsLoading || !canCreatePlans}
      persistMenu={true}
      allowDisabledFocus
    >
      New Meetingflow
    </DefaultButton>
  );

  return (
    <div>
      {canCreatePlans ? (
        <>{Button}</>
      ) : (
        <TooltipHost
          content={
            <>
              <AsyncLink
                style={{ textDecoration: 'underline !important' }}
                onClick={requestCreatorMembership}
              >
                Request creator membership
              </AsyncLink>{' '}
              to create Meetingflows
            </>
          }
          color="#ffffff"
          directionalHint={DirectionalHint.topAutoEdge}
          tooltipProps={{
            delay: 0,
            styles: {
              root: {
                color: NeutralColors.white,
                display: 'inline-block',
                padding: '1.5rem',

                a: {
                  textDecoration: 'underline !important',
                },
              },
            },
          }}
          calloutProps={{
            backgroundColor: MEETINGFLOW_COLORS.teal,
            color: 'white !important',
            styles: {
              root: {
                '*': {
                  color: 'white !important',
                  fontWeight: FontWeights.semibold,
                },
                backgroundColor: MEETINGFLOW_COLORS.teal,
              },
              beakCurtain: {
                padding: 0,
                backgroundColor: MEETINGFLOW_COLORS.teal,
              },
            },
          }}
          styles={{
            root: {
              display: 'inline-block',
            },
          }}
        >
          {Button}
        </TooltipHost>
      )}
    </div>
  );
};
