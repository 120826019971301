import { useAuth0 } from '@auth0/auth0-react';
import { mergeStyleSets, Spinner } from '@fluentui/react';
import { DealRoom, Organization } from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useMemo } from 'react';

type OfficeArtifactPreviewProps = {
  className?: string;
  previewClassName?: string;
  organizationSlug: Organization['slug'];
  dealRoomId: DealRoom['id'];
  label?: string | null;
  name: string;
  fileName: string;
  fileUrl: string;
  thumbnailUrl: string | null;
};

export const OfficeArtifactPreview = ({
  className,
  previewClassName,
  organizationSlug,
  dealRoomId,
  label,
  name,
  fileName,
  fileUrl,
  thumbnailUrl,
}: OfficeArtifactPreviewProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: publicFileUrlResult,
    isLoading: publicFileUrlLoading,
    isError: publicFileUrlError,
  } = useQuery(`PublicFileUrl-${fileUrl}`, async () => {
    const token = await getAccessTokenSilently();
    return await DealRoomsApiClient.getPublicUrlForFileArtifact(
      {
        organizationSlug,
        dealRoomId,
        fileName,
        extendOnView: true,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );
  });

  const iframeSrc = useMemo(
    () =>
      publicFileUrlResult?.data?.url
        ? `https://view.officeapps.live.com/op/embed.aspx?src=${publicFileUrlResult?.data?.url}`
        : undefined,
    [publicFileUrlResult],
  );

  // eslint-disable-next-line no-console
  console.debug('iframeSrc', iframeSrc);

  const styles = mergeStyleSets({
    container: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    iframe: {
      height: '100%',
      width: '100%',
    },
  });

  if (publicFileUrlLoading && thumbnailUrl) {
    return (
      <div className={classNames(styles.container, className)}>
        <img src={thumbnailUrl} />
      </div>
    );
  } else if (publicFileUrlLoading) {
    return (
      <div className={classNames(styles.container, className)}>
        <Spinner />
      </div>
    );
  }

  if (publicFileUrlError || !iframeSrc) {
    return (
      <div className={classNames(styles.container, className)}>
        <p>Failed to load preview</p>
      </div>
    );
  }

  return (
    <div
      id="msdoc-renderer"
      className={classNames(styles.container, className)}
    >
      <iframe
        id="msdoc-iframe"
        className={classNames(styles.iframe, previewClassName)}
        title={label || name}
        src={iframeSrc}
      />
    </div>
  );
};
