import { mergeStyleSets } from '@fluentui/react';
import { useCallback } from 'react';
import { useAddDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useAddDealRoomArtifactDialog';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import DealRoomAsyncCommandBarButton from '../Components/DealRoomButton';
import { UpdateArtifactIconProps } from '../../../utils/iconProps';

type UpdateArtifactProps = {
  organizationSlug: string;
  dealRoomId: number;
  artifact: DealRoomArtifact;
};

export const UpdateArtifact = ({
  organizationSlug,
  dealRoomId,
  artifact,
}: UpdateArtifactProps) => {
  const {
    createDeferred: showAddDealRoomArtifactDialog,
    dialog: addDealRoomArtifactDialog,
  } = useAddDealRoomArtifactDialog({
    organizationSlug,
    dealRoomId,
    artifact,
  });

  const { refetch: refetchDealRoom } = useDealRoom(
    organizationSlug,
    dealRoomId,
  );

  const styles = mergeStyleSets({
    updateArtifactContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    addAttachment: {
      fontSize: '.75rem',
      lineHeight: '1rem',
    },
  });

  const handleClickUpdateVersion = useCallback(async () => {
    try {
      const result = await showAddDealRoomArtifactDialog().promise;
      if (result) {
        await refetchDealRoom();
      }
    } catch (error) {}
  }, [refetchDealRoom, showAddDealRoomArtifactDialog]);

  return (
    <div className={styles.updateArtifactContainer}>
      <DealRoomAsyncCommandBarButton
        customClasses={styles.addAttachment}
        onClick={handleClickUpdateVersion}
        text="New version"
        buttonStyleType="DEAL_ROOM"
        iconProps={UpdateArtifactIconProps}
      />
      {addDealRoomArtifactDialog}
    </div>
  );
};
