import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useAuth0 } from '@auth0/auth0-react';
import { EventsApiClient } from '../Services/NetworkCommon';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { Spinner } from '@fluentui/react';
import { useLocalStorageState } from '../Hooks/useLocalStorageState';
import { CalendarEventSource } from '@meetingflow/common/Api/data-contracts';

export const EventLookup = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();

  const { getAccessTokenSilently } = useAuth0();

  const [activeOrgId] = useLocalStorageState<number | undefined>(
    'active-org-id',
    undefined,
  );

  useEffect(() => {
    const calendarProvider = searchParams
      .get('calendarProvider')
      ?.toUpperCase() as CalendarEventSource;
    const eventId = searchParams.get('eventId');

    appInsights.trackEvent({
      name: 'CALENDAR_INTEGRATION_CLICK',
      properties: {
        source: calendarProvider,
        eventId,
      },
    });

    if (!(calendarProvider && eventId)) {
      toast.error(`Missing required parameters`);
      navigate(`/`);
      return;
    }

    getAccessTokenSilently().then(async (token) => {
      try {
        const result = await EventsApiClient.getEventCreate(
          { calendarProvider, eventId, lastUsedOrganizationId: activeOrgId },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (result.status === 200) {
          navigate(result.data.redirectUrl);
        } else {
          toast.error('Could not find the meeting');
          navigate(`/`);
        }
      } catch (e) {
        toast.error('Could not find the meeting');
        navigate(`/`);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Spinner />;
};

export default EventLookup;
