import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  FontWeights,
  mergeStyles,
  NeutralColors,
  Spinner,
  Text,
  useTheme,
} from '@fluentui/react';
import {
  DetailedUser,
  OrganizationInvite,
  OrganizationUserRole,
} from '@meetingflow/common/Api/data-contracts';
import PublicEmailDomains from '@meetingflow/common/PublicEmailDomains';
import { getDomain } from '@meetingflow/common/StringHelpers';
import { useCallback, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { CompanyByDomain, OrgSuggestionsQuery } from '../../QueryNames';
import {
  ApiClient,
  MeetingflowsApiClient,
  OrganizationsApiClient,
} from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { BigPictureCompanyDetails } from '../../types/BigPictureCompanyDetails';
import { WelcomeCreateOrganization } from './WelcomeCreateOrganization';
import { WelcomeInvitations } from './WelcomeInvitations';
import { WelcomeOrganizationForbidden } from './WelcomeOrganizationForbidden';
import { WelcomeOrganizationJoin } from './WelcomeOrganizationJoin';
import { WelcomeProfile } from './WelcomeProfile';
import { WelcomeSuggestions } from './WelcomeSuggestions';
import { WelcomeTOS } from './WelcomeTOS';
import { humanizeNumber } from '../../Helpers/Typography';

export type WelcomeWizardProps = {
  organizationSlug?: string;
  orgRole?: OrganizationUserRole;
  userProfile?: DetailedUser;
  refetchUserProfile: () => Promise<unknown>;
  pendingInvites?: OrganizationInvite[];
  refetchInvites: () => Promise<unknown>;
  refetchAll: () => Promise<unknown>;
};

// Just here to make development easier. Toggle to force these screens to appear
const SHOW_INVIATIONS = false;
const SHOW_SUGGESTIONS = false;

export const WelcomeWizard = ({
  organizationSlug,
  orgRole,
  userProfile,
  refetchUserProfile,
  pendingInvites,
  refetchInvites,
  refetchAll,
}: WelcomeWizardProps) => {
  const { getAccessTokenSilently, user: auth0User } = useAuth0();

  const emailDomain = useMemo(() => {
    return getDomain(auth0User!.email!);
  }, [auth0User]);

  const client = useQueryClient();

  const [forceCreate, setForceCreate] = useState<boolean>(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const { isDark } = useLightOrDarkMode();

  const {
    data: orgSuggestions,
    isLoading: orgSuggestionsLoading,
    isFetched: orgSuggestionsFetched,
    refetch: refetchOrgSuggestions,
  } = useQuery(
    OrgSuggestionsQuery,
    async () => {
      const token = await getAccessTokenSilently();
      return OrganizationsApiClient.listOrganizationSuggestions(
        { organization: organizationSlug },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !PublicEmailDomains.includes(emailDomain),
    },
  );

  const {
    data: companyDetails,
    isLoading: companyDetailsLoading,
    isFetched: companyDetailsFetched,
    refetch: refetchCompanyDetails,
  } = useQuery(
    CompanyByDomain(emailDomain),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<BigPictureCompanyDetails>(
        `/company/${emailDomain}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !PublicEmailDomains.includes(emailDomain) },
  );

  const navigateToOrg = useCallback(
    async (orgSlug: string) => {
      try {
        const token = await getAccessTokenSilently();
        const samples = await MeetingflowsApiClient.listPlans(
          { organizationSlug: orgSlug, isSample: true },
          { headers: { Authorization: `Bearer ${token}` } },
        );

        const sample = samples.data?.find((s) => s.sampleType === 'WELCOME');
        // Not doing the sample right now - JC, 2023-11-15
        // if (sample) {
        //   navigate(`/organization/${orgSlug}/plan/${sample.id}`);
        // } else {
        // navigate(`/organization/${orgSlug}`);
        // }

        navigate(`/organization/${orgSlug}/?firstVisit=true`);
        return await client.invalidateQueries();
      } catch {
        navigate(`/organization/${orgSlug}`);
        return client.invalidateQueries();
      }
    },
    [client, getAccessTokenSilently, navigate],
  );

  const wizardPage = useMemo(() => {
    if (!userProfile?.tosAccepted) {
      return <WelcomeTOS user={userProfile} refetchUser={refetchUserProfile} />;
    }

    if (!userProfile?.timezone) {
      return (
        <WelcomeProfile user={userProfile} refetchUser={refetchUserProfile} />
      );
    }

    if (pendingInvites?.length || SHOW_INVIATIONS) {
      return (
        <WelcomeInvitations
          organizationSlug={organizationSlug}
          existingRole={orgRole}
          invites={pendingInvites || []}
          refreshInvites={refetchInvites}
        />
      );
    }

    if (!organizationSlug || SHOW_SUGGESTIONS) {
      if ((orgSuggestions?.data?.length && !forceCreate) || SHOW_SUGGESTIONS) {
        return (
          <WelcomeSuggestions
            organizationSlug={organizationSlug}
            suggestions={orgSuggestions?.data || []}
            onJoinOrg={navigateToOrg}
            onRequestAccess={async () => refetchOrgSuggestions()}
            onCancelRequest={refetchOrgSuggestions}
            onCreateOwnOrganization={() => {
              refetchCompanyDetails();
              setForceCreate(true);
            }}
          />
        );
      }

      return (
        <WelcomeCreateOrganization
          companyName={
            companyDetails?.data?.name || companyDetails?.data?.legalName
          }
          onOrganizationCreated={navigateToOrg}
        />
      );
    } else {
      if (orgSuggestions?.data?.length) {
        return (
          <WelcomeOrganizationJoin
            onJoinOrg={refetchAll}
            onRequestAccess={refetchOrgSuggestions}
            onCancelRequest={refetchOrgSuggestions}
            organization={orgSuggestions.data[0]}
          />
        );
      }

      return <WelcomeOrganizationForbidden />;
    }
  }, [
    userProfile,
    pendingInvites,
    organizationSlug,
    refetchUserProfile,
    orgRole,
    refetchInvites,
    orgSuggestions?.data,
    forceCreate,
    companyDetails?.data?.name,
    companyDetails?.data?.legalName,
    navigateToOrg,
    refetchOrgSuggestions,
    refetchCompanyDetails,
    refetchAll,
  ]);

  const wizardPageText = useMemo(() => {
    if (!userProfile?.tosAccepted) {
      return {
        title: '👋 Welcome to Meetingflow.',
        subtitle:
          'Meetingflow is a powerful tool to help you run better meetings. After a quick setup, you`ll be recording meetings, receiving AI summaries generated from transripts, and a interacting with a powerful AI Chat that learns from your meetings.',
      };
    }

    if (!userProfile?.timezone) {
      return {
        title: '🙋Your User Profile',
        subtitle: `We need some basic information to get you started. You can change these later in your User Profile.`,
      };
    }

    if (pendingInvites?.length || SHOW_INVIATIONS) {
      return {
        title: '💌 You have Workspace Invitations!',
        subtitle:
          pendingInvites?.length === 1
            ? `You've been invited to join a Workspace.`
            : `You've been invited to join ${humanizeNumber(
                pendingInvites?.length || 0,
              )} Workspaces.`,
      };
    }

    if (!organizationSlug || SHOW_SUGGESTIONS) {
      if ((orgSuggestions?.data?.length && !forceCreate) || SHOW_SUGGESTIONS) {
        return {
          title: '🕵️ You have Workspace Suggestions!',
          subtitle:
            orgSuggestions?.data?.length === 1
              ? 'Based on your email domain, we found a Workspace you may want to join!'
              : `Based on your email domain, we found ${humanizeNumber(
                  orgSuggestions?.data?.length || 0,
                )} Workspaces you may want to join!`,
        };
      }

      return {
        title: '🗂️ Next, create a Workspace.',
        subtitle: `A Workspace is a hub for you and others you want to collaborate with. Users in the same workspace can view each other's Meetingflows. A Workspace can be used for your entire company, a specific team, or just yourself — and users can be a member of multiple Workspaces.`,
      };
    } else {
      if (orgSuggestions?.data?.length) {
        return {
          title: 'Join Workspace',
          subtitle: 'Please join your Workspace.',
        };
      }

      return {};
    }
  }, [
    userProfile,
    pendingInvites,
    organizationSlug,
    orgSuggestions?.data,
    forceCreate,
  ]);

  const modelContentClass = mergeStyles({
    maxWidth: '1024px',
    minHeight: '200px',
    width: '1024px',
    margin: '0 auto',
    boxSizing: 'border-box',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.purpleMedium
      : MEETINGFLOW_COLORS.purpleMedium,
    padding: '1rem 1.5rem 1.5rem 1.5rem',

    '.welcome-modal-header': {
      backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
      fontWeight: FontWeights.semibold,
      textAlign: 'left',
      padding: '0',
      marginBottom: '.25rem',
      boxSizing: 'border-box',
      color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.white,
      textShadow: '0 0 1px rgba(0,0,0,.5)',
      maxWidth: '75%',

      h2: {
        fontSize: FontSizes.size28,
        fontWeight: FontWeights.semibold,
        margin: '0',
        color: MEETINGFLOW_COLORS.white,
        textAlign: 'left',
      },
      p: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.size16,
        margin: '0 0 1rem 0',
        textAlign: 'left',
        color: MEETINGFLOW_COLORS.purpleGrey,

        span: {
          color: MEETINGFLOW_COLORS.white,
        },
      },
    },

    '.welcome-modal-header.center': {
      textAlign: 'center',
      margin: '0 auto',

      '*': {
        textAlign: 'center',
      },
    },

    '.wizard-page': {
      // padding: '1rem',
      borderRadius: '.25rem',
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.purpleMedium
        : MEETINGFLOW_COLORS.purpleMedium,

      '.ms-Link': {
        color: MEETINGFLOW_COLORS.teal,
        textDecoration: 'underline',
      },
    },

    '.welcome-wizard-form': {
      display: 'flex',
      flexDirection: 'column',
      gap: '.5rem',
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.black
        : MEETINGFLOW_COLORS.white,
      padding: '1rem',
      borderRadius: '.25rem',

      h4: {
        fontSize: FontSizes.size18,
        lineHeight: '20px',
        fontWeight: FontWeights.semibold,
        margin: '0',
        color: isDark
          ? MEETINGFLOW_COLORS.purpleGrey
          : MEETINGFLOW_COLORS.black,
        textAlign: 'left',
      },

      'h4.separator': {
        paddingTop: '1rem',
        marginTop: '1rem',
        borderTop: `.5px solid ${MEETINGFLOW_COLORS.purpleMediumDark}`,
      },

      'h4 + p': {
        marginTop: '-.25rem',
        marginBottom: '1rem',
      },

      p: {
        maxWidth: '75%',
        margin: '0 0 1rem 0',
        color: isDark
          ? MEETINGFLOW_COLORS.purpleGrey
          : MEETINGFLOW_COLORS.black,

        strong: {
          fontWeight: FontWeights.semibold,
        },

        a: {
          color: MEETINGFLOW_COLORS.teal,
          textDecoration: 'underline',
        },
      },

      'p.discoverability-disabled': {
        display: 'block',
        margin: '0 0 1rem 0',
        backgroundColor: MEETINGFLOW_COLORS.magentaMedium,
        padding: '.25rem .5rem .5rem .5rem',
        borderRadius: '.25rem',
        color: MEETINGFLOW_COLORS.white,

        ':before': {
          content: '"⚠️"',
          display: 'inline-block',
          marginRight: '.25rem',
        },
      },

      '.elements': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: '.5rem',

        '> div': {
          flex: '1 1 0',
          position: 'relative',
        },

        'p[class*=errorMessage]': {
          backgroundColor: MEETINGFLOW_COLORS.magentaMedium,
          color: MEETINGFLOW_COLORS.white,
          padding: '.25rem .5rem',
          borderRadius: '.25rem',
          display: 'inline-block',
          position: 'absolute',
          top: 0,
          right: 0,

          span: {
            ':before': {
              content: '"⚠️"',
              display: 'inline-block',
              marginRight: '.25rem',
            },
          },
        },

        '.help-text': {
          display: 'block',
          fontSize: FontSizes.size10,
          color: isDark ? `rgb(208, 208, 208)` : `rgb(48, 48, 48)`,
          lineHeight: '1rem',
          margin: 0,
          marginTop: '.25rem',
        },
      },

      '.elements.column': {
        flexDirection: 'column',
      },

      '.elements.row': {
        flexDirection: 'row',
      },

      '.elements.center': {
        justifyContent: 'center',
        '*': {
          margin: '0 auto',
          textAlign: 'center',
        },
      },

      '.controls': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',

        paddingTop: '1rem',
        marginTop: '1rem',
        borderTop: `.5px solid ${MEETINGFLOW_COLORS.purpleMediumDark}`,

        '.ms-Button, .ms-Button--icon': {
          transition: 'all .3s ease-in-out',

          backgroundColor: MEETINGFLOW_COLORS.purpleMediumDark,
          borderColor: MEETINGFLOW_COLORS.purpleMediumDark,
          color: MEETINGFLOW_COLORS.white,
          cursor: 'pointer',

          ':hover': {
            backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
            borderColor: MEETINGFLOW_COLORS.purpleMedium,
          },
        },

        '.ms-Button--primary, .ms-Button.primary': {
          backgroundColor: MEETINGFLOW_COLORS.teal,
          borderColor: MEETINGFLOW_COLORS.teal,
          color: MEETINGFLOW_COLORS.white,
          cursor: 'pointer',

          ':hover': {
            backgroundColor: MEETINGFLOW_COLORS.tealMediumLight,
            borderColor: MEETINGFLOW_COLORS.tealMediumLight,
          },
        },
      },

      '.ms-TextField-fieldGroup, .ms-Dropdown, .ms-Dropdown-title': {
        borderColor: isDark
          ? `rgba(255,255,255,.35) !important`
          : `rgba(255,255,255,.35) !important`,
        backgroundColor: isDark ? NeutralColors.gray140 : NeutralColors.gray20,
        color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,

        ':after': {
          // display: 'none',
          borderColor: `${MEETINGFLOW_COLORS.white} !important`,
          borderWidth: '1px !important',
        },
      },

      '.ms-Checkbox-checkbox': {
        backgroundColor: MEETINGFLOW_COLORS.teal,
        borderColor: MEETINGFLOW_COLORS.teal,
      },

      '.ms-TextField-prefix': {
        backgroundColor: isDark
          ? NeutralColors.gray190
          : MEETINGFLOW_COLORS.purpleMediumDark,
        color: MEETINGFLOW_COLORS.white,
        opacity: '.5',
      },
    },
  });

  const loading =
    (orgSuggestionsLoading && !orgSuggestionsFetched) ||
    (companyDetailsLoading && !companyDetailsFetched);

  return (
    <div className={modelContentClass}>
      <div className={`welcome-modal-header`}>
        <h2>{wizardPageText.title}</h2>
        <p className=" subheader">{wizardPageText.subtitle}</p>
      </div>
      {loading ? <Spinner /> : <div className="wizard-page">{wizardPage}</div>}
    </div>
  );
};

export default WelcomeWizard;
