import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useUserProfile } from '../Hooks/useProfile';
import { useQuery } from 'react-query';
import { OrganizationsQuery } from '../QueryNames';
import { OrganizationsApiClient } from '../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../Themes/Themes';
import {
  ComboBox,
  DatePicker,
  DefaultButton,
  FontSizes,
  IComboBoxOption,
  IComboBoxStyles,
  IDatePickerStyles,
  ITextFieldStyles,
  NeutralColors,
  SelectableOptionMenuItemType,
  Spinner,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';
import { DateTime } from 'luxon';
import toast from 'react-hot-toast';

export function AdminEmailPreview() {
  const { user } = useUserProfile();
  const [email, setEmail] = useState(user?.email);
  const [organization, setOrganization] = useState('');
  const [now, setNow] = useState(DateTime.local().toISO()!);
  const [emailType, setEmailType] = useState('daily');
  const [response, setResponse] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const { isDark } = useLightOrDarkMode();

  const { data: orgData, isLoading: orgDataIsLoading } = useQuery(
    OrganizationsQuery,
    async () => {
      const token = await getAccessTokenSilently();
      return OrganizationsApiClient.listOrganizations(
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  useEffect(() => {
    if (orgData?.data.length) {
      setOrganization(orgData.data[0].slug);
    }
  }, [orgData]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    const token = await getAccessTokenSilently();

    const url =
      '/api/admin/emails/preview?' +
      new URLSearchParams({
        email: email || user?.email || '',
        organization: organization || orgData?.data[0].slug || '',
        now,
        emailType,
      });

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const responseData = await response.text();

      if (!response.ok) {
        throw new Error(responseData);
      }

      setResponse(responseData);
      setIsSubmitting(false);
    } catch (error) {
      console.error('Error:', error);
      setIsSubmitting(false);
      toast.error('Error generating email preview');
    }
  };

  const layoutClass = mergeStyles({
    overflowY: 'auto',
    height: '100%',
    maxHeight: '100%',
    minHeight: '100%',
  });

  const emailPreviewClass = mergeStyles({
    height: '100%',
    minHeight: '100%',
  });

  const spinnerStyles = {
    root: {
      margin: 'auto',
      marginTop: '1rem',
    },
  };

  const formHeaderClass = mergeStyles({
    position: 'sticky',
    padding: '.5rem',
    top: 0,
    boxSizing: 'border-box',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.purpleGrey,
    zIndex: 100,
    borderBottom: `1px solid ${
      isDark ? NeutralColors.gray220 : MEETINGFLOW_COLORS.purpleMedium
    }`,
    borderTop: `1px solid ${
      isDark ? NeutralColors.gray220 : MEETINGFLOW_COLORS.purpleMedium
    }`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    columnGap: '.25rem',
  });

  const textFieldStyles = {
    root: {
      boxSizing: 'border-box',
      maxWidth: '15rem',
      flexGrow: 1,
      transition: '.3s ease-in-out all',
      height: '1.5rem',
      borderRadius: '.25rem',
      backgroundColor: isDark
        ? NeutralColors.gray180
        : MEETINGFLOW_COLORS.purpleGrey,
      border: 'none',
      ':after': {
        border: 'none',
      },
    },
    fieldGroup: {
      height: '1.5rem',
      boxSize: 'border-box',
      border: 'none',
      backgroundColor: isDark
        ? NeutralColors.gray200
        : MEETINGFLOW_COLORS.purpleGreyLight,
    },
    field: {
      height: '1.5rem',
      borderRadius: '.25rem',
      fontSize: FontSizes.small,
      color: isDark ? NeutralColors.gray100 : MEETINGFLOW_COLORS.purpleMedium,
      border: 'none',
      boxSizing: 'border-box',
      '::placeholder': {
        color: isDark ? NeutralColors.gray100 : MEETINGFLOW_COLORS.purpleMedium,
      },
    },
  } as Partial<ITextFieldStyles>;

  const comboBoxStyles = {
    root: {
      flexGrow: 1,
      transition: '.3s ease-in-out all',
      height: '1.5rem',
      lineHeight: '1.5rem',
      fontSize: FontSizes.small,
      backgroundColor: isDark
        ? NeutralColors.gray200
        : MEETINGFLOW_COLORS.purpleGreyLight,
      border: 'none',
      borderRadius: '.25rem',
      minWidth: '5rem',

      ':after': {
        display: 'none !important',
      },

      '.ms-Icon': {
        color: MEETINGFLOW_COLORS.purpleMedium,
      },

      '.ms-ComboBox-CaretDown-button': {
        backgroundColor: isDark
          ? NeutralColors.gray200
          : MEETINGFLOW_COLORS.purpleGreyLight,
      },
    },
    input: {
      backgroundColor: 'transparent',
      color: isDark ? NeutralColors.gray100 : NeutralColors.gray130,
      '::placeholder': {
        color: isDark ? NeutralColors.gray100 : MEETINGFLOW_COLORS.purpleMedium,
      },
    },
    optionsContainer: {
      '.ms-ComboBox-divider': {
        backgroundColor: isDark ? NeutralColors.gray130 : NeutralColors.gray40,
      },
    },
  } as Partial<IComboBoxStyles>;

  const datePickerStyles = {
    textField: {
      flexGrow: 1,
      transition: '.3s ease-in-out all',

      '> span': {
        display: 'none !important',
      },

      'div[role="combobox"]': {
        height: '1.5rem',
        lineHeight: '1.5rem',
        fontSize: FontSizes.small,
        backgroundColor: isDark
          ? NeutralColors.gray200
          : MEETINGFLOW_COLORS.purpleGreyLight,
        border: 'none',
        borderRadius: '.25rem',
        minWidth: '3rem',
        color: isDark ? NeutralColors.gray100 : MEETINGFLOW_COLORS.purpleMedium,

        label: {
          color: isDark
            ? NeutralColors.gray100
            : MEETINGFLOW_COLORS.purpleMedium,
        },

        '*': {
          color: isDark
            ? NeutralColors.gray100
            : MEETINGFLOW_COLORS.purpleMedium,
        },
      },
    },
    root: {
      height: '1.5rem',
      minWidth: '5rem',
      flexBasis: '25%',
      div: {
        height: '1.5rem',
        border: 'none !important',
        borderRadius: '.25rem !important',
        backgroundColor: 'tnasparent',

        ':after': {
          display: 'none !important',
        },
      },

      '.ms-Label': {
        margin: '0 0 .25rem 0',
        padding: 0,
        lineHeight: '.75rem',
        height: '.75rem',
        color: isDark ? NeutralColors.gray100 : NeutralColors.gray120,
        fontSize: FontSizes.mini,
      },
    },

    icon: {
      margin: '.2rem 0 0 0',
      padding: 0,
      color: MEETINGFLOW_COLORS.purpleMedium,
    },
  } as Partial<IDatePickerStyles>;

  const submitButtonStyles = {
    root: {
      backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
      color: NeutralColors.white,
      borderRadius: '.25rem',
      height: '1.5rem',
      lineHeight: '1.5rem',
      fontSize: FontSizes.small,
      padding: '0 .5rem',
      transition: '.3s ease-in-out all',
      border: 'none',
      ':hover': {
        backgroundColor: MEETINGFLOW_COLORS.purpleDark,
      },
    },
  };

  const EMAIL_TYPE_OPTIONS = [
    {
      key: 'daily',
      text: 'Daily (real user data)',
    },
    {
      key: 'weekly',
      text: 'Weekly (real user data)',
    },
    {
      key: 'weeklySample',
      text: 'Weekly (sample data)',
    },
    {
      key: 'divider',
      text: '-',
      itemType: SelectableOptionMenuItemType.Divider,
    },
    {
      key: 'organizationInvite',
      text: 'Organization Invite',
    },
    {
      key: 'organizationJoinRequest',
      text: 'Organization Join Request',
    },
    {
      key: 'organizationJoinRequestResponse',
      text: 'Organization Join Request Response',
    },
    {
      key: 'organizationAutomaticJoinNotification',
      text: 'Organization Automatic Join Notification',
    },
    {
      key: 'divider',
      text: '-',
      itemType: SelectableOptionMenuItemType.Divider,
    },
    {
      key: 'meetingPlanShareNotification',
      text: 'Meeting Plan Share Notification',
    },
    {
      key: 'inviteAndShareNotification',
      text: 'Invite and Share Notification',
    },
    {
      key: 'mentionNotification',
      text: 'Mention Notification',
    },
    {
      key: 'companyMentionNotification',
      text: 'Company Mention Notification',
    },
    {
      key: 'companyInviteAndMentionNotification',
      text: 'Company Invite and Mention Notification',
    },
    {
      key: 'contactMentionNotification',
      text: 'Contact Mention Notification',
    },
    {
      key: 'contactInviteAndMentionNotification',
      text: 'Contact Invite and Mention Notification',
    },
    {
      key: 'divider',
      text: '-',
      itemType: SelectableOptionMenuItemType.Divider,
    },
    {
      key: 'taskAssignmentNotification',
      text: 'Task Assignment Notification',
    },
    {
      key: 'taskCompletedAssigneeNotification',
      text: 'Task Completed Assignee Notification',
    },
    {
      key: 'taskCompletedCreatorNotification',
      text: 'Task Completed Creator Notification',
    },
    {
      key: 'sendMeetingSummary',
      text: 'Meeting Summary',
    },
    {
      key: 'followup',
      text: 'Followup',
    },
    {
      key: 'recordingReady',
      text: 'Recording Ready (sample)',
    },
    {
      key: 'emailVerificationChallenge',
      text: 'Email Verification Challenge',
    },
    {
      key: 'divider',
      text: '-',
      itemType: SelectableOptionMenuItemType.Divider,
    },
    {
      key: 'userWelcomeNotification',
      text: 'User Welcome Notification (defunct)',
    },
  ] as IComboBoxOption[];

  return (
    <div className={layoutClass}>
      <form onSubmit={handleSubmit} className={formHeaderClass}>
        <TextField
          type="text"
          id="email"
          value={email}
          styles={textFieldStyles}
          placeholder="User email"
          onChange={(e, value) => setEmail(value || '')}
        />

        <label htmlFor="organization"></label>
        <TextField
          type="text"
          id="organization"
          value={organization}
          styles={textFieldStyles}
          placeholder="Organization slug"
          onChange={(e, value) => setOrganization(value || '')}
        />

        <ComboBox
          id="emailType"
          options={EMAIL_TYPE_OPTIONS}
          selectedKey={emailType}
          onChange={(e, option) => setEmailType((option?.key as string) || '')}
          placeholder="Select email type..."
          styles={comboBoxStyles}
        />

        <DatePicker
          styles={datePickerStyles}
          value={DateTime.fromISO(now).toJSDate()}
          placeholder="From date..."
          ariaLabel="Select a Minimum date"
          onSelectDate={(date) => {
            if (!!date) {
              setNow(DateTime.fromJSDate(date).toISO()!);
            } else {
              setNow('');
            }
          }}
        />

        <DefaultButton
          type="submit"
          value="Generate"
          text="Generate"
          disabled={orgDataIsLoading || isSubmitting}
          styles={submitButtonStyles}
        />
      </form>

      {isSubmitting ? (
        <Spinner styles={spinnerStyles} />
      ) : (
        <div
          id="email-preview"
          className={emailPreviewClass}
          dangerouslySetInnerHTML={{ __html: response }}
        ></div>
      )}
    </div>
  );
}
