import { useAuth0 } from '@auth0/auth0-react';
import {
  Dropdown,
  FontSizes,
  FontWeights,
  NeutralColors,
  PivotItem,
  Spinner,
  Text,
  mergeStyles,
} from '@fluentui/react';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { AsyncIconButton } from '../../../Components/HOC/AsyncButton';
import { SettingsPageLayout } from '../../../Components/Organization/Settings/SettingsPageLayout';
import { SettingsStyledPivot } from '../../../Components/Organization/Settings/StyledPivot';
import { useConfirmationDialog } from '../../../Hooks/Modals/useConfirmationDialog';
import { useCustomFieldsetDialog } from '../../../Hooks/Modals/useCustomFieldsetDialog';
import { useExternalServiceConfigurations } from '../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../Hooks/useOrganization';
import { OrganizationExternalServiceConfigurationCustomFieldSetsQuery } from '../../../QueryNames';
import { ExternalServicesApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { OrganizationSlugRouteParams } from '../../../types/RouteParams';
import { SALESFORCE_OBJECT_TYPE } from '../../../types/SalesforceObjectTypes';
import { ConfigureSalesforceObject } from './ConfigureSalesforceObject';

export const ConfigureSalesforceIntegration = () => {
  const {
    loading: configurationsLoading,
    configurationById,
    refetchAll: refetchAllConfigurations,
  } = useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const { organizationSlug, configurationId, sObjectType } = useParams<
    OrganizationSlugRouteParams & {
      configurationId: string;
      sObjectType: string;
    }
  >();

  const [activeCustomFieldsetId, setActiveCustomFieldSetId] = useState<
    number | null
  >(null);

  const { organization, hasEntitlement } = useOrganization();

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { isDark } = useLightOrDarkMode();

  const {
    createDeferred: createAddEditCustomFieldsetDialog,
    dialog: addEditCustomFieldsetDialog,
  } = useCustomFieldsetDialog();

  const {
    createDeferred: createConfirmDeleteCustomFieldSetDeffered,
    dialog: confirmDeleteCustomFieldSetDialog,
  } = useConfirmationDialog({
    title: `Delete preset?`,
    subText:
      'Deleting this custom field preset will delete it for all users and cannot be undone. Are you sure you want to delete this preset?',
    showCloseButton: true,
    primaryAction: 'CANCEL',
  });

  const configId = useMemo(() => {
    if (!configurationId) {
      return NaN;
    }

    try {
      return parseInt(configurationId);
    } catch {
      return NaN;
    }
  }, [configurationId]);

  useEffect(() => {
    if (
      isNaN(configId) ||
      (!!sObjectType &&
        !(Object.values(SALESFORCE_OBJECT_TYPE) as string[]).includes(
          sObjectType,
        ))
    ) {
      navigate(`/organization/${organizationSlug}/settings`);
    } else if (!sObjectType) {
      navigate(
        `/organization/${organizationSlug}/configuration/salesforce/${configId}/Opportunity`,
      );
    }
  }, [configId, navigate, organizationSlug, sObjectType]);

  useEffect(() => {
    if (!configurationsLoading && !configurationById(configId)) {
      navigate(`/organization/${organizationSlug}/settings`);
    }
  }, [
    configId,
    configurationById,
    configurationsLoading,
    navigate,
    organizationSlug,
  ]);

  useEffect(() => {
    if (
      sObjectType === SALESFORCE_OBJECT_TYPE.LEAD &&
      !hasEntitlement('SALESFORCE_LEADS')
    ) {
      navigate(
        `/organization/${organizationSlug}/configuration/salesforce/${configId}`,
      );
    }
  }, [configId, hasEntitlement, navigate, organizationSlug, sObjectType]);

  const {
    data: customFieldSets,
    isLoading: customFieldsetsLoading,
    isFetched: customFieldsetsFetched,
    refetch: refetchCustomFieldsets,
  } = useQuery(
    OrganizationExternalServiceConfigurationCustomFieldSetsQuery(
      organizationSlug!,
      configId,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ExternalServicesApiClient.listCustomFieldsets(
        {
          organizationSlug: organizationSlug!,
          configurationId: configId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },
    {
      enabled:
        !!organizationSlug &&
        hasEntitlement('CUSTOM_FIELDSETS') &&
        !isNaN(configId),
      onSuccess: (data) => {
        if (
          activeCustomFieldsetId !== null &&
          !data.data.some((f) => f.id === activeCustomFieldsetId)
        ) {
          setActiveCustomFieldSetId(null);
        }
      },
    },
  );

  useEffect(() => {
    if (
      organizationSlug &&
      hasEntitlement('CUSTOM_FIELDSETS') &&
      !isNaN(configId)
    ) {
      refetchCustomFieldsets();
    }
  }, [configId, hasEntitlement, organizationSlug, refetchCustomFieldsets]);

  const customFieldPresetClass = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '.5rem',
    marginBottom: '1rem',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.purpleGrey,
    padding: '.5rem',
    borderRadius: '.25rem',

    h3: {
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.medium,
      lineHeight: '1rem',
      backgroundColor: isDark
        ? NeutralColors.gray180
        : MEETINGFLOW_COLORS.purpleGreyer,
      borderBottom: `1px solid ${
        isDark
          ? NeutralColors.gray170
          : MEETINGFLOW_COLORS.purpleGreyMediumLight
      }`,
      margin: '-.5rem -.5rem',
      padding: '.5rem .5rem .5rem .5rem',
      marginBottom: '0',
      borderTopRightRadius: '.25rem',
      borderTopLeftRadius: '.25rem',
    },

    '.field-container': {
      display: 'flex',
      flexDirection: 'row',
      columnGap: '.5rem',
      alignItems: 'flex-end',

      '.inline-label': {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.medium,
        lineHeight: '32px',
      },
    },

    '.customFieldsetPicker': {
      minWidth: '250px',

      '.ms-Label': {
        display: 'none',
      },
    },
    '.addCustomFieldsetButton': {},
    '.editCustomFieldsetButton': {},
    '.deleteCustomFieldsetButton': {},
  });

  if (!organizationSlug || !configId) {
    return null;
  }

  if (configurationsLoading) {
    return <Spinner />;
  }

  return (
    <>
      <SettingsPageLayout
        title="Configure Salesforce"
        subtitle={organization?.name}
      >
        {hasEntitlement('CUSTOM_FIELDSETS') ? (
          <div className={customFieldPresetClass}>
            <h3>CRM Field Presets</h3>
            <div className="field-container">
              <Text className="inline-label">Preset</Text>
              <Dropdown
                className="customFieldsetPicker"
                label={'Field Preset'}
                disabled={customFieldsetsLoading && !customFieldsetsFetched}
                selectedKey={activeCustomFieldsetId || -1}
                options={[
                  {
                    key: -1,
                    text: 'Default',
                    title: 'Default',
                  },
                  ...(customFieldSets?.data?.map((f) => ({
                    key: f.id,
                    text: f.name,
                  })) ?? []),
                ]}
                onChange={(e, opt) => {
                  if (opt) {
                    setActiveCustomFieldSetId(
                      opt.key === -1 ? null : (opt.key as number),
                    );
                  }
                }}
              />
              <AsyncIconButton
                className="addCustomFieldsetButton"
                disabled={!(organizationSlug && !isNaN(configId))}
                iconProps={{ iconName: 'Add' }}
                title="Add new custom field preset"
                onClick={async () => {
                  const customFieldset =
                    await createAddEditCustomFieldsetDialog().promise;
                  try {
                    const token = await getAccessTokenSilently();
                    const result =
                      await ExternalServicesApiClient.createCustomFieldSet(
                        organizationSlug!,
                        configId,
                        customFieldset,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        },
                      );

                    refetchAllConfigurations();

                    toast.success(
                      `The Custom Field Preset was successfully created`,
                    );

                    refetchCustomFieldsets();
                    setActiveCustomFieldSetId(result.data.id);
                  } catch (e: unknown) {
                    toast.error(
                      `Something went wrong creating the Custom Field Preset, please try again`,
                    );
                  }
                }}
              />
              <AsyncIconButton
                className="editCustomFieldsetButton"
                disabled={
                  !(organizationSlug && !isNaN(configId)) ||
                  activeCustomFieldsetId === -1 ||
                  !customFieldSets?.data?.some(
                    (f) => f.id === activeCustomFieldsetId,
                  )
                }
                iconProps={{ iconName: 'Edit' }}
                title="Edit custom field preset"
                onClick={async () => {
                  const activeFieldSet = customFieldSets?.data?.find(
                    (f) => f.id === activeCustomFieldsetId,
                  );
                  if (!activeFieldSet) {
                    return;
                  }
                  const updatedCustomFieldset =
                    await createAddEditCustomFieldsetDialog(activeFieldSet)
                      .promise;
                  try {
                    const token = await getAccessTokenSilently();
                    const result =
                      await ExternalServicesApiClient.updateCustomFieldset(
                        organizationSlug!,
                        configId,
                        activeFieldSet.id,
                        updatedCustomFieldset,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        },
                      );

                    refetchAllConfigurations();

                    toast.success(
                      `The Custom Field Preset was successfully updated`,
                    );

                    refetchCustomFieldsets();
                    setActiveCustomFieldSetId(result.data.id);
                  } catch (e: unknown) {
                    toast.error(
                      `Something went wrong updating the Custom Field Preset, please try again`,
                    );
                  }
                }}
              />
              <AsyncIconButton
                className="deleteCustomFieldsetButton"
                disabled={
                  !(organizationSlug && !isNaN(configId)) ||
                  activeCustomFieldsetId === null
                }
                iconProps={{ iconName: 'Delete' }}
                title="Delete active custom field preset"
                onClick={async () => {
                  if (!activeCustomFieldsetId) {
                    return;
                  }

                  if (
                    await createConfirmDeleteCustomFieldSetDeffered().promise
                  ) {
                    try {
                      const token = await getAccessTokenSilently();
                      await ExternalServicesApiClient.deleteCustomFieldset(
                        organizationSlug,
                        configId,
                        activeCustomFieldsetId,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        },
                      );

                      refetchAllConfigurations();

                      toast.success(
                        `The Custom Field Preset was successfully deleted`,
                      );

                      refetchCustomFieldsets();
                      setActiveCustomFieldSetId(null);
                    } catch {
                      toast.error(
                        `Something went wrong deleting the Custom Field Preset, please try again`,
                      );
                    }
                  }
                }}
              />
            </div>
            <Text
              block
              style={{
                fontWeight: FontWeights.regular,
                fontSize: FontSizes.small,
                lineHeight: '1rem',
                color: isDark ? NeutralColors.gray60 : MEETINGFLOW_COLORS.black,
              }}
            >
              Create multiple "presets" of available fields that your users can
              choose between in their Workspace Settings. Try creating different
              presets for each role in your team.
            </Text>
          </div>
        ) : null}
        <SettingsStyledPivot
          selectedKey={sObjectType}
          onLinkClick={(item, ev) => {
            navigate(
              `/organization/${organizationSlug}/configuration/salesforce/${configId}/${item?.props.itemKey}`,
            );
          }}
          styles={{
            root: {},
            itemContainer: {
              height: 'calc(100vh -  12rem)',
              overflowY: 'auto',
            },
          }}
        >
          <PivotItem key="Account" itemKey="Account" headerText="Account">
            <div
              key="Account"
              style={{ position: 'relative', width: '100%', height: '100%' }}
            >
              <ConfigureSalesforceObject
                key={`${organizationSlug}_${configId}_${activeCustomFieldsetId}_Account`}
                organizationSlug={organizationSlug}
                configurationId={configId}
                customFieldsetId={activeCustomFieldsetId}
                sObjectType="Account"
                allowReadonly
                allowUpdateable
              />
            </div>
          </PivotItem>
          {hasEntitlement('SALESFORCE_LEADS') ? (
            <PivotItem key="Lead" itemKey="Lead" headerText="Lead">
              <div
                key="Lead"
                style={{ position: 'relative', width: '100%', height: '100%' }}
              >
                <ConfigureSalesforceObject
                  key={`${organizationSlug}_${configId}_${activeCustomFieldsetId}_Lead`}
                  organizationSlug={organizationSlug}
                  configurationId={configId}
                  customFieldsetId={activeCustomFieldsetId}
                  sObjectType="Lead"
                  allowReadonly
                  allowUpdateable
                />
              </div>
            </PivotItem>
          ) : null}
          <PivotItem
            key="Opportunity"
            itemKey="Opportunity"
            headerText="Opportunity"
          >
            <div
              key="Opportunity"
              style={{ position: 'relative', width: '100%', height: '100%' }}
            >
              <ConfigureSalesforceObject
                key={`${organizationSlug}_${configId}_${activeCustomFieldsetId}_Opportunity`}
                organizationSlug={organizationSlug}
                configurationId={configId}
                customFieldsetId={activeCustomFieldsetId}
                sObjectType="Opportunity"
                allowReadonly
                allowUpdateable
              />
            </div>
          </PivotItem>
          <PivotItem key="Task" itemKey="Task" headerText="Task">
            <div
              key="Task"
              style={{ position: 'relative', width: '100%', height: '100%' }}
            >
              <ConfigureSalesforceObject
                key={`${organizationSlug}_${configId}_${activeCustomFieldsetId}_Task`}
                organizationSlug={organizationSlug}
                configurationId={configId}
                customFieldsetId={activeCustomFieldsetId}
                sObjectType="Task"
                hideReadonly
                allowCreateable
              />
            </div>
          </PivotItem>
        </SettingsStyledPivot>
      </SettingsPageLayout>
      {addEditCustomFieldsetDialog}
      {confirmDeleteCustomFieldSetDialog}
    </>
  );
};

export default ConfigureSalesforceIntegration;
