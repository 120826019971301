import React, { ReactNode } from 'react';
import {
  FontIcon,
  FontWeights,
  mergeStyles,
  Text,
  useTheme,
} from '@fluentui/react';

interface DealRoomCardProps {
  children: ReactNode;
  className?: string;
  title?: string;
  icon?: string;
}

const DealRoomCard: React.FC<DealRoomCardProps> = ({
  children,
  className,
  title,
  icon,
}) => {
  const theme = useTheme();

  const dealRoomCardStyle = mergeStyles({
    borderRadius: '4px',
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.neutralLight,
    borderStyle: 'solid',

    '.header': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '8px 12px',
      borderBottomColor: theme.palette.neutralLight,
      borderBottomStyle: 'solid',
      color: theme.palette.themePrimary,
      '.title': {
        fontWeight: FontWeights.semibold,
      },

      '.icon': {
        fontWeight: FontWeights.semibold,
        fontSize: '16px',
        color: theme.palette.neutralDark,
        marginRight: '8px',
      },
    },
  });

  return (
    <div className={`${dealRoomCardStyle} ${className || ''}`}>
      {title ? (
        <div className="header">
          {icon ? <FontIcon className="icon" iconName={icon} /> : null}
          <Text className="title" variant="medium">
            {title}
          </Text>
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default DealRoomCard;
