import { useAuth0 } from '@auth0/auth0-react';
import { FontSizes, FontWeights, mergeStyles, useTheme } from '@fluentui/react';
import { useNavigate } from 'react-router';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';
import { useOrganization } from '../Hooks/useOrganization';
import { MEETINGFLOW_COLORS } from '../Themes/Themes';
import { useQuery } from 'react-query';
import { ApiClient } from '../Services/NetworkCommon';
import { useMemo, useRef } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

type NeedsPaymentResponse = {
  needsPayment?: boolean;
  reason?: string;
  shortReason?: string;
  error?: string;
};

export const BillingCta = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const { organization, role } = useOrganization();

  const organizationId = organization?.id;
  const organizationSlug = organization?.slug;

  const appInsights = useAppInsightsContext();

  const ctaRef = useRef<HTMLDivElement>(null);

  const { data: needsPaymentResponse, isLoading: isBillingDataLoading } =
    useQuery(
      `billingneedspayment_${organizationId}`,
      async () => {
        const token = await getAccessTokenSilently();
        return ApiClient.get<NeedsPaymentResponse>(
          `/organization/${organizationSlug}/billing/needs-payment`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      },
      {
        enabled: Boolean(organizationId && organizationSlug),
      },
    );

  const needsPayment = needsPaymentResponse?.data?.needsPayment;
  const shortReason = needsPaymentResponse?.data?.shortReason;
  const reason = needsPaymentResponse?.data?.reason;

  const billingCTAClass = useMemo(
    () =>
      mergeStyles({
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        columnGap: '.5rem',
        backgroundColor: MEETINGFLOW_COLORS.red,
        padding: '.25rem .25rem .25rem .5rem',
        borderBottomLeftRadius: '.25rem',
        borderBottomRightRadius: '.25rem',
        color: MEETINGFLOW_COLORS.white,
        position: 'absolute',
        top: '-.125rem',
        right: ctaRef.current?.clientWidth
          ? `calc(50% - ${ctaRef.current.clientWidth / 2}px)`
          : undefined,
        zIndex: '50000',
        boxShadow: '0 0 10px 0 rgba(0,0,0,.25)',
        cursor: 'pointer',
        transition: '.3s ease-in-out all',
        opacity: '.85',
        lineHeight: '1.5rem',

        ':hover': {
          backgroundColor: MEETINGFLOW_COLORS.magenta,

          '.label': {
            backgroundColor: MEETINGFLOW_COLORS.purpleDarker,
          },
        },

        '.reason': {
          fontWeight: FontWeights.regular,
          fontSize: FontSizes.small,
        },

        '.label': {
          transition: '.3s ease-in-out all',
          fontWeight: FontWeights.semibold,
          backgroundColor: MEETINGFLOW_COLORS.purpleDark,
          borderRadius: '.25rem',
          color: MEETINGFLOW_COLORS.white,
          padding: '0 .5rem',
          fontSize: FontSizes.small,
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ctaRef.current?.clientWidth],
  );

  useMemo(() => {
    if (needsPayment) {
      appInsights.trackEvent({
        name: 'BILLING_CTA_SHOWN',
        properties: {
          organizationSlug,
          reason,
          shortReason,
          role,
        },
      });
    }
  }, [appInsights, organizationSlug, reason, role, shortReason, needsPayment]);

  if (
    !organizationId ||
    !organizationSlug ||
    !needsPayment ||
    isBillingDataLoading
  ) {
    return null;
  }

  return (
    <div
      ref={ctaRef}
      className={billingCTAClass}
      onClick={() =>
        navigate(`/organization/${organization?.slug}/settings?tab=billing`)
      }
    >
      <span className="reason">{shortReason}.</span>
      {role === 'ADMIN' ? (
        <span className="label">Set up Billing</span>
      ) : (
        <span className="label">Contact an Administrator</span>
      )}
    </div>
  );
};

export default BillingCta;
