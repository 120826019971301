import { mergeStyles } from '@fluentui/react';
import useBreakpoints from '../../Hooks/useBreakpoints';
import { StyledSpinner } from '../StyledSpinner';
import { APP_MAX_WIDTH, APP_MAX_WIDTH_INT } from './BaseLayout';

export interface PageLayoutProps {
  primaryContent?: React.ReactNode; // Large column content
  primaryContentLoading?: boolean;
  primaryContentWidthPercentage?: number; // Width of the primary column, as a percentage
  primaryContentOverflow?: string;
  secondaryContent?: React.ReactNode; // Smaller column content
  secondaryContentLoading?: boolean;
  secondaryContentOverflow?: string;
  headerContent?: React.ReactNode; // Content to go above the two main columns
  headerContentLoading?: boolean;
  footerContent?: React.ReactNode; // Content to go below the two columns
  footerContentLoading?: boolean;
  secondaryContentSide?: 'left' | 'right';
  children?: React.ReactNode; // Children will be used if primaryContent is not specified.
  maxHeight?: string; // Optionally provide a maxHeight, additional content will be independently scrollable
  maxWidth?: number; // in pixels
  layoutRightPadding?: string;
  layoutLeftPadding?: string;
}

export const PageLayout: React.FunctionComponent<PageLayoutProps> = ({
  primaryContent,
  primaryContentLoading,
  primaryContentWidthPercentage = 70,
  primaryContentOverflow,
  secondaryContent,
  secondaryContentLoading,
  secondaryContentOverflow,
  headerContent,
  headerContentLoading,
  footerContent,
  footerContentLoading,
  secondaryContentSide = 'right',
  children,
  maxHeight,
  maxWidth = APP_MAX_WIDTH_INT,
  layoutRightPadding = '.25rem',
  layoutLeftPadding = '.25rem',
}) => {
  const breakpoints = useBreakpoints();

  const secondaryContentWidthPercentage = 100 - primaryContentWidthPercentage;
  const showSecondaryContentInSidebar = breakpoints.md;

  const layoutStyles = mergeStyles({
    flexDirection: secondaryContentSide === 'left' ? 'row-reverse' : 'row',
    display: `${showSecondaryContentInSidebar ? 'flex' : 'block'}`,
    transition: '.3s all ease-in-out',
    width: 'auto',
    margin: '1rem auto 0 auto',
    height: '100%',
    maxHeight,
    padding: `0 ${layoutRightPadding} .25rem ${layoutLeftPadding}`,
    overflow: maxHeight ? 'hidden' : 'auto',
    maxWidth:
      !secondaryContent && primaryContentWidthPercentage !== 100
        ? `${maxWidth * (primaryContentWidthPercentage / 100.0)}px`
        : `${maxWidth}px`,
    '.header-content': { padding: '0 .25rem', width: '100%' },
    '.primary-content': {
      overflow: primaryContentOverflow ? primaryContentOverflow : undefined,
      maxHeight,
      padding: showSecondaryContentInSidebar ? '0 .25rem' : 0,
      transition: '.3s all ease-in-out',
      width: `${
        showSecondaryContentInSidebar
          ? secondaryContent
            ? `calc(${primaryContentWidthPercentage}% - .5rem)`
            : '100%'
          : '100%'
      }`,
      margin: showSecondaryContentInSidebar
        ? secondaryContentSide === 'left'
          ? '0 0 0 .5rem'
          : '0 .5rem 0 0'
        : undefined,
    },
    '.secondary-content': {
      maxHeight,
      overflow: secondaryContentOverflow ? secondaryContentOverflow : undefined,
      transition: '.3s all ease-in-out',
      padding: showSecondaryContentInSidebar
        ? secondaryContentWidthPercentage === 0
          ? 0
          : '0 .25rem'
        : 0,
      width: `${
        showSecondaryContentInSidebar
          ? `calc(${secondaryContentWidthPercentage}% - .5rem)`
          : '100%'
      }`,
      height: `${
        showSecondaryContentInSidebar ? 'calc(100vh - 7.5rem)' : undefined
      }`,
      margin: showSecondaryContentInSidebar
        ? secondaryContentSide === 'right'
          ? '0 0 0 .5rem'
          : '0 .5rem 0 0'
        : undefined,
    },
    '.footer-content': { padding: '0 .25rem' },
  });

  return (
    <div
      style={{
        height: '100%',
      }}
    >
      {headerContent ? (
        headerContentLoading ? (
          <StyledSpinner />
        ) : (
          <div
            style={{
              padding: showSecondaryContentInSidebar ? '0 .25rem' : 0,
              margin: '0 .25rem',
              maxWidth: APP_MAX_WIDTH,
            }}
          >
            {headerContent}
          </div>
        )
      ) : null}
      <div className={layoutStyles}>
        <div className="primary-content">
          {primaryContentLoading ? (
            <StyledSpinner />
          ) : (
            primaryContent || children
          )}
        </div>
        {secondaryContent ? (
          secondaryContentLoading ? (
            <StyledSpinner />
          ) : (
            <div className="secondary-content">{secondaryContent}</div>
          )
        ) : null}
      </div>
      {footerContent ? (
        footerContentLoading ? (
          <StyledSpinner />
        ) : (
          <div
            style={{
              padding: '0 .25rem',
              width: '100%',
              maxWidth: APP_MAX_WIDTH,
            }}
          >
            {footerContent}
          </div>
        )
      ) : null}
    </div>
  );
};
