import { useAuth0 } from '@auth0/auth0-react';
import { IStyle, Spinner, Stack, Text, mergeStyles } from '@fluentui/react';
import {
  Application,
  ExternalServiceConfiguration,
} from '@meetingflow/common/Api/data-contracts';
import { GroupBy } from '@meetingflow/common/ArrayHelpers';
import { sortedUniq } from 'lodash';
import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { DEFAULT_STACK_TOKENS } from '../../../../Helpers/Layout';
import { useConfirmationDialog } from '../../../../Hooks/Modals/useConfirmationDialog';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { OrganizationExternalServiceConfigurationsQuery } from '../../../../QueryNames';
import { ExternalServicesApiClient } from '../../../../Services/NetworkCommon';
import hubspotIcon from '../../../../Static/Images/hubspot.png';
import salesforceIcon from '../../../../Static/Images/salesforce.png';
import slackIcon from '../../../../Static/Images/slack.png';
import { ManageIntegrationTile } from '../../../Integrations/ManageIntegrationTile';
import {
  settingsIndentedControlClass,
  settingsRootStackStyles,
  settingsSectionClass,
  settingsSectionDescriptionClass,
  settingsSectionDescriptionClassDark,
  settingsSubHeadClass,
} from '../SettingsPageLayout';

export const ManageIntegrationsTabContent = () => {
  const { getAccessTokenSilently } = useAuth0();

  const client = useQueryClient();
  const navigate = useNavigate();

  const { slug: orgSlug, hasEntitlement } = useOrganization();

  const { isDark } = useLightOrDarkMode();

  const { allConfigurations, configurationById, loading, refetch } =
    useExternalServiceConfigurations({ withToken: false });

  const {
    createDeferred: createConfirmRemoveIntegrationDeferred,
    dialog: confirmRemoveIntegrationDialog,
  } = useConfirmationDialog({
    title: `Remove integration?`,
    subText:
      'Removing this integration will remove it for all users in the workspace, and disassociate all Meetingflows.  This action is permanent and cannot be undone.  Are you sure you would like to remove it?',
    primaryAction: 'CANCEL',
  });

  const configurationsByApp = useMemo(
    () => GroupBy(allConfigurations, (configuration) => configuration.app),
    [allConfigurations],
  );

  const appsWithConfigurations = useMemo(() => {
    return sortedUniq(Object.keys(configurationsByApp) as Application[]);
  }, [configurationsByApp]);

  const removeIntegration = useCallback(
    async (configuration: ExternalServiceConfiguration) => {
      try {
        const confirmRemoveIntegration =
          await createConfirmRemoveIntegrationDeferred().promise;

        if (!confirmRemoveIntegration) {
          return;
        }

        const token = await getAccessTokenSilently();
        await toast.promise(
          ExternalServicesApiClient.deleteConfiguration(
            orgSlug!,
            configuration.id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: `Removing ${configuration.app.toLowerCase()} integration from the workspace`,
            success: (response) => {
              client.invalidateQueries(
                OrganizationExternalServiceConfigurationsQuery(orgSlug!),
              );
              client.invalidateQueries(
                OrganizationExternalServiceConfigurationsQuery(
                  orgSlug!,
                  undefined,
                  true,
                ),
              );
              client.invalidateQueries(
                OrganizationExternalServiceConfigurationsQuery(
                  orgSlug!,
                  configuration.app,
                ),
              );
              client.invalidateQueries(
                OrganizationExternalServiceConfigurationsQuery(
                  orgSlug!,
                  configuration.app,
                  true,
                ),
              );

              refetch();

              return `The ${configuration.app.toLowerCase()} integration was successfully removed from the workspace`;
            },
            error: (_err: unknown) => {
              return `Something went wrong removing the ${configuration.app.toLowerCase()} integration, please try again later`;
            },
          },
        );
      } catch (err: unknown) {}
    },
    [
      client,
      createConfirmRemoveIntegrationDeferred,
      getAccessTokenSilently,
      orgSlug,
      refetch,
    ],
  );

  if (loading) {
    return <Spinner />;
  }

  if (!allConfigurations.length) {
    return <Text>There are no configured integrations.</Text>;
  }

  return (
    <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
      <div className={settingsSectionClass}>
        <h2 className={settingsSubHeadClass}>Workspace Integrations</h2>

        <div className={settingsIndentedControlClass}>
          <Text
            className={
              isDark
                ? mergeStyles(settingsSectionDescriptionClassDark as IStyle)
                : mergeStyles(settingsSectionDescriptionClass as IStyle)
            }
          >
            The following third-party services have been integrated into your
            workspace by its Members. You can remove these integrations or
            configure them as needed.
          </Text>

          {appsWithConfigurations.flatMap((app) => {
            return configurationsByApp[app].map((configuration) => {
              switch (app) {
                case 'HUBSPOT': {
                  return (
                    <ManageIntegrationTile
                      key={configuration.id}
                      configurationId={configuration.id}
                      iconHeight="32px"
                      iconWidth="32px"
                      iconAlt="HubSpot"
                      title="HubSpot"
                      instanceId={configuration.instanceId}
                      iconSrc={hubspotIcon}
                      userTokenCount={
                        configuration._count?.externalServiceUserTokens
                      }
                      hasToken={
                        !!configurationById(configuration.id)
                          ?.externalServiceUserTokens?.length
                      }
                      showConfigure={hasEntitlement('HUBSPOT_CUSTOM_FIELDS')}
                      onConfigure={async () => {
                        navigate(
                          `/organization/${orgSlug}/configuration/hubspot/${configuration.id}/deals`,
                        );
                      }}
                      onRemove={async () => removeIntegration(configuration)}
                    />
                  );
                }
                case 'SALESFORCE': {
                  return (
                    <ManageIntegrationTile
                      key={configuration.id}
                      configurationId={configuration.id}
                      iconHeight="32px"
                      iconWidth="47px"
                      iconAlt="Salesforce"
                      title="Salesforce"
                      instanceId={configuration.instanceId}
                      iconSrc={salesforceIcon}
                      userTokenCount={
                        configuration._count?.externalServiceUserTokens
                      }
                      hasToken={
                        !!configurationById(configuration.id)
                          ?.externalServiceUserTokens?.length
                      }
                      showConfigure={hasEntitlement('SALESFORCE_CUSTOM_FIELDS')}
                      onConfigure={async () => {
                        navigate(
                          `/organization/${orgSlug}/configuration/salesforce/${configuration.id}/Opportunity`,
                        );
                      }}
                      onRemove={async () => removeIntegration(configuration)}
                    />
                  );
                }
                case 'SLACK': {
                  return (
                    <ManageIntegrationTile
                      key={configuration.id}
                      configurationId={configuration.id}
                      iconHeight="32px"
                      iconWidth="32px"
                      iconAlt="Slack"
                      title="Slack"
                      instanceId={configuration.instanceId}
                      iconSrc={slackIcon}
                      userTokenCount={
                        configuration._count?.externalServiceUserTokens
                      }
                      onRemove={async () => removeIntegration(configuration)}
                    />
                  );
                }
                default: {
                  return null;
                }
              }
            });
          })}
        </div>
      </div>
      {confirmRemoveIntegrationDialog}
    </Stack>
  );
};
