import { useAuth0 } from '@auth0/auth0-react';
import { Spinner, mergeStyles, Stack } from '@fluentui/react';
import {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Slate } from 'slate-react';
import { CustomEditable } from '../../Collab/CustomEditable';
import { getCoreEditor } from '../../Collab/Helpers/EditorHelpers';
import { useLinkDialog } from '../../../Hooks/Modals/useLinkDialog';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { DealRoomCommandBarButton } from '../Components/DealRoomButton';
import ConversationsElement from '../Components/ConversationsTab/ConversationsElement';
import {
  DealRoomComment,
  SlateElement,
} from '@meetingflow/common/Api/data-contracts';
import { DateTime, DurationObjectUnits } from 'luxon';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { AsyncPrimaryButton } from '../../HOC/AsyncButton';
import {
  AtMentionsIcon,
  ConvSendReplyIcon,
  LinkIcon,
  ReplyRecordIcon,
} from '../Components/ConversationsTab/ConvReplyIllustrations';
import { AxiosResponse } from 'axios';
import { activeChords } from '../../../Helpers/KeyboardEventHelpers';
import { isIOS, isMacOs } from 'react-device-detect';
const isMacOrIOS = isMacOs || isIOS;

type DealRoomArtifactCommentsPanelProps = {
  organizationSlug: string;
  dealRoomId: number;
  artifactId: number;
  currentThreadId: number | undefined;
  setCurrentThreadId: Function;
};

interface DealRoomConvWithComments extends DealRoomComment {
  allReplys: DealRoomComment[] | undefined;
  notifications: number | undefined;
}

const defaultValueCurrentComment: SlateElement[] = [
  { type: 'paragraph', children: [{ text: '' }] },
];

export const DealRoomArtifactCommentsPanel = forwardRef(
  (
    {
      organizationSlug,
      dealRoomId,
      artifactId,
      currentThreadId,
      setCurrentThreadId,
    }: DealRoomArtifactCommentsPanelProps,
    ref,
  ) => {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    const { createDeferred: createLinkDialogDeferred, dialog: linkDialog } =
      useLinkDialog();

    const currentComment: MutableRefObject<SlateElement[]> = useRef([
      ...defaultValueCurrentComment,
    ]);

    const [inProgress, setInProgress] = useState<boolean>(false);

    const convListRef = useRef<HTMLDivElement>(null);
    const selectedConvRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      backToConvList() {
        if (selectedConvRef?.current) {
          selectedConvRef.current.style.transition = 'transform 0.3s ease';
          selectedConvRef.current.style.transform = 'translateX(100%)';
        }
        setTimeout(() => {
          setCurrentThreadId(undefined);
        }, 300);
      },
    }));

    useEffect(() => {
      if (currentThreadId === undefined) {
        currentComment.current = defaultValueCurrentComment;
      }
    }, [currentThreadId]);

    const {
      data: artifactComments,
      isLoading: artifactCommentsLoading,
      isFetched: artifactCommentsFetched,
    } = useQuery(
      ['artifactComments', organizationSlug, dealRoomId, artifactId],
      async () => {
        const token = await getAccessTokenSilently();
        return await DealRoomsApiClient.listArtifactComments(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
      },
      {
        enabled: !!organizationSlug && !!dealRoomId && !!artifactId,
      },
    );
    const commentEditor = useMemo(() => {
      return getCoreEditor(
        getAccessTokenSilently,
        organizationSlug,
        createLinkDialogDeferred,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      createLinkDialogDeferred,
      getAccessTokenSilently,
      organizationSlug,
      currentThreadId,
      artifactComments,
    ]);

    const sendReply = async () => {
      setInProgress(true);
      createArtifactConv(currentThreadId === 0 ? null : currentThreadId).then(
        () => {
          currentComment.current = defaultValueCurrentComment;
          setInProgress(false);
        },
      );
    };

    const createArtifactConv = async (threadId?: number | null) => {
      if (threadId === 0) {
        openConv(threadId).then();
        return;
      }
      if (
        JSON.stringify(currentComment.current) ===
        JSON.stringify(defaultValueCurrentComment)
      )
        return;
      const token = await getAccessTokenSilently();
      const createdConv: AxiosResponse<DealRoomComment> =
        await DealRoomsApiClient.createArtifactComment(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            comment: currentComment.current,
            ...(threadId ? { threadId } : {}),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

      await queryClient.invalidateQueries({
        queryKey: [
          'artifactComments',
          organizationSlug,
          dealRoomId,
          artifactId,
        ],
      });
      if (threadId === null && createdConv?.data?.id) {
        setCurrentThreadId(createdConv.data.id);
      }
    };

    const openConv = async (convId: number) => {
      if (convListRef?.current) {
        convListRef.current.style.transition = 'transform 0.3s ease';
        convListRef.current.style.transform = 'translateX(-100%)';
      }
      setTimeout(() => {
        setCurrentThreadId(convId);
      }, 300);
    };

    // const deleteArtifactConv = async (convId: number) => {
    //   const token = await getAccessTokenSilently();
    //   await DealRoomsApiClient.deleteArtifactComment(
    //     organizationSlug,
    //     dealRoomId,
    //     artifactId,
    //     convId,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     },
    //   );
    //   queryClient.invalidateQueries({
    //     queryKey: [
    //       'artifactComments',
    //       organizationSlug,
    //       dealRoomId,
    //       artifactId,
    //     ],
    //   });
    // };

    // const onKeyDown = useCallback(
    //   (event: React.KeyboardEvent<HTMLDivElement>) => {
    //     const { ctrlChord, cmdChord } = activeChords(event);
    //
    //     if (
    //       ((isMacOrIOS && cmdChord) || (!isMacOrIOS && ctrlChord)) &&
    //       event.key === 'a'
    //     ) {
    //       event.preventDefault();
    //       event.stopPropagation();
    //
    //       Transforms.select(editor, []);
    //
    //       return true;
    //     }
    //
    //     // const mentionHandled = mentionKeyDownHandler(event);
    //     // if (mentionHandled) {
    //     //   return true;
    //     // }
    //   },
    //   [editor],
    // );

    const convWrapper = mergeStyles({
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    });

    const conversationsContainerStyles = mergeStyles({
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      '.addConvContainer': {
        display: 'flex',
        flexFlow: 'column',
        padding: '2rem 1.5rem 1rem 1.5rem',
      },
      '.addConvButton': {},
      '.conversationsList': {
        display: 'flex',
        padding: '0.5rem 1.5rem 1.5rem 1.5rem',
        gap: '0.5rem',
        overflow: 'scroll',
      },
    });

    const selectedConvContainerStyles = mergeStyles({
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      '.commentsList': {
        padding: '2rem 1.5rem',
        overflow: 'scroll',
        flexGrow: '1',
      },
      '.writeCommentContainer': {
        borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
        padding: '1.5rem',
        '.writeCommentContainer2': {
          border: `1px solid ${DEALROOMS_COLORS.neutralDark}`,
          borderRadius: '0.25rem',
          padding: '1rem',
          '.writeCommentControls': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            '.convButs': {
              padding: '0.5rem',
              margin: '0',
              minHeight: 'fit-content',
              minWidth: 'fit-content',
              backgroundColor: DEALROOMS_COLORS.white,
              color: DEALROOMS_COLORS.darkerGray,
              border: 'none',
              borderRadius: '0.5rem',
              ':hover': {
                backgroundColor: DEALROOMS_COLORS.neutralLighter,
                border: 'none',
                color: DEALROOMS_COLORS.darkerGray,
              },
              ':focus': {
                outline: '1px solid ' + DEALROOMS_COLORS.neutralSecondary,
              },
              '.disabled': {
                color: DEALROOMS_COLORS.lighterGray,
              },
            },
          },
        },
      },
    });

    const timeAgo = (
      updatedAt: string,
      isNotification?: boolean | undefined,
    ) => {
      const timeDiff: DurationObjectUnits = DateTime.now()
        .diff(DateTime.fromISO(updatedAt), [
          'years',
          'months',
          'days',
          'hours',
          'minutes',
        ])
        .toObject();
      if (!timeDiff) return '';
      switch (true) {
        case !!timeDiff.years:
          if (isNotification) return '';
          return timeDiff.years + ' years ago';
        case !!timeDiff.months:
          if (isNotification) return '';
          return timeDiff.months + ' months ago';
        case !!timeDiff.days:
          if (isNotification) return '';
          return timeDiff.days + ' days ago';
        case !!timeDiff.hours:
          if (isNotification) return '';
          return timeDiff.hours + ' hours ago';
        case !!timeDiff.minutes:
          if (isNotification && timeDiff.minutes > 5) return '';
          return Math.ceil(timeDiff.minutes) + ' min ago';
        default:
          return '';
      }
    };

    const convList: DealRoomComment[] | [] =
      artifactComments?.data?.filter(
        (conversation: DealRoomComment) =>
          !conversation.threadId && !conversation.replyToId,
      ) || [];

    const convListWithComments: DealRoomConvWithComments[] | [] = convList.map(
      (conv: DealRoomComment) => {
        let notifications = 0;
        const allReplys = artifactComments?.data?.filter(
          (origConv: DealRoomComment) => origConv.threadId === conv.id,
        );
        allReplys?.forEach((convReply) => {
          if (timeAgo(convReply.updatedAt, true)) notifications++;
        });
        return {
          ...conv,
          allReplys,
          notifications,
        };
      },
    );

    const currentConv: DealRoomConvWithComments | undefined =
      convListWithComments?.find(
        (conversation: DealRoomConvWithComments) =>
          conversation?.id === currentThreadId,
      );

    const slateComp = (conversation: DealRoomComment | null) => (
      <Slate
        editor={getCoreEditor(
          getAccessTokenSilently,
          organizationSlug,
          createLinkDialogDeferred,
        )}
        // @ts-ignore
        initialValue={conversation?.comment}
      >
        <CustomEditable
          organizationSlug={organizationSlug}
          name={`ArtifactConversation_#${conversation?.id}`}
          editorMaxHeight={'fit-content'}
          onKeyDown={(event) => true}
          readonly={true}
          simpleTextRenderer={true}
          conversationsMode={false}
        />
      </Slate>
    );

    const slateCompEditable = () => (
      <Slate
        editor={commentEditor}
        initialValue={currentComment?.current}
        // @ts-ignore
        onChange={(v: SlateElement[]) => {
          currentComment.current = v;
        }}
      >
        <CustomEditable
          organizationSlug={organizationSlug}
          name={`ArtifactConversation_#${currentConv?.id || 'editable'}`}
          editorMaxHeight={'fit-content'}
          onKeyDown={(event) => true}
          readonly={false}
          simpleTextRenderer={true}
          conversationsMode={true}
        />
      </Slate>
    );

    return (
      <div className={convWrapper}>
        {currentThreadId === undefined && (
          <div ref={convListRef} className={conversationsContainerStyles}>
            <div className="addConvContainer">
              <DealRoomCommandBarButton
                customClasses="addConvButton"
                buttonStyleType="COMMAND_BAR"
                iconProps={{ iconName: 'CirclePlus' }}
                onClick={(event) => {
                  // event.preventDefault();
                  event.stopPropagation();
                  return createArtifactConv(0);
                }}
              >
                New conversation
              </DealRoomCommandBarButton>
            </div>
            <Stack className="conversationsList">
              {artifactCommentsLoading && !artifactCommentsFetched ? (
                <Spinner />
              ) : (
                convListWithComments.map(
                  (conversation: DealRoomConvWithComments) => {
                    return (
                      <ConversationsElement
                        key={conversation.id}
                        conversation={conversation}
                        convClick={openConv}
                        // convDelete={deleteArtifactConv}
                        timeAgo={timeAgo(
                          conversation?.allReplys &&
                            conversation?.allReplys.length
                            ? conversation.allReplys[
                                conversation.allReplys.length - 1
                              ].updatedAt
                            : conversation.updatedAt,
                        )}
                        timeAgoNotification={timeAgo(
                          conversation?.allReplys &&
                            conversation?.allReplys.length
                            ? conversation.allReplys[
                                conversation.allReplys.length - 1
                              ].updatedAt
                            : conversation.updatedAt,
                          true,
                        )}
                        notifications={conversation.notifications}
                      >
                        {slateComp(conversation)}
                      </ConversationsElement>
                    );
                  },
                )
              )}
            </Stack>
          </div>
        )}
        {currentThreadId !== undefined && (
          <div ref={selectedConvRef} className={selectedConvContainerStyles}>
            <Stack className="commentsList">
              {currentConv?.comment && (
                <>
                  <ConversationsElement
                    key={currentConv.id}
                    isComment={true}
                    conversation={currentConv}
                    timeAgo={timeAgo(currentConv.updatedAt)}
                  >
                    {slateComp(currentConv)}
                  </ConversationsElement>
                  {currentConv?.allReplys?.map(
                    (conversation: DealRoomComment, convIndex: number) => (
                      <ConversationsElement
                        key={`${conversation.id}_${convIndex}`}
                        isComment={true}
                        conversation={conversation}
                        timeAgo={timeAgo(conversation.updatedAt)}
                      >
                        {slateComp(conversation)}
                      </ConversationsElement>
                    ),
                  )}
                </>
              )}
            </Stack>
            <div className="writeCommentContainer">
              <div className="writeCommentContainer2">
                {!inProgress && slateCompEditable()}
                {inProgress && <div style={{ height: '2rem' }} />}
                <div className="writeCommentControls">
                  <div>
                    {/*<AsyncPrimaryButton
                        className="convButs"
                        onClick={async () => {}}
                      >
                        <ReplyRecordIcon />
                      </AsyncPrimaryButton>
                      <AsyncPrimaryButton
                        className="convButs"
                        onClick={async () => {}}
                      >
                        <LinkIcon />
                      </AsyncPrimaryButton>
                      <AsyncPrimaryButton
                        className="convButs"
                        onClick={async () => {}}
                      >
                        <AtMentionsIcon />
                      </AsyncPrimaryButton>*/}
                  </div>
                  <AsyncPrimaryButton
                    className="convButs"
                    onClick={sendReply}
                    disabled={inProgress}
                  >
                    <ConvSendReplyIcon
                      color={
                        inProgress ? DEALROOMS_COLORS.lighterGray : undefined
                      }
                    />
                  </AsyncPrimaryButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);
