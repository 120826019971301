import {
  FontIcon,
  Icon,
  Pivot,
  PivotItem,
  Text,
  getTheme,
  mergeStyleSets,
  mergeStyles,
} from '@fluentui/react';
import {
  DealRoom,
  DetailedDealRoom,
  Organization,
} from '@meetingflow/common/Api/data-contracts';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { AsyncDefaultButton, AsyncIconButton } from '../../../Components/HOC/AsyncButton';

type BUYER_JOURNEY_PIVOT_KEY =
  | 'overview'
  | 'calendar';

type DealRoomBuyersTimelineTabProps = {
  organizationSlug: Organization['slug'];
  dealRoomId: DealRoom['id'];
  dealRoom: DetailedDealRoom;
};

const year = 2024;
const month = 8;
const firstDayOfMonth = new Date(year, month, 1);
const lastDayOfMonth = new Date(year, month + 1, 0);

// Calculate the number of days in the month
const daysInMonth = lastDayOfMonth.getDate();

// Calculate the day of the week for the first day of the month
const startDay = firstDayOfMonth.getDay(); // 0 (Sunday) to 6 (Saturday)

// Create an array of day numbers, with empty slots for days before the start of the month
const daysArray = Array(startDay).fill(null).concat(
  Array.from({ length: daysInMonth }, (_, i) => i + 1)
);

const theme = getTheme();

export const DealRoomBuyersTimelineTab = ({
  organizationSlug,
  dealRoomId,
}: DealRoomBuyersTimelineTabProps) => {

  const calendarStyleSets = mergeStyleSets({
    header: [
      theme.fonts.xLarge,
      {
        display: 'grid',
        gridTemplateColumns: 'auto max-content 1fr auto',
        gridTemplateRows: 'auto auto',
        gridTemplateAreas: `
          "back title none actions"
          "back description description description"
        `,

        '.back': {
          gridArea: 'back',
        },

        '.title': {
          gridArea: 'title',
        },

        '.description': {
          gridArea: 'description',
        },

        '.close': {
          gridArea: 'close',
        },

        '.actions': {
          gridArea: 'actions',
          justifySelf: 'end',
          display: 'flex',
          flexDirection: 'row',
        },
      },
    ],
    content: [
      theme.fonts.medium,
      {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        overflow: 'hidden',

        flex: '1 1 auto',
        alignContent: 'center',
        justifyContent: 'center',

        '.audio-artifact-preview': {
          width: '100%',
        },

        '.image-artifact-preview': {
          maxWidth: '100%',
          maxHeight: '100%',
        },

        '.video-artifact-preview': {
          width: '100%',
        },

        '.document-artifact-preview': {
          width: '100%',
          height: '100%',
        },

        '.link-artifact-preview': {
          width: '100%',
          height: '100%',
        },
      },
    ],
    chat: {
      flex: '0 0 300px',
      borderLeft: '1px solid #ccc',
      padding: '20px',
    },
  });

  const styleSets = mergeStyleSets({
    header: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gridTemplateRows: 'auto auto',
      gridTemplateAreas: `
        "title actions"
        "subtitle actions"
      `,

      '.title': {
        gridArea: 'title',
      },

      '.subtitle': {
        gridArea: 'subtitle',
      },

      '.actions': {
        gridArea: 'actions',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '1rem',
      },
    },

    other: {
      '.gridItem': {
        gridColumn: 2 / 7,
      }
    }
  });
  
  const styles = mergeStyles({
    '.journeyGrid': {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gridTemplateRows: 'repeat(24, 1fr)',
      gridColumnGap: '0px',
      gridRowGap: '0px'
    },

    '.title': {
      gridArea: 'title',
    },

    '.description': {
      gridArea: 'description',
    },

    

    //   '.timeline': {
    //     gridArea: 'timeline',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     gap: '1rem',
    //   },
    // },

  });

  const [activeTab, setActiveTab] = useState<BUYER_JOURNEY_PIVOT_KEY>('overview');
  const [now, setNow] = useState(DateTime.now());

  const weekStart = useMemo(() => now.startOf('week'), [now]);
  const weekEnd = useMemo(() => now.endOf('week'), [now]);
  const lastWeek = useMemo(() => now.minus({ weeks: 1 }), [now]);
  const nextWeek = useMemo(() => now.plus({ weeks: 1 }), [now]);

  return (
    <div>
      <div style={{ margin: '50px' }}/>

      <div className={styleSets.header}>
        
          <div>
            <Text className="title" variant="xLarge" block>
              Your journey
            </Text>

            <Text className="subtitle" variant="medium" block>
              Revisit your journey and meaningful events with us
            </Text>
          </div>

          <div className="actions">
            <Pivot
              selectedKey={activeTab}
              onLinkClick={(i) => {
                setActiveTab(
                  (i?.props?.itemKey as BUYER_JOURNEY_PIVOT_KEY | undefined) ||
                    'overview',
                );
              }}
            >
              <PivotItem headerText="Overview" itemKey="overview"/>
              <PivotItem headerText="Calendar" itemKey="calendar"/>
            </Pivot>
          </div>

      </div>

      <div style={{ margin: '50px' }}/>
      
      {activeTab === 'calendar' && (
        <div style={{ background: 'lightgray', padding: '20px'}}>
          {/* <div style={{ margin: '40px', display: 'flex', flexDirection: 'row',}}> */}
          <div className={calendarStyleSets.header}>
          <h2 className="text-center">{now.toFormat("yyyy mm dd LLL")}</h2>
            <div style={{justifySelf: 'flex-begin'}}>{now.toISODate()}</div>
            <div className="actions">
              <AsyncDefaultButton text="Show today" style={{verticalAlign: 'middle', color: 'black', marginBottom: '10px'}} onClick={async () => {setNow(DateTime.now())}}/>
              <AsyncIconButton text="Last Week" iconProps={{ iconName: "ChevronLeftMed" }} style={{verticalAlign: 'middle', color: 'black', marginBottom: '5px'}} onClick={async () => {setNow(lastWeek)}}/>
              <AsyncIconButton text="Calendar" iconProps={{ iconName: "Calendar" }} style={{verticalAlign: 'middle', color: 'black', marginBottom: '5px'}} onClick={async () => {}}/>
              <AsyncIconButton text="Next Week" iconProps={{ iconName: "ChevronRightMed" }} style={{verticalAlign: 'middle', color: 'black', marginBottom: '5px'}} onClick={async () => {setNow(nextWeek)}}/>
            </div>
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)', gap: '1px' }}>

            {/* Render day headers */}
            <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', color: 'black', backgroundColor: '#f9f9f9', border: '1px solid #ddd', }}>{/* Empty cell */}</div>
            <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', color: 'black', backgroundColor: '#f9f9f9', border: '1px solid #ddd', }}><div>Sun</div><div>Aug-25</div></div>
            <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', color: 'black', backgroundColor: '#f9f9f9', border: '1px solid #ddd', }}><div>Mon</div><div>Aug-26</div></div>
            <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', color: 'black', backgroundColor: '#f9f9f9', border: '1px solid #ddd', }}><div>Tue</div><div>Aug-27</div></div>
            <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', color: 'black', backgroundColor: '#f9f9f9', border: '1px solid #ddd', }}><div>Wed</div><div>Aug-28</div></div>
            <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', color: 'black', backgroundColor: '#f9f9f9', border: '1px solid #ddd', }}><div>Thu</div><div>Aug-29</div></div>
            <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', color: 'black', backgroundColor: '#f9f9f9', border: '1px solid #ddd', }}><div>Fri</div><div>Aug-30</div></div>
            <div style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', color: 'black', backgroundColor: '#f9f9f9', border: '1px solid #ddd', }}><div>Sat</div><div>Aug-31</div></div>

            {/* Fill empty cells for the last row if necessary */}
            {Array(24 + 24 * 7).fill(null).map((_, index) => (
                <div
                  key={index}
                  style={{
                    padding: '10px',
                    border: '1px solid #ddd',
                    textAlign: 'center',
                    backgroundColor: index % 8 == 0 ? '#f9f9f9' : '#fff',
                    color: index % 8 == 0 ? 'black' : '#ccc',
                  }}
                >
                  { index % 8 == 0 ? index / 8 : index/* Empty cell */}
                </div>
              ))
            }
          </div>
        </div>
      )}

    </div>
  );
};
