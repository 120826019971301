import { useEffect, useRef, useState } from 'react';
import { PathPattern, useMatch } from 'react-router';

export const useRouteChange = <Path extends string>(
  pattern: PathPattern<Path> | Path,
  onMatchChange: (direction: 'to' | 'from') => void,
): void => {
  const callbackRef = useRef(onMatchChange);
  const [wasMatch, setWasMatch] = useState<boolean | undefined>(undefined);
  const match = !!useMatch(pattern);

  useEffect(() => {
    if (wasMatch === match) {
      return;
    }
    if (wasMatch === undefined) {
      setWasMatch(match);
    } else {
      if (!wasMatch && match) {
        callbackRef?.current?.('to');
      } else if (wasMatch && !match) {
        callbackRef.current?.('from');
      }
      setWasMatch(match);
    }
  }, [match, wasMatch]);
};
