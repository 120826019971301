import { useAuth0 } from '@auth0/auth0-react';
import {
  DirectionalHint,
  Dropdown,
  FontSizes,
  FontWeights,
  IColumn,
  IconButton,
  IDropdownOption,
  IDropdownStyles,
  mergeStyles,
  NeutralColors,
  SearchBox,
  SelectionMode,
  Text,
  Toggle,
  TooltipHost,
} from '@fluentui/react';
import { Contact } from '@meetingflow/common/Api/data-contracts';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import { EMPTY_ARRAY } from '../../../Constants';
import { titleCase } from '../../../Helpers/Typography';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../../../Hooks/useLocalStorageState';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useTitle } from '../../../Hooks/useTitle';
import { OrganizationContactsQueryInfinite } from '../../../QueryNames';
import { ContactsApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { ContactCard } from '../../MeetingPlans/MeetingPlanAttendees';
import { StyledDetailsList } from '../../StyledDetailsList';

type AvailableColumns =
  | 'event'
  | 'organizer'
  | 'creator'
  | 'attendees'
  | 'recurring'
  | 'status'
  | 'delete';

interface ContactListProps {
  columns?: AvailableColumns[];
  limit?: number;
  displayFiltering?: boolean;
  allowScrolling?: boolean;
  filterByNameQuery?: string;
  onSelectContact?: (contact: Contact['id']) => void;
  onListChange?: (contacts: Contact[] | undefined) => void;
  activeContactId?: string | number | undefined;
}

export const ContactList = ({
  columns,
  limit = 30,
  displayFiltering = true,
  allowScrolling = true,
  filterByNameQuery = '',
  onSelectContact,
  onListChange,
  activeContactId,
}: ContactListProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { slug: organizationSlug } = useOrganization();

  const [nameQuery, setNameQuery] = useState<string>(filterByNameQuery);
  const { isDark } = useLightOrDarkMode();

  useTitle('Contacts - Library');

  const [controlsVisible, setControlsVisible] = useState<boolean>(false);
  // my:
  //   true: show only contacts active user has met with
  //   false: show all contacts
  const [myFilter, setMyFilter] = useLocalStorageState<boolean>(
    `${organizationSlug}_CONTACTS_MY`,
    true,
  );
  // includeInternal:
  //   true: includes contacts internal to active org (sends undefined to API)
  //   false: show only contacts external to active org (sends false to API)
  const [includeInternalFilter, setIncludeInternalFilter] =
    useLocalStorageState<boolean>(
      `${organizationSlug}_CONTACTS_INCLUDE_INTERNAL`,
      false,
    );

  const [sortBy, setSortBy] = useLocalStorageState<
    | 'name'
    | 'email'
    | 'createdAt'
    | 'timesMet30d'
    | 'timesMet90d'
    | 'lastMeetingDate'
    | 'nextMeetingDate'
  >(`${organizationSlug}_CONTACTS_SORT_BY`, 'lastMeetingDate');

  const [sortOrder, setSortOrder] = useLocalStorageState<'asc' | 'desc'>(
    `${organizationSlug}_CONTACTS_SORT_ORDER`,
    'asc',
  );

  const parentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { ref, inView } = useInView({ root: parentRef.current });
  const {
    data: contactsData,
    isLoading: contactsLoading,
    isFetching: contactsFetching,
    isRefetching: contactsRefetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    OrganizationContactsQueryInfinite(organizationSlug!, true),
    async ({ pageParam }) => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.listContacts(
        {
          organizationSlug: organizationSlug!,
          limit,
          skip: pageParam || 0,
          name: nameQuery !== '' ? nameQuery : undefined,
          my: myFilter,
          hasPlans: true,
          isInternal: includeInternalFilter ? undefined : false,
          sortBy,
          sortOrder,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      getPreviousPageParam: (previousPage) => {
        if (
          !previousPage?.config?.params?.skip ||
          previousPage.config.params.skip < limit
        ) {
          return undefined;
        }
        return previousPage.config.params.skip - limit;
      },
      getNextPageParam: (previousPage) => {
        if (!previousPage?.data?.length || previousPage.data.length < limit) {
          return undefined;
        }

        return previousPage?.config?.params?.skip
          ? previousPage.config.params.skip + limit
          : limit;
      },
    },
  );

  // Fetch next page when infinite scroll button is in view
  useEffect(() => {
    if (inView && allowScrolling && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  // Send the contact array back to the parent component, if onListChange exists
  useEffect(() => {
    onListChange && onListChange(contactsData?.pages?.[0]?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsData]);

  // Refetch query when search term changes
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameQuery, myFilter, includeInternalFilter, sortBy, sortOrder]);

  const controlsClass = mergeStyles({
    position: 'sticky',
    top: '0',
    zIndex: 50,
    padding: '.25rem',
    backgroundColor: isDark
      ? NeutralColors.gray200
      : MEETINGFLOW_COLORS.purpleUltraSuperLight,
    boxShadow: '0 0 5px rgba(0,0,0,.15)',
    width: 'cal(100% - .5rem)',

    '.controls-toggle, .my-toggle': {
      display: 'inline-block',
      transition: '.3s ease-in-out all',
      color: isDark
        ? controlsVisible
          ? NeutralColors.white
          : NeutralColors.gray120
        : MEETINGFLOW_COLORS.purpleDark,
      padding: '0',
      width: '28px',
      height: '32px',

      ':hover': {
        backgroundColor: isDark
          ? NeutralColors.gray220
          : MEETINGFLOW_COLORS.purpleGrey,
      },
    },

    '.my-toggle': {
      backgroundColor: myFilter ? MEETINGFLOW_COLORS.purpleDark : 'transparent',
      color: myFilter
        ? MEETINGFLOW_COLORS.white
        : isDark
          ? NeutralColors.gray120
          : MEETINGFLOW_COLORS.purpleLight,

      ':hover': {
        backgroundColor: MEETINGFLOW_COLORS.purpleDark,
        color: 'white',
      },
    },

    '.controls-toggle': {
      borderBottom: controlsVisible
        ? `.25rem solid ${
            isDark ? NeutralColors.gray220 : MEETINGFLOW_COLORS.purpleGrey
          }`
        : undefined,
      height: controlsVisible ? '38px' : '32px',
      position: 'absolute',
      top: '.25rem',
      right: '.25rem',
      backgroundColor: controlsVisible
        ? isDark
          ? NeutralColors.gray220
          : MEETINGFLOW_COLORS.purpleGrey
        : 'transparent',
    },

    '.controls-container': {
      transition: '.3s ease-in-out all',
      overflow: 'hidden',
      height: controlsVisible ? 'auto' : '0',
      maxHeight: controlsVisible ? '10rem' : '0',
      backgroundColor: isDark
        ? NeutralColors.gray220
        : MEETINGFLOW_COLORS.purpleGrey,
      marginTop: '.25rem',
      borderBottomRightRadius: '.25rem',
      borderBottomLeftRadius: '.25rem',
      borderTopLeftRadius: '.25rem',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
      'sort sort'
      'includeInternal myFilter'
      `,
      columnGap: '.25rem',
      rowGap: '.25rem',
      padding: controlsVisible ? '.25rem' : '0 .25rem',
    },
  });

  const searchBoxStyles = {
    root: {
      borderRadius: '1rem',
      outline: 'none !important',
      padding: '0 .25rem',
      marginRight: '4rem',
      ':after': {
        display: 'none',
      },
    },
    clearButton: {
      button: { backgroundColor: 'transparent !important' },
    },
  };

  const filterTooltipStyles = {
    root: {
      display: 'block',
    },
  };

  const filterToggleStyles = {
    root: {
      display: 'inline-block',
      lineHeight: '.75rem',
      margin: 0,
      width: '100%',
      transition: '.3s ease-in-out all',

      ':hover .ms-Toggle-thumb': {
        backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
      },

      '[aria-checked=true]': {
        '.ms-Toggle-thumb': {
          backgroundColor: MEETINGFLOW_COLORS.teal,
        },
      },
      '.ms-Toggle-thumb': {
        transition: '.3s ease-in-out all',
        backgroundColor: NeutralColors.gray80,
      },
      'button:disabled .ms-Toggle-thumb': {
        backgroundColor: NeutralColors.gray70,
      },
      'button:hover': {
        backgroundColor: isDark ? NeutralColors.gray160 : NeutralColors.white,
      },
    },
    label: {
      margin: '0 .5rem 0 0',
      padding: 0,
      fontSize: FontSizes.mini,
      color: NeutralColors.gray120,
    },
    container: {
      display: 'inline-block',
    },
    pill: {
      backgroundColor: isDark
        ? NeutralColors.gray180
        : MEETINGFLOW_COLORS.purpleUltraSuperLight,
      outlineWidth: `0 !important`,
      borderWidth: `0 !important`,
      transition: '.3s ease-in-out all',
    },
  };

  const includeInternalStyles = {
    ...filterToggleStyles,
    root: { ...filterToggleStyles.root, gridArea: 'includeInternal' },
  };

  const filterDropdownStyles: Partial<IDropdownStyles> = {
    root: {
      display: 'inline-grid',
      gridTemplateColumns: '3rem auto',
    },
    label: {
      fontSize: FontSizes.mini,
      display: 'inline-block',
      height: '24px',
      lineHeight: '22px',
      padding: 0,
      color: NeutralColors.gray120,
    },
    dropdown: {
      display: 'inline-block',
      fontSize: FontSizes.mini,
      height: '20px',
    },
    title: {
      fontSize: FontSizes.mini,
      height: '20px',
      lineHeight: '20px',
      border: 'none',
      backgroundColor: isDark
        ? NeutralColors.gray180
        : MEETINGFLOW_COLORS.purpleUltraSuperLight,
    },
    dropdownItem: {
      minHeight: '1.5rem',
      fontSize: FontSizes.mini,
      lineHeight: '20px',
      padding: '0 .25rem',
      margin: 0,
      height: '1.5rem',
      ':hover': {
        backgroundColor: isDark ? NeutralColors.gray150 : undefined,
      },
    },
    dropdownItemSelected: {
      minHeight: '1.5rem',
      fontSize: FontSizes.mini,
      lineHeight: '20px',
      padding: '0 .25rem',
      margin: 0,
      height: '1.5rem',
      backgroundColor: isDark ? NeutralColors.gray150 : undefined,
    },
    caretDownWrapper: {
      height: '20px',
      top: '-6px',
    },
    caretDown: {
      fontSize: FontSizes.mini,
    },
  };

  const sortByDropdownStyles: Partial<IDropdownStyles> = {
    ...filterDropdownStyles,
    root: {
      display: 'inline-grid',
      gridTemplateColumns: '2.5rem auto',
      paddingRight: '.25rem',
      width: 'calc(60% - .25rem)',
    },
  };

  const sortOrderDropdownStyles: Partial<IDropdownStyles> = {
    ...filterDropdownStyles,
    root: {
      display: 'inline-grid',
      gridTemplateColumns: '2rem auto',
      width: '40%',
    },
  };

  const filterTooltipCalloutProps = {
    backgroundColor: MEETINGFLOW_COLORS.teal,
    styles: {
      root: {
        padding: 0,
        minWidth: '12rem',
        maxWidth: '18rem',
        color: MEETINGFLOW_COLORS.white,
      },
      calloutMain: {
        padding: '.5rem',
        color: MEETINGFLOW_COLORS.white,
      },
    },
  };

  const filterSortDescriptionStyle = {
    padding: '.25rem',
    backgroundColor: isDark
      ? NeutralColors.gray200
      : MEETINGFLOW_COLORS.purpleLightest,
    fontSize: FontSizes.mini,
    textAlign: 'center' as const,
    color: isDark ? NeutralColors.gray120 : NeutralColors.gray90,
  };

  const sortByOptions: IDropdownOption[] = useMemo(
    () => [
      { key: 'name', text: 'Name' },
      { key: 'email', text: 'Email' },
      { key: 'timesMet30d', text: 'Times Met (30d)' },
      { key: 'timesMet90d', text: 'Times Met (90d)' },
      { key: 'lastMeetingDate', text: 'Last Meeting' },
      { key: 'nextMeetingDate', text: 'Next Meeting' },
    ],
    [],
  );

  const sortOrderOptions: IDropdownOption[] = [
    { key: 'asc', text: 'Ascending' },
    { key: 'desc', text: 'Descending' },
  ];

  const sortValueType = [
    'createdAt',
    'lastMeetingDate',
    'nextMeetingDate',
  ].includes(sortBy)
    ? 'DATE'
    : 'NUMBER';

  const contactsColumns: IColumn[] = useMemo(() => {
    const cols = [
      {
        key: 'name',
        name: 'Contact',
        fieldName: 'name',
        minWidth: 150,
        maxWidth: 230,
        headerClassName: `center-align ${
          sortBy === 'name' || sortBy === 'email'
            ? `sort-${sortBy} sort-order-${sortOrder}`
            : ''
        }`,
        onColumnClick: () => {
          if (sortBy !== 'name') {
            setSortBy('name');
          } else {
            if (sortOrder === 'asc') {
              setSortOrder('desc');
            } else if (sortOrder === 'desc') setSortOrder('asc');
          }
        },
        onRender: (contact: Contact) => {
          return organizationSlug ? (
            <ContactCard
              domain={contact.emailDomain}
              organizationSlug={organizationSlug}
              contact={contact}
              hideBottomBorder
              showAvatar
              onContactClick={onSelectContact}
              noMargin
              noPadding
              defaultActive={parseInt(`${activeContactId}`) === contact.id}
              hideSocialIcons
            />
          ) : (
            <></>
          );
        },
      },
    ] as IColumn[];

    if (sortBy !== 'name' && sortBy !== 'email') {
      cols.push({
        key: 'sort-value',
        className: 'center-align',
        headerClassName: `center-align sort-${sortBy} sort-order-${sortOrder}`,
        name: sortByOptions.find((o) => o.key === sortBy)?.text || '',
        minWidth: 80,
        fieldName: 'sort-value',
        onColumnClick: () => {
          if (sortOrder === 'asc') {
            setSortOrder('desc');
          } else if (sortOrder === 'desc') setSortOrder('asc');
        },
        onRender: (contact: Contact) => {
          // @ts-ignore
          const sortValue = contact?.[sortBy];
          const sortValueClass = mergeStyles({
            fontSize:
              sortValueType === 'DATE' ? FontSizes.small : FontSizes.xLarge,
            fontWeight: FontWeights.semibold,
            borderLeft: `1px solid ${
              isDark ? NeutralColors.gray170 : MEETINGFLOW_COLORS.purpleGrey
            }`,
            color: MEETINGFLOW_COLORS.teal,
            textAlign: 'center',
            width: '100%',
            height: '37px',
            lineHeight: '37px',
            padding: '0 .25rem',
          });
          return (
            <div className={sortValueClass}>
              {sortValueType === 'DATE'
                ? titleCase(
                    DateTime.fromISO(sortValue as string).toRelative({
                      style: 'short',
                    }) || '',
                  )
                : sortValue}
            </div>
          );
        },
      });
    }
    return cols;
  }, [
    organizationSlug,
    onSelectContact,
    activeContactId,
    sortBy,
    sortByOptions,
    sortValueType,
    isDark,
    sortOrder,
    setSortOrder,
    setSortBy,
  ]);

  return (
    <>
      <div className={controlsClass}>
        <SearchBox
          styles={searchBoxStyles}
          placeholder="Filter by name..."
          onChange={(e, value) => {
            setNameQuery(value || '');
          }}
          onSearch={(value) => {
            setNameQuery(value);
          }}
          onClear={() => {
            setNameQuery('');
          }}
        />

        <IconButton
          iconProps={{ iconName: 'FilterSettings' }}
          className="controls-toggle"
          onClick={() => setControlsVisible(!controlsVisible)}
        />

        <TooltipHost
          directionalHint={DirectionalHint.bottomRightEdge}
          styles={{
            root: {
              position: 'absolute',
              top: '.25rem',
              right: '2.25rem',
            },
          }}
          content={
            <>
              <Text
                variant="small"
                block
                style={{
                  fontWeight: FontWeights.semibold,
                  marginBottom: '.5rem',
                  color: MEETINGFLOW_COLORS.white,
                }}
              >
                Your Contacts Filter
              </Text>
              <Text
                variant="xSmall"
                block
                style={{
                  color: MEETINGFLOW_COLORS.white,
                }}
              >
                <strong>On:</strong> Shows only Contacts you have met with
              </Text>
              <Text
                variant="xSmall"
                style={{ color: MEETINGFLOW_COLORS.white }}
                block
              >
                <strong>Off:</strong> Shows all Contacts in your organization
              </Text>
            </>
          }
          calloutProps={filterTooltipCalloutProps}
        >
          <IconButton
            iconProps={{ iconName: myFilter ? 'ReminderPerson' : 'Contact' }}
            className="my-toggle"
            onClick={() => setMyFilter(!myFilter)}
          />
        </TooltipHost>

        <div className={'controls-container'}>
          <TooltipHost
            styles={includeInternalStyles}
            directionalHint={DirectionalHint.bottomLeftEdge}
            content={
              <>
                <Text
                  variant="small"
                  block
                  style={{
                    fontWeight: FontWeights.semibold,
                    marginBottom: '.5rem',
                    color: MEETINGFLOW_COLORS.white,
                  }}
                >
                  Include Internal Contacts
                </Text>
                <Text
                  variant="xSmall"
                  block
                  style={{
                    color: MEETINGFLOW_COLORS.white,
                  }}
                >
                  <strong>On:</strong> Includes contacts from your organization
                </Text>
                <Text
                  variant="xSmall"
                  style={{ color: MEETINGFLOW_COLORS.white }}
                  block
                >
                  <strong>Off:</strong> Filters out contacts from your
                  organization
                </Text>
              </>
            }
            calloutProps={filterTooltipCalloutProps}
          >
            <Toggle
              styles={filterToggleStyles}
              label="Include Internal"
              checked={includeInternalFilter}
              onChange={(_e, checked) => {
                setIncludeInternalFilter(!!checked);
              }}
              role="checkbox"
              className="toggle-my"
            />
          </TooltipHost>
          <TooltipHost
            directionalHint={DirectionalHint.bottomRightEdge}
            styles={filterTooltipStyles}
            content={
              <>
                <Text
                  variant="small"
                  block
                  style={{
                    fontWeight: FontWeights.semibold,
                    marginBottom: '.5rem',
                    color: MEETINGFLOW_COLORS.white,
                  }}
                >
                  Your Contacts Filter
                </Text>
                <Text
                  variant="xSmall"
                  block
                  style={{
                    color: MEETINGFLOW_COLORS.white,
                  }}
                >
                  <strong>On:</strong> Shows only Contacts you have met with
                </Text>
                <Text
                  variant="xSmall"
                  style={{ color: MEETINGFLOW_COLORS.white }}
                  block
                >
                  <strong>Off:</strong> Shows all Contacts in your organization
                </Text>
              </>
            }
            calloutProps={filterTooltipCalloutProps}
          >
            <Toggle
              styles={filterToggleStyles}
              label={'Show only yours'}
              checked={myFilter}
              onChange={(_e, checked) => {
                setMyFilter(checked ?? false);
              }}
              role="checkbox"
              className="toggle-my"
            />
          </TooltipHost>

          <div style={{ gridArea: 'sort' }}>
            <Dropdown
              label="Sort by"
              selectedKey={sortBy}
              options={sortByOptions}
              styles={sortByDropdownStyles}
              onChange={(event, option) =>
                option &&
                setSortBy(
                  option?.key?.toString() as
                    | 'name'
                    | 'createdAt'
                    | 'timesMet30d'
                    | 'timesMet90d'
                    | 'lastMeetingDate'
                    | 'nextMeetingDate',
                )
              }
            />

            <Dropdown
              label="Order"
              selectedKey={sortOrder}
              options={sortOrderOptions}
              styles={sortOrderDropdownStyles}
              onChange={(event, option) =>
                option &&
                setSortOrder(option?.key?.toString() as 'asc' | 'desc')
              }
            />
          </div>
        </div>
      </div>
      <div ref={parentRef}>
        <div style={filterSortDescriptionStyle}>
          {myFilter ? "Contacts you've met with" : 'All contacts'}
          {includeInternalFilter ? ' (except internal)' : ''}, by{' '}
          {sortByOptions.find((o) => o.key === sortBy)?.text.toLowerCase()} (
          {sortOrderOptions
            .find((o) => o.key === sortOrder)
            ?.text.toLowerCase()}
          )
        </div>
        {contactsData?.pages?.length ? (
          contactsData?.pages.map((page, index) => {
            const loading =
              !page?.data?.length &&
              (contactsLoading || contactsFetching || contactsRefetching);
            return (
              <StyledDetailsList
                key={index}
                enableShimmer={loading}
                shimmerLines={25}
                items={page?.data || EMPTY_ARRAY}
                selectionMode={SelectionMode.none}
                columns={contactsColumns}
                className="small-header"
                compact
              />
            );
          })
        ) : (
          <StyledDetailsList
            enableShimmer
            shimmerLines={25}
            items={EMPTY_ARRAY}
            selectionMode={SelectionMode.none}
            columns={contactsColumns}
            isHeaderVisible={false}
            compact
          />
        )}

        {allowScrolling && contactsData?.pages ? (
          <div ref={ref}>
            <button
              // ref={ref}
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
              style={{
                display: hasNextPage ? 'block' : 'none',
                margin: '0 auto',
              }}
            >
              {isFetchingNextPage
                ? 'Loading more...'
                : hasNextPage
                  ? 'Load More'
                  : 'Nothing more to load'}
            </button>
            <div>
              {contactsFetching && !isFetchingNextPage
                ? 'Background Updating...'
                : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ContactList;
