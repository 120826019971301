import {
  DefaultButton,
  mergeStyles,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import { useForceUpdate } from '@fluentui/react-hooks';
import { CreateDealRoomPayload } from '@meetingflow/common/Api/data-contracts';
import { useCallback, useState } from 'react';
import { ContactPicker } from '../../../Components/Common/ContactPicker';
import { ContactChip } from '../../../Components/ContactChip';
import { BaseModal } from '../../../Components/MeetingPlans/Dialogs/BaseModal';

const nameStyle = { root: { gridArea: 'name' } };
const descriptionStyle = { root: { gridArea: 'description' } };
const contactsStyle = {
  gridArea: 'contacts',
  display: 'flex',
  alignItems: 'center',
};
const cancelStyle = { gridArea: 'cancel', marginTop: '3rem' };
const createStyle = { gridArea: 'create', marginTop: '3rem' };

type NewDealRoomDialogProps = {
  organizationSlug: string;
  onCreate: (payload: CreateDealRoomPayload) => void;
  onDismiss: () => void;
};

export const NewDealRoomDialog = ({
  organizationSlug,
  onCreate,
  onDismiss,
}: NewDealRoomDialogProps) => {
  const forceUpdate = useForceUpdate();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [contacts, setContacts] = useState<CreateDealRoomPayload['contacts']>(
    [],
  );

  const modalStyles = mergeStyles({
    '.ms-Dialog-main': {
      width: '60%',
      height: 'auto',
      maxHeight: '60%',
      paddingBottom: '.5rem',
    },
  });

  const dialogContentStyle = mergeStyles({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr auto auto',
    gridTemplateRows: 'auto auto auto auto',
    gridTemplateAreas: `
    'name name name name'
    'description description description description'
    'contacts contacts contacts contacts'
    'none0 none1 cancel create'
    `,
    gridColumnGap: '0.5rem',
    gridRowGap: '0.5rem',
    padding: '1rem',
  });

  const contactsClassName = mergeStyles({
    gridArea: 'contacts',
  });

  const onCreateClick = useCallback(() => {
    if (!name) {
      return;
    }

    onCreate({
      name,
      description,
      contacts,
    });
  }, [contacts, description, name, onCreate]);

  const onPickContact = useCallback(async (email: string) => {
    setContacts((prev) => {
      if (prev.some((c) => c.email === email)) {
        return prev;
      }

      return [...prev, { email }];
    });

    return true;
  }, []);

  return (
    <BaseModal
      className={modalStyles}
      isOpen
      title="Create New Decision Site"
      onDismiss={onDismiss}
    >
      <div className={dialogContentStyle}>
        <TextField
          label="Name"
          value={name}
          onChange={(e, newValue) => {
            setName(newValue || '');
          }}
          styles={nameStyle}
        />

        <TextField
          label="Description"
          value={description}
          onChange={(e, newValue) => {
            setDescription(newValue || '');
            forceUpdate();
          }}
          styles={descriptionStyle}
        />

        <div style={contactsStyle}>
          {contacts.map((c) => (
            <ContactChip
              key={c.email}
              email={c.email || `${c.contactId}`}
              onDelete={() =>
                setContacts((prev) =>
                  prev.filter((contact) => contact.email !== c.email),
                )
              }
            />
          ))}

          <ContactPicker
            className={contactsClassName}
            id="new-dealroom-add-contact-picker"
            organizationSlug={organizationSlug}
            addLabel="Add Contact"
            focusOnEdit
            allowFreeform
            onPick={onPickContact}
          />
        </div>

        <DefaultButton style={cancelStyle} text="Cancel" onClick={onDismiss} />
        <PrimaryButton
          style={createStyle}
          text="Create"
          disabled={!name || !contacts.length}
          onClick={onCreateClick}
          label="Create"
        />
      </div>
    </BaseModal>
  );
};
