import { toNumber } from 'lodash';
import { DateTime } from 'luxon';
import { HubSpotDeal } from '../../Models/HubSpot/HubSpotDeal';
import { HubSpotUserDealStats } from '../../Models/HubSpot/HubSpotDealStats';
import RiskPhrases from '../RiskPhrases';
import { HubSpotFieldNameFieldMap } from './HubSpotFieldHelpers';
import {
  AgeInDays,
  DaysInStage,
  DaysSinceLastModified,
  DaysToCloseDate,
} from './HubSpotDealHelpers';

export type HubSpotDealInsightRule = (
  deal: HubSpotDeal,
  fieldNameFieldMap: HubSpotFieldNameFieldMap,
  orgOpportunityStats?: HubSpotUserDealStats,
) =>
  | {
    priority: number;
    name: string;
    insight: string;
    key: string;
    fieldName?: string;
  }
  | undefined;

export const closeDateInThePastRule = (deal: {
  properties: { closedate: string };
}) => {
  const age = DaysToCloseDate(deal);

  return age < 0
    ? {
      priority: 3,
      name: 'Past Close Date',
      insight: `Close date was ${Math.abs(age)} days ago`,
      key: 'closeDateInThePast',
      fieldName: 'closedate',
    }
    : undefined;
};

export const longTimeToClose = (
  deal: { properties: { createdate: string } },
  _fieldNameFieldMap: HubSpotFieldNameFieldMap,
  orgStats?: HubSpotUserDealStats,
) => {
  const age = AgeInDays(deal);

  if (!orgStats || !orgStats.overall?.DaysToClose) {
    return undefined;
  }

  return age > orgStats.overall.DaysToClose.upperQuartile
    ? {
      priority: 1,
      name: 'Old Deal',
      insight: `Opened ${age} days ago`,
      key: 'longTimeToClose',
      fieldName: 'createdate',
    }
    : undefined;
};

export const missingNextStepsRule = (deal: {
  properties: { hs_next_step: string | null };
}) => {
  return !deal.properties.hs_next_step?.trim()
    ? {
      priority: 1,
      name: 'Missing Next Step',
      insight: 'Missing next step',
      key: 'missingNextSteps',
      fieldName: 'hs_next_step',
    }
    : undefined;
};

export const missingAmountRule = (deal: {
  properties: { amount: string | null };
}) => {
  return deal.properties.amount === null
    ? {
      priority: 1,
      name: 'Missing Amount',
      insight: 'Missing Amount',
      key: 'missingAmount',
      fieldName: 'amount',
    }
    : undefined;
};

export const notUpdatedIn30DaysRules = (deal: {
  properties: { createdate: string; hs_lastmodifieddate: string | null };
}) => {
  const age = DaysSinceLastModified(deal);

  return age > 30
    ? {
      priority: 1,
      name: 'Not Updated In 30d',
      insight: `Not been updated in ${age} days`,
      key: 'notUpdatedIn30Days',
      fieldName: 'hs_lastmodifieddate',
    }
    : undefined;
};

export const nextStepsIndicateRiskRule = (deal: {
  properties: { hs_next_step: string | null };
}) => {
  if (!deal.properties.hs_next_step) {
    return undefined;
  }

  const nextSteps = deal.properties.hs_next_step.trim().toLowerCase();

  const riskIndicators = RiskPhrases.filter((phrase) =>
    nextSteps.includes(phrase),
  );

  return riskIndicators.length
    ? {
      priority: 1,
      name: 'Next Steps Indicate Risk',
      insight: `Next Step mentions ${riskIndicators.join(', ')}`,
      key: 'nextStepsIndicateRisk',
      fieldName: 'hs_next_step',
    }
    : undefined;
};

export const highValueRule = (
  deal: { properties: { amount: string | null } },
  _fieldNameFieldMap: HubSpotFieldNameFieldMap,
  orgStats?: HubSpotUserDealStats,
) => {
  if (!deal.properties.amount || !orgStats || !orgStats.overall?.Amount) {
    return undefined;
  }

  const isOwnerHighValue =
    orgStats.overall.Amount?.upperQuartile &&
    toNumber(deal.properties.amount) > orgStats.overall.Amount.upperQuartile;

  return isOwnerHighValue
    ? {
      priority: 1,
      name: 'High Value Opportunity',
      insight: `High Relative Value`,
      key: 'highValue',
      fieldName: 'amount',
    }
    : undefined;
};

export const longTimeInStageRule = (
  deal: {
    properties: { createdate: string; dealstage: string };
    dealStageHistory?: { value: string; timestamp: string }[];
  },
  _fieldNameFieldMap: HubSpotFieldNameFieldMap,
  orgStats?: HubSpotUserDealStats,
) => {
  if (!deal.dealStageHistory?.length || !orgStats || !orgStats.overall) {
    return undefined;
  }

  const currentStage = deal.properties.dealstage;

  if (!orgStats.overall.DaysInStage?.[currentStage]) {
    return undefined;
  }

  const timeInStage = DaysInStage(deal);

  const isLongTimeInStageOwner =
    orgStats.overall.DaysInStage?.[currentStage] &&
    timeInStage > orgStats.overall.DaysInStage[currentStage].mean;

  return isLongTimeInStageOwner
    ? {
      priority: 1,
      name: 'Long Time Stage',
      insight: `Relatively Long Time in ${currentStage}`,
      key: 'longTimeInStage',
      fieldName: 'dealstage',
    }
    : undefined;
};

export const noLastMeetingflowRule = (deal: {
  properties: { createdate: string };
  lastMeetingflow?: { startTime: string };
}) => {
  const age = AgeInDays(deal);

  return age > 14 && !deal.lastMeetingflow
    ? {
      priority: 1,
      name: 'No Meetingflow',
      insight: `No Recent Meetingflows`,
      key: 'noLastMeetingflow',
      fieldName: 'lastMeetingflow',
    }
    : undefined;
};

export const oldLastMeetingflow = (deal: {
  lastMeetingflow?: { startTime: string };
}) => {
  if (!deal.lastMeetingflow) {
    return undefined;
  }

  const meetingflowDaysAgo = Math.round(
    DateTime.fromISO(deal.lastMeetingflow.startTime).diffNow('days').days,
  );

  return meetingflowDaysAgo > 30
    ? {
      priority: 2,
      name: 'No Recent Meetingflow',
      insight: `Last meetingflow is from ${meetingflowDaysAgo} days ago`,
      key: 'meetingflowDaysAgo',
      fieldName: 'lastMeetingflow',
    }
    : undefined;
};

export const HubSpotDealInsightRules: HubSpotDealInsightRule[] = [
  closeDateInThePastRule,
  notUpdatedIn30DaysRules,
  longTimeInStageRule,
  nextStepsIndicateRiskRule,
  longTimeToClose,
  highValueRule,
  oldLastMeetingflow,
  noLastMeetingflowRule,
  missingNextStepsRule,
]satisfies HubSpotDealInsightRule[];
