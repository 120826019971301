import { mergeStyles } from '@fluentui/react';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { PricingAndFinancialsProps } from './PricingAndFinancials';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { useCallback } from 'react';
import { DealRoomLabelledDateValue } from '../DealRoomLabelledDateValue';
import { DealRoomLabelledCurrencyValue } from '../DealRoomLabelledCurrencyValue';

export const PricingAndFinancialsContent = ({
  organizationSlug,
  dealRoomId,
  dealRoom,
  refreshDealRoom,
}: PricingAndFinancialsProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const wrapperStyle = mergeStyles({
    display: 'flex',
    gap: '1rem',
    alignItems: 'flex-start',

    '.estimate-container': {
      backgroundColor: DEALROOMS_COLORS.financialSecondaryGray,
    },

    '.savings-container': {
      backgroundColor: DEALROOMS_COLORS.financialLighterGray,
    },

    '.separator': {
      width: '1px',
      backgroundColor: DEALROOMS_COLORS.neutralLight,
      height: '145px',
      margin: '0 1rem',
    },
  });

  const handleChangeAction = useCallback(
    async ({
      newValue,
      actionType,
    }: {
      newValue: number | string | null;
      actionType:
        | 'initialBudget'
        | 'costEstimate'
        | 'tcoEstimate'
        | 'targetCloseDate';
    }) => {
      if (newValue === dealRoom[actionType]) {
        return;
      }

      const token = await getAccessTokenSilently();
      await DealRoomsApiClient.updateDealRoom(
        organizationSlug,
        dealRoomId,
        {
          [actionType]: newValue,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      await refreshDealRoom();
    },
    [
      getAccessTokenSilently,
      dealRoom,
      organizationSlug,
      dealRoomId,
      refreshDealRoom,
    ],
  );

  return (
    <div className={wrapperStyle}>
      <DealRoomLabelledCurrencyValue
        label="Your initial budget"
        value={dealRoom.initialBudget}
        canEdit
        onChange={(newValue) =>
          handleChangeAction({ newValue, actionType: 'initialBudget' })
        }
      />
      <DealRoomLabelledCurrencyValue
        label="Our Price Estimate"
        className="estimate-container"
        value={dealRoom.costEstimate}
        onChange={(newValue) =>
          handleChangeAction({ newValue, actionType: 'costEstimate' })
        }
      />
      <DealRoomLabelledCurrencyValue
        label="TCO Savings"
        className="savings-container"
        value={dealRoom.tcoEstimate}
        onChange={(newValue) =>
          handleChangeAction({ newValue, actionType: 'tcoEstimate' })
        }
      />

      <div className="separator" />
      <DealRoomLabelledDateValue
        label="Decision"
        canEdit
        value={dealRoom.targetCloseDate}
        onChange={(newValue) =>
          handleChangeAction({ newValue, actionType: 'targetCloseDate' })
        }
      />
    </div>
  );
};
