import { mergeStyles, Stack } from '@fluentui/react';
import { DealRoomSidePanelCTA } from '../DealRoomSidePanelCTA';
import { DealRoomPrimaryButton } from './DealRoomButton';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const RightSidePanelCTAs = () => {
  const styles = mergeStyles({
    padding: '1.5rem',
    border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    borderRadius: '4px',
    gap: '2rem',
    backgroundColor: DEALROOMS_COLORS.neutralGray,
    boxShadow: '0px 2px 2px 0px #00000005',

    '.basic-actions-container': {
      gap: '1rem',
    },
  });

  return (
    <Stack className={styles}>
      <Stack className="basic-actions-container">
        <DealRoomSidePanelCTA
          label="Your Project"
          description="Anything you want to share about your project, ambitions, or things you want to solve for?"
          onClick={() => {}}
        />

        <DealRoomSidePanelCTA
          label="Our Solution"
          description="Want to know more about our product?  What it does? How it works?"
          onClick={() => {}}
        />

        <DealRoomSidePanelCTA
          label="Pricing"
          description="Need a quote or simply have questions about pricing?"
          onClick={() => {}}
        />
      </Stack>
      <DealRoomSidePanelCTA
        label="Ready to buy?"
        description="Let us know if you are ready to buy or if you have any questions."
      >
        <DealRoomPrimaryButton buttonStyleType="PRIMARY" onClick={() => {}}>
          Go to checkout
        </DealRoomPrimaryButton>
      </DealRoomSidePanelCTA>
    </Stack>
  );
};
