import { IconButton, mergeStyles, Text, useTheme } from '@fluentui/react';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import DealRoomCard from './Components/DealRoomCard';
import { DEALROOMS_COLORS } from '../../Themes/Themes';

type DealRoomSidePanelCTAProps = {
  className?: string;
  label: string;
  description: string;
  onClick?: () => void;
};

export const DealRoomSidePanelCTA = ({
  className,
  label,
  description,
  onClick,
  children,
}: PropsWithChildren<DealRoomSidePanelCTAProps>) => {
  const theme = useTheme();

  const cardStyles = mergeStyles({
    border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    borderRadius: '4px',
    padding: '1.5rem',
    boxShadow: '0px 2px 3px 0px #00000008',

    '.action-container': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '0.5rem',

      '.label': {
        color: DEALROOMS_COLORS.themePrimary,
        lineHeight: '1.5rem',
      },

      '.button': {
        width: '1.5rem',
        height: '1.5rem',
      },
    },

    '.description': {
      color: DEALROOMS_COLORS.themeSecondary,
      fontSize: '.9375rem',
      lineHeight: '1.5rem',
    },

    '.children-container': { marginTop: '1rem' },
  });

  return (
    <DealRoomCard className={classNames(cardStyles, className)}>
      <div className="action-container">
        <Text className="label" variant="large">
          {label}
        </Text>
        {onClick ? (
          <IconButton
            className="button"
            iconProps={{ iconName: 'Forward' }}
            onClick={onClick}
            styles={{ icon: { color: theme.palette.themeSecondary } }}
          />
        ) : null}
      </div>

      <Text className="description" variant="small" block>
        {description}
      </Text>

      {children ? <div className="children-container">{children}</div> : null}
    </DealRoomCard>
  );
};
