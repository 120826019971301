import { mergeStyles } from '@fluentui/react';
import {
  DealRoom,
  DetailedDealRoom,
  Organization,
} from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useUserProfile } from '../../../Hooks/useProfile';
import { isBuyer } from '../../../utils/buyerDecisionSiteUtils';
import { SplitViewLayout } from '../../Layouts/SplitViewLayout';
import { DealRoomArtifacts } from '../Artifacts/DealRoomArtifacts';
import { DealRoomUserPrioritiesContainer } from '../Components/DealRoomUserPrioritiesContainer';
import { FooterInfoCard } from '../Components/FooterInfoCard';
import { PricingAndFinancials } from '../Components/PricingAndFinancials/PricingAndFinancials';
import { RightSidePanelCTAs } from '../Components/RightSidePanelCTAs';

type DealRoomBuyersOverviewTabProps = {
  className?: string;
  organizationSlug: Organization['slug'];
  dealRoomId: DealRoom['id'];
  dealRoom: DetailedDealRoom;
  refreshDealRoom: () => Promise<unknown>;
};

export const DealRoomBuyersOverviewTab = ({
  className,
  organizationSlug,
  dealRoomId,
  dealRoom,
  refreshDealRoom,
}: DealRoomBuyersOverviewTabProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useUserProfile();

  const styles = mergeStyles({
    '.headerContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '3.5rem',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',

    '.artifacts': {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'auto',
      gap: '1rem',

      '.artifact': {
        minWidth: '18rem',
      },
    },

    '.pricing-and-timeline': {
      display: 'grid',
      gridTemplateColumns: '1fr max-content',
      gridTemplateAreas: `
        "pricing timeline"
      `,
      gap: '1rem',
      flexDirection: 'row',

      '.pricing': {
        gridArea: 'pricing',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      },

      '.timeline': {
        gridArea: 'timeline',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      },
    },

    '.relevant-artifacts': {},
  });

  const showBuyerPriorities = useMemo(() => {
    const currentUserDetails = dealRoom?.contacts?.find(
      (contact) => contact.email.toLowerCase() === user?.email?.toLowerCase(),
    );
    if (!currentUserDetails) return false;

    return isBuyer(currentUserDetails.role);
  }, [dealRoom?.contacts, user?.email]);

  return (
    <SplitViewLayout
      layoutName="DealRoomBuyersViewInner"
      header={
        <div className={classNames(className, styles)}>
          {showBuyerPriorities && (
            <DealRoomUserPrioritiesContainer
              dealRoomId={dealRoomId}
              organizationSlug={organizationSlug}
            />
          )}
        </div>
      }
      gap="12px"
      fullWidthHeader
      sidebar={<RightSidePanelCTAs />}
      sidebarWidthPercent={25}
      footer={<FooterInfoCard />}
    >
      <div className={classNames(className, styles)}>
        <DealRoomArtifacts
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          artifacts={dealRoom.artifacts}
          activity={dealRoom.activity}
          featured
          includeDeleted={false}
          showAdd
          refreshDealRoom={refreshDealRoom}
        />
        <PricingAndFinancials
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          dealRoom={dealRoom}
          refreshDealRoom={refreshDealRoom}
        />
        <div className="relevant-artifacts"></div>
      </div>
    </SplitViewLayout>
  );
};
