import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from '@fluentui/react';
import { CalendarEventSource } from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { isString } from 'lodash';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useOrganization } from '../../Hooks/useOrganization';
import {
  MeetingPlanQuery,
  OrganizationMeetingPlansQuery,
  OrganizationMeetingsHappeningSoon,
  OrganizationUpcomingMeetings,
} from '../../QueryNames';
import { MeetingflowsApiClient } from '../../Services/NetworkCommon';
import { isBoolean, toBoolean } from '@meetingflow/common/TypeHelpers';

const isCalendarEventProvider = (val: unknown): val is CalendarEventSource =>
  isString(val) && ['GOOGLE', 'M365'].includes(val);

const MeetingPlanAutoCreate = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const client = useQueryClient();
  const appInsights = useAppInsightsContext();

  const { slug } = useOrganization();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const calendarProvider = searchParams
      .get('calendarProvider')
      ?.toUpperCase();
    const eventId = searchParams.get('eventId');
    const record =
      searchParams.has('record') &&
      isBoolean(searchParams.get('record')) &&
      !!toBoolean(searchParams.get('record'));

    appInsights.trackEvent({
      name: 'ONE_CLICK_CREATE_PLAN_FOR_EVENT',
      properties: {
        organizationSlug: slug,
        source: calendarProvider,
        eventId,
      },
    });

    if (!calendarProvider || !eventId) {
      navigate(`/organization/${slug}`);
      return;
    }

    if (!isCalendarEventProvider(calendarProvider)) {
      navigate(`/organization/${slug}`);
      return;
    }

    getAccessTokenSilently().then(async (token) => {
      const result = await MeetingflowsApiClient.postMeetingflow(
        slug!,
        {
          source: calendarProvider,
          eventId,
          record,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          validateStatus: (status) => {
            return (
              status === 201 ||
              status === 302 ||
              status === 400 ||
              status === 403 ||
              status === 404
            );
          },
        },
      );

      if (result.status === 201) {
        await client.invalidateQueries(OrganizationMeetingPlansQuery(slug!));
        await client.invalidateQueries(
          OrganizationMeetingsHappeningSoon(slug!),
        );
        await client.invalidateQueries(OrganizationUpcomingMeetings(slug!));

        appInsights.trackEvent({
          name: 'CREATE_PLAN_FOR_EVENT',
          properties: {
            organizationSlug: slug,
            source: calendarProvider,
            eventId,
          },
        });

        client.setQueryData(MeetingPlanQuery(slug!, result.data.id), result);

        if (record) {
          navigate(
            `/organization/${slug}/plan/${result.data.id}?scheduleCallRecording=${record}`,
          );
        } else {
          navigate(`/organization/${slug}/plan/${result.data.id}`);
        }
      } else if (result.status === 302) {
        client.setQueryData(MeetingPlanQuery(slug!, result.data.id), result);

        if (record) {
          navigate(
            `/organization/${slug}/plan/${result.data.id}?scheduleCallRecording=${record}`,
          );
        } else {
          navigate(`/organization/${slug}/plan/${result.data.id}`);
        }
      } else if (result.status === 403) {
        toast.error(
          `You do not have permission to create Meetingflows in ${slug}.`,
          {
            duration: 7000,
          },
        );
        navigate(`/organization/${slug}`);
      } else if (result.status === 400 || result.status === 404) {
        toast.error(
          "Sorry, we couldn't find that event. It may have been deleted or be from a different workspace.",
          {
            duration: 7000,
          },
        );
        appInsights.trackEvent({
          name: 'EVENT_NOT_FOUND',
          properties: {
            organizationSlug: slug,
            source: calendarProvider,
            eventId,
          },
        });
        navigate(`/organization/${slug}`);
      } else {
        toast.error('Meetingflow creation failed.');
        navigate(`/organization/${slug}`);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Spinner />;
};

export default MeetingPlanAutoCreate;
