import React, { useMemo, useState } from 'react';

import { useQuery } from 'react-query';
import { TextField } from '@fluentui/react/lib/TextField';
import {
  Dropdown,
  FontWeights,
  IDropdownOption,
  mergeStyles,
  Persona,
  PersonaSize,
  PrimaryButton,
} from '@fluentui/react';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';

import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import {
  BuyerPrefSettingsIllustrations,
  MailIllustrations,
  SendInviteIllustrations,
} from './UserSettingsIllustrations';
import { OrganizationMembersQuery } from '../../../../QueryNames';
import {
  InvitesApiClient,
  MembersApiClient,
} from '../../../../Services/NetworkCommon';
import { OrganizationMemberRole } from '@meetingflow/common/Api/data-contracts';
import { ROLE_DROPDOWN_OPTIONS } from '../../../../Helpers/Organizations';

interface UserSettingsInviteProps {
  isSaving: boolean;
  organizationSlug: string;
}

const orgRoles = ROLE_DROPDOWN_OPTIONS.map((userRole) => ({
  ...userRole,
  text: userRole.text.split(' ')[0],
}));

const emailPattern = /^[a-zA-Z0-9._%+-]{2,}@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,}$/;

export const UserSettingsInvite = ({
  isSaving,
  organizationSlug,
}: UserSettingsInviteProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const [emailToInvite, setEmailToInvite] = useState<string>('');
  const [orgRoleState, setOrgRoleState] = useState<string>(orgRoles[0].key);

  const { data: orgMembersData, isLoading: orgMembersLoading } = useQuery(
    OrganizationMembersQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return MembersApiClient.listMembers(
        { organizationSlug },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  const isInviteEmailValid: boolean = useMemo(
    () => emailPattern.test(emailToInvite!),
    [emailToInvite],
  );

  const handleInvite = async () => {
    const token = await getAccessTokenSilently();
    try {
      const result = await InvitesApiClient.putInvite(
        {
          organizationSlug: organizationSlug!,
          email: emailToInvite,
          role: orgRoleState as OrganizationMemberRole,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (result.status === 201) {
        // New user invited
        setEmailToInvite('');
        setOrgRoleState(orgRoles[0].key);
        toast.success(
          `${emailToInvite} has been invited to ${
            organizationSlug
          }. Feel free to invite someone else!`,
        );
      } else if (result.status === 200) {
        // User already invited
        setEmailToInvite('');
        setOrgRoleState(orgRoles[0].key);
        toast.success(`${emailToInvite} was sent an invitation reminder`);
      }
    } catch (e) {
      toast.error(`Something went wrong inviting ${emailToInvite}.`);
    }
  };

  const inviteStyle = mergeStyles({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1',
    '.invite-content': {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      maxWidth: '50rem',
      '.invite_someone_container': {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.3rem',
        justifyContent: 'space-between',
        alignItems: 'start',
        position: 'relative',
        '.invite_someone_input_icon': {
          position: 'absolute',
          top: '0.35rem',
          left: '.5rem',
          zIndex: '1',
        },
        '.invite_someone_input': {
          flexGrow: 1,
          label: {
            fontSize: '0.8rem',
            fontWeight: 200,
            '::after': {
              color: DEALROOMS_COLORS.darkerRed,
            },
          },
          input: {
            borderRadius: '0.25rem',
            backgroundColor: DEALROOMS_COLORS.inputLightGray,
            padding: '0 0 0 2rem',
          },
          '*': {
            border: '0',
            outline: 'none',
          },
        },
        '.invite_someone_access_level': {
          width: '10rem',
          '>div:nth-child(1)': {
            border: '0',
            outline: '0',
            ':hover': {},
            ':focus-visible': {
              ':after': {
                border: '1px solid ' + DEALROOMS_COLORS.sidebarTextSecondary,
                outline: 'none',
              },
            },
          },
          '>div:nth-child(1):after': {
            border: '0',
            outline: '0',
          },
          'span:nth-child(1)': {
            border: '0',
            outline: '0',
            color: DEALROOMS_COLORS.darkerGray,
            backgroundColor: DEALROOMS_COLORS.inputLightGray,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: '0.25rem',
            svg: {
              margin: '0 0.3rem 0 0',
            },
          },
        },
        '.invite_someone_btn': {
          display: 'flex',
          width: 'content',
          height: 'content',
          padding: '0.3rem 1rem',
          borderRadius: '0.25rem',
          backgroundColor: DEALROOMS_COLORS.userSurveyPrimary,
          svg: {
            margin: '0 0.3rem 0 0',
          },
          ':disabled': {
            backgroundColor: DEALROOMS_COLORS.sidebarTextSecondary,
          },
        },
      },
      '.people-access-label': {
        fontSize: '1rem',
      },
      '.people-grid-container': {
        backgroundColor: DEALROOMS_COLORS.white,
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)' /* 3 equal-width columns */,
        border: `1px solid${DEALROOMS_COLORS.neutralLight}`,
        borderRadius: '0.25rem',
        padding: '0.4rem 0.6rem',
        '.header': {
          color: DEALROOMS_COLORS.themeSecondary,
          fontSize: '0.8rem',
          fontWeight: FontWeights.regular,
          borderBottom: `1px solid${DEALROOMS_COLORS.neutralLight}`,
          padding: '0.5rem',
          textAlign: 'left',
        },
        '.item': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '0.5rem',
          color: DEALROOMS_COLORS.themePrimary,
          fontSize: '0.9rem',
          fontWeight: FontWeights.regular,
          padding: '0.5rem',
          textAlign: 'left',
          borderBottom: `1px solid${DEALROOMS_COLORS.neutralLight}`,
          ':nth-last-child(-n+3)': {
            borderBottom: '0',
          },
        },
      },
    },
  });

  const onRenderTitle = (
    options: IDropdownOption[] | undefined,
  ): JSX.Element => {
    const option = options?.length ? options[0] : 'N/A';
    return (
      <>
        <BuyerPrefSettingsIllustrations />
        <span>{typeof option !== 'string' ? option?.text : option}</span>
      </>
    );
  };

  const prettyRoleString = (role: OrganizationMemberRole | 'GUEST') => {
    return orgRoles.find((orgRole) => role === orgRole.key)?.text || '';
  };

  return (
    <div className={inviteStyle}>
      <div className="invite-content">
        <div className="invite_someone_container">
          <div className="invite_someone_input_icon">
            <MailIllustrations />
          </div>
          <TextField
            className="invite_someone_input"
            value={emailToInvite}
            onChange={(event, newValue) => setEmailToInvite(newValue || '')}
            errorMessage={
              emailToInvite && !isInviteEmailValid
                ? 'Please enter a valid email address'
                : ''
            }
            disabled={isSaving}
          />
          <Dropdown
            className="invite_someone_access_level"
            options={orgRoles}
            selectedKey={orgRoleState}
            onChange={(_evt, option, _index) =>
              setOrgRoleState(
                (option?.key as OrganizationMemberRole) || orgRoles[0].key,
              )
            }
            onRenderTitle={onRenderTitle}
          />
          <PrimaryButton
            className="invite_someone_btn"
            onClick={handleInvite}
            disabled={!emailToInvite || !isInviteEmailValid}
          >
            <SendInviteIllustrations />
            Invite
          </PrimaryButton>
        </div>
        <div className="people-access-label">People with Access</div>
        <div className="people-grid-container">
          <div className="header">Name</div>
          <div className="header">Email</div>
          <div className="header">Access Level</div>

          {orgMembersData?.data.map((inviteData) => (
            <React.Fragment key={`person_with_access_${inviteData.id}`}>
              <div className="item">
                <Persona
                  {...{
                    text: inviteData?.name || undefined,
                    imageUrl: inviteData?.avatarUrl || undefined,
                  }}
                  size={PersonaSize.size24}
                  hidePersonaDetails={true}
                />
                {inviteData?.name && <div>{inviteData.name}</div>}
              </div>
              <div className="item">{inviteData?.email}</div>
              <div className="item">{prettyRoleString(inviteData?.role)}</div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
