import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  Dropdown,
  FontIcon,
  FontWeights,
  ITooltipHostStyles,
  NeutralColors,
  PrimaryButton,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { OmitValues } from '@meetingflow/common/ObjectHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { titleize } from 'inflection';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import tlds from 'tlds';
import * as yup from 'yup';
import { DEFAULT_STACK_TOKENS } from '../../Helpers/Layout';
import { ROLE_DROPDOWN_OPTIONS_LIMITED } from '../../Helpers/Organizations';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useTitle } from '../../Hooks/useTitle';
import {
  CompanyByDomain,
  OrganizationMembersQuery,
  OrganizationQuery,
  OrganizationRoleQuery,
  OrganizationsQuery,
} from '../../QueryNames';
import {
  ApiClient,
  MeetingflowsApiClient,
  OrganizationsApiClient,
} from '../../Services/NetworkCommon';
import { BigPictureCompanyDetails } from '../../types/BigPictureCompanyDetails';
import { Card } from '../Card';
import { PageLayout } from '../Layouts/PageLayout';

import PublicEmailDomains from '@meetingflow/common/PublicEmailDomains';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { AutomaticallyShareRecordingsPreference } from '@meetingflow/common/Api/data-contracts';
import { AUTO_SHARE_RECORDINGS_OPTIONS } from '../../Constants';

type CreateOrganizationFormData = {
  slug: string;
  name: string;
  allowJoinRequests: boolean;
  approveJoinRequestsAutomatically: boolean;
  approveJoinRequestsAutomaticallyAsRole?: 'CREATOR' | 'COLLABORATOR';
  automaticallyShareRecordings: AutomaticallyShareRecordingsPreference;
};

export const CreateOrganization = () => {
  const { isDark } = useLightOrDarkMode();
  const { user, getAccessTokenSilently } = useAuth0();
  const domains = PublicEmailDomains;
  const client = useQueryClient();
  const navigate = useNavigate();

  const appInsights = useAppInsightsContext();

  const emailDomain = useMemo(() => {
    return user!.email!.toLowerCase()!.split('@')[1];
  }, [user]);

  const userDomain = useMemo(() => {
    const matchingTld = tlds
      .filter((tld) => emailDomain?.endsWith(tld))
      .reduce((a, b) => (a.length <= b.length ? b : a));
    if (matchingTld) {
      const trimmedDomain = emailDomain?.replace(`.${matchingTld}`, '');
      if ((trimmedDomain?.length ?? 0) >= 5) {
        return trimmedDomain;
      }
    }

    return emailDomain;
  }, [emailDomain]);

  const { data: companyDetails } = useQuery(
    CompanyByDomain(emailDomain),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<BigPictureCompanyDetails>(
        `/company/${emailDomain}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: domains && !domains.includes(emailDomain) },
  );

  const companyName =
    companyDetails?.data?.name || companyDetails?.data?.legalName;

  const defaultSlug = useMemo(() => {
    if (domains?.includes(emailDomain)) {
      const replaced = user!
        .email!.toLowerCase()
        .replaceAll(/[^a-z0-9]/g, '-')
        .replaceAll(/-{2,}/g, '-');
      if (replaced.length < 4) {
        return `${replaced}-flow`;
      }
      return replaced;
    }
    if (companyName) {
      return companyName
        .toLowerCase()
        .replaceAll(/[^a-z0-9]/g, '-')
        .replaceAll(/-{2,}/g, '-');
    }
    if (!userDomain) {
      return '';
    }
    const replaced = userDomain
      .toLowerCase()
      .replaceAll(/[^a-z0-9]/g, '-')
      .replaceAll(/-{2,}/g, '-');
    if (replaced.length < 4) {
      return `${replaced}-flow`;
    }
    return replaced;
  }, [companyName, domains, emailDomain, user, userDomain]);

  const defaultName = useMemo(() => {
    if (domains?.includes(emailDomain)) {
      if (user?.given_name && user.family_name) {
        return titleize(
          `${user.given_name}${
            user.given_name.endsWith('s') ? "'" : "'s"
          } Workspace`,
        );
      }
      if (user?.name) {
        return titleize(
          `${user.name}${user.name.endsWith('s') ? "'" : "'s"} Workspace`,
        );
      }
      return titleize(
        `${user!.email}${user!.email!.endsWith('s') ? "'" : "'s"} Workspace`,
      );
    }
    if (companyName) {
      return titleize(companyName);
    }

    if (!userDomain) {
      return '';
    }
    return titleize(userDomain.replaceAll('.', '-'));
  }, [companyName, domains, emailDomain, user, userDomain]);

  useTitle('Create Workspace');

  const formSchema = useMemo(
    () =>
      yup
        .object({
          slug: yup
            .string()
            .min(5, 'The workspace identifier must be at least 5 characters')
            .matches(
              /^[a-z][-a-z0-9]{3,}[a-z0-9]$/,
              'The identifier must start with a letter, and contain letters, numbers and dashes',
            )
            .test(
              'Available?',
              'The specified identifier is not available',
              async (value) => {
                if (!value || !value.match(/^[a-z][-a-z0-9]{3,}[a-z0-9]$/)) {
                  return false;
                }
                const token = await getAccessTokenSilently();
                try {
                  const result =
                    await OrganizationsApiClient.checkSlugAvailable(value, {
                      headers: { Authorization: `Bearer ${token}` },
                    });
                  return result.data;
                } catch (err) {
                  return false;
                }
              },
            )
            .required(),
          name: yup.string().required(),
          allowJoinRequests: yup.boolean(),
          approveJoinRequestsAutomatically: yup.boolean(),
          approveJoinRequestsAutomaticallyAsRole: yup
            .string()
            .oneOf(['COLLABORATOR', 'CREATOR'], 'Invalid role'),
          automaticallyShareRecordings: yup
            .string()
            .oneOf(['ENABLED', 'DISABLED']),
        })
        .required(),
    [getAccessTokenSilently],
  );

  const disableDiscoverability = !domains || domains.includes(emailDomain);

  const {
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { isValid, errors },
    setValue,
  } = useForm<CreateOrganizationFormData>({
    defaultValues: {
      slug: defaultSlug,
      name: defaultName,
      allowJoinRequests: disableDiscoverability ? false : true,
      approveJoinRequestsAutomatically: disableDiscoverability ? false : true,
      approveJoinRequestsAutomaticallyAsRole: 'COLLABORATOR',
    },
    resolver: yupResolver(formSchema, { abortEarly: false }),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('slug', defaultSlug, { shouldValidate: true });
    setValue('name', defaultName, { shouldValidate: true });
  }, [defaultSlug, defaultName, setValue]);

  useEffect(() => {
    if (disableDiscoverability) {
      setValue('allowJoinRequests', false);
      setValue('approveJoinRequestsAutomatically', false);
    } else {
      setValue('allowJoinRequests', true);
      setValue('approveJoinRequestsAutomatically', true);
    }
  }, [disableDiscoverability, setValue]);

  // Validate org slug on mount
  useEffect(() => {
    trigger('slug');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyName) {
      setValue('name', companyName);
      setValue(
        'slug',
        companyName
          .toLowerCase()
          .replace(/\s+/g, '-')
          .replace(/[^-a-z0-9]/g, ''),
      );
    }
  }, [companyName, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    if (isValid) {
      const token = await getAccessTokenSilently();
      const result = await OrganizationsApiClient.putOrganization(
        data.slug,
        {
          name: data.name,
          automaticallyShareRecordings: data.automaticallyShareRecordings,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (result.status === 201) {
        if (
          data.allowJoinRequests &&
          data.approveJoinRequestsAutomaticallyAsRole
        ) {
          // Add the domain rules
          const ruleType = data.approveJoinRequestsAutomatically
            ? 'AUTO_APPROVE'
            : 'CAN_REQUEST_ACCESS';
          const roleType = data.approveJoinRequestsAutomaticallyAsRole;
          await OrganizationsApiClient.putDomainRule(
            data.slug,
            emailDomain,
            {
              roleType,
              ruleType,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        }

        client.invalidateQueries(OrganizationsQuery);
        client.invalidateQueries(OrganizationQuery(data.slug));
        client.invalidateQueries(OrganizationRoleQuery(data.slug));
        client.invalidateQueries(OrganizationMembersQuery(data.slug));

        toast.success(
          `${data.name} has been created. Feel free to invite others!`,
        );
        try {
          const tkn = await getAccessTokenSilently();
          const samples = await MeetingflowsApiClient.listPlans(
            { organizationSlug: data.slug, isSample: true },
            { headers: { Authorization: `Bearer ${tkn}` } },
          );
          const sample = samples.data?.find((s) => s.sampleType === 'WELCOME');
          if (sample) {
            navigate(`/organization/${data.slug}/plan/${sample.id}`);
          } else {
            navigate(`/organization/${data.slug}`);
          }
          client.invalidateQueries();
        } catch {
          navigate(`/organization/${data.slug}`);
          client.invalidateQueries();
        }
      }
    }
  });

  const toolTipHostStyles = useMemo(
    () =>
      ({
        root: {
          display: 'inline-block',
          height: '16px',
          width: '16px',
          marginLeft: '.25rem',

          p: {
            margin: 0,
          },
        },
      }) as ITooltipHostStyles,
    [],
  );

  const tooltipCalloutProps = useMemo(
    () => ({
      styles: {
        root: {
          padding: 0,
          background: MEETINGFLOW_COLORS.purpleDarker,
          color: isDark ? undefined : MEETINGFLOW_COLORS.white,
        },
        beak: {
          background: MEETINGFLOW_COLORS.purpleDarker,
        },
        calloutMain: {
          padding: '1rem',
          backgroundColor: isDark ? undefined : MEETINGFLOW_COLORS.purpleDarker,
          p: {
            margin: 0,
            borderBottomRightRadius: '.5rem',
            borderBottomLeftRadius: '.5rem',
            color: MEETINGFLOW_COLORS.white,
          },
        },
      },
    }),
    [isDark],
  );

  const automaticallyShareRecordings = watch('automaticallyShareRecordings');

  return (
    <PageLayout
      primaryContent={
        <Card
          title="Next, create a workspace."
          contentContainerPadding={'1rem'}
          description="A workspace is used to allow you to collaborate with team members.
          Enter a name for your workspace. It can be anything you like.
        Also, choose a URL identifier for your workspace."
          backgroundColor="transparent"
          showBorder={false}
          showShadow={false}
        >
          <Stack tokens={DEFAULT_STACK_TOKENS}>
            <Stack horizontal tokens={DEFAULT_STACK_TOKENS}>
              <Stack.Item grow={1}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { value, onBlur, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={(_e, newValue) => onChange(newValue || '')}
                      onBlur={onBlur}
                      errorMessage={
                        !!errors.name ? errors.name.message : undefined
                      }
                      label="Name"
                    />
                  )}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <Controller
                  name="slug"
                  control={control}
                  render={({ field: { value, onBlur, onChange } }) => (
                    <TextField
                      prefix="https://app.meetingflow.com/organization/"
                      styles={{
                        prefix: {
                          backgroundColor: isDark
                            ? NeutralColors.gray190
                            : NeutralColors.gray20,
                        },
                      }}
                      errorMessage={errors.slug?.message}
                      label="URL Identifier"
                      value={value}
                      onChange={(_e, newValue) => onChange(newValue || '')}
                      onBlur={onBlur}
                      description={`The URL identifier will be used in shareable URLs for your workspace's Meetingflows`}
                    />
                  )}
                />
              </Stack.Item>
            </Stack>
            {disableDiscoverability ? (
              <Text
                block
                style={{
                  marginTop: '1.5rem',
                  marginBottom: '.5rem',
                  fontWeight: FontWeights.semibold,
                }}
              >
                Note: workspace discoverability is disabled for your email
                domain ({emailDomain}) for privacy reasons.
              </Text>
            ) : null}
            <div
              style={{
                opacity: disableDiscoverability ? '.25' : '11',
              }}
            >
              <Stack
                horizontal
                tokens={DEFAULT_STACK_TOKENS}
                style={{ marginBottom: '1rem' }}
              >
                <Stack.Item grow={1}>
                  <Text
                    block
                    style={{
                      marginTop: '.5rem',
                      marginBottom: '.5rem',
                      fontWeight: FontWeights.semibold,
                    }}
                  >
                    Working as a team?
                  </Text>
                  <Controller
                    name="allowJoinRequests"
                    control={control}
                    render={({ field }) => {
                      const { onChange, value, ...rest } = field;
                      return (
                        <Checkbox
                          {...OmitValues(rest, 'ref')}
                          disabled={disableDiscoverability}
                          checked={value}
                          styles={{ root: { i: { color: 'white' } } }}
                          onChange={(_ev, checked) => {
                            appInsights.trackEvent({
                              name: 'CreateOrgToggleAllowJoinRequests',
                              properties: {
                                domain: userDomain,
                                checked: !!checked,
                              },
                            });
                            onChange(!!checked);
                          }}
                          // @ts-ignore
                          label={
                            <>
                              Yes. Let anyone with a <b>{emailDomain}</b> email
                              address request access to this workspace.
                            </>
                          }
                        />
                      );
                    }}
                  />
                </Stack.Item>
              </Stack>
              <Stack horizontal tokens={DEFAULT_STACK_TOKENS}>
                <Stack.Item>
                  <Controller
                    name="approveJoinRequestsAutomatically"
                    control={control}
                    render={({ field: { onChange, value, ...rest } }) => (
                      <Checkbox
                        {...rest}
                        checked={value}
                        styles={{ root: { i: { color: 'white' } } }}
                        onChange={(_ev, checked) => onChange(!!checked)}
                        disabled={
                          disableDiscoverability || !watch('allowJoinRequests')
                        }
                        label="Automatically approve requests and let them join as:"
                      />
                    )}
                  />
                </Stack.Item>
                <Stack.Item>
                  <div
                    style={{
                      position: 'relative',
                      top: '-.25rem',
                    }}
                  >
                    <Controller
                      name="approveJoinRequestsAutomaticallyAsRole"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          errorMessage={
                            errors.approveJoinRequestsAutomaticallyAsRole
                              ?.message
                          }
                          options={ROLE_DROPDOWN_OPTIONS_LIMITED}
                          disabled={!watch('allowJoinRequests')}
                          selectedKey={field.value}
                          onBlur={field.onBlur}
                          onChange={(_evt, option, _index) => {
                            if (!option?.key) {
                              return;
                            }
                            field.onChange(
                              option?.key as 'CREATOR' | 'COLLABORATOR',
                            );
                          }}
                        />
                      )}
                    />
                  </div>
                </Stack.Item>
              </Stack>
            </div>

            <div className="row">
              <h4 className="separator">
                Automatically Share Call Recordings{' '}
                <TooltipHost
                  styles={toolTipHostStyles}
                  calloutProps={tooltipCalloutProps}
                  content={
                    <>
                      <Text>
                        At the end of recorded meetings, Meetingflow can make
                        the recording, transcript, and AI-generated summary
                        available to all meeting attendees.{' '}
                        <strong>
                          Attendees will receive an email summary of the meeting
                          and a link to the recording and transcript. The link
                          is accessible by anyone with an email domain matching
                          those of attendees.
                        </strong>{' '}
                        This setting can be overridden on a per-Meetingflow
                        basis, and recordings can be shared with attendees
                        manually when this setting is off.{' '}
                        <strong>
                          Meeting notes, action items, and other internal
                          information will not be shared.
                        </strong>
                      </Text>
                    </>
                  }
                >
                  <FontIcon iconName="Info" />
                </TooltipHost>
              </h4>
              <Stack.Item grow={1}>
                <div>
                  <Controller
                    name="automaticallyShareRecordings"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        errorMessage={
                          errors.automaticallyShareRecordings?.message
                        }
                        options={AUTO_SHARE_RECORDINGS_OPTIONS}
                        defaultSelectedKey={'ENABLED'}
                        onBlur={field.onBlur}
                        onChange={(_evt, option, _index) => {
                          if (!option?.key) {
                            return;
                          }
                          field.onChange(
                            option?.key as AutomaticallyShareRecordingsPreference,
                          );
                        }}
                        label="Automatically Share Recordings"
                      />
                    )}
                  />
                  {automaticallyShareRecordings === 'ENABLED' ? (
                    <div>
                      <span>
                        <strong>On:</strong> Recordings, transcripts, and
                        AI-generated summaries of recorded meetings will be
                        shared with all meeting attendees. The link is
                        accessible by anyone with an email domain matching those
                        of attendees.
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span>
                        <strong>Off:</strong> Recordings, transcripts, and
                        AI-generated summaries will not be made available to
                        external attendees. Internal attendees will receive an
                        email with this information, and users can manually
                        share the recording for a given Meetingflow.
                      </span>
                    </div>
                  )}
                </div>
              </Stack.Item>
            </div>
            <Stack
              horizontal
              horizontalAlign={'end'}
              tokens={DEFAULT_STACK_TOKENS}
            >
              <Stack.Item>
                <PrimaryButton
                  disabled={!isValid}
                  text="Create"
                  onClick={onSubmit}
                />
              </Stack.Item>
            </Stack>
          </Stack>
        </Card>
      }
    />
  );
};

export default CreateOrganization;
