import { useAuth0 } from '@auth0/auth0-react';
import {
  ContextualMenuItemType,
  IconButton,
  IContextualMenuProps,
  mergeStyleSets,
  Text,
  useTheme,
} from '@fluentui/react';
import {
  DealRoom,
  DetailedDealRoom,
  Organization,
} from '@meetingflow/common/Api/data-contracts';
import { CollectToMap } from '@meetingflow/common/ArrayHelpers';
import { hasOwnNumberProperty } from '@meetingflow/common/ObjectHelpers';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useUserProfile } from '../../../Hooks/useProfile';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { DealRoomActionItem } from '../ActionItems/DealRoomActionItem';

type DealRoomBuyersActionItemsTabProps = {
  organizationSlug: Organization['slug'];
  dealRoomId: DealRoom['id'];
  actionItems: DetailedDealRoom['mutualActionItems'];
  activity: DetailedDealRoom['activity'];
  refetchDealRoom: () => unknown | Promise<unknown>;
};

export const DealRoomBuyersActionItemsTab = ({
  organizationSlug,
  dealRoomId,
  actionItems,
  activity,
  refetchDealRoom,
}: DealRoomBuyersActionItemsTabProps) => {
  const theme = useTheme();

  const { getAccessTokenSilently } = useAuth0();

  const { userId } = useUserProfile();

  const navigate = useNavigate();

  const styles = mergeStyleSets({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    header: {
      display: 'grid',
      gridTemplateColumns: 'max-content 1fr max-content',
      gridTemplateRows: 'max-content max-content',
      gridTemplateAreas: `
        "title none actions"
        "subtitle none actions"
      `,

      '.title': {
        gridArea: 'title',
      },

      '.subtitle': {
        gridArea: 'subtitle',
      },

      '.actions': {
        gridArea: 'actions',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '1rem',
      },
    },
    actionItems: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
  });

  const incompleteActionItems = useMemo(() => {
    return actionItems.filter((actionItem) => !actionItem.completedAt);
  }, [actionItems]);

  const completeActionItems = useMemo(() => {
    return actionItems.filter((actionItem) => !!actionItem.completedAt);
  }, [actionItems]);

  const menuProps: IContextualMenuProps = useMemo(
    () => ({
      shouldFocusOnMount: true,
      items: [
        {
          key: 'add-examples',
          text: 'Add examples',
          iconProps: { iconName: 'AddToShoppingList' },
          onClick: () => {
            (async () => {
              const token = await getAccessTokenSilently();

              const artifacts = await DealRoomsApiClient.listArtifacts(
                { organizationSlug, dealRoomId },
                {
                  headers: { Authorization: `Bearer ${token}` },
                },
              );

              await DealRoomsApiClient.createActionItem(
                organizationSlug,
                dealRoomId,
                {
                  actionItem: 'First example',
                },
                { headers: { Authorization: `Bearer ${token}` } },
              );

              await DealRoomsApiClient.createActionItem(
                organizationSlug,
                dealRoomId,
                {
                  actionItem: 'Second example with assignee',
                  assigneeId: userId,
                },
                { headers: { Authorization: `Bearer ${token}` } },
              );

              await DealRoomsApiClient.createActionItem(
                organizationSlug,
                dealRoomId,
                {
                  actionItem: 'Third example with long due date',
                  dueDate: DateTime.now().plus({ days: 30 }).toISO(),
                },
                { headers: { Authorization: `Bearer ${token}` } },
              );

              await DealRoomsApiClient.createActionItem(
                organizationSlug,
                dealRoomId,
                {
                  actionItem: 'Fourth example near due date',
                  dueDate: DateTime.now().plus({ days: 2 }).toISO(),
                },
                { headers: { Authorization: `Bearer ${token}` } },
              );

              await DealRoomsApiClient.createActionItem(
                organizationSlug,
                dealRoomId,
                {
                  actionItem: 'Fifth example overdue',
                  dueDate: DateTime.now().minus({ days: 2 }).toISO(),
                },
                { headers: { Authorization: `Bearer ${token}` } },
              );

              if (artifacts.data.length) {
                const artifact = artifacts.data[0];
                const actionItem = await DealRoomsApiClient.createActionItem(
                  organizationSlug,
                  dealRoomId,
                  {
                    actionItem: 'Sixth example with one artifact',
                  },
                  { headers: { Authorization: `Bearer ${token}` } },
                );

                await DealRoomsApiClient.updateArtifact(
                  organizationSlug,
                  dealRoomId,
                  artifact.id,
                  {
                    dealRoomActionItemId: actionItem.data.id,
                  },
                  { headers: { Authorization: `Bearer ${token}` } },
                );
              }

              if (artifacts.data.length >= 3) {
                const artifactIds = artifacts.data.slice(1, 3).map((a) => a.id);
                const actionItem = await DealRoomsApiClient.createActionItem(
                  organizationSlug,
                  dealRoomId,
                  {
                    actionItem: 'Seventh example with multiple artifacts',
                  },
                  { headers: { Authorization: `Bearer ${token}` } },
                );

                await Promise.all(
                  artifactIds.map((artifactId) =>
                    DealRoomsApiClient.updateArtifact(
                      organizationSlug,
                      dealRoomId,
                      artifactId,
                      {
                        dealRoomActionItemId: actionItem.data.id,
                      },
                      { headers: { Authorization: `Bearer ${token}` } },
                    ),
                  ),
                );
              }

              const completedActionItemOne =
                await DealRoomsApiClient.createActionItem(
                  organizationSlug,
                  dealRoomId,
                  {
                    actionItem: 'Eigth example completed near due date',
                    dueDate: DateTime.now().plus({ days: 2 }).toISO(),
                  },
                  { headers: { Authorization: `Bearer ${token}` } },
                );

              await DealRoomsApiClient.updateActionItem(
                organizationSlug,
                dealRoomId,
                completedActionItemOne.data.id,
                {
                  completedAt: DateTime.now().minus({ minutes: 10 }).toISO(),
                },
                { headers: { Authorization: `Bearer ${token}` } },
              );

              const completedActionItemTwo =
                await DealRoomsApiClient.createActionItem(
                  organizationSlug,
                  dealRoomId,
                  {
                    actionItem: 'Ninth example completed overdue',
                    dueDate: DateTime.now().minus({ days: 2 }).toISO(),
                  },
                  { headers: { Authorization: `Bearer ${token}` } },
                );

              await DealRoomsApiClient.updateActionItem(
                organizationSlug,
                dealRoomId,
                completedActionItemTwo.data.id,
                {
                  completedAt: DateTime.now().minus({ minutes: 30 }).toISO(),
                },
                { headers: { Authorization: `Bearer ${token}` } },
              );

              await refetchDealRoom();
            })();
          },
        },
        { key: 'divider', itemType: ContextualMenuItemType.Divider },
        {
          key: 'delete-all',
          text: 'Delete all',
          disabled: !actionItems.length,
          iconProps: { iconName: 'Delete' },
          onClick: () => {
            (async () => {
              const token = await getAccessTokenSilently();

              await Promise.all(
                actionItems.map((actionItem) =>
                  DealRoomsApiClient.deleteActionItem(
                    organizationSlug,
                    dealRoomId,
                    actionItem.id,
                    { headers: { Authorization: `Bearer ${token}` } },
                  ),
                ),
              );

              await refetchDealRoom();
            })();
          },
        },
      ],
    }),
    [
      actionItems,
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchDealRoom,
      userId,
    ],
  );

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Text variant="xLarge" className="title">
          Mutual progress plan
        </Text>
        <Text variant="medium" className="subtitle">
          Your necessary steps before you can make a decision
        </Text>
        <div className="actions">
          <IconButton
            iconProps={{ iconName: 'Settings' }}
            menuProps={menuProps}
            menuIconProps={{ style: { display: 'none' } }}
          />
        </div>
      </div>

      <div className={styles.actionItems}>
        {incompleteActionItems.map((actionItem) => {
          const artifactIds = actionItem.artifacts.map(
            (artifact) => artifact.id,
          );
          const artifactViewUsers = activity
            .filter(
              (a) =>
                a.type === 'VIEW_ARTIFACT' &&
                hasOwnNumberProperty(a.additionalDetails, 'artifactId') &&
                artifactIds.includes(a.additionalDetails.artifactId),
            )
            .map((activity) => activity.user)
            .filter(Truthy);

          const viewerMap = CollectToMap(artifactViewUsers, 'id');

          return (
            <DealRoomActionItem
              key={actionItem.id}
              organizationSlug={organizationSlug}
              dealRoomId={dealRoomId}
              id={actionItem.id}
              createdAt={actionItem.createdAt}
              dueDate={actionItem.dueDate}
              completedAt={actionItem.completedAt}
              text={actionItem.actionItem}
              creator={actionItem.creator}
              assignee={actionItem.assignee}
              artifacts={actionItem.artifacts}
              viewers={Object.values(viewerMap)}
              onCheckChanged={async () => {
                const token = await getAccessTokenSilently();
                const result = await DealRoomsApiClient.updateActionItem(
                  organizationSlug,
                  dealRoomId,
                  actionItem.id,
                  {
                    completedAt: actionItem.completedAt
                      ? null
                      : DateTime.now().toISO(),
                  },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );
                await refetchDealRoom();
              }}
              onArtifactClick={async (artifactId) =>
                navigate(
                  `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifact/${artifactId}`,
                )
              }
            />
          );
        })}
        {completeActionItems.map((actionItem) => {
          const artifactIds = actionItem.artifacts.map(
            (artifact) => artifact.id,
          );
          const artifactViewUsers = activity
            .filter(
              (a) =>
                a.type === 'VIEW_ARTIFACT' &&
                hasOwnNumberProperty(a.additionalDetails, 'artifactId') &&
                artifactIds.includes(a.additionalDetails.artifactId),
            )
            .map((activity) => activity.user)
            .filter(Truthy);

          const viewerMap = CollectToMap(artifactViewUsers, 'id');

          return (
            <DealRoomActionItem
              key={actionItem.id}
              organizationSlug={organizationSlug}
              dealRoomId={dealRoomId}
              id={actionItem.id}
              createdAt={actionItem.createdAt}
              dueDate={actionItem.dueDate}
              completedAt={actionItem.completedAt}
              text={actionItem.actionItem}
              creator={actionItem.creator}
              assignee={actionItem.assignee}
              artifacts={actionItem.artifacts}
              viewers={Object.values(viewerMap)}
              onCheckChanged={async () => {
                const token = await getAccessTokenSilently();
                const result = await DealRoomsApiClient.updateActionItem(
                  organizationSlug,
                  dealRoomId,
                  actionItem.id,
                  {
                    completedAt: actionItem.completedAt
                      ? null
                      : DateTime.now().toISO(),
                  },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );
                await refetchDealRoom();
              }}
              onArtifactClick={async (artifactId) =>
                navigate(
                  `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifact/${artifactId}`,
                )
              }
            />
          );
        })}
      </div>
    </div>
  );
};
