import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  Dropdown,
  IStackItemStyles,
  PrimaryButton,
  Stack,
  TextField,
  Text,
  mergeStyles,
  IStyle,
  FontWeights,
  FontSizes,
} from '@fluentui/react';
import { useForceUpdate } from '@fluentui/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { titleCase } from '@meetingflow/common/StringHelpers';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { DEFAULT_STACK_TOKENS } from '../../../../Helpers/Layout';
import useBreakpoints from '../../../../Hooks/useBreakpoints';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { ExternalServicesApiClient } from '../../../../Services/NetworkCommon';
import {
  settingsIndentedControlClass,
  settingsPrimaryButtonClass,
  settingsRootStackStyles,
  settingsSectionClass,
  settingsSectionDescriptionClass,
  settingsSectionDescriptionClassDark,
  settingsSubHeadClass,
  settingsSubSubHeadClass,
} from '../SettingsPageLayout';
import { useEffect } from 'react';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import toast from 'react-hot-toast';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { AutomaticMeetingRecordingPreference } from '@meetingflow/common/Api/data-contracts';

type CallRecorderPreferenceForm = {
  callRecorderName: string;
};

const callRecorderPreferenceSchema = yup
  .object({
    name: yup.string(),
  })
  .required();

export const EditUserOrganizationSettingsTabContent = () => {
  const { getAccessTokenSilently } = useAuth0();

  const forceUpdate = useForceUpdate();

  const breakpoints = useBreakpoints();

  const { isDark } = useLightOrDarkMode();

  const {
    configurationsWithToken,
    loading: externalServiceConfigurationsLoading,
    fetched: externalServiceConfigurationsFetched,
    refetchAll: refetchExternalServiceConfigurations,
  } = useExternalServiceConfigurations({
    withToken: true,
  });

  const {
    organization,
    slug: organizationSlug,
    role: organizationRole,
    hasEntitlement,
    isAdmin,
  } = useOrganization();

  const {
    user: userProfile,
    workspacePreferences,
    updateWorkspacePreferences,
  } = useUserProfile();

  const {
    setValue: setCallRecorderPreferenceFormValue,
    reset: resetCallRecorderPreferenceForm,
    handleSubmit: handleCallRecorderPreferenceFormSubmit,
    control: callRecorderPreferenceFormControl,
    formState: {
      isValid: callRecorderPreferenceFormIsValid,
      errors: callRecorderPreferenceFormErrors,
      isDirty: callRecorderPreferenceFormIsDirty,
    },
    watch: watchCallRecorderPreferenceForm,
  } = useForm<CallRecorderPreferenceForm>({
    resolver: yupResolver(callRecorderPreferenceSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  useEffect(() => {
    resetCallRecorderPreferenceForm({
      callRecorderName: workspacePreferences?.callRecorderName || '',
    });
  }, [resetCallRecorderPreferenceForm, workspacePreferences]);

  const onCallRecorderPreferenceFormSubmit =
    handleCallRecorderPreferenceFormSubmit((data) => {
      updateWorkspacePreferences?.({
        callRecorderName: data.callRecorderName || null,
      });
    });

  const { mutate: updateDefaultCustomFieldSetId } = useMutation(
    async ({
      tokenId,
      defaultCustomFieldSetId,
    }: {
      tokenId: number;
      defaultCustomFieldSetId: number | null;
    }) => {
      const token = await getAccessTokenSilently();
      const result = await ExternalServicesApiClient.setDefaultFieldSet(
        organizationSlug!,
        tokenId,
        { fieldSetId: defaultCustomFieldSetId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return result.data;
    },
    {
      onSettled: () => {
        refetchExternalServiceConfigurations().then(forceUpdate);
      },
    },
  );

  const halfWidthStackItemStyles = {
    root: {
      maxWidth: breakpoints.md ? 'calc(50% - 1rem)' : 'calc(100% - 1rem)',
    },
  } as IStackItemStyles;

  const hasCustomFieldsets = configurationsWithToken.some(
    (configuration) => configuration.externalServiceCustomFieldsets?.length,
  );

  return (
    <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
      {isAdmin ? (
        <Text
          style={{
            display: 'block',
            fontSize: FontSizes.mediumPlus,
            margin: '0 0 1rem 0',
            padding: '.5rem',
            backgroundColor: MEETINGFLOW_COLORS.orangeHighlight,
            borderRadius: '.25rem',
            color: MEETINGFLOW_COLORS.white,
          }}
        >
          Settings in this tab are for your user account within this Workspace.
          Changes here apply only to you, not to everyone in the Workspace.
        </Text>
      ) : null}

      <div className={settingsSectionClass}>
        <h2 className={settingsSubHeadClass}>
          Workspace Communication Preferences
        </h2>
        <Stack.Item grow={1} styles={halfWidthStackItemStyles}>
          <div className={settingsIndentedControlClass}>
            <Text
              className={
                isDark
                  ? mergeStyles(settingsSectionDescriptionClassDark as IStyle)
                  : mergeStyles(settingsSectionDescriptionClass as IStyle)
              }
            >
              Receive a regular email from Meetingflow detailing activity in
              this Workspace.
            </Text>
            <Checkbox
              styles={{ root: { marginBottom: '.5rem' } }}
              checked={
                userProfile?.preferenceReceivingDailyEmail === 'OPT_IN' &&
                workspacePreferences?.orgPreferenceReceivingDailyEmail ===
                  'OPT_IN'
              }
              disabled={
                !updateWorkspacePreferences ||
                userProfile?.preferenceReceivingDailyEmail === 'OPT_OUT'
              }
              onChange={(_e, checked) => {
                updateWorkspacePreferences?.({
                  orgPreferenceReceivingDailyEmail: !!checked
                    ? 'OPT_IN'
                    : 'OPT_OUT',
                });
              }}
              title="Receive a daily email about this workspace"
              label="Receive a daily email about this workspace"
            />
            <Checkbox
              checked={
                userProfile?.preferenceReceivingWeeklyEmail === 'OPT_IN' &&
                workspacePreferences?.orgPreferenceReceivingWeeklyEmail ===
                  'OPT_IN'
              }
              disabled={
                !updateWorkspacePreferences ||
                userProfile?.preferenceReceivingWeeklyEmail === 'OPT_OUT'
              }
              onChange={(_e, checked) => {
                updateWorkspacePreferences?.({
                  orgPreferenceReceivingWeeklyEmail: !!checked
                    ? 'OPT_IN'
                    : 'OPT_OUT',
                });
              }}
              title="Receive a weekly summary email about this workspace"
              label="Receive a weekly summary email about this workspace"
            />
          </div>
        </Stack.Item>
      </div>

      {hasEntitlement('CALL_RECORDING') ? (
        <div className={settingsSectionClass}>
          <h2 className={settingsSubHeadClass}>AI Note Taker</h2>

          <>
            <h3 className={settingsSubSubHeadClass}>
              AI Note Taker Preferences
            </h3>
            <Stack.Item grow={1} styles={halfWidthStackItemStyles}>
              <div className={settingsIndentedControlClass}>
                <Text
                  className={
                    isDark
                      ? mergeStyles(
                          settingsSectionDescriptionClassDark as IStyle,
                        )
                      : mergeStyles(settingsSectionDescriptionClass as IStyle)
                  }
                >
                  Meetingflow can automatically schedule recording for your
                  meetings.{' '}
                  <strong>
                    Calendar events marked private will never be automatically
                    recorded.
                  </strong>
                </Text>
                <Dropdown
                  styles={{ root: { marginBottom: '.5rem' } }}
                  selectedKey={
                    workspacePreferences?.orgPreferenceAutomaticCallRecording ||
                    'NONE'
                  }
                  options={[
                    { key: 'NONE', text: 'None' },
                    { key: 'INTERNAL', text: 'All Internal Meetings' },
                    { key: 'EXTERNAL', text: 'All External Meetings' },
                    {
                      key: 'ALL',
                      text: 'All Meetings (Internal and External)',
                    },
                  ]}
                  disabled={
                    !updateWorkspacePreferences ||
                    !['ADMIN', 'CREATOR'].includes(organizationRole || '') ||
                    !organization?.type ||
                    !['PAID', 'INTERNAL', 'VIP'].includes(organization.type)
                  }
                  onChange={(_e, option) => {
                    if (option) {
                      updateWorkspacePreferences?.({
                        orgPreferenceAutomaticCallRecording:
                          option.key as AutomaticMeetingRecordingPreference,
                      });
                    }
                  }}
                  title={
                    !['PAID', 'INTERNAL', 'VIP'].includes(
                      organization?.type ?? '',
                    )
                      ? 'Automatic call recording is a paid feature'
                      : !['ADMIN', 'CREATOR'].includes(organizationRole || '')
                        ? `Only admins and creators can enable automatic call recording`
                        : 'Automatically record meetings on your calendar'
                  }
                  label="Automatically record meetings on your calendar"
                />
              </div>
            </Stack.Item>
          </>

          <h3 className={settingsSubSubHeadClass}>AI Note Taker Bot Name</h3>
          <Stack.Item grow={1} styles={halfWidthStackItemStyles}>
            <div className={settingsIndentedControlClass}>
              <Text
                className={
                  isDark
                    ? mergeStyles(settingsSectionDescriptionClassDark as IStyle)
                    : mergeStyles(settingsSectionDescriptionClass as IStyle)
                }
              >
                When recording a video call, the name below will be used by the
                Meetingflow AI Note Taker bot.
              </Text>
              <Controller
                name="callRecorderName"
                control={callRecorderPreferenceFormControl}
                render={({ field: { value, onBlur, onChange } }) => (
                  <TextField
                    errorMessage={
                      callRecorderPreferenceFormErrors.callRecorderName?.message
                    }
                    disabled={!updateWorkspacePreferences}
                    placeholder={`${userProfile?.name}'s Call Recorder`}
                    label="Name"
                    value={value}
                    onChange={(_e, newValue) => onChange(newValue || '')}
                    onBlur={onBlur}
                    description="The name of your Meetingflow call recorder."
                  />
                )}
              />
            </div>
          </Stack.Item>

          <PrimaryButton
            disabled={
              !updateWorkspacePreferences ||
              !callRecorderPreferenceFormIsValid ||
              !callRecorderPreferenceFormIsDirty
            }
            text="Save"
            onClick={onCallRecorderPreferenceFormSubmit}
            className={settingsPrimaryButtonClass}
          />
        </div>
      ) : null}

      {hasEntitlement('CUSTOM_FIELDSETS') ? (
        <div className={settingsSectionClass}>
          {hasCustomFieldsets ? (
            <>
              <h2 className={settingsSubHeadClass}>Default CRM Fieldsets</h2>
              <div className={settingsIndentedControlClass}>
                <Text
                  className={
                    isDark
                      ? mergeStyles(
                          settingsSectionDescriptionClassDark as IStyle,
                        )
                      : mergeStyles(settingsSectionDescriptionClass as IStyle)
                  }
                >
                  Your Workspace Administrator has created multiple CRM
                  fieldsets for you to choose from. Select the default fieldsets
                  you would like to use when updating CRM objects.
                </Text>

                {configurationsWithToken
                  .filter(
                    (configuration) =>
                      configuration.externalServiceCustomFieldsets?.length &&
                      configuration.externalServiceUserTokens?.length,
                  )
                  .map((configuration) => {
                    return (
                      <Stack.Item
                        key={configuration.id}
                        grow={1}
                        styles={halfWidthStackItemStyles}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            columnGap: '1rem',
                          }}
                        >
                          <Text block>
                            <strong
                              style={{ fontWeight: FontWeights.semibold }}
                            >
                              {titleCase(configuration.app)}
                            </strong>{' '}
                            ({configuration.instanceId})
                          </Text>
                          <Dropdown
                            className="customFieldsetPicker"
                            styles={{ root: { minWidth: '200px' } }}
                            disabled={
                              externalServiceConfigurationsLoading &&
                              !externalServiceConfigurationsFetched
                            }
                            selectedKey={
                              configuration.externalServiceUserTokens![0]
                                .defaultCustomFieldSetId || -1
                            }
                            options={[
                              {
                                key: -1,
                                text: 'Default',
                                title: 'Default',
                              },
                              ...(configuration.externalServiceCustomFieldsets!.map(
                                (f) => ({
                                  key: f.id,
                                  text: f.name,
                                }),
                              ) ?? []),
                            ]}
                            onChange={(e, opt) => {
                              if (opt) {
                                updateDefaultCustomFieldSetId({
                                  tokenId:
                                    configuration.externalServiceUserTokens![0]!
                                      .id,
                                  defaultCustomFieldSetId:
                                    opt.key === -1 ? null : (opt.key as number),
                                });
                                toast.success(
                                  'Your default fieldset has been updated.',
                                );
                              }
                            }}
                          />
                        </div>
                      </Stack.Item>
                    );
                  })}
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </Stack>
  );
};
