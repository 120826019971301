import { TableRowItem } from './DealRoomArtifactsTable';
import {
  IButtonStyles,
  Icon,
  IContextualMenuProps,
  mergeStyles,
  PrimaryButton,
} from '@fluentui/react';
import { useShareDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useShareDealRoomArtifactDialog';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { useConfirmationDialog } from '../../../Hooks/Modals/useConfirmationDialog';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useConst } from '@fluentui/react-hooks';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

type ArtifactsTableActionsProps = {
  itemData: TableRowItem;
  organizationSlug: string;
  dealRoomId: number;
  refreshDealRoom: () => Promise<unknown>;
  refetchArtifacts: () => Promise<unknown>;
  openDetailsView: (id: DealRoomArtifact['id']) => unknown;
  onDelete: ((id: DealRoomArtifact['id']) => Promise<unknown>) | undefined;
};

export const ArtifactsTableActions = ({
  itemData,
  organizationSlug,
  dealRoomId,
  refreshDealRoom,
  refetchArtifacts,
  openDetailsView,
  onDelete,
}: ArtifactsTableActionsProps) => {
  const { userId } = useUserProfile();
  const { getAccessTokenSilently } = useAuth0();

  const {
    createDeferred: createConfirmShareDeferred,
    dialog: confirmShareDialog,
  } = useShareDealRoomArtifactDialog({
    organizationSlug: organizationSlug || '',
    artifactName: itemData?.name,
  });

  const {
    createDeferred: createConfirmDeleteDeferred,
    dialog: confirmDeleteDialog,
  } = useConfirmationDialog({
    title: itemData?.deletedAt ? 'Restore Artifact' : 'Delete Artifact',
    subText: itemData?.deletedAt
      ? 'Restoring this Artifact will restore it for all users. Are you sure you want to restore this Artifact?'
      : 'Deleting this  Artifact will delete it for all users and cannot be undone. Are you sure you want to delete this Artifact?',
    primaryAction: 'CANCEL',
  });

  const handleClickShare = useCallback(
    async (
      e:
        | React.MouseEvent<HTMLElement, MouseEvent>
        | React.KeyboardEvent<HTMLElement>
        | undefined,
    ) => {
      e?.preventDefault();
      e?.stopPropagation();

      try {
        const confirmShare = await createConfirmShareDeferred().promise;
        if (!confirmShare) {
          return;
        }

        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.shareArtifact(
            organizationSlug!,
            dealRoomId,
            itemData?.artifactId,
            {
              subject: confirmShare.subject,
              message: confirmShare.message,
              contacts: confirmShare.contacts,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Sharing Artifact',
            success: (_response) => {
              return 'Successfully shared Artifact';
            },
            error: (err: unknown) => {
              return 'Something went wrong sharing Artifact, please try again later';
            },
          },
        );

        await refreshDealRoom();
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [
      refreshDealRoom,
      organizationSlug,
      dealRoomId,
      itemData?.artifactId,
      createConfirmShareDeferred,
      getAccessTokenSilently,
    ],
  );

  const handleClickDelete = useCallback(
    async (
      e:
        | React.MouseEvent<HTMLElement, MouseEvent>
        | React.KeyboardEvent<HTMLElement>
        | undefined,
    ) => {
      e?.preventDefault();
      e?.stopPropagation();

      try {
        if (userId !== itemData?.creator?.id || !onDelete) {
          return;
        }

        const confirmDelete = await createConfirmDeleteDeferred().promise;

        if (!confirmDelete) {
          return;
        }

        const token = await getAccessTokenSilently();

        if (!itemData?.deletedAt) {
          // DELETE ITEM
          await toast.promise(
            DealRoomsApiClient.deleteArtifact(
              organizationSlug!,
              dealRoomId,
              itemData?.artifactId,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            ),
            {
              loading: 'Deleting Artifact',
              success: (_response) => {
                refetchArtifacts();
                onDelete(itemData?.artifactId);
                return 'Successfully deleted Artifact';
              },
              error: (err: unknown) => {
                return 'Something went wrong deleting Artifact, please try again later';
              },
            },
          );
        } else {
          // RESTORE ITEM
          await toast.promise(
            DealRoomsApiClient.undeleteArtifact(
              organizationSlug!,
              dealRoomId,
              itemData?.artifactId,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            ),
            {
              loading: 'Restoring Artifact',
              success: (_response) => {
                refetchArtifacts();
                onDelete(itemData?.artifactId);
                return 'Successfully restored Artifact';
              },
              error: (err: unknown) => {
                return 'Something went wrong restoring Artifact, please try again later';
              },
            },
          );
        }
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [
      organizationSlug,
      dealRoomId,
      itemData?.artifactId,
      itemData?.deletedAt,
      itemData?.creator?.id,
      onDelete,
      createConfirmDeleteDeferred,
      getAccessTokenSilently,
      userId,
      refetchArtifacts,
    ],
  );

  const containerStyles = mergeStyles({
    display: 'flex',
    alignItems: 'flex-end',
    gap: '.25rem',
  });

  const buttonStyles = {
    menuIcon: {
      display: 'none',
    },
    root: {
      padding: '0',
      minWidth: '2rem',
      backgroundColor: 'transparent !important',
      border: 'none',
      transition: 'all 0.3s ease-in-out',

      selectors: {
        i: { color: DEALROOMS_COLORS.themePrimary },
      },
    },
    rootDisabled: { opacity: '0.6', cursor: 'not-allowed' },
    rootHovered: {
      border: `1px solid ${DEALROOMS_COLORS.themePrimary}`,
      backgroundColor: 'transparent !important',
    },
    rootPressed: {
      border: `1px solid ${DEALROOMS_COLORS.themePrimary}`,
      backgroundColor: 'transparent !important',
    },
    rootFocused: {
      border: `1px solid ${DEALROOMS_COLORS.themePrimary}`,
      backgroundColor: 'transparent !important',
    },
    rootExpanded: {
      border: `1px solid ${DEALROOMS_COLORS.themePrimary}`,
      backgroundColor: 'transparent !important',
    },
  } as IButtonStyles;

  // @ts-ignore
  const menuProps = useConst<IContextualMenuProps>(() => ({
    styles: {
      root: {
        backgroundColor: DEALROOMS_COLORS.white,
      },
    },
    calloutProps: {
      styles: {
        root: {},
        container: {
          backgroundColor: DEALROOMS_COLORS.white,
          border: `1px solid ${DEALROOMS_COLORS.financialLightGray}`,

          '.ms-ContextualMenu-item': {
            color: DEALROOMS_COLORS.cloudburst,

            '.ms-ContextualMenu-item-linkText': {
              color: DEALROOMS_COLORS.themePrimary,
            },

            selectors: {
              ':hover': {
                color: DEALROOMS_COLORS.themePrimary,
                cursor: 'pointer',

                '.ms-ContextualMenu-itemText': {
                  color: DEALROOMS_COLORS.themePrimary,
                },

                i: {
                  color: DEALROOMS_COLORS.themePrimary,
                },
              },
            },

            i: {
              color: DEALROOMS_COLORS.cloudburst,
            },
          },
        },
      },
    },

    items: [
      {
        key: 'share',
        text: 'Share',
        iconProps: { iconName: 'Share' },
        onClick: async (e) => {
          await handleClickShare(e);
        },
      },
      {
        key: 'delete',
        text: itemData?.deletedAt ? 'Restore' : 'Delete',
        iconProps: { iconName: itemData?.deletedAt ? 'Undo' : 'Delete' },
        onClick: async (e) => {
          await handleClickDelete(e);
        },
      },
    ],
  }));

  return (
    <div className={containerStyles}>
      <PrimaryButton
        menuProps={menuProps}
        styles={buttonStyles}
        onClick={async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Icon iconName="More" />
      </PrimaryButton>
      {confirmShareDialog}
      {confirmDeleteDialog}
    </div>
  );
};
