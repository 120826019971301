import { ExternalServiceObjectType } from '@meetingflow/common/Api/data-contracts';

export const HUBSPOT_OBJECT_TYPES = {
  DEALS: 'deals',
  COMPANIES: 'companies',
  CONTACTS: 'contacts',
  MEETINGS: 'meetings',
  CALLS: 'calls',
  NOTES: 'notes',
  TASKS: 'tasks',
} as const;

type HubSpotObjectTypeKeys = keyof typeof HUBSPOT_OBJECT_TYPES;

export type HubSpotObjectType =
  (typeof HUBSPOT_OBJECT_TYPES)[HubSpotObjectTypeKeys];

export const HubspotToExernalObjectType: Record<
  HubSpotObjectType,
  ExternalServiceObjectType
> = {
  calls: 'CALL',
  companies: 'ACCOUNT',
  contacts: 'CONTACT',
  deals: 'DEAL',
  meetings: 'MEETING',
  notes: 'NOTE',
  tasks: 'TASK',
};
