import { useAuth0 } from '@auth0/auth0-react';
import { Checkbox, PrimaryButton, Stack, Link } from '@fluentui/react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from 'react';
import { PageLayout } from '../Layouts/PageLayout';
import { DEFAULT_STACK_TOKENS } from '../../Helpers/Layout';
import { Card } from '../Card';
import { DetailedUser } from '@meetingflow/common/Api/data-contracts';
import { UserApiClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';

type WelcomeTOSForm = {
  tosAccepted: boolean;
};

const formSchema = yup
  .object({
    tosAccepted: yup
      .boolean()
      .isTrue('You must accept the Terms of Service to continue')
      .required(),
  })
  .required();

export type WelcomeTOSProps = {
  user?: DetailedUser;
  refetchUser: () => Promise<unknown>;
};

export const WelcomeTOS = ({ user, refetchUser }: WelcomeTOSProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm<WelcomeTOSForm>({
    defaultValues: {
      tosAccepted: false,
    },
    resolver: yupResolver(formSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('tosAccepted', !!user?.tosAccepted);
  }, [setValue, user]);

  const onSubmit = handleSubmit(async (data) => {
    if (isValid && data.tosAccepted) {
      const token = await getAccessTokenSilently();
      const result = await UserApiClient.acceptTos({
        headers: { Authorization: `Bearer ${token}` },
      });
      if (result.status === 200) {
        reset({
          tosAccepted: !!result.data.tosAccepted,
        });
        refetchUser();
      }
    }
  });

  return (
    <div>
      <div className="welcome-wizard-form">
        <div className="elements column">
          <h4>Terms of Service</h4>
          <p>
            By using Meetingflow, you are agreeing to our{' '}
            <Link
              href="https://meetingflow.com/terms-of-service/"
              target="_blank"
            >
              Terms of Service
            </Link>
            . Please review and accept the terms to continue.
          </p>
          <Controller
            name="tosAccepted"
            control={control}
            render={({ field: { onChange, value, ...renderRest } }) => (
              <Checkbox
                label="I have read and agree to the Terms of Service"
                {...renderRest}
                checked={value}
                onChange={(_, checked) => onChange(!!checked)}
              />
            )}
          />
        </div>

        <div className="controls">
          <PrimaryButton disabled={!isValid} text="Next" onClick={onSubmit} />
        </div>
      </div>
    </div>
  );
};

export default WelcomeTOS;
