import { useAuth0 } from '@auth0/auth0-react';
import {
  Dropdown,
  FontIcon,
  FontSizes,
  FontWeights,
  NeutralColors,
  Spinner,
  Text,
  Toggle,
  TooltipHost,
  mergeStyles,
} from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import useBreakpoints from '../../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../../../Hooks/useLocalStorageState';
import { OrganizationRecentlyCreatedPlans } from '../../../QueryNames';
import { MeetingflowsApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { MeetingflowCard } from '../../MeetingPlans/MeetingflowCard';

export type MyPlansPaneProps = {
  organizationSlug: string;
};

export const MyPlansPane = ({ organizationSlug }: MyPlansPaneProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { email } = user!;
  const [sortOrder, setSortOrder] = useLocalStorageState<'desc' | 'asc'>(
    'ORG_HOME_PLANS_SORT_ORDER',
    'desc',
  );
  const [sortBy, setSortBy] = useLocalStorageState<
    'startTime' | 'createdAt' | 'updatedAt'
  >('ORG_HOME_PLANS_SORT_BY', 'startTime');
  const [onlyMine, setOnlyMine] = useLocalStorageState<boolean>(
    'ORG_HOME_PLANS_ONLY_MINE',
    true,
  );
  const breakpoints = useBreakpoints();
  const { isDark } = useLightOrDarkMode();
  const appInsights = useAppInsightsContext();
  const navigate = useNavigate();

  const {
    data: plansData,
    isLoading: plansLoading,
    isRefetching: plansRefetching,
    refetch,
  } = useQuery(
    OrganizationRecentlyCreatedPlans(organizationSlug),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.listPlans(
        {
          organizationSlug,
          participants: onlyMine ? [email!] : undefined,
          sortBy,
          sortOrder,
          limit: 20,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 15 minutes
      refetchInterval: 900000,
      staleTime: 900000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      retry: 1,
    },
  );

  useEffect(() => {
    refetch?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder, onlyMine]);

  const meetingflowsControlsClass = mergeStyles({
    position: 'sticky',
    top: 0,
    backgroundColor: isDark
      ? NeutralColors.gray200
      : MEETINGFLOW_COLORS.purpleUltraLight,
    height: '3.25rem',
    overflow: 'hidden',
    display: 'flex',
    columnGap: '.5rem',
    padding: '0 .5rem',
  });

  const dropdownStyles = {
    root: {
      marginBottom: '.5rem',
      flexGrow: 1,
      transition: '.3s ease-in-out all',
    },
    dropdown: {
      height: '1.5rem',
      lineHeight: '1.5rem',
      fontSize: FontSizes.small,
      backgroundColor: isDark
        ? NeutralColors.gray200
        : MEETINGFLOW_COLORS.purpleGrey,
      border: 'none',
      borderRadius: '.25rem',
    },
    title: {
      height: '1.5rem',
      lineHeight: '1.25rem',
      backgroundColor: isDark
        ? NeutralColors.gray180
        : MEETINGFLOW_COLORS.purpleGrey,
      border: 'none',
      borderRadius: '.25rem',
      color: isDark ? NeutralColors.gray80 : MEETINGFLOW_COLORS.purpleDark,
    },
    caretDownWrapper: {
      height: '1.5rem',
      lineHeight: '1.5rem',
    },
    label: {
      padding: 0,
      height: '.75rem',
      lineHeight: '.75rem',
      fontSize: FontSizes.mini,
      marginBottom: '.25rem',
      color: NeutralColors.gray120,
    },
  };

  return (
    <>
      <div className={meetingflowsControlsClass}>
        <Dropdown
          label="Sort By"
          options={[
            {
              key: 'startTime',
              text: breakpoints.lg ? 'Meeting Start' : 'Mtg Start',
            },
            {
              key: 'createdAt',
              text: breakpoints.lg ? 'Meetingflow Created' : 'Created',
            },
            {
              key: 'updatedAt',
              text: breakpoints.lg ? 'Meetingflow Updated' : 'Updated',
            },
          ]}
          onChange={(_, option) => {
            appInsights.trackEvent({
              name: 'HOME_PAGE_MEETINGFLOWS_CHANGE_CONTROLS',
              properties: {
                property: 'sortBy',
                organizationSlug,
                emailAddress: user?.email,
                sortBy: option?.key,
              },
            });

            setSortBy(option?.key as 'startTime' | 'createdAt' | 'updatedAt');
          }}
          selectedKey={sortBy}
          styles={dropdownStyles}
        />

        <Dropdown
          label="Sort Order"
          options={[
            { key: 'desc', text: breakpoints.lg ? 'Descending' : 'Desc' },
            { key: 'asc', text: breakpoints.lg ? 'Ascending' : 'Asc' },
          ]}
          onChange={(_, option) => {
            appInsights.trackEvent({
              name: 'HOME_PAGE_MEETINGFLOWS_CHANGE_CONTROLS',
              properties: {
                property: 'sortOrder',
                organizationSlug,
                emailAddress: user?.email,
                sortOrder: 'option?.key',
              },
            });

            setSortOrder(option?.key as 'desc' | 'asc');
          }}
          selectedKey={sortOrder}
          styles={dropdownStyles}
        />

        <div style={{ whiteSpace: 'nowrap' }}>
          <TooltipHost
            content={
              <>
                <Text
                  variant="mediumPlus"
                  block
                  style={{
                    fontWeight: FontWeights.semibold,
                    marginBottom: '.5rem',
                    fontSize: FontSizes.smallPlus,
                    color: MEETINGFLOW_COLORS.white,
                  }}
                >
                  Show Mine Only
                </Text>
                <Text
                  variant="small"
                  block
                  style={{
                    color: MEETINGFLOW_COLORS.white,
                    marginBottom: '.5rem',
                  }}
                >
                  <strong>On:</strong> Shows Meetingflows where you are an
                  attendee or organizer of the meeting, or where you are the
                  creator of the Meetingflow.
                </Text>
                <Text
                  variant="small"
                  block
                  style={{ color: MEETINGFLOW_COLORS.white }}
                >
                  <strong>Off:</strong> Shows all Meetingflows in your
                  workspace.
                </Text>
              </>
            }
            styles={{
              root: {
                margin: '0',
                display: 'inline-block',
                position: 'relative',
                top: '-.9rem',
                marginRight: '.25rem',
              },
            }}
            calloutProps={{
              backgroundColor: MEETINGFLOW_COLORS.teal,
              styles: {
                root: {
                  padding: 0,
                  minWidth: '12rem',
                  maxWidth: '18rem',
                  color: MEETINGFLOW_COLORS.white,
                },
                calloutMain: {
                  padding: '.5rem',
                  color: MEETINGFLOW_COLORS.white,
                },
              },
            }}
          >
            <FontIcon
              iconName="Info"
              className="toggle-info"
              style={{
                fontSize: '14px',
                color: NeutralColors.gray100,
                cursor: 'help',
                display: 'inline-block',
                position: 'relative',
              }}
            />
          </TooltipHost>

          <Toggle
            disabled={plansLoading || plansRefetching}
            label={breakpoints.lg ? 'Show Mine Only' : 'Mine Only'}
            checked={onlyMine === true}
            onChange={(e, checked) => {
              appInsights.trackEvent({
                name: 'HOME_PAGE_MEETINGFLOWS_CHANGE_CONTROLS',
                properties: {
                  property: 'onlyMine',
                  organizationSlug,
                  emailAddress: user?.email,
                  onlyMine: !!checked,
                },
              });

              setOnlyMine(!!checked);
            }}
            styles={{
              root: {
                display: 'inline-block',
                margin: 0,
                flexGrow: 1,
                transition: '.3s ease-in-out all',
                textAlign: 'right',
                position: 'relative',
                top: '.25rem',

                '.ms-Toggle-innerContainer': {
                  textAlign: 'right',
                  justifyContent: 'flex-end',
                  position: 'relative',
                  top: '.15rem',
                  button: {
                    transition: 'all .3s ease-in-out',
                    backgroundColor: isDark
                      ? NeutralColors.gray180
                      : MEETINGFLOW_COLORS.purpleGrey,
                  },
                },
                '.ms-Toggle-thumb': {
                  backgroundColor: onlyMine
                    ? MEETINGFLOW_COLORS.purpleTertiary
                    : isDark
                    ? NeutralColors.gray130
                    : NeutralColors.gray70,
                },
                'button:disabled .ms-Toggle-thumb': {
                  backgroundColor: NeutralColors.gray70,
                },
                'button:hover': {
                  backgroundColor: isDark
                    ? NeutralColors.gray190
                    : NeutralColors.gray40,
                },
                button: {
                  position: 'relative',
                  top: '  .1rem',
                },
              },
              label: {
                padding: 0,
                height: '.75rem',
                lineHeight: '.75rem',
                fontSize: FontSizes.mini,
                marginBottom: '.25rem',
                color: NeutralColors.gray120,
                lineClamp: 2,
                overflow: 'hidden',
                display: '-webkit-box',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
              },
              pill: {
                backgroundColor: NeutralColors.gray40,
                outlineWidth: `0 !important`,
                borderWidth: `0 !important`,
              },
            }}
          />
        </div>
      </div>

      {plansData?.data?.length && !plansLoading ? (
        <div
          style={{
            maxHeight: breakpoints.md ? `calc(100vh - 10rem)` : undefined,
            overflow: 'auto',
            padding: '0 .25rem',
          }}
        >
          {plansData?.data?.map((meetingflow) => (
            <div
              key={meetingflow.id}
              style={{
                padding: '.25rem 0',
                borderBottom: `1px solid ${
                  isDark ? NeutralColors.gray170 : NeutralColors.gray20
                }`,
              }}
            >
              <MeetingflowCard
                organizationSlug={organizationSlug!}
                meetingflowId={meetingflow.id}
                meetingflowObj={meetingflow}
                refetchMeetingflow={refetch}
                hideShowConferenceJoinButton
                showCompanies
                showCallRecordingButton
                onClick={() => {
                  navigate(
                    `/organization/${organizationSlug}/plan/${meetingflow.id}`,
                  );
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <>
          {plansLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: '3rem',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <Text
              block
              style={{
                textAlign: 'center',
                fontSize: FontSizes.medium,
                fontWeight: FontWeights.semibold,
                color: MEETINGFLOW_COLORS.teal,
                padding: '1rem',
              }}
            >
              There are no Meetingflows to show. 😢
            </Text>
          )}
        </>
      )}
    </>
  );
};
