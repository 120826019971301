import {
  FontIcon,
  FontSizes,
  FontWeights,
  Link,
  mergeStyles,
  Text,
} from '@fluentui/react';
import {
  Company,
  ExternalServiceObject,
  ExternalServiceObjectType,
} from '@meetingflow/common/Api/data-contracts';
import randomColor from 'randomcolor';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useExternalServiceConfigurations } from '../../../Hooks/useExternalServiceConfigurations';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { HubSpotPanelContext } from '../../../types/HubSpotPanelContext';
import { SalesforcePanelContext } from '../../../types/SalesforcePanelContext';
import { BaseSidePanel } from '../../MeetingPlans/SidePanels/BaseSidePanel';
import { CompanyRecentPlansList } from '../../MeetingPlans/SidePanels/CompanyRecentPlansList';
import { MeetingPlanHubSpotSuggestedCompaniesList } from '../../MeetingPlans/SidePanels/HubSpot/MeetingPlanHubSpotSuggestedCompaniesList';
import { MeetingPlanHubSpotSuggestedDealsList } from '../../MeetingPlans/SidePanels/HubSpot/MeetingPlanHubSpotSuggestedDealList';
import { MeetingPlanSalesforceSuggestedAccountList } from '../../MeetingPlans/SidePanels/Salesforce/MeetingPlanSalesforceSuggestedAccountsList';
import { MeetingPlanSalesforceSuggestedOpportunitiesList } from '../../MeetingPlans/SidePanels/Salesforce/MeetingPlanSalesforceSuggestedOpportunitiesList';

export interface CompanyOverviewSidePanelProps {
  organizationSlug: string;
  companyId: number;
  company: Pick<Company, 'name' | 'domains'>;
  onPickedSalesforceObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPickedHubSpotObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  pushSalesforcePanel: (context: SalesforcePanelContext) => void;
  pushHubSpotPanel: (context: HubSpotPanelContext) => void;
  showSalesforceObjectPicker?: (
    defaultTab?: ExternalServiceObjectType | 'PINNED',
  ) => void;
  showHubSpotObjectPicker?: (
    defaultTab?: ExternalServiceObjectType | 'PINNED',
  ) => void;
  onBack?: () => void;
  onClose?: () => void;
}

export const CompanyOverviewSidePanel = ({
  organizationSlug,
  companyId,
  company,
  onPickedSalesforceObject,
  onPickedHubSpotObject,
  pushSalesforcePanel,
  pushHubSpotPanel,
  showSalesforceObjectPicker,
  showHubSpotObjectPicker,
  onBack,
  onClose,
}: CompanyOverviewSidePanelProps) => {
  const navigate = useNavigate();

  const { configurationsWithToken: salesforceConfigurationsWithToken } =
    useExternalServiceConfigurations({
      app: 'SALESFORCE',
      withToken: true,
    });

  const { configurationsWithToken: hubspotConfigurationsWithToken } =
    useExternalServiceConfigurations({
      app: 'HUBSPOT',
      withToken: true,
    });

  const [activeSalesforceConfigurationId, setActiveSalesforceConfigurationId] =
    useState<number | undefined>();

  const [activeHubSpotConfigurationId, setActiveHubSpotConfigurationId] =
    useState<number | undefined>();

  useEffect(() => {
    if (salesforceConfigurationsWithToken?.length === 1) {
      setActiveSalesforceConfigurationId(
        salesforceConfigurationsWithToken[0].id,
      );
    }
  }, [salesforceConfigurationsWithToken]);

  useEffect(() => {
    if (hubspotConfigurationsWithToken?.length === 1) {
      setActiveHubSpotConfigurationId(hubspotConfigurationsWithToken[0].id);
    }
  }, [hubspotConfigurationsWithToken]);

  const sectionHeaderClass = mergeStyles({
    ':first-child': {
      margin: '.5rem 0 .5rem 0',
    },

    margin: '1rem 0 .5rem 0 ',
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semibold,
    color: MEETINGFLOW_COLORS.teal,
    position: 'relative',
    display: 'block',
  });

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  return (
    <BaseSidePanel title={company.name} onBack={onBack} onClose={onClose}>
      {activeSalesforceConfigurationId ? (
        <MeetingPlanSalesforceSuggestedAccountList
          organizationSlug={organizationSlug}
          salesforceConfigurationId={activeSalesforceConfigurationId}
          externalDomains={company.domains.map((d) => d.domain)}
          onPickedObject={onPickedSalesforceObject}
          pushSalesforcePanel={pushSalesforcePanel}
          showSalesforceObjectPicker={showSalesforceObjectPicker}
        />
      ) : null}

      {activeSalesforceConfigurationId ? (
        <MeetingPlanSalesforceSuggestedOpportunitiesList
          organizationSlug={organizationSlug}
          salesforceConfigurationId={activeSalesforceConfigurationId}
          externalDomains={company.domains.map((d) => d.domain)}
          onPickedObject={onPickedSalesforceObject}
          pushSalesforcePanel={pushSalesforcePanel}
          showSalesforceObjectPicker={showSalesforceObjectPicker}
        />
      ) : null}

      {activeHubSpotConfigurationId ? (
        <MeetingPlanHubSpotSuggestedCompaniesList
          organizationSlug={organizationSlug}
          configurationId={activeHubSpotConfigurationId}
          externalDomains={company.domains.map((d) => d.domain)}
          onPickedObject={onPickedHubSpotObject}
          pushHubSpotPanel={pushHubSpotPanel}
          showHubSpotObjectPicker={showHubSpotObjectPicker}
        />
      ) : null}

      {activeHubSpotConfigurationId ? (
        <MeetingPlanHubSpotSuggestedDealsList
          organizationSlug={organizationSlug}
          configurationId={activeHubSpotConfigurationId}
          externalDomains={company.domains.map((d) => d.domain)}
          onPickedObject={onPickedHubSpotObject}
          pushHubSpotPanel={pushHubSpotPanel}
          showHubSpotObjectPicker={showHubSpotObjectPicker}
        />
      ) : null}

      <Text block className={sectionHeaderClass}>
        Recent Meetingflows
        <Link
          style={{
            display: 'block',
            float: 'right',
            textAlign: 'right',
            fontWeight: FontWeights.semilight,
            fontSize: FontSizes.small,
            marginRight: 0,
            transition: '.3s ease-in-out all',
          }}
          onClick={() =>
            navigate(
              `/organization/${organizationSlug}/library/plans?companies=${companyId}`,
            )
          }
        >
          All Meetingflows
          <FontIcon
            style={{
              display: 'inline-block',
              position: 'relative',
              top: '.15rem',
              marginLeft: '.25rem',
            }}
            iconName="PageRight"
          />
        </Link>
      </Text>

      <CompanyRecentPlansList
        organizationSlug={organizationSlug}
        companyId={companyId}
        maxCount={10}
        onContactClick={(c) => {
          navigate(`/organization/${organizationSlug}/library/contacts/${c}`);
        }}
        color={color}
      />
    </BaseSidePanel>
  );
};
