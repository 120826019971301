import { useAuth0 } from '@auth0/auth0-react';
import { IColumn, SelectionMode, Text } from '@fluentui/react';
import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { StyledSpinner } from './StyledSpinner';
import { OrganizationInviteItem } from './OrganizationInviteItem';
import { InvitesQuery, OrganizationsQuery } from '../QueryNames';
import { PageLayout } from './Layouts/PageLayout';
import { titleCase } from '../Helpers/Typography';
import { cloneDeep } from 'lodash';
import { StyledDetailsList } from './StyledDetailsList';
import { useTitle } from '../Hooks/useTitle';
import { Card } from './Card';
import { useNavigate } from 'react-router';
import { EMPTY_ARRAY } from '../Constants';
import { OrganizationInvite } from '@meetingflow/common/Api/data-contracts';
import { InvitesApiClient } from '../Services/NetworkCommon';

const StyledDetailsListStyles = {
  root: {
    marginBottom: '1rem',
  },
};

export const UserInvites = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();
  const navigate = useNavigate();
  const { email } = user!;

  useTitle('Invites');

  const {
    data: inviteData,
    isLoading: invitesLoading,
    refetch: refetchInvites,
  } = useQuery(InvitesQuery('ALL'), async () => {
    const token = await getAccessTokenSilently();
    return InvitesApiClient.getInvites(
      {},
      { headers: { Authorization: `Bearer ${token}` } },
    );
  });

  const createdInvitations = useMemo(() => {
    if (inviteData?.data?.length) {
      return inviteData.data.filter(
        (invite) => invite.inviter.email === email!,
      );
    }
    return [];
  }, [email, inviteData]);

  const receivedInvitations = useMemo(() => {
    if (inviteData?.data?.length) {
      return inviteData.data.filter(
        (invite) => invite.invitee.email === email!,
      );
    }
    return [];
  }, [email, inviteData]);

  const onAcceptInvite = useCallback(
    async (slug: string) => {
      await client.invalidateQueries(InvitesQuery());
      await client.invalidateQueries(InvitesQuery('RECEIVED'));
      await client.invalidateQueries(OrganizationsQuery);
      navigate(`/organization/${slug}`);
    },
    [client, navigate],
  );

  const inviteColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'name',
        name: 'Name',
        minWidth: 100,
        maxWidth: 150,
        fieldName: 'name',
        onRender: (invite: OrganizationInvite) => <>{invite.invitee.name}</>,
      },
      {
        key: 'workspace',
        name: 'Workspace',
        minWidth: 100,
        maxWidth: 150,
        fieldName: 'organization.name',
        onRender: (invite: OrganizationInvite) => (
          <>{invite.organization.name}</>
        ),
      },
      {
        key: 'email',
        name: 'Email',
        minWidth: 120,
        maxWidth: 150,
        fieldName: 'invitee.email',
        onRender: (invite: OrganizationInvite) => <>{invite.invitee.email}</>,
      },
      {
        key: 'role',
        name: 'Role',
        minWidth: 100,
        maxWidth: 100,
        fieldName: 'role',
        onRender: (invite: OrganizationInvite) => <>{titleCase(invite.role)}</>,
      },
      {
        key: 'createdAt',
        name: 'Created',
        minWidth: 100,
        maxWidth: 100,
        fieldName: 'createdAt',
      },
      {
        key: 'delete',
        name: 'Delete',
        minWidth: 100,
        maxWidth: 100,
        onRender: (invite: OrganizationInvite) => (
          <OrganizationInviteItem
            key={invite.id}
            invite={invite}
            onAcceptInvite={() => onAcceptInvite(invite.organization.slug)}
            refreshInvites={refetchInvites}
          />
        ),
      },
    ],
    [onAcceptInvite, refetchInvites],
  );

  const receivedInviteColumns = useMemo(
    () =>
      cloneDeep(inviteColumns).map((c: IColumn) => {
        if (c.key === 'delete') {
          c.key = 'accept';
          c.name = 'Accept';
        }
        return c;
      }),
    [inviteColumns],
  );

  if (invitesLoading) {
    return <StyledSpinner />;
  }

  return (
    <PageLayout
      primaryContentWidthPercentage={70}
      primaryContent={
        <Card
          title="Invites"
          contentContainerPadding={'1rem'}
          description="Manage Meetingflow invites you have sent and received."
        >
          <Text block variant="large" style={{ margin: '0 0 .5rem 0' }}>
            Invites you've sent
          </Text>
          {createdInvitations.length ? (
            <StyledDetailsList
              items={createdInvitations || EMPTY_ARRAY}
              columns={inviteColumns}
              selectionMode={SelectionMode.none}
              styles={StyledDetailsListStyles}
            />
          ) : (
            <Text block style={{ fontStyle: 'italic' }}>
              You have no outstanding created invites.
            </Text>
          )}
          <Text
            block
            variant="large"
            style={{
              margin: `${createdInvitations.length ? '0' : '2rem'} 0 .5rem 0`,
            }}
          >
            Invites you've received
          </Text>
          {receivedInvitations.length ? (
            <StyledDetailsList
              items={receivedInvitations || EMPTY_ARRAY}
              columns={receivedInviteColumns}
              selectionMode={SelectionMode.none}
            />
          ) : (
            <Text block style={{ fontStyle: 'italic' }}>
              You have no outstanding received invites.
            </Text>
          )}
        </Card>
      }
    />
  );
};

export default UserInvites;
