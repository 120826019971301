import { IconButton, TextField, Text } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useAddDealRoomArtifactDialog } from '../../../../Hooks/Modals/DealRoom/useAddDealRoomArtifactDialog';
import { useCallback, useMemo, useState } from 'react';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useNavigate } from '../../../../Hooks/useNavigate';
import { useArtifactSidebarStyles } from './useSidebarStyles';
import { AttachmentsList } from './AttachmentsList';
import {
  AddArtifactIconProps,
  ChevronRightMedIconProps,
  ZoomIconProps,
} from '../../../../utils/iconProps';
import { useConfirmationDialog } from '../../../../Hooks/Modals/useConfirmationDialog';
import { ArtifactContextualMenu } from './ArtifactContextualMenu';
import DealRoomAsyncCommandBarButton from '../../Components/DealRoomButton';

type ArtifactsSidebarProps = {
  organizationSlug: string;
  dealRoomId: number;
  artifactId: number;
};

export const ArtifactsSidebar = ({
  organizationSlug,
  dealRoomId,
  artifactId,
}: ArtifactsSidebarProps) => {
  const [
    isSidebarOpen,
    {
      setTrue: setSidebarOpen,
      setFalse: setSidebarFalse,
      toggle: toggleSidebar,
    },
  ] = useBoolean(false);
  const [
    showArtifactMenu,
    {
      setTrue: setShowArtifactMenu,
      setFalse: setHideArtifactMenu,
      toggle: toggleShowUserMenu,
    },
  ] = useBoolean(false);
  const [searchedTerm, setSearchedTerm] = useState('');
  const [contextMenuTargetId, setContextMenuTargetId] = useState<number | null>(
    null,
  );

  const styles = useArtifactSidebarStyles(isSidebarOpen);

  const navigate = useNavigate();

  const {
    createDeferred: showAddDealRoomArtifactDialog,
    dialog: addDealRoomArtifactDialog,
  } = useAddDealRoomArtifactDialog({
    organizationSlug,
    dealRoomId,
  });
  const {
    createDeferred: createConfirmDeleteDeferred,
    dialog: confirmDeleteDialog,
  } = useConfirmationDialog({
    title: `Delete Artifact?`,
    subText:
      'Deleting this Artifact will delete it for all users and cannot be undone. Are you sure you want to delete this Artifact?',
    primaryAction: 'CANCEL',
    removeWhiteLabel: true,
  });

  const { dealRoom, refetch: refetchDealRoom } = useDealRoom(
    organizationSlug,
    dealRoomId,
  );

  const filteredArtifacts = useMemo(() => {
    if (Array.isArray(dealRoom?.artifacts)) {
      return dealRoom.artifacts.filter((artifact) => {
        const fileName = ('fileName' in artifact ? artifact.fileName || '' : '')
          .toLowerCase()
          .trim();
        const name = (artifact.name || '').toLowerCase().trim();

        const currentSearchedTerm = searchedTerm.toLowerCase().trim();

        return (
          (fileName.includes(currentSearchedTerm) ||
            name.includes(currentSearchedTerm)) &&
          !artifact.deletedAt
        );
      });
    }
    return [];
  }, [dealRoom?.artifacts, searchedTerm]);

  const handleOpenAddArtifact = useCallback(async () => {
    try {
      const result = await showAddDealRoomArtifactDialog().promise;
      if (result) {
        await refetchDealRoom();
      }
    } catch (error) {
      // no file selected
    }
  }, [refetchDealRoom, showAddDealRoomArtifactDialog]);

  const handleArtifactClick = useCallback(
    (newArtifactId: number) => {
      navigate(
        `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifact/${newArtifactId}`,
        { preserveQuery: true },
      );
    },
    [dealRoomId, navigate, organizationSlug],
  );

  const handleDismissContextualMenu = useCallback(() => {
    setHideArtifactMenu();
    setContextMenuTargetId(null);
  }, [setHideArtifactMenu, setContextMenuTargetId]);

  return (
    <div className={`${styles.sidePanelContentWrapper}`}>
      <IconButton
        className={styles.expandButton}
        iconProps={ChevronRightMedIconProps}
        onClick={toggleSidebar}
      />
      <div
        className={`${styles.sidePanelContent} ${isSidebarOpen ? styles.sidePanelContentVisible : ''}`}
      >
        <DealRoomAsyncCommandBarButton
          customClasses={styles.addAttachment}
          onClick={handleOpenAddArtifact}
          text="New Attachment"
          buttonStyleType="DEAL_ROOM"
          iconProps={AddArtifactIconProps}
        />
        <div className={styles.searchContainer}>
          <IconButton
            className={styles.searchButton}
            iconProps={ZoomIconProps}
          />
          <TextField
            value={searchedTerm}
            onChange={(e, newValue) => setSearchedTerm(newValue || '')}
            className={styles.searchInput}
            placeholder="Search Artifacts"
          />
        </div>
        <Text className={styles.attachmentListTitle}>Your Artifacts</Text>
        <div className={styles.attachmentListContainer}>
          <AttachmentsList
            attachmentList={filteredArtifacts}
            artifactId={artifactId}
            handleArtifactClick={handleArtifactClick}
            setShowArtifactMenu={setShowArtifactMenu}
            setContextMenuTargetId={setContextMenuTargetId}
          />
        </div>
        <div className={styles.endLine}></div>
      </div>
      <ArtifactContextualMenu
        showArtifactMenu={showArtifactMenu}
        contextMenuTargetId={contextMenuTargetId}
        handleDismissContextualMenu={handleDismissContextualMenu}
        artifactId={artifactId}
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        createConfirmDeleteDeferred={createConfirmDeleteDeferred}
      />
      {addDealRoomArtifactDialog}
      {confirmDeleteDialog}
    </div>
  );
};
