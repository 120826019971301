import { useAuth0 } from '@auth0/auth0-react';
import {
  DocumentCard,
  DocumentCardActions,
  DocumentCardActivity,
  DocumentCardTitle,
  IButtonProps,
  mergeStyleSets,
} from '@fluentui/react';
import {
  DealRoomArtifact,
  DealRoomArtifactBase,
} from '@meetingflow/common/Api/data-contracts';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { PropsWithChildren, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useShareDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useShareDealRoomArtifactDialog';
import { useConfirmationDialog } from '../../../Hooks/Modals/useConfirmationDialog';
import { useUserProfile } from '../../../Hooks/useProfile';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import classNames from 'classnames';

export type BaseArtifactCardProps = Pick<
  DealRoomArtifactBase,
  | 'id'
  | 'createdAt'
  | 'label'
  | 'name'
  | 'downvoteCount'
  | 'downvoted'
  | 'upvoteCount'
  | 'upvoted'
  | 'creator'
> & {
  className?: string;
  orgSlug: string;
  dealRoomId: number;
  viewCount?: number;
  refreshDealRoom: () => Promise<unknown>;
  onClick?: (artifactId: DealRoomArtifact['id']) => void;
  onDelete: (artifactId: number) => void;
};

export const BaseArtifactCard = ({
  className,
  id,
  createdAt,
  creator,
  name,
  label,
  downvoted,
  downvoteCount,
  upvoted,
  upvoteCount,
  children,
  dealRoomId,
  orgSlug,
  viewCount,
  refreshDealRoom,
  onClick,
  onDelete,
}: PropsWithChildren<BaseArtifactCardProps>) => {
  const { userId } = useUserProfile();
  const { getAccessTokenSilently } = useAuth0();

  const {
    createDeferred: createConfirmDeleteDeferred,
    dialog: confirmDeleteDialog,
  } = useConfirmationDialog({
    title: `Delete Artifact?`,
    subText:
      'Deleting this Artifact will delete it for all users and cannot be undone. Are you sure you want to delete this Artifact?',
    primaryAction: 'CANCEL',
  });

  const {
    createDeferred: createConfirmShareDeferred,
    dialog: confirmShareDialog,
  } = useShareDealRoomArtifactDialog({
    organizationSlug: orgSlug,
    artifactName: name,
  });

  const ownerId = creator?.id;
  const canDelete = ownerId === userId;

  const styles = mergeStyleSets({
    preview: {
      height: '10rem',
      overflow: 'hidden',
      '& div': { height: '100%' },
      '& img': { height: '100%', objectFit: 'cover' },
    },
  });

  const artifactActions: IButtonProps[] = useMemo(
    () =>
      [
        {
          iconProps: { iconName: upvoted ? 'LikeSolid' : 'Like' },
          text: 'Upvote',
          label: 'Upvote',
          onClick: async (e: {
            preventDefault: () => void;
            stopPropagation: () => void;
          }) => {
            e.preventDefault();
            e.stopPropagation();

            try {
              const token = await getAccessTokenSilently();
              await toast.promise(
                !upvoted
                  ? DealRoomsApiClient.upvoteArtifact(
                      orgSlug!,
                      dealRoomId,
                      id,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      },
                    )
                  : DealRoomsApiClient.removeArtifactUpvote(
                      orgSlug!,
                      dealRoomId,
                      id,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      },
                    ),
                {
                  loading: 'Upvoting Artifact',
                  success: (_response) => {
                    refreshDealRoom();
                    return `Upvoted Artifact`;
                  },
                  error: (err: unknown) => {
                    return 'Something went wrong upvoting Artifact, please try again later';
                  },
                },
              );
              await refreshDealRoom();
            } catch (err: unknown) {}
          },
        },
        {
          iconProps: { iconName: downvoted ? 'DislikeSolid' : 'Dislike' },
          text: 'Downvote',
          label: 'Downvote',
          onClick: async (e: {
            preventDefault: () => void;
            stopPropagation: () => void;
          }) => {
            e.preventDefault();
            e.stopPropagation();

            try {
              const token = await getAccessTokenSilently();
              await toast.promise(
                !downvoted
                  ? DealRoomsApiClient.downvoteArtifact(
                      orgSlug!,
                      dealRoomId,
                      id,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      },
                    )
                  : DealRoomsApiClient.removeArtifactDownvote(
                      orgSlug!,
                      dealRoomId,
                      id,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      },
                    ),
                {
                  loading: 'Downvoting Artifact',
                  success: (_response) => {
                    return 'Downvoted Artifact';
                  },
                  error: (err: unknown) => {
                    return 'Something went wrong downvoting Artifact, please try again later';
                  },
                },
              );
              await refreshDealRoom();
            } catch (err: unknown) {}
          },
        },
        {
          iconProps: { iconName: 'Share' },
          text: 'Share',
          label: 'Share',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick: async (e: {
            preventDefault: () => void;
            stopPropagation: () => void;
          }) => {
            e.preventDefault();
            e.stopPropagation();

            try {
              const confirmShare = await createConfirmShareDeferred().promise;

              if (!confirmShare) {
                return;
              }

              const token = await getAccessTokenSilently();
              await toast.promise(
                DealRoomsApiClient.shareArtifact(
                  orgSlug!,
                  dealRoomId,
                  id,
                  {
                    subject: confirmShare.subject,
                    message: confirmShare.message,
                    contacts: confirmShare.contacts,
                  },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                ),
                {
                  loading: 'Sharing Artifact',
                  success: (_response) => {
                    return 'Successfully shared m Artifact';
                  },
                  error: (err: unknown) => {
                    return 'Something went wrong sharing Artifact, please try again later';
                  },
                },
              );

              await refreshDealRoom();
            } catch (err: unknown) {}
          },
        },
        canDelete
          ? {
              iconProps: { iconName: 'Delete' },
              text: 'Delete',
              label: 'Delete',
              onClick: async (e: {
                preventDefault: () => void;
                stopPropagation: () => void;
              }) => {
                e.preventDefault();
                e.stopPropagation();

                try {
                  const confirmDelete =
                    await createConfirmDeleteDeferred().promise;

                  if (!confirmDelete) {
                    return;
                  }

                  const token = await getAccessTokenSilently();
                  await toast.promise(
                    DealRoomsApiClient.deleteArtifact(
                      orgSlug!,
                      dealRoomId,
                      id,
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      },
                    ),
                    {
                      loading: 'Deleting Artifact',
                      success: (_response) => {
                        onDelete(id);

                        return 'Successfully deleted Artifact';
                      },
                      error: (err: unknown) => {
                        return 'Something went wrong deleting Artifact, please try again later';
                      },
                    },
                  );

                  await refreshDealRoom();
                } catch (err: unknown) {}
              },
            }
          : undefined,
      ].filter(Truthy),
    [
      canDelete,
      createConfirmDeleteDeferred,
      createConfirmShareDeferred,
      dealRoomId,
      downvoted,
      getAccessTokenSilently,
      id,
      onDelete,
      orgSlug,
      refreshDealRoom,
      upvoted,
    ],
  );

  return (
    <>
      <DocumentCard
        className={classNames('artifact', className)}
        onClick={onClick ? () => onClick(id) : undefined}
      >
        <DocumentCardTitle title={label || name} />
        <div className={styles.preview}>{children}</div>
        <DocumentCardActivity
          activity={`Created ${humanizeDateTime(createdAt, { displayComponents: ['date'] })}`}
          people={
            creator && (!!creator.avatarFileUrl || !!creator.avatarUrl)
              ? [
                  {
                    name: creator.name ?? creator.email,
                    profileImageSrc: (creator.avatarFileUrl ||
                      creator.avatarUrl)!,
                  },
                ]
              : []
          }
        />
        <DocumentCardActions views={viewCount} actions={artifactActions} />
      </DocumentCard>
      {confirmDeleteDialog}
      {confirmShareDialog}
    </>
  );
};
