import {
  FontSizes,
  FontWeights,
  mergeStyles,
  NeutralColors,
  useTheme,
} from '@fluentui/react';
import classNames from 'classnames';
import { hexToRGB } from '../Helpers/ColorHelpers';
import { PRIORITY_COLORS, PRIORITY_COLORS_DARK } from '../Constants';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../Themes/Themes';

interface InsightChipProps {
  id?: string;
  className?: string;
  isActive?: boolean;
  priority: number;
  text: string;
  count: number;
  onClick: () => void;
}

export const InsightChip = ({
  id,
  className,
  isActive,
  priority,
  text,
  count,
  onClick,
}: InsightChipProps) => {
  const theme = useTheme();
  const { isDark } = useLightOrDarkMode();

  const chipStyles = mergeStyles({
    marginRight: '0.5rem',
    height: '1rem',
    backgroundColor: isActive
      ? MEETINGFLOW_COLORS.purpleTertiary
      : isDark
      ? PRIORITY_COLORS_DARK[priority.toString() as '1' | '2' | '3']
      : PRIORITY_COLORS[priority.toString() as '1' | '2' | '3'],
    borderRadius: '1rem',
    paddingLeft: '.5rem',
    paddingRight: '.25rem',
    marginBottom: '.25rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    minWidth: '4rem',
    maxWidth: '10rem',
    lineHeight: '.95rem',
    fontSize: FontSizes.xSmall,
    fontWeight: FontWeights.semibold,
    color: isActive
      ? MEETINGFLOW_COLORS.white
      : priority === 1
      ? isDark
        ? NeutralColors.gray70
        : NeutralColors.gray20
      : MEETINGFLOW_COLORS.white,
    cursor: 'pointer',
    transition: '.3s ease-in-out all',
    button: {
      height: '1rem',
      width: '1rem',
    },
    span: {
      display: 'inline-block',
      backgroundColor: isDark ? NeutralColors.gray220 : NeutralColors.white,
      height: '.75rem',
      minWidth: '.75rem',
      borderRadius: '.375rem',
      textAlign: 'center',
      marginLeft: '.5rem',
      lineHeight: '.7rem',
      padding: '0 2px',
      color: isActive
        ? MEETINGFLOW_COLORS.purpleTertiary
        : PRIORITY_COLORS[priority.toString() as '1' | '2' | '3'],
      fontSize: FontSizes.mini,
      fontWeight: FontWeights.semibold,
    },
    ':hover': {
      backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
      color: NeutralColors.white,
      button: {
        transition: '.3s ease-in-out all',
        backgroundColor: isDark ? NeutralColors.gray190 : NeutralColors.white,
        color: theme.palette.themePrimary,
      },
    },
  });

  return (
    <div
      id={id}
      className={classNames(chipStyles, className)}
      onClick={onClick}
      title={`${text} (${count})`}
    >
      {text}
      <span>{count}</span>
    </div>
  );
};
