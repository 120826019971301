import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  DefaultButton,
  FontIcon,
  FontSizes,
  FontWeights,
  Link,
  mergeStyles,
  NeutralColors,
  Spinner,
  Text,
} from '@fluentui/react';
import { CalendarEvent } from '@meetingflow/common/Api/data-contracts';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { debounce, isNaN, parseInt, toNumber } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  addArrayValue,
  deleteValue,
  deleteValues,
  getArrayValues,
  getValue,
  removeArrayValue,
  setValue,
} from '../../Helpers/SearchParamHelpers';
import useBreakpoints from '../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../Hooks/useOrganization';
import { OrganizationSearchQuery } from '../../QueryNames';
import { SearchApiClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { Card } from '../Card';
import { MeetingflowCard } from '../MeetingPlans/MeetingflowCard';
import { CompanyCard, ContactCard } from '../MeetingPlans/MeetingPlanAttendees';
import EventCard from './Library/EventCard';

const PAGE_SIZE = 25;

export const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getAccessTokenSilently } = useAuth0();
  const { slug: organizationSlug, internalDomains } = useOrganization();
  const navigate = useNavigate();
  const { isDark } = useLightOrDarkMode();
  const appInsights = useAppInsightsContext();

  const { md, lg } = useBreakpoints();

  const { queryKey, q, page, contactIds, companyIds, tagIds } = useMemo(() => {
    const queryPage = searchParams.has('page')
      ? parseInt(searchParams.get('page')!)
      : 0;

    const contactIds = getArrayValues(searchParams, 'contactIds')
      .map((id) => toNumber(id))
      .filter((id) => !isNaN(id))
      .filter(Truthy);

    const companyIds = getArrayValues(searchParams, 'companyIds')
      .map((id) => toNumber(id))
      .filter((id) => !isNaN(id))
      .filter(Truthy);

    const tagIds = getArrayValues(searchParams, 'tagIds').filter(Truthy);

    return {
      queryKey: searchParams.toString(),
      q: getValue(searchParams, 'q') ?? '*',
      page: isNaN(queryPage) ? 0 : queryPage || 0,
      contactIds,
      companyIds,
      tagIds,
    };
  }, [searchParams]);

  const {
    data: searchResults,
    isLoading: searchResultsLoading,
    isRefetching: searchResultsRefetching,
    refetch: refetchSearch,
  } = useQuery(OrganizationSearchQuery(organizationSlug!), async () => {
    const token = await getAccessTokenSilently();
    appInsights.trackEvent({
      name: 'EXECUTE_SEARCH',
      properties: {
        organizationSlug,
        query: q,
        contactFilter: !!contactIds?.length,
        companyFilter: !!companyIds?.length,
        tagIds: !!tagIds.length,
        page,
        includeEvents: true,
      },
    });
    return SearchApiClient.search(
      {
        organizationSlug: organizationSlug!,
        q,
        contactIds,
        companyIds,
        tagIds,
        limit: PAGE_SIZE,
        skip: page * PAGE_SIZE || 0,
        includeEvents: true,
        count: true,
        facet: true,
        highlight: true,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  const {
    resultQuery,
    totalResultCount,
    pages,
    companyFacets,
    contactFacets,
    tagFacets,
    events,
    meetingflows,
    contacts,
    companies,
  } = useMemo(() => {
    if (!searchResults?.data) {
      return {
        resultQuery: undefined,
        totalResultCount: undefined,
        pages: 0,
        companyFacets: [],
        contactFacets: [],
        tagFacets: [],
        events: [],
        meetingflows: [],
        contacts: [],
        companies: [],
      };
    }

    const results = searchResults?.data;

    appInsights.trackEvent({
      name: 'SEARCH_RESULTS',
      properties: {
        organizationSlug,
        query: results.query,
        contactFilter: !!contactIds.length,
        companyFilter: !!companyIds.length,
        eventCount: results.events.length,
        totalContactCount: results.contacts.count,
        contactCount: results.contacts.results.length,
        totalCompanyCount: results.companies.count,
        companyCount: results.companies.results.length,
        totalMeetingflowCount: results.meetingflows.count,
        meetingflowCount: results.meetingflows.results.length,
      },
    });

    return {
      resultQuery: results.query,
      totalResultCount: results.meetingflows.count,
      pages: results.meetingflows.count
        ? Math.floor(results.meetingflows.count / PAGE_SIZE)
        : 0,
      companyFacets: results.meetingflows.facets?.companies || [],
      contactFacets: results.meetingflows.facets?.contacts || [],
      tagFacets: results.meetingflows.facets?.tags || [],
      events: results.events || [],
      meetingflows: results.meetingflows.results || [],
      contacts: results.contacts.results || [],
      companies: results.companies.results || [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults?.data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(debounce(refetchSearch, 500), [
    refetchSearch,
  ]);

  useEffect(() => {
    debouncedRefetch({
      queryKey: OrganizationSearchQuery(organizationSlug!, queryKey),
    });
  }, [debouncedRefetch, organizationSlug, queryKey]);

  const internalCompanyFacets = useMemo(() => {
    return companyFacets.filter((facet) =>
      facet.company.domains.some((d: string) => internalDomains.includes(d)),
    );
  }, [companyFacets, internalDomains]);

  const externalCompanyFacets = useMemo(() => {
    return companyFacets.filter((facet) =>
      facet.company.domains.every((d: string) => !internalDomains.includes(d)),
    );
  }, [companyFacets, internalDomains]);

  const internalContactFacets = useMemo(() => {
    return contactFacets.filter((facet) =>
      internalDomains.includes(facet.contact.emailDomain),
    );
  }, [contactFacets, internalDomains]);

  const externalContactFacets = useMemo(() => {
    return contactFacets.filter(
      (facet) => !internalDomains.includes(facet.contact.emailDomain),
    );
  }, [contactFacets, internalDomains]);

  const searchClass = mergeStyles({
    display: md ? 'flex' : 'block',
    overflow: md ? 'hidden' : 'auto',
    width: md ? '100%' : `calc(100% - 1rem)`,
    height: '100%',
    maxHeight: md ? 'calc(100vh - 3.75rem)' : undefined,
    padding: md ? 0 : '.5rem',
    backgroundColor: isDark
      ? NeutralColors.gray220
      : MEETINGFLOW_COLORS.purpleUltraSuperLight,

    '.facets': {
      width: md ? '20%' : '100%',
      height: md ? 'calc(100vh - 3.75rem)' : undefined,
      overflowX: 'auto',
      overflowY: 'auto',
      display: md ? 'block' : 'none',
      padding: md ? '.5rem' : 0,
      backgroundColor: isDark
        ? NeutralColors.gray210
        : MEETINGFLOW_COLORS.purpleUltraSuperLight,

      '.facet-set': {
        padding: '0 1rem 0 0',
        textAlign: 'right',
        marginBottom: '1rem',
      },

      '.facet-set-title': {
        position: 'relative',
        display: 'block',
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.medium,
        color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,
        marginBottom: '0',
        lineHeight: '2rem',
        transition: '.3s ease-in-out all',
        paddingRight:
          searchResultsLoading || searchResultsRefetching ? '2rem' : 0,

        '+ .list-header': {
          marginTop: '.5rem',
        },
      },

      '.clear': {
        display: 'block',
        textAlign: 'right',
        justifyContent: 'flex-end',
        paddingRight: '1rem',

        button: { display: 'inline-block' },
      },

      '.ms-Checkbox': {
        textAlign: 'right',
        flexDirection: 'row-reverse',
        overflow: 'hidden',
      },

      '.ms-Checkbox-text': {
        color: isDark ? NeutralColors.gray100 : NeutralColors.gray160,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
      },

      '.ms-Checkbox-label': {
        textAlign: 'right',
        flexDirection: 'row-reverse',
        width: '100%',
        overflow: 'hidden',
      },

      '.ms-Checkbox-label span.count': {
        display: 'inline-block',
        backgroundColor: MEETINGFLOW_COLORS.teal,
        color: MEETINGFLOW_COLORS.white,
        minWidth: '1rem',
        height: '1rem',
        textAlign: 'center',
        borderRadius: '.5rem',
        fontSize: FontSizes.mini,
        lineHeight: '1rem',
        fontWeight: FontWeights.semibold,
        padding: '0 .15rem',
      },
    },

    '.results': {
      display: lg ? 'flex' : undefined,
      width: md ? '80%' : '100%',
      height: 'calc(100vh - 3.75rem)',
      padding: !lg ? (md ? '.5rem' : 0) : undefined,
      backgroundColor: isDark
        ? NeutralColors.gray220
        : MEETINGFLOW_COLORS.purpleUltraSuperLight,
      borderLeft: md
        ? `.25rem solid ${
            isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleUltraLight
          }`
        : undefined,

      '.primary': {
        width: lg ? `calc(66.66% - 1.5rem)` : '100%',
        height: lg ? '100%' : undefined,
        padding: lg ? '.5rem' : 0,
        paddingLeft: lg ? '1rem' : undefined,
        overflowY: 'auto',

        '.pagination': {
          display: 'block',
          textAlign: 'right',
          marginBottom: '1rem',
          position: 'sticky',
          bottom: '.25rem',
          padding: '.5rem',
          backgroundColor: isDark
            ? NeutralColors.gray220
            : MEETINGFLOW_COLORS.purpleUltraSuperLight,
          zIndex: 50,
          '.previous, .next': {
            display: 'inline-block',
            padding: '.25rem .5rem',
            borderRadius: '.25rem',
            cursor: 'pointer',
            transition: '.3s ease-in-out all',
            color: isDark
              ? MEETINGFLOW_COLORS.purpleGrey
              : MEETINGFLOW_COLORS.purpleMedium,
            fontSize: FontSizes.small,
            fontWeight: FontWeights.semibold,
            backgroundColor: isDark
              ? MEETINGFLOW_COLORS.purpleDarker
              : MEETINGFLOW_COLORS.purpleGrey,
            textDecoration: 'none !important',
            ':hover': {
              backgroundColor: isDark
                ? MEETINGFLOW_COLORS.purpleDarkest
                : MEETINGFLOW_COLORS.purpleMedium,
              color: MEETINGFLOW_COLORS.white,
            },
            i: {
              fontSize: FontSizes.small,
              position: 'relative',
              top: '.1rem',
              margin: '0 .25rem',
            },
            ':disabled': {
              backgroundColor: isDark
                ? NeutralColors.gray200
                : NeutralColors.gray20,
              cursor: 'not-allowed',
            },
          },
          '.next': { marginLeft: '.5rem' },
        },
      },

      '.secondary': {
        width: lg ? `calc(33.33% - 1rem)` : '100%',
        height: lg ? '100%' : undefined,
        padding: lg ? '.5rem' : 0,
        paddingRight: 0,
        overflowY: 'auto',
      },
    },

    '.list-header': {
      display: 'block',
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.mini,
      textTransform: 'uppercase',
      margin: '1rem 0 .25rem 0',
      color: isDark ? NeutralColors.gray80 : NeutralColors.gray120,
    },

    '.item-list': {
      '.item': {
        marginTop: '.25rem',
        paddingBottom: '.25rem',
        borderBottom: `1px solid ${
          isDark ? NeutralColors.gray170 : MEETINGFLOW_COLORS.purpleUltraLight
        }`,
        whiteSpace: 'pre-wrap',

        ':last-child': {
          borderBottom: 'none',
        },
      },
    },

    '.highlight-result': {
      display: 'block',
      position: 'relative',
      marginTop: '.5rem',
      marginLeft: '1rem',
      fontSize: FontSizes.small,
      color: isDark ? NeutralColors.gray90 : NeutralColors.gray140,
      backgroundColor: isDark
        ? NeutralColors.gray210
        : MEETINGFLOW_COLORS.purpleUltraLight,
      padding: '.5rem .25rem .25rem .5rem',
      borderRadius: '.25rem',
      width: 'calc(100% - 2.25rem)',

      '.highlight': { padding: '0 .05rem' },

      '.highlight-location': {
        display: 'block',
        backgroundColor: MEETINGFLOW_COLORS.orange,
        position: 'absolute',
        height: '1rem',
        top: '-.5rem',
        right: '-.5rem',
        color: MEETINGFLOW_COLORS.white,
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.mini,
        textTransform: 'uppercase',
        padding: '0 .25rem',
        borderRadius: '.25rem',
      },
    },
  });

  const checkboxStyles = useMemo(
    () => ({
      root: { marginBottom: '.25rem', minHeight: '1rem' },
      text: {
        fontSize: '.75rem',
        lineHeight: '1rem',
      },
      checkbox: { height: '1rem', width: '1rem', marginLeft: '.25rem' },
    }),
    [],
  );

  const meetingflowResults = useMemo(
    () =>
      meetingflows.length ? (
        <Card
          className="meetingflows"
          loading={searchResultsLoading || searchResultsRefetching}
          title={
            totalResultCount
              ? `Meetingflows (${totalResultCount})`
              : `Meetingflows`
          }
          smallHeader
          contentContainerPadding=".5rem"
        >
          <div className="item-list">
            {meetingflows.map((mf, idx) => {
              return (
                <div className="item" key={mf.id}>
                  <MeetingflowCard
                    key={mf.id}
                    organizationSlug={organizationSlug!}
                    meetingflowId={mf.id}
                    // @ts-ignore TODO: Wasn't sure how to handle type issue here - jcroft
                    meetingflow={{
                      ...mf,
                      companies: mf.companies.map((c) => ({
                        ...c,
                        domains: c.domains.map((d) => ({ domain: d })),
                      })),
                    }}
                    showCallRecordingButton
                    onClick={() => {
                      appInsights.trackEvent({
                        name: 'CLICK_SEARCH_RESULT',
                        properties: {
                          organizationSlug,
                          q: resultQuery,
                          type: 'meetingflow',
                          id: mf.id,
                          index: idx,
                          page,
                        },
                      });

                      navigate(
                        `/organization/${organizationSlug}/plan/${mf.id}`,
                      );
                    }}
                    hideShowConferenceJoinButton
                  />
                  {mf.highlights?.summary ? (
                    <>
                      {mf.highlights?.summary.map((highlight) => (
                        <div className="highlight-result" key={highlight}>
                          <Text className="highlight-location">Summary</Text>
                          {highlight.split('|||').map((txt, index) => (
                            <span
                              key={txt}
                              className="highlight"
                              style={{
                                backgroundColor:
                                  index % 2 === 0
                                    ? undefined
                                    : `rgba(255,255,0,.5)`,
                                color:
                                  index % 2 === 0
                                    ? undefined
                                    : MEETINGFLOW_COLORS.black,
                              }}
                            >
                              {txt}
                            </span>
                          ))}
                        </div>
                      ))}
                    </>
                  ) : null}
                  {mf.highlights?.objectives ? (
                    <>
                      {mf.highlights?.objectives.map((highlight) => (
                        <div className="highlight-result" key={highlight}>
                          <Text className="highlight-location">Plan</Text>
                          {highlight.split('|||').map((txt, index) => (
                            <span
                              key={txt}
                              className="highlight"
                              style={{
                                backgroundColor:
                                  index % 2 === 0
                                    ? undefined
                                    : `rgba(255,255,0,.5)`,
                                color: index % 2 === 0 ? undefined : 'black',
                              }}
                            >
                              {txt}
                            </span>
                          ))}
                        </div>
                      ))}
                    </>
                  ) : null}
                  {mf.highlights?.notes ? (
                    <>
                      {mf.highlights?.notes.map((highlight) => (
                        <div className="highlight-result" key={highlight}>
                          <Text className="highlight-location">Notes</Text>
                          {highlight.split('|||').map((txt, index) => (
                            <span
                              key={txt}
                              className="highlight"
                              style={{
                                backgroundColor:
                                  index % 2 === 0
                                    ? undefined
                                    : `rgba(255,255,0,.5)`,
                                color: index % 2 === 0 ? undefined : 'black',
                              }}
                            >
                              {txt}
                            </span>
                          ))}
                        </div>
                      ))}
                    </>
                  ) : null}
                  {mf.highlights?.transcript ? (
                    <>
                      {mf.highlights?.transcript.map((highlight) => (
                        <div className="highlight-result" key={highlight}>
                          <Text className="highlight-location">Transcript</Text>
                          {highlight.split('|||').map((txt, index) => (
                            <span
                              key={txt}
                              className="highlight"
                              style={{
                                backgroundColor:
                                  index % 2 === 0
                                    ? undefined
                                    : `rgba(255,255,0,.5)`,
                                color: index % 2 === 0 ? undefined : 'black',
                              }}
                            >
                              {txt}
                            </span>
                          ))}
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
              );
            })}
          </div>
        </Card>
      ) : null,
    [
      appInsights,
      meetingflows,
      navigate,
      organizationSlug,
      page,
      resultQuery,
      searchResultsLoading,
      searchResultsRefetching,
      totalResultCount,
    ],
  );

  const facetSpinner = (
    <Spinner
      styles={{
        root: {
          display: 'inline-block',
          margin: '0',
          position: 'absolute',
          top: '.25rem',
          right:
            searchResultsLoading || searchResultsRefetching ? '0' : '-2rem',
          transition: '.3s ease-in-out all',
        },
      }}
    />
  );

  return (
    <div className={searchClass}>
      <div className="facets">
        <div className="facet-set">
          <Text className="facet-set-title">
            Filter by Company{' '}
            {searchResultsLoading || searchResultsRefetching
              ? facetSpinner
              : null}
          </Text>
          {externalCompanyFacets.length ? (
            <>
              <Text className="list-header">External</Text>
              {externalCompanyFacets.map((f, idx) => (
                <Checkbox
                  styles={checkboxStyles}
                  key={f.company.id}
                  // @ts-ignore - label is a ReactNode
                  label={
                    <>
                      {f.company.name} <span className="count">{f.count}</span>
                    </>
                  }
                  checked={companyIds.includes(f.company.id)}
                  onChange={(_e, checked) => {
                    if (checked) {
                      appInsights.trackEvent({
                        name: 'CHANGE_SEARCH_FACETS',
                        properties: {
                          organizationSlug,
                          type: 'company',
                          internal: false,
                          action: 'add',
                          index: idx,
                        },
                      });
                      setSearchParams(
                        deleteValue(
                          addArrayValue(
                            searchParams,
                            'companyIds',
                            f.company.id.toString(),
                          ),
                          'page',
                        ),
                      );
                    } else {
                      appInsights.trackEvent({
                        name: 'CHANGE_SEARCH_FACETS',
                        properties: {
                          organizationSlug,
                          type: 'company',
                          internal: false,
                          action: 'remove',
                          index: idx,
                        },
                      });
                      setSearchParams(
                        deleteValue(
                          removeArrayValue(
                            searchParams,
                            'companyIds',
                            f.company.id.toString(),
                          ),
                          'page',
                        ),
                      );
                    }
                  }}
                />
              ))}
            </>
          ) : null}
          {internalCompanyFacets.length ? (
            <>
              <Text className="list-header">Internal</Text>
              {internalCompanyFacets.map((f, idx) => (
                <Checkbox
                  styles={checkboxStyles}
                  key={f.company.id}
                  // @ts-ignore - label is a ReactNode
                  label={
                    <>
                      {f.company.name} <span className="count">{f.count}</span>
                    </>
                  }
                  checked={companyIds.includes(f.company.id)}
                  onChange={(_e, checked) => {
                    if (checked) {
                      appInsights.trackEvent({
                        name: 'CHANGE_SEARCH_FACETS',
                        properties: {
                          organizationSlug,
                          type: 'company',
                          internal: true,
                          action: 'add',
                          index: idx,
                        },
                      });
                      setSearchParams(
                        deleteValue(
                          addArrayValue(
                            searchParams,
                            'companyIds',
                            f.company.id.toString(),
                          ),
                          'page',
                        ),
                      );
                    } else {
                      appInsights.trackEvent({
                        name: 'CHANGE_SEARCH_FACETS',
                        properties: {
                          organizationSlug,
                          type: 'company',
                          internal: true,
                          action: 'remove',
                          index: idx,
                        },
                      });
                      setSearchParams(
                        deleteValue(
                          removeArrayValue(
                            searchParams,
                            'companyIds',
                            f.company.id.toString(),
                          ),
                          'page',
                        ),
                      );
                    }
                  }}
                />
              ))}
            </>
          ) : null}
        </div>

        <div className="facet-set">
          <Text className="facet-set-title">
            Filter by Contact{' '}
            {searchResultsLoading || searchResultsRefetching
              ? facetSpinner
              : null}
          </Text>
          {externalContactFacets.length ? (
            <>
              <Text className="list-header">External</Text>

              {externalContactFacets.map((f, idx) => (
                <Checkbox
                  styles={checkboxStyles}
                  key={f.contact.id}
                  // @ts-ignore - label is a ReactNode
                  label={
                    <>
                      {f.contact.name || f.contact.email}{' '}
                      <span className="count">{f.count}</span>
                    </>
                  }
                  checked={contactIds.includes(f.contact.id)}
                  onChange={(_e, checked) => {
                    if (checked) {
                      appInsights.trackEvent({
                        name: 'CHANGE_SEARCH_FACETS',
                        properties: {
                          organizationSlug,
                          type: 'contact',
                          internal: false,
                          action: 'add',
                          index: idx,
                        },
                      });
                      setSearchParams(
                        deleteValue(
                          addArrayValue(
                            searchParams,
                            'contactIds',
                            f.contact.id.toString(),
                          ),
                          'page',
                        ),
                      );
                    } else {
                      appInsights.trackEvent({
                        name: 'CHANGE_SEARCH_FACETS',
                        properties: {
                          organizationSlug,
                          type: 'contact',
                          internal: false,
                          action: 'remove',
                          index: idx,
                        },
                      });
                      setSearchParams(
                        deleteValue(
                          removeArrayValue(
                            searchParams,
                            'contactIds',
                            f.contact.id.toString(),
                          ),
                          'page',
                        ),
                      );
                    }
                  }}
                />
              ))}
            </>
          ) : null}
          {internalContactFacets.length ? (
            <>
              <Text className="list-header">Internal</Text>
              {internalContactFacets.map((f, idx) => (
                <Checkbox
                  styles={checkboxStyles}
                  key={f.contact.id}
                  // @ts-ignore - label is a ReactNode
                  label={
                    <>
                      {f.contact.name || f.contact.email}{' '}
                      <span className="count">{f.count}</span>
                    </>
                  }
                  checked={contactIds.includes(f.contact.id)}
                  onChange={(_e, checked) => {
                    if (checked) {
                      appInsights.trackEvent({
                        name: 'CHANGE_SEARCH_FACETS',
                        properties: {
                          organizationSlug,
                          type: 'contact',
                          internal: true,
                          action: 'add',
                          index: idx,
                        },
                      });
                      setSearchParams(
                        deleteValue(
                          addArrayValue(
                            searchParams,
                            'contactIds',
                            f.contact.id.toString(),
                          ),
                          'page',
                        ),
                      );
                    } else {
                      appInsights.trackEvent({
                        name: 'CHANGE_SEARCH_FACETS',
                        properties: {
                          organizationSlug,
                          type: 'contact',
                          internal: true,
                          action: 'remove',
                          index: idx,
                        },
                      });
                      setSearchParams(
                        deleteValue(
                          removeArrayValue(
                            searchParams,
                            'contactIds',
                            f.contact.id.toString(),
                          ),
                          'page',
                        ),
                      );
                    }
                  }}
                />
              ))}
            </>
          ) : null}
        </div>

        <div className="facet-set">
          <Text className="facet-set-title">
            Filter by Tag{' '}
            {searchResultsLoading || searchResultsRefetching
              ? facetSpinner
              : null}
          </Text>
          {tagFacets.length ? (
            <>
              <Text className="list-header">Tags</Text>

              {tagFacets.map((f, idx) => (
                <Checkbox
                  styles={checkboxStyles}
                  key={f.tag.id}
                  // @ts-ignore - label is a ReactNode
                  label={
                    <>
                      #{f.tag.label || f.tag.id}{' '}
                      <span className="count">{f.count}</span>
                    </>
                  }
                  checked={tagIds.includes(f.tag.id)}
                  onChange={(_e, checked) => {
                    if (checked) {
                      appInsights.trackEvent({
                        name: 'CHANGE_SEARCH_FACETS',
                        properties: {
                          organizationSlug,
                          type: 'tag',
                          internal: false,
                          action: 'add',
                          index: idx,
                        },
                      });
                      setSearchParams(
                        deleteValue(
                          addArrayValue(
                            searchParams,
                            'tagIds',
                            f.tag.id.toString(),
                          ),
                          'page',
                        ),
                      );
                    } else {
                      appInsights.trackEvent({
                        name: 'CHANGE_SEARCH_FACETS',
                        properties: {
                          organizationSlug,
                          type: 'tag',
                          internal: false,
                          action: 'remove',
                          index: idx,
                        },
                      });
                      setSearchParams(
                        deleteValue(
                          removeArrayValue(
                            searchParams,
                            'tagIds',
                            f.tag.id.toString(),
                          ),
                          'page',
                        ),
                      );
                    }
                  }}
                />
              ))}
            </>
          ) : null}
        </div>

        <div className="clear">
          <DefaultButton
            iconProps={{ iconName: 'ClearFilter' }}
            label="Clear Filters"
            text="Clear Filters"
            alt="Clear Filters"
            styles={{ root: { margin: '0 auto', display: 'block' } }}
            disabled={
              !(
                getArrayValues(searchParams, 'contactIds').length ||
                getArrayValues(searchParams, 'companyIds').length
              )
            }
            onClick={() => {
              appInsights.trackEvent({
                name: 'CLEAR_SEARCH_FACETS',
                properties: {
                  organizationSlug,
                },
              });
              setSearchParams(
                deleteValues(searchParams, [
                  'page',
                  'contactIds',
                  'companyIds',
                ]),
              );
            }}
          >
            Clear Filters
          </DefaultButton>
        </div>
      </div>

      <div className="results">
        {searchResultsLoading || searchResultsRefetching ? facetSpinner : null}
        <div className="primary">
          {!searchResultsLoading &&
          !searchResultsRefetching &&
          !meetingflows.length &&
          !companies.length &&
          !contacts.length &&
          !events.length ? (
            <div className="no-results">
              There are no results matching "{q}".
            </div>
          ) : null}

          <div className="events">
            {events.length && organizationSlug ? (
              <Card
                className="events"
                title="Calendar Events"
                smallHeader
                contentContainerPadding=".5rem"
                contentContainerOverflow="visible"
              >
                {events.map((e: CalendarEvent) => (
                  <EventCard
                    event={e}
                    key={e.iCalUid}
                    organizationSlug={organizationSlug}
                    showCompanies
                  />
                ))}
              </Card>
            ) : null}
          </div>
          <div className="meetingflows">{meetingflowResults}</div>
          {pages > 0 ? (
            <div className="pagination">
              <Link
                className="previous"
                disabled={page <= 0}
                onClick={() => {
                  if (page > 0) {
                    setSearchParams(
                      setValue(
                        searchParams,
                        'page',
                        `${Math.min(page - 1, pages)}`,
                      ),
                    );
                  }
                }}
              >
                <FontIcon iconName="CaretLeftSolid8" />
                Previous page
              </Link>
              <Link
                className="next"
                disabled={page >= pages}
                onClick={() => {
                  if (page < pages) {
                    setSearchParams(
                      setValue(
                        searchParams,
                        'page',
                        `${Math.max(page + 1, 0)}`,
                      ),
                    );
                  }
                }}
              >
                Next page
                <FontIcon iconName="CaretRightSolid8" />
              </Link>
            </div>
          ) : null}
        </div>
        <div className="secondary">
          {companies.length ? (
            <Card
              className="companies"
              title="Companies"
              smallHeader
              contentContainerPadding=".5rem"
            >
              <div className="item-list">
                {companies.map((c, idx) => (
                  <div className="item" key={c.id}>
                    <CompanyCard
                      organizationSlug={organizationSlug!}
                      key={c.id}
                      // @ts-ignore
                      company={{
                        ...c,
                        domains: c.domains.map((d) => ({ domain: d })),
                      }}
                      onClick={() => {
                        appInsights.trackEvent({
                          name: 'CLICK_SEARCH_RESULT',
                          properties: {
                            organizationSlug,
                            q: resultQuery,
                            type: 'company',
                            id: c.id,
                            index: idx,
                            page,
                          },
                        });

                        navigate(
                          `/organization/${organizationSlug}/library/companies/${c.id}`,
                        );
                      }}
                    />
                    {c.highlights?.notes ? (
                      <>
                        {c.highlights?.notes.map((highlight) => (
                          <div className="highlight-result" key={highlight}>
                            <Text className="highlight-location">Notes</Text>
                            {highlight.split('|||').map((txt, index) => (
                              <span
                                key={txt}
                                className="highlight"
                                style={{
                                  backgroundColor:
                                    index % 2 === 0
                                      ? undefined
                                      : `rgba(255,255,0,.5)`,
                                  color: index % 2 === 0 ? undefined : 'black',
                                }}
                              >
                                {txt}
                              </span>
                            ))}
                          </div>
                        ))}
                      </>
                    ) : null}
                  </div>
                ))}
              </div>
            </Card>
          ) : null}
          {contacts.length ? (
            <Card
              className="contacts"
              title="Contacts"
              smallHeader
              contentContainerPadding=".5rem"
            >
              <div className="item-list">
                {contacts.map((c, idx) => (
                  <div className="item" key={c.id}>
                    <ContactCard
                      key={c.id}
                      organizationSlug={organizationSlug!}
                      domain={c.emailDomain}
                      contact={c}
                      hideBottomBorder
                      onContactClick={() => {
                        appInsights.trackEvent({
                          name: 'CLICK_SEARCH_RESULT',
                          properties: {
                            organizationSlug,
                            q: resultQuery,
                            type: 'contact',
                            id: c.id,
                            index: idx,
                            page,
                          },
                        });

                        navigate(
                          `/organization/${organizationSlug}/library/contacts/${c.id}`,
                        );
                      }}
                    />
                    {c.highlights?.notes ? (
                      <>
                        {c.highlights?.notes.map((highlight) => (
                          <div key={highlight} className="highlight-result">
                            <Text className="highlight-location">Notes</Text>
                            {highlight.split('|||').map((txt, index) => (
                              <span
                                key={txt}
                                className="highlight"
                                style={{
                                  backgroundColor:
                                    index % 2 === 0
                                      ? undefined
                                      : `rgba(255,255,0,.5)`,
                                  color: index % 2 === 0 ? undefined : 'black',
                                }}
                              >
                                {txt}
                              </span>
                            ))}
                          </div>
                        ))}
                      </>
                    ) : null}
                  </div>
                ))}
              </div>
            </Card>
          ) : null}
        </div>
      </div>
    </div>
  );
};
