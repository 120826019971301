import {
  Dropdown,
  FontIcon,
  FontSizes,
  FontWeights,
  IDropdownOption,
  IconButton,
  NeutralColors,
  Text,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { SummarizeContentPayload } from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../Hooks/useOrganization';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import GPTGenerateButton from './GPTGenerateButton';

const GPTModelOptions: IDropdownOption[] = [
  {
    key: 'gpt-3.5-turbo',
    text: 'ChatGPT',
  },
  {
    key: 'gpt-4',
    text: 'GPT-4',
  },
];

interface GPTOutputBoxProps {
  organizationSlug: string;
  outputLabel?: string;
  generateLabel?: string;
  generatingLabel?: string;
  regenerateLabel?: string;
  disabled?: boolean;
  disableEditResult?: boolean;
  defaultModel?: SummarizeContentPayload['model'];
  defaultSystemPrompt?: string;
  defaultPrompt?: string;
  onSystemPromptChange?: (value: string) => void;
  onPromptChange?: (value: string) => void;
  onGenerating?: () => void;
  onOutputChanged?: (value: string) => void;
  onGenerated?: (value: string) => void;
  onGeneratingChanged?: (isGenerating?: boolean) => void;
  surfaceName: string;
  generateImmediately?: boolean;
  regenerateOnSystemPromptChange?: boolean;
  regenerateOnPromptChange?: boolean;
  displayGPTControls?: boolean;
  hideLabels?: boolean;
  maxTokens?: number;
}

export const GPTOutputBox = ({
  organizationSlug,
  outputLabel = 'Output',
  generateLabel,
  generatingLabel,
  regenerateLabel,
  disabled,
  disableEditResult,
  defaultModel,
  defaultSystemPrompt,
  defaultPrompt,
  onSystemPromptChange,
  onPromptChange,
  onGenerating,
  onOutputChanged,
  onGenerated,
  onGeneratingChanged,
  surfaceName,
  generateImmediately,
  regenerateOnSystemPromptChange,
  regenerateOnPromptChange,
  displayGPTControls,
  hideLabels,
  maxTokens,
}: GPTOutputBoxProps) => {
  const { hasEntitlement } = useOrganization();

  const [model, setModel] = useState<SummarizeContentPayload['model']>(
    defaultModel ?? 'gpt-3.5-turbo',
  );
  const [systemPrompt, setSystemPrompt] = useState<string>(
    defaultSystemPrompt || '',
  );
  const [prompt, setPrompt] = useState<string>(defaultPrompt || '');
  const [output, setOutput] = useState<string>('');
  const [generating, setGeneratingState] = useState<boolean>(false);

  const [GPTControlsExpanded, setGPTControlsExpanded] =
    useState<boolean>(false);

  const { isDark } = useLightOrDarkMode();

  const setGenerating = useCallback(
    (isGenerating: boolean) => {
      onGeneratingChanged?.(isGenerating);
      setGeneratingState(isGenerating);
    },
    [onGeneratingChanged],
  );

  useEffect(() => {
    setPrompt(defaultPrompt || '');
  }, [defaultPrompt]);

  useEffect(() => {
    setSystemPrompt(defaultSystemPrompt || '');
  }, [defaultSystemPrompt]);

  const gptOutputBoxClass = mergeStyles({
    marginBottom: '.5rem',
    '.system.prompt': {
      paddingTop: '.5rem',
      transition: '.3s ease-in-out all',
      '.ms-Label': {
        fontSize: FontSizes.smallPlus,
      },
    },

    '.prompt': {
      paddingTop: '.5rem',
      transition: '.3s ease-in-out all',
      '.ms-Label': {
        fontSize: FontSizes.smallPlus,
      },
    },

    '.system-prompt': {
      paddingTop: '.5rem',
      transition: '.3s ease-in-out all',
      '.ms-Label': {
        fontSize: FontSizes.smallPlus,
      },
    },

    '.output': {
      position: 'relative',
      '.generate-button': {
        float: 'right',
      },
      '.output-input': {
        '.ms-TextField-fieldGroup': {
          width: '100%',
          border: 'none',

          textarea: {
            cursor: 'default !important',
            minHeight: '8rem',
            fontSize: '12px',
            lineHeight: '1.2rem',

            padding: '.5rem',
            width: '100%',
            height: 'auto',
            boxSizing: 'border-box',
            // fontFamily: 'Kalam',
            border: isDark
              ? `1px solid ${NeutralColors.gray180}`
              : `1px solid rgba(200, 198, 196)`,
            borderRadius: '.25rem',
            flexDirection: 'column',
            overflowWrap: 'break-word',
            overflow: 'auto',
            backgroundColor: isDark
              ? NeutralColors.gray220
              : MEETINGFLOW_COLORS.white,

            animationName: 'fadeInAnimation',
            animationDuration: '.3s',
            transitionTimingFunction: 'linear',
            animationIterationCount: '1',
            animationFillMode: 'forwards',
            transition: '.5s ease-in-out all',

            ':disabled': {
              color: 'white !important',
              backgroundColor: MEETINGFLOW_COLORS.teal,
              fontWeight: FontWeights.semibold,
              boxShadow: '0 0 10px inset rgba(0,0,0,.1)',
              cursor: 'not-allowed',

              border: isDark
                ? `5px solid ${MEETINGFLOW_COLORS.purpleGrey}`
                : `5px solid ${MEETINGFLOW_COLORS.purpleGrey}`,
            },
          },
        },
      },
    },

    '.gpt-controls': {
      transition: '.3s ease-in-out all',
      borderRadius: '.25rem',
      display: displayGPTControls ? 'block' : 'none',
      backgroundColor: GPTControlsExpanded
        ? isDark
          ? NeutralColors.gray210
          : MEETINGFLOW_COLORS.purpleGrey
        : 'transparent',
      padding: GPTControlsExpanded ? '.25rem' : '.25rem .25rem .25rem 0',
      margin: '.25rem 0',

      '.disclosure-link': {
        cursor: 'pointer',
        fontSize: FontSizes.small,
        color: isDark ? NeutralColors.gray120 : NeutralColors.gray140,

        i: {
          fontSize: FontSizes.xSmall,
          color: isDark ? NeutralColors.gray120 : NeutralColors.gray140,
          marginRight: '.25rem',
        },
      },

      '.content': {
        transition: '.3s ease-in-out all',
        maxHeight: GPTControlsExpanded ? 'auto' : '0',
        opacity: GPTControlsExpanded ? 1 : 0,
        // backgroundColor: MEETINGFLOW_COLORS.purpleGrey,
        padding: '0 .25rem',
      },
    },
  });

  return (
    <div
      className={classNames(
        gptOutputBoxClass,
        'gpt-output-box',
        `${displayGPTControls ? 'with-gpt-controls' : ''}`,
        `${GPTControlsExpanded ? 'gpt-controls-expanded' : ''}`,
      )}
    >
      <div className="output">
        {navigator.clipboard && !disabled && !generating ? (
          <IconButton
            iconProps={{ iconName: 'Copy' }}
            onClick={() => {
              navigator.clipboard.writeText(output);
              toast.success(`Copied ${outputLabel} to the clipboard.`);
            }}
            styles={{
              root: {
                width: '1rem',
                height: '1rem',
                position: 'absolute',
                top: '2rem',
                right: '.25rem',
                zIndex: '500',
              },
              icon: { fontSize: '10px' },
            }}
            title={`Copy ${outputLabel} to clipboard`}
          />
        ) : null}
        <TextField
          disabled={generating || disableEditResult}
          className="output-input"
          // Ignore because I want some JSX here, which works fine, but TS wants it to be a string. - jcroft
          // @ts-ignore
          label={
            <div>
              {hideLabels ? undefined : outputLabel}
              {hasEntitlement('CHAT_GPT') ? (
                <GPTGenerateButton
                  organizationSlug={organizationSlug}
                  model={model}
                  systemPrompt={systemPrompt}
                  prompt={prompt}
                  onReset={() => {
                    setOutput('');
                    onOutputChanged?.('');
                  }}
                  onGenerating={onGenerating}
                  onToken={(generated) => {
                    setOutput(generated);
                    onOutputChanged?.(generated);
                  }}
                  onSuccess={(generated) => {
                    setOutput(generated);
                    onGenerated?.(generated);
                  }}
                  onGeneratingChanged={setGenerating}
                  disabled={disabled}
                  surfaceName={surfaceName}
                  label={generateLabel}
                  generatingLabel={generatingLabel}
                  regenerateLabel={regenerateLabel}
                  generateImmediately={generateImmediately}
                  regenerateOnPromptChange={regenerateOnPromptChange}
                  regenerateOnSystemPromptChange={
                    regenerateOnSystemPromptChange
                  }
                  maxTokens={maxTokens}
                />
              ) : null}
            </div>
          }
          value={output}
          multiline
          readOnly
          onChange={(_e, newValue) => {
            setOutput(newValue ?? '');
          }}
        />
      </div>

      {hasEntitlement('CHAT_GPT') ? (
        <div className="gpt-controls">
          <Text
            className="disclosure-link"
            onClick={() => setGPTControlsExpanded(!GPTControlsExpanded)}
          >
            <FontIcon
              iconName={
                GPTControlsExpanded ? 'ChevronDownSmall' : 'ChevronRightSmall'
              }
            />
            GPT Controls
          </Text>
          <div className="content">
            <div className="model">
              <Dropdown
                selectedKey={model}
                options={GPTModelOptions}
                onChange={(e, option) => {
                  if (option) {
                    setModel(option.key as SummarizeContentPayload['model']);
                  }
                }}
              />
            </div>
            <div className="system-prompt">
              <TextField
                className="system-prompt-input"
                label={hideLabels ? undefined : 'System Prompt'}
                value={systemPrompt}
                multiline
                description="System Prompt"
                onChange={(_e, newValue) => {
                  setSystemPrompt(newValue ?? '');
                  onSystemPromptChange?.(newValue || '');
                }}
                styles={{
                  field: {
                    fontSize: FontSizes.small,
                  },
                }}
              />
            </div>
            <div className="prompt">
              <TextField
                className="prompt-input"
                label={hideLabels ? undefined : 'Prompt'}
                value={prompt}
                description="Prompt"
                multiline
                onChange={(_e, newValue) => {
                  setPrompt(newValue ?? '');
                  onPromptChange?.(newValue || '');
                }}
                styles={{
                  field: {
                    fontSize: FontSizes.small,
                  },
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default GPTOutputBox;
