import {
  FontSizes,
  FontWeights,
  IconButton,
  IStyle,
  mergeStyles,
  Text,
} from '@fluentui/react';
import {
  ConferenceInfo,
  Meetingflow,
} from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';
import { useOrganization } from '../Hooks/useOrganization';
import MeetIcon from '../Static/Images/meet.png';
import SkypeForBusinessIcon from '../Static/Images/sfb.png';
import SkypeIcon from '../Static/Images/skype.png';
import WebExIcon from '../Static/Images/webex.png';
import ZoomIcon from '../Static/Images/zoom.png';
import { MEETINGFLOW_COLORS } from '../Themes/Themes';

export type JoinConferenceButtonProps = {
  event: Pick<Meetingflow, 'startTime' | 'endTime' | 'conferenceInfo'>;
  iconSize?: number;
  showWhenNotNowOrSoon?: boolean;
  showTextLabel?: boolean;
  showHoverBackground?: boolean;
};

const DISPLAY_NAMES: Record<ConferenceInfo['type'], string> = {
  msTeams: 'Teams',
  zoom: 'Zoom',
  googleMeet: 'Meet',
  skypeForBusiness: 'Skype',
  skype: 'Skype',
  ciscoWebEx: 'WebEx',
};

export const JoinConferenceButton = React.memo(
  ({
    event,
    iconSize = 32,
    showWhenNotNowOrSoon = false,
    showTextLabel = false,
    showHoverBackground = false,
  }: JoinConferenceButtonProps) => {
    const { organization } = useOrganization();
    const appInsights = useAppInsightsContext();
    const { isDark } = useLightOrDarkMode();

    const eventIsNowOrSoon =
      DateTime.fromISO(event.startTime).diffNow('minutes').minutes < 60 &&
      DateTime.fromISO(event.endTime).diffNow('minutes').minutes > -10;

    const iconSrc: string | undefined = useMemo(() => {
      if (!event.conferenceInfo?.type) {
        return;
      }

      switch (event.conferenceInfo.type) {
        case 'zoom':
          return ZoomIcon;
        case 'googleMeet':
          return MeetIcon;
        case 'skypeForBusiness':
          return SkypeForBusinessIcon;
        case 'skype':
          return SkypeIcon;
        case 'msTeams':
          return 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/teams_48x1.svg';
        case 'ciscoWebEx':
          return WebExIcon;
        default:
          return;
      }
    }, [event.conferenceInfo?.type]);

    const IconProps = useMemo(
      () => ({
        imageProps: {
          src: iconSrc,
          style: {
            width: iconSize,
            height: iconSize,
            // filter: eventIsNowOrSoon ? undefined : 'grayscale(100%)',
            display: 'block',
            margin: '0 auto',
          },
        },
      }),
      [iconSize, iconSrc],
    );

    const IconButtonStyles = useMemo(
      () => ({
        root: {
          textAlign: 'center',
          width: showTextLabel ? `${iconSize * 2}px + .5rem` : iconSize,
          backgroundColor: 'transparent !important',
          height: showTextLabel ? 'auto' : iconSize,
          borderRadius: '.25rem',
          padding: '.25rem 0 0 0 ',
          display: 'inline-block',
          transition: '.3s background-color ease-in-out',
          ':hover': {
            backgroundColor: 'transparent !important',
          },
          // padding: 0,
          '.ms-Icon': {
            height: iconSize,
            width: iconSize,
            margin: '0 auto !important',
          },
          '.ms-Button-flexContainer': {
            display: 'block',
          },
          '.ms-Button-textContainer': {
            display: 'block',
          },
        },
        rootHovered: {
          backgroundColor: showHoverBackground
            ? `${MEETINGFLOW_COLORS.purpleGrey} !important`
            : undefined,
          '.join-conference-label': {
            color: MEETINGFLOW_COLORS.purpleDark,
            fontWeight: FontWeights.semibold,
          },
        },
        rootDisabled: {
          backgroundColor: 'transparent !important',
        },
      }),
      [iconSize, showHoverBackground, showTextLabel],
    );

    const OnIconClick = useCallback(() => {
      window.open(event.conferenceInfo?.joinUri, '_blank');
      appInsights.trackEvent({
        name: 'JOIN_CONFERENCE_CALL',
        properties: {
          organizationId: organization?.id,
          type: event?.conferenceInfo?.type,
        },
      });
    }, [
      appInsights,
      event.conferenceInfo?.joinUri,
      event.conferenceInfo?.type,
      organization?.id,
    ]);

    const LabelClass: IStyle = useMemo(
      () =>
        mergeStyles({
          width: `${iconSize * 2}px`,
          height: '1rem',
          textAlign: 'center',
          fontSize: FontSizes.mini,
          fontWeight: FontWeights.semibold,
          lineHeight: '1rem',
          position: 'relative',
          top: '-.25rem',
          color: isDark
            ? MEETINGFLOW_COLORS.white
            : MEETINGFLOW_COLORS.purpleDark,
        }),
      [iconSize, isDark],
    );

    return event.conferenceInfo?.joinUri &&
      (eventIsNowOrSoon || showWhenNotNowOrSoon) ? (
      <IconButton
        iconProps={IconProps}
        styles={IconButtonStyles}
        onClick={OnIconClick}
        disabled={showWhenNotNowOrSoon ? false : !eventIsNowOrSoon}
        title={`Join ${DISPLAY_NAMES[event.conferenceInfo.type]} meeting`}
      >
        {showTextLabel ? (
          <Text
            block
            className={classNames([LabelClass, 'join-conference-label'])}
          >
            Join {DISPLAY_NAMES[event.conferenceInfo.type]}
          </Text>
        ) : null}
      </IconButton>
    ) : null;
  },
);
