import {
  FontIcon,
  FontSizes,
  FontWeights,
  Link,
  mergeStyles,
  NeutralColors,
  Spinner,
  Text,
} from '@fluentui/react';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { useQuery } from 'react-query';
import { SalesforceOpportunitySuggestionsQuery } from '../../../../QueryNames';
import { SalesforcePanelContext } from '../../../../types/SalesforcePanelContext';
import { SalesforceOpportunityTile } from './SalesforceOpportunityTile';
import { SalesforceOpportunity } from '../../../../Models/Salesforce/SalesforceOpportunity';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import {
  ExternalServiceObject,
  ExternalServiceObjectType,
} from '@meetingflow/common/Api/data-contracts';

export type MeetingPlanSalesforceSuggestedOpportunitiesListProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  salesforceConfigurationId: number;
  externalDomains?: string[];
  onPickedObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPinnedObject?:
    | ((e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>)
    | undefined;
  pushSalesforcePanel: (context: SalesforcePanelContext) => void;
  associatedObjects?: ExternalServiceObject[];
  showTitle?: boolean;
  title?: string;
  showSalesforceObjectPicker?: (
    defaultTab?: ExternalServiceObjectType | 'PINNED',
  ) => void;
  maxItems?: number;
  hideIfNoContent?: boolean;
};
export const MeetingPlanSalesforceSuggestedOpportunitiesList = ({
  organizationSlug,
  meetingPlanId,
  salesforceConfigurationId,
  externalDomains,
  onPickedObject,
  onPinnedObject,
  pushSalesforcePanel,
  associatedObjects = [],
  showTitle = true,
  title = 'Suggested Opportunities',
  showSalesforceObjectPicker,
  maxItems,
  hideIfNoContent,
}: MeetingPlanSalesforceSuggestedOpportunitiesListProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { loading: configurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const {
    data: salesforceOpportunitySuggestions,
    isLoading: salesforceOpportunitySuggestionsLoading,
    isRefetching: salesforceOpportunitySuggestionsRefetching,
    refetch: refetchSalesforceOpportunitySuggestions,
  } = useQuery(
    SalesforceOpportunitySuggestionsQuery(
      organizationSlug!,
      salesforceConfigurationId,
      undefined,
      externalDomains,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceOpportunity[]>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/opportunities`,
        {
          params: {
            active: true,
            domains: externalDomains,
            limit: maxItems,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 5 min
      staleTime: 300000,
      enabled: !!externalDomains?.length,
    },
  );

  useEffect(() => {
    refetchSalesforceOpportunitySuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    meetingPlanId,
    salesforceConfigurationId,
    organizationSlug,
    externalDomains,
  ]);

  const listClass = mergeStyles({
    position: 'relative',
    animationName: 'fadeInSlideAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    width: 'auto',
    transition: '.3s ease-in-out all',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    rowGap: '.125rem',
  });

  const slideSpinnerClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
  });

  if (configurationsLoading) {
    return (
      <div style={{ margin: '1rem 0' }}>
        <Spinner className={slideSpinnerClass} />
      </div>
    );
  }

  const sectionSubHeaderClass = mergeStyles({
    margin: '0 0 .5rem 0!important',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.semibold,
    color: NeutralColors.gray100,
    position: 'relative',
    display: 'block',
    width: '100%',
  });

  const slideSpinnerInHeaderClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
    position: 'absolute',
    top: showSalesforceObjectPicker ? '-.25rem' : 0,
    right: '0',
  });

  const salesforceOpps = salesforceOpportunitySuggestions?.data
    ?.filter((opportunity) => {
      const associatedObjectIds = associatedObjects.map((o) => o.externalId);
      return !associatedObjectIds.includes(opportunity.Id);
    })
    ?.slice(0, maxItems);

  return (
    <div
      className={listClass}
      style={{
        display:
          (hideIfNoContent && salesforceOpportunitySuggestionsLoading) ||
          (hideIfNoContent &&
            !salesforceOpportunitySuggestionsLoading &&
            salesforceOpps?.length === 0) ||
          !salesforceOpps
            ? 'none'
            : 'block',
      }}
    >
      {showTitle ? (
        <Text block className={sectionSubHeaderClass}>
          {title}
          {showSalesforceObjectPicker ? (
            <Link
              style={{
                display: 'block',
                float: 'right',
                textAlign: 'right',
                fontWeight: FontWeights.semilight,
                fontSize: FontSizes.small,
                marginRight:
                  salesforceOpportunitySuggestionsLoading ||
                  salesforceOpportunitySuggestionsRefetching
                    ? '2rem'
                    : 0,
                transition: '.3s ease-in-out all',
              }}
              onClick={() => showSalesforceObjectPicker('DEAL')}
            >
              All
              <FontIcon
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  top: '.15rem',
                  marginLeft: '.25rem',
                }}
                iconName="PageRight"
              />
            </Link>
          ) : null}
          {salesforceOpportunitySuggestionsLoading ||
          salesforceOpportunitySuggestionsRefetching ? (
            <Spinner className={slideSpinnerInHeaderClass} />
          ) : null}
        </Text>
      ) : null}
      {salesforceOpps && salesforceOpps?.length > 0 ? (
        <div>
          {salesforceOpps.map((opp) => {
            const externalServiceObject = {
              externalId: opp.Id,
              objectType: 'DEAL',
              name: opp.Name,
              serviceConfigurationId: salesforceConfigurationId,
              serviceConfiguration: configurationById(
                salesforceConfigurationId,
              )!,
            } as ExternalServiceObject;

            return (
              <SalesforceOpportunityTile
                organizationSlug={organizationSlug}
                meetingPlanId={meetingPlanId}
                key={opp.Id}
                externalId={opp.Id}
                name={opp.Name}
                accountName={opp.Account?.Name}
                showExternalLink
                onClickAccountName={(e?: React.MouseEvent<HTMLElement>) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  if (opp.Account?.Id) {
                    pushSalesforcePanel({
                      name: opp.Account?.Name,
                      objectId: opp.Account?.Id,
                      objectType: 'DEAL',
                      serviceConfigurationId: salesforceConfigurationId,
                    });
                  }
                }}
                salesforceInstance={
                  configurationById(salesforceConfigurationId)!.instanceId!
                }
                onClick={() => onPickedObject(externalServiceObject)}
                isLoading={false}
                onPinClick={onPinnedObject}
              />
            );
          })}
        </div>
      ) : (
        <>
          {salesforceOpportunitySuggestionsLoading ||
          salesforceOpportunitySuggestionsRefetching ? null : (
            <Text
              block
              variant="small"
              style={{ fontStyle: 'italic', margin: '.5rem 0' }}
            >
              There are no suggested opportunities.
            </Text>
          )}
        </>
      )}
    </div>
  );
};
