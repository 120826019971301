import {
  humanizeDateTime,
  HumanizeDateTimeOptions,
} from '@meetingflow/common/DateHelpers';
import { DateTime } from 'luxon';
import React, { CSSProperties } from 'react';

type Props = {
  dateTime?: string | DateTime | null;
  style?: CSSProperties;
  className?: string;
  title?: string;
} & HumanizeDateTimeOptions;

const StyledDateTime = React.memo(
  ({ style, className, title, dateTime, ...rest }: Props) => (
    <span className={className} style={style} title={title}>
      {humanizeDateTime(dateTime, rest)}
    </span>
  ),
);

export default StyledDateTime;
