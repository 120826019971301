export const SALESFORCE_OBJECT_TYPE = {
  ACCOUNT: 'Account',
  CONTACT: 'Contact',
  LEAD: 'Lead',
  OPPORTUNITY: 'Opportunity',
  TASK: 'Task',
  USER: 'User',
} as const;

type SalesforceObjectTypeKeys = keyof typeof SALESFORCE_OBJECT_TYPE;

export type SalesforceObjectType =
  (typeof SALESFORCE_OBJECT_TYPE)[SalesforceObjectTypeKeys];
