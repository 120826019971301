import { useAuth0 } from '@auth0/auth0-react';
import { Text, FontIcon, mergeStyles, IStyle, Stack } from '@fluentui/react';
import { DEFAULT_STACK_TOKENS } from '../../../../Helpers/Layout';
import { OrganizationInvitesQuery } from '../../../../QueryNames';
import { InvitesApiClient } from '../../../../Services/NetworkCommon';
import { OrganizationMembers } from '../OrganizationMembers';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useQuery } from 'react-query';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import OrganizationInvites from '../../OrganizationInvites';
import OrganizationInviteMember from '../../OrganizationInviteMember';
import {
  settingsIndentedControlClass,
  settingsSectionDescriptionClass,
  settingsSectionDescriptionClassDark,
  settingsSectionDescriptionIconClass,
  settingsSubHeadClass,
} from '../SettingsPageLayout';
import { OrganizationInvite } from '@meetingflow/common/Api/data-contracts';

export const MembershipsTabContent = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const { name: orgName, slug: orgSlug, isAdmin } = useOrganization();

  const {
    data: inviteData,
    isLoading: invitesLoading,
    refetch: refetchInvites,
  } = useQuery(
    OrganizationInvitesQuery(orgSlug),
    async () => {
      const token = await getAccessTokenSilently();
      return InvitesApiClient.getOrgInvites(
        { organizationSlug: orgSlug! },
        { headers: { Authorization: `Bearer ${token}` } },
      );
    },
    { enabled: isAdmin },
  );

  return (
    <Stack tokens={DEFAULT_STACK_TOKENS}>
      {inviteData?.data?.length ? (
        <>
          <h2 className={settingsSubHeadClass}>Outstanding Invites</h2>
          <div className={settingsIndentedControlClass}>
            <Text
              className={
                isDark
                  ? mergeStyles(settingsSectionDescriptionClassDark as IStyle)
                  : mergeStyles(settingsSectionDescriptionClass as IStyle)
              }
            >
              <FontIcon
                iconName="Info"
                className={settingsSectionDescriptionIconClass}
              />
              The following individuals have been invited to join {orgName}, but
              have not yet responded.
            </Text>
            <OrganizationInvites />
          </div>
        </>
      ) : null}

      <h2 className={settingsSubHeadClass}>Invite Someone to Join {orgName}</h2>
      <div className={settingsIndentedControlClass}>
        <OrganizationInviteMember
          onInvite={(invite: OrganizationInvite) => {
            refetchInvites();
          }}
        />
      </div>

      <h2 className={settingsSubHeadClass}>
        Current Members and Guests of {orgName}
      </h2>
      <div className={settingsIndentedControlClass}>
        <OrganizationMembers organizationSlug={orgSlug!} />
      </div>
    </Stack>
  );
};
