import { useAuth0 } from '@auth0/auth0-react';
import {
  IColumn,
  mergeStyles,
  Selection,
  SelectionMode,
  useTheme,
  Text,
  Link,
  NeutralColors,
} from '@fluentui/react';
import { useForceUpdate } from '@fluentui/react-hooks';
import { OrganizationRequest } from '@meetingflow/common/Api/data-contracts';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { EMPTY_ARRAY } from '../../Constants';
import { titleCase } from '../../Helpers/Typography';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../Hooks/useOrganization';
import { useTitle } from '../../Hooks/useTitle';
import { OrganizationPendingRequestsQuery } from '../../QueryNames';
import { RequestsApiClient } from '../../Services/NetworkCommon';
import { OrganizationSlugRouteParams } from '../../types/RouteParams';
import { Card } from '../Card';
import { AsyncCommandBarButton, AsyncDefaultButton } from '../HOC/AsyncButton';
import { PageLayout } from '../Layouts/PageLayout';
import StyledDateTime from '../StyledDateTime';
import { StyledDetailsList } from '../StyledDetailsList';

const getItemKey: <T extends { key: string }>(
  item: T,
  index?: number | undefined,
) => string = (item) => item.key;

export type OrganizationRequestsProps = {};

export const OrganizationRequests = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { organizationSlug } = useParams<OrganizationSlugRouteParams>();
  const { organization, name } = useOrganization(organizationSlug);
  const isDark = useLightOrDarkMode();
  const navigate = useNavigate();

  useTitle('Pending Requests');

  const forceUpdate = useForceUpdate();

  const theme = useTheme();

  const {
    data: requestsData,
    isLoading: requestsLoading,
    refetch: refetchRequests,
  } = useQuery(
    OrganizationPendingRequestsQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return RequestsApiClient.getOrgRequests(
        {
          organizationSlug: organizationSlug!,
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );
    },
  );

  const selection = useMemo(() => {
    return new Selection({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: forceUpdate,
    });
  }, [forceUpdate]);

  const requestColumns: IColumn[] = [
    {
      key: 'name',
      name: 'Name',
      minWidth: 100,
      fieldName: 'request.requester.name',
      onRender: (request: OrganizationRequest) => <>{request.requester.name}</>,
    },
    {
      key: 'email',
      name: 'Email',
      minWidth: 120,
      fieldName: 'request.requester.email',
      onRender: (request: OrganizationRequest) => (
        <>{request.requester.email}</>
      ),
    },
    {
      key: 'role',
      name: 'Role',
      minWidth: 100,
      fieldName: 'role',
      onRender: (request: OrganizationRequest) => (
        <>{titleCase(request.role)}</>
      ),
    },
    {
      key: 'createdAt',
      name: 'Requested',
      minWidth: 100,
      fieldName: 'createdAt',
      onRender: (request: OrganizationRequest) => (
        <StyledDateTime dateTime={request.createdAt} />
      ),
    },
    {
      key: 'approve',
      name: 'Approve',
      minWidth: 128,
      headerClassName: 'center-align',
      className: 'center-align',
      onRender: (request: OrganizationRequest) => (
        <AsyncDefaultButton
          iconProps={{
            iconName: 'BoxCheckmarkSolid',
            style: { color: theme.semanticColors.successIcon },
          }}
          key={request.id}
          onClick={async () => {
            const token = await getAccessTokenSilently();
            await RequestsApiClient.approveRequest(
              organizationSlug!,
              request.id,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            );
            refetchRequests();
          }}
        >
          Approve
        </AsyncDefaultButton>
      ),
    },
    {
      key: 'deny',
      name: 'Deny',
      minWidth: 128,
      headerClassName: 'center-align',
      className: 'center-align',
      onRender: (request: OrganizationRequest) => (
        <AsyncDefaultButton
          iconProps={{
            iconName: 'BoxMultiplySolid',
            style: { color: theme.semanticColors.errorIcon },
          }}
          key={request.id}
          onClick={async () => {
            const token = await getAccessTokenSilently();
            await RequestsApiClient.deleteRequest(
              organizationSlug!,
              request.id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            );
            refetchRequests();
          }}
        >
          Deny
        </AsyncDefaultButton>
      ),
    },
  ];

  const commandBarClass = mergeStyles({
    margin: '0 0 .5rem 0',
    button: {
      height: '2rem',
    },
  });

  return (
    <PageLayout
      primaryContentWidthPercentage={70}
      primaryContentLoading={requestsLoading}
      primaryContent={
        requestsData?.data?.length ? (
          <Card
            title="Pending Requests"
            contentContainerPadding={'1rem'}
            description={`${requestsData.data.length} users want to join ${organization?.name}! Approve or deny their requests here.`}
          >
            <div className={commandBarClass}>
              <AsyncCommandBarButton
                iconProps={{ iconName: 'AddGroup' }}
                onClick={async () => {
                  const token = await getAccessTokenSilently();
                  await RequestsApiClient.approveRequests(
                    organizationSlug!,
                    { ids: requestsData.data.map((r) => r.id) },
                    { headers: { Authorization: `Bearer ${token}` } },
                  );
                  refetchRequests();
                }}
              >
                Approve all
              </AsyncCommandBarButton>
              <AsyncCommandBarButton
                iconProps={{ iconName: 'UserRemove' }}
                disabled={selection.getSelectedCount() === 0}
                onClick={async () => {
                  const selected =
                    // eslint-disable-next-line max-len
                    selection.getSelection() as OrganizationRequest[];
                  if (!selected.length) {
                    return;
                  }
                  const token = await getAccessTokenSilently();
                  await RequestsApiClient.approveRequests(
                    organizationSlug!,
                    { ids: selected.map((r) => r.id) },
                    { headers: { Authorization: `Bearer ${token}` } },
                  );
                  refetchRequests();
                }}
              >
                Approve selected
              </AsyncCommandBarButton>
              <AsyncCommandBarButton
                iconProps={{ iconName: 'UserRemove' }}
                onClick={async () => {
                  const token = await getAccessTokenSilently();
                  await RequestsApiClient.denyRequests(
                    organizationSlug!,
                    { ids: requestsData.data.map((r) => r.id) },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    },
                  );
                  refetchRequests();
                }}
              >
                Deny all
              </AsyncCommandBarButton>
              <AsyncCommandBarButton
                iconProps={{ iconName: 'AddGroup' }}
                disabled={selection.getSelectedCount() === 0}
                onClick={async () => {
                  const selected =
                    // eslint-disable-next-line max-len
                    selection.getSelection() as OrganizationRequest[];
                  if (!selected.length) {
                    return;
                  }
                  const token = await getAccessTokenSilently();
                  await RequestsApiClient.denyRequests(
                    organizationSlug!,
                    {
                      ids: selected.map((r) => r.id),
                    },
                    { headers: { Authorization: `Bearer ${token}` } },
                  );
                  refetchRequests();
                }}
              >
                Deny selected
              </AsyncCommandBarButton>
            </div>
            <div>
              <StyledDetailsList
                items={requestsData?.data || EMPTY_ARRAY}
                columns={requestColumns}
                selectionMode={SelectionMode.multiple}
                selection={selection}
                getKey={getItemKey}
                setKey={'multiple'}
              />
            </div>
          </Card>
        ) : (
          <Card
            title="Pending Requests"
            contentContainerPadding={'1rem'}
            description={`There are currently no requests to join ${organization?.name}.`}
          >
            <Text
              block
              style={{
                fontStyle: 'italic',
                padding: '1rem',
                borderRadius: '.25rem',
                color: isDark ? NeutralColors.gray160 : NeutralColors.gray200,
                backgroundColor: theme?.semanticColors?.warningBackground,
              }}
            >
              This screen shows requests from non-members to join your
              organization, {organization?.name}.<br />
              At this time, there are no requests to join.
            </Text>

            <Text block style={{ marginTop: '1rem' }}>
              You can view pending invites for others to join {name} at{' '}
              <Link
                onClick={() => {
                  navigate(`/organization/${organizationSlug}/invites`);
                }}
              >
                Manage Invites
              </Link>
              .
            </Text>
          </Card>
        )
      }
    />
  );
};

export default OrganizationRequests;
