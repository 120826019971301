import { PivotItem } from '@fluentui/react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { setValue } from '../../../Helpers/SearchParamHelpers';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useTitle } from '../../../Hooks/useTitle';
import { SettingsPageLayout } from './SettingsPageLayout';
import { Billing } from './SettingsTabs/Billing';
import { CallRecordingUsageTabContent } from './SettingsTabs/CallRecordingUsageTabContent';
import { DeleteOrganizationTabContent } from './SettingsTabs/DeleteOrganizationTabContent';
import { DomainsTabContent } from './SettingsTabs/DomainsTabContent';
import { EditOrganizationTabContent } from './SettingsTabs/EditOrganizationTabContent';
import { EditUserOrganizationSettingsTabContent } from './SettingsTabs/EditUserOrganizationSettingsTabContent';
import { LeaveOrganizationTabContent } from './SettingsTabs/LeaveOrganizationTabContent';
import { ManageIntegrationsTabContent } from './SettingsTabs/ManageIntegrationsTabContent';
import { MembershipsTabContent } from './SettingsTabs/MembershipsTabContent';
import { SettingsStyledPivot } from './StyledPivot';

export type OrganizationSettingsProps = {};

export const OrganizationSettings = () => {
  const { name: orgName, isAdmin, slug } = useOrganization();
  const [searchParams, setSearchParams] = useSearchParams();
  useTitle(`${orgName} Settings`);

  const pivotItems: React.ReactElement[] = isAdmin
    ? [
        <PivotItem key="workspace" itemKey="workspace" headerText="Workspace">
          <EditOrganizationTabContent />
        </PivotItem>,

        <PivotItem key="domains" itemKey="domains" headerText="Domains">
          <DomainsTabContent />
        </PivotItem>,

        <PivotItem key="members" itemKey="members" headerText="Memberships">
          <MembershipsTabContent />
        </PivotItem>,
        <PivotItem key="billing" itemKey="billing" headerText="Billing">
          <Billing />
        </PivotItem>,
        <PivotItem
          key="call-recording"
          itemKey="call-recording"
          headerText="Call Recording Usage"
        >
          <CallRecordingUsageTabContent />
        </PivotItem>,

        <PivotItem
          key="integrations"
          itemKey="integrations"
          headerText="Integrations"
        >
          <ManageIntegrationsTabContent />
        </PivotItem>,

        <PivotItem key="user" itemKey="user" headerText="Personal Preferences">
          <EditUserOrganizationSettingsTabContent />
        </PivotItem>,

        <PivotItem key="delete" itemKey="delete" headerText="Delete Workspace">
          <DeleteOrganizationTabContent />
        </PivotItem>,
      ]
    : [
        <PivotItem key="user" itemKey="user" headerText="Preferences">
          <EditUserOrganizationSettingsTabContent />
        </PivotItem>,

        <PivotItem key="leave" itemKey="leave" headerText="Leave Workspace">
          <LeaveOrganizationTabContent />
        </PivotItem>,
      ];

  useEffect(() => {
    if (
      searchParams.get('tab') &&
      !pivotItems.some((pivotItem) => pivotItem.key === searchParams.get('tab'))
    ) {
      setSearchParams(
        setValue(searchParams, 'tab', isAdmin ? 'workspace' : 'user'),
      );
    }
  });

  return (
    <SettingsPageLayout title="Workspace Settings" subtitle={orgName}>
      <SettingsStyledPivot
        selectedKey={
          searchParams.get('tab') || (isAdmin ? 'workspace' : 'user')
        }
        onLinkClick={(item) => {
          setSearchParams(
            setValue(
              searchParams,
              'tab',
              item?.props.itemKey &&
                pivotItems.some(
                  (pivotItem) => pivotItem.key === item.props.itemKey,
                )
                ? item.props.itemKey
                : isAdmin
                  ? 'workspace'
                  : 'user',
            ),
          );
        }}
      >
        {pivotItems}
      </SettingsStyledPivot>
    </SettingsPageLayout>
  );
};

export default OrganizationSettings;
