import { useAuth0 } from '@auth0/auth0-react';
import { NeutralColors, mergeStyles } from '@fluentui/react';
import { Company } from '@meetingflow/common/Api/data-contracts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../Hooks/useOrganization';
import { OrganizationCompanyQuery } from '../../../QueryNames';
import { CompaniesApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { OrganizationSlugRouteParams } from '../../../types/RouteParams';
import CompanyDetail from './CompanyDetail';
import CompanyList from './CompanyList';

const CompanyBrowser = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { isDark } = useLightOrDarkMode();

  const { slug: organizationSlug } = useOrganization();

  const { objectId } = useParams<
    OrganizationSlugRouteParams & {
      objectId?: string;
    }
  >();

  const [companies, setCompanies] = useState<Company[] | undefined>([]);

  const selectCompany = useCallback(
    (companyId: Company['id']) =>
      navigate(
        `/organization/${organizationSlug}/library/companies/${companyId}`,
      ),
    [navigate, organizationSlug],
  );

  const companyId = useMemo(() => {
    if (!objectId) {
      return undefined;
    }
    const parsedObjectId = parseInt(objectId);

    return Number.isInteger(parsedObjectId) ? parsedObjectId : undefined;
  }, [objectId]);

  const { data: activeCompanyData, refetch: refetchCompany } = useQuery(
    OrganizationCompanyQuery(organizationSlug!, companyId),
    async () => {
      const token = await getAccessTokenSilently();
      if (companyId) {
        return CompaniesApiClient.getCompany(organizationSlug!, companyId, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
    },
    { enabled: !!organizationSlug && !!companyId },
  );

  useEffect(() => {
    if (!companyId && companies?.length) {
      selectCompany(companies[0].id);
    }
  }, [companies, companyId, selectCompany]);

  useEffect(() => {
    if (companyId) {
      refetchCompany();
    }
  }, [companyId, refetchCompany]);

  const activeCompany = activeCompanyData?.data;

  const companyBrowserClass = mergeStyles({
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    maxHeight: 'calc(100vh - 3.75rem)',
    backgroundColor: isDark
      ? NeutralColors.gray200
      : MEETINGFLOW_COLORS.purpleUltraSuperLight,
    '.ms-DetailsRow-cell': { padding: '.25rem' },
  });

  return (
    <div className={companyBrowserClass}>
      <div
        style={{
          width: '27rem',
          height: 'calc(100vh - 7rem)',
          overflowX: 'auto',
          overflowY: 'auto',
        }}
      >
        <CompanyList
          onSelectCompany={selectCompany}
          onListChange={setCompanies}
          activeCompanyId={companyId}
        />
      </div>

      <div style={{ width: '100%' }}>
        {companyId && organizationSlug && activeCompany ? (
          <CompanyDetail
            key={companyId}
            company={activeCompany}
            organizationSlug={organizationSlug}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CompanyBrowser;
