import {
  Stack,
  mergeStyles,
  FontSizes,
  FontWeights,
  Text,
  IStyle,
  Spinner,
  DefaultButton,
  SpinButton,
} from '@fluentui/react';
import { DEFAULT_STACK_TOKENS } from '../../../../Helpers/Layout';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { useUserProfile } from '../../../../Hooks/useProfile';

import {
  settingsIndentedControlClass,
  settingsRootStackStyles,
  settingsSectionDescriptionClass,
  settingsSectionDescriptionClassDark,
} from '../SettingsPageLayout';
import { useAuth0 } from '@auth0/auth0-react';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { useQuery } from 'react-query';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { useState } from 'react';
import toast from 'react-hot-toast';

const valueHighlightClass = mergeStyles({
  color: MEETINGFLOW_COLORS.teal,
});

type HmacResponse = {
  hmac: string;
  billableUsers: number;
  prices: {
    essentials: {
      annual: number | undefined;
      monthly: number | undefined;
    };
    business: {
      annual: number | undefined;
      monthly: number | undefined;
    };
    enterprise: {
      annual: number | undefined;
      monthly: number | undefined;
    };
  };
};

type BillingStatusResponse = {
  organizationType: string;
  user?: {
    name: string;
    id: number;
    email: string;
  };
};

type PortalLinkResponse = {
  link?: string;
  error?: string;
};

type BillingUsersResponse = {
  users: {
    name: string | null;
    email: string;
  }[];
};

export const Billing = () => {
  const {
    organization,
    role,
    name: organizationName,
    isLoading: isOrganizationLoading,
  } = useOrganization();
  const organizationId = organization?.id;
  const organizationSlug = organization?.slug;
  const organizationType = organization?.type;

  const { user, isLoading: isProfileLoading } = useUserProfile();
  const userId = user?.id;

  if (isOrganizationLoading || isProfileLoading) {
    return spinner;
  }

  if (
    !organizationId ||
    !organizationSlug ||
    !userId ||
    !role ||
    !organizationType
  ) {
    return goneWrong;
  }

  if (organizationType === 'VIP') {
    return VipExplanation;
  }

  if (organizationType === 'INTERNAL') {
    return InternalExplanation;
  }

  // Organization is PAID, meaning billing is active
  if (organizationType === 'PAID') {
    return (
      <BillingActive
        organizationId={organizationId}
        organizationSlug={organizationSlug}
        userId={userId}
      />
    );
  }

  // Billing not active, user is admin, so show them the pricing table so they can buy our product!!
  if (role === 'ADMIN') {
    return (
      <PricingTable
        userId={userId}
        organizationId={organizationId}
        organizationSlug={organizationSlug}
      />
    );
  } else {
    // Billing not active, user is not an admin, so show them who to contact to get billing active.
    return (
      <ListOfBillingUsers
        userId={userId}
        organizationId={organizationId}
        organizationSlug={organizationSlug}
      />
    );
  }
};

export type PricingTableProps = {
  organizationId: number;
  organizationSlug: string;
  userId: number;
};

type StripePlans = 'essentials' | 'business' | 'enterprise';

type CreateCheckoutPost = {
  seatCount: number;
  plan: StripePlans;
  isAnnual: boolean;
  clientReferenceId?: string;
};

// Pricing table component for admin users.
const PricingTable = ({
  organizationId,
  organizationSlug,
  userId,
}: PricingTableProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { name: organizationName } = useOrganization();

  const [isAnnual, setIsAnnual] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState<StripePlans>('business');
  const [seatCount, setSeatCount] = useState(0);

  const { data: hmacResponse, isLoading: isHmacDataLoading } = useQuery(
    `hmac_${userId}_${organizationId}`,
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HmacResponse>(
        `/organization/${organizationSlug}/billing/hmac`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );
  const hmacValue = hmacResponse?.data?.hmac;
  const billableUsers = hmacResponse?.data?.billableUsers || 0;
  const prices = hmacResponse?.data?.prices;

  if (isHmacDataLoading) {
    return spinner;
  }

  if (!hmacValue) {
    return goneWrong;
  }

  // If any of the prices are not set, that's a problem.
  if (
    !prices?.essentials?.annual ||
    !prices?.essentials?.monthly ||
    !prices?.business?.annual ||
    !prices?.business?.monthly ||
    !prices?.enterprise?.annual ||
    !prices?.enterprise?.monthly
  ) {
    return goneWrong;
  }

  // This reference id gets attached to the Checkout Session in stripe.
  // We can use that to tell which user/org is trying to pay us.
  // We can trust the value after verifying the HMAC.
  const referenceId = `userId_${userId}-orgId_${organizationId}-hmac_${hmacValue}`;
  console.info(`referenceId: ${referenceId}`);

  async function clickSubscribeButton(
    plan: StripePlans,
    isAnnual: boolean,
    seatCount: number,
  ) {
    const token = await getAccessTokenSilently();

    const response = await ApiClient.post(
      `/organization/${organizationSlug}/billing/create-checkout`,
      {
        plan,
        isAnnual,
        seatCount,
        clientReferenceId: referenceId,
      } satisfies CreateCheckoutPost,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    const redirectUrl = response.data?.checkoutUrl;

    if (!redirectUrl) {
      console.error(response.data);
      toast.error(`Something has gone wrong, please retry or contact support.`);
      return;
    }
    window.location = redirectUrl;
    // o7
    return;
  }

  const newPricingTableClass = mergeStyles({
    backgroundColor: MEETINGFLOW_COLORS.purpleMediumDarker,
    padding: '.75rem',
    color: 'white',

    '.top-callout': {
      textAlign: 'center',
    },

    '.segmented-control': {
      textAlign: 'center',

      '.segmented-control-inner': {
        backgroundColor: MEETINGFLOW_COLORS.purpleMediumDark,
        display: 'inline-block',
        borderRadius: '2rem',
        marginTop: '.75rem',
        padding: '.25rem',

        button: {
          color: '#EEE',
          margin: '0',
          borderRadius: '1rem',
          border: 'none',
          backgroundColor: MEETINGFLOW_COLORS.purpleMediumDark,
        },

        'button.selected': {
          color: 'white',
          backgroundColor: MEETINGFLOW_COLORS.purpleGreyMediumAlt2,
        },
      },
    },

    '.plan-container': {
      flex: 1,
      padding: '1rem 1rem .5rem 1rem',
      margin: '1rem',
      borderRadius: '1rem',
      transition: 'all .3s ease-in-out',

      '& .selected': {
        backgroundColor: MEETINGFLOW_COLORS.purpleMediumDark,
      },

      h3: {
        fontSize: '1.5rem',
        margin: '.25rem',
        fontWeight: FontWeights.semibold,
        color: MEETINGFLOW_COLORS.purpleLighter,
      },

      '.price-container': {
        display: 'flex',
        alignItems: 'center',
        width: '60%',
        columnGap: '.25rem',
        marginBottom: '.5rem',

        '.price': {
          fontSize: '2rem',
          paddingRight: '.25rem',
          fontWeight: FontWeights.semibold,
        },

        div: {
          fontSize: '.85rem',
          lineHeight: '.9rem',
          margin: '0',
        },
      },

      '> button': {
        border: `1px solid transparent`,
        borderRadius: '1.5rem',
        backgroundColor: MEETINGFLOW_COLORS.purpleGreyMediumAlt2,
        fontSize: '1rem',
        lineHeight: '1.5rem',
        margin: '.5rem 0',
        color: 'white',
        transition: 'all .3s ease-in-out',
        fontWeight: FontWeights.semibold,
      },

      '> button.selected': {
        backgroundColor: MEETINGFLOW_COLORS.purpleMediumDark,
        border: `1px solid ${MEETINGFLOW_COLORS.purpleGreyMedium}`,
      },

      ul: {
        lineHeight: '1.75',
        margin: '0 0 0 1rem',
        padding: 0,
      },
    },

    '.pricing-table': {
      display: 'flex',
      color: 'white',
    },

    '.bottom-callout': {
      textAlign: 'center',

      a: {
        color: 'white',
        textDecoration: 'underline',
      },
    },

    '.seat-count-selector-panel': {
      backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
      padding: '1rem .75rem .75rem .75rem',
      borderBottomLeftRadius: '.5rem',
      borderBottomRightRadius: '.5rem',
      marginTop: '1rem',
      marginLeft: '-.5rem',
      marginRight: '-.5rem',
      color: MEETINGFLOW_COLORS.white,

      'p.seat-license-description': {
        margin: '-1rem -.75rem .75rem -.75rem',
        padding: '.5rem',
        fontWeight: FontWeights.semibold,
        backgroundColor: MEETINGFLOW_COLORS.purpleGreyMediumAlt2,
      },

      'p.help-text': {
        margin: '.25rem 0 .75rem 0',
        fontSize: '.75rem',
        color: MEETINGFLOW_COLORS.purpleLighter,
        maxWidth: '15rem',
      },

      '.total': {
        margin: '1rem 0 1rem 0',
        fontSize: '1.5rem',
        // textAlign: 'center',

        strong: {
          fontWeight: FontWeights.semibold,
        },

        span: {
          fontWeight: FontWeights.light,
          fontSize: '1.25rem',
        },
      },

      '.subscribe-button': {
        display: 'block',
        borderRadius: '1.5rem',
        fontWeight: FontWeights.semibold,
        backgroundColor: MEETINGFLOW_COLORS.magenta,
        fontSize: '1rem',
        margin: '0 0 0 0',
        marginTop: '1rem',
        color: 'white',
        border: `1.5px solid ${MEETINGFLOW_COLORS.purpleGreyer}`,
      },
    },
  });

  const spinButtonStyles = {
    label: {
      color: MEETINGFLOW_COLORS.white,
    },
    input: {
      maxWidth: '3rem',
      border: `4px solid ${MEETINGFLOW_COLORS.purpleMediumDark} !important`,
      padding: '0 .25rem',
      textAlign: 'right',
    },
    spinButtonWrapper: {
      ':after': {
        border: 'none !important',
      },
    },
    arrowButtonsContainer: {
      button: {
        color: MEETINGFLOW_COLORS.white,
        backgroundColor: MEETINGFLOW_COLORS.purpleMediumDark,
        transition: 'all .3s ease-in-out',

        ':hover': {
          backgroundColor: MEETINGFLOW_COLORS.purpleMediumDarker,
          color: MEETINGFLOW_COLORS.white,
        },
      },
    },
  };

  const seatCountSelectorPanel = (
    <div className="seat-count-selector-panel">
      {isAnnual ? (
        <>
          <p className="seat-license-description">
            A Seat License is required for each user. Select the number of users
            in your plan.
          </p>
          <SpinButton
            label="Seat Licenses"
            min={billableUsers}
            value={seatCount.toString()}
            onIncrement={(e) => setSeatCount(seatCount + 1)}
            onDecrement={(e) =>
              setSeatCount(Math.max(seatCount - 1, billableUsers))
            }
            onChange={(e, newValue) => {
              if (newValue) {
                setSeatCount(Math.max(billableUsers, Number(newValue)));
              }
            }}
            styles={spinButtonStyles}
          />

          <p className="help-text">
            Minimum choice is the current billable user count for{' '}
            {organizationName}: <b>{billableUsers}</b>
          </p>
        </>
      ) : (
        <p className="help-text">
          You will be billed for the user count at the end of each billing
          cycle. The total reflects the current billable user count for{' '}
          {organizationName}: <b>{billableUsers}</b>, and is subject to change
          if you add or remove users.
        </p>
      )}

      <p className="total">
        <strong>Total</strong>: $
        {(
          Number(prices[selectedPlan][isAnnual ? 'annual' : 'monthly']) *
          seatCount
        ).toLocaleString()}{' '}
        <span>per {isAnnual ? 'year' : 'month'}</span>
      </p>

      <DefaultButton
        text="Subscribe with Stripe"
        onClick={(e) => {
          clickSubscribeButton(selectedPlan, isAnnual, seatCount);
        }}
        className="subscribe-button"
      />
    </div>
  );

  if (!seatCount && billableUsers) {
    setSeatCount(billableUsers);
  }

  return (
    <div className={newPricingTableClass}>
      <div className="top-callout">
        Questions? Email us at{' '}
        <a
          href="mailto:support@meetingflow.com"
          style={{ color: 'white', textDecoration: 'underline' }}
        >
          support@meetingflow.com
        </a>{' '}
        or consult the{' '}
        <a
          href="https://meetingflow.com/help-center/billing-faq/"
          target="_blank"
          rel="noreferrer"
          style={{ color: 'white', textDecoration: 'underline' }}
        >
          Billing FAQ
        </a>
        .
      </div>
      <div className="segmented-control">
        <span className="segmented-control-inner">
          <DefaultButton
            text="Annual"
            onClick={(e) => setIsAnnual(true)}
            className={isAnnual ? 'selected' : ''}
          />
          <DefaultButton
            text="Monthly"
            onClick={(e) => setIsAnnual(false)}
            className={!isAnnual ? 'selected' : ''}
          />
        </span>
      </div>
      <div className="pricing-table">
        <div
          className={`plan-container ${
            selectedPlan === 'essentials' ? 'selected' : ''
          }`}
        >
          <h3>Essentials Plan</h3>
          <div className="price-container">
            <div className="price">
              $
              {isAnnual
                ? prices?.essentials.annual.toLocaleString()
                : prices?.essentials.monthly.toLocaleString()}
            </div>
            <div>
              per
              <br />
              user
            </div>
            <div>
              <br />/ {isAnnual ? 'year' : 'month'}
            </div>
          </div>
          <DefaultButton
            text={selectedPlan === 'essentials' ? 'Selected' : 'Select'}
            onClick={(e) => {
              setSelectedPlan('essentials');
            }}
            className={selectedPlan === 'essentials' ? 'selected' : ''}
          />
          <ul>
            <li>Up to 20 Creators or Collaborators </li>
            <li>Basic CRM support </li>
            <li>10 monthly call recording hours / paid user</li>
          </ul>

          {selectedPlan === 'essentials' && seatCountSelectorPanel}
        </div>

        <div
          className={`plan-container ${
            selectedPlan === 'business' ? 'selected' : ''
          }`}
        >
          <h3>Business Plan</h3>
          <div className="price-container">
            <div className="price">
              $
              {isAnnual
                ? prices?.business.annual.toLocaleString()
                : prices?.business.monthly.toLocaleString()}
            </div>
            <div>
              per
              <br />
              user
            </div>
            <div>
              <br />/ {isAnnual ? 'year' : 'month'}
            </div>
          </div>
          <DefaultButton
            text={selectedPlan === 'business' ? 'Selected' : 'Select'}
            onClick={(e) => {
              setSelectedPlan('business');
            }}
            className={selectedPlan === 'business' ? 'selected' : ''}
          />
          <ul>
            <li>Unlimited Creators and Collaborators</li>
            <li>Custom CRM fields support</li>
            <li>20 monthly call recording hours / paid user</li>
          </ul>

          {selectedPlan === 'business' && seatCountSelectorPanel}
        </div>
        <div
          className={`plan-container ${
            selectedPlan === 'enterprise' ? 'selected' : ''
          }`}
        >
          <h3>Enterprise Plan</h3>
          <div className="price-container">
            <div className="price">
              $
              {isAnnual
                ? prices?.enterprise.annual.toLocaleString()
                : prices?.enterprise.monthly.toLocaleString()}
            </div>
            <div>
              per
              <br />
              user
            </div>
            <div>
              <br />/ {isAnnual ? 'year' : 'month'}
            </div>
          </div>
          <DefaultButton
            text={selectedPlan === 'enterprise' ? 'Selected' : 'Select'}
            onClick={(e) => {
              setSelectedPlan('enterprise');
            }}
            className={selectedPlan === 'enterprise' ? 'selected' : ''}
          />
          <ul>
            <li>Unlimited Creators and Collaborators</li>
            <li>Unlimited call recording</li>
            <li>Priority support</li>
          </ul>

          {selectedPlan === 'enterprise' && seatCountSelectorPanel}
        </div>
      </div>
      <div className="bottom-callout">
        <a
          href="https://meetingflow.com/pricing/#pricing-table"
          target="_blank"
          rel="noreferrer"
        >
          View the full feature comparison
        </a>
      </div>
    </div>
  );
};

type BillingActiveProps = {
  organizationId: number;
  organizationSlug: string;
  userId: number;
};

// Screen to show when billing is active.
const BillingActive = ({
  organizationId,
  organizationSlug,
  userId,
}: BillingActiveProps) => {
  const { isDark } = useLightOrDarkMode();
  const { getAccessTokenSilently } = useAuth0();

  const { data: billingDataResponse, isLoading: isBillingDataLoading } =
    useQuery(`billinginfo_${userId}_${organizationId}`, async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<BillingStatusResponse>(
        `/organization/${organizationSlug}/billing/status`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    });
  const billingUserName = billingDataResponse?.data?.user?.name;
  const billingUserEmail = billingDataResponse?.data?.user?.email;
  const billingUserId = billingDataResponse?.data?.user?.id;

  const shouldGetPortalLink = Boolean(userId === billingUserId);
  const { data: portalLinkDataResponse, isLoading: isPortalLinkDataLoading } =
    useQuery(
      `portallink_${userId}_${organizationId}`,
      async () => {
        if (!organizationSlug) {
          return undefined;
        }
        const token = await getAccessTokenSilently();
        return ApiClient.get<PortalLinkResponse>(
          `/organization/${organizationSlug}/billing/portal-link`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      },
      {
        enabled: shouldGetPortalLink,
      },
    );
  const portalLink = portalLinkDataResponse?.data?.link;

  if (isBillingDataLoading || isPortalLinkDataLoading) {
    return spinner;
  }

  if (shouldGetPortalLink && !portalLink) {
    return goneWrong;
  }

  if (billingUserId === undefined) {
    return goneWrong;
  }

  // Billing active, but not managed by this user. Show who it is managed by.
  if (userId !== billingUserId) {
    return (
      <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
        <div className={settingsIndentedControlClass}>
          <Text
            style={{
              fontWeight: FontWeights.semibold,
              fontSize: FontSizes.large,
              lineHeight: '1.75rem',
              display: 'block',
              maxWidth: 'unset',
            }}
            className={
              isDark
                ? mergeStyles(settingsSectionDescriptionClassDark as IStyle)
                : mergeStyles(settingsSectionDescriptionClass as IStyle)
            }
          >
            Billing is currently active and managed by{' '}
            <span className={valueHighlightClass}>
              {billingUserName} ({billingUserEmail}).
            </span>{' '}
            <br />
            If you need to make changes, please contact them or email us at{' '}
            <a href="mailto:support@meetingflow.com">support@meetingflow.com</a>
            .
          </Text>
        </div>
      </Stack>
    );
  } else {
    // Billing active, and managed by this user. Show link to billing portal.
    return (
      <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
        <div className={settingsIndentedControlClass}>
          <Text
            style={{
              fontWeight: FontWeights.semibold,
              fontSize: FontSizes.large,
              lineHeight: '1.75rem',
              display: 'block',
              maxWidth: 'unset',
            }}
            className={
              isDark
                ? mergeStyles(settingsSectionDescriptionClassDark as IStyle)
                : mergeStyles(settingsSectionDescriptionClass as IStyle)
            }
          >
            Billing is currently active. Manage via the{' '}
            <a href={portalLink}>Billing Portal</a>.
            <br />
            Please contact{' '}
            <a href="mailto:support@meetingflow.com">
              support@meetingflow.com
            </a>{' '}
            if you have any questions or issues.
          </Text>
        </div>
      </Stack>
    );
  }
};

type ListOfBillingUsersProps = {
  organizationId: number;
  organizationSlug: string;
  userId: number;
};

// Screen to show when billing is not active, and the user is not able to set up billing. So we show them who to contact.
const ListOfBillingUsers = ({
  organizationId,
  organizationSlug,
  userId,
}: ListOfBillingUsersProps) => {
  const { isDark } = useLightOrDarkMode();
  const { getAccessTokenSilently } = useAuth0();

  const { data: billingUsersResponse, isLoading: isBillingUsersLoading } =
    useQuery(`billingusers_${userId}_${organizationId}`, async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<BillingUsersResponse>(
        `/organization/${organizationSlug}/billing/billing-users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    });
  const billingUsers = billingUsersResponse?.data?.users;

  if (isBillingUsersLoading) {
    return spinner;
  }

  if (!billingUsers) {
    return goneWrong;
  }

  return (
    <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
      <div className={settingsIndentedControlClass}>
        <Text
          style={{
            fontWeight: FontWeights.semibold,
            fontSize: FontSizes.large,
            lineHeight: '1.75rem',
            display: 'block',
            maxWidth: 'unset',
          }}
          className={
            isDark
              ? mergeStyles(settingsSectionDescriptionClassDark as IStyle)
              : mergeStyles(settingsSectionDescriptionClass as IStyle)
          }
        >
          Billing must be managed by admin users. Please contact one of them, or
          email us at{' '}
          <a href="mailto:support@meetingflow.com">support@meetingflow.com</a>.
        </Text>
        <ul>
          {billingUsers.map((user, index) => (
            <li key={index}>
              {user.name || ''} (
              <a href={`mailto:${user.email}`}>{user.email}</a>)
            </li>
          ))}
        </ul>
      </div>
    </Stack>
  );
};

const VipExplanation = (
  <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
    <Text>
      Any billing concerns for this organization are being handled manually.{' '}
      Please contact{' '}
      <a href="mailto:support@meetingflow.com">support@meetingflow.com</a> if
      you have any questions or concerns.
    </Text>
  </Stack>
);

const InternalExplanation = (
  <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
    <Text>This is an INTERNAL organization. No billing is required.</Text>
  </Stack>
);

const goneWrong = (
  <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
    <Text>
      Something has gone wrong loading billing information. If you need to make
      changes, please email us at{' '}
      <a href="mailto:support@meetingflow.com">support@meetingflow.com</a>.
    </Text>
  </Stack>
);

const spinner = (
  <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
    <Spinner></Spinner>
  </Stack>
);
