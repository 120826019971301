import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  DefaultButton,
  DetailsList,
  FontIcon,
  IObjectWithKey,
  Icon,
  PrimaryButton,
  ScrollablePane,
  SelectionMode,
  ShimmeredDetailsList,
  mergeStyles,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { executeWithConcurrencyLimit } from '@meetingflow/common/PromiseHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useForceUpdate } from '../../Hooks/useForceUpdate';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { OrganizationsApiClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { AsyncPrimaryButton } from '../HOC/AsyncButton';
import { BaseModal } from '../MeetingPlans/Dialogs/BaseModal';

const DEBUG = import.meta.env.DEV;

export interface IMeetingFlowExport extends IObjectWithKey {
  id: string;
  title: string;
  startTime: string;
  fileName: string;
  hasRecording: boolean;
  state?: 'pending' | 'downloading' | 'downloaded' | 'failed';
}

export type ExportOrganizationDialogProps = {
  organizationSlug: string;
  organizationName: string;
  onDismiss: () => void;
};

export const ExportOrganizationDialog = ({
  organizationSlug,
  organizationName,
  onDismiss,
}: ExportOrganizationDialogProps) => {
  const [renderId, forceUpdate] = useForceUpdate();

  const { user, getAccessTokenSilently } = useAuth0();
  const contentRef = useRef<HTMLDivElement>(null);

  const [
    downloading,
    { setTrue: setDownloading, setFalse: setNotDownloading },
  ] = useBoolean(false);
  const abortController = useRef<AbortController>();
  const downloadingMeetingflows = useRef<Set<string>>(new Set<string>());
  const downloadedMeetingflows = useRef<Set<string>>(new Set<string>());
  const failedMeetingflows = useRef<Set<string>>(new Set<string>());
  const [selected, setSelected] = useState<string[]>([]);

  const { isDark } = useLightOrDarkMode();

  const client = useAppInsightsContext();

  const { data: orgExports, isLoading: orgExportsLoading } = useQuery(
    `${organizationSlug}_Exports`,
    async () => {
      const token = await getAccessTokenSilently();
      return await OrganizationsApiClient.listOrgExports(organizationSlug, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {},
  );

  useEffect(() => {
    setSelected(
      orgExports?.data?.meetingflows
        ?.filter(
          (meetingflow) => !downloadedMeetingflows.current.has(meetingflow.id),
        )
        ?.map((meetingflow) => meetingflow.id) ?? [],
    );
  }, [orgExports?.data?.meetingflows]);

  const meetingflows: IMeetingFlowExport[] = useMemo(() => {
    return (orgExports?.data?.meetingflows || [])?.map(
      (meetingflow: IMeetingFlowExport) => {
        let state:
          | 'pending'
          | 'downloading'
          | 'downloaded'
          | 'failed'
          | undefined = undefined;
        if (downloadingMeetingflows.current.has(meetingflow.id)) {
          state = 'downloading';
        } else if (downloadedMeetingflows.current.has(meetingflow.id)) {
          state = 'downloaded';
        } else if (failedMeetingflows.current.has(meetingflow.id)) {
          state = 'failed';
        } else if (downloading && selected.includes(meetingflow.id)) {
          state = 'pending';
        }

        return {
          ...meetingflow,
          state,
        };
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloading, orgExports?.data?.meetingflows, selected, renderId]);

  useEffect(() => {
    return () => abortController.current?.abort();
  }, []);

  const columns = useMemo(
    () => [
      {
        key: 'selected',
        minWidth: 10,
        maxWidth: 15,
        onRender: (item: IMeetingFlowExport) => (
          <div>
            <Checkbox
              disabled={downloading || item.state === 'downloaded'}
              checked={selected.includes(item.id)}
              onChange={(_, checked) => {
                if (!!checked && !selected.includes(item.id)) {
                  setSelected((s) => [...s, item.id]);
                } else if (!checked) {
                  setSelected((s) => s.filter((id) => id !== item.id));
                }
              }}
            />
          </div>
        ),
      },
      {
        key: 'title',
        name: 'Title',
        minWidth: 50,
        maxWidth: 400,
        onRender: (item: IMeetingFlowExport) => <div>{item.title}</div>,
      },
      {
        key: 'time',
        name: 'Time',
        minWidth: 50,
        maxWidth: 200,
        onRender: (item: IMeetingFlowExport) => (
          <div>{humanizeDateTime(item.startTime)}</div>
        ),
      },
      {
        key: 'hasRecording',
        minWidth: 20,
        maxWidth: 50,
        onRender: (item: IMeetingFlowExport) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: item.hasRecording
                ? MEETINGFLOW_COLORS.teal
                : MEETINGFLOW_COLORS.red,
            }}
            title={item.hasRecording ? `Has Recording` : `No Recording`}
          >
            {item.hasRecording ? (
              <FontIcon iconName="CheckMark" style={{ color: 'white' }} />
            ) : (
              <FontIcon iconName="Cancel" style={{ color: 'white' }} />
            )}
          </div>
        ),
      },
      {
        key: 'status',
        name: 'Status',
        minWidth: 20,
        maxWidth: 50,
        onRender: (item: IMeetingFlowExport) => {
          if (item.state === 'downloaded') {
            return <Icon iconName="Completed" title="Downloaded" />;
          } else if (item.state === 'downloading') {
            return <Icon iconName="Download" title="Downloading" />;
          } else if (item.state === 'failed') {
            return <Icon iconName="Error" title="Failed" />;
          } else if (item.state === 'pending') {
            return <Icon iconName="BuildQueue" title="Pending" />;
          } else {
            return null;
          }
        },
      },
    ],
    [downloading, selected],
  );

  const downloadMeetingflow = useCallback(
    async (meetingflow: IMeetingFlowExport, signal: AbortSignal) => {
      console.info(`Starting download of Meetingflow ${meetingflow.id}`);
      try {
        const token = await getAccessTokenSilently();

        const headers = new Headers();
        headers.append('Authorization', `Bearer ${token}`);
        headers.append(
          'X-Tz',
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        );
        const fetchPromise = fetch(
          `/api/organization/${organizationSlug}/plan/${meetingflow.id}/export`,
          {
            method: 'GET',
            headers,
            signal,
          },
        );

        downloadingMeetingflows.current.add(meetingflow.id);
        forceUpdate();

        const response = await fetchPromise;

        if (response.ok) {
          const blobPromise = response.blob();

          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(await blobPromise);
          downloadLink.download = meetingflow.fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }

        setSelected((s) => s.filter((id) => id !== meetingflow.id));
        downloadedMeetingflows.current.add(meetingflow.id);

        console.info(`Successfully downloaded Meetingflow ${meetingflow.id}`);
      } catch (e: unknown) {
        failedMeetingflows.current.add(meetingflow.id);
        toast.error(`Failed to export Meetingflow ${meetingflow.id}`);
        console.error(
          `Something went wrong downloading meetingflow ${meetingflow.id}`,
          e,
        );
      } finally {
        downloadingMeetingflows.current.delete(meetingflow.id);
        forceUpdate();
      }
    },
    [forceUpdate, getAccessTokenSilently, organizationSlug],
  );

  const downloadSelectedMeetingflows = useCallback(async () => {
    abortController.current?.abort();

    const controller = new AbortController();

    abortController.current = controller;
    setDownloading();

    try {
      toast.promise(
        executeWithConcurrencyLimit(
          selected.map(
            (id) => () =>
              downloadMeetingflow(
                meetingflows.find((meetingflow) => meetingflow.id === id)!,
                controller.signal,
              ),
          ),
          4,
        ),
        {
          loading: `Exporting ${selected.length} Meetingflows... This might take a while`,
          success: `Successfully downloading ${selected.length} Meetingflows`,
          error: `Something went wrong, please try again`,
        },
      );
    } catch (err) {
      if (err instanceof DOMException) {
        console.info(`Download cancelled`);
      } else {
        console.error(err);
      }
    }

    setNotDownloading();
  }, [
    downloadMeetingflow,
    meetingflows,
    selected,
    setDownloading,
    setNotDownloading,
  ]);

  const modalContentStyles = mergeStyles({
    gap: '.5rem',
    width: '100%',
    height: '100%',

    '.modal-content': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',

      '.selectors': {},
      '.list': { position: 'relative', width: '100%', height: '80%' },
      '.download-buttons': {},
    },
  });

  return (
    <BaseModal
      title={`Export ${organizationName || organizationSlug} Data`}
      headerBackgroundColor={MEETINGFLOW_COLORS.purpleMedium}
      styles={{
        main: {
          height: '75%',
          width: '75%',
          backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
          overflow: 'hidden',
        },
        scrollableContent: {
          height: '100%',
          width: '100%',
          overflow: 'hidden',
        },
        root: {},
        layer: {},
      }}
      isOpen
      onDismiss={() => {
        abortController.current?.abort();
        onDismiss();
      }}
      modalBodyClassName={modalContentStyles}
    >
      <>
        <div className="selectors">
          <PrimaryButton
            disabled={!meetingflows.length || downloading}
            onClick={() => {
              setSelected(
                meetingflows
                  .filter((mf) => !downloadedMeetingflows.current.has(mf.id))
                  .map((mf) => mf.id),
              );
            }}
          >
            Select All
          </PrimaryButton>
          <PrimaryButton
            disabled={!meetingflows.length || downloading}
            onClick={() => {
              setSelected([]);
            }}
          >
            Select None
          </PrimaryButton>
        </div>
        <div className="list">
          <ScrollablePane>
            <ShimmeredDetailsList
              enableShimmer={orgExportsLoading}
              selectionMode={SelectionMode.none}
              //@ts-ignore
              columns={columns}
              items={meetingflows}
            />
          </ScrollablePane>
        </div>
        <div className="download-buttons">
          <AsyncPrimaryButton
            disabled={!selected.length}
            onClick={downloadSelectedMeetingflows}
          >
            Download {selected.length} Selected
          </AsyncPrimaryButton>
          <DefaultButton
            disabled={!downloading}
            onClick={() => abortController.current?.abort()}
          >
            Cancel
          </DefaultButton>
        </div>
      </>{' '}
    </BaseModal>
  );
};
