import { useBoolean } from '@fluentui/react-hooks';
import {
  FontSizes,
  Icon,
  IconButton,
  mergeStyles,
  Stack,
  Text,
} from '@fluentui/react';
import DealRoomCard from './DealRoomCard';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const FooterInfoCard = () => {
  const [
    footerVisible,
    { setTrue: setFooterVisible, setFalse: setFooterHidden },
  ] = useBoolean(true);

  const styles = mergeStyles({
    '&:empty': {
      display: 'none',
    },
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',
    padding: '1rem 1rem 1.5rem 1.5rem',
    border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    boxShadow: '0px 2px 3px 0px #00000008',

    '.footer-card-content': {
      flex: '1',
      paddingTop: '.5rem',

      '.title': {
        fontWeight: '400',
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        color: DEALROOMS_COLORS.themePrimary,
      },

      '.description': {
        maxWidth: '596px',
        fontWeight: '400',
        fontSize: '0.9375rem',
        lineHeight: '1.5rem',
        color: DEALROOMS_COLORS.themeSecondary,
      },
    },

    '.info': {
      width: '2rem',
      height: '2rem',
      fontSize: FontSizes.size20,
      marginTop: '.5rem',
      display: 'flex',
      alignItems: 'center',
      color: DEALROOMS_COLORS.neutralDark,
    },

    '.dismiss': {
      width: '1.5rem',
      height: '1.5rem',
      i: {
        color: DEALROOMS_COLORS.neutralDark,
      },
    },
  });

  if (!footerVisible) return null;

  return (
    <DealRoomCard className={styles}>
      <Icon className="info" iconName="Info" />
      <Stack className="footer-card-content">
        <Text className="title" variant="xLarge">
          Tell us about your experience
        </Text>
        <Text className="description" variant="medium">
          We'd love to hear about your journey! Please share your personal
          experiences, insights, and the key moments that have shaped your path.
        </Text>
      </Stack>
      <IconButton
        className="dismiss"
        iconProps={{ iconName: 'Cancel' }}
        onClick={setFooterHidden}
      />
    </DealRoomCard>
  );
};
