import useStore from '../../../../Stores/userSettingsStore';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

import { mergeStyles, TextField, Pivot, PivotItem } from '@fluentui/react';
import {
  buyerOrientationSurveyOptions,
  optionValuesThatNeedPhoneNumber,
} from '../BuyerOrientationSurvey/buyerOrientationSurveyUtils';
import { SurveyOptionCard } from '../BuyerOrientationSurvey/SurveyOptionCard';
import { UserDetails } from '../../../../types/BuyerOrientationSurveyTypes';
import { CalendarIllustration } from '../BuyerOrientationSurvey/BuyerOrientationIllustrations';
import { UserSettingsInternal } from './UserSettingsInternal';

interface UserSettingsBuyerPrefProps {
  isSaving: boolean;
  currentUserDetails: UserDetails;
  buyerPrefsState: { [key: string]: string | null };
  refetchDealRoom: () => unknown | Promise<unknown>;
}

const phonePattern = /^\+?(\d{1,3})?[-.\s]?\(?\d+\)?[-.\s]?\d+([-.\s]?\d+)*$/;

export const UserSettingsBuyerPref = ({
  isSaving,
  currentUserDetails,
  buyerPrefsState,
  refetchDealRoom,
}: UserSettingsBuyerPrefProps) => {
  return (
    <Pivot>
      <PivotItem headerText="Communication">
        <UserSettingsBuyerPrefComms
          isSaving={isSaving}
          currentUserDetails={currentUserDetails}
          buyerPrefsState={buyerPrefsState}
          refetchDealRoom={refetchDealRoom}
        />
      </PivotItem>

      <PivotItem headerText="Internal">
        {currentUserDetails?.email?.includes('@augment.co') && (
          <UserSettingsInternal
            refetchDealRoom={refetchDealRoom}
            currentUserDetails={currentUserDetails}
          />
        )}
      </PivotItem>
    </Pivot>
  );
};

const UserSettingsBuyerPrefComms = ({
  isSaving,
  currentUserDetails,
  buyerPrefsState,
}: UserSettingsBuyerPrefProps) => {
  // Styles for the buyer preferences UI
  const buyerPrefStyle = mergeStyles({
    // Section container styles
    '.buyer-pref-section': {
      marginBottom: '1rem',

      // Section title
      '>div:first-child': {
        color: DEALROOMS_COLORS.themePrimary,
        fontSize: '1rem',
        paddingBottom: '1rem',
        marginBottom: '0.5rem',
      },

      // Options container - holds the preference cards
      '>div:nth-child(2)': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'center',
        gap: '1.5rem',
        paddingBottom: '1rem',
      },
    },

    // Remove padding from last section
    '.buyer-pref-section:last-child >div:nth-child(2)': {
      padding: 0,
    },

    // Set max widths for different sections
    '.buyer-pref-section:nth-child(1) >div:nth-child(2) >*': {
      maxWidth: '16rem',
    },
    '.buyer-pref-section:nth-child(2) >div:nth-child(2) >*, .buyer-pref-section:nth-child(3) >div:nth-child(2) >*':
      {
        maxWidth: '11.6rem',
      },

    // Phone number input field styles
    '.communication-phone-main-container': {
      position: 'relative',
      marginTop: '0.5rem',

      '.communication-phone-icon-container': {
        position: 'absolute',
        top: '2.1rem',
        left: '.75rem',
        zIndex: 1,
      },

      '.communication-phone-text-field': {
        '.ms-Label': {
          fontWeight: '400',
          color: DEALROOMS_COLORS.themePrimary,
        },
        '.ms-TextField-fieldGroup': {
          border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
          borderRadius: '4px',
        },
        input: {
          minHeight: '1.8rem',
          fontSize: '1rem',
          fontWeight: '400',
          paddingLeft: '2.375rem',
          '::placeholder': {
            color: DEALROOMS_COLORS.userSurveyInputPlaceholder,
          },
        },
      },
    },
  });

  const handleSelectOption = useStore((state) => state.setBuyerPrefs);

  // Helper function to check if an option should be disabled
  const isOptionDisabled = (
    optionValue: string,
    currentPref: string | null,
    buyerPref: string | null,
  ) => {
    return (
      isSaving ||
      (!!buyerPref && optionValue !== buyerPref) ||
      (!!currentPref && !buyerPref && optionValue !== currentPref)
    );
  };

  // Helper function to get current preference value
  const getCurrentPreference = (
    buyerPref: string | null,
    currentPref: string | null,
  ) => {
    return buyerPref || currentPref;
  };

  // Helper function to render preference section
  const renderPreferenceSection = (
    title: string,
    prefKey:
      | 'assistancePreference'
      | 'consumptionPreference'
      | 'communicationPreference',
    options: (typeof buyerOrientationSurveyOptions)[typeof prefKey]['options'],
  ) => (
    <div className="buyer-pref-section">
      <div>{title}</div>
      <div>
        {options.map((option) => (
          <SurveyOptionCard
            key={option.optionId}
            className="card"
            option={option}
            isSelected={
              option.value ===
              getCurrentPreference(
                buyerPrefsState[prefKey],
                currentUserDetails?.[prefKey] || null,
              )
            }
            handleSelectOption={(value) =>
              !isSaving && handleSelectOption(prefKey, value)
            }
            isDisabled={isOptionDisabled(
              option.value,
              currentUserDetails?.[prefKey] || null,
              buyerPrefsState[prefKey],
            )}
          />
        ))}
      </div>
    </div>
  );

  // Get current phone number value
  const phoneNumber =
    'phoneNumber' in buyerPrefsState
      ? buyerPrefsState.phoneNumber || ''
      : currentUserDetails?.phoneNumber || '';

  return (
    <div className={buyerPrefStyle}>
      {/* Assistance preference section */}
      {renderPreferenceSection(
        'How much help do you want from me?',
        'assistancePreference',
        buyerOrientationSurveyOptions.assistancePreference.options,
      )}

      {/* Content consumption preference section */}
      {renderPreferenceSection(
        'How do you like to consume content?',
        'consumptionPreference',
        buyerOrientationSurveyOptions.consumptionPreference.options,
      )}

      {/* Communication preference section */}
      {renderPreferenceSection(
        "What's your preferred method of communication?",
        'communicationPreference',
        buyerOrientationSurveyOptions.communicationPreference.options,
      )}

      {/* Phone number input field - only shown when needed */}
      <div>
        {optionValuesThatNeedPhoneNumber.includes(
          getCurrentPreference(
            buyerPrefsState.communicationPreference,
            currentUserDetails?.communicationPreference || null,
          ) || '',
        ) && (
          <div className="communication-phone-main-container">
            <div className="communication-phone-icon-container">
              <CalendarIllustration />
            </div>
            <TextField
              className="communication-phone-text-field"
              label="SMS-compatible phone number"
              required
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(_e, newValue) => {
                if (isSaving) return;
                handleSelectOption('phoneNumber', newValue || '');
                if (
                  !buyerPrefsState.communicationPreference &&
                  currentUserDetails?.communicationPreference
                ) {
                  handleSelectOption(
                    'communicationPreference',
                    currentUserDetails.communicationPreference,
                  );
                }
              }}
              errorMessage={
                'phoneNumber' in buyerPrefsState && !buyerPrefsState.phoneNumber
                  ? 'Phone number required'
                  : buyerPrefsState.phoneNumber &&
                      !phonePattern.test(buyerPrefsState.phoneNumber)
                    ? 'Phone number is not correct'
                    : ''
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
