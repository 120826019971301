import { useAuth0 } from '@auth0/auth0-react';
import {
  DatePicker,
  DefaultButton,
  FontIcon,
  FontSizes,
  FontWeights,
  IColumn,
  NeutralColors,
  SelectionMode,
  Spinner,
  Stack,
  Text,
  Toggle,
  TooltipHost,
  mergeStyles,
} from '@fluentui/react';
import { CalendarEvent } from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classnames from 'classnames';
import { DateTime, Duration } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { DEFAULT_STACK_TOKENS } from '../../..//Helpers/Layout';
import { MeetingflowCard } from '../../../Components/MeetingPlans/MeetingflowCard';
import { EMPTY_ARRAY } from '../../../Constants';
import { isAxiosErrorResponse } from '../../../Helpers/AxiosHelpers';
import { toIGroups } from '../../../Helpers/CalendarEventHelpers';
import { isGoogleUser } from '../../../Helpers/IdentityHelpers';
import { collectToRecord } from '../../../Helpers/SearchParamHelpers';
import useBreakpoints from '../../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useUserProfile } from '../../../Hooks/useProfile';
import {
  OrganizationMeetingsHappeningSoon,
  OrganizationUpcomingMeetings,
} from '../../../QueryNames';
import { ApiClient, EventsApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { OrganizationSlugRouteParams } from '../../../types/RouteParams';
import { AsyncIconButton } from '../../HOC/AsyncButton';
import { AsyncLink } from '../../HOC/AsyncLink';
import { StyledDetailsList } from '../../StyledDetailsList';
import EventCard from '../Library/EventCard';

interface AgendaProps {
  detailListTopOffset?: string;
}

export const AgendaPane = ({
  detailListTopOffset = '14.25rem',
}: AgendaProps) => {
  const { user, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const { organizationSlug } = useParams<OrganizationSlugRouteParams>();
  const appInsights = useAppInsightsContext();
  const { isDark } = useLightOrDarkMode();

  const navigate = useNavigate();

  const { internalDomains, isLoading: orgIsLoading } =
    useOrganization(organizationSlug);

  const breakpoints = useBreakpoints();

  const client = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams({
    selectedDate: DateTime.now().startOf('day').toISO()!,
    q: '',
  });

  const selectedDate = useMemo(
    () =>
      searchParams.has('selectedDate')
        ? DateTime.fromISO(searchParams.get('selectedDate')!)
        : DateTime.now(),
    [searchParams],
  );

  const searchString = useMemo(() => searchParams.get('q'), [searchParams]);

  const mergeSearchParams = (
    newSearchParams: Record<string, string | string[]> | URLSearchParams,
  ) => {
    if (newSearchParams instanceof URLSearchParams) {
      newSearchParams.sort();
      setSearchParams(newSearchParams);
    } else {
      const params = createSearchParams({
        ...collectToRecord(searchParams),
        ...newSearchParams,
      });
      params.sort();
      setSearchParams(params);
    }
  };

  const activeWeekStart = useMemo(
    () => selectedDate.startOf('week'),
    [selectedDate],
  );

  const { user: userProfile, updateUserProfile } = useUserProfile();

  const [showInternal, setShowInternal] = useState<boolean>(
    userProfile?.preferenceAgendaMeetingFilter === 'SHOW_ALL',
  );

  const [showAttendees, setShowAttendees] = useState<boolean>(
    userProfile?.preferenceAgendaShowAttendees === 'COMPANY_AND_ATTENDEES',
  );

  useEffect(() => {
    const preferenceShowInternal =
      userProfile?.preferenceAgendaMeetingFilter === 'SHOW_ALL';
    const preferenceShowAttendees =
      userProfile?.preferenceAgendaShowAttendees === 'COMPANY_AND_ATTENDEES';
    ReactDOM.unstable_batchedUpdates(() => {
      if (preferenceShowInternal !== showInternal) {
        setShowInternal(preferenceShowInternal);
      }
      if (preferenceShowAttendees !== showAttendees) {
        setShowAttendees(preferenceShowAttendees);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userProfile?.preferenceAgendaMeetingFilter,
    userProfile?.preferenceAgendaShowAttendees,
  ]);

  const {
    data: eventsData,
    isLoading: eventsLoading,
    refetch: refetchEvents,
    isError: eventsErrored,
    isFetching: eventsFetching,
    isRefetching: eventsRefetching,
    error: eventsError,
  } = useQuery(
    OrganizationUpcomingMeetings(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return EventsApiClient.listEvents(
        {
          organizationSlug: organizationSlug!,
          q: searchString || undefined,
          minDate: selectedDate.startOf('week').startOf('day').toISO()!,
          maxDate: selectedDate.plus({ days: 7 }).endOf('day').toISO()!,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      refetchInterval: Duration.fromObject({ minutes: 15 }).as('milliseconds'),
      staleTime: Duration.fromObject({ minutes: 30 }).as('milliseconds'),
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      retry: 1,
      enabled: !!organizationSlug,
      queryHash: `${OrganizationUpcomingMeetings(
        organizationSlug!,
      )}_${searchString}_${selectedDate
        .startOf('week')
        .startOf('day')
        .toISO()}`,
    },
  );

  useEffect(() => {
    refetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWeekStart, searchParams]);

  const daysOfActiveWeek: DateTime[] = [];
  let i;
  for (i = 0; i <= 6; i++) {
    daysOfActiveWeek.push(activeWeekStart.plus({ days: i }));
  }

  const goToNextWeek = () => {
    const nextWeekStart = activeWeekStart.plus({ weeks: 1 }).startOf('week');

    appInsights.trackEvent({
      name: 'HOME_PAGE_CALENDAR_CHANGE_CONTROLS',
      properties: {
        property: 'selectedDate',
        button: 'nextWeek',
        organizationSlug,
        emailAddress: user?.email,
        selectedDate: nextWeekStart.toISO()!,
      },
    });

    mergeSearchParams({ selectedDate: nextWeekStart.toISO()! });
  };

  const goToPreviousWeek = () => {
    const previousWeekStart = activeWeekStart
      .minus({ weeks: 1 })
      .startOf('week');

    appInsights.trackEvent({
      name: 'HOME_PAGE_CALENDAR_CHANGE_CONTROLS',
      properties: {
        property: 'selectedDate',
        button: 'previousWeek',
        organizationSlug,
        emailAddress: user?.email,
        selectedDate: previousWeekStart.toISO()!,
      },
    });

    mergeSearchParams({ selectedDate: previousWeekStart.toISO()! });
  };

  const goToToday = () => {
    appInsights.trackEvent({
      name: 'HOME_PAGE_CALENDAR_CHANGE_CONTROLS',
      properties: {
        property: 'selectedDate',
        button: 'today',
        organizationSlug,
        emailAddress: user?.email,
        selectedDate: DateTime.now().toISO()!,
      },
    });

    mergeSearchParams({ selectedDate: DateTime.now().toISO()! });
  };

  const fadeInClass = mergeStyles({
    position: 'relative',
    animationName: 'fadeInSlideAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    width: 'auto',
    transition: '.3s ease-in-out all',
  });

  const calendarControlsClass = mergeStyles({
    width: '100%',
    padding: '.5rem 0 .25rem 0',
    textAlign: 'left',
    position: 'relative',
    backgroundColor: isDark
      ? NeutralColors.gray200
      : MEETINGFLOW_COLORS.purpleUltraLight,
    borderBottom: `1px solid ${
      isDark ? NeutralColors.gray170 : MEETINGFLOW_COLORS.white
    }`,

    button: {
      display: 'inline-block',
    },

    'span.month': {
      display: 'block',
      position: 'absolute',
      left: '1rem',
      top: '1rem',
    },

    '.toggle-internal': {
      whiteSpace: 'nowrap',
    },

    '.today-button': {
      whiteSpace: 'nowrap',
      display: !breakpoints.md ? 'none' : undefined,
    },

    '.toggle-attendees': {
      whiteSpace: 'nowrap',
    },
    '.ms-Toggle-label': {
      marginRight: '.5rem',
    },
    '.week-selection': {
      marginRight: '1rem',
    },
  });

  const dateHeaderClass = mergeStyles({
    textAlign: 'center',
    borderBottom: `.25rem solid transparent`,
    position: 'relative',
    color: NeutralColors.gray130,
    padding: breakpoints.lg ? '.3rem .25 .35rem .25' : undefined,
    transition: '.3s ease-in-out all',
    ':hover': {
      cursor: 'pointer',
      borderBottom: `.25rem solid ${MEETINGFLOW_COLORS.purpleSecondary}`,
    },
    '&.active': {
      color: MEETINGFLOW_COLORS.teal,
      borderBottom: `.25rem solid ${MEETINGFLOW_COLORS.teal}`,
    },
    '.day-of-week': {
      display: 'block',
      fontSize: FontSizes.size12,
      color: isDark ? NeutralColors.gray50 : NeutralColors.gray120,
    },
    '.day-of-month': {
      display: 'block',
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.size16,
      color: isDark ? NeutralColors.gray50 : NeutralColors.gray120,
    },
    '.event-indicator': {
      display: 'block',
      fontWeight: FontWeights.semibold,
      position: 'absolute',
      width: '10px',
      left: 'calc(50% - 5px)',
      bottom: '0',
      color: MEETINGFLOW_COLORS.orange,
    },
  });

  const eventCardWrapperClass = mergeStyles({
    transition: '.3s ease-in-out all',

    ':hover': {},

    '.meeting-plan-create-button': {
      margin: 0,
    },
  });

  const eventColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'title',
        name: 'Event',
        minWidth: 150,
        className: 'agenga-event-column',
        headerClassName: 'agenda-event-column',
        fieldName: 'title',
        onRender: (event: CalendarEvent) => {
          const eventIsInPast =
            DateTime.now() > DateTime.fromISO(event.endTime);

          const eventIsNow =
            DateTime.now() > DateTime.fromISO(event.startTime) &&
            DateTime.now() < DateTime.fromISO(event.endTime);

          const eventIsSoon =
            DateTime.fromISO(event.startTime).diffNow('minutes').minutes < 60;

          const eventIsNowOrSoon =
            !eventIsInPast && (eventIsNow || eventIsSoon);

          if (event?.meetingplanId) {
            return (
              <MeetingflowCard
                meetingflowId={event.meetingplanId}
                organizationSlug={organizationSlug!}
                showCompanies
                showCallRecordingButton
                onClick={() => {
                  appInsights.trackEvent({
                    name: 'HOME_PAGE_CALENDAR_CLICK_MEETINGFLOW',
                    properties: {
                      organizationSlug,
                      meetingPlanId: event.meetingplanId,
                      emailAddress: user?.email,
                    },
                  });
                  navigate(
                    `/organization/${organizationSlug}/plan/${event.meetingplanId}`,
                  );
                }}
              />
            );
          }

          return (
            <div className={classnames(eventCardWrapperClass)}>
              {organizationSlug ? (
                <>
                  <EventCard
                    event={event}
                    key={event.iCalUid}
                    organizationSlug={organizationSlug}
                    showCompanies
                    showTextLabelOnCreateButton
                  />
                  {/* <div
                    style={{
                      position: 'absolute',
                      right: '.5rem',
                      top: '.5rem',
                    }}
                  >
                    {event.conferenceInfo ? (
                      <div className="conference-join-wrapper">
                        <JoinConferenceButton event={event} />
                      </div>
                    ) : eventIsNow ? (
                      <Text
                        style={{
                          fontWeight: FontWeights.bold,
                          color: MEETINGFLOW_COLORS.orange,
                        }}
                      >
                        Now
                      </Text>
                    ) : eventIsNowOrSoon ? (
                      <Text
                        style={{
                          fontWeight: FontWeights.bold,
                          color: MEETINGFLOW_COLORS.teal,
                          lineHeight: '2rem',
                        }}
                      >
                        Soon
                      </Text>
                    ) : null}
                  </div> */}
                </>
              ) : null}
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationSlug, eventCardWrapperClass, user, user?.email],
  );

  const events: CalendarEvent[] = useMemo(() => {
    if (!eventsData?.data) {
      return [];
    }

    // Filter out events from days prior to the selected date. We need these events
    // in the query for the "dot" indicators on previous days, but we do not want to display them.
    let filteredEvents: CalendarEvent[] = eventsData.data.filter(
      (event) =>
        DateTime.fromISO(event?.startTime).startOf('day') >=
        selectedDate.startOf('day'),
    );

    // If we are not hiding internal meetings, we're good
    if (showInternal) {
      return filteredEvents;
    }

    // If we're hiding internal meetings, filter them out (also filter out "private" visibility events).
    filteredEvents = filteredEvents.filter((event) => {
      return (
        (event.organizer?.emailDomain &&
          !internalDomains.includes(event.organizer.emailDomain)) ||
        (event.creator?.emailDomain &&
          !internalDomains.includes(event.creator.emailDomain)) ||
        event.attendees?.some((attendee) => {
          return (
            !internalDomains.includes(attendee.emailDomain) && !event.isPrivate
          );
        })
      );
    });

    return filteredEvents;
  }, [eventsData?.data, showInternal, internalDomains, selectedDate]);

  const CalendarHeader = (
    <div
      style={{
        position: 'sticky',
        top: '0',
      }}
    >
      <div className={calendarControlsClass}>
        <div className="week-selection">
          <div
            style={{
              display: 'inline-block',
              width: 'auto',
              height: '2rem',
              position: 'relative',
              textAlign: 'left',
              padding: '0 .5rem',
            }}
          >
            <Toggle
              label={'Show Internal'}
              styles={{
                root: {
                  display: 'inline-block',
                  textAlign: 'left',
                  whiteSpace: 'nowrap',
                  position: 'relative',
                  top: '-.4rem',
                  '.ms-Toggle-innerContainer': {
                    display: 'block',
                    whiteSpace: 'nowrap',
                    textAlign: 'left',
                    button: {
                      transition: 'all .3s ease-in-out',
                      backgroundColor: isDark
                        ? NeutralColors.gray180
                        : MEETINGFLOW_COLORS.purpleGrey,
                    },
                  },
                  'label, button': {
                    display: 'block',
                    marginRight: '.25rem',
                    textAlign: 'left',
                    fontSize: FontSizes.small,
                    color: NeutralColors.gray120,
                    border: 'none !important',
                  },
                  label: {
                    height: '.75rem',
                    lineHeight: '.75rem',
                    fontSize: FontSizes.mini,
                    marginBottom: '.5rem',
                    color: NeutralColors.gray120,
                  },
                  button: {
                    display: 'flex',
                    position: 'relative',
                    top: '.1rem',
                  },
                  '.ms-Toggle-thumb': {
                    backgroundColor: showInternal
                      ? MEETINGFLOW_COLORS.purpleTertiary
                      : isDark
                        ? NeutralColors.gray130
                        : NeutralColors.gray70,
                  },
                  'button:disabled .ms-Toggle-thumb': {
                    backgroundColor: isDark
                      ? NeutralColors.gray170
                      : NeutralColors.gray70,
                  },
                  'button:hover': {
                    backgroundColor: isDark
                      ? NeutralColors.gray190
                      : NeutralColors.gray40,
                  },
                },
              }}
              checked={showInternal}
              onChange={(_e, checked) => {
                appInsights.trackEvent({
                  name: 'HOME_PAGE_CALENDAR_CHANGE_CONTROLS',
                  properties: {
                    property: 'showInternal',
                    organizationSlug,
                    emailAddress: user?.email,
                    showInternal: !!checked,
                  },
                });

                updateUserProfile({
                  preferenceAgendaMeetingFilter: checked
                    ? 'SHOW_ALL'
                    : 'EXTERNAL_ONLY',
                });

                setShowInternal(checked ?? false);
              }}
              role="checkbox"
              className="toggle-internal"
            />
            <TooltipHost
              content={
                <>
                  <Text
                    variant="mediumPlus"
                    block
                    style={{
                      fontWeight: FontWeights.semibold,
                      marginBottom: '.5rem',
                      fontSize: FontSizes.smallPlus,
                      color: MEETINGFLOW_COLORS.white,
                    }}
                  >
                    Show Internal Events
                  </Text>
                  <Text
                    variant="small"
                    block
                    style={{
                      color: MEETINGFLOW_COLORS.white,
                      marginBottom: '.5rem',
                    }}
                  >
                    <strong>On:</strong> Shows all meetings, including internal
                    (all attendees are from your workspace) and private
                  </Text>
                  <Text
                    variant="small"
                    block
                    style={{ color: MEETINGFLOW_COLORS.white }}
                  >
                    <strong>Off:</strong> Does not display meetings that are
                    internal (all attendees are from your workspace) or private
                  </Text>
                </>
              }
              styles={{
                root: {
                  margin: '0',
                  display: 'inline-block',
                  position: 'absolute',
                  top: '-.1rem',
                },
              }}
              calloutProps={{
                backgroundColor: MEETINGFLOW_COLORS.teal,
                styles: {
                  root: {
                    padding: 0,
                    minWidth: '12rem',
                    maxWidth: '18rem',
                    color: MEETINGFLOW_COLORS.white,
                  },
                  calloutMain: {
                    padding: '.5rem',
                    color: MEETINGFLOW_COLORS.white,
                  },
                },
              }}
            >
              <FontIcon
                iconName="Info"
                className="toggle-info"
                style={{
                  fontSize: '14px',
                  color: NeutralColors.gray100,
                  cursor: 'help',
                  display: 'inline-block',
                  position: 'relative',
                }}
              />
            </TooltipHost>
          </div>
          <div
            style={{
              display: 'inline-block',
              position: 'absolute',
              right: '0',
              top: '.5rem',
              width: '11.5rem',
              textAlign: 'right',
            }}
          >
            <DefaultButton
              text={breakpoints.lg ? 'Today' : 'Now'}
              onClick={goToToday}
              className="today-button"
              disabled={eventsLoading || eventsFetching || eventsRefetching}
              styles={{
                root: {
                  padding: breakpoints.lg ? '0 .5rem' : 0,
                  backgroundColor: isDark
                    ? NeutralColors.gray170
                    : MEETINGFLOW_COLORS.purpleGrey,
                  height: '24px',
                  border: 'none',
                  color: MEETINGFLOW_COLORS.purpleSecondary,
                  transition: 'all .3s ease-in-out',
                  marginRight: '.25rem',
                  minWidth: '1rem',

                  ':hover': {
                    backgroundColor: `${MEETINGFLOW_COLORS.purpleDark} !important`,
                  },
                },
                textContainer: {
                  fontSize: FontSizes.small,
                },
                rootHovered: {
                  backgroundColor: `${MEETINGFLOW_COLORS.purpleDark} !important`,
                  color: 'white',
                },
                rootDisabled: {
                  backgroundColor: `${
                    isDark
                      ? NeutralColors.gray200
                      : MEETINGFLOW_COLORS.purpleGrey
                  } !important`,
                  color: 'white',
                },
              }}
            />
            <AsyncIconButton
              text="Previous Week"
              iconProps={{ iconName: 'FlickRight' }}
              onClick={async () => goToPreviousWeek()}
              disabled={eventsLoading || eventsFetching || eventsRefetching}
              styles={{
                root: {
                  position: 'relative',
                  top: '.15rem',
                  padding: 0,
                  backgroundColor: 'transparent',
                  height: '24px',
                  border: 'none',
                  color: MEETINGFLOW_COLORS.purpleTertiary,
                  transition: 'all .3s ease-in-out',

                  ':hover': {
                    backgroundColor: `transparent !important`,
                  },
                },
                rootDisabled: {
                  backgroundColor: `transparent !important`,
                  color: MEETINGFLOW_COLORS.purpleTertiary,
                },
              }}
            />
            <DatePicker
              value={new Date(searchParams.get('selectedDate')!)}
              onSelectDate={(date: Date | null | undefined) => {
                if (!!date) {
                  appInsights.trackEvent({
                    name: 'HOME_PAGE_CALENDAR_CHANGE_CONTROLS',
                    properties: {
                      property: 'selectedDate',
                      button: 'picker',
                      organizationSlug,
                      emailAddress: user?.email,
                      selectedDate: date.toISOString(),
                    },
                  });

                  mergeSearchParams({ selectedDate: date.toISOString() });
                }
              }}
              placeholder="Select a date..."
              ariaLabel="Select a date"
              borderless
              formatDate={(date) =>
                DateTime.fromJSDate(date!).toFormat('MMMM YYYY')
              }
              styles={{
                root: {
                  position: 'relative',
                  top: '.15rem',
                  right: '.15rem',
                  display: 'inline-block',
                  backgroundColor: 'transparent',
                  '.ms-TextField-fieldGroup': {
                    backgroundColor: 'transparent',
                  },
                  'i[data-icon-name="Calendar"]': {
                    color: MEETINGFLOW_COLORS.purpleTertiary,
                  },
                },
                textField: {
                  '[role=combobox]': {
                    width: '0rem !important',
                    opacity: 0,
                    fontWeight: `${FontWeights.semibold} !important`,
                    fontSize: `${FontSizes.xLarge} !important`,
                    backgroundColor: 'transparent',
                  },
                },
              }}
            />
            <AsyncIconButton
              text="Next Week"
              iconProps={{ iconName: 'FlickLeft' }}
              onClick={async () => goToNextWeek()}
              disabled={eventsLoading || eventsFetching || eventsRefetching}
              styles={{
                root: {
                  position: 'relative',
                  top: '.15rem',
                  padding: 0,
                  backgroundColor: 'transparent',
                  height: '24px',
                  border: 'none',
                  color: MEETINGFLOW_COLORS.purpleTertiary,
                  transition: 'all .3s ease-in-out',

                  ':hover': {
                    backgroundColor: `transparent !important`,
                  },
                },
                rootDisabled: {
                  backgroundColor: `transparent !important`,
                  color: 'white',
                },
              }}
            />
          </div>
        </div>
      </div>
      <Stack
        tokens={DEFAULT_STACK_TOKENS}
        horizontal
        style={{
          width: '100%',
          backgroundColor: isDark
            ? NeutralColors.gray220
            : MEETINGFLOW_COLORS.purpleUltraSuperLight,
          borderBottom: isDark
            ? `1px solid ${NeutralColors.gray220}`
            : `1px solid ${MEETINGFLOW_COLORS.purpleGrey}`,
        }}
      >
        {daysOfActiveWeek.map((day) => (
          <Stack.Item
            grow={1}
            key={day.toLocaleString()}
            className={classnames([
              dateHeaderClass,
              selectedDate.hasSame(day, 'day') ? 'active' : undefined,
            ])}
            onClick={() => {
              appInsights.trackEvent({
                name: 'HOME_PAGE_CALENDAR_CHANGE_CONTROLS',
                properties: {
                  property: 'selectedDate',
                  button: 'dayPicker',
                  organizationSlug,
                  emailAddress: user?.email,
                  selectedDate: day.toISO()!,
                },
              });

              mergeSearchParams({ selectedDate: day.toISO()! });
            }}
          >
            <span className="day-of-week">
              {day.toLocaleString({ weekday: 'short' })}
            </span>
            <span className="day-of-month">
              {day.toLocaleString({ day: 'numeric' })}
            </span>
          </Stack.Item>
        ))}
      </Stack>
    </div>
  );

  const dayGroups = useMemo(() => {
    return toIGroups(events);
  }, [events]);

  const loading =
    !events.length && (eventsLoading || eventsFetching || orgIsLoading);

  const error = useMemo(() => {
    if (!eventsErrored) {
      return null;
    }
    if (isAxiosErrorResponse(eventsError)) {
      return (
        <div
          className={fadeInClass}
          style={{
            backgroundColor: MEETINGFLOW_COLORS.tealLight,
            height: breakpoints.md ? 'calc(100vh - 14rem)' : 'auto',
            margin: 0,
            padding: '1rem',
          }}
        >
          <div
            className={fadeInClass}
            style={{
              backgroundColor: MEETINGFLOW_COLORS.teal,
              padding: '1rem 1.5rem',
              borderRadius: '.25rem',
              textAlign: 'center',
              fontSize: FontSizes.mediumPlus,
              color: 'white',
            }}
          >
            <Text
              block
              style={{
                fontWeight: FontWeights.semibold,
                fontSize: FontSizes.large,
                color: 'inherit',
                marginBottom: '.5rem',
              }}
            >
              Let's get your calendar set up.
            </Text>{' '}
            <Text
              block
              style={{
                color: 'inherit',
                fontSize: 'inherit',
                marginBottom: '.5rem',
                lineHeight: '1.25rem',
              }}
            >
              Meetingflow works best when it has access to your{' '}
              {isGoogleUser(user!.sub!) ? 'Google' : 'Microsoft'} Calendar.
              We'll show your agenda here, and you'll be able to create
              Meetingflows from any event with one click.
            </Text>
            <AsyncLink
              styles={{
                root: {
                  display: 'inline-block',
                  textDecoration: 'none !important',
                  backgroundColor: MEETINGFLOW_COLORS.white,
                  color: MEETINGFLOW_COLORS.purpleSecondary,
                  fontSize: FontSizes.large,
                  borderRadius: '1.5rem',
                  padding: '.5rem 1rem',
                  margin: '1rem auto',
                  fontWeight: FontWeights.semibold,
                  transition: 'all .3s ease-in-out',
                  textAlign: 'center',

                  ':hover': {
                    backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
                    color: MEETINGFLOW_COLORS.white,
                  },
                },
              }}
              onClick={async () => {
                appInsights.trackEvent({
                  name: 'CONNECT_CALENDAR_CLICK',
                  properties: {
                    organizationSlug,
                    emailAddress: user?.email,
                    provider: isGoogleUser(user!.sub!) ? 'GOOGLE' : 'M365',
                    surface: 'WORKSPACE_HOME',
                  },
                });
                const token = await getAccessTokenWithPopup({
                  cacheMode: 'off',
                  authorizationParams: {
                    display: 'popup',
                    login_hint: user?.email,
                    connection: isGoogleUser(user!.sub!)
                      ? 'google-oauth2'
                      : 'Microsoft',
                  },
                });

                await ApiClient.delete(
                  `/token/${isGoogleUser(user!.sub!) ? 'GOOGLE' : 'M365'}`,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );

                client.invalidateQueries(
                  OrganizationMeetingsHappeningSoon(organizationSlug!),
                );

                refetchEvents();
              }}
            >
              Connect your {isGoogleUser(user!.sub!) ? 'Google' : 'Microsoft'}{' '}
              Calendar
            </AsyncLink>
            <Text
              block
              style={{
                color: `rgba(255,255,255,.75)`,
                fontSize: FontSizes.small,
                margin: '1.5rem 1rem 0 1rem',
              }}
            >
              Alternatively, create a Meetingflow without connecting your
              calendar using the "New Meetingflow" button in the upper-right!
            </Text>
          </div>
        </div>
      );
    }
    return (
      <div>
        Something went wrong fetching your agenda, refresh the page to try again
      </div>
    );
  }, [
    eventsErrored,
    eventsError,
    user,
    getAccessTokenWithPopup,
    client,
    organizationSlug,
    refetchEvents,
    fadeInClass,
    appInsights,
    breakpoints.md,
  ]);

  const agendaClass = mergeStyles({
    '.agenga-event-column.ms-DetailsRow-cell': {
      padding: '.25rem',
      width: '100% !important',
    },

    '.ms-DetailsRow-fields': {
      width: '100% !important',
      minWidth: '100% !important',
      display: 'block',
    },
  });

  return (
    <>
      {CalendarHeader}
      {eventsErrored ? (
        error
      ) : (
        <div>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: '3rem',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <div className={agendaClass}>
              <StyledDetailsList
                shimmerLines={25}
                enableShimmer={loading}
                items={events || EMPTY_ARRAY}
                groups={dayGroups?.length ? dayGroups : undefined}
                selectionMode={SelectionMode.none}
                columns={eventColumns}
                isHeaderVisible={false}
                maxHeight={
                  breakpoints.md
                    ? `calc(100vh - ${detailListTopOffset})`
                    : undefined
                }
                // @ts-ignore
                noDataMessage={
                  <Text
                    block
                    style={{
                      textAlign: 'center',
                      fontSize: FontSizes.medium,
                      fontWeight: FontWeights.bold,
                      color: MEETINGFLOW_COLORS.teal,
                      padding: '1rem',
                    }}
                  >
                    There are no events this week!
                    <br />
                    {!showInternal
                      ? ' You may want to switch off the "Show Internal Events" filter.'
                      : null}
                  </Text>
                }
              />
              {error}
            </div>
          )}
        </div>
      )}
    </>
  );
};
