import { useAuth0 } from '@auth0/auth0-react';
import {
  FontWeights,
  FontSizes,
  Text,
  Dropdown,
  TooltipHost,
  FontIcon,
  NeutralColors,
  mergeStyles,
  Toggle,
  IToggleStyles,
  Spinner,
} from '@fluentui/react';
import {
  MeetingPlanTasksQuery,
  OrganizationUserTasksQuery,
} from '../../../QueryNames';
import { TasksApiClient } from '../../../Services/NetworkCommon';
import { OrganizationSlugRouteParams } from '../../../types/RouteParams';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { PatchTaskPayload, Task } from '@meetingflow/common/Api/data-contracts';
import { MeetingPlanActionItems } from '../../MeetingPlans/Sections/MeetingPlanActionItems';
import { AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { isAxiosErrorResponse } from '../../../Helpers/AxiosHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { useLocalStorageState } from '../../../Hooks/useLocalStorageState';
import useBreakpoints from '../../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useEffect } from 'react';

export const TasksPane = ({ heightOffset = '350px' }) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { organizationSlug } = useParams<OrganizationSlugRouteParams>();
  const client = useQueryClient();
  const appInsights = useAppInsightsContext();
  const breakpoints = useBreakpoints();
  const { isDark } = useLightOrDarkMode();

  const [sortOrder, setSortOrder] = useLocalStorageState<'desc' | 'asc'>(
    'ORG_HOME_ACTION_ITEMS_SORT_ORDER',
    'asc',
  );
  const [sortBy, setSortBy] = useLocalStorageState<
    'dueDate' | 'createdAt' | 'updatedAt'
  >('ORG_HOME_ACTION_ITEMS_SORT_BY', 'dueDate');

  const [onlyMine, setOnlyMine] = useLocalStorageState<boolean>(
    'ORG_HOME_ACTION_ITEMS_ONLY_MINE',
    true,
  );

  const [completed, setCompleted] = useLocalStorageState<boolean>(
    'ORG_HOME_ACTION_ITEMS_COMPLETED',
    false,
  );

  const {
    data: tasksData,
    refetch,
    isRefetching: tasksRefetching,
    isLoading: tasksLoading,
  } = useQuery(
    OrganizationUserTasksQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return TasksApiClient.listTasks(
        {
          organizationSlug: organizationSlug!,
          my: onlyMine,
          completed,
          includeMeetingflow: true,
          sortBy,
          sortOrder,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug,
      // 15 minutes
      refetchInterval: 900000,
      staleTime: 900000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      retry: 1,
    },
  );

  useEffect(() => {
    refetch?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder, onlyMine, completed]);

  const { mutate: patchTask } = useMutation({
    mutationFn: async (
      update: { id: Task['id']; meetingflowId: string } & PatchTaskPayload,
    ) => {
      const token = await getAccessTokenSilently();
      return TasksApiClient.patchTask(
        organizationSlug || '',
        update.meetingflowId,
        update.id,
        {
          text: update.text,
          completed: update.completed,
          dueDate: update.dueDate,
          assigneeIdOrEmail: update.assigneeIdOrEmail,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    onMutate: async (update) => {
      await client.cancelQueries({
        queryKey: MeetingPlanTasksQuery(
          organizationSlug || '',
          update.meetingflowId,
        ),
      });

      const previous = client.getQueryData(
        MeetingPlanTasksQuery(organizationSlug || '', update.meetingflowId),
      ) as AxiosResponse<Task[]>;

      if (previous?.data) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug || '', update.meetingflowId),
          {
            ...previous,
            data: previous.data?.map((t) =>
              t.id !== update.id
                ? t
                : ({
                    ...t,
                    text: update.text ?? t.text,
                    completed: update.completed ?? t.completed,
                    dueDate: update.dueDate ?? t.dueDate,
                  } satisfies Task),
            ),
          },
        );
      }

      return {
        previous,
      };
    },
    onError: (err, update, ctx) => {
      toast.error(`Something went wrong updating Task`);

      appInsights.trackException({
        exception: err instanceof Error ? err : new Error(`${err}`),
        properties: {
          organizationSlug,
          status: isAxiosErrorResponse(err) ? err.response?.status : undefined,
          statusText: isAxiosErrorResponse(err)
            ? err.response?.statusText
            : undefined,
        },
      });

      if (ctx?.previous) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug || '', update.meetingflowId),
          ctx.previous,
        );
      }
    },
    onSuccess: (response, update, ctx) => {
      refetch();
      appInsights.trackEvent({
        name: 'UPDATE_ACTION_ITEM',
        properties: {
          organizationSlug,
          meetingPlanId: update.meetingflowId,
          id: update.id,
          completed: response.data.completed,
        },
      });
      if (ctx?.previous) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug || '', update.meetingflowId),
          {
            ...ctx.previous,
            data: ctx.previous.data?.map((t) =>
              t.id !== update.id ? t : response.data,
            ),
          },
        );
      }
    },
    onSettled: (_data, _error, update) => {
      client.invalidateQueries(
        MeetingPlanTasksQuery(organizationSlug || '', update.meetingflowId),
      );
    },
  });

  const { mutate: deleteTask } = useMutation({
    mutationFn: async (update: { id: Task['id']; meetingflowId: string }) => {
      const token = await getAccessTokenSilently();
      return TasksApiClient.deleteTask(
        organizationSlug || '',
        update.meetingflowId,
        update.id,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    onMutate: async (update: { id: Task['id']; meetingflowId: string }) => {
      await client.cancelQueries({
        queryKey: MeetingPlanTasksQuery(
          organizationSlug || '',
          update.meetingflowId,
        ),
      });

      const previous = client.getQueryData(
        MeetingPlanTasksQuery(organizationSlug || '', update.meetingflowId),
      ) as AxiosResponse<Task[]>;

      if (previous?.data) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug || '', update.meetingflowId),
          {
            ...previous,
            data: previous.data?.filter((t) => t.id !== update.id),
          },
        );
      }

      return {
        previous,
      };
    },
    onError: (err, update, ctx) => {
      toast.error(`Something went wrong deleting Task`);

      appInsights.trackException({
        exception: err instanceof Error ? err : new Error(`${err}`),
        properties: {
          organizationSlug,
          status: isAxiosErrorResponse(err) ? err.response?.status : undefined,
          statusText: isAxiosErrorResponse(err)
            ? err.response?.statusText
            : undefined,
        },
      });

      if (ctx?.previous) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug || '', update.meetingflowId),
          ctx.previous,
        );
      }
    },
    onSuccess: (_response, update, _ctx) => {
      refetch();
      appInsights.trackEvent({
        name: 'REMOVE_ACTION_ITEM',
        properties: {
          organizationSlug,
          meetingPlanId: update.meetingflowId,
          id: update.id,
        },
      });
    },
    onSettled: (_data, _error, update) => {
      client.invalidateQueries(
        MeetingPlanTasksQuery(organizationSlug || '', update.meetingflowId),
      );
    },
  });

  const actionItemsControlsClass = mergeStyles({
    position: 'sticky',
    top: 0,
    backgroundColor: isDark
      ? NeutralColors.gray200
      : MEETINGFLOW_COLORS.purpleUltraLight,
    height: '3.25rem',
    overflow: 'hidden',
    display: 'flex',
    columnGap: '.5rem',
    padding: '0 .5rem',
  });

  const dropdownStyles = {
    root: {
      marginBottom: '.5rem',
      flexGrow: 1,
      transition: '.3s ease-in-out all',
    },
    dropdown: {
      height: '1.5rem',
      lineHeight: '1.5rem',
      fontSize: FontSizes.small,
      backgroundColor: isDark
        ? NeutralColors.gray200
        : MEETINGFLOW_COLORS.purpleGrey,
      border: 'none',
      borderRadius: '.25rem',
    },
    title: {
      height: '1.5rem',
      lineHeight: '1.25rem',
      backgroundColor: isDark
        ? NeutralColors.gray180
        : MEETINGFLOW_COLORS.purpleGrey,
      border: 'none',
      borderRadius: '.25rem',
      color: isDark ? NeutralColors.gray80 : MEETINGFLOW_COLORS.purpleDark,
    },
    caretDownWrapper: {
      height: '1.5rem',
      lineHeight: '1.5rem',
    },
    label: {
      padding: 0,
      height: '.75rem',
      lineHeight: '.75rem',
      fontSize: FontSizes.mini,
      marginBottom: '.25rem',
      color: NeutralColors.gray120,
    },
  };

  const getToggleStyles = (on: boolean) => {
    return {
      root: {
        display: 'inline-block',
        margin: 0,
        flexGrow: 1,
        transition: '.3s ease-in-out all',
        textAlign: 'right',
        position: 'relative',
        top: '.25rem',

        '.ms-Toggle-innerContainer': {
          textAlign: 'right',
          justifyContent: 'flex-end',
          position: 'relative',
          top: '.15rem',
          button: {
            transition: 'all .3s ease-in-out',
            backgroundColor: isDark
              ? NeutralColors.gray180
              : MEETINGFLOW_COLORS.purpleGrey,
          },
        },
        '.ms-Toggle-thumb': {
          backgroundColor: on
            ? MEETINGFLOW_COLORS.purpleTertiary
            : isDark
            ? NeutralColors.gray130
            : NeutralColors.gray70,
        },
        'button:disabled .ms-Toggle-thumb': {
          backgroundColor: NeutralColors.gray70,
        },
        'button:hover': {
          backgroundColor: isDark
            ? NeutralColors.gray190
            : NeutralColors.gray40,
        },
        button: {
          position: 'relative',
          top: '  .1rem',
        },
      },
      label: {
        padding: 0,
        height: '.75rem',
        lineHeight: '.75rem',
        fontSize: FontSizes.mini,
        marginBottom: '.25rem',
        color: NeutralColors.gray120,
        lineClamp: 2,
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
      },
      pill: {
        backgroundColor: NeutralColors.gray40,
        outlineWidth: `0 !important`,
        borderWidth: `0 !important`,
      },
    } as Partial<IToggleStyles>;
  };

  return (
    <>
      <div className={actionItemsControlsClass}>
        <Dropdown
          label="Sort By"
          options={[
            {
              key: 'dueDate',
              text: breakpoints.lg ? 'Due Date' : 'Due',
            },
            {
              key: 'createdAt',
              text: breakpoints.lg ? 'Task Created' : 'Created',
            },
            {
              key: 'updatedAt',
              text: breakpoints.lg ? 'Task Updated' : 'Updated',
            },
          ]}
          onChange={(_, option) => {
            appInsights.trackEvent({
              name: 'HOME_PAGE_ACTION_ITEMS_CHANGE_CONTROLS',
              properties: {
                property: 'sortBy',
                organizationSlug,
                emailAddress: user?.email,
                sortBy: option?.key,
              },
            });

            setSortBy(option?.key as 'dueDate' | 'createdAt' | 'updatedAt');
          }}
          selectedKey={sortBy}
          styles={dropdownStyles}
        />

        <Dropdown
          label="Sort Order"
          options={[
            { key: 'desc', text: breakpoints.lg ? 'Descending' : 'Desc' },
            { key: 'asc', text: breakpoints.lg ? 'Ascending' : 'Asc' },
          ]}
          onChange={(_, option) => {
            appInsights.trackEvent({
              name: 'HOME_PAGE_ACTION_ITEMS_CHANGE_CONTROLS',
              properties: {
                property: 'sortOrder',
                organizationSlug,
                emailAddress: user?.email,
                sortOrder: option?.key,
              },
            });

            setSortOrder(option?.key as 'desc' | 'asc');
          }}
          selectedKey={sortOrder}
          styles={dropdownStyles}
        />

        <div style={{ whiteSpace: 'nowrap', minWidth: '4rem' }}>
          <Toggle
            disabled={tasksLoading || tasksRefetching}
            label={'Completed'}
            checked={completed === true}
            onChange={(e, checked) => {
              appInsights.trackEvent({
                name: 'HOME_PAGE_ACTION_ITEMS_CHANGE_CONTROLS',
                properties: {
                  property: 'completed',
                  organizationSlug,
                  emailAddress: user?.email,
                  completed: !!checked,
                },
              });

              setCompleted(!!checked);
            }}
            styles={getToggleStyles(completed)}
          />
        </div>

        <div style={{ whiteSpace: 'nowrap' }}>
          <TooltipHost
            content={
              <>
                <Text
                  variant="mediumPlus"
                  block
                  style={{
                    fontWeight: FontWeights.semibold,
                    marginBottom: '.5rem',
                    fontSize: FontSizes.smallPlus,
                    color: MEETINGFLOW_COLORS.white,
                  }}
                >
                  Show Mine Only
                </Text>
                <Text
                  variant="small"
                  block
                  style={{
                    color: MEETINGFLOW_COLORS.white,
                    marginBottom: '.5rem',
                  }}
                >
                  <strong>On:</strong> Shows Action Items where you are the
                  assignee, as well as unassigned Action Items you created.
                </Text>
                <Text
                  variant="small"
                  block
                  style={{ color: MEETINGFLOW_COLORS.white }}
                >
                  <strong>Off:</strong> Shows all Action Items in your
                  workspace.
                </Text>
              </>
            }
            styles={{
              root: {
                margin: '0',
                display: 'inline-block',
                position: 'relative',
                top: '-.9rem',
                marginRight: '.25rem',
              },
            }}
            calloutProps={{
              backgroundColor: MEETINGFLOW_COLORS.teal,
              styles: {
                root: {
                  padding: 0,
                  minWidth: '12rem',
                  maxWidth: '18rem',
                  color: MEETINGFLOW_COLORS.white,
                },
                calloutMain: {
                  padding: '.5rem',
                  color: MEETINGFLOW_COLORS.white,
                },
              },
            }}
          >
            <FontIcon
              iconName="Info"
              className="toggle-info"
              style={{
                fontSize: '14px',
                color: NeutralColors.gray100,
                cursor: 'help',
                display: 'inline-block',
                position: 'relative',
              }}
            />
          </TooltipHost>

          <Toggle
            disabled={tasksLoading || tasksRefetching}
            label={breakpoints.lg ? 'Mine Only' : 'Mine Only'}
            checked={onlyMine === true}
            onChange={(e, checked) => {
              appInsights.trackEvent({
                name: 'HOME_PAGE_ACTION_ITEMS_CHANGE_CONTROLS',
                properties: {
                  property: 'onlyMine',
                  organizationSlug,
                  emailAddress: user?.email,
                  onlyMine,
                },
              });

              setOnlyMine(!!checked);
            }}
            styles={getToggleStyles(onlyMine)}
          />
        </div>
      </div>

      {organizationSlug &&
      tasksData?.data?.length &&
      tasksData?.data?.length > 0 &&
      !tasksLoading ? (
        <div style={breakpoints.md ? { maxHeight: '100%' } : undefined}>
          <div style={{ padding: '0' }}>
            <MeetingPlanActionItems
              tasks={tasksData?.data}
              organizationSlug={organizationSlug}
              onDelete={(taskId: string) => {
                const meetingflowId = tasksData?.data?.find(
                  (t) => t.id === taskId,
                )?.meetingPlanId;
                if (meetingflowId) {
                  deleteTask({ id: taskId, meetingflowId });
                }
              }}
              onUpdate={(id: Task['id'], patch: PatchTaskPayload) => {
                const meetingflowId = tasksData?.data?.find((t) => t.id === id)
                  ?.meetingPlanId;
                if (meetingflowId) {
                  patchTask({ id, meetingflowId, ...patch });
                }
              }}
              disallowEdit
              disallowAdd
              showMeetingflow
              listMaxHeight={
                breakpoints.md
                  ? `calc(100vh - ${heightOffset} - 12.25rem)`
                  : undefined
              }
            />
          </div>
        </div>
      ) : (
        <>
          {tasksLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: '3rem',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <Text
              block
              style={{
                textAlign: 'center',
                fontSize: FontSizes.medium,
                fontWeight: FontWeights.semibold,
                color: MEETINGFLOW_COLORS.teal,
                padding: '1rem',
              }}
            >
              You're all caught up! 🎉
            </Text>
          )}
        </>
      )}
    </>
  );
};
