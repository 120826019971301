export const getUrlFileType = (url: string) => {
  try {
    const u = new URL(url);
    const ext = u.pathname.split('.').pop();
    return ext === '/' ? undefined : ext?.toLowerCase();
  } catch (_e: unknown) {
    return undefined;
  }
};

export const absoluteUrl = (urlString: string) => {
  try {
    return new URL(urlString);
  } catch (_e) {
    return new URL(urlString, document.baseURI);
  }
};
export const isUrl = (maybeUrl: string) => {
  try {
    new URL(maybeUrl);
    return true;
  } catch (_e: unknown) {
    return false;
  }
};

export const isExternalURL = (urlString: string) => {
  try {
    const url = new URL(urlString);

    // DOES THIS URL ORIGINATE FROM THIS WEBSITE?
    if (url.origin !== new URL(document.URL, document.baseURI).origin) {
      return true;
    }
  } catch (_e) {
    new URL(urlString, document.baseURI);
  }

  return false;
};

export const isMeetingflowURL = (maybeUrl: string) =>
  isUrl(maybeUrl) &&
  ['app.meetingflow.com', 'localhost'].includes(new URL(maybeUrl).hostname);

export const isExternalImageUrl = (maybeUrl: string) =>
  isUrl(maybeUrl) &&
  !['app.meetingflow.com', 'localhost'].includes(new URL(maybeUrl).hostname) &&
  ['png', 'jpg', 'jpeg', 'svg', 'gif', 'bmp', 'webp', 'tiff'].includes(
    getUrlFileType(maybeUrl) || '',
  );

export const canAccessUrl = async (maybeUrl: string): Promise<boolean> => {
  if (!isUrl(maybeUrl)) {
    return false;
  }

  try {
    const response = await fetch(maybeUrl, { method: 'GET' });
    return response.ok;
  } catch (_e: unknown) {
    return false;
  }
};

export const externalize = (urlString: string) => {
  try {
    new URL(urlString);
    return urlString;
  } catch (e: unknown) {
    if (urlString.startsWith(`/`)) {
      return window.origin + urlString;
    }

    return `http://${urlString}`;
  }
};

export type PageMeta = {
  type?: string;
  siteName?: string;
  locale?: string;
  title?: string;
  description?: string;
  image?: string;
  imageAlt?: string;
  url?: string;
};

export const getPageMeta = async (
  token: string,
  urlString: string,
): Promise<PageMeta> => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);
  const response = await fetch(
    `/api/proxy?url=${encodeURIComponent(externalize(urlString))}`,
    { method: 'GET', headers },
  );
  if (!response.ok) {
    throw new Error(`Failed to proxy request`);
  }
  const html = await response.text();
  const page = new DOMParser().parseFromString(html, 'text/html');

  const type =
    page.querySelector("meta[property='og:type']")?.getAttribute('content') ||
    undefined;
  const locale =
    page.querySelector("meta[property='og:locale']")?.getAttribute('content') ||
    undefined;
  const siteName =
    page
      .querySelector("meta[property='og:site_name']")
      ?.getAttribute('content') ||
    page
      .querySelector("meta[name='application-name']")
      ?.getAttribute('content') ||
    undefined;
  const title =
    page.querySelector("meta[property='og:title']")?.getAttribute('content') ||
    page.querySelectorAll('title')[0]?.innerText;
  const description =
    page
      .querySelector("meta[property='og:description']")
      ?.getAttribute('content') ||
    page.querySelector("meta[name='description']")?.getAttribute('content') ||
    undefined;
  const image =
    page.querySelector("meta[property='og:image']")?.getAttribute('content') ||
    undefined;
  const imageAlt =
    page
      .querySelector("meta[property='og:image:alt']")
      ?.getAttribute('content') || undefined;
  const url =
    page.querySelector("meta[property='og:url']")?.getAttribute('content') ||
    undefined;

  return {
    type,
    siteName,
    locale,
    title,
    description,
    image,
    imageAlt,
    url,
  };
};

export const isUrlReachable = (
  url: string,
  timeout = 6000,
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (!isUrl(url)) resolve(false);
    try {
      fetch(url)
        .then(() => resolve(true))
        .catch(() => resolve(false));
      setTimeout(() => {
        resolve(false);
      }, timeout);
    } catch (e) {
      reject(e);
    }
  });
};
