import { useAuth0 } from '@auth0/auth0-react';
import { OrganizationDomainRulesQuery } from '../../../QueryNames';
import { OrganizationsApiClient } from '../../../Services/NetworkCommon';
import { useQuery } from 'react-query';
import { StyledSpinner } from '../../StyledSpinner';
import { useOrganization } from '../../../Hooks/useOrganization';
import {
  IColumn,
  Dropdown,
  SelectionMode,
  Checkbox,
  Text,
  ConstrainMode,
} from '@fluentui/react';
import { ROLE_DROPDOWN_OPTIONS_LIMITED } from '../../../Helpers/Organizations';
import toast from 'react-hot-toast';
import { AsyncIconButton, AsyncPrimaryButton } from '../../HOC/AsyncButton';
import { useDeferredPromise } from '../../../Hooks/useDeferredPromise';
import { NewDomainRuleDialog } from './NewDomainRuleDialog';
import { EMPTY_ARRAY } from '../../../Constants';
import { useMemo } from 'react';
import PublicEmailDomains from '@meetingflow/common/PublicEmailDomains';
import { OrganizationDomainRuleType } from '@meetingflow/common/Api/data-contracts';
import { SettingsStyledDetailsList } from './SettingsStyledDetailList';
import { settingsPrimaryButtonClass } from './SettingsPageLayout';

export type OrganizationDomainProps = {
  organizationSlug: string;
};
export const OrganizationDomains = ({
  organizationSlug,
}: OrganizationDomainProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const domains = PublicEmailDomains;
  const { isAdmin } = useOrganization(organizationSlug);

  const {
    createDeferred: deferDomainRule,
    resolve: domainRuleResolve,
    reject: domainRuleReject,
    deferred: domainRuleDeferred,
  } = useDeferredPromise<{
    domain: string;
    ruleType: OrganizationDomainRuleType;
    roleType?: 'CREATOR' | 'COLLABORATOR' | null;
  }>();

  const {
    data: domainRulesData,
    isLoading: domainRulesLoading,
    refetch: refetchDomainRules,
  } = useQuery(OrganizationDomainRulesQuery(organizationSlug!), async () => {
    const token = await getAccessTokenSilently();
    return OrganizationsApiClient.listDomainRules(
      { organizationSlug },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  const ruleColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'domain',
        name: 'Domain',
        minWidth: 180,
        maxWidth: 180,
        fieldName: 'domain',
        onRender: (rule, column) => <Text>{rule.domain}</Text>,
      },
      {
        key: 'request',
        name: 'Can Request Access',
        minWidth: 120,
        maxWidth: 120,
        fieldName: '',
        className: `center-align`,
        headerClassName: `center-align`,
        onRender: (rule, _column) => (
          <div
            style={{
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Checkbox
              styles={{
                root: {
                  display: 'inline-block',
                },
              }}
              title={
                domains && domains.includes(rule.domain)
                  ? `Workspace discoverability is disabled for ${rule.domain} for privacy reasons.`
                  : `Let anyone with a ${rule.domain} email
          address request access to this workspace. `
              }
              disabled={!isAdmin || (domains && domains.includes(rule.domain))}
              checked={
                rule.ruleType === 'CAN_REQUEST_ACCESS' ||
                rule.ruleType === 'AUTO_APPROVE'
              }
              onChange={async (_ev, checked) => {
                const token = await getAccessTokenSilently();
                const result = await OrganizationsApiClient.putDomainRule(
                  organizationSlug,
                  rule.domain,
                  checked
                    ? {
                        ruleType: 'CAN_REQUEST_ACCESS',
                        roleType: 'COLLABORATOR',
                      }
                    : {
                        ruleType: 'INTERNAL_DOMAIN',
                        roleType: null,
                      },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );
                if (result.status === 200) {
                  await refetchDomainRules();
                }
              }}
            />
          </div>
        ),
      },
      {
        key: 'role',
        name: 'New Member Default Role',
        minWidth: 330,
        maxWidth: 330,
        fieldName: 'role',
        onRender: (rule, _column) => {
          if (rule.ruleType === 'INTERNAL_DOMAIN') {
            return null;
          }
          return (
            <Dropdown
              options={ROLE_DROPDOWN_OPTIONS_LIMITED}
              selectedKey={rule?.roleType}
              title={
                domains && domains.includes(rule.domain)
                  ? `Workspace discoverability is disabled for ${rule.domain} for privacy reasons.`
                  : `Automatically approve requests to join from users with a ${rule.domain} email address with the selected role.`
              }
              disabled={!isAdmin || (domains && domains.includes(rule.domain))}
              onChange={async (_evt, option) => {
                if (!option) {
                  return;
                }
                const token = await getAccessTokenSilently();
                const result = await OrganizationsApiClient.putDomainRule(
                  organizationSlug,
                  rule.domain,
                  {
                    ruleType: rule.ruleType as
                      | 'AUTO_APPROVE'
                      | 'CAN_REQUEST_ACCESS',
                    roleType: option.key as 'CREATOR' | 'COLLABORATOR',
                  },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );
                if (result.status === 200) {
                  await refetchDomainRules();
                }
              }}
            />
          );
        },
      },
      {
        key: 'auto approve',
        name: 'Auto approve',
        minWidth: 100,
        maxWidth: 100,
        fieldName: '',
        className: `center-align`,
        headerClassName: `center-align`,
        onRender: (rule, _column) => (
          <div
            style={{
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Checkbox
              styles={{
                root: {
                  display: 'inline-block',
                },
              }}
              title={
                domains && domains.includes(rule.domain)
                  ? `Workspace discoverability is disabled for ${rule.domain} for privacy reasons.`
                  : `Automatically approve requests to join from users with a ${rule.domain} email address.`
              }
              disabled={
                !isAdmin ||
                (domains && domains.includes(rule.domain)) ||
                rule.ruleType === 'INTERNAL_DOMAIN'
              }
              checked={rule.ruleType === 'AUTO_APPROVE'}
              onChange={async (_ev, checked) => {
                const token = await getAccessTokenSilently();
                const result = await OrganizationsApiClient.putDomainRule(
                  organizationSlug,
                  rule.domain,
                  {
                    ruleType: checked ? 'AUTO_APPROVE' : 'CAN_REQUEST_ACCESS',
                    roleType: rule.roleType as 'CREATOR' | 'COLLABORATOR',
                  },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );
                if (result.status === 200) {
                  await refetchDomainRules();
                }
              }}
            />
          </div>
        ),
      },
      {
        key: 'delete',
        name: 'Delete',
        minWidth: 48,
        maxWidth: 48,
        onRender: (rule, _column) => (
          <AsyncIconButton
            iconProps={{ iconName: 'Delete' }}
            disabled={!isAdmin}
            onClick={async () => {
              const token = await getAccessTokenSilently();
              const result = await OrganizationsApiClient.deleteDomainRule(
                organizationSlug,
                rule.domain,
                { headers: { Authorization: `Bearer ${token}` } },
              );
              if (result.status === 204) {
                await refetchDomainRules();
                toast.success(`Domain removed.`);
              }
            }}
          />
        ),
      },
    ],
    [
      domains,
      getAccessTokenSilently,
      isAdmin,
      organizationSlug,
      refetchDomainRules,
    ],
  );

  if (domainRulesLoading) {
    return <StyledSpinner />;
  }

  return (
    <>
      <div>
        <SettingsStyledDetailsList
          items={domainRulesData?.data || EMPTY_ARRAY}
          columns={ruleColumns}
          selectionMode={SelectionMode.none}
          constrainMode={ConstrainMode.unconstrained}
        />
      </div>
      {domainRuleDeferred?.isPending ? (
        <NewDomainRuleDialog
          existingDomains={
            domainRulesData?.data.map((rule) => rule.domain) || []
          }
          hidden={!domainRuleDeferred || !domainRuleDeferred.isPending}
          onCreate={domainRuleResolve}
          onCancel={domainRuleReject}
        />
      ) : null}

      <div>
        <AsyncPrimaryButton
          className={settingsPrimaryButtonClass}
          onClick={async () => {
            try {
              const domainRule = await deferDomainRule().promise;
              const token = await getAccessTokenSilently();
              await toast.promise(
                OrganizationsApiClient.putDomainRule(
                  organizationSlug,
                  domainRule.domain,
                  domainRule.ruleType === 'INTERNAL_DOMAIN' ||
                    !domainRule.roleType
                    ? {
                        ruleType: 'INTERNAL_DOMAIN',
                        roleType: null,
                      }
                    : {
                        ruleType: domainRule.ruleType,
                        roleType: domainRule.roleType,
                      },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                ),
                {
                  loading: 'Creating domain rule',
                  success: (_result) => {
                    refetchDomainRules();
                    return 'Successfully created domain rule';
                  },
                  error: (_err: unknown) => {
                    return 'Something went wrong creating the new domain rule, please try again later';
                  },
                },
              );
            } catch (err: unknown) {}
          }}
        >
          Add Domain
        </AsyncPrimaryButton>
      </div>
    </>
  );
};
