import { useAuth0 } from '@auth0/auth0-react';
import {
  Dropdown,
  NeutralColors,
  PrimaryButton,
  SelectionMode,
  Spinner,
  Stack,
  TextField,
  useTheme,
  Text,
  FontSizes,
  FontWeights,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Contact,
  ContactSuggestion,
  OrganizationInvite,
  OrganizationMemberRole,
} from '@meetingflow/common/Api/data-contracts';
import { OmitValues } from '@meetingflow/common/ObjectHelpers';
import { getDomain } from '@meetingflow/common/StringHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import * as yup from 'yup';
import { GOOGLE_CAL_DOMAINS } from '../../Constants';
import { DEFAULT_STACK_TOKENS } from '../../Helpers/Layout';
import {
  ROLE_DROPDOWN_OPTIONS_LIMITED,
  ROLE_DROPDOWN_OPTIONS_NO_GUEST,
} from '../../Helpers/Organizations';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../Hooks/useOrganization';
import { useTitle } from '../../Hooks/useTitle';
import {
  InvitesQuery,
  OrganizationInvitesQuery,
  OrganizationMemberSuggestionsQuery,
  OrganizationMembersQuery,
} from '../../QueryNames';
import {
  InvitesApiClient,
  MembersApiClient,
} from '../../Services/NetworkCommon';
import { OrganizationSlugRouteParams } from '../../types/RouteParams';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { on } from 'events';

export type OrganizationInviteMemberProps = {
  onInvite?: (invite: OrganizationInvite) => void;
};

type InviteMemberFormData = {
  name?: string;
  email: string;
  role: OrganizationMemberRole;
};

export const OrganizationInviteMember = ({
  onInvite,
}: OrganizationInviteMemberProps) => {
  const { organizationSlug } = useParams<OrganizationSlugRouteParams>();
  const { getAccessTokenSilently } = useAuth0();
  const appInsights = useAppInsightsContext();
  const isDark = useLightOrDarkMode();
  const client = useQueryClient();

  const {
    organization,
    role,
    isLoading,
    domainRules,
    isAdmin,
    internalDomains,
  } = useOrganization(organizationSlug);

  useTitle('Invite Members');

  const { data: suggestedMembers, refetch: refetchSuggestedMembers } = useQuery(
    OrganizationMemberSuggestionsQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return MembersApiClient.listMemberSuggestions(
        { organizationSlug: organizationSlug! },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!organizationSlug },
  );

  const formSchema = useMemo(
    () =>
      yup
        .object({
          email: yup
            .string()
            .email('The specified email is invalid')
            .required()
            .test(
              'Is Admin or Internal Domain Email',
              `You must enter an email address from a domain that is internal to your workspace (${internalDomains
                .filter((d) => !GOOGLE_CAL_DOMAINS.includes(d))
                .join(
                  ', ',
                )}). Contact an admin to invite a user from an external domain`,
              (v) =>
                isAdmin ||
                (!!v && internalDomains.includes(getDomain(v) || '')),
            ),
          name: yup.string().optional(),
          role: yup
            .string()
            .oneOf(
              ['ADMIN', 'COLLABORATOR', 'CREATOR', 'GUEST'],
              'Invalid role',
            )
            .required(),
        })
        .required(),
    [internalDomains, isAdmin],
  );

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isValid, errors },
  } = useForm<InviteMemberFormData>({
    defaultValues: {
      name: '',
      email: '',
      role: isAdmin ? 'CREATOR' : 'COLLABORATOR',
    },
    resolver: yupResolver(formSchema, { abortEarly: false }),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (data) => {
    if (isValid) {
      const token = await getAccessTokenSilently();
      const result = await InvitesApiClient.putInvite(
        {
          organizationSlug: organizationSlug!,
          name: data.name,
          email: data.email,
          role: data.role,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (result.status === 201) {
        client.invalidateQueries(InvitesQuery());
        client.invalidateQueries(InvitesQuery('SENT'));
        client.invalidateQueries(OrganizationMembersQuery(organizationSlug!));
        client.invalidateQueries(OrganizationInvitesQuery(organizationSlug!));
        refetchSuggestedMembers();
        reset();
        toast.success(
          `${data.name || data.email} has been invited to ${
            organization?.name || organizationSlug
          }. Feel free to invite someone else!`,
        );
        appInsights.trackEvent({
          name: 'SEND_INVITE',
          properties: {
            organizationSlug,
            email: data.email,
            role: data.role,
          },
        });
        if (onInvite) {
          onInvite(result.data);
        }
      } else if (result.status === 200) {
        client.invalidateQueries(InvitesQuery());
        client.invalidateQueries(InvitesQuery('SENT'));
        refetchSuggestedMembers();
        reset();
        toast.success(
          `${data.name || data.email} was sent an invitation reminder`,
        );
      }
    }
  });

  const onActiveItemChanged = useCallback(
    (item: Contact | ContactSuggestion) => {
      if (!!item) {
        if (item.name) {
          setValue('name', item.name, {
            shouldValidate: true,
          });
        }
        setValue('email', item.email, {
          shouldValidate: true,
        });
      }
    },
    [setValue],
  );

  const getInviteAsRoleForEmailAddress = useCallback(
    (emailAddress: string) => {
      const domainRule = domainRules?.find(
        (dr) => dr.domain === emailAddress.split('@')[1]?.toLowerCase(),
      );
      if (domainRule?.ruleType === 'AUTO_APPROVE') {
        return domainRule.roleType || 'GUEST';
      }
      return 'GUEST';
    },
    [domainRules],
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Stack tokens={DEFAULT_STACK_TOKENS} horizontal>
        <Stack.Item grow={1} styles={{ root: { maxWidth: '300px' } }}>
          <Controller
            name="email"
            control={control}
            render={({ field: { value, onBlur, onChange } }) => (
              <TextField
                label="Email"
                errorMessage={errors.email?.message}
                value={value}
                onChange={(_e, newValue) => {
                  setValue('name', '');
                  if (newValue) {
                    if (isValid) {
                      const newRole = getInviteAsRoleForEmailAddress(newValue);
                      setValue(
                        'role',
                        newRole === 'GUEST' ? 'COLLABORATOR' : newRole,
                      );
                    }
                  }
                  onChange(newValue || '');
                }}
                onBlur={(e) => {
                  onBlur?.();
                }}
              />
            )}
          />
        </Stack.Item>
        <Stack.Item grow={1} styles={{ root: { maxWidth: '350px' } }}>
          <Controller
            name="role"
            control={control}
            render={({ field }) => {
              return (
                <Dropdown
                  disabled={!isAdmin}
                  label="Role"
                  errorMessage={errors.role?.message}
                  options={
                    isAdmin
                      ? ROLE_DROPDOWN_OPTIONS_NO_GUEST
                      : ROLE_DROPDOWN_OPTIONS_LIMITED
                  }
                  selectedKey={field.value}
                  onChange={(_evt, option) => {
                    if (!option) {
                      return;
                    }
                    field.onChange(option.key as OrganizationMemberRole);
                  }}
                />
              );
            }}
          />
        </Stack.Item>
        <Stack.Item styles={{ root: { paddingTop: '1.4rem' } }}>
          <PrimaryButton
            disabled={!isValid && role !== 'GUEST'}
            text="Invite"
            onClick={onSubmit}
          />
        </Stack.Item>
      </Stack>

      <Text block style={{ fontSize: FontSizes.small, marginTop: '.5rem' }}>
        <strong style={{ fontWeight: FontWeights.semibold }}>
          Suggestions:{' '}
        </strong>
        {suggestedMembers?.data?.map((c, idx) => (
          <a
            key={c.email}
            style={{
              color: isDark
                ? MEETINGFLOW_COLORS.purpleSecondary
                : MEETINGFLOW_COLORS.purpleDark,
            }}
            onClick={() => onActiveItemChanged(c)}
          >
            {c.name ? `${c.name} (${c.email})` : c.email}
            {idx < suggestedMembers.data.length - 1 ? ', ' : ''}
          </a>
        ))}
      </Text>
    </>
  );
};

export default OrganizationInviteMember;
