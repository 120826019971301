import { useAuth0 } from '@auth0/auth0-react';
import {
  DomainRule,
  OrganizationSuggestion,
} from '@meetingflow/common/Api/data-contracts';
import toast from 'react-hot-toast';
import { RequestsApiClient } from '../Services/NetworkCommon';
import { AsyncDefaultButton, AsyncPrimaryButton } from './HOC/AsyncButton';

export type OrganizationSuggestionButtonProps = {
  urlSlug?: string;
  suggestion: Pick<
    OrganizationSuggestion,
    'id' | 'name' | 'slug' | 'domainRules' | 'pendingRequests'
  >;
  onJoinOrg: (slug: string) => Promise<unknown>;
  onRequestAccess: (slug: string) => Promise<unknown>;
  onCancelRequest: () => Promise<unknown>;
};
export const OrganizationSuggestionButton = ({
  suggestion,
  onJoinOrg,
  onRequestAccess,
  onCancelRequest,
}: OrganizationSuggestionButtonProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const joinOrg = async (domainRule: DomainRule) => {
    try {
      const token = await getAccessTokenSilently();
      await RequestsApiClient.postRequest(suggestion.slug, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (domainRule.ruleType === 'AUTO_APPROVE') {
        await onJoinOrg(suggestion.slug);
      }
      if (domainRule.ruleType === 'CAN_REQUEST_ACCESS') {
        await onRequestAccess(suggestion.slug);
      }
    } catch {
      toast.error(
        `Something went wrong requesting to join the workspace, please try again later`,
      );
    }
  };

  const cancelRequest = async (requestId: number) => {
    try {
      const token = await getAccessTokenSilently();
      await RequestsApiClient.deleteRequest(suggestion.slug, requestId, {
        headers: { Authorization: `Bearer ${token}` },
      });
      await onCancelRequest();
    } catch {
      toast.error(
        `Something went wrong cancelling request, please try again later`,
      );
    }
  };

  if (!!suggestion.pendingRequests.length) {
    return (
      <div>
        <AsyncDefaultButton
          onClick={() => cancelRequest(suggestion.pendingRequests[0].id)}
        >
          Cancel Request
        </AsyncDefaultButton>
      </div>
    );
  }

  if (!!suggestion.domainRules.length) {
    const domainRule = suggestion.domainRules[0];
    return (
      <AsyncPrimaryButton onClick={() => joinOrg(domainRule)}>
        {domainRule.ruleType === 'AUTO_APPROVE' ? 'Join' : 'Request Access'}
      </AsyncPrimaryButton>
    );
  }

  return null;
};
