import { useAuth0 } from '@auth0/auth0-react';
import { Stack, useTheme, Text, mergeStyles, IStyle } from '@fluentui/react';
import { FontSizes, FontWeights, NeutralColors } from '@fluentui/theme';
import { DEFAULT_STACK_TOKENS } from '../../../../Helpers/Layout';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { OrganizationCallRecordingUsageQuery } from '../../../../QueryNames';
import { ReportingApiClient } from '../../../../Services/NetworkCommon';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useUserProfile } from '../../../../Hooks/useProfile';
import {
  settingsIndentedControlClass,
  settingsRootStackStyles,
  settingsSectionDescriptionClass,
  settingsSectionDescriptionClassDark,
  settingsSubHeadClass,
} from '../SettingsPageLayout';
import { DateTime } from 'luxon';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { numbertoWords } from '../../../../Helpers/NumberHelpers';
import { titleCase } from '@meetingflow/common/StringHelpers';

export const CallRecordingUsageTabContent = () => {
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();
  const { slug: organizationSlug } = useOrganization();

  const { isDark } = useLightOrDarkMode();

  const {
    data: usageData,
    isLoading: useageDataLoading,
    refetch: refetchUsageDatax,
  } = useQuery(
    OrganizationCallRecordingUsageQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return ReportingApiClient.listCallRecordingUsage(
        {
          organizationSlug: organizationSlug!,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  const usageTableClass = mergeStyles({
    display: 'flex',
    flexDirection: 'column',

    '.row': {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: `1px solid ${
        isDark ? NeutralColors.gray180 : NeutralColors.gray20
      }`,
      position: 'relative',
    },

    '.header': {
      fontWeight: FontWeights.semibold,
      position: 'relative',

      '.superscript': {
        position: 'absolute',
        right: '-.125rem',
        top: '.5rem',
        fontSize: '0.5rem',
        verticalAlign: 'super',
        marginLeft: '.125rem',
      },
    },

    '.column': {
      width: '7rem',
      padding: '.5rem',
      textAlign: 'right',
    },
  });

  const valueHighlightClass = mergeStyles({
    color: MEETINGFLOW_COLORS.teal,
  });

  const currentMonthUsage = usageData?.data?.usage?.[0];
  const currentMonthFormattedMonth = currentMonthUsage
    ? DateTime.fromISO(currentMonthUsage?.date, { zone: 'utc' })
        .setZone('local', { keepLocalTime: true })
        .toFormat('LLLL')
    : '';
  const currentMonthformattedYear = currentMonthUsage
    ? DateTime.fromISO(currentMonthUsage?.date, { zone: 'utc' })
        .setZone('local', { keepLocalTime: true })
        .toFormat('yyyy')
    : '';
  const currentMonthFormattedHours =
    currentMonthUsage?.recordingHours.toFixed(2);

  return (
    <>
      <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
        {currentMonthUsage ? (
          <>
            <h2 className={settingsSubHeadClass}>
              {currentMonthFormattedMonth}, {currentMonthformattedYear}
            </h2>
            <div className={settingsIndentedControlClass}>
              <Text
                style={{
                  fontWeight: FontWeights.semibold,
                  fontSize: FontSizes.large,
                  lineHeight: '1.75rem',
                }}
                className={
                  isDark
                    ? mergeStyles(settingsSectionDescriptionClassDark as IStyle)
                    : mergeStyles(settingsSectionDescriptionClass as IStyle)
                }
              >
                <span className={valueHighlightClass}>
                  {titleCase(
                    numbertoWords(currentMonthUsage?.userCount) || 'zero',
                  )}
                </span>{' '}
                unique user{currentMonthUsage?.userCount === 1 ? '' : 's'} from
                your Workspace <br />
                {currentMonthUsage?.userCount === 1
                  ? 'has'
                  : 'have'} created{' '}
                <span className={valueHighlightClass}>
                  {numbertoWords(currentMonthUsage?.recordingCount)}
                </span>{' '}
                recording{currentMonthUsage?.recordingCount === 1 ? '' : 's'},
                totaling <br />
                <span className={valueHighlightClass}>
                  {currentMonthFormattedHours}
                </span>{' '}
                hours of recording time this month.
              </Text>
            </div>
          </>
        ) : null}

        <h2 className={settingsSubHeadClass}>Call Recording Usage History</h2>
        <div className={settingsIndentedControlClass}>
          <div className={usageTableClass}>
            <div className="row header">
              <div className="column header year">Year</div>
              <div className="column header month">Month</div>
              <div className="column header count">
                Recordings<span className="superscript">1</span>
              </div>
              <div className="column header hours">
                Hours <span className="superscript">1</span>
              </div>
              <div className="column header hours">
                Users <span className="superscript">2</span>
              </div>
            </div>

            {usageData?.data?.usage?.map((u) => {
              const formattedMonth = DateTime.fromISO(u.date, {
                zone: 'utc',
              })
                .setZone('local', { keepLocalTime: true })
                .toFormat('LLLL');
              const formattedYear = DateTime.fromISO(u.date, {
                zone: 'utc',
              })
                .setZone('local', { keepLocalTime: true })
                .toFormat('yyyy');
              const isCurrentMonth =
                DateTime.fromISO(u.date, { zone: 'utc' })
                  .setZone('local', { keepLocalTime: true })
                  .toFormat('yyyy-MM') === DateTime.now().toFormat('yyyy-MM');

              const formattedHours = u.recordingHours?.toFixed(2);
              return (
                <div
                  className={`row ${isCurrentMonth ? 'current' : ''}`}
                  key={`${formattedMonth}-${formattedYear}`}
                >
                  <div className="column year">{formattedYear}</div>
                  <div className="column month">{formattedMonth}</div>
                  <div className="column count">{u.recordingCount}</div>
                  <div className="column hours">{formattedHours}</div>
                  <div className="column users">{u.userCount}</div>
                </div>
              );
            })}
          </div>
        </div>

        <h2 className={settingsSubHeadClass}>Notes</h2>
        <div className={settingsIndentedControlClass}>
          <Text
            className={
              isDark
                ? mergeStyles(settingsSectionDescriptionClassDark as IStyle)
                : mergeStyles(settingsSectionDescriptionClass as IStyle)
            }
          >
            <ol
              style={{
                paddingLeft: '1rem',
              }}
            >
              <li>
                The number of recordings and number of hours recorded include
                any recordings that have since been deleted.
              </li>
              <li>
                The number of users represents unqiue users who used started or
                scheduled a call recording during the month.
              </li>
            </ol>
          </Text>
        </div>
      </Stack>
    </>
  );
};
