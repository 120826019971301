import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  Icon,
  IGroup,
  SelectionMode,
  Spinner,
  Text,
} from '@fluentui/react';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import * as Axios from 'axios';
import { sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SettingsStyledDetailsList } from '../../../Components/Organization/Settings/SettingsStyledDetailList';
import { useExternalServiceConfigurations } from '../../../Hooks/useExternalServiceConfigurations';
import {
  HubSpotProperty,
  HubSpotSchemaResponse,
} from '../../../Models/HubSpot/HubSpotSchema';
import { HubSpotObjectSchemaQuery } from '../../../QueryNames';
import { ApiClient } from '../../../Services/NetworkCommon';
import { HubSpotObjectType } from '../../../types/HubSpotObjectTypes';

export type ConfigureHubSpotObjectProps = {
  organizationSlug: string;
  configurationId: number;
  customFieldsetId: number | null;
  objectType: HubSpotObjectType;
  hideReadonly?: boolean;
  allowReadonly?: boolean;
  allowCreateable?: boolean;
  allowUpdateable?: boolean;
};

export const ConfigureHubSpotObject = ({
  organizationSlug,
  configurationId,
  customFieldsetId,
  objectType,
  hideReadonly,
  allowReadonly,
  allowCreateable,
  allowUpdateable,
}: ConfigureHubSpotObjectProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { loading: configurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'HUBSPOT', withToken: true });

  const {
    data: objectSchema,
    isLoading: objectSchemaLoading,
    refetch: refetchSchema,
    isRefetching: objectSchemaRefetching,
  } = useQuery(
    HubSpotObjectSchemaQuery(
      organizationSlug!,
      configurationId,
      objectType,
      customFieldsetId,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotSchemaResponse>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/schema/${objectType}`,
        {
          params: { fieldsetId: customFieldsetId },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!configurationById(configurationId),
    },
  );

  const selectedFields: string[] = useMemo(
    () => objectSchema?.data?.customFields?.map((f) => f.fieldName) ?? [],
    [objectSchema?.data?.customFields],
  );

  const queryClient = useQueryClient();

  const { mutate: updateCustomFields } = useMutation(
    async (fields: string[]) => {
      const token = await getAccessTokenSilently();
      const result = await ApiClient.put<HubSpotSchemaResponse>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/custom-fields/${objectType}`,
        { fieldsetId: customFieldsetId, fields },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return result.data; // Assuming the PUT request returns the updated data
    },
    {
      onMutate: async (newCustomFields) => {
        await queryClient.cancelQueries(
          HubSpotObjectSchemaQuery(
            organizationSlug,
            configurationId,
            objectType,
            customFieldsetId,
          ),
        );

        const previousResponse = queryClient.getQueryData<
          Axios.AxiosResponse<HubSpotSchemaResponse>
        >(
          HubSpotObjectSchemaQuery(
            organizationSlug,
            configurationId,
            objectType,
            customFieldsetId,
          ),
        );

        if (previousResponse) {
          queryClient.setQueryData<Axios.AxiosResponse<HubSpotSchemaResponse>>(
            HubSpotObjectSchemaQuery(
              organizationSlug,
              configurationId,
              objectType,
              customFieldsetId,
            ),
            (old) => ({
              ...old!,
              data: {
                ...old!.data,
                customFields: newCustomFields.map((fieldName, idx) => ({
                  fieldName,
                  displayOrder: idx,
                })),
              },
            }),
          );
        }

        return { previousResponse };
      },
      onError: (err, newCustomFields, context) => {
        toast.error(
          'Something went wrong updating the configured custom fields, try again',
        );
        if (context?.previousResponse) {
          queryClient.setQueryData(
            HubSpotObjectSchemaQuery(
              organizationSlug,
              configurationId,
              objectType,
              customFieldsetId,
            ),
            context.previousResponse,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          HubSpotObjectSchemaQuery(
            organizationSlug,
            configurationId,
            objectType,
            customFieldsetId,
          ),
        );
      },
    },
  );

  const [items, groups] = useMemo(() => {
    const filteredFields =
      objectSchema?.data?.hubspotSchema?.properties
        ?.filter((property) => !['id', 'reference'].includes(property.type))
        ?.filter((property) => !property.hidden)
        ?.filter((property) =>
          hideReadonly
            ? !property.calculated &&
              !property.modificationMetadata.readOnlyValue
            : true,
        ) || [];

    const selectedHubSpotProperties = (objectSchema?.data?.customFields ?? [])
      .map((f) => filteredFields.find((field) => f.fieldName === field.name))
      .filter(Truthy);

    const availableFields = sortBy(
      filteredFields.filter(
        (field) =>
          !selectedHubSpotProperties.some((sf) => sf.name === field.name),
      ),
      (field) => field.label,
    );

    const g: IGroup[] = [
      {
        key: 'selected',
        name: 'Selected Fields',
        startIndex: 0,
        count: selectedHubSpotProperties.length,
      },
      {
        key: 'available',
        name: 'Available Fields',
        startIndex: selectedHubSpotProperties.length,
        count: availableFields.length,
      },
    ];

    const i = [
      ...selectedHubSpotProperties.map((f) => ({
        ...f,
        id: f.name,
        supportDrag: true,
        supportDrop: true,
      })),
      ...availableFields,
    ];
    return [i, g];
  }, [
    hideReadonly,
    objectSchema?.data?.customFields,
    objectSchema?.data?.hubspotSchema?.properties,
  ]);

  const columns = useMemo(
    () => [
      {
        key: 'selected',
        name: 'Select',
        minWidth: 50,
        maxWidth: 50,
        onRender: (field: HubSpotProperty) => {
          const readonly = field.calculated;
          const allowField =
            (allowReadonly && readonly) ||
            (allowCreateable &&
              !field.calculated &&
              !field.modificationMetadata.readOnlyValue) ||
            (allowUpdateable &&
              !field.calculated &&
              !field.modificationMetadata.readOnlyValue);

          let title: string | undefined;

          return (
            <Checkbox
              key={field.name}
              title={title}
              styles={{
                root: {
                  marginLeft: '2rem',
                  width: '2rem',
                },
              }}
              disabled={!allowField}
              checked={selectedFields?.some((f) => f === field.name)}
              onChange={(_e, checked) => {
                const updatedFields = checked
                  ? DeduplicateArray([...selectedFields, field.name])
                  : selectedFields.filter((v) => v !== field.name);
                updateCustomFields(updatedFields);
              }}
            />
          );
        },
      },
      {
        key: 'name',
        name: 'Name',
        minWidth: 80,
        maxWidth: 250,
        fieldName: 'label',
        onRender: (item: HubSpotProperty) => (
          <Text style={{ cursor: 'default' }}>{item.label}</Text>
        ),
      },
      {
        key: 'type',
        name: 'Type',
        minWidth: 100,
        maxWidth: 100,
        fieldName: 'type',
        onRender: (item: HubSpotProperty) => (
          <Text style={{ cursor: 'default' }}>{item.type}</Text>
        ),
      },
      {
        key: 'custom',
        name: 'Custom Field',
        minWidth: 100,
        maxWidth: 100,
        fieldName: 'custom',
        onRender: (item: HubSpotProperty) =>
          !item.hubspotDefined ? (
            <Text style={{ cursor: 'default' }}>
              <Icon iconName="SkypeCheck" style={{ color: 'green' }} />
            </Text>
          ) : null,
      },
      {
        key: 'readonly',
        name: 'Read Only',
        minWidth: 100,
        maxWidth: 100,
        fieldName: '',
        onRender: (item: HubSpotProperty) => {
          return item.calculated || item.modificationMetadata.readOnlyValue ? (
            <Text style={{ cursor: 'default' }}>
              <Icon iconName="SkypeCheck" style={{ color: 'green' }} />
            </Text>
          ) : null;
        },
      },
    ],
    // Recreate the columns when updateCount changes to trigger a rerendering of the StyledDetailsList
    [
      allowCreateable,
      allowReadonly,
      allowUpdateable,
      selectedFields,
      updateCustomFields,
    ],
  );

  const onItemDrop = useCallback(
    (dragStartKey: string, dragStopKey: string) => {
      // Find the indexes of the source and target
      const sourceIndex = selectedFields.findIndex((v) => v === dragStartKey);
      const targetIndex = selectedFields.findIndex((v) => v === dragStopKey);
      console.info({ sourceIndex, targetIndex });

      // If either source or target isn't found, or if they are the same, don't do anything
      if (
        sourceIndex === -1 ||
        targetIndex === -1 ||
        sourceIndex === targetIndex
      ) {
        console.warn(
          `${dragStartKey} or ${dragStopKey} not found in fields ${JSON.stringify(
            selectedFields,
          )}`,
        );
        return selectedFields;
      }

      const newSelectedFields = selectedFields.filter(
        (v) => v !== dragStartKey,
      );

      const newPosition = Math.max(
        newSelectedFields.findIndex((v) => v === dragStopKey),
        0,
      );

      newSelectedFields.splice(newPosition, 0, dragStartKey);

      // Return the re-ordered array
      updateCustomFields(newSelectedFields);
    },
    [selectedFields, updateCustomFields],
  );

  if (
    configurationsLoading ||
    (objectSchemaLoading && !objectSchemaRefetching)
  ) {
    return <Spinner />;
  }

  return (
    <SettingsStyledDetailsList
      key={objectType}
      selectionMode={SelectionMode.none}
      groups={groups}
      items={items}
      getKey={(item: HubSpotProperty) => item.name}
      onItemDrop={onItemDrop}
      columns={columns}
      maxRowHeight={'none'}
    />
  );
};
