import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  IGroup,
  Icon,
  SelectionMode,
  Spinner,
  Text,
} from '@fluentui/react';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import * as Axios from 'axios';
import { sortBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SettingsStyledDetailsList } from '../../../Components/Organization/Settings/SettingsStyledDetailList';
import { useExternalServiceConfigurations } from '../../../Hooks/useExternalServiceConfigurations';
import { useOrganization } from '../../../Hooks/useOrganization';
import {
  SalesforceField,
  SalesforceSchemaResponse,
} from '../../../Models/Salesforce/SalesforceObjectSchema';
import { SalesforceSObjectSchemaQuery } from '../../../QueryNames';
import { ApiClient } from '../../../Services/NetworkCommon';
import { SalesforceObjectType } from '../../../types/SalesforceObjectTypes';
import { Truthy } from '@meetingflow/common/TypeHelpers';

export type ConfigureSalesforceObjectProps = {
  organizationSlug: string;
  configurationId: number;
  customFieldsetId: number | null;
  sObjectType: SalesforceObjectType;
  hideReadonly?: boolean;
  allowReadonly?: boolean;
  allowCreateable?: boolean;
  allowUpdateable?: boolean;
};

export const ConfigureSalesforceObject = ({
  organizationSlug,
  configurationId,
  sObjectType,
  customFieldsetId,
  hideReadonly,
  allowReadonly,
  allowCreateable,
  allowUpdateable,
}: ConfigureSalesforceObjectProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { loading: configurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });
  const { hasEntitlement } = useOrganization();

  const [useDefaultOrder, setUseDefaultOrder] = useState<boolean>(true);

  const {
    data: objectSchema,
    isLoading: objectSchemaLoading,
    refetch: refetchSchema,
    isRefetching: objectSchemaRefetching,
  } = useQuery(
    SalesforceSObjectSchemaQuery(
      organizationSlug!,
      configurationId,
      sObjectType,
      customFieldsetId,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceSchemaResponse>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${configurationId}/schema/${sObjectType}`,
        {
          params: { fieldsetId: customFieldsetId },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!configurationById(configurationId),
      onSuccess: (data) => {
        setUseDefaultOrder(
          data.data.customFields.every((f) => f.displayOrder === null),
        );
      },
    },
  );

  const selectedFields: string[] = useMemo(
    () => objectSchema?.data?.customFields?.map((f) => f.fieldName) ?? [],
    [objectSchema?.data?.customFields],
  );

  const queryClient = useQueryClient();

  const { mutate: updateCustomFields } = useMutation(
    async ({
      fields,
      forceDefaultOrder,
    }: {
      fields: string[];
      forceDefaultOrder?: boolean;
    }) => {
      const token = await getAccessTokenSilently();
      const result = await ApiClient.put<SalesforceSchemaResponse>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${configurationId}/custom-fields/${sObjectType}`,
        {
          fieldsetId: customFieldsetId,
          fields,
          defaultOrder:
            forceDefaultOrder !== undefined
              ? forceDefaultOrder
              : useDefaultOrder,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return result.data;
    },
    {
      onMutate: async ({ fields, forceDefaultOrder }) => {
        await queryClient.cancelQueries(
          SalesforceSObjectSchemaQuery(
            organizationSlug!,
            configurationId,
            sObjectType,
            customFieldsetId,
          ),
        );

        const previousResponse = queryClient.getQueryData<
          Axios.AxiosResponse<SalesforceSchemaResponse>
        >(
          SalesforceSObjectSchemaQuery(
            organizationSlug!,
            configurationId,
            sObjectType,
            customFieldsetId,
          ),
        );

        if (previousResponse) {
          queryClient.setQueryData<
            Axios.AxiosResponse<SalesforceSchemaResponse>
          >(
            SalesforceSObjectSchemaQuery(
              organizationSlug!,
              configurationId,
              sObjectType,
              customFieldsetId,
            ),
            (old) => ({
              ...old!,
              data: {
                ...old!.data,
                customFields: fields.map((fieldName, idx) => ({
                  fieldName,
                  displayOrder:
                    forceDefaultOrder !== undefined
                      ? forceDefaultOrder
                        ? null
                        : idx
                      : useDefaultOrder
                        ? null
                        : idx,
                })),
              },
            }),
          );
        }

        return { previousResponse };
      },
      onError: (err, newCustomFields, context) => {
        toast.error(
          'Something went wrong updating the configured custom fields, try again',
        );
        if (context?.previousResponse) {
          queryClient.setQueryData(
            SalesforceSObjectSchemaQuery(
              organizationSlug!,
              configurationId,
              sObjectType,
              customFieldsetId,
            ),
            context.previousResponse,
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          SalesforceSObjectSchemaQuery(
            organizationSlug!,
            configurationId,
            sObjectType,
            customFieldsetId,
          ),
        );
      },
    },
  );

  const [items, groups] = useMemo(() => {
    const filteredFields =
      objectSchema?.data?.salesforceSchema?.fields
        ?.filter((field) => {
          switch (field.type) {
            case 'id': {
              return false;
            }
            case 'reference': {
              return (
                field.referenceTo?.length === 1 &&
                (['Account', 'Opportunity', 'Contact'].includes(
                  field.referenceTo[0],
                ) ||
                  (hasEntitlement('SALESFORCE_LEADS') &&
                    field.referenceTo[0] === 'Lead'))
              );
            }
            default: {
              return true;
            }
          }
        })
        .filter((field) =>
          hideReadonly ? field.createable || field.updateable : true,
        ) || [];

    const selectedFields = (objectSchema?.data?.customFields ?? [])
      .map((f) => filteredFields.find((field) => f.fieldName === field.name))
      .filter(Truthy);

    const unselectedFields = sortBy(
      filteredFields.filter(
        (field) => !selectedFields.some((sf) => sf.name === field.name),
      ),
      (field) => field.label,
    );

    const g: IGroup[] = [
      {
        key: 'selected',
        name: 'Selected Fields',
        startIndex: 0,
        count: selectedFields.length,
      },
      {
        key: 'available',
        name: 'Available Fields',
        startIndex: selectedFields.length,
        count: unselectedFields.length,
      },
    ];

    const i = [
      ...selectedFields.map((f) => ({
        ...f,
        id: f.name,
        supportDrag: !useDefaultOrder,
        supportDrop: !useDefaultOrder,
      })),
      ...unselectedFields,
    ];
    return [i, g];
  }, [
    hasEntitlement,
    hideReadonly,
    objectSchema?.data?.customFields,
    objectSchema?.data?.salesforceSchema?.fields,
    useDefaultOrder,
  ]);

  const columns = useMemo(
    () => [
      {
        key: 'selected',
        name: 'Select',
        minWidth: 50,
        maxWidth: 50,
        onRender: (field: SalesforceField) => {
          const readonly = field.calculated || !field.updateable;
          const allowField =
            (allowReadonly && readonly) ||
            (allowCreateable && field.createable) ||
            (allowUpdateable && field.updateable);
          let title: string | undefined;
          if (!!objectSchema?.data?.disallowedFields?.includes(field.name)) {
            title = 'This field is not supported';
          }
          return (
            <Checkbox
              key={field.name}
              title={title}
              styles={{
                root: {
                  marginLeft: '2rem',
                  width: '2rem',
                },
              }}
              disabled={
                !!objectSchema?.data?.disallowedFields?.includes(field.name) ||
                !allowField
              }
              checked={selectedFields.includes(field.name)}
              onChange={(_e, checked) => {
                const updatedFields = checked
                  ? DeduplicateArray([...selectedFields, field.name])
                  : selectedFields.filter((v) => v !== field.name);
                updateCustomFields({ fields: updatedFields });
              }}
            />
          );
        },
      },
      {
        key: 'name',
        name: 'Name',
        minWidth: 80,
        maxWidth: 250,
        fieldName: 'label',
        onRender: (item: SalesforceField) => (
          <Text style={{ cursor: 'default' }}>{item.label}</Text>
        ),
      },
      {
        key: 'type',
        name: 'Type',
        minWidth: 100,
        maxWidth: 100,
        fieldName: 'type',
        onRender: (item: SalesforceField) => {
          switch (item.type) {
            case 'reference': {
              return (
                <Text style={{ cursor: 'default' }}>
                  `Lookup(${item.referenceTo![0]})`
                </Text>
              );
            }
            default: {
              return <Text style={{ cursor: 'default' }}>{item.type}</Text>;
            }
          }
        },
      },
      {
        key: 'custom',
        name: 'Custom Field',
        minWidth: 100,
        maxWidth: 100,
        fieldName: 'custom',
        onRender: (item: SalesforceField) =>
          item.custom ? (
            <Text style={{ cursor: 'default' }}>
              <Icon iconName="SkypeCheck" style={{ color: 'green' }} />
            </Text>
          ) : null,
      },
      {
        key: 'readonly',
        name: 'Read Only',
        minWidth: 100,
        maxWidth: 100,
        fieldName: '',
        onRender: (item: SalesforceField) => {
          return item.calculated || (!item.createable && !item.updateable) ? (
            <Text style={{ cursor: 'default' }}>
              <Icon iconName="SkypeCheck" style={{ color: 'green' }} />
            </Text>
          ) : null;
        },
      },
    ],
    [
      allowCreateable,
      allowReadonly,
      allowUpdateable,
      objectSchema?.data?.disallowedFields,
      selectedFields,
      updateCustomFields,
    ],
  );

  const onItemDrop = useCallback(
    (dragStartKey: string, dragStopKey: string) => {
      // Find the indexes of the source and target
      const sourceIndex = selectedFields.findIndex((v) => v === dragStartKey);
      const targetIndex = selectedFields.findIndex((v) => v === dragStopKey);
      console.info({ sourceIndex, targetIndex });

      // If either source or target isn't found, or if they are the same, don't do anything
      if (
        sourceIndex === -1 ||
        targetIndex === -1 ||
        sourceIndex === targetIndex
      ) {
        console.warn(
          `${dragStartKey} or ${dragStopKey} not found in fields ${JSON.stringify(
            selectedFields,
          )}`,
        );
        return selectedFields;
      }

      const newSelectedFields = selectedFields.filter(
        (v) => v !== dragStartKey,
      );

      const newPosition = Math.max(
        newSelectedFields.findIndex((v) => v === dragStopKey),
        0,
      );

      newSelectedFields.splice(newPosition, 0, dragStartKey);

      // Return the re-ordered array
      updateCustomFields({ fields: newSelectedFields });
    },
    [selectedFields, updateCustomFields],
  );

  if (
    configurationsLoading ||
    (objectSchemaLoading && !objectSchemaRefetching)
  ) {
    return <Spinner />;
  }

  return (
    <>
      <div>
        <Checkbox
          checked={useDefaultOrder}
          onChange={(_e, checked) => {
            setUseDefaultOrder(!!checked);
            updateCustomFields({
              fields: selectedFields,
              forceDefaultOrder: !!checked,
            });
          }}
          title="Use the default Salesforce field order"
          label="Use the default Salesforce field order"
        />
      </div>
      <SettingsStyledDetailsList
        key={sObjectType}
        selectionMode={SelectionMode.none}
        groups={groups}
        items={items}
        getKey={(item: SalesforceField) => item.name}
        onItemDrop={onItemDrop}
        columns={columns}
        maxRowHeight={'none'}
      />
    </>
  );
};
