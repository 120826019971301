export const RiskPhrases = [
  'tough sell',
  'additional clarification needed',
  'additional negotiation needed',
  'adoption concerns',
  'approval delays',
  'awaiting approval',
  'awaiting clarification',
  'awaiting decision',
  'awaiting feedback',
  'awaiting feedback',
  'awaiting final decision',
  'awaiting final sign-off',
  'awaiting further information',
  'awaiting internal sign-off',
  'at risk',
  'barrier to entry',
  'blocked by',
  'blocked on',
  'bottleneck in process',
  'budget constraints',
  'budget cuts',
  'budget limitations',
  'buy-in challenges',
  'challenging implementation',
  'challenging stakeholders',
  'challenging',
  'change in leadership',
  'change in market conditions',
  'change in requirements',
  'change of heart',
  'change of management',
  'changing requirements',
  'client indecisive',
  'client is unresponsive',
  'client needs more information',
  'client reconsidering options',
  'client requires more time',
  'client unresponsive',
  'communication gaps',
  'competitor involved',
  'competitor involvement',
  'competitor',
  'compliance concerns',
  'compliance concerns',
  'compliance issues',
  'concerned about timeline',
  'concerns about roi',
  'conflict of interest',
  'competing interests',
  'competing interest',
  'conflicting priorities',
  'considering alternative options',
  'considering alternatives',
  'contingency planning',
  'contract delays',
  'contract negotiation',
  'customer dissatisfaction',
  'decision on hold',
  'decision pending further information',
  'decision pending',
  'decision-maker change',
  'decision-maker turnover',
  'delayed decision-making',
  'delayed',
  'deployment challenges',
  'difficult implementation',
  'difficulty in reaching agreement',
  'disagreement among stakeholders',
  'disengaged',
  'doubts about solution fit',
  'escalation required',
  'evaluation in progress',
  'extended evaluation period',
  'facing objections',
  'feasibility concerns',
  'financial constraints',
  'funding issues',
  'further discussion required',
  'further evaluation needed',
  'hard-sell',
  'hesitant stakeholders',
  'hesitant',
  'hesitation',
  'high attrition risk',
  'implementation challenges',
  'implementation obstacles',
  'incomplete information',
  'inconclusive meeting',
  'integration hurdles',
  'internal changes',
  'internal conflicts',
  'internal deliberation',
  'internal discussion',
  'internal reorganization',
  'internal restructuring',
  'internal review',
  'internal roadblocks',
  'issue resolution',
  'lack authority',
  'lack of budget',
  'lack of buy-in',
  'lack of clarity',
  'lack of consensus',
  'lack of decision-maker availability',
  'lack of engagement',
  'lack of interest',
  'lack of progress',
  'lack of support',
  'lack of urgency',
  'lacking authority',
  'lacking definition',
  'lacking urgency',
  'limited availability',
  'limited buy-in',
  'limited budget',
  'limited communication',
  'limited interest',
  'limited resources',
  'lost contact',
  'low priority',
  'management change',
  'misaligned expectations',
  'need approval',
  'need help',
  'need to unblock',
  'needing help',
  'needs help',
  'negotiation challenges',
  'no authority',
  'no budget',
  'no commitment',
  'no decision',
  'no follow-up',
  'no timeline',
  'not convinced',
  'not responding',
  'not responsive',
  'objections',
  'on hold',
  'on-hold',
  'organizational changes',
  'organizational resistance',
  'other priorities',
  'pending clarification',
  'pending evaluation',
  'pending feedback',
  'pending final decision',
  'pending further discussion',
  'pending internal review',
  'pending requirements',
  'pending sign-off',
  'postponed',
  'postponed',
  'potential delay',
  'potential delays',
  'price sensitive',
  'price sensitivity',
  'pricing concerns',
  'product fit concerns',
  'product fit issues',
  'project on hold',
  'project reprioritization',
  'prolonged negotiations',
  'purchasing delays',
  'pushback',
  'pushed back',
  'quality concerns',
  're-evaluating',
  're-prioritized',
  'reassessing budget priorities',
  'reconsidering priorities',
  'reconsidering',
  'red flags',
  'requires additional information',
  'requires further discussion',
  'requires more time',
  'resistance from key stakeholder',
  'resistance from stakeholders',
  'resistance',
  'resource limitations',
  'restrucuturing',
  'risk of cancellation',
  'risk of loss',
  'risk of postponement',
  'roi concerns',
  'scope changes',
  'shifting priorities',
  'slipped',
  'slipping',
  'slow decision-making process',
  'slow progress',
  'slow responses',
  'slow to respond',
  'staffing challenges',
  'stakeholder concerns',
  'stakeholder hesitancy',
  'stalled negotiation',
  'stalled progress',
  'stalled',
  'stalled',
  'struggling to gain consensus',
  'struggling',
  'technical issues',
  'tentative commitment',
  'timeline concerns',
  'unavailable',
  'uncertain timeline',
  'uncertain',
  'unclear decision making process',
  'unclear decision-making process',
  'unclear expectations',
  'unclear next steps',
  'unclear',
  'unconvinced',
  'undefined decision process',
  'undefined timeline',
  'unresolved concerns',
  'unresolved issues',
  'unresolved questions',
  'unresponsive client',
  'unresponsive',
  'waiting for approval',
  'waiting for decision',
  'waiting for feedback',
  'waiting for final decision',
  'waiting for sign-off',
  'waiting on feedback',
];

export default RiskPhrases;
