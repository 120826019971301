import {
  FontIcon,
  FontSizes,
  FontWeights,
  IconButton,
  mergeStyles,
  NeutralColors,
  PersonaSize,
  PrimaryButton,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  Company,
  ExternalServiceObjectType,
} from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import randomColor from 'randomcolor';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useStack } from '../../../Hooks/useStack';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { CompanyDetailsPanelContext } from '../../../types/CompanyDetailsPanelContext';
import { Card } from '../../Card';
import { CompanyCard } from '../../MeetingPlans/MeetingPlanAttendees';
import { CompanyNoteEditor } from '../../MeetingPlans/SidePanels/CompanyNoteEditor';
import { HubSpotBrowserSidePanel } from '../../MeetingPlans/SidePanels/HubSpot/HubSpotBrowserSidePanel';
import { HubSpotObjectSidePanel } from '../../MeetingPlans/SidePanels/HubSpot/HubSpotObjectSidePanel';
import { SalesforceBrowserSidePanel } from '../../MeetingPlans/SidePanels/Salesforce/SalesforceBrowserSidePanel';
import { SalesforceObjectSidePanel } from '../../MeetingPlans/SidePanels/Salesforce/SalesforceObjectSidePanel';
import { CompanyOverviewSidePanel } from '../SidePanels/CompanyOverviewSidePanel';
import CompanyContacts from './CompanyContacts';
import CompanyResourcesDoc from './CompanyResourcesDoc';
import CompanySummaryCoach from './CompanySummaryCoach';

const PIPELINE_COACH_WIDTH = '25rem';
const PIPELINE_COACH_HEIGHT = `32rem`;
const PIPELINE_COACH_TEXTAREA_HEIGHT = `32rem`;
const PIPELINE_COACH_MIN_HEIGHT = '10rem';
const PIPELINE_COACH_MAX_HEIGHT = `calc(100vh - 10rem)`; // 3.75rem is the height of the header

interface CompanyDetailProps {
  organizationSlug: string;
  company: Company;
}

const CompanyDetail = ({ organizationSlug, company }: CompanyDetailProps) => {
  const { isDark } = useLightOrDarkMode();
  const companyId = company.id;
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();

  const [
    companyActivitySummaryVisible,
    { toggle: toggleCompanyActivitySummaryVisible },
  ] = useBoolean(false);

  const {
    head: panelContext,
    push: pushToPanelStack,
    pop: popPanelStack,
    reset: resetPanelStack,
    count: panelStackCount,
  } = useStack<CompanyDetailsPanelContext>({ type: 'insights' });

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  const wrapperStyles = mergeStyles({
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 5.25rem)',
    padding: '0',
    backgroundColor: isDark
      ? NeutralColors.gray220
      : MEETINGFLOW_COLORS.purpleUltraSuperLight,
    borderLeft: `.25rem solid ${
      isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleUltraLight
    }`,
    overflowY: 'hidden',
  });

  const primaryContentStyles = mergeStyles({
    width: `calc(100% - 25rem)`,
    padding: '.5rem',
    overflowX: 'hidden',
    overflowY: 'auto',
  });

  const sidebarStyles = mergeStyles({
    width: '25rem',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '.5rem',
    borderLeft: `.25rem solid ${
      isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleUltraLight
    }`,

    // Style overrides for where companies/contacts differs from Meetingflow side panels
    '.base-side-panel-content': {
      height: 'unset !important',
      minHeight: 'unset !important',
    },
  });

  const companySummaryButtonClass = mergeStyles({
    position: 'absolute',
    bottom: '1.5rem',
    right: '1rem',
    zIndex: 51,
    // fontFamily: 'Kalam',
    transition: '.3s ease-in-out all',

    span: {
      color: `${MEETINGFLOW_COLORS.white} !important`,
      position: 'relative',
      top: '-1px',
    },

    '&:hover': {
      backgroundColor: MEETINGFLOW_COLORS.teal,
      borderColor: MEETINGFLOW_COLORS.teal,
    },

    i: {
      fontSize: '1.5rem',
      position: 'relative',
      top: '6px',
      right: 0,
      color: `${MEETINGFLOW_COLORS.white} !important`,
    },
  });

  const coachContainerClass = mergeStyles({
    animationName: 'fadeInAnimation',
    animationDuration: '.3s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    transition: '.5s ease-in-out all',
    position: 'absolute',
    bottom: '5rem',
    right: '1rem',
    width: PIPELINE_COACH_WIDTH,
    maxWidth: PIPELINE_COACH_WIDTH,
    height: PIPELINE_COACH_HEIGHT,
    minHeight: PIPELINE_COACH_MIN_HEIGHT,
    maxHeight: PIPELINE_COACH_MAX_HEIGHT,
    backgroundColor: isDark
      ? NeutralColors.gray200
      : MEETINGFLOW_COLORS.purpleUltraLight,
    zIndex: 51,
    borderRadius: '.5rem',
    boxShadow: '0px 0px 8px rgba(0,0,0,.1)',
    border: `1px solid ${
      isDark ? NeutralColors.gray200 : MEETINGFLOW_COLORS.purpleGrey
    }`,
    paddingBottom: '.5rem',

    '.coach-header': {
      lineHeight: '2rem',
      // fontFamily: 'Kalam',
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.smallPlus,
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 'calc(100% - 1rem - 2px)',
      height: '2rem',
      backgroundColor: isDark
        ? NeutralColors.gray210
        : MEETINGFLOW_COLORS.purpleGrey,
      zIndex: 55,
      border: `1px solid ${
        isDark ? NeutralColors.gray200 : MEETINGFLOW_COLORS.white
      }`,
      borderTopLeftRadius: '.25rem',
      borderTopRightRadius: '.25rem',
      borderBottom: 'none',
      padding: '0 .5rem',
      color: MEETINGFLOW_COLORS.purpleMedium,
    },

    '.coach-content': {
      position: 'relative',
      overflow: 'auto',
      height: `calc(100% - 3rem)`,
      top: '2rem',
      padding: '.5rem',
    },

    '.close-button': {
      position: 'absolute',
      top: '.25rem',
      right: '.25rem',
      height: '1.5rem',
      transition: '.3s ease-in-out all',
      backgroundColor: 'transparent !important',
      color: MEETINGFLOW_COLORS.purpleSecondary,
      ':hover': {
        color: MEETINGFLOW_COLORS.teal,
      },
      zIndex: 56,
    },

    '.gpt-output-box': {
      display: 'flex',
      flexDirection: 'column',
      height: `calc(100% - 3rem)`,

      '.generate-button': {
        position: 'relative',
        backgroundColor: 'transparent',
        padding: '0 0 0 .5rem',
        borderRadius: '.25rem',
        top: '-0 !important',
        zIndex: '56',
        float: 'none !important',
        textAlign: 'center',
        transition: '.3s ease-in-out all',
        span: {
          color: isDark ? NeutralColors.gray100 : NeutralColors.gray100,
          position: 'relative',
          top: '-1px',
        },
        outline: `1px solid transparent`,
        ':hover': {
          outline: `1px solid ${
            isDark ? NeutralColors.gray170 : MEETINGFLOW_COLORS.purpleLight
          }`,
          backgroundColor: isDark
            ? NeutralColors.gray160
            : MEETINGFLOW_COLORS.white,
        },
      },

      '.gpt-controls': {
        marginBottom: '.5rem',
      },

      '.output': {
        position: 'relative',
        '.generate-button': {
          float: 'right',
        },
        '.output-input': {
          '.ms-TextField-wrapper': {
            '.ms-TextField-fieldGroup': {
              backgroundColor: 'transparent !important',
              textarea: {
                resize: 'none',
                height: `${PIPELINE_COACH_TEXTAREA_HEIGHT} !important`,
                overflow: 'auto',
                fontSize: `${FontSizes.medium} !important`,
                fontWeight: FontWeights.semibold,
                border: 'none !important',
                backgroundColor: 'transparent !important',
                paddingTop: '.5rem !important',
                paddingRight: '1rem !important',
                color: isDark ? NeutralColors.gray70 : NeutralColors.gray130,

                ':disabled': {
                  color: `${
                    isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black
                  } !important`,
                  boxShadow: 'none !important',
                  cursor: 'unset',

                  border: `none !important`,
                },
              },
            },
          },
        },
      },
    },
  });

  return (
    <div className={wrapperStyles}>
      <div className={primaryContentStyles}>
        <CompanyCard
          key={`company-card-${companyId}`}
          organizationSlug={organizationSlug}
          company={company}
          socialIconSize={24}
          personaSize={PersonaSize.size56}
        />
        <div style={{ marginTop: '1rem' }}>
          <Card
            key={`company-card-wrapper-${companyId}`}
            smallHeader
            allowCollapsing
            title={`Notes for ${company.legalName || company.name}`}
            contentContainerPadding={'.25rem'}
            noBottomPadding
          >
            <CompanyNoteEditor
              company={company}
              organizationSlug={organizationSlug}
              color={color}
              fullWidth
            />
            <CompanyResourcesDoc
              company={company}
              organizationSlug={organizationSlug}
            />
          </Card>
        </div>

        <Card
          key={companyId}
          smallHeader
          allowCollapsing
          title={`Contacts at ${company.name}`}
          contentContainerPadding={'.25rem'}
          noBottomPadding
        >
          <div style={{ width: '100%' }}>
            <CompanyContacts
              key={`company-contacts-${companyId}`}
              hideCompanyHeader
              organizationSlug={organizationSlug}
              company={company}
              domain={company.domains[0].domain}
              internalDomains={company.domains.map((d) => d.domain)}
              onContactClick={(c) => {
                navigate(
                  `/organization/${organizationSlug}/library/contacts/${c}`,
                );
              }}
            />
          </div>
        </Card>
      </div>
      <div className={sidebarStyles}>
        {panelContext?.type === 'insights' ? (
          <CompanyOverviewSidePanel
            organizationSlug={organizationSlug}
            companyId={company.id}
            company={company}
            onPickedSalesforceObject={(salesforceContext) =>
              pushToPanelStack({
                type: 'salesforce-object',
                salesforceContext: {
                  objectId: salesforceContext.externalId,
                  name: salesforceContext.name,
                  objectType: salesforceContext.objectType,
                  serviceConfigurationId:
                    salesforceContext.serviceConfigurationId,
                },
              })
            }
            onPickedHubSpotObject={(context) =>
              pushToPanelStack({
                type: 'hubspot-object',
                context: {
                  objectId: context.externalId,
                  name: context.name,
                  objectType: context.objectType,
                  serviceConfigurationId: context.serviceConfigurationId,
                },
              })
            }
            pushSalesforcePanel={(context) =>
              pushToPanelStack({
                type: 'salesforce-object',
                salesforceContext: context,
              })
            }
            pushHubSpotPanel={(context) =>
              pushToPanelStack({
                type: 'hubspot-object',
                context,
              })
            }
            showSalesforceObjectPicker={(defaultTab) =>
              pushToPanelStack({ type: 'salesforce-browser', defaultTab })
            }
            showHubSpotObjectPicker={(defaultTab) =>
              pushToPanelStack({ type: 'hubspot-browser', defaultTab })
            }
          />
        ) : null}

        {panelContext?.type === 'salesforce-browser' ? (
          <SalesforceBrowserSidePanel
            organizationSlug={organizationSlug}
            defaultTab={panelContext.defaultTab}
            pushSalesforcePanel={(context) =>
              pushToPanelStack({
                type: 'salesforce-object',
                salesforceContext: context,
              })
            }
            onPickedObject={(salesforceContext) =>
              pushToPanelStack({
                type: 'salesforce-object',
                salesforceContext: {
                  objectId: salesforceContext.externalId,
                  name: salesforceContext.name,
                  objectType: salesforceContext.objectType,
                  serviceConfigurationId:
                    salesforceContext.serviceConfigurationId,
                },
              })
            }
            onBack={() => {
              if (panelStackCount > 1) {
                popPanelStack();
              } else {
                resetPanelStack({ type: 'insights' });
              }
            }}
          />
        ) : null}

        {panelContext?.type === 'salesforce-object' ? (
          <SalesforceObjectSidePanel
            key={panelContext.salesforceContext.objectId}
            organizationSlug={organizationSlug!}
            salesforceContext={panelContext.salesforceContext}
            showObjectPicker={(
              defaultTab?: ExternalServiceObjectType | 'PINNED',
            ) => {
              if (defaultTab === 'PINNED') {
                pushToPanelStack({
                  type: 'salesforce-browser',
                  defaultTab: 'ACCOUNT',
                });
              } else {
                pushToPanelStack({
                  type: 'salesforce-browser',
                  defaultTab,
                });
              }
            }}
            pushSalesforcePanel={(context) => {
              pushToPanelStack({
                type: 'salesforce-object',
                salesforceContext: context,
              });
            }}
            onBack={() => {
              if (panelStackCount > 1) {
                popPanelStack();
              } else {
                resetPanelStack({ type: 'insights' });
              }
            }}
          />
        ) : null}

        {panelContext?.type === 'hubspot-browser' ? (
          <HubSpotBrowserSidePanel
            organizationSlug={organizationSlug}
            defaultTab={panelContext.defaultTab}
            pushHubSpotPanel={(context) =>
              pushToPanelStack({
                type: 'hubspot-object',
                context,
              })
            }
            onPickedObject={(context) =>
              pushToPanelStack({
                type: 'hubspot-object',
                context: {
                  objectId: context.externalId,
                  name: context.name,
                  objectType: context.objectType,
                  serviceConfigurationId: context.serviceConfigurationId,
                },
              })
            }
            onBack={() => {
              if (panelStackCount > 1) {
                popPanelStack();
              } else {
                resetPanelStack({ type: 'insights' });
              }
            }}
          />
        ) : null}

        {panelContext?.type === 'hubspot-object' ? (
          <HubSpotObjectSidePanel
            key={panelContext.context.objectId}
            organizationSlug={organizationSlug!}
            panelContext={panelContext.context}
            showObjectPicker={(
              defaultTab?: ExternalServiceObjectType | 'PINNED',
            ) => {
              if (defaultTab === 'PINNED') {
                pushToPanelStack({
                  type: 'hubspot-browser',
                  defaultTab: 'DEAL',
                });
              } else {
                pushToPanelStack({
                  type: 'hubspot-browser',
                  defaultTab,
                });
              }
            }}
            pushHubSpotPanel={(context) => {
              pushToPanelStack({
                type: 'hubspot-object',
                context,
              });
            }}
            onBack={() => {
              if (panelStackCount > 1) {
                popPanelStack();
              } else {
                resetPanelStack({ type: 'insights' });
              }
            }}
          />
        ) : null}
      </div>

      {['panda-ai', 'meetingflow', 'kyle-zone2'].includes(organizationSlug) ? (
        <>
          <PrimaryButton
            // @ts-ignore - Need JSX here for icon
            text={
              <>
                Summarize Recent Meetingflows{' '}
                <FontIcon
                  iconName="AISparkle"
                  style={{ color: MEETINGFLOW_COLORS.teal }}
                />
              </>
            }
            onClick={() => {
              appInsights.trackEvent({
                name: `PIPELINE_COACH_BUTTON_CLICKED`,
                properties: {
                  // If we're clicking the button, we're doing the opposite of the current state
                  open: !companyActivitySummaryVisible,
                },
              });
              toggleCompanyActivitySummaryVisible();
            }}
            className={companySummaryButtonClass}
          />
          {companyActivitySummaryVisible ? (
            <div className={coachContainerClass}>
              <div className="coach-header">
                <span>Company Summary</span>
                <IconButton
                  className="close-button"
                  iconProps={{
                    iconName: 'ChromeClose',
                  }}
                  onClick={toggleCompanyActivitySummaryVisible}
                />
              </div>
              <div className="coach-content">
                <CompanySummaryCoach
                  organizationSlug={organizationSlug!}
                  companyId={companyId}
                  outputLabel="Company Summary (Preview)"
                  surfaceName="Company Summary"
                  maxTokens={512}
                  hideLabels
                />
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default CompanyDetail;
