import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  Dropdown,
  IDialogProps,
  mergeStyles,
  PrimaryButton,
  TextField,
  Text,
  FontWeights,
} from '@fluentui/react';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ROLE_DROPDOWN_OPTIONS_LIMITED } from '../../../Helpers/Organizations';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { OmitValues } from '@meetingflow/common/ObjectHelpers';
import PublicEmailDomains from '@meetingflow/common/PublicEmailDomains';
import { useEffect } from 'react';
import { OrganizationDomainRuleType } from '@meetingflow/common/Api/data-contracts';

export const RFC_522_DOMAIN_REGEXP =
  /^(((?!-))(xn--)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30})\.[a-z]{2,}$/;

type NewDomainRuleForm = {
  domain: string;
  allowJoinRequests: boolean;
  approveJoinRequestsAutomatically: boolean;
  roleType?: 'CREATOR' | 'COLLABORATOR' | null;
};

export type NewDomainRuleDialogProps = {
  existingDomains: string[];
  onCreate: (domainRule: {
    domain: string;
    ruleType: OrganizationDomainRuleType;
    roleType?: 'CREATOR' | 'COLLABORATOR' | null;
  }) => void;
  onCancel: () => void;
};
export const NewDomainRuleDialog = ({
  existingDomains,
  onCreate,
  onCancel,
  ...rest
}: IDialogProps & NewDomainRuleDialogProps) => {
  const { isDark } = useLightOrDarkMode();
  const domains = PublicEmailDomains;
  const formSchema = yup
    .object({
      domain: yup
        .string()
        .matches(RFC_522_DOMAIN_REGEXP, 'Invalid domain')
        .notOneOf(
          existingDomains,
          'Domain is already associated with this workspace',
        )
        .required(),
      allowJoinRequests: yup.boolean(),
      approveJoinRequestsAutomatically: yup.boolean(),
      roleType: yup.string().oneOf(['CREATOR', 'COLLABORATOR']).optional(),
    })
    .test(
      'validateDomainRule',
      'Role type must be set if ruleType is not internal domain',
      (value) => {
        if (
          !value.allowJoinRequests &&
          !value.approveJoinRequestsAutomatically
        ) {
          return true;
        }

        return !!value.roleType;
      },
    )
    .required();

  const {
    watch,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isValid, errors },
  } = useForm<NewDomainRuleForm>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      domain: '',
      allowJoinRequests: true,
      approveJoinRequestsAutomatically: false,
      roleType: 'COLLABORATOR',
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const domain = watch('domain').trim().toLowerCase();
  const disableDiscoverability = !domains || domains.includes(domain);

  useEffect(() => {
    if (disableDiscoverability) {
      setValue('allowJoinRequests', false);
      setValue('approveJoinRequestsAutomatically', false);
    } else {
      setValue('allowJoinRequests', true);
      setValue('approveJoinRequestsAutomatically', true);
    }
  }, [disableDiscoverability, setValue]);

  const onSubmit = handleSubmit((data) => {
    if (isValid) {
      let ruleType: OrganizationDomainRuleType = 'INTERNAL_DOMAIN';
      if (data.allowJoinRequests) {
        ruleType = 'CAN_REQUEST_ACCESS';
        if (data.approveJoinRequestsAutomatically) {
          ruleType = 'AUTO_APPROVE';
        }
      }
      onCreate({
        domain: data.domain,
        ruleType,
        roleType: ruleType === 'INTERNAL_DOMAIN' ? null : data.roleType,
      });
      reset();
    }
  });

  return (
    <Dialog
      {...rest}
      dialogContentProps={{ title: 'Add new domain rule' }}
      maxWidth={'100%'}
      onDismiss={() => {
        reset();
        onCancel();
      }}
    >
      <Controller
        name="domain"
        control={control}
        defaultValue={''}
        render={({ field }) => (
          <TextField
            {...OmitValues(field, 'ref')}
            onChange={(_e, newValue) =>
              field.onChange(newValue?.trim()?.toLowerCase() || '')
            }
            label="Domain"
            errorMessage={errors.domain?.message}
          />
        )}
      />{' '}
      {disableDiscoverability ? (
        <Text
          block
          style={{
            marginTop: '1.5rem',
            marginBottom: '.5rem',
            fontWeight: FontWeights.semibold,
          }}
        >
          Workspace discoverability is disabled for {domain} for privacy
          reasons.
        </Text>
      ) : null}
      <Controller
        name="allowJoinRequests"
        control={control}
        defaultValue={false}
        render={({ field: { onChange, value, ...renderRest } }) => (
          <Checkbox
            {...renderRest}
            className={mergeStyles({
              marginLeft: '1.5rem',
              marginTop: '.5rem',
              marginBottom: '.5rem',
            })}
            label={`Allow users from this domain to request access to this workspace.`}
            disabled={disableDiscoverability}
            checked={value}
            onChange={(_ev, checked) => onChange(!!checked)}
          />
        )}
      />
      {watch('allowJoinRequests') ? (
        <>
          <Controller
            name="roleType"
            control={control}
            render={({ field }) => (
              <Dropdown
                errorMessage={errors.roleType?.message}
                options={ROLE_DROPDOWN_OPTIONS_LIMITED}
                disabled={disableDiscoverability || !watch('allowJoinRequests')}
                selectedKey={field.value}
                onBlur={field.onBlur}
                onChange={(_evt, option, _index) => {
                  if (!option?.key) {
                    return;
                  }
                  field.onChange(option?.key as 'CREATOR' | 'COLLABORATOR');
                }}
                label="Add new members as:"
              />
            )}
          />
          <Controller
            name="approveJoinRequestsAutomatically"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value, ...renderRest } }) => (
              <Checkbox
                {...renderRest}
                className={mergeStyles({
                  marginLeft: '1.5rem',
                  marginTop: '.5rem',
                })}
                disabled={disableDiscoverability || !watch('allowJoinRequests')}
                label={`Automatically approve requests to join this workspace.`}
                checked={value}
                onChange={(_ev, checked) => onChange(!!checked)}
              />
            )}
          />
        </>
      ) : null}
      <DialogFooter>
        <PrimaryButton text="Add" disabled={!isValid} onClick={onSubmit} />
        <DefaultButton
          text="Cancel"
          onClick={() => {
            reset();
            onCancel();
          }}
          styles={{
            label: { color: isDark ? 'white' : undefined },
          }}
        />
      </DialogFooter>
    </Dialog>
  );
};
