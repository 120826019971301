import {
  DetailsList,
  mergeStyles,
  Icon,
  FontSizes,
  FontWeights,
  IIconStyles,
} from '@fluentui/react';
import { DateTime } from 'luxon';
import {
  DealRoomArtifact,
  DealRoomMeetingflowArtifact,
  DetailedDealRoom,
} from '@meetingflow/common/Api/data-contracts';
import { artifactTypeToIconName } from '../../../Helpers/IconHelpers';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { ArtifactsTableActions } from './ArtifactsTableActions';
import { useMemo, useState } from 'react';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { DecisionSiteMeetingflowCard } from '../../../Components/MeetingPlans/DecisionSiteMeetingflowCard';

type DealRoomArtifactsTableProps = {
  onClick: (id: DealRoomArtifact['id']) => unknown;
  onDelete?: (id: DealRoomArtifact['id']) => Promise<unknown>;
  organizationSlug: string;
  dealRoomId: number;
  refreshDealRoom: () => Promise<unknown>;
  showActionButtons?: boolean;
  isCollapsible?: boolean;
  defaultCollapsed?: boolean;
  includeDeleted?: boolean;
  excludeMeetingflows?: boolean;
  showOnlyDeleted?: boolean;
  showOnlyMeetingflows?: boolean;
  title?: string;
  subTitle?: string;
  icon?: React.ReactNode;
  queryKey?: string;
};

export type TableRowItem = {
  key: DealRoomArtifact['id'];
  name: DealRoomArtifact['name'];
  sharedOn: DealRoomArtifact['createdAt'];
  artifactId: DealRoomArtifact['id'];
  deletedAt: DealRoomArtifact['deletedAt'];
  updatedAt: DealRoomArtifact['updatedAt'];
  type: DealRoomArtifact['type'];
  creator: DealRoomArtifact['creator'];
  mimeType: string | null | undefined;
  meetingflow?: DealRoomMeetingflowArtifact['meetingflow'];
};

const getFormattedRows = (
  artifacts: DetailedDealRoom['artifacts'],
): TableRowItem[] =>
  artifacts.map((artifact) => ({
    key: artifact.id,
    name: artifact.name,
    sharedOn: DateTime.fromISO(artifact.createdAt).toFormat('M/d/yy'),
    artifactId: artifact.id,
    deletedAt: artifact.deletedAt,
    updatedAt: artifact.updatedAt,
    type: artifact.type,
    mimeType: 'mimeType' in artifact ? artifact.mimeType : undefined,
    creator: artifact.creator,
    meetingflow: 'meetingflow' in artifact ? artifact.meetingflow : undefined,
  }));

interface TitleSectionProps {
  title: string;
  subTitle?: string;
  icon?: React.ReactNode;
  isCollapsible: boolean;
  isCollapsed: boolean;
  setIsCollapsed: (value: boolean) => void;
}

const TitleSection = ({
  title,
  subTitle,
  icon,
  isCollapsible,
  isCollapsed,
  setIsCollapsed,
}: TitleSectionProps) => {
  const titleStyles = mergeStyles({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: isCollapsible ? 'pointer' : 'default',
    fontWeight: FontWeights.semibold,
    fontSize: FontSizes.medium,
    paddingLeft: isCollapsible ? '0' : '2rem',

    '.collapse-toggle i': {
      display: 'block',
      fontSize: '10px',
      cursor: 'pointer !important',
      width: '24px',
      textAlign: 'center',
    },

    span: {
      fontSize: FontSizes.small,
      fontWeight: FontWeights.regular,
      color: DEALROOMS_COLORS.cloudburst,
    },
  });

  return (
    <h5
      className={titleStyles}
      onClick={() => isCollapsible && setIsCollapsed(!isCollapsed)}
    >
      {isCollapsible && (
        <div className="collapse-toggle">
          <Icon iconName={isCollapsed ? 'ChevronRight' : 'ChevronDown'} />
        </div>
      )}
      {icon ? icon : null}
      {title}
      {subTitle ? <span>{subTitle}</span> : null}
    </h5>
  );
};

export const DealRoomArtifactsTable = ({
  onClick,
  organizationSlug,
  dealRoomId,
  refreshDealRoom,
  onDelete,
  showActionButtons = true,
  isCollapsible = false,
  defaultCollapsed = false,
  title,
  subTitle,
  icon,
  queryKey,
  showOnlyDeleted = false,
  showOnlyMeetingflows = false,
  includeDeleted = false,
  excludeMeetingflows = false,
}: DealRoomArtifactsTableProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const [isCollapsed, setIsCollapsed] = useState(
    isCollapsible ? defaultCollapsed : false,
  );

  const {
    data: allArtifactsData,
    isLoading,
    refetch: refetchArtifacts,
  } = useQuery(
    [queryKey || 'artifacts', dealRoomId, organizationSlug],
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listArtifacts(
        { organizationSlug, dealRoomId, includeDeleted: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  const allArtifacts = allArtifactsData?.data;

  const undeletedArtifacts = useMemo(
    () =>
      excludeMeetingflows
        ? allArtifacts?.filter(
            (artifact) =>
              artifact.type !== 'MEETINGFLOW' && !artifact.deletedAt,
          )
        : allArtifacts?.filter((artifact) => !artifact.deletedAt),
    [allArtifacts, excludeMeetingflows],
  );

  const deletedArtifacts = useMemo(
    () =>
      excludeMeetingflows
        ? allArtifacts?.filter(
            (artifact) => artifact.type !== 'MEETINGFLOW' && artifact.deletedAt,
          )
        : allArtifacts?.filter((artifact) => artifact.deletedAt),
    [allArtifacts, excludeMeetingflows],
  );

  const meetingflowArtifacts = useMemo(
    () => allArtifacts?.filter((artifact) => artifact.type === 'MEETINGFLOW'),
    [allArtifacts],
  );

  const mainContainerWrapperStyles = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '100%',
    overflow: 'hidden',
    height: 'auto',
    maxHeight: isCollapsed ? '1.5rem' : '100%',
    transition: '1.3s ease-in-out max-height height',

    h5: {
      margin: 0,
    },
  });

  const mainContainerStyles = mergeStyles({
    width: 'calc(100% - 22px)',
    padding: '10px',
    backgroundColor: DEALROOMS_COLORS.white,
    boxShadow: '0px 2px 2px 0px #00000005',
    transition: 'all 0.3s ease',
  });

  const tableStyles = mergeStyles({
    width: '100%',
    marginLeft: '1rem',

    '.ms-DetailsHeader': {
      height: 'auto',
      lineHeight: '16px !important',
      paddingTop: '10px',
      paddingBottom: '8px',
    },

    '.ms-DetailsHeader-cell': {
      fontSize: '13px !important',
      lineHeight: '16px !important',
      fontWeight: '400 !important',
      minHeight: '16px',
      height: '16px',
      backgroundColor: 'transparent',
      color: DEALROOMS_COLORS.themeSecondary,

      '.ms-DetailsHeader-cellName': {
        fontSize: '13px !important',
        lineHeight: '16px !important',
        fontWeight: '400 !important',
      },

      ':hover': {
        backgroundColor: DEALROOMS_COLORS.white,
        color: DEALROOMS_COLORS.themeSecondary,
      },

      ':active': {
        backgroundColor: DEALROOMS_COLORS.white,
        color: DEALROOMS_COLORS.themeSecondary,
      },

      ':selected': {
        backgroundColor: DEALROOMS_COLORS.white,
        color: DEALROOMS_COLORS.themeSecondary,
      },
    },

    '.ms-DetailsRow': {
      backgroundColor: 'transparent',
      borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight} !important`,

      ':hover': {
        backgroundColor: DEALROOMS_COLORS.financialLighterGray,
      },
    },

    '.ms-DetailsRow-cell': {
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      color: DEALROOMS_COLORS.themePrimary,
      padding: '12px 0 12px 12px',
      fontSize: '15px',
      lineHeight: '24px',
    },

    '.artifact-name': {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      fontWeight: FontWeights.regular,
      width: '100%',
      height: '100%',

      ':hover': {
        color: DEALROOMS_COLORS.plum100,
      },

      i: {
        color: DEALROOMS_COLORS.neutralDark,
        fontSize: '15px',
      },
    },

    '.deleted-artifact': {
      cursor: 'not-allowed !important',
      color: DEALROOMS_COLORS.neutralSecondary,

      ':hover': {
        color: DEALROOMS_COLORS.neutralSecondary,
      },
    },
  });

  const getColumnsDef = useMemo(
    () => [
      {
        key: 'name',
        name: 'Name',
        fieldName: 'name',
        minWidth: 100,
        onRender: (data: TableRowItem) => {
          const iconName = artifactTypeToIconName(
            data.type,
            data.mimeType || undefined,
          );
          return data?.type === 'MEETINGFLOW' && data?.meetingflow?.id ? (
            <>
              <div
                className={`artifact-name ${data?.deletedAt ? 'deleted-artifact' : ''} `}
              >
                <Icon iconName={iconName} />
                <DecisionSiteMeetingflowCard
                  meetingflowId={data.meetingflow.id}
                  dealRoomId={dealRoomId}
                  organizationSlug={organizationSlug}
                  onClick={() => onClick(data.artifactId)}
                  noTitleLink
                  showCompanies
                  showCallRecordingButton={false}
                  showEndTime
                />
              </div>
            </>
          ) : (
            <div
              className={`artifact-name ${data?.deletedAt ? 'deleted-artifact' : ''} `}
              onClick={
                data?.deletedAt ? undefined : (e) => onClick(data?.artifactId)
              }
            >
              <Icon iconName={iconName} />
              <span>{data.name}</span>
            </div>
          );
        },
      },
      {
        key: 'owner',
        name: 'Owner',
        fieldName: 'creator.id',
        minWidth: 100,
        onRender: (data: TableRowItem) => {
          return <>{data.creator?.name}</>;
        },
      },
      {
        key: 'lastModified',
        name: 'Last Modified',
        fieldName: 'updatedAt',
        minWidth: 100,
        onRender: (data: TableRowItem) => {
          return <>{DateTime.fromISO(data.updatedAt).toFormat('M/d/yy')}</>;
        },
      },
      ...(showActionButtons
        ? [
            {
              key: 'actions',
              name: '',
              fieldName: 'actions',
              minWidth: 32,
              maxWidth: 32,
              onRender: (data: TableRowItem) => (
                <ArtifactsTableActions
                  itemData={data}
                  organizationSlug={organizationSlug}
                  dealRoomId={dealRoomId}
                  refreshDealRoom={refreshDealRoom}
                  refetchArtifacts={refetchArtifacts}
                  openDetailsView={onClick}
                  onDelete={onDelete ? onDelete : undefined}
                />
              ),
            },
          ]
        : []),
    ],
    [
      organizationSlug,
      dealRoomId,
      onClick,
      onDelete,
      refreshDealRoom,
      showActionButtons,
      refetchArtifacts,
    ],
  );

  const artifacts = showOnlyMeetingflows
    ? meetingflowArtifacts || []
    : showOnlyDeleted
      ? deletedArtifacts || []
      : includeDeleted
        ? allArtifacts || []
        : undeletedArtifacts || [];

  return (
    <div className={mainContainerWrapperStyles}>
      {title && (
        <TitleSection
          title={title}
          subTitle={subTitle}
          icon={icon}
          isCollapsible={isCollapsible}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      )}

      <div className={mainContainerStyles}>
        <DetailsList
          items={getFormattedRows(artifacts)}
          columns={getColumnsDef}
          className={tableStyles}
          checkboxVisibility={2}
        />
      </div>
    </div>
  );
};
