import {
  FontIcon,
  FontSizes,
  FontWeights,
  Link,
  mergeStyles,
  NeutralColors,
  Spinner,
  Text,
} from '@fluentui/react';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { useQuery } from 'react-query';
import { SalesforceAccountSuggestionsQuery } from '../../../../QueryNames';
import {
  ExternalServiceObject,
  ExternalServiceObjectType,
} from '@meetingflow/common/Api/data-contracts';
import { SalesforceAccount } from '../../../../Models/Salesforce/SalesforceAccount';
import { SalesforceAccountTile } from './SalesforceAccountTile';
import { SalesforcePanelContext } from '../../../../types/SalesforcePanelContext';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
export type MeetingPlanSalesforceSuggestedAccountListProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  salesforceConfigurationId: number;
  externalDomains?: string[];
  onPickedObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPinnedObject?: (
    e?: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => Promise<unknown>;
  pushSalesforcePanel: (context: SalesforcePanelContext) => void;
  associatedObjects?: ExternalServiceObject[];
  showTitle?: boolean;
  title?: string;
  showSalesforceObjectPicker?: (
    defaultTab?: ExternalServiceObjectType | 'PINNED',
  ) => void;
  maxItems?: number;
  hideIfNoContent?: boolean;
};
export const MeetingPlanSalesforceSuggestedAccountList = ({
  organizationSlug,
  meetingPlanId,
  salesforceConfigurationId,
  externalDomains,
  onPickedObject,
  onPinnedObject,
  pushSalesforcePanel,
  associatedObjects = [],
  showTitle = true,
  title = 'Suggested Accounts',
  showSalesforceObjectPicker,
  maxItems,
  hideIfNoContent,
}: MeetingPlanSalesforceSuggestedAccountListProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { loading: configurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'SALESFORCE', withToken: true });

  const {
    data: salesforceAccountSuggestions,
    isLoading: salesforceAccountSuggestionsLoading,
    isRefetching: salesforceAccountSuggestionsRefetching,
    refetch: refetchSalesforceAccountSuggestions,
  } = useQuery(
    SalesforceAccountSuggestionsQuery(
      organizationSlug!,
      salesforceConfigurationId,
      externalDomains,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SalesforceAccount[]>(
        `/organization/${organizationSlug}/external/salesforce/configuration/${salesforceConfigurationId}/accounts`,
        {
          params: {
            active: true,
            domains: externalDomains,
            limit: maxItems,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 5 min
      staleTime: 300000,
      enabled: !!externalDomains?.length,
    },
  );

  useEffect(() => {
    refetchSalesforceAccountSuggestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    meetingPlanId,
    salesforceConfigurationId,
    organizationSlug,
    externalDomains,
  ]);

  const listClass = mergeStyles({
    position: 'relative',
    animationName: 'fadeInSlideAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    width: 'auto',
    transition: '.3s ease-in-out all',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    rowGap: '.125rem',
  });

  const slideSpinnerClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
  });

  if (configurationsLoading) {
    return (
      <div style={{ margin: '1rem 0' }}>
        <Spinner className={slideSpinnerClass} />
      </div>
    );
  }

  const sectionSubHeaderClass = mergeStyles({
    margin: '0 0 .5rem 0!important',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.semibold,
    color: NeutralColors.gray100,
    position: 'relative',
    display: 'block',
    width: '100%',
  });

  if (configurationsLoading) {
    return (
      <div style={{ margin: '1rem 0' }}>
        <Spinner className={slideSpinnerClass} />
      </div>
    );
  }

  const slideSpinnerInHeaderClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
    position: 'absolute',
    top: showSalesforceObjectPicker ? '-.25rem' : 0,
    right: '0',
  });

  const salesforceAccounts = salesforceAccountSuggestions?.data
    ?.filter((account) => {
      const associatedObjectIds = associatedObjects.map((o) => o.externalId);
      return !associatedObjectIds.includes(account.Id);
    })
    ?.slice(0, maxItems);

  return (
    <div
      className={listClass}
      style={{
        display:
          (hideIfNoContent && salesforceAccountSuggestionsLoading) ||
          (hideIfNoContent &&
            !salesforceAccountSuggestionsLoading &&
            salesforceAccounts?.length === 0) ||
          !salesforceAccounts
            ? 'none'
            : 'block',
      }}
    >
      {showTitle ? (
        <Text block className={sectionSubHeaderClass}>
          {title}
          {showSalesforceObjectPicker ? (
            <Link
              style={{
                display: 'block',
                float: 'right',
                textAlign: 'right',
                fontSize: FontSizes.small,
                fontWeight: FontWeights.semilight,
                marginRight:
                  salesforceAccountSuggestionsLoading ||
                  salesforceAccountSuggestionsRefetching
                    ? '2rem'
                    : 0,
                transition: '.3s ease-in-out all',
              }}
              onClick={() => showSalesforceObjectPicker('ACCOUNT')}
            >
              All
              <FontIcon
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  top: '.15rem',
                  marginLeft: '.25rem',
                }}
                iconName="PageRight"
              />
            </Link>
          ) : null}
          {salesforceAccountSuggestionsLoading ||
          salesforceAccountSuggestionsRefetching ? (
            <Spinner className={slideSpinnerInHeaderClass} />
          ) : null}
        </Text>
      ) : null}
      {salesforceAccounts && salesforceAccounts?.length > 0 ? (
        <div>
          {salesforceAccounts.map((account) => {
            const externalServiceObject = {
              externalId: account.Id,
              objectType: 'ACCOUNT',
              name: account.Name,
              serviceConfigurationId: salesforceConfigurationId,
              serviceConfiguration: configurationById(
                salesforceConfigurationId,
              )!,
            } as ExternalServiceObject;
            return (
              <SalesforceAccountTile
                meetingPlanId={meetingPlanId}
                organizationSlug={organizationSlug}
                key={account.Id}
                externalId={account.Id}
                name={account.Name}
                salesforceInstance={
                  configurationById(salesforceConfigurationId)!.instanceId!
                }
                onClick={() => onPickedObject(externalServiceObject)}
                onPinClick={onPinnedObject}
                showExternalLink
              />
            );
          })}
        </div>
      ) : (
        <>
          {salesforceAccountSuggestionsLoading ||
          salesforceAccountSuggestionsRefetching ? null : (
            <Text
              block
              variant="small"
              style={{ fontStyle: 'italic', margin: '.5rem 0' }}
            >
              There are no suggested accounts.
            </Text>
          )}
        </>
      )}
    </div>
  );
};
