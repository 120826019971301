import { useAuth0 } from '@auth0/auth0-react';
import {
  Dropdown,
  FontSizes,
  IStackItemStyles,
  IStyle,
  PrimaryButton,
  Stack,
  Text,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AutomaticallyShareRecordingsPreference,
  MeetingflowVisibility,
} from '@meetingflow/common/Api/data-contracts';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';
import ImageUploader from '../../../../Components/Common/ImageUploader';
import {
  AUTO_SHARE_RECORDINGS_OPTIONS,
  VISIBILITY_OPTIONS,
} from '../../../../Constants';
import { DEFAULT_STACK_TOKENS } from '../../../../Helpers/Layout';
import useBreakpoints from '../../../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../../Hooks/useOrganization';
import {
  OrganizationCallRecorderThumbnailQuery,
  OrganizationQuery,
  OrganizationsQuery,
} from '../../../../QueryNames';
import { OrganizationsApiClient } from '../../../../Services/NetworkCommon';
import {
  settingsIndentedControlClass,
  settingsPrimaryButtonClass,
  settingsRootStackStyles,
  settingsSectionClass,
  settingsSectionDescriptionClass,
  settingsSectionDescriptionClassDark,
  settingsSubHeadClass,
} from '../SettingsPageLayout';

type MeetingPlanOrganizationSettingsForm = {
  name: string;
  defaultVisibility: MeetingflowVisibility;
  automaticallyShareRecordings: AutomaticallyShareRecordingsPreference;
};

const formSchema = yup
  .object({
    name: yup.string().required(),
    automaticallyShareRecordings: yup
      .string()
      .oneOf(['ENABLED', 'DISABLED'])
      .required(),
    defaultVisibility: yup
      .string()
      .oneOf(['ORGANIZATION', 'LIMITED'])
      .required(),
  })
  .required();

export const EditOrganizationTabContent = () => {
  const { getAccessTokenSilently } = useAuth0();

  const client = useQueryClient();
  const { isDark } = useLightOrDarkMode();

  const breakpoints = useBreakpoints();

  const {
    name: orgName,
    slug: orgSlug,
    organization,
    hasEntitlement,
    refetch,
  } = useOrganization();

  const { data: callRecorderThumbnail, refetch: refetchCallRecorderThumbnail } =
    useQuery(
      OrganizationCallRecorderThumbnailQuery(orgSlug!),
      async () => {
        const token = await getAccessTokenSilently();
        return OrganizationsApiClient.getCallRecorderThumbnail(orgSlug!, {
          headers: { Authorization: `Bearer ${token}` },
        });
      },
      { enabled: !!orgSlug && hasEntitlement('CALL_RECORDING') },
    );

  const {
    setValue,
    reset,
    handleSubmit,
    control,
    formState: { isValid, errors, isDirty },
    watch,
  } = useForm<MeetingPlanOrganizationSettingsForm>({
    resolver: yupResolver(formSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  useEffect(() => {
    if (organization) {
      reset({
        name: organization.name,
        defaultVisibility: organization.defaultVisibility,
        automaticallyShareRecordings: organization.automaticallyShareRecordings,
      });
    }
  }, [setValue, organization, reset]);

  const onSubmit = handleSubmit(async (data) => {
    if (isValid) {
      const token = await getAccessTokenSilently();
      const result = await OrganizationsApiClient.patchOrganization(
        orgSlug!,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (result.status === 200) {
        reset({
          name: result.data.name,
          defaultVisibility: result.data.defaultVisibility,
        });
        client.setQueryData(OrganizationQuery(orgSlug!), result);
        client.invalidateQueries(OrganizationsQuery);
        await refetch();
      }
    }
  });

  const defaultVisibility = watch('defaultVisibility');
  const automaticallyShareRecordings = watch('automaticallyShareRecordings');

  // Styling to match the description text of form fields, because Dropdown doesn't have a description prop.
  const visibilityDescriptionClass = mergeStyles({
    fontSize: FontSizes.mini,
    marginTop: '.25rem',
    color: 'rgb(96, 94, 92)',

    ul: {
      margin: 0,
      paddingLeft: '1rem',
    },
  });

  const halfWidthStackItemStyles = {
    root: {
      maxWidth: breakpoints.md ? 'calc(50% - 1rem)' : 'calc(100% - 1rem)',
    },
  } as IStackItemStyles;

  return (
    <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
      <div className={settingsSectionClass}>
        <h2 className={settingsSubHeadClass}>Workspace</h2>
        <Stack.Item grow={1} styles={halfWidthStackItemStyles}>
          <div className={settingsIndentedControlClass}>
            <Controller
              name="name"
              control={control}
              defaultValue={orgName}
              render={({ field: { value, onBlur, onChange } }) => (
                <TextField
                  errorMessage={errors.name?.message}
                  label="Name"
                  value={value}
                  onChange={(_e, newValue) => onChange(newValue || '')}
                  onBlur={onBlur}
                  description="A name for your organization's workspace. We recommend using your company name."
                />
              )}
            />

            <TextField
              disabled
              label="Identifier"
              value={orgSlug}
              description="A URL-friendly identifier for your organization's workspace. This cannot be changed."
              readOnly
            />
          </div>
        </Stack.Item>

        <h2 className={settingsSubHeadClass}>Privacy</h2>

        <Stack.Item grow={1} styles={halfWidthStackItemStyles}>
          <div className={settingsIndentedControlClass}>
            <Text
              className={
                isDark
                  ? mergeStyles(settingsSectionDescriptionClassDark as IStyle)
                  : mergeStyles(settingsSectionDescriptionClass as IStyle)
              }
            >
              Meetingflows are the building blocks of your organization's
              Workspace. One can be created for each meeting, and they will be
              used to do meeting prep, take notes, and complete follow up tasks.
            </Text>
            <Controller
              name="defaultVisibility"
              control={control}
              render={({ field }) => (
                <Dropdown
                  errorMessage={errors.defaultVisibility?.message}
                  options={VISIBILITY_OPTIONS}
                  selectedKey={field.value}
                  onBlur={field.onBlur}
                  onChange={(_evt, option, _index) => {
                    if (!option?.key) {
                      return;
                    }
                    field.onChange(option?.key as MeetingflowVisibility);
                  }}
                  label="Default Meetingflow Visibility"
                />
              )}
            />
            {defaultVisibility === 'ORGANIZATION' ? (
              <div className={visibilityDescriptionClass}>
                <span>
                  <strong>Workspace:</strong> By default, Meetingflows will can
                  be viewed by
                </span>
                <ul style={{ margin: '0.25rem' }}>
                  <li>All workspace Admins, Creators, and Collaborators</li>
                  <li>
                    Guests with whom the Meetingflow was explicitly shared
                  </li>
                </ul>
              </div>
            ) : (
              <div className={visibilityDescriptionClass}>
                <span>
                  <strong>Limited:</strong> By default, Meetingflows can be
                  viewed by
                </span>
                <ul style={{ margin: '0.25rem' }}>
                  <li>All workspace Admins</li>
                  <li>
                    Creators and Collaborators who created the Meetingflow, are
                    attendees, or with whom the plan was explicitly shared
                  </li>
                  <li>
                    Guests with whom the Meetingflow was explicitly shared
                  </li>
                </ul>
              </div>
            )}
          </div>
        </Stack.Item>

        {hasEntitlement('CALL_RECORDING') ? (
          <div className={settingsSectionClass}>
            <h2 className={settingsSubHeadClass}>
              AI Note Taker Bot Thumbnail
            </h2>
            <Stack.Item grow={1}>
              <div className={settingsIndentedControlClass}>
                <Text
                  className={
                    isDark
                      ? mergeStyles(
                          settingsSectionDescriptionClassDark as IStyle,
                        )
                      : mergeStyles(settingsSectionDescriptionClass as IStyle)
                  }
                >
                  When recording a video call, the Meetingflow AI Note Taker bot
                  will display this image as a participant video image. You can
                  upload your own image if you prefer your own branding.
                </Text>
                <ImageUploader
                  initialPreview={callRecorderThumbnail?.data}
                  onSelectFile={async (thumbnail) => {
                    const token = await getAccessTokenSilently();
                    const result =
                      await OrganizationsApiClient.setCallRecorderThumbnail(
                        orgSlug!,
                        { thumbnail },
                        {
                          headers: { Authorization: `Bearer ${token}` },
                        },
                      );

                    toast.success(
                      'The workspace call recorder thumbnail was updated',
                    );

                    refetchCallRecorderThumbnail();
                  }}
                  onClearImage={async () => {
                    const token = await getAccessTokenSilently();
                    const result =
                      await OrganizationsApiClient.deleteCallRecorderThumbnail(
                        orgSlug!,
                        {
                          headers: { Authorization: `Bearer ${token}` },
                        },
                      );

                    toast.success(
                      'The workspace call recorder thumbnail was cleared',
                    );

                    refetchCallRecorderThumbnail();
                  }}
                />
              </div>
            </Stack.Item>
          </div>
        ) : null}

        {hasEntitlement('CALL_RECORDING') ? (
          <>
            <h2 className={settingsSubHeadClass}>
              Automatically Share Call Recordings
            </h2>
            <Stack.Item grow={1} styles={halfWidthStackItemStyles}>
              <div className={settingsIndentedControlClass}>
                <Text
                  className={
                    isDark
                      ? mergeStyles(
                          settingsSectionDescriptionClassDark as IStyle,
                        )
                      : mergeStyles(settingsSectionDescriptionClass as IStyle)
                  }
                >
                  At the end of recorded meetings, Meetingflow can make the
                  recording, transcript, and AI-generated summary available to
                  all meeting attendees.{' '}
                  <strong>
                    Attendees will receive an email summary of the meeting and a
                    link to the recording and transcript.
                  </strong>{' '}
                  This setting can be overridden on a per-Meetingflow basis, and
                  recordings can be shared with attendees manually when this
                  setting is off.{' '}
                  <strong>
                    Meeting notes, action items, and other internal information
                    will not be shared.
                  </strong>
                </Text>
                <Controller
                  name="automaticallyShareRecordings"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      errorMessage={
                        errors.automaticallyShareRecordings?.message
                      }
                      options={AUTO_SHARE_RECORDINGS_OPTIONS}
                      selectedKey={field.value}
                      onBlur={field.onBlur}
                      onChange={(_evt, option, _index) => {
                        if (!option?.key) {
                          return;
                        }
                        field.onChange(
                          option?.key as AutomaticallyShareRecordingsPreference,
                        );
                      }}
                      label="Automatically Share Recordings"
                    />
                  )}
                />
                {automaticallyShareRecordings === 'ENABLED' ? (
                  <div className={visibilityDescriptionClass}>
                    <span>
                      <strong>On:</strong> Recordings, transcripts, and
                      AI-generated summaries of recorded meetings will be shared
                      with all meeting attendees.
                    </span>
                  </div>
                ) : (
                  <div className={visibilityDescriptionClass}>
                    <span>
                      <strong>Off:</strong> Recordings, transcripts, and
                      AI-generated summaries will not be made available to
                      external attendees. Internal attendees will receive an
                      email with this information, and users can manually share
                      the recording for a given Meetingflow.
                    </span>
                  </div>
                )}
              </div>
            </Stack.Item>
          </>
        ) : null}

        <PrimaryButton
          disabled={!isValid || !isDirty}
          text="Save"
          onClick={onSubmit}
          className={settingsPrimaryButtonClass}
        />
      </div>
    </Stack>
  );
};
