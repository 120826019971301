import { IDropdownOption } from '@fluentui/react';

export const ROLE_DROPDOWN_OPTIONS = [
  {
    key: 'ADMIN',
    text: 'Admin',
  },
  {
    key: 'CREATOR',
    text: 'Creator (Can create Meetingflows)',
  },
  {
    key: 'COLLABORATOR',
    text: 'Collaborator (Can read and edit Meetingflows)',
  },
  {
    key: 'GUEST',
    text: 'Guest',
  },
] satisfies IDropdownOption[];

export const ROLE_DROPDOWN_OPTIONS_NO_GUEST = [
  {
    key: 'ADMIN',
    text: 'Admin',
  },
  {
    key: 'CREATOR',
    text: 'Creator (Can create Meetingflows)',
  },
  {
    key: 'COLLABORATOR',
    text: 'Collaborator (Can read and edit Meetingflows)',
  },
] satisfies IDropdownOption[];

export const ROLE_DROPDOWN_OPTIONS_LIMITED = [
  {
    key: 'CREATOR',
    text: 'Creator (Can create Meetingflows)',
  },
  {
    key: 'COLLABORATOR',
    text: 'Collaborator (Can read and edit Meetingflows)',
  },
] satisfies IDropdownOption[];

export const ROLE_DROPDOWN_OPTIONS_DISABLED_GUEST = [
  {
    key: 'ADMIN',
    text: 'Admin',
  },
  {
    key: 'CREATOR',
    text: 'Creator (Can create Meetingflows)',
  },
  {
    key: 'COLLABORATOR',
    text: 'Collaborator (Can read and edit Meetingflows)',
  },
  {
    key: 'GUEST',
    text: 'Guest',
    disabled: true,
  },
] satisfies IDropdownOption[];
