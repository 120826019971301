import { DateTime } from 'luxon';
import { SalesforceOpportunity } from '../../Models/Salesforce/SalesforceOpportunity';

export const AgeInDays = (
  opportunity: Pick<SalesforceOpportunity, 'CreatedDate'>,
) =>
  Math.round(
    Math.abs(DateTime.fromISO(opportunity.CreatedDate).diffNow('days').days),
  );

export const DaysSinceLastModified = (
  opportunity: Pick<SalesforceOpportunity, 'CreatedDate' | 'LastModifiedDate'>,
) =>
  opportunity.LastModifiedDate
    ? Math.round(
        Math.abs(
          DateTime.fromISO(opportunity.LastModifiedDate).diffNow('days').days,
        ),
      )
    : AgeInDays(opportunity);

export const DaysInStage = (
  opportunity: Pick<
    SalesforceOpportunity,
    'CreatedDate' | 'StageName' | 'history'
  >,
) => {
  const lastStageChangeIndex = opportunity.history?.findIndex(
    (h) => h.StageName !== opportunity.StageName,
  );

  return lastStageChangeIndex && lastStageChangeIndex > 0
    ? Math.round(
        Math.abs(
          DateTime.fromISO(
            opportunity.history![lastStageChangeIndex - 1]!.CreatedDate,
          ).diffNow('days').days,
        ),
      )
    : AgeInDays(opportunity);
};

export const DaysToCloseDate = (
  opportunity: Pick<SalesforceOpportunity, 'CloseDate'>,
) => Math.round(DateTime.fromISO(opportunity.CloseDate).diffNow('days').days);
