import {
  FontWeights,
  Icon,
  IconButton,
  mergeStyles,
  NeutralColors,
  useTheme,
} from '@fluentui/react';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../Themes/Themes';

interface ContactChipProps {
  id?: string;
  className?: string;
  name?: string;
  email?: string;
  onClick?: () => void;
  onDelete?: () => void;
}

export const ContactChip = ({
  id,
  className,
  name,
  email,
  onClick,
  onDelete,
}: ContactChipProps) => {
  const theme = useTheme();
  const { isDark } = useLightOrDarkMode();

  const label = useMemo(() => {
    if (name && email) {
      return `${name} <${email}>`;
    }
    if (name) {
      return name;
    }
    if (email) {
      return email;
    }
    return '';
  }, [email, name]);

  const chipStyles = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',

    columnGap: '0.5rem',
    height: '1.5rem',
    backgroundColor: isDark
      ? NeutralColors.gray150
      : MEETINGFLOW_COLORS.purpleGreyMediumAlt,
    borderRadius: '1rem',
    paddingLeft: '.5rem',
    paddingRight: '1.25rem',
    lineHeight: '1.35rem',
    fontWeight: FontWeights.semibold,
    color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,
    cursor: 'pointer',
    transition: '.3s ease-in-out all',
    maxWidth: '13rem',
    ':hover': {
      backgroundColor: isDark
        ? NeutralColors.gray130
        : MEETINGFLOW_COLORS.purpleGreyMedium,
      button: {
        transition: '.3s ease-in-out all',
        backgroundColor: isDark
          ? NeutralColors.gray120
          : MEETINGFLOW_COLORS.purpleGreyMedium,
        color: MEETINGFLOW_COLORS.white,
      },
    },
    i: {
      position: 'relative',
      top: '1px',
    },
    button: {
      maxWidth: '1rem',
      height: '1rem',
      width: '1rem',
      position: 'relative',
      top: '4px',
      marginLeft: '.5rem',

      i: {
        position: 'relative',
        top: 'unset',
        color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,
      },
    },
    '.contact-chip-text': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  });

  return (
    <div
      id={id}
      className={classNames(chipStyles, className)}
      onClick={onClick}
      title={label}
    >
      <Icon iconName="Contact" />
      <span className="contact-chip-text">{label}</span>
      {onDelete ? (
        <IconButton
          id="chip-delete-button"
          title="Remove"
          onClick={(e) => {
            e.stopPropagation();
            return onDelete();
          }}
          styles={{
            icon: { color: 'white' },
          }}
          iconProps={{ iconName: 'StatusCircleErrorX' }}
        />
      ) : null}
    </div>
  );
};
