import { useAuth0 } from '@auth0/auth0-react';
import { NeutralColors, mergeStyles } from '@fluentui/react';
import { Contact } from '@meetingflow/common/Api/data-contracts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../Hooks/useOrganization';
import { OrganizationContactQuery } from '../../../QueryNames';
import { ContactsApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { OrganizationSlugRouteParams } from '../../../types/RouteParams';
import ContactDetail from './ContactDetail';
import ContactList from './ContactList';

const ContactBrowser = () => {
  const navigate = useNavigate();
  const { slug: organizationSlug } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const [contacts, setContacts] = useState<Contact[] | undefined>([]);

  const { objectId } = useParams<
    OrganizationSlugRouteParams & {
      objectId?: string;
    }
  >();

  const selectContact = useCallback(
    (contactId: Contact['id']) =>
      navigate(
        `/organization/${organizationSlug}/library/contacts/${contactId}`,
      ),
    [navigate, organizationSlug],
  );

  const contactId = useMemo(() => {
    if (!objectId) {
      return undefined;
    }
    const parsedObjectId = parseInt(objectId);

    return Number.isInteger(parsedObjectId) ? parsedObjectId : undefined;
  }, [objectId]);

  const { data: activeContactData, refetch: refetchContact } = useQuery(
    OrganizationContactQuery(organizationSlug!, contactId),
    async () => {
      const token = await getAccessTokenSilently();
      if (contactId) {
        return ContactsApiClient.getContact(organizationSlug!, contactId!, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
    },
    { enabled: !!organizationSlug && !!contactId },
  );

  const activeContact = activeContactData?.data;

  useEffect(() => {
    if (!contactId && contacts?.length) {
      selectContact(contacts[0].id);
    }
  }, [contactId, contacts, selectContact]);

  useEffect(() => {
    if (contactId) {
      refetchContact();
    }
  }, [contactId, refetchContact]);

  const contactBrowserClass = mergeStyles({
    display: 'flex',
    width: '100%',
    height: '100%',
    maxHeight: 'calc(100vh - 3.75rem)',
    overflow: 'hidden',
    backgroundColor: isDark
      ? NeutralColors.gray200
      : MEETINGFLOW_COLORS.purpleUltraSuperLight,
    '.ms-DetailsRow-cell': { padding: '.25rem' },
  });

  return (
    <div className={contactBrowserClass}>
      <div
        style={{
          width: '27rem',
          height: 'calc(100vh - 7rem)',
          overflowX: 'auto',
          overflowY: 'auto',
        }}
      >
        <ContactList
          onSelectContact={selectContact}
          activeContactId={contactId}
          onListChange={setContacts}
        />
      </div>

      <div style={{ width: '100%' }}>
        {contactId && organizationSlug && activeContact ? (
          <ContactDetail
            key={contactId}
            contact={activeContact}
            organizationSlug={organizationSlug}
            onContactClick={selectContact}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ContactBrowser;
