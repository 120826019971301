import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  Dropdown,
  FontIcon,
  FontWeights,
  ITooltipHostStyles,
  NeutralColors,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { OmitValues } from '@meetingflow/common/ObjectHelpers';
import PublicEmailDomains from '@meetingflow/common/PublicEmailDomains';
import { titleize } from 'inflection';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import tlds from 'tlds';
import * as yup from 'yup';
import { DEFAULT_STACK_TOKENS } from '../../Helpers/Layout';
import { ROLE_DROPDOWN_OPTIONS_LIMITED } from '../../Helpers/Organizations';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { OrganizationsApiClient } from '../../Services/NetworkCommon';
import { Card } from '../Card';
import { AsyncPrimaryButton } from '../HOC/AsyncButton';
import { PageLayout } from '../Layouts/PageLayout';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { AUTO_SHARE_RECORDINGS_OPTIONS } from '../../Constants';
import {
  AutomaticallyShareRecordingsPreference,
  MeetingflowVisibility,
} from '@meetingflow/common/Api/data-contracts';

type CreateOrganizationFormData = {
  slug: string;
  name: string;
  allowJoinRequests: boolean;
  approveJoinRequestsAutomatically: boolean;
  approveJoinRequestsAutomaticallyAsRole?: 'CREATOR' | 'COLLABORATOR';
  automaticallyShareRecordings: AutomaticallyShareRecordingsPreference;
};

export type WelcomeCreateOrganizationProps = {
  companyName?: string;
  onOrganizationCreated: (slug: string) => void;
};
export const WelcomeCreateOrganization = ({
  companyName,
  onOrganizationCreated,
}: WelcomeCreateOrganizationProps) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { isDark } = useLightOrDarkMode();
  const domains = PublicEmailDomains;
  const emailDomain = useMemo(() => {
    return user!.email!.toLowerCase().split('@')[1];
  }, [user]);

  const userDomain = useMemo(() => {
    const matchingTld = tlds
      .filter((tld) => emailDomain?.endsWith(tld))
      .reduce((a, b) => (a.length <= b.length ? b : a));
    if (matchingTld) {
      const trimmedDomain = emailDomain?.replace(`.${matchingTld}`, '');
      if ((trimmedDomain?.length ?? 0) >= 5) {
        return trimmedDomain;
      }
    }

    return emailDomain;
  }, [emailDomain]);

  const defaultSlug = useMemo(() => {
    if (domains?.includes(emailDomain)) {
      const replaced = user!
        .email!.toLowerCase()
        .replaceAll(/[^a-z0-9]/g, '-')
        .replaceAll(/-{2,}/g, '-');
      if (replaced.length < 4) {
        return `${replaced}-flow`;
      }
      return replaced;
    }
    if (companyName) {
      return companyName
        .toLowerCase()
        .replaceAll(/[^a-z0-9]/g, '-')
        .replaceAll(/-{2,}/g, '-');
    }
    if (!userDomain) {
      return '';
    }
    const replaced = userDomain
      .toLowerCase()
      .replaceAll(/[^a-z0-9]/g, '-')
      .replaceAll(/-{2,}/g, '-');
    if (replaced.length < 4) {
      return `${replaced}-flow`;
    }
    return replaced;
  }, [companyName, domains, emailDomain, user, userDomain]);

  const defaultName = useMemo(() => {
    if (domains?.includes(emailDomain)) {
      if (user?.given_name && user.family_name) {
        return titleize(
          `${user.given_name}${
            user.given_name.endsWith('s') ? "'" : "'s"
          } Workspace`,
        );
      }
      if (user?.name) {
        return titleize(
          `${user.name}${user.name.endsWith('s') ? "'" : "'s"} Workspace`,
        );
      }
      return titleize(
        `${user!.email}${user!.email!.endsWith('s') ? "'" : "'s"} Workspace`,
      );
    }
    if (companyName) {
      return titleize(companyName);
    }

    if (!userDomain) {
      return '';
    }
    return titleize(userDomain.replaceAll('.', '-'));
  }, [companyName, domains, emailDomain, user, userDomain]);

  const formSchema = useMemo(
    () =>
      yup
        .object({
          slug: yup
            .string()
            .min(5, 'The workspace identifier must be at least 5 characters')
            .matches(
              /^[a-z][-a-z0-9]{3,}[a-z0-9]$/,
              'The identifier must start with a letter, and contain letters, numbers and dashes',
            )
            .test(
              'Available?',
              'The specified identifier is not available',
              async (value) => {
                if (!value || !value.match(/^[a-z][-a-z0-9]{3,}[a-z0-9]$/)) {
                  return false;
                }
                const token = await getAccessTokenSilently();
                try {
                  const result =
                    await OrganizationsApiClient.checkSlugAvailable(value, {
                      headers: { Authorization: `Bearer ${token}` },
                    });
                  return result.data;
                } catch (err) {
                  return false;
                }
              },
            )
            .required(),
          name: yup.string().required(),
          allowJoinRequests: yup.boolean(),
          approveJoinRequestsAutomatically: yup.boolean(),
          approveJoinRequestsAutomaticallyAsRole: yup
            .string()
            .oneOf(['COLLABORATOR', 'CREATOR'], 'Invalid role'),
          automaticallyShareRecordings: yup
            .string()
            .oneOf(['ENABLED', 'DISABLED']),
        })
        .required(),
    [getAccessTokenSilently],
  );

  const disableDiscoverability = !domains || domains.includes(emailDomain);

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    watch,
    trigger,
    setValue,
  } = useForm<CreateOrganizationFormData>({
    defaultValues: {
      slug: defaultSlug,
      name: defaultName,
      allowJoinRequests: disableDiscoverability ? false : true,
      approveJoinRequestsAutomatically: disableDiscoverability ? false : true,
      approveJoinRequestsAutomaticallyAsRole: 'COLLABORATOR',
    },
    resolver: yupResolver(formSchema, { abortEarly: false }),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('slug', defaultSlug, { shouldValidate: true });
    setValue('name', defaultName, { shouldValidate: true });
  }, [defaultSlug, defaultName, setValue]);

  useEffect(() => {
    if (disableDiscoverability) {
      setValue('allowJoinRequests', false);
      setValue('approveJoinRequestsAutomatically', false);
    } else {
      setValue('allowJoinRequests', true);
      setValue('approveJoinRequestsAutomatically', true);
    }
  }, [disableDiscoverability, setValue]);

  // Validate org slug on mount
  useEffect(() => {
    trigger('slug');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyName) {
      setValue('name', companyName);
      setValue(
        'slug',
        companyName
          .toLowerCase()
          .replace(/\s+/g, '-')
          .replace(/[^-a-z0-9]/g, ''),
      );
    }
  }, [companyName, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    if (isValid) {
      const token = await getAccessTokenSilently();
      const result = await OrganizationsApiClient.putOrganization(
        data.slug,
        {
          name: data.name,
          automaticallyShareRecordings: data.automaticallyShareRecordings,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (result.status === 201) {
        if (
          data.allowJoinRequests &&
          data.approveJoinRequestsAutomaticallyAsRole
        ) {
          // Add the domain rules
          const ruleType = data.approveJoinRequestsAutomatically
            ? 'AUTO_APPROVE'
            : 'CAN_REQUEST_ACCESS';
          const roleType = data.approveJoinRequestsAutomaticallyAsRole;
          await OrganizationsApiClient.putDomainRule(
            data.slug,
            emailDomain,
            { roleType, ruleType },
            { headers: { Authorization: `Bearer ${token}` } },
          );
        }

        toast.success(`The workspace ${data?.name} has been created!.`);

        onOrganizationCreated(data.slug);
      }
    }
  });

  const toolTipHostStyles = useMemo(
    () =>
      ({
        root: {
          display: 'inline-block',
          height: '16px',
          width: '16px',
          marginLeft: '.25rem',

          p: {
            margin: 0,
          },
        },
      }) as ITooltipHostStyles,
    [],
  );

  const tooltipCalloutProps = useMemo(
    () => ({
      styles: {
        root: {
          padding: 0,
          background: MEETINGFLOW_COLORS.purpleDarker,
          color: isDark ? undefined : MEETINGFLOW_COLORS.white,
        },
        beak: {
          background: MEETINGFLOW_COLORS.purpleDarker,
        },
        calloutMain: {
          padding: '1rem',
          backgroundColor: isDark ? undefined : MEETINGFLOW_COLORS.purpleDarker,
          p: {
            margin: 0,
            borderBottomRightRadius: '.5rem',
            borderBottomLeftRadius: '.5rem',
            color: MEETINGFLOW_COLORS.white,
          },
        },
      },
    }),
    [isDark],
  );

  const automaticallyShareRecordings = watch('automaticallyShareRecordings');

  return (
    <div>
      <div className="welcome-wizard-form">
        <div className="elements column">
          <div className="elements row">
            <Controller
              name="name"
              control={control}
              render={({ field: { value, onBlur, onChange } }) => (
                <TextField
                  value={value}
                  onChange={(_e, newValue) => onChange(newValue || '')}
                  onBlur={onBlur}
                  errorMessage={errors.name?.message}
                  label="Name"
                  styles={{ root: { flex: '.5 1 0px !important' } }}
                  description="Choose a name for your Workspace. It can be anything you like."
                />
              )}
            />

            <Controller
              name="slug"
              control={control}
              render={({ field: { value, onBlur, onChange } }) => (
                <TextField
                  prefix="https://app.meetingflow.com/organization/"
                  styles={{
                    prefix: {
                      backgroundColor: isDark
                        ? NeutralColors.gray190
                        : NeutralColors.gray20,
                    },
                  }}
                  value={value}
                  onChange={(_e, newValue) => onChange(newValue || '')}
                  onBlur={onBlur}
                  errorMessage={errors.slug?.message}
                  label="URL Identifier"
                  description={`Choose a custom URL identifier for your Workspace. It will be visible when you share Meetingflow links with others.`}
                />
              )}
            />
          </div>

          {/* <h4 className="separator">
            Discoverability{' '}
            <TooltipHost
              styles={toolTipHostStyles}
              calloutProps={tooltipCalloutProps}
              content={
                <>
                  <p>
                    If Workspace Discoverability is enabled, other Meetingflow
                    users that share your email domain will be able to discover
                    your Workspace and join it. Upon signing up for Meetingflow,
                    users from {emailDomain} will be presented with the option
                    to join your Workspace.
                  </p>

                  <p>
                    If Discoverability is off, only users you invite directly
                    will be able to join.
                  </p>
                </>
              }
            >
              <FontIcon iconName="Info" />
            </TooltipHost>
          </h4>
          <p style={{ marginBottom: '0' }}>
            Enable Discoverability to allow others from{' '}
            <strong>{emailDomain} </strong>to join your Workspace.{' '}
          </p>
          {disableDiscoverability ? (
            <p className="discoverability-disabled">
              Note: Workspace Discoverability is disabled for your email domain
              ({emailDomain}). You can create a Workspace with a {emailDomain}{' '}
              email address, but others will not be able to discover it. To
              create a Workspace with Discoverability, sign in to Meetingflow
              with a work email address.
            </p>
          ) : null} */}

          {/* <div
            className="elements row"
            style={{
              opacity: disableDiscoverability ? '.25' : '11',
              marginTop: '1rem',
              columnGap: '2rem',
            }}
          >
            <Controller
              name="approveJoinRequestsAutomatically"
              control={control}
              render={({ field: { onChange, value, ...rest } }) => (
                <Checkbox
                  {...OmitValues(rest, 'ref')}
                  checked={value}
                  styles={{
                    root: {
                      i: { color: 'white' },
                      flex: 'auto',
                      whiteSpace: 'nowrap',
                    },
                  }}
                  onChange={(_ev, checked) => onChange(!!checked)}
                  // @ts-ignore
                  label={
                    <>
                      Let anyone with a {emailDomain} email address join
                      as&nbsp;a:
                    </>
                  }
                  disabled={
                    disableDiscoverability || !watch('allowJoinRequests')
                  }
                />
              )}
            />

            <div
              style={{
                position: 'relative',
                top: '-.25rem',
                flex: 'auto',
              }}
            >
              <Controller
                name="approveJoinRequestsAutomaticallyAsRole"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    errorMessage={
                      errors.approveJoinRequestsAutomaticallyAsRole?.message
                    }
                    options={ROLE_DROPDOWN_OPTIONS_LIMITED}
                    disabled={!watch('allowJoinRequests')}
                    selectedKey={field.value}
                    onBlur={field.onBlur}
                    onChange={(_evt, option, _index) => {
                      if (!option?.key) {
                        return;
                      }
                      field.onChange(option?.key as 'CREATOR' | 'COLLABORATOR');
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="row">
            <h4 className="separator">
              Automatically Share Call Recordings{' '}
              <TooltipHost
                styles={toolTipHostStyles}
                calloutProps={tooltipCalloutProps}
                content={
                  <>
                    <Text>
                      At the end of recorded meetings, Meetingflow can make the
                      recording, transcript, and AI-generated summary available
                      to all meeting attendees.{' '}
                      <strong>
                        Attendees will receive an email summary of the meeting
                        and a link to the recording and transcript. The link is
                        accessible by anyone with an email domain matching those
                        of attendees.
                      </strong>{' '}
                      This setting can be overridden on a per-Meetingflow basis,
                      and recordings can be shared with attendees manually when
                      this setting is off.{' '}
                      <strong>
                        Meeting notes, action items, and other internal
                        information will not be shared.
                      </strong>
                    </Text>
                  </>
                }
              >
                <FontIcon iconName="Info" />
              </TooltipHost>
            </h4>
            <Stack.Item grow={1}>
              <div style={{ maxWidth: '50%' }}>
                <Controller
                  name="automaticallyShareRecordings"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      errorMessage={
                        errors.automaticallyShareRecordings?.message
                      }
                      options={AUTO_SHARE_RECORDINGS_OPTIONS}
                      defaultSelectedKey={'ENABLED'}
                      onBlur={field.onBlur}
                      onChange={(_evt, option, _index) => {
                        if (!option?.key) {
                          return;
                        }
                        field.onChange(
                          option?.key as AutomaticallyShareRecordingsPreference,
                        );
                      }}
                      label="Automatically Share Recordings"
                    />
                  )}
                />
                {automaticallyShareRecordings === 'ENABLED' ? (
                  <div style={{ marginTop: '.5rem' }}>
                    <span>
                      <strong>On:</strong> Recordings, transcripts, and
                      AI-generated summaries of recorded meetings will be shared
                      with all meeting attendees. The link is accessible by
                      anyone with an email domain matching those of attendees.
                    </span>
                  </div>
                ) : (
                  <div style={{ marginTop: '.5rem' }}>
                    <span>
                      <strong>Off:</strong> Recordings, transcripts, and
                      AI-generated summaries will not be made available to
                      external attendees. Internal attendees will receive an
                      email with this information, and users can manually share
                      the recording for a given Meetingflow.
                    </span>
                  </div>
                )}
              </div>
            </Stack.Item>
          </div> */}
        </div>
        <div className="controls">
          <AsyncPrimaryButton
            disabled={!isValid}
            text="Create Workspace"
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default WelcomeCreateOrganization;
