import { useAuth0 } from '@auth0/auth0-react';
import { Stack, DefaultButton, useTheme, Text } from '@fluentui/react';
import { FontWeights } from '@fluentui/theme';
import { DEFAULT_STACK_TOKENS } from '../../../../Helpers/Layout';
import { useConfirmationDialog } from '../../../../Hooks/Modals/useConfirmationDialog';
import { useOrganization } from '../../../../Hooks/useOrganization';
import {
  OrganizationsQuery,
  OrganizationQuery,
  OrganizationRoleQuery,
  OrganizationMembersQuery,
  OrganizationMeetingPlansQuery,
} from '../../../../QueryNames';
import { MembersApiClient } from '../../../../Services/NetworkCommon';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { StyledSeparator } from '../../../StyledSeparator';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { toast } from 'react-hot-toast';
import { settingsRootStackStyles } from '../SettingsPageLayout';

export const LeaveOrganizationTabContent = () => {
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();
  const client = useQueryClient();
  const theme = useTheme();

  const { userId } = useUserProfile();
  const { name: orgName, slug: orgSlug } = useOrganization();

  const {
    createDeferred: createConfirmLeaveOrgDeferred,
    deferred: confirmLeaveOrgDeferred,
    dialog: confirmLeaveOrgDialog,
  } = useConfirmationDialog({
    title: `Leave the ${orgName} workspace?`,
    subText:
      'If you leave the workspace you will lose access to all data belonging to the workspace.  Are you sure you would like to leave?',
    primaryAction: 'CANCEL',
  });

  return (
    <>
      <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
        <Text
          style={{
            color: theme.semanticColors.errorText,
            fontWeight: FontWeights.semibold,
            margin: '1rem 0 0 0',
            backgroundColor: theme.semanticColors.errorBackground,
            padding: '.5rem',
            borderRadius: '.25rem',
          }}
        >
          Warning! This action is permanent.
        </Text>

        <StyledSeparator
          marginTop={'2rem'}
          backgroundColor={theme.semanticColors.errorText}
          textColor={'white'}
        >
          Danger Zone
        </StyledSeparator>

        <Stack.Item>
          <DefaultButton
            type="danger"
            text={`Leave ${orgName}`}
            onClick={async () => {
              if (!orgSlug || !userId || confirmLeaveOrgDeferred?.isPending) {
                return;
              }

              try {
                const confirmLeave = await createConfirmLeaveOrgDeferred()
                  .promise;

                if (!confirmLeave) {
                  return;
                }

                const token = await getAccessTokenSilently();
                await toast.promise(
                  MembersApiClient.deleteMember(orgSlug, userId, {
                    headers: { Authorization: `Bearer ${token}` },
                  }),
                  {
                    loading: `Leaving ${orgName}`,
                    success: (_result) => {
                      navigate(`/`);
                      client.invalidateQueries(OrganizationsQuery);
                      client.invalidateQueries(OrganizationQuery(orgSlug));
                      client.invalidateQueries(OrganizationRoleQuery(orgSlug));
                      client.invalidateQueries(
                        OrganizationMembersQuery(orgSlug),
                      );
                      client.invalidateQueries(
                        OrganizationMeetingPlansQuery(orgSlug),
                      );
                      return `You left ${orgName}`;
                    },
                    error: (_err: unknown) => {
                      return 'Something went wrong leaving the workspace, please try again';
                    },
                  },
                );
              } catch (err: unknown) {}
            }}
          />
        </Stack.Item>
      </Stack>
      {confirmLeaveOrgDialog}
    </>
  );
};
