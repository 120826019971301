import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { ApiClient } from '../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../Themes/Themes';

import {
  Checkbox,
  FontWeights,
  NeutralColors,
  PrimaryButton,
  mergeStyles,
} from '@fluentui/react';
import { escapeCsvFormulas } from '@meetingflow/common/ObjectHelpers';
import { DateTime } from 'luxon';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';

async function updateOrgType(
  organizationId: number,
  newType: 'TRIAL' | 'FREE' | 'PAID' | 'VIP' | 'INTERNAL',
  bearerToken: string,
) {
  try {
    const response = await fetch('/api/admin/usage/orgType', {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        organizationId,
        newType,
      }),
    });
    const responseData = await response.text();
    console.info(responseData);
    toast.success('Organization type changed');
    location.reload();
  } catch (error) {
    toast.error('Error changing organization type. Panic!');
  }
}

async function syncStripeUsage(bearerToken: string) {
  try {
    const response = await fetch('/api/admin/usage/syncStripeUsage', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      },
    });
    const responseData = await response.text();
    console.info(responseData);
    toast.success('Stripe usage synced');
  } catch (error) {
    toast.error('Stripe usage sync error');
  }
}

async function removeDevUserSubscriptions(bearerToken: string) {
  try {
    const response = await fetch(
      '/api/admin/usage/removeDevUserSubscriptions',
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
    const responseData = await response.text();
    console.info(responseData);
    toast.success('Removed dev user subscriptions');
  } catch (error) {
    toast.error('Error removing dev user subscriptions');
  }
}

type AdminBillingListResponse = {
  organizations: {
    organizationId: number;
    organizationName: string;
    organizationSlug: string;
    organizationType: string;

    entitlements: string[];

    callRecording: {
      currentMonth: {
        totalRecordingTimeHours: number;
        totalRecordingCount: number;
      };
      lifetime: {
        totalRecordingTimeHours: number;
        totalRecordingCount: number;
      };
    };
    totalRecordingTimeHours: number;
    totalRecordingCount: number;

    totalMeetingflows: number;

    subscription?: {
      status: string;
      plan: string;
      quantity: number;
      billingPeriod: string;
    };

    needsPayment: boolean;
    needsPaymentReason: string;

    users: {
      userId: number;
      name: string | null;
      email: string;
      role: string;
      organizationMembershipCreated: string;
      organizationMembershipUpdated: string;
    }[];
  }[];
};

export function AdminBillingList() {
  const { isDark } = useLightOrDarkMode();
  const { getAccessTokenSilently } = useAuth0();

  const [filterToNeedsPayment, setFilterToNeedsPayment] = useState(false);

  const { data: paymentResponse, isLoading: isLoading } = useQuery(
    `admin_billingneedspayment`,
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<AdminBillingListResponse>(
        `/admin/usage/needs-payment`,
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: false,
        },
      );
    },
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!paymentResponse?.data) {
    return <div>Something went wrong</div>;
  }

  const data = paymentResponse.data;
  const organizations = data.organizations.filter(
    (o) => !filterToNeedsPayment || o.needsPayment,
  );

  const billingListClass = mergeStyles({
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,
    padding: '1rem',
    borderRadius: '.25rem',
    overflowY: 'scroll',
    height: '100%',

    '> .controls': {
      padding: '.25rem',
      display: 'flex',
      marginBottom: '.5rem',

      '.ms-Checkbox-text': {
        fontWeight: FontWeights.semibold,
      },
    },

    details: {
      marginBottom: '.25rem',
      '> summary': {
        display: 'flex',
        flexDirection: 'row',
        rowGap: '.25rem',
        justifyContent: 'space-between',
        cursor: 'pointer',

        span: {
          display: 'block',
          width: '100%',
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
            : MEETINGFLOW_COLORS.white,
          padding: '0 .25rem',
          lineHeight: '1.5rem',
        },

        'span.needs-payment-indicator span': {
          display: 'inline-block',
          width: 'auto',
          padding: '.25rem',
          lineHeight: '1rem',
          borderRadius: '.25rem',
          color: MEETINGFLOW_COLORS.white,
          fontWeight: FontWeights.semibold,
        },

        'span.needs-payment-indicator.paid span': {
          backgroundColor: MEETINGFLOW_COLORS.greenHighlight,
        },

        'span.needs-payment-indicator.needs-payment span': {
          backgroundColor: MEETINGFLOW_COLORS.red,
        },
      },
    },
    'details:hover': {
      backgroundColor: isDark
        ? NeutralColors.gray180
        : MEETINGFLOW_COLORS.purpleGreyLight,

      '> summary': {
        span: {
          backgroundColor: isDark
            ? NeutralColors.gray200
            : MEETINGFLOW_COLORS.purpleGreyLight,
        },
      },
    },

    'details[open]': {
      backgroundColor: isDark
        ? NeutralColors.gray200
        : MEETINGFLOW_COLORS.purpleGreyLight,
      marginBottom: '1rem',
      paddingBottom: '1rem',

      '> summary': {
        span: {
          backgroundColor: isDark
            ? NeutralColors.gray200
            : MEETINGFLOW_COLORS.purpleGrey,
          padding: '.25rem',
          fontWeight: FontWeights.semibold,
        },
      },
    },

    'details.header': {
      marginBottom: '.25rem',
      summary: {
        fontWeight: FontWeights.semibold,
        borderBottom: `.5px solid ${MEETINGFLOW_COLORS.purpleGrey}`,
        paddingBottom: '.25rem',
      },
    },
  });

  return (
    <div className={billingListClass}>
      <div className="controls">
        <Checkbox
          label="Only organizations requiring payment"
          onChange={() => setFilterToNeedsPayment(!filterToNeedsPayment)}
        ></Checkbox>
        <PrimaryButton
          style={{
            marginLeft: '1.5rem',
          }}
          className="export-link"
          onClick={async () => {
            if (!organizations?.length) {
              return;
            }

            const json2csv = await import('@json2csv/plainjs');
            const fileSaver = await import('file-saver');

            const csvColumns = [
              { name: 'Name', fieldName: 'organizationName' },
              { name: 'Slug', fieldName: 'organizationSlug' },
              { name: 'Organization ID', fieldName: 'organizationId' },
              { name: 'Type', fieldName: 'organizationType' },
              { name: 'Stripe Subscription', fieldName: 'subscription' },
              { name: 'Needs payment', fieldName: 'needsPayment' },
              {
                name: 'Total billable user count',
                fieldName: 'totalBillableUsers',
              },
              { name: 'ADMIN user count', fieldName: 'adminUserCount' },
              { name: 'CREATOR user count', fieldName: 'creatorUserCount' },
              {
                name: 'COLLABORATOR user count',
                fieldName: 'collaboratorUserCount',
              },
              { name: 'GUEST user count', fieldName: 'guestUserCount' },
              {
                name: 'Call recording count this month',
                fieldName: 'callRecordingCountThisMonth',
              },
              {
                name: 'Call recording hours this month',
                fieldName: 'callRecordingHoursThisMonth',
              },
              {
                name: 'Call recording count lifetime',
                fieldName: 'callRecordingCountLifetime',
              },
              {
                name: 'Call recording hours lifetime',
                fieldName: 'callRecordingHoursLifetime',
              },
              { name: 'Total Meetingflows', fieldName: 'totalMeetingflows' },
            ];

            const parser = new json2csv.Parser({
              transforms: [escapeCsvFormulas],
            });

            const csvContent = parser.parse(
              organizations.map((org) => ({
                organizationId: org.organizationId,
                organizationName: org.organizationName,
                organizationSlug: org.organizationSlug,
                organizationType: org.organizationType,
                subscription: org.subscription?.plan,
                needsPayment: org.needsPayment,
                totalBillableUsers: org.users.filter(
                  (u) => u.role === 'ADMIN' || u.role === 'CREATOR',
                ).length,
                adminUserCount: org.users.filter((u) => u.role === 'ADMIN')
                  .length,
                creatorUserCount: org.users.filter((u) => u.role === 'CREATOR')
                  .length,
                collaboratorUserCount: org.users.filter(
                  (u) => u.role === 'COLLABORATOR',
                ).length,
                guestUserCount: org.users.filter((u) => u.role === 'GUEST')
                  .length,
                callRecordingCountThisMonth:
                  org.callRecording.currentMonth.totalRecordingCount,
                callRecordingHoursThisMonth:
                  org.callRecording.currentMonth.totalRecordingTimeHours,
                callRecordingCountLifetime:
                  org.callRecording.lifetime.totalRecordingCount,
                callRecordingHoursLifetime:
                  org.callRecording.lifetime.totalRecordingTimeHours,
                totalMeetingflows: org.totalMeetingflows,
              })),
            );
            const csvBlob = new Blob([csvContent], { type: 'text/csv' });

            fileSaver.saveAs(
              csvBlob,
              `billing_admin_${DateTime.now().toISODate({
                format: 'basic',
              })}.csv`,
            );
          }}
        >
          Export to CSV
        </PrimaryButton>
        <PrimaryButton
          style={{
            marginLeft: '1.5rem',
          }}
          onClick={async () => {
            syncStripeUsage(await getAccessTokenSilently());
          }}
        >
          Sync Usage To Stripe
        </PrimaryButton>
        {import.meta.env.DEV && (
          <PrimaryButton
            style={{
              marginLeft: '1.5rem',
            }}
            onClick={async () => {
              removeDevUserSubscriptions(await getAccessTokenSilently());
            }}
          >
            💣 Delete Dev Subscriptions
          </PrimaryButton>
        )}
      </div>

      <details className="header">
        <summary>
          <span>Name</span>
          <span>Slug</span>
          <span>ID</span>
          <span>Type</span>
          <span>Stripe Subscription</span> <span>Needs payment</span>
        </summary>
      </details>

      {organizations.map((org, index) => (
        <details key={`org_${index}`}>
          <summary>
            <span>{org.organizationName}</span>
            <span>{org.organizationSlug}</span>
            <span>{org.organizationId}</span>
            <span>{org.organizationType}</span>
            <span>
              {org.subscription
                ? `${org.subscription?.status} - ${org.subscription?.plan} - ${org.subscription?.billingPeriod} - ${org.subscription?.quantity} seats`
                : 'No subscription found'}
            </span>{' '}
            <span
              className={`needs-payment-indicator ${
                org.needsPayment ? 'needs-payment' : 'paid'
              }`}
            >
              <span>{org.needsPayment ? 'Yes' : 'No'}</span>
            </span>
          </summary>
          <p style={{ paddingLeft: '1rem' }}>
            Needs payment:{' '}
            {org.needsPayment ? (
              <span style={{ backgroundColor: MEETINGFLOW_COLORS.red }}>
                Yes
              </span>
            ) : (
              <span
                style={{ backgroundColor: MEETINGFLOW_COLORS.greenHighlight }}
              >
                No
              </span>
            )}{' '}
            | Reason: {org.needsPaymentReason}
          </p>
          <details style={{ paddingLeft: '1rem' }}>
            <summary>Change organization type (be careful!)</summary>
            <p style={{ paddingLeft: '1rem' }}>
              {org.organizationName} | {org.organizationSlug} | id:{' '}
              {org.organizationId} | Current organization type:{' '}
              {org.organizationType}
            </p>
            <p style={{ paddingLeft: '1rem' }}>
              <PrimaryButton
                text="Trial"
                onClick={async () =>
                  updateOrgType(
                    org.organizationId,
                    'TRIAL',
                    await getAccessTokenSilently(),
                  )
                }
              ></PrimaryButton>{' '}
              <PrimaryButton
                text="Free"
                onClick={async () =>
                  updateOrgType(
                    org.organizationId,
                    'FREE',
                    await getAccessTokenSilently(),
                  )
                }
              ></PrimaryButton>{' '}
              <PrimaryButton
                text="Paid"
                onClick={async () =>
                  updateOrgType(
                    org.organizationId,
                    'PAID',
                    await getAccessTokenSilently(),
                  )
                }
              ></PrimaryButton>{' '}
              <PrimaryButton
                text="VIP"
                onClick={async () =>
                  updateOrgType(
                    org.organizationId,
                    'VIP',
                    await getAccessTokenSilently(),
                  )
                }
              ></PrimaryButton>{' '}
              <PrimaryButton
                text="Internal"
                onClick={async () =>
                  updateOrgType(
                    org.organizationId,
                    'INTERNAL',
                    await getAccessTokenSilently(),
                  )
                }
              ></PrimaryButton>
            </p>
          </details>
          <p style={{ paddingLeft: '1rem' }}>
            Call recording for this calendar month. Recording count:{' '}
            {org.callRecording.currentMonth.totalRecordingCount.toFixed(0)} |
            Recording hours:{' '}
            {org.callRecording.currentMonth.totalRecordingTimeHours.toFixed(1)}
          </p>
          <p style={{ paddingLeft: '1rem' }}>
            Lifetime call recording. Recording count:{' '}
            {org.callRecording.lifetime.totalRecordingCount.toFixed(0)} |
            Recording hours:{' '}
            {org.callRecording.lifetime.totalRecordingTimeHours.toFixed(1)}
          </p>
          <p style={{ paddingLeft: '1rem' }}>
            Entitlements: {org.entitlements.map((e) => `${e}, `)}
          </p>
          <p style={{ paddingLeft: '1rem' }}>
            Billable Meeting Plans: {org.totalMeetingflows}
          </p>

          <details style={{ paddingLeft: '1rem' }}>
            <summary>
              Users - Total Billable:{' '}
              {
                org.users.filter(
                  (u) => u.role === 'ADMIN' || u.role === 'CREATOR',
                ).length
              }{' '}
              - ADMIN: {org.users.filter((u) => u.role === 'ADMIN').length} -
              CREATOR: {org.users.filter((u) => u.role === 'CREATOR').length} -
              COLLABORATOR:{' '}
              {org.users.filter((u) => u.role === 'COLLABORATOR').length} -
              GUEST: {org.users.filter((u) => u.role === 'GUEST').length}
            </summary>
            <ul>
              {org.users.map((user, userIndex) => (
                <li key={`userorg_${index}_${userIndex}`}>
                  User ID: {user.userId}, Name: {user.name || 'N/A'}, Email:{' '}
                  {user.email}, Role: {user.role}, OrgMembership Created:{' '}
                  {user.organizationMembershipCreated}, OrgMembership Updated:{' '}
                  {user.organizationMembershipUpdated}
                </li>
              ))}
            </ul>
          </details>
        </details>
      ))}
    </div>
  );
}
