import {
  DefaultButton,
  DetailsList,
  FontSizes,
  FontWeights,
  IColumn,
  PrimaryButton,
  SelectionMode,
  Text,
} from '@fluentui/react';
import { OrganizationSuggestionButton } from '../OrganizationSuggestionButton';
import { PageLayout } from '../Layouts/PageLayout';
import { StyledDetailsList } from '../StyledDetailsList';
import { Card } from '../Card';
import { useAuth0 } from '@auth0/auth0-react';
import { EMPTY_ARRAY } from '../../Constants';
import { useMemo } from 'react';
import { OrganizationSuggestion } from '@meetingflow/common/Api/data-contracts';
import { inflect } from 'inflection';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { titleCase } from '@meetingflow/common/StringHelpers';

export type WelcomeSuggestionsProps = {
  organizationSlug?: string;
  suggestions: Pick<
    OrganizationSuggestion,
    'id' | 'name' | 'slug' | 'domainRules' | 'pendingRequests'
  >[];
  onJoinOrg: (slug: string) => Promise<unknown>;
  onRequestAccess: (slug: string) => Promise<unknown>;
  onCancelRequest: () => Promise<unknown>;
  onCreateOwnOrganization: () => void;
};

export const WelcomeSuggestions = ({
  suggestions,
  onJoinOrg,
  onRequestAccess,
  onCancelRequest,
  onCreateOwnOrganization,
}: WelcomeSuggestionsProps) => {
  const { user } = useAuth0();

  const inviteColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'workspace',
        name: 'Workspace Name',
        minWidth: 40,
        maxWidth: 250,
        fieldName: 'organization.name',
        onRender: (suggestion: OrganizationSuggestion) => (
          <span
            style={{
              fontWeight: FontWeights.semibold,
              color: MEETINGFLOW_COLORS.white,
            }}
          >
            {suggestion.name}
          </span>
        ),
      },
      {
        key: 'orgId',
        name: 'Workspace URL Identifier',
        minWidth: 180,
        maxWidth: 180,
        fieldName: 'organization.slug',
        onRender: (suggestion: OrganizationSuggestion) => (
          <>{suggestion.slug}</>
        ),
      },
      {
        key: 'role',
        name: 'Your Role',
        minWidth: 180,
        maxWidth: 180,
        fieldName: 'organization.domainRules',
        onRender: (suggestion: OrganizationSuggestion) => (
          <>{titleCase(suggestion.domainRules[0]?.roleType || '')}</>
        ),
      },
      {
        key: 'accept',
        name: 'Join Workspace',
        minWidth: 48,
        headerClassName: 'right',
        onRender: (suggestion: OrganizationSuggestion) => (
          <div
            className="welcome-wizard-form"
            style={{ margin: 0, padding: 0 }}
          >
            <div
              className="controls"
              style={{ marginTop: 0, border: 0, paddingTop: 0 }}
            >
              <OrganizationSuggestionButton
                key={suggestion.id}
                suggestion={suggestion}
                onJoinOrg={onJoinOrg}
                onRequestAccess={onRequestAccess}
                onCancelRequest={onCancelRequest}
              />
            </div>
          </div>
        ),
      },
    ],
    [onCancelRequest, onJoinOrg, onRequestAccess],
  );

  return (
    <div className="welcome-wizard-form">
      {suggestions.length ? (
        <>
          <DetailsList
            items={suggestions || EMPTY_ARRAY}
            columns={inviteColumns}
            selectionMode={SelectionMode.none}
            compact
            styles={{
              root: {
                backgroundColor: 'transparent',

                '.right .ms-DetailsHeader-cellTitle': {
                  textAlign: 'right',
                  justifyContent: 'flex-end',
                },

                '.ms-DetailsHeader , .ms-DetailsRow': {
                  borderBottom: `.5px solid ${MEETINGFLOW_COLORS.purpleMediumDark} !important`,
                  padding: 0,
                },

                '.ms-DetailsHeader , .ms-DetailsHeader-cell, .ms-DetailsRow, .ms-DetailsRow-cell':
                  {
                    fontSize: FontSizes.medium,
                    height: 'auto',
                    backgroundColor: 'transparent !important',
                  },
              },
            }}
          />
        </>
      ) : (
        <Text block style={{ fontStyle: 'italic' }}>
          You have no outstanding received invites.
        </Text>
      )}

      <div
        className="controls"
        style={{
          borderTop: 0,
          justifyContent: 'flex-start',
          columnGap: '2rem',
        }}
      >
        <div>
          <Text
            block
            variant="mediumPlus"
            style={{
              fontWeight: FontWeights.semibold,
            }}
          >
            Or, create your own.
          </Text>
          <Text block style={{}}>
            If the existing workspaces above aren't what you're looking for, you
            can create your own to get started.
          </Text>
        </div>
        <PrimaryButton
          onClick={onCreateOwnOrganization}
          style={{ alignSelf: 'flex-end', flex: '1 1 auto' }}
        >
          Create a new Workspace
        </PrimaryButton>
      </div>
    </div>
  );
};
