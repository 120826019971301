import { useAuth0 } from '@auth0/auth0-react';
import { CompanyRecentPlansQuery } from '../../../QueryNames';
import { MeetingflowsApiClient } from '../../../Services/NetworkCommon';
import { useQuery } from 'react-query';
import { PlanReaderList } from './PlanReader';
import { Contact } from '@meetingflow/common/Api/data-contracts';

export interface CompanyRecentPlansListProps {
  organizationSlug: string;
  companyId: number;
  currentMeetingPlanId?: string;
  maxCount?: number;
  onContactClick: (c: Contact['id']) => void;
  color: string;
}
export const CompanyRecentPlansList = ({
  organizationSlug,
  currentMeetingPlanId,
  companyId,
  maxCount,
  onContactClick,
  color,
}: CompanyRecentPlansListProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: relatedPlansData,
    isLoading: relatedPlansLoading,
    refetch: refetchRelatedPlans,
  } = useQuery(
    CompanyRecentPlansQuery(
      organizationSlug!,
      companyId.toString(),
      currentMeetingPlanId,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      const companyPlansResult = await MeetingflowsApiClient.listPlans(
        { organizationSlug, company: companyId, limit: maxCount },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return companyPlansResult;
    },
  );

  return (
    <PlanReaderList
      meetingPlans={relatedPlansData?.data || []}
      refetchMeetingflows={refetchRelatedPlans}
      organizationSlug={organizationSlug}
      loading={relatedPlansLoading}
      onContactClick={onContactClick}
      containerPadding={'0'}
      color={color}
    />
  );
};
