import toast from 'react-hot-toast';
import { BaseEditor, Editor, Element, Path, Range, Transforms } from 'slate';
import {
  uploadFileToOrg,
  uploadFileToPlan,
} from '../../../Helpers/FileHelpers';
import { isDocXHTML } from '../../../Helpers/SlateHelpers';
import { isExternalImageUrl } from '../../../Helpers/URLHelpers';
import {
  getNextSiblingBlock,
  getParentBlock,
  getPreviousSiblingBlock,
  insertImageNode,
} from '../Helpers/EditorHelpers';

const SUPPORTED_MIME_TYPES = [
  'image/gif',
  'image/png',
  'image/webp',
  'image/jpeg',
  'image/bmp',
  'image/tiff',
  'image/svg+xml',
  'image/vnd.microsoft.icon',
];

export interface ImageEditor extends BaseEditor {
  insertImage: (data: File | string) => void;
}

export const withImages = <T extends Editor>(
  editor: T,
  getAccessToken: () => Promise<string>,
  organizationSlug: string,
  meetingPlanId?: string,
): T & ImageEditor => {
  const {
    isVoid,
    isInline,
    insertData,
    insertBreak,
    insertSoftBreak,
    deleteBackward,
    deleteForward,
    deleteFragment,
    normalizeNode,
  } = editor;

  const insertImage = (data: File | string) => {
    if (data instanceof File) {
      toast.promise(
        (async () => {
          const token = await getAccessToken();

          const uploadedUrl = await (meetingPlanId
            ? uploadFileToPlan(token, organizationSlug, meetingPlanId, data)
            : uploadFileToOrg(token, organizationSlug, data));

          insertImageNode(editor, uploadedUrl, data.type);
        })(),
        {
          loading: 'Uploading image',
          success: 'Successfully uploaded image',
          error: (err) => {
            console.error({
              fileType: data.type,
              fileSize: data.size,
              err,
            });
            return 'Failed to upload image';
          },
        },
      );
    } else if (isExternalImageUrl(data)) {
      insertImageNode(editor, data);
    }
    return;
  };

  editor.isVoid = (element: Element) => {
    return element.type === 'image' || isVoid(element);
  };

  editor.isInline = (element) => isInline(element);

  editor.insertData = (data: DataTransfer): void => {
    let imageFile: File | null = null;
    for (const file of data.files) {
      if (SUPPORTED_MIME_TYPES.includes(file.type)) {
        imageFile = file;
        break;
      }
      continue;
    }

    if (!imageFile) {
      for (const item of data.items) {
        if (SUPPORTED_MIME_TYPES.includes(item.type)) {
          imageFile = item.getAsFile();
          if (imageFile) break;
        }
      }
    }

    const textData = data.getData('text/plain');
    const htmlData = data.getData('text/html');

    if (textData && isExternalImageUrl(textData)) {
      return insertImage(textData);
    } else if (!!imageFile && !isDocXHTML(htmlData)) {
      return insertImage(imageFile);
    } else {
      return insertData(data);
    }
  };

  editor.insertBreak = () => {
    const { selection } = editor;
    const match = getParentBlock(editor, { type: 'image' });

    // If an image element is selected and the user hit enter
    if (selection && Range.isCollapsed(selection) && match) {
      const [, path] = match;
      const sibling = getNextSiblingBlock(editor);
      // If there is no element after the image, insert a paragraph
      if (!sibling) {
        Transforms.insertNodes(
          editor,
          { type: 'paragraph', children: [{ text: '' }] },
          { at: Path.next(path), select: true },
        );
        return;
      }
    }

    insertBreak();
  };

  editor.insertSoftBreak = () => {
    const { selection } = editor;
    const match = getParentBlock(editor, { type: 'image' });

    // If an image element is selected and the user hit shift+enter
    if (selection && Range.isCollapsed(selection) && match) {
      const [, path] = match;
      const sibling = getPreviousSiblingBlock(editor, { at: path });
      // If there is no element before the image, insert a paragraph before and select
      if (!sibling) {
        Transforms.insertNodes(
          editor,
          { type: 'paragraph', children: [{ text: '' }] },
          { at: path, select: true },
        );
        return;
      }
    }
    insertSoftBreak();
  };

  editor.deleteBackward = (...args) => {
    deleteBackward(...args);
  };

  editor.deleteForward = (...args) => {
    deleteForward(...args);
  };

  editor.deleteFragment = (...args) => {
    deleteFragment(...args);
  };

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    // If an image has no href, remove it
    if (Element.isElement(node) && node.type === 'image') {
      if (!node.href) {
        Transforms.removeNodes(editor, { at: path });
        return false;
      }
    }
    return normalizeNode(entry);
  };

  (editor as T & ImageEditor).insertImage = insertImage;

  return editor as T & ImageEditor;
};
