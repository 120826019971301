import { useAuth0, User } from '@auth0/auth0-react';
import { DateRangeShortcut } from '@blueprintjs/datetime';
import { DateRange, DateRangeInput2 } from '@blueprintjs/datetime2';
import { Checkbox, IColumn, mergeStyles, SelectionMode } from '@fluentui/react';
import { roundToPrecision } from '@meetingflow/common/NumberHelpers';
import { escapeCsvFormulas } from '@meetingflow/common/ObjectHelpers';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { EMPTY_ARRAY } from '../../Constants';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../Hooks/useOrganization';
import { useTitle } from '../../Hooks/useTitle';
import { OrganizationUsageQuery } from '../../QueryNames';
import { ReportingApiClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { AsyncLink } from '../HOC/AsyncLink';
import { ContactCard } from '../MeetingPlans/MeetingPlanAttendees';
import { StyledDetailsList } from '../StyledDetailsList';

const SIX_MONTHS_AGO = DateTime.now().minus({ months: 6 }).toJSDate();

const DatePickerShortcuts = [
  {
    label: 'Last 30 Days',
    dateRange: [
      DateTime.now().minus({ days: 30 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Month to Date',
    dateRange: [
      DateTime.now().startOf('month').startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Year to Date',
    dateRange: [
      DateTime.now().startOf('year').startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Last Month',
    dateRange: [
      DateTime.now().minus({ months: 1 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Last 3 Months',
    dateRange: [
      DateTime.now().minus({ months: 3 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Last 6 Months',
    dateRange: [
      DateTime.now().minus({ months: 6 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Last 12 Months',
    dateRange: [
      DateTime.now().minus({ months: 12 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
] satisfies DateRangeShortcut[];

export const UsageReport = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { slug: organizationSlug, isAdmin } = useOrganization();

  const { isDark } = useLightOrDarkMode();

  useTitle('Workspace - Usage');

  const parentRef = useRef<HTMLDivElement>(null);

  const [includeGuests, setIncludeGuests] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange>([
    DateTime.now().minus({ days: 30 }).startOf('day').toJSDate(),
    DateTime.now().endOf('day').toJSDate(),
  ]);

  const {
    data: userActivityData,
    isLoading: userActivityLoading,
    isFetched: userActivityFetched,
    refetch: refetchUserActivity,
  } = useQuery(OrganizationUsageQuery(organizationSlug!), async () => {
    if (!isAdmin) {
      return undefined;
    }
    const token = await getAccessTokenSilently();
    return ReportingApiClient.listUserActivity(
      {
        organizationSlug: organizationSlug!,
        includeInactive: true,
        includeGuests,
        minDate: dateRange?.[0]?.toISOString(),
        maxDate: dateRange?.[1]?.toISOString(),
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );
  });

  useEffect(() => {
    if (userActivityFetched) {
      refetchUserActivity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeGuests, dateRange]);

  const usageColumns: IColumn[] = useMemo(
    () =>
      [
        {
          key: 'name',
          name: 'User',
          fieldName: 'name',
          headerClassName: 'left-align',
          minWidth: 150,
          maxWidth: 300,
          onRender: (user: User) => (
            <div>
              {user && organizationSlug ? (
                <ContactCard
                  // @ts-ignore
                  contact={user}
                  organizationSlug={organizationSlug}
                  domain={user?.email?.split('@')[1] || ''}
                  hideBottomBorder
                  hideSocialIcons
                  disallowClick
                />
              ) : undefined}
            </div>
          ),
        },
        // comment out session info for the moment, since the data is all zeroes
        /*
        {
          key: 'numSessions',
          name: 'Sessions',
          fieldName: 'numSessions',
          onRender: (user) => <div>{user.numSessions}</div>,
        },
        */
        {
          key: 'numPlanCreates',
          name: 'Meetingflows Created',
          className: 'usage-numeric',
          headerClassName: 'right-align',
          fieldName: 'numPlanCreates',
          maxWidth: 200,
          onRender: (user) => <div>{user.numPlanCreates}</div>,
        },
        // {
        //   key: 'numPlanViews',
        //   name: 'Plan Views',
        //   fieldName: 'numPlanViews',
        //   className: 'usage-numeric',
        //   onRender: (user) => <div>{user.numPlanViews}</div>,
        // },
        {
          key: 'numPlansViewed',
          name: 'Unique Meetingflows Viewed',
          fieldName: 'numPlansViewed',
          className: 'usage-numeric',
          headerClassName: 'right-align',
          maxWidth: 200,
          onRender: (user) => <div>{user.numPlansViewed}</div>,
        },
        {
          key: 'numCallRecordings',
          name: 'Calls Recorded',
          className: 'usage-numeric',
          headerClassName: 'right-align',
          fieldName: 'numCallRecordings',
          maxWidth: 200,
          onRender: (user) => <div>{user.numCallRecordings}</div>,
        },
        {
          key: 'numHoursRecorded',
          name: 'Num Hours Recorded',
          className: 'usage-numeric',
          headerClassName: 'right-align',
          fieldName: 'numHoursRecorded',
          maxWidth: 200,
          onRender: (user) => (
            <div>{roundToPrecision(user.numHoursRecorded, 2)}</div>
          ),
        },
        // {
        //   key: 'numNonCreatedPlanViews',
        //   name: 'Non-created Plan Views',
        //   fieldName: 'numNonCreatedPlanViews',
        //   className: 'usage-numeric',
        //   onRender: (user) => <div>{user.numNonCreatedPlanViews}</div>,
        //   minWidth: 175,
        // },
        // {
        //   key: 'numNonCreatedPlansViewed',
        //   name: 'Non-created Plans Viewed',
        //   fieldName: 'numNonCreatedPlansViewed',
        //   className: 'usage-numeric',
        //   onRender: (user) => <div>{user.numNonCreatedPlansViewed}</div>,
        //   minWidth: 175,
        // },
        {
          key: 'numShares',
          name: 'Meetingflow Shares',
          fieldName: 'numShares',
          className: 'usage-numeric',
          headerClassName: 'right-align',
          maxWidth: 200,
          onRender: (user) => <div>{user.numShares}</div>,
        },
        {
          key: 'numSlackShares',
          name: 'Meetingflows Shares via Slack',
          fieldName: 'numSlackShares',
          className: 'usage-numeric',
          headerClassName: 'right-align',
          maxWidth: 200,
          onRender: (user) => <div>{user.numSlackShares}</div>,
        },
        {
          key: 'numPrepMeetingCreates',
          name: 'Prep Meetings Created',
          fieldName: 'numPrepMeetingCreates',
          className: 'usage-numeric',
          headerClassName: 'right-align',
          maxWidth: 200,
          onRender: (user) => <div>{user.numPrepMeetingCreates}</div>,
        },
        {
          key: 'numLogMeetingToCrm',
          name: 'Meetings Logged To CRM',
          fieldName: 'numLogMeetingToCrm',
          className: 'usage-numeric',
          headerClassName: 'right-align',
          maxWidth: 200,
          onRender: (user) => <div>{user.numLogMeetingToCrm}</div>,
        },
        {
          key: 'numUpdateCrmObject',
          name: 'CRM Object Updates',
          fieldName: 'numUpdateCrmObject',
          className: 'usage-numeric right-align last-column',
          headerClassName: 'right-align last-column',
          maxWidth: 200,
          onRender: (user) => <div>{user.numUpdateCrmObject}</div>,
        },
      ] as IColumn[],
    [organizationSlug],
  );

  const usageReportWrapper = mergeStyles({
    height: 'calc(100% - 2rem)',
    width: 'auto',
    overflow: 'auto',
    margin: '1rem 0',
    padding: '1rem',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,

    '.controls': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      padding: '0',
      marginBottom: '.25rem',

      '.options': {
        display: 'flex',
        columnGap: '.5rem',

        '.timerange-date-picker': {
          boxSizing: 'border-box',
          // width: '360px',
          display: 'flex',
          columnGap: '.25rem',
          '.bp5-input-group': {
            // flexBasis: '50%',
          },
        },

        '.include-guest-checkbox': {
          boxSizing: 'border-box',

          '.ms-Checkbox-label': {
            position: 'relative',
            top: '.25rem',
          },
        },
      },

      '.export': {
        display: 'flex',

        '.export-link': {},
      },
    },
  });

  return isAdmin ? (
    <div className={usageReportWrapper}>
      <div ref={parentRef}>
        <div className="controls">
          <div className="options">
            <DateRangeInput2
              className={classNames(
                'timerange-date-picker',
                'blueprint-component',
                isDark ? 'bp5-dark' : undefined,
              )}
              value={dateRange}
              shortcuts={DatePickerShortcuts}
              onChange={setDateRange}
              startInputProps={{ name: 'StartInput' }}
              endInputProps={{ name: 'EndInput' }}
              parseDate={(input) =>
                DateTime.fromMillis(Date.parse(input)).toJSDate()
              }
              formatDate={(date) =>
                DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT)
              }
            />

            <Checkbox
              label="Include Workspace Guests"
              className="include-guest-checkbox"
              checked={includeGuests}
              onChange={(_e, checked) => setIncludeGuests(!!checked)}
            />
          </div>

          <div className="export">
            <AsyncLink
              className="export-link"
              onClick={async () => {
                if (!userActivityData?.data?.users?.length) {
                  return;
                }

                const json2csv = await import('@json2csv/plainjs');
                const fileSaver = await import('file-saver');

                const fields = usageColumns.map((column) => ({
                  label: column.name,
                  value: column.fieldName || column.key,
                }));
                fields.splice(1, 0, { label: 'Email', value: 'email' });

                const parser = new json2csv.Parser({
                  fields,
                  transforms: [escapeCsvFormulas],
                });

                const csvContent = parser.parse(userActivityData.data.users);
                const csvBlob = new Blob([csvContent], { type: 'text/csv' });

                fileSaver.saveAs(
                  csvBlob,
                  `${organizationSlug}_usage_${DateTime.now().toISODate({
                    format: 'basic',
                  })}.csv`,
                );
              }}
            >
              Export to CSV
            </AsyncLink>
          </div>
        </div>
        <StyledDetailsList
          key={'userActivityList'}
          enableShimmer={userActivityLoading}
          shimmerLines={25}
          items={userActivityData?.data.users || EMPTY_ARRAY}
          selectionMode={SelectionMode.none}
          columns={usageColumns}
          isHeaderVisible={true}
          compact
          className="long-headers"
        />
      </div>
    </div>
  ) : (
    <div>You must be an workspace administrator to access this page.</div>
  );
};

export default UsageReport;
