import { IconButton, mergeStyles, Text, TextField } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { parseLocaleFloat } from '../../../Helpers/NumberHelpers';
import { DealRoomCommandBarButton } from './DealRoomButton';

type DealRoomLabelledCurrencyValueProps = {
  className?: string;
  label: string;
  value: number | null;
  currencySymbol?: string;
  canEdit?: boolean;
  fractionDigits?: number;
  changeTrigger?: 'blur' | 'change';
  onChange?: (newValue: number | null) => Promise<unknown>;
  viewsNumber?: number;
};

const acceptIcon = { iconName: 'Accept' };
const editIcon = { iconName: 'Edit' };

export const DealRoomLabelledCurrencyValue = ({
  className,
  label,
  value,
  canEdit,
  currencySymbol = '$',
  changeTrigger,
  fractionDigits = 2,
  onChange,
  viewsNumber,
}: DealRoomLabelledCurrencyValueProps) => {
  const [editing, { setTrue: setEditing, setFalse: setNotEditing }] =
    useBoolean(false);
  const [stringValue, setStringValue] = useState<string>('');

  useEffect(() => {
    if (editing) {
      setStringValue(value?.toLocaleString() ?? '');
    } else {
      setStringValue('');
    }
  }, [editing, value]);

  const styles = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: DEALROOMS_COLORS.financialPrimaryGray,
    border: `1px solid ${DEALROOMS_COLORS.financialPrimaryGray}`,
    borderRadius: '8px',
    padding: '1rem',
    gap: '0.5rem',
    width: '170px',

    '.label': {
      fontSize: '.9375rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
      textAlign: 'left',
      color: DEALROOMS_COLORS.themePrimary,
    },

    '.text-value': {
      fontSize: '2rem',
      fontWeight: '400',
      lineHeight: '2.5rem',
      textAlign: 'left',
      color: DEALROOMS_COLORS.themePrimary,
    },

    '.value': {
      margin: '.25rem 0',

      '.ms-TextField-fieldGroup': {
        borderWidth: '0 0 2px 0',
        borderStyle: 'solid',
        borderColor: DEALROOMS_COLORS.themeSecondary,
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        borderRadius: '4px',

        ':after': {
          border: 'none',
          outline: `1px solid ${DEALROOMS_COLORS.themeSecondary}`,
          outlineOffset: '3px',
        },
      },

      '.ms-TextField-prefix': {
        color: DEALROOMS_COLORS.darkerGray,
        backgroundColor: 'transparent',
      },

      input: {
        color: DEALROOMS_COLORS.darkerGray,
      },
    },

    '.edit-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '.edit': {
        borderRadius: '4px',
        i: {
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },

      '.save': {
        border: 'none',
        backgroundColor: 'transparent',
        ':hover': {
          backgroundColor: DEALROOMS_COLORS.neutralLighter,
        },
        i: {
          color: DEALROOMS_COLORS.themePrimary,
        },
      },

      '.views': {
        marginRight: 'auto',
        fontSize: '0.8125rem',
        fontWeight: '400',
        lineHeight: '2rem',
        color: DEALROOMS_COLORS.themePrimary,
      },
    },
  });

  return (
    <div className={classNames(styles, className)}>
      <Text variant="xSmall" block className="label">
        {label}
      </Text>
      {editing ? (
        <TextField
          className="value"
          autoFocus
          readOnly={!editing}
          prefix={currencySymbol}
          value={stringValue}
          onChange={(_e, newValue) => {
            if (newValue && !isNaN(parseLocaleFloat(newValue))) {
              if (changeTrigger === 'change') {
                onChange?.(parseLocaleFloat(newValue));
              }
              setStringValue(parseLocaleFloat(newValue).toLocaleString());
            } else {
              setStringValue(newValue || '');
            }
          }}
          onBlur={() => {
            if (changeTrigger === 'blur') {
              onChange?.(
                !isNaN(parseLocaleFloat(stringValue))
                  ? parseLocaleFloat(stringValue)
                  : null,
              );
              setNotEditing();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onChange?.(
                !isNaN(parseLocaleFloat(stringValue))
                  ? parseLocaleFloat(stringValue)
                  : null,
              );
              setNotEditing();
            } else if (e.key === 'Escape') {
              setNotEditing();
            }
          }}
        />
      ) : (
        <Text variant="large" block className="text-value">
          {value === null
            ? 'N/A'
            : `${currencySymbol || '$'}${value.toFixed(fractionDigits)}`}
        </Text>
      )}
      <div className="edit-container">
        <div className="views">
          {typeof viewsNumber === 'number' ? viewsNumber : 'N/A'} views
        </div>
        {canEdit &&
          (editing ? (
            <DealRoomCommandBarButton
              text="Save"
              customClasses="save"
              buttonStyleType="COMMAND_BAR"
              iconProps={acceptIcon}
              onClick={() => {
                onChange?.(
                  !isNaN(parseLocaleFloat(stringValue))
                    ? parseLocaleFloat(stringValue)
                    : null,
                );
                setNotEditing();
              }}
            />
          ) : (
            <IconButton
              iconProps={editIcon}
              className="edit"
              onClick={setEditing}
            />
          ))}
      </div>
    </div>
  );
};
