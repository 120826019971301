import { mergeStyles } from '@fluentui/react';
import { DealRoomImageArtifact } from '@meetingflow/common/Api/data-contracts';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';

export type ImageArtifactCardProps = BaseArtifactCardProps &
  Pick<
    DealRoomImageArtifact,
    'fileName' | 'fileUrl' | 'thumbnailFileName' | 'thumbnailUrl' | 'mimeType'
  >;

export const ImageArtifactCard = ({
  orgSlug,
  dealRoomId,
  fileName,
  fileUrl,
  thumbnailFileName,
  thumbnailUrl,
  mimeType,
  ...rest
}: ImageArtifactCardProps) => {
  const imageStyles = mergeStyles({
    height: '100%',
    objectFit: 'cover',
  });

  return (
    <BaseArtifactCard
      className="image-artifact"
      orgSlug={orgSlug}
      dealRoomId={dealRoomId}
      {...rest}
    >
      <ImageWithFallback
        src={thumbnailUrl || fileUrl}
        fallbackSrc={thumbnailUrl ? fileUrl : undefined}
        alt={fileName}
        className={imageStyles}
        fallback={
          <ArtifactIconPreview
            type="IMAGE"
            fileExtension={fileName.split('.').pop()}
            mimeType={mimeType}
          />
        }
      />
    </BaseArtifactCard>
  );
};
