import { useAuth0 } from '@auth0/auth0-react';
import {
  ComboBox,
  FontIcon,
  FontSizes,
  FontWeights,
  IComboBox,
  IComboBoxOption,
  mergeStyles,
  NeutralColors,
  SelectableOptionMenuItemType,
  Spinner,
} from '@fluentui/react';
import {
  CalendarEvent,
  Company,
  Contact,
  Meetingflow,
} from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isIOS, isMacOs } from 'react-device-detect';
import { useQuery } from 'react-query';
import { useMatch, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { EMPTY_ARRAY } from '../../Constants';
import { activeChords } from '../../Helpers/KeyboardEventHelpers';
import useBreakpoints from '../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../Hooks/useOrganization';
import { useRouteChange } from '../../Hooks/useRouteChange';
import {
  OrganizationGlobalSearchQuery,
  OrganizationSearchQuery,
} from '../../QueryNames';
import { SearchApiClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { MeetingflowCard } from '../MeetingPlans/MeetingflowCard';
import { CompanyCard, ContactCard } from '../MeetingPlans/MeetingPlanAttendees';
import EventCard from './Library/EventCard';

const isMacOrIOS = isMacOs || isIOS;

const PAGE_SIZE = 3;

const SEARCH_ROUTE_PATTERN = {
  path: '/organization/:organizationSlug/search',
  end: false,
};

interface GlobalSearchProps {
  onFocusSearch?: () => void;
  onBlurSearch?: () => void;
}

export const GlobalSearch = ({
  onFocusSearch,
  onBlurSearch,
}: GlobalSearchProps) => {
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const { getAccessTokenSilently } = useAuth0();
  const { slug: organizationSlug, name } = useOrganization();
  const navigate = useNavigate();
  const { isDark } = useLightOrDarkMode();
  const globalSearchRef = React.useRef<HTMLDivElement>(null);
  const globalSearchComboBoxRef = React.useRef<IComboBox>(null);
  const appInsights = useAppInsightsContext();
  const [pendingSelectionKey, setPendingSelectionKey] = useState<
    string | undefined
  >(undefined);
  const breakpoints = useBreakpoints();

  const isOnSearchResultsPage = !!useMatch(SEARCH_ROUTE_PATTERN);

  const searchActive = searchTerm !== undefined;

  const {
    data: searchResults,
    isLoading: searchResultsLoading,
    isRefetching: searchResultsRefetching,
    refetch: refetchSearch,
  } = useQuery(
    OrganizationGlobalSearchQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return SearchApiClient.search(
        {
          organizationSlug: organizationSlug!,
          q: searchTerm,
          limit: 3,
          contactLimit: 3,
          companyLimit: 3,
          eventLimit: 3,
          skip: 0,
          includeEvents: true,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // Disabled if we are on the search results page, it will do its own search that will include facets
      enabled: searchTerm !== undefined && !isOnSearchResultsPage,
    },
  );

  const { resultQuery, events, meetingflows, contacts, companies } =
    useMemo(() => {
      if (!searchResults?.data) {
        return {
          resultQuery: undefined,
          totalResultCount: undefined,
          pages: 0,
          companyFacets: [],
          contactFacets: [],
          events: [],
          meetingflows: [] as Meetingflow[],
          contacts: [] as Contact[],
          companies: [] as Company[],
        };
      }

      const results = searchResults?.data;

      appInsights.trackEvent({
        name: 'GLOBAL_SEARCH_RESULTS',
        properties: {
          organizationSlug,
          query: results.query,
          eventCount: results.events.length,
          totalContactCount: results.contacts.count,
          contactCount: results.contacts.results.length,
          totalCompanyCount: results.companies.count,
          companyCount: results.companies.results.length,
          totalMeetingflowCount: results.meetingflows.count,
          meetingflowCount: results.meetingflows.results.length,
        },
      });

      return {
        resultQuery: results.query,
        totalResultCount: results.meetingflows.count,
        pages: results.meetingflows.count
          ? Math.floor(results.meetingflows.count / PAGE_SIZE)
          : 0,
        companyFacets: results.meetingflows.facets?.companies || [],
        contactFacets: results.meetingflows.facets?.contacts || [],
        events: results.events || [],
        meetingflows: results.meetingflows.results || [],
        contacts: results.contacts.results || [],
        companies: results.companies.results || [],
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchResults?.data]);

  const urlQuery = useMemo(() => {
    return searchParams.has('q') ? searchParams.get('q') : null;
  }, [searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(debounce(refetchSearch, 500), [
    refetchSearch,
  ]);

  useEffect(() => {
    if (searchTerm !== undefined && !isOnSearchResultsPage) {
      debouncedRefetch({
        queryKey: OrganizationSearchQuery(organizationSlug!, searchTerm),
      });
    }
  }, [debouncedRefetch, isOnSearchResultsPage, organizationSlug, searchTerm]);

  const focusSearch = useCallback(() => {
    setSearchTerm(urlQuery || '');
    onFocusSearch?.();
    setTimeout(() => {
      globalSearchComboBoxRef.current?.focus(true);
    }, 500);
  }, [urlQuery, onFocusSearch]);

  const blurSearch = useCallback(() => {
    setSearchTerm(undefined);
    onBlurSearch?.();
    setPendingSelectionKey(undefined);
    globalSearchComboBoxRef.current?.dismissMenu();
  }, [onBlurSearch]);

  useEffect(() => {
    const focusSearchOnKeystroke = (e: KeyboardEvent) => {
      const { ctrlChord, cmdChord } = activeChords(e);
      if (
        ((isMacOrIOS && cmdChord) || (!isMacOrIOS && ctrlChord)) &&
        e.key === 'k'
      ) {
        e.preventDefault();
        focusSearch();
      }
    };

    window.addEventListener('keydown', focusSearchOnKeystroke);
    return () => {
      window.removeEventListener('keydown', focusSearchOnKeystroke);
    };
  }, [focusSearch, globalSearchComboBoxRef]);

  useRouteChange(SEARCH_ROUTE_PATTERN, (direction) => {
    if (direction === 'from') {
      blurSearch();
    }
  });

  const searchClass = mergeStyles({
    position: 'relative',
    width: '100%',
    maxWidth: searchActive ? 'auto' : '2rem',
    display: 'inline-block',
    transition: '.3s ease-in-out all',
    textAlign: 'right',

    '.search-icon': {
      opacity: searchActive ? '1' : '1',
      height: '2rem',
      width: '2rem',
      position: 'absolute',
      top: 0,
      // right: searchActive ? 0 : undefined,
      textAlign: 'center',
      display: 'block',
      backgroundColor: 'transparent',
      fontSize: '1.1rem',
      fontWeight: FontWeights.semibold,
      lineHeight: '2rem',
      transition: '.3s ease-in-out all',
      borderRadius: '50%',
      cursor: 'pointer',
      color: isDark ? NeutralColors.white : MEETINGFLOW_COLORS.orange,
      zIndex: 500,

      ':hover': {
        backgroundColor: MEETINGFLOW_COLORS.orange,
        color: isDark ? NeutralColors.white : MEETINGFLOW_COLORS.white,
      },
    },
  });

  const options = useMemo(() => {
    setPendingSelectionKey(undefined);
    if (
      searchResultsLoading ||
      searchResultsRefetching ||
      !searchTerm ||
      isOnSearchResultsPage
    ) {
      return EMPTY_ARRAY;
    }
    let optionList = [
      {
        key: 'events-header',
        text: 'Calendar Events',
        itemType: SelectableOptionMenuItemType.Header,
      },
      ...events.map((event: CalendarEvent) => ({
        key: `event-${event.iCalUid}`,
        text: event.title,
        data: event,
      })),
      {
        key: 'events-divider',
        text: '-',
        itemType: SelectableOptionMenuItemType.Divider,
      },

      {
        key: 'meetingflows-header',
        text: 'Meetingflows',
        itemType: SelectableOptionMenuItemType.Header,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...meetingflows.map((meetingflow: any) => ({
        key: `meetingflow-${meetingflow.id}`,
        text: meetingflow.title,
        data: meetingflow,
      })),
      {
        key: 'meetingflows-divider',
        text: '-',
        itemType: SelectableOptionMenuItemType.Divider,
      },

      {
        key: 'companies-header',
        text: 'Companies',
        itemType: SelectableOptionMenuItemType.Header,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...companies.map((company: any) => ({
        key: `company-${company.id}`,
        text: company.name,
        data: company,
      })),
      {
        key: 'companies-divider',
        text: '-',
        itemType: SelectableOptionMenuItemType.Divider,
      },

      {
        key: 'contacts-header',
        text: 'Contacts',
        itemType: SelectableOptionMenuItemType.Header,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...contacts.map((contact: any) => ({
        key: `contact-${contact.id}`,
        text: contact.name || contact.email || 'UNKNOWN',
        data: contact,
      })),
    ] as IComboBoxOption[];

    if (events.length === 0) {
      optionList = optionList.filter(
        (option) =>
          option.key !== 'events-header' && option.key !== 'events-divider',
      );
    }

    if (meetingflows.length === 0) {
      optionList = optionList.filter(
        (option) =>
          option.key !== 'meetingflows-header' &&
          option.key !== 'meetingflows-divider',
      );
    }

    if (contacts.length === 0) {
      optionList = optionList.filter(
        (option) =>
          option.key !== 'contacts-header' && option.key !== 'contacts-divider',
      );
    }

    if (companies.length === 0) {
      optionList = optionList.filter(
        (option) =>
          option.key !== 'companies-header' &&
          option.key !== 'companies-divider',
      );
    }

    return optionList;
  }, [
    searchTerm,
    events,
    meetingflows,
    companies,
    contacts,
    searchResultsLoading,
    searchResultsRefetching,
    isOnSearchResultsPage,
  ]);

  const comboBoxOptionClass = mergeStyles({
    display: 'block',
    width: '100%',
    padding: 0,
  });

  const renderOption = useCallback(
    (option?: IComboBoxOption) => {
      if (!option) return null;

      const item = option?.data;

      if (!item) return null;

      const itemKey = option?.key?.toString();
      if (!itemKey) {
        return null;
      }
      const itemType =
        option?.itemType?.toString() ||
        option?.key?.toString()?.split('-')?.[0];

      if (!itemType) return null;

      // Headers
      if (itemType === '2') {
        return <>{option.text}</>;
      }

      if (itemType === 'event') {
        return (
          <div
            className={classNames(comboBoxOptionClass, 'event-option')}
            key={itemKey}
          >
            <EventCard organizationSlug={organizationSlug!} event={item} />
          </div>
        );
      }

      if (itemType === 'meetingflow') {
        return (
          <div className={comboBoxOptionClass} key={itemKey}>
            <MeetingflowCard
              organizationSlug={organizationSlug!}
              meetingflowId={item.id}
              showCallRecordingButton
              onClick={() => {
                appInsights.trackEvent({
                  name: 'CLICK_GLOBAL_SEARCH_RESULT',
                  properties: {
                    organizationSlug,
                    q: resultQuery,
                    type: 'meetingflow',
                    id: item.id,
                  },
                });
                navigate(`/organization/${organizationSlug}/plan/${item.id}`);
              }}
              hideShowConferenceJoinButton
            />
          </div>
        );
      }

      if (itemType === 'company') {
        return (
          <div className={comboBoxOptionClass} key={itemKey}>
            <CompanyCard
              organizationSlug={organizationSlug!}
              company={{
                ...item,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                domains: item.domains.map((d: any) => ({ domain: d })),
              }}
              onClick={() => {
                appInsights.trackEvent({
                  name: 'CLICK_GLOBAL_SEARCH_RESULT',
                  properties: {
                    organizationSlug,
                    q: resultQuery,
                    type: 'company',
                    id: item.id,
                  },
                });
                navigate(
                  `/organization/${organizationSlug}/library/companies/${item.id}`,
                );
              }}
            />
          </div>
        );
      }

      if (itemType === 'contact') {
        return (
          <div className={comboBoxOptionClass} key={itemKey}>
            <ContactCard
              organizationSlug={organizationSlug!}
              domain={item.emailDomain}
              contact={item}
              hideBottomBorder
              onContactClick={() => {
                appInsights.trackEvent({
                  name: 'CLICK_GLOBAL_SEARCH_RESULT',
                  properties: {
                    organizationSlug,
                    q: resultQuery,
                    type: 'contact',
                    id: item.id,
                  },
                });
                navigate(
                  `/organization/${organizationSlug}/library/contacts/${item.id}`,
                );
              }}
            />
          </div>
        );
      } else return null;
    },
    [appInsights, comboBoxOptionClass, navigate, organizationSlug, resultQuery],
  );

  const selectOption = (option: IComboBoxOption) => {
    const item = option?.data;
    if (!item) {
      return;
    }

    const itemType =
      option?.itemType?.toString() || option?.key?.toString()?.split('-')?.[0];

    if (!itemType) {
      return;
    }

    if (itemType === 'event') {
      // There are no event details pages
      // Could maybe make this press the "Create/View" button on the event card
      return;
    }

    if (itemType === 'meetingflow') {
      navigate(`/organization/${organizationSlug}/plan/${item.id}`);
    }
    if (itemType === 'company') {
      navigate(
        `/organization/${organizationSlug}/library/companies/${item.id}`,
      );
    }
    if (itemType === 'contact') {
      navigate(`/organization/${organizationSlug}/library/contacts/${item.id}`);
    }

    blurSearch();
  };

  const navigateToResultsPage = () => {
    // If there is a pending selection (because the user has moused over or pressed the down arrow key), then navigate to it
    if (pendingSelectionKey) {
      const option = options.find((o) => o.key === pendingSelectionKey);
      if (option) {
        selectOption(option);
      }
    } else {
      navigate(
        `/organization/${organizationSlug}/search?q=${encodeURIComponent(
          searchTerm || '',
        )}`,
      );
    }
  };

  return (
    <div
      id="meetingflow-global-search"
      className={searchClass}
      ref={globalSearchRef}
    >
      <FontIcon
        iconName="Search"
        className={'search-icon'}
        title={`Search ${name ?? 'your organization'} (${
          isMacOrIOS ? 'Cmd' : 'Ctrl'
        }+k)`}
        onClick={() => {
          focusSearch();
        }}
      />
      <ComboBox
        componentRef={globalSearchComboBoxRef}
        text={searchTerm}
        openOnKeyboardFocus
        allowFreeform
        useComboBoxAsMenuWidth
        autoComplete="off"
        onKeyUp={(e) => {
          e.preventDefault();
          if (e.key === 'Escape') {
            blurSearch();
          }
          if (e.key === 'Enter') {
            navigateToResultsPage();
          }
        }}
        onInputValueChange={(nv) => {
          // Uncomment if we want to have search results debounced and updated as you type
          // if (isOnSearchResultsPage) {
          //   setSearchParams(
          //     deleteValue(setValue(searchParams, 'q', nv || ''), 'page'),
          //   );
          // }
          setSearchTerm(nv || '');
        }}
        onRenderOption={renderOption}
        onRenderUpperContent={() => {
          if (isOnSearchResultsPage) {
            return null;
          }
          if (searchResultsLoading || searchResultsRefetching) {
            return <Spinner />;
          }
          if (!searchTerm?.length && options.length === 0) {
            return (
              <div className="no-results">
                Enter search terms...
                <div>
                  You can find meeting titles, people's names, company names,
                  and more.
                </div>
              </div>
            );
          }
          if (!!searchTerm?.length && !options.length) {
            return <div className="no-results">There are no results...</div>;
          }
          return null;
        }}
        onPendingValueChanged={(option) => {
          if (option) {
            setPendingSelectionKey(option.key.toString());
          }
        }}
        options={options}
        placeholder={'Search...'}
        onBlur={() => {
          blurSearch();
        }}
        calloutProps={{
          styles: {
            root: {
              opacity: searchActive ? '1' : '0',
              minHeight: '3rem',
            },
            calloutMain: {
              opacity: searchActive ? '1' : '0',
            },
          },
        }}
        styles={{
          root: {
            width: searchActive ? 'auto' : '1rem',
            minWidth: breakpoints.xl
              ? '23vw'
              : breakpoints.lg
              ? '23vw'
              : breakpoints.md
              ? '26vw'
              : '10vw',
            opacity: searchActive ? '1' : '0',
            position: 'relative',
            top: '0',
            transition: '.1s ease-in-out all',
            ':after': {
              borderColor: `${MEETINGFLOW_COLORS.orange} !important`,
            },
            button: { display: 'none' },
            borderRadius: '1rem',
          },
          input: {
            borderRadius: '1rem',
            paddingLeft: '1.5rem',
          },

          container: {
            width: '100%',
            transition: '.3s ease-in-out all',
            borderRadius: '1rem',

            '.ms-ComboBox': {
              ':after': {
                borderRadius: '1rem',
              },
            },
          },

          optionsContainerWrapper: {
            opacity: searchActive ? '1' : '0',
          },

          optionsContainer: {
            backgroundColor: isDark
              ? NeutralColors.gray200
              : MEETINGFLOW_COLORS.white,
            padding:
              !searchTerm || (searchTerm?.length && options.length === 0)
                ? '0'
                : '.25rem',

            button: {
              padding: '2px 0',
              ':after': {
                borderColor: `${MEETINGFLOW_COLORS.orange} !important`,
                borderRadius: '.25rem',
                outline: 'none !important',
              },
              ':hover': {
                backgroundColor: 'transparent',
                '.meeting-plan-create-button': {
                  i: {
                    color: MEETINGFLOW_COLORS.white,
                  },
                  ':hover': {
                    backgroundColor: MEETINGFLOW_COLORS.purpleDark,
                    i: {
                      color: MEETINGFLOW_COLORS.white,
                    },
                  },
                },
                '.meeting-plan-view-button': {
                  i: {
                    color: MEETINGFLOW_COLORS.purpleDark,
                  },
                  ':hover': {
                    backgroundColor: MEETINGFLOW_COLORS.purpleDark,
                    i: {
                      color: MEETINGFLOW_COLORS.white,
                    },
                  },
                },
              },
            },
            '.event-option': {
              cursor: 'default',
            },
            '.ms-ComboBox-header': {
              padding: '0 .25rem',
              fontSize: FontSizes.mini,
              textTransform: 'uppercase',
              lineHeight: '1.5rem',
              height: 'auto',
              color: isDark
                ? NeutralColors.gray120
                : MEETINGFLOW_COLORS.purpleDark,
              backgroundColor: isDark
                ? NeutralColors.gray190
                : MEETINGFLOW_COLORS.purpleUltraSuperLight,
            },
          },

          callout: {
            width: '100%',
            transition: '.3s ease-in-out all',
            opacity: searchActive ? '1' : '0',
            'button .ms-Button-flexContainer > span': {
              width: '100%',
              display: 'block',
            },
            '.placeholder, .no-results': {
              minHeight: '1rem',
              lineHeight: '1rem',
              padding: '.25rem .5rem',
              fontSize: FontSizes.small,
              fontWeight: FontWeights.semibold,
              color: MEETINGFLOW_COLORS.purpleMedium,

              div: {
                color: NeutralColors.gray110,
                fontWeight: FontWeights.regular,
                paddingBottom: '.25rem',
              },
            },
          },
        }}
      />
    </div>
  );
};
