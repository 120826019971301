import { useAuth0 } from '@auth0/auth0-react';
import {
  DefaultButton,
  FontWeights,
  Link,
  mergeStyles,
  PrimaryButton,
  Text,
} from '@fluentui/react';
import { useNavigate } from 'react-router';

export const WelcomeOrganizationForbidden = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();

  const singleInviteClass = mergeStyles({
    textAlign: 'center',
    width: '75%',
    margin: '0 auto',
    padding: '.5rem',
  });

  return (
    <div className={singleInviteClass}>
      <div>
        <Text
          variant="xLarge"
          style={{
            fontWeight: FontWeights.semibold,
          }}
        >
          You don't have access to this workspace.
        </Text>
      </div>

      <div>
        {' '}
        <br />
      </div>

      <PrimaryButton onClick={() => navigate(`/`)}>Go home</PrimaryButton>

      <div>
        {' '}
        <br />
      </div>

      <div>
        <Text
          variant="small"
          style={{
            fontWeight: FontWeights.regular,
          }}
        >
          You are currently logged in as <strong>{user?.email}</strong>. Wrong
          account? Click{' '}
          <a
            href={`/logout?returnTo=${encodeURIComponent(
              `${window.location.origin}?redirect=${window.location.pathname}`,
            )}`}
          >
            here
          </a>{' '}
          to switch account.
        </Text>
      </div>
    </div>
  );
};

export default WelcomeOrganizationForbidden;
