import { useEffect, useState } from 'react';
import { pickFile } from '../../Helpers/FileHelpers';
import {
  AsyncActionButton,
  AsyncDefaultButton,
  AsyncPrimaryButton,
} from '../HOC/AsyncButton';
import { FontWeights, mergeStyles } from '@fluentui/react';

interface ImageFile {
  name: string;
  size: number;
  type: string;
  url: string;
}

export type ImageUploaderProps = {
  initialPreview?: string | null;
  onSelectFile?: (uploadedFile: string) => Promise<unknown>;
  onClearImage?: () => Promise<unknown>;
};

const ImageUploader = ({
  initialPreview,
  onSelectFile,
  onClearImage,
}: ImageUploaderProps) => {
  const [selectedFile, setSelectedFile] = useState<ImageFile | null>(null);

  useEffect(() => {
    setSelectedFile(null);
  }, [initialPreview]);

  const imagePreviewClass = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#f3f2f1',
    padding: '1rem',
    borderRadius: '5px',
    width: '100%',
    height: '100%',
    maxWidth: '400px',
    marginBottom: '1rem',
    '& img': {
      maxWidth: '400px',
      maxHeight: '250px',
      margin: initialPreview && !selectedFile ? '0' : '0 0 1rem 0',
    },

    '& p': {
      margin: 0,

      '& strong': {
        fontWeight: FontWeights.semibold,
      },
    },

    '& h5': {
      margin: '0 0 .5rem 0',
      fontWeight: FontWeights.semibold,
    },
  });

  return (
    <div>
      <AsyncActionButton
        iconProps={{ iconName: 'Upload' }}
        label="Upload New"
        text="Upload New"
        onClick={async () => {
          const file = await pickFile({
            excludeAcceptAll: true,
            fileTypes: 'image',
          });
          if (!file) {
            return;
          }

          const reader = new FileReader();

          reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const MAX_WIDTH = 1280;
              const MAX_HEIGHT = 720;

              let width = img.naturalWidth;
              let height = img.naturalHeight;

              if (width > MAX_WIDTH || height > MAX_HEIGHT) {
                const aspectRatio = width / height;

                if (width > MAX_WIDTH) {
                  width = MAX_WIDTH;
                  height = width / aspectRatio;
                }

                if (height > MAX_HEIGHT) {
                  height = MAX_HEIGHT;
                  width = height * aspectRatio;
                }
              }

              canvas.width = width;
              canvas.height = height;

              const ctx = canvas.getContext('2d');
              ctx?.drawImage(img, 0, 0, width, height);

              const dataUrl = canvas.toDataURL('image/jpeg');

              setSelectedFile({
                name: file.name,
                size: file.size,
                type: file.type,
                url: dataUrl,
              });
            };

            img.src = e.target?.result as string;
          };

          reader.readAsDataURL(file);
        }}
      />
      <AsyncActionButton
        iconProps={{ iconName: 'Clear' }}
        label="Clear"
        text="Clear"
        disabled={!initialPreview}
        onClick={async () => {
          onClearImage?.();
        }}
      />
      {initialPreview && !selectedFile ? (
        <div className={imagePreviewClass}>
          <img src={initialPreview} />
        </div>
      ) : null}
      {selectedFile ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: '.5rem',
          }}
        >
          <div className={imagePreviewClass}>
            <img src={selectedFile.url} alt={selectedFile.name} />
            <h5>Preview</h5>
            <p>
              <strong>File name: </strong>
              {selectedFile.name}
            </p>
            <p>
              <strong>File size:</strong> {selectedFile.size} bytes
            </p>
            <p>
              <strong>File type:</strong> {selectedFile.type}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '.5rem',
            }}
          >
            <AsyncPrimaryButton
              text="Save"
              onClick={async () => onSelectFile?.(selectedFile.url)}
            />
            <AsyncDefaultButton
              text="Cancel"
              onClick={async () => setSelectedFile(null)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ImageUploader;
