import { DateTime } from 'luxon';

export const AgeInDays = (deal: { properties: { createdate: string } }) =>
  Math.round(
    Math.abs(DateTime.fromISO(deal.properties.createdate).diffNow('days').days),
  );

export const DaysSinceLastModified = (deal: {
  properties: { createdate: string; hs_lastmodifieddate: string | null };
}) =>
  deal.properties.hs_lastmodifieddate
    ? Math.round(
        Math.abs(
          DateTime.fromISO(deal.properties.hs_lastmodifieddate).diffNow('days')
            .days,
        ),
      )
    : AgeInDays(deal);

export const DaysInStage = (deal: {
  properties: { createdate: string; dealstage: string };
  dealStageHistory?: { value: string; timestamp: string }[];
}) => {
  const currentStage = deal.properties.dealstage;

  const lastStageChangeIndex = deal.dealStageHistory?.findIndex(
    (h) => h.value !== deal.properties.dealstage,
  );

  return lastStageChangeIndex && lastStageChangeIndex > 0
    ? Math.round(
        Math.abs(
          DateTime.fromISO(
            deal.dealStageHistory![lastStageChangeIndex - 1]!.timestamp,
          ).diffNow('days').days,
        ),
      )
    : AgeInDays(deal);
};

export const DaysToCloseDate = (deal: { properties: { closedate: string } }) =>
  Math.round(DateTime.fromISO(deal.properties.closedate).diffNow('days').days);
