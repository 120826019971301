import {
  ActionType,
  BuyerOrientationStepProps,
} from '../../../../../types/BuyerOrientationSurveyTypes';
import { mergeStyles } from '@fluentui/react';
import { buyerOrientationSurveyOptions } from '../buyerOrientationSurveyUtils';
import { SurveyOptionCard } from '../SurveyOptionCard';
import { useCallback, useMemo } from 'react';

export const BuyerOrientationPriorities = ({
  surveySelectedOptions,
  dispatchAction,
}: BuyerOrientationStepProps) => {
  const prioritiesContainerStyles = mergeStyles({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',

    '.priorities-grid-container': {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: '1.5rem',

      '@media(max-width: 810px)': {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },

      '@media(max-width: 620px)': {
        gridTemplateColumns: '1fr',
      },
    },

    '.priorities-other-option-container': {
      width: '100%',
      maxWidth: '372px',
      margin: '0 auto',
    },
  });

  const handleSelectOption = useCallback(
    (newValue: string) => {
      dispatchAction({
        type: ActionType.UPDATE_PRIORITIES,
        payload: newValue,
      });
    },
    [dispatchAction],
  );

  const handleChangeOtherOption = useCallback(
    (newValue: string) => {
      dispatchAction({
        type: ActionType.UPDATE_OTHER_PRIORITY,
        payload: newValue,
      });
    },
    [dispatchAction],
  );

  const lastOptionDetails = useMemo(() => {
    return buyerOrientationSurveyOptions.priorities.options[
      buyerOrientationSurveyOptions.priorities.options.length - 1
    ];
  }, []);

  return (
    <div className={prioritiesContainerStyles}>
      <div className="priorities-grid-container">
        {buyerOrientationSurveyOptions.priorities.options
          .slice(0, -1)
          .map((option) => (
            <SurveyOptionCard
              key={option.optionId}
              option={option}
              isSelected={surveySelectedOptions.priorities.includes(
                option.value,
              )}
              handleSelectOption={handleSelectOption}
            />
          ))}
      </div>
      <div className="priorities-other-option-container">
        <SurveyOptionCard
          key={lastOptionDetails.optionId}
          option={lastOptionDetails}
          isSelected={surveySelectedOptions.priorities.includes(
            lastOptionDetails.value,
          )}
          handleSelectOption={handleSelectOption}
          showTextArea={surveySelectedOptions.priorities.includes(
            lastOptionDetails.value,
          )}
          textAreaValue={surveySelectedOptions.otherPriorityText || ''}
          handleChangeTextArea={handleChangeOtherOption}
        />
      </div>
    </div>
  );
};
