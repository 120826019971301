import { useAuth0 } from '@auth0/auth0-react';
import {
  FontIcon,
  FontSizes,
  FontWeights,
  IColumn,
  mergeStyles,
  NeutralColors,
  PrimaryButton,
  SelectionMode,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { CalendarEvent } from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { EMPTY_ARRAY } from '../../Constants';
import { isGoogleUser } from '../../Helpers/IdentityHelpers';
import useAddIntegrationModal from '../../Hooks/useAddIntegrationModal';
import useBreakpoints from '../../Hooks/useBreakpoints';
import { useExternalServiceConfigurations } from '../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../../Hooks/useLocalStorageState';
import { useTitle } from '../../Hooks/useTitle';
import { OrganizationMeetingsHappeningSoon } from '../../QueryNames';
import { EventsApiClient } from '../../Services/NetworkCommon';
import GCalLogo from '../../Static/Images/google-calendar.svg';
import OutlookIcon from '../../Static/Images/outlook.svg';
import SalesforceIcon from '../../Static/Images/salesforce.png';
import SlackIcon from '../../Static/Images/slack.png';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { OrganizationSlugRouteParams } from '../../types/RouteParams';
import { Card } from '../Card';
import { FloChatBox } from '../GPT/FloChatBox';
import { JoinConferenceButton } from '../JoinConferenceButton';
import { StyledDetailsList } from '../StyledDetailsList';
import { AgendaPane } from './Home/Agenda';
import { MeetingflowBrowser } from './Home/MeetingflowBrowser';
import { MyPlansPane } from './Home/MyPlans';
import { TasksPane } from './Home/Tasks';
import EventCard from './Library/EventCard';

// eslint-disable-next-line
let deferredPrompt: any;

export type OrganizationHomeProps = {
  showHappeningSoonCard?: boolean;
};
export const OrganizationHome = ({
  showHappeningSoonCard = false,
}: OrganizationHomeProps) => {
  useTitle('Home');
  const { user, getAccessTokenSilently } = useAuth0();
  const { organizationSlug } = useParams<OrganizationSlugRouteParams>();
  const breakpoints = useBreakpoints();
  const { isDark } = useLightOrDarkMode();
  const appInsights = useAppInsightsContext();
  const installButtonRef = React.useRef<HTMLDivElement>(null);

  const [floVisible, { toggle: toggleFloVisible }] = useBoolean(false);
  const [pwaAppInstalled, setPwaAppInstalled] = useState(false);
  const [pwaAppSupported, setPwaAppSupported] = useState(false);

  const navigate = useNavigate();

  const [hausPromoCardCollapsed, setHausPromoCardCollapsed] =
    useLocalStorageState<boolean>(
      `PROMO_CARD_COLLAPSED_${organizationSlug}`,
      false,
    );

  const { data: eventsData, isLoading: eventsDataLoading } = useQuery(
    OrganizationMeetingsHappeningSoon(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return EventsApiClient.listEvents(
        {
          organizationSlug: organizationSlug!,
          minDate: DateTime.now().plus({ minutes: -60 }).toISO()!,
          maxDate: DateTime.now().plus({ minutes: 60 }).toISO()!,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 15 minutes
      refetchInterval: 900000,
      staleTime: 900000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      retry: false,
      enabled: !!organizationSlug,
    },
  );

  const happeningSoonEvents = useMemo(() => {
    const events =
      eventsData?.data?.filter((event) => {
        const eventIsInPast = DateTime.now() > DateTime.fromISO(event.endTime);

        const eventIsNow =
          DateTime.now() > DateTime.fromISO(event.startTime) &&
          DateTime.now() < DateTime.fromISO(event.endTime);

        const eventIsSoon =
          DateTime.fromISO(event.startTime).diffNow('minutes').minutes < 60;

        const eventIsNowOrSoon = !eventIsInPast && (eventIsNow || eventIsSoon);

        return eventIsNowOrSoon;
      }) ?? EMPTY_ARRAY;
    return events;
  }, [eventsData]);

  const { refetch: refetchExternalServiceConfigurations, hasToken } =
    useExternalServiceConfigurations({ withToken: true });

  const {
    createDeferred: createAddIntegrationDeferred,
    dialog: addIntegrationDialog,
  } = useAddIntegrationModal();

  const organizationHomeClass = mergeStyles({
    display: breakpoints.md ? 'flex' : 'block',
    overflow: breakpoints.md ? 'hidden' : 'auto',
    width: breakpoints.md ? '100%' : `calc(100% - 1rem)`,
    padding: breakpoints.md ? '0' : '.5rem',
    height: !breakpoints.md ? `calc(100% - 1rem)` : '100%',
    maxHeight: breakpoints.md ? 'calc(100vh - 3.75rem)' : undefined,
    // backgroundColor: isDark
    //   ? NeutralColors.black
    //   : MEETINGFLOW_COLORS.purpleUltraSuperLight,

    '.main': {
      display: breakpoints.md ? 'flex' : undefined,
      width: '100%',
      height: breakpoints.md ? 'calc(100vh - 3.75rem)' : 'auto',
      // backgroundColor: isDark
      //   ? NeutralColors.black
      //   : MEETINGFLOW_COLORS.purpleUltraSuperLight,

      '.primary': {
        width: breakpoints.md ? `calc(33.333% - 1rem)` : '100%',
        height: breakpoints.md ? '100%' : undefined,
        padding: breakpoints.md ? '.5rem' : 0,
      },

      '.secondary': {
        width: breakpoints.md ? `calc(33.333% - 1rem)` : '100%',
        height: breakpoints.md ? '100%' : undefined,
        padding: breakpoints.md ? '.5rem' : 0,
      },

      '.tertiary': {
        width: breakpoints.md ? `calc(33.333% - 1rem)` : '100%',
        height: breakpoints.md ? '100%' : undefined,
        padding: breakpoints.md ? '.5rem' : 0,
      },
    },
  });

  const eventCardWrapperClass = mergeStyles({
    '.meeting-plan-create-button': {
      margin: 0,
    },
  });

  const happeningSoonEventColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'title',
        name: 'Event',
        minWidth: 150,
        fieldName: 'title',
        onRender: (event: CalendarEvent) => (
          <div className={eventCardWrapperClass}>
            {organizationSlug ? (
              <EventCard
                event={event}
                key={event.iCalUid}
                organizationSlug={organizationSlug}
                showCompanies
                showTextLabelOnCreateButton
              />
            ) : null}
          </div>
        ),
      },
      {
        key: 'conference',
        name: 'Video',
        minWidth: 48,
        maxWidth: 48,
        fieldName: 'conferenceInfo',
        className: `center-align`,
        headerClassName: `center-align`,
        onRender: (event: CalendarEvent) => {
          const eventIsNowOrSoon =
            DateTime.fromISO(event.startTime).diffNow('minutes').minutes < 60 &&
            DateTime.fromISO(event.endTime).diffNow('minutes').minutes > -10;

          return (
            <div style={{ marginTop: '.25rem' }}>
              {event.conferenceInfo ? (
                <JoinConferenceButton event={event} showWhenNotNowOrSoon />
              ) : DateTime.now() > DateTime.fromISO(event.startTime) &&
                DateTime.now() < DateTime.fromISO(event.endTime) ? (
                <Text
                  style={{
                    fontWeight: FontWeights.bold,
                    color: MEETINGFLOW_COLORS.orange,
                  }}
                >
                  Now
                </Text>
              ) : eventIsNowOrSoon ? (
                <Text
                  style={{
                    fontWeight: FontWeights.bold,
                    color: MEETINGFLOW_COLORS.teal,
                    lineHeight: '2rem',
                  }}
                >
                  Soon
                </Text>
              ) : null}
            </div>
          );
        },
      },
    ],
    [organizationSlug, eventCardWrapperClass],
  );

  const installPWA = useCallback(() => {
    appInsights.trackEvent({
      name: 'HOME_PAGE_PROMO_CLICK_DESKTOP_APP',
      properties: {
        organizationSlug,
        emailAddress: user?.email,
      },
    });
    if (deferredPrompt) {
      deferredPrompt.prompt();
      // eslint-disable-next-line
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          appInsights.trackEvent({
            name: 'DESKTOP_PWA_INSTALL_ACCEPTED',
            properties: {
              organizationSlug,
              emailAddress: user?.email,
            },
          });
        } else {
          appInsights.trackEvent({
            name: 'DESKTOP_PWA_INSTALL_CANCELED',
            properties: {
              organizationSlug,
              emailAddress: user?.email,
            },
          });
        }
      });
    }
  }, [organizationSlug, user?.email, appInsights]);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      deferredPrompt = e;

      setPwaAppSupported(true);
    });
  }, []);

  useEffect(() => {
    window.addEventListener('appinstalled', (event) => {
      // Clear the deferredPrompt so it can be garbage collected
      // @ts-ignore
      window.deferredPrompt = null;
      setPwaAppInstalled(true);
      appInsights.trackEvent({
        name: 'DESKTOP_PWA_INSTALLED',
        properties: {
          organizationSlug,
          emailAddress: user?.email,
        },
      });
    });
  }, [organizationSlug, user?.email, appInsights]);

  const hausPromoContainerClass = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    columnGap: '.5rem',
    rowGap: '.5rem',
    flexWrap: 'wrap',
    height: '100%',
  });

  const hausPromoClass = mergeStyles({
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.magentaSuperLight,
    // overflow: 'hidden',
    borderRadius: '.25rem',
    padding: '.5rem',
    display: 'flex',
    flexDirection: 'row',
    minHeight: '67px',
    cursor: 'pointer',
    transition: '.3s all ease-in-out',
    position: 'relative',
    flexBasis: 'calc(50% - .25rem)',
    boxSizing: 'border-box',

    ':hover': {
      backgroundColor: isDark
        ? NeutralColors.gray210
        : MEETINGFLOW_COLORS.purpleLighter,
    },

    '.haus-promo-image': {
      width: '2rem',
      float: 'left',
      marginRight: '.5rem',
      paddingTop: '.25rem',

      img: {
        width: '2rem',
      },
    },

    '.haus-promo-details': {
      display: 'flex',
      flexDirection: 'column',
    },

    '.haus-promo-headline': {
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.medium,
      color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.magentaDark,
    },

    '.haus-promo-description': {
      fontWeight: FontWeights.regular,
      fontSize: FontSizes.small,
      color: isDark ? NeutralColors.gray80 : MEETINGFLOW_COLORS.purpleDarkest,
      lineClamp: 3,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': '3',
      '-webkit-box-orient': 'vertical',
    },

    '.haus-promo-checkmark': {
      width: '2rem',
      height: '2rem',
      backgroundColor: MEETINGFLOW_COLORS.white,
      borderRadius: '1rem',
      position: 'absolute',
      right: '-.25rem',
      top: '-.25rem',
      boxShadow: '0px 0px 2px rgba(0,0,0,.25)',
    },
  });

  const quietHausPromoClass = mergeStyles({
    backgroundColor: 'transparent',
    overflow: 'hidden',
    borderRadius: '.25rem',
    padding: '0 .5rem',
    display: 'flex',
    flexDirection: 'row',

    '.haus-promo-details': {
      display: 'flex',
      flexDirection: 'column',
    },

    '.haus-promo-headline': {
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.medium,
      color: MEETINGFLOW_COLORS.white,
    },

    '.haus-promo-description': {
      fontWeight: FontWeights.regular,
      fontSize: FontSizes.mini,
      color: MEETINGFLOW_COLORS.white,
      lineClamp: 1,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': '1',
      '-webkit-box-orient': 'vertical',

      a: {
        color: isDark
          ? MEETINGFLOW_COLORS.white
          : MEETINGFLOW_COLORS.magentaSuperLight,
        textDecoration: 'underline',
        fontWeight: FontWeights.semibold,
        transition: '.2s all ease-in-out',

        ':hover': {
          color: MEETINGFLOW_COLORS.white,
        },
      },
    },
  });

  const fadeInClass = mergeStyles({
    position: 'relative',
    animationName: 'fadeInAnimation',
    animationDuration: '2s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    width: 'auto',
    transition: '.3s ease-in-out all',
  });

  const hausPromoCardHeight = hausPromoCardCollapsed
    ? '28px'
    : pwaAppInstalled || !pwaAppSupported
    ? '350px'
    : '370px';
  const happeningSoonCardVisible =
    happeningSoonEvents?.length > 0 && showHappeningSoonCard;
  const happeningSoonCardHeight = happeningSoonEvents?.length * 56 + 32;
  const happeningSoonCardHeightRem = `${happeningSoonCardHeight / 16}rem`;
  const calendarCardHeight = !happeningSoonCardVisible
    ? `calc(100% - 1.25rem)`
    : `calc(100% - ${happeningSoonCardHeightRem} - 2.25rem)`;
  const agendaTopOffsetPx = happeningSoonCardVisible
    ? 220 + happeningSoonCardHeight + 8
    : 220;

  const hausPromoCard = (
    <Card
      contentContainerOverflow={'hidden'}
      title="Get the Most Out of Meetingflow"
      smallHeader
      contentContainerPadding={'.5rem'}
      maxHeight={breakpoints.md ? hausPromoCardHeight : undefined}
      minHeight={breakpoints.md ? hausPromoCardHeight : undefined}
      color={
        hausPromoCardCollapsed
          ? MEETINGFLOW_COLORS.magentaDark
          : MEETINGFLOW_COLORS.magentaDark
      }
      backgroundColor={
        isDark ? MEETINGFLOW_COLORS.magentaDark : MEETINGFLOW_COLORS.magentaDark
      }
      titleColor={
        hausPromoCardCollapsed
          ? MEETINGFLOW_COLORS.white
          : MEETINGFLOW_COLORS.white
      }
      showBorder={false}
      noBottomPadding
      marginBottom={'1rem'}
      allowCollapsing
      defaultCollapsed={hausPromoCardCollapsed}
      onToggleCollapsed={(collapsed) => {
        setHausPromoCardCollapsed(collapsed);
      }}
      animateCollapse
    >
      <div className={hausPromoContainerClass}>
        <div
          className={hausPromoClass}
          style={{
            flexBasis: '100%',
            backgroundColor: MEETINGFLOW_COLORS.white,
            minHeight: '5rem',
            cursor: 'default',
          }}
          // onClick={() => {
          //   appInsights.trackEvent({
          //     name: 'HOME_PAGE_PROMO_CLICK_CALL_RECORDING',
          //     properties: {
          //       organizationSlug,
          //       emailAddress: user?.email,
          //     },
          //   });
          //   // TODO: Make it do something
          //   // window.open(
          //   //   isGoogleUser(user!.sub!)
          //   //     ? 'https://chrome.google.com/webstore/detail/meetingflow-calendar-exte/eglggijabcagpkececaemgliiojkoefg'
          //   //     : 'https://appsource.microsoft.com/en-us/product/office/wa200004496?tab=overview&exp=ubp8',
          //   //   '_blank',
          //   // );
          // }}
        >
          <div className="haus-promo-image">
            <FontIcon
              iconName="Record2"
              style={{
                fontSize: '2rem',
                fontWeight: 'bold',
                color: MEETINGFLOW_COLORS.magenta,
              }}
            />
          </div>
          <div className="haus-promo-details">
            <Text
              className="haus-promo-headline"
              style={{
                color: MEETINGFLOW_COLORS.magenta,
                fontSize: FontSizes.large,
              }}
            >
              Effortlessly record calls and get AI insights.
            </Text>
            <div
              className="haus-promo-description"
              style={{
                color: MEETINGFLOW_COLORS.magentaDark,
                fontWeight: FontWeights.semibold,
              }}
            >
              Meetingflow supports call recording! Press a pink "Record" button
              to send our Note Taker to your next call. It will record,
              transcribe, analzye, summarize, and provide insights about your
              meeting — automatically.
            </div>
          </div>
        </div>

        <div
          className={hausPromoClass}
          onClick={() => {
            appInsights.trackEvent({
              name: 'HOME_PAGE_PROMO_CLICK_CRM',
              properties: {
                organizationSlug,
                emailAddress: user?.email,
              },
            });
            createAddIntegrationDeferred({
              displayOnly: ['SALESFORCE', 'HUBSPOT'],
              title: 'Add CRM Integration',
              description: (
                <div
                  style={{
                    padding: '.5rem',
                  }}
                >
                  <Text
                    variant="mediumPlus"
                    block
                    style={{
                      fontWeight: FontWeights.semibold,
                      color: MEETINGFLOW_COLORS.teal,
                      marginBottom: '.25rem',
                      paddingLeft: '1rem',
                    }}
                  >
                    Connecting your CRM is quick and easy.
                  </Text>
                  <Text
                    variant="medium"
                    block
                    style={{ maxWidth: '28rem', paddingLeft: '1rem' }}
                  >
                    Meetingflow works with both Salesforce and HubSpot, allowing
                    you to keep your workspaces' source of truth update to date
                    — without losing your flow.
                  </Text>
                </div>
              ),
            })
              .promise.then((result) => {
                if (result === undefined) {
                  return;
                }
                navigate(`${result.app.toLowerCase()}/pipeline?coachVisible`);
                refetchExternalServiceConfigurations();
              })
              .catch(() => toast.error('Failed to add integration.'));
          }}
        >
          <div className="haus-promo-image">
            <img src={SalesforceIcon} alt="Salesforce" />
          </div>

          <div className="haus-promo-details">
            <Text className="haus-promo-headline">Log Meetings to CRM</Text>
            <div className="haus-promo-description">
              Meetingflow supports Salesforce and HubSpot, allowing you to
              update accounts and deals, log meetings, and manage your pipeline
              — without losing your flow.
            </div>
          </div>

          {hasToken('HUBSPOT') || hasToken('SALESFORCE') ? (
            <div className={classNames(fadeInClass, 'haus-promo-checkmark')}>
              <FontIcon
                iconName="SkypeCircleCheck"
                style={{
                  color: MEETINGFLOW_COLORS.teal,
                  fontSize: '2rem',
                  fontWeight: 'bold',
                }}
              />
            </div>
          ) : null}
        </div>

        <div
          className={hausPromoClass}
          onClick={() => {
            appInsights.trackEvent({
              name: 'HOME_PAGE_PROMO_CLICK_CALENDAR',
              properties: {
                organizationSlug,
                emailAddress: user?.email,
              },
            });
            window.open(
              isGoogleUser(user!.sub!)
                ? 'https://chrome.google.com/webstore/detail/meetingflow-calendar-exte/eglggijabcagpkececaemgliiojkoefg'
                : 'https://appsource.microsoft.com/en-us/product/office/wa200004496?tab=overview&exp=ubp8',
              '_blank',
            );
          }}
        >
          <div className="haus-promo-image">
            {isGoogleUser(user!.sub!) ? (
              <img src={GCalLogo} alt="Google Calendar" />
            ) : (
              <img src={OutlookIcon} alt="Microsoft Outlook" />
            )}
          </div>
          <div className="haus-promo-details">
            <Text className="haus-promo-headline">
              Work in{' '}
              {isGoogleUser(user!.sub!)
                ? 'Google Calendar'
                : 'Microsoft Outlook'}
            </Text>
            <div className="haus-promo-description">
              {isGoogleUser(user!.sub!)
                ? "Meetingflow's Chrome extension allows you to create and open Meetingflows directly from Google Calendar."
                : "Meetingflow's Outlook extension allows you to create and open Meetingflows directly from Outlook."}
            </div>
          </div>
        </div>

        <div
          className={hausPromoClass}
          onClick={() => {
            appInsights.trackEvent({
              name: 'HOME_PAGE_PROMO_CLICK_SLACK',
              properties: {
                organizationSlug,
                emailAddress: user?.email,
              },
            });
            createAddIntegrationDeferred({
              displayOnly: ['SLACK', 'TEAMS'],
              title: 'Add Slack Integration',
              description: (
                <div
                  style={{
                    padding: '.5rem',
                  }}
                >
                  <Text
                    variant="mediumPlus"
                    block
                    style={{
                      fontWeight: FontWeights.semibold,
                      color: MEETINGFLOW_COLORS.teal,
                      marginBottom: '.25rem',
                      paddingLeft: '1rem',
                    }}
                  >
                    Update your teammates in seconds.
                  </Text>
                  <Text
                    variant="medium"
                    block
                    style={{ maxWidth: '28rem', paddingLeft: '1rem' }}
                  >
                    Meetingflow integrates with Slack and Teams, allowing you to
                    share meeting notes and summaries to any of your channels,
                    right from your Meetingflow.
                  </Text>
                </div>
              ),
            })
              .promise.then((result) => {
                if (result === undefined) {
                  return;
                }
                refetchExternalServiceConfigurations();
              })
              .catch(() => toast.error('Failed to add integration.'));
          }}
        >
          <div className="haus-promo-image">
            <img src={SlackIcon} alt="Salesforce" />
          </div>
          <div className="haus-promo-details">
            <Text className="haus-promo-headline">
              Share Summaries in Slack or Teams
            </Text>
            <div className="haus-promo-description">
              Meetingflow's Slack and Teams integration allows you to share
              Meetingflows to channels, including automatically-generated
              meeting summaries.
            </div>
          </div>
          {hasToken('SLACK') ? (
            <div className={classNames(fadeInClass, 'haus-promo-checkmark')}>
              <FontIcon
                iconName="SkypeCircleCheck"
                style={{
                  color: MEETINGFLOW_COLORS.teal,
                  fontSize: '2rem',
                  fontWeight: 'bold',
                }}
              />
            </div>
          ) : null}
        </div>

        <div
          className={hausPromoClass}
          ref={installButtonRef}
          onClick={() => installPWA()}
        >
          <div className="haus-promo-image">
            <FontIcon
              iconName="CloneToDesktop"
              style={{
                fontSize: '2rem',
                fontWeight: 'bold',
                color: MEETINGFLOW_COLORS.teal,
              }}
            />
          </div>
          <div className="haus-promo-details">
            <Text className="haus-promo-headline">Install Desktop App</Text>
            <div className="haus-promo-description">
              Install Meetingflow's desktop app to access Meetingflow from your
              Dock or Start menu.
            </div>
          </div>
        </div>

        <div className={quietHausPromoClass}>
          <div className="haus-promo-details">
            <Text className="haus-promo-headline">Enabling a team?</Text>
            <div className="haus-promo-description">
              You may want to check out our{' '}
              <a
                href="https://meetingflow.com/help-center/"
                target="_blank"
                rel="noreferrer"
              >
                Help Center
              </a>{' '}
              for info on templates and inviting your teammates.
            </div>
          </div>
        </div>
      </div>
    </Card>
  );

  const calendarCard = (
    <Card
      contentContainerPadding="0"
      contentContainerOverflow={'hidden'}
      title="Your Calendar"
      smallHeader
      maxHeight={breakpoints.md ? calendarCardHeight : undefined}
      minHeight={breakpoints.md ? calendarCardHeight : undefined}
      color={
        isDark
          ? MEETINGFLOW_COLORS.purpleSecondary
          : MEETINGFLOW_COLORS.purpleLighter
      }
      titleColor={
        isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.purpleDarkest
      }
      showBorder={false}
      noBottomPadding
      marginBottom={breakpoints.md ? '0' : undefined}
      // backgroundColor={MEETINGFLOW_COLORS.tealLight}
    >
      <AgendaPane
        detailListTopOffset={breakpoints.md ? `${agendaTopOffsetPx}px` : '0'}
      />
    </Card>
  );

  const meetingflowsCard = (
    <Card
      contentContainerOverflow={'hidden'}
      title="All Meetingflows"
      smallHeader
      maxHeight={breakpoints.md ? 'calc(100% - 1.25rem)' : undefined}
      minHeight={breakpoints.md ? 'calc(100% - 1.25rem)' : undefined}
      color={
        isDark
          ? MEETINGFLOW_COLORS.purpleSecondary
          : MEETINGFLOW_COLORS.purpleLighter
      }
      titleColor={
        isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.purpleDarkest
      }
      showBorder={false}
      noBottomPadding
      marginBottom={breakpoints.md ? '0' : undefined}
    >
      {organizationSlug ? (
        // <MeetingflowBrowser
        //   organizationSlug={organizationSlug}
        //   queryKey={`meetingflows-${organizationSlug}-combined-view`}
        // />
        <MyPlansPane organizationSlug={organizationSlug} />
      ) : null}
    </Card>
  );

  const happeningSoonCard = happeningSoonCardVisible ? (
    <Card
      contentContainerOverflow={'hidden auto'}
      title="Happening Soon"
      smallHeader
      maxHeight={breakpoints.md ? happeningSoonCardHeight : undefined}
      minHeight={breakpoints.md ? happeningSoonCardHeight : undefined}
      color={MEETINGFLOW_COLORS.teal}
      titleColor={MEETINGFLOW_COLORS.white}
      showBorder={false}
      contentContainerPadding={'0'}
      noBottomPadding
    >
      <StyledDetailsList
        shimmerLines={5}
        enableShimmer={eventsDataLoading}
        items={happeningSoonEvents || EMPTY_ARRAY}
        selectionMode={SelectionMode.none}
        columns={happeningSoonEventColumns}
        isHeaderVisible={false}
      />
    </Card>
  ) : null;

  const actionItemsCard = (
    <Card
      contentContainerOverflow={'hidden'}
      title="Action Items"
      smallHeader
      contentContainerPadding={'0'}
      maxHeight={
        breakpoints.md
          ? `calc(100% - ${hausPromoCardHeight} - 2.25rem)`
          : undefined
      }
      minHeight={
        breakpoints.md
          ? `calc(100% - ${hausPromoCardHeight} - 2.25rem)`
          : undefined
      }
      color={
        isDark
          ? MEETINGFLOW_COLORS.purpleSecondary
          : MEETINGFLOW_COLORS.purpleLighter
      }
      titleColor={
        isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.purpleDarkest
      }
      showBorder={false}
      noBottomPadding
      marginBottom={'.975rem'}
    >
      <TasksPane heightOffset={hausPromoCardHeight} />
    </Card>
  );

  const floContainerClass = mergeStyles({
    animationName: 'fadeInAnimation',
    animationDuration: '.3s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    transition: '.5s ease-in-out all',
    position: 'absolute',
    bottom: '3rem',
    right: '1rem',
    width: '25rem',
    maxWidth: '25rem',
    height: '40rem',
    minHeight: '10rem',
    maxHeight: 'calc(100vh - 10rem)',
    backgroundColor: isDark
      ? NeutralColors.gray200
      : MEETINGFLOW_COLORS.purpleUltraLight,
    zIndex: 51,
    borderRadius: '.5rem',
    boxShadow: '0px 0px 8px rgba(0,0,0,.1)',
    border: `1px solid ${
      isDark ? NeutralColors.gray200 : MEETINGFLOW_COLORS.purpleGrey
    }`,
  });

  const floButtonClass = mergeStyles({
    position: 'absolute',
    bottom: '.5rem',
    right: '1rem',
    zIndex: 51,
    // fontFamily: 'Kalam',
    transition: '.3s ease-in-out all',

    span: {
      color: `${MEETINGFLOW_COLORS.white} !important`,
      position: 'relative',
      top: '-1px',
    },

    '&:hover': {
      backgroundColor: MEETINGFLOW_COLORS.teal,
      borderColor: MEETINGFLOW_COLORS.teal,
    },

    i: {
      fontSize: '1.5rem',
      position: 'relative',
      top: '6px',
      right: 0,
      color: `${MEETINGFLOW_COLORS.white} !important`,
    },
  });

  return (
    <div className={organizationHomeClass}>
      <div className="main">
        <div className="primary">
          {happeningSoonCard}
          {calendarCard}
        </div>
        <div className="secondary">{meetingflowsCard}</div>
        <div className="tertiary">
          {breakpoints.md ? (
            <>
              {actionItemsCard}
              {hausPromoCard}
            </>
          ) : (
            <>
              {actionItemsCard}
              {hausPromoCard}
            </>
          )}
        </div>
      </div>
      {addIntegrationDialog}
      {['panda-ai', 'meetingflow', 'kyle-zone2'].includes(organizationSlug!) &&
      floVisible ? (
        <div className={floContainerClass}>
          <FloChatBox
            organizationSlug={organizationSlug!}
            title="Meetingflow Assistant"
            onClose={toggleFloVisible}
            chatInputPlaceholder="Ask Flo a question"
          />
        </div>
      ) : null}
      {['panda-ai', 'meetingflow', 'kyle-zone2'].includes(organizationSlug!) ? (
        <PrimaryButton
          // @ts-ignore - Need JSX here for icon
          text={
            <>
              Meetingflow Assistant{' '}
              <FontIcon
                iconName="AISparkle"
                style={{ color: MEETINGFLOW_COLORS.teal }}
              />
            </>
          }
          onClick={toggleFloVisible}
          className={floButtonClass}
        />
      ) : null}
    </div>
  );
};

export default OrganizationHome;
