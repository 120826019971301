import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import {
  IconButton,
  mergeStyleSets,
  Modal,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import {
  AddArtifactIconProps,
  CheckMarkIconProps,
  ChromeCloseIconProps,
} from '../../../utils/iconProps';
import {
  DealRoomAsyncCommandBarButton,
  DealRoomAsyncPrimaryButton,
  DealRoomCommandBarButton,
} from '../Components/DealRoomButton';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { pickFile } from '../../../Helpers/FileHelpers';
import { DraggableFileUploaderContainer } from '../DraggableFileUploaderContainer';
import { useNavigate } from '../../../Hooks/useNavigate';

type UploadDealRoomAttachmentsDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  onAdd: (item: DealRoomArtifact) => void;
  onDismiss: () => void;
  artifact?: DealRoomArtifact;
};

export const UploadDealRoomAttachmentsDialog = ({
  organizationSlug,
  dealRoomId,
  onAdd,
  onDismiss,
  artifact,
}: UploadDealRoomAttachmentsDialogProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const styles = mergeStyleSets({
    modalWrapper: {
      '& > .ms-Overlay': {
        backdropFilter: 'blur(3px)',
      },
      '.ms-Dialog-main': {
        minHeight: 'fit-content',
        backgroundColor: 'transparent',
      },
    },
    contentContainer: {
      width: 'calc(100vw - 3rem)',
      padding: '1rem 1.5rem 1.5rem',
      maxWidth: '538px',
      backgroundColor: DEALROOMS_COLORS.white,
      borderRadius: '.5rem',
      boxShadow: '0px 8px 16px 0px #00000024',
      gap: '1.5rem',
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    title: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      fontWeight: '400',
    },
    closeButton: {
      i: {
        fontSize: '.75rem',
      },
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    contentTitle: {
      '.ms-TextField-fieldGroup': {
        borderRadius: '.25rem',
        border: 'none !important',
        height: 'auto',
        '::after': {
          borderRadius: '.25rem',
          border: `1px solid ${DEALROOMS_COLORS.neutralSecondary}`,
        },
      },
      '.ms-Label': {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.darkerGray,
      },
      input: {
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        color: DEALROOMS_COLORS.darkerGray,
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.375',
        borderRadius: '.25rem',
        padding: '9px 14px',
        '::placeholder': {
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },
    },
    contentDescription: {
      '.ms-TextField-fieldGroup': {
        borderRadius: '4px',
        border: 'none !important',
        height: 'auto',

        '::after': {
          borderRadius: '.25rem',
          border: `1px solid ${DEALROOMS_COLORS.neutralSecondary}`,
        },
      },
      '.ms-Label': {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.darkerGray,
      },
      textarea: {
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        color: DEALROOMS_COLORS.darkerGray,
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '22px',
        borderRadius: '.25rem',
        padding: '9px 14px',
        height: '96px',
        maxHeight: '96px',
        overflowY: 'auto',

        '::placeholder': {
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },
    },
    footerContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem',
      flexWrap: 'wrap',
      '> .cancel-button': {
        padding: '0.5rem 1rem',
        justifyContent: 'center',
        '.ms-Button-label': {
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: '400',
          color: DEALROOMS_COLORS.darkerGray,
        },
      },
      '> .save-button': {
        padding: '0.5rem 1rem',
        '.ms-Button-label': {
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: '400',
          color: DEALROOMS_COLORS.white,
        },
      },
      '> .save-button-disabled': {
        backgroundColor: DEALROOMS_COLORS.neutralDark,
        color: DEALROOMS_COLORS.lightGray,
        borderColor: DEALROOMS_COLORS.neutralDark,
        cursor: 'not-allowed',
      },
    },
    uploadSection: {
      display: 'flex',
      gap: '1rem',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      input: {
        display: 'none',
      },
    },
    uploadSectionMessages: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    uploadSectionFileName: {
      fontSize: '0.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '500',
      color: DEALROOMS_COLORS.themePrimary,
      wordBreak: 'break-word',
      textAlign: 'center',
    },
    uploadSectionMessage: {
      fontSize: '0.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '500',
      color: DEALROOMS_COLORS.neutralDark,
      wordBreak: 'break-word',
      textAlign: 'center',
    },
    highlighted: {
      color: DEALROOMS_COLORS.uploadAttachmentHighLightedText,
    },
    dropContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    draggableContainer: {
      maxWidth: '307px',
    },
    selectFileButton: {
      padding: '.375rem .75rem',
    },
  });

  const handleSelectFile = useCallback(async () => {
    try {
      const file = await pickFile({});

      if (!file) {
        return;
      }

      const hasExtension = file.name.lastIndexOf('.') > 0;

      if (!hasExtension) {
        toast.error(
          'Please select a file with an extension, like .txt or .pdf',
        );
        return;
      }

      setSelectedFile(file);
    } catch (err) {}
  }, []);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const droppedFiles = event?.dataTransfer?.files;
    if (droppedFiles && droppedFiles.length > 0) {
      const currentFile = Array.from(droppedFiles)[0];

      const hasExtension = currentFile?.name?.lastIndexOf('.') > 0;

      if (!hasExtension) {
        toast.error(
          'Please select a file with an extension, like .txt or .pdf',
        );
        return;
      }

      setSelectedFile(currentFile);
    }
  }, []);

  const handleDeleteArtifact = useCallback(async () => {
    if (!artifact) return;
    try {
      const token = await getAccessTokenSilently();
      return await DealRoomsApiClient.deleteArtifact(
        organizationSlug!,
        dealRoomId,
        artifact.id,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (err: unknown) {
      return err;
    }
  }, [artifact, dealRoomId, getAccessTokenSilently, organizationSlug]);

  const uploadArtifact = useCallback(
    async (artifactId: number | null) => {
      if (!selectedFile) return;

      const token = await getAccessTokenSilently();

      await toast.promise(
        DealRoomsApiClient.uploadFileArtifact(
          organizationSlug,
          dealRoomId,
          {
            file: selectedFile!,
            ...(title || artifact?.name
              ? { name: title || artifact?.name }
              : {}),
            ...(description ? { description } : {}),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Uploading attachment...',
          success: (result) => {
            onAdd(result.data);
            if (artifactId && result?.data && 'id' in result.data) {
              navigate(
                `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifact/${result.data.id}`,
                { preserveQuery: true },
              );
            }
            return `Successfully ${artifact ? 'updated' : 'uploaded'} attachment`;
          },
          error: 'Failed to upload attachment',
        },
      );
    },
    [
      artifact,
      dealRoomId,
      description,
      getAccessTokenSilently,
      navigate,
      onAdd,
      organizationSlug,
      selectedFile,
      title,
    ],
  );

  const handleUploadFile = useCallback(async () => {
    if (!selectedFile) return;

    if (artifact) {
      const response = await handleDeleteArtifact();
      if (
        response &&
        typeof response === 'object' &&
        'status' in response &&
        response.status === 204
      ) {
        await uploadArtifact(artifact.id);
      } else {
        toast.error('Something went wrong! Please try again later.');
      }
    } else {
      await uploadArtifact(null);
    }
  }, [artifact, handleDeleteArtifact, selectedFile, uploadArtifact]);

  return (
    <Modal className={styles.modalWrapper} isOpen onDismiss={onDismiss}>
      <Stack className={styles.contentContainer}>
        <Stack.Item className={styles.headerContainer}>
          <Text className={styles.title}>
            {artifact ? 'Update New Version' : 'Upload New Attachment'}
          </Text>
          <IconButton
            className={styles.closeButton}
            ariaLabel="Close"
            iconProps={ChromeCloseIconProps}
            onClick={onDismiss}
            alt="Close image"
          />
        </Stack.Item>
        <Stack.Item className={styles.content}>
          {artifact ? (
            <DraggableFileUploaderContainer
              handleOnDrop={handleDrop}
              className={styles.draggableContainer}
            >
              <DealRoomAsyncCommandBarButton
                customClasses={styles.selectFileButton}
                onClick={handleSelectFile}
                text="Select File"
                buttonStyleType="DEAL_ROOM"
                iconProps={{ iconName: 'AddArtifact' }}
              />
              <div className={styles.dropContent}>
                <Text className={styles.uploadSectionMessage}>
                  Select or drop a file to upload.
                </Text>
                <Text className={styles.uploadSectionMessage}>
                  This file will{' '}
                  <span className={styles.highlighted}>replace</span> the
                  existing version.
                </Text>
                {selectedFile && (
                  <Text className={styles.uploadSectionFileName}>
                    {selectedFile.name || 'N/A'}
                  </Text>
                )}
              </div>
            </DraggableFileUploaderContainer>
          ) : (
            <>
              <div className={styles.uploadSection}>
                <DealRoomAsyncCommandBarButton
                  customClasses={styles.selectFileButton}
                  onClick={handleSelectFile}
                  text="Select File"
                  buttonStyleType="DEAL_ROOM"
                  iconProps={AddArtifactIconProps}
                />
                <div className={styles.uploadSectionMessages}>
                  {selectedFile ? (
                    <Text className={styles.uploadSectionFileName}>
                      {selectedFile.name || 'N/A'}
                    </Text>
                  ) : (
                    <Text className={styles.uploadSectionMessage}>
                      Select a file to upload.
                    </Text>
                  )}
                </div>
              </div>
              <TextField
                value={title}
                className={styles.contentTitle}
                label="Title"
                placeholder="Document title..."
                onChange={(e, newValue) => setTitle(newValue || '')}
              />
            </>
          )}
          <TextField
            value={description}
            className={styles.contentDescription}
            label={artifact ? 'Description of Changes' : 'Description'}
            multiline
            resizable={false}
            placeholder="Enter Artifact Description"
            onChange={(e, newValue) => setDescription(newValue || '')}
          />
        </Stack.Item>
        <Stack.Item className={styles.footerContainer}>
          <DealRoomCommandBarButton
            customClasses="cancel-button"
            buttonStyleType="COMMAND_BAR"
            text="Cancel"
            onClick={onDismiss}
          />
          <DealRoomAsyncPrimaryButton
            customClasses={classNames('save-button', {
              ['save-button-disabled']: !selectedFile,
            })}
            onClick={handleUploadFile}
            text="Add Artifact"
          />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};
