import { useAuth0 } from '@auth0/auth0-react';
import {
  FontSizes,
  FontWeights,
  IconButton,
  Link,
  NeutralColors,
  Spinner,
  SpinnerSize,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { GPTChatBoxMessage } from '@meetingflow/common/Api/data-contracts';
import {
  isErrorMessage,
  isMemoriesMessage,
  isThoughtMessage,
  isTokenMessage,
} from '@meetingflow/common/AssistantHelpers';
import { PickValues } from '@meetingflow/common/ObjectHelpers';
import {
  guessNameFromEmail,
  titleCase,
} from '@meetingflow/common/StringHelpers';
import classNames from 'classnames';
import { isString } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks, {
  Options as RehypeExternalLinksOptions,
} from 'rehype-external-links';
import remarkGfm from 'remark-gfm';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useScrollToBottom } from '../../Hooks/useScrollToBottom';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';

const DEBUG = false; //true;

export type FloChatBoxProps = {
  organizationSlug: string;
  title?: string;
  onClose: () => void;
  chatInputPlaceholder?: string;
  maxHeightRem?: number;
};

export const FloChatBox = ({
  organizationSlug,
  title,
  onClose,
  chatInputPlaceholder,
  maxHeightRem = 40,
}: FloChatBoxProps) => {
  const { user } = useAuth0();
  const abortController = useRef<AbortController>();
  const contentRef = useRef<HTMLDivElement>(null);

  const userName = useMemo(
    () =>
      (
        user!.name ||
        guessNameFromEmail(user!.name) ||
        titleCase(user!.email!.split('@')[0])
      )?.split(/\b/g)?.[0],
    [user],
  );

  const [
    isGenerating,
    { setTrue: setGeneratingTrue, setFalse: setGeneratingFalse },
  ] = useBoolean(false);

  const [currentThought, setCurrentThought] = useState<string | null>(null);
  const [currentOutput, setCurrentOutput] = useState<string>('');
  const [memories, setMemories] = useState<string[] | undefined>();
  const [userInput, setUserInput] = useState<string>('');

  const [messages, setMessages] = useState<GPTChatBoxMessage[]>([]);

  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const FOOTER_HEIGHT = 4.75;
  const FOOTER_HEIGHT_REM = `${FOOTER_HEIGHT}rem`;
  const CONTENT_HEIGHT = maxHeightRem - FOOTER_HEIGHT;
  const CONTENT_HEIGHT_REM = `${CONTENT_HEIGHT}rem`;

  const generate = useCallback(async () => {
    const userMessageIndex = messages.findLastIndex((m) => m.role === 'user');

    if (userMessageIndex < 0) {
      return;
    }

    abortController.current?.abort();

    const newController = new AbortController();
    abortController.current = newController;

    setGeneratingTrue();
    setCurrentOutput('');
    setCurrentThought(null);

    try {
      const token = await getAccessTokenSilently();
      const result = await new Promise<
        { error: true; message?: string | null } | string
      >((resolve, reject) => {
        fetch(`/api/organization/${organizationSlug}/flo/query`, {
          method: 'POST',
          headers: {
            Accept: 'text/event-stream',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            messages: messages
              .filter((message) => !message.error && !!message.content)
              .map((message) =>
                PickValues(message, ['role', 'name', 'content']),
              ),
            memories,
          }),
          signal: newController.signal,
        })
          .then(async (res) => {
            let responseBody = '';
            let generated = '';

            if (!res.body) {
              reject(new Error(`res has no body`));
              return;
            }

            const reader = res.body
              .pipeThrough(new TextDecoderStream())
              .getReader();

            if (!reader) {
              reject(`Failed to get stream reader`);
            }

            let gotOutput = false;

            // eslint-disable-next-line no-constant-condition
            while (true) {
              gotOutput = false;
              const read = await reader.read();

              if (read.done && !responseBody) {
                resolve(generated);
                return;
              }

              const value = read.value;

              if (value) {
                responseBody += value;
              }

              let i = responseBody.indexOf('\n\n');

              while (i >= 0) {
                const eventPayload = responseBody.slice(0, i);
                responseBody = responseBody.slice(i + 2);

                if (eventPayload.startsWith('data: ')) {
                  const payload = eventPayload.slice(6);

                  if (payload === '[DONE]') {
                    if (DEBUG) {
                      console.info(`Got [DONE] event payload ${payload}`);
                    }

                    break;
                  }

                  let message: unknown = undefined;
                  try {
                    message = JSON.parse(payload);

                    if (DEBUG) {
                      console.info(`Parsed data chunk ${payload}`);
                    }
                  } catch (err: unknown) {
                    console.warn(`Error parsing payload data chunk ${payload}`);
                  }

                  if (isTokenMessage(message)) {
                    generated += message.token;
                    gotOutput = true;
                  } else if (isThoughtMessage(message)) {
                    setCurrentThought(message.thought);
                  } else if (isMemoriesMessage(message)) {
                    setMemories(message.memories);
                  } else if (isErrorMessage(message)) {
                    resolve({ error: true, message: message.message });
                    return;
                  } else {
                    console.warn(
                      `Received unrecognized message type! ${payload}`,
                    );
                  }
                } else if (eventPayload.startsWith('done')) {
                  if (DEBUG) {
                    console.info(`Got "done" event payload ${eventPayload}`);
                  }
                  break;
                } else {
                  console.warn(
                    `Received unknown payload type: ${eventPayload}`,
                  );
                }

                if (gotOutput) {
                  setCurrentOutput(generated);
                }

                i = responseBody.indexOf('\n\n');
              }
            }
          })
          .catch((err) => reject(err));
      });

      if (isString(result)) {
        setCurrentOutput('');
        setMessages([
          ...messages,
          { role: 'assistant', display: true, content: result },
        ]);
      } else {
        setCurrentOutput('');
        setMessages([
          ...messages,
          {
            role: 'assistant',
            error: true,
            display: true,
            content: result.message || 'Something went wrong, please try again',
          },
        ]);
      }
    } catch (err: unknown) {
      if (err instanceof DOMException && err.name === 'AbortError') {
        console.info('Stream summary cancelled');
        return;
      } else {
        toast.error('Something went wrong');
      }
      console.error(typeof err, err);
    } finally {
      if (abortController.current === newController) {
        abortController.current = undefined;
        setCurrentThought(null);
        setCurrentOutput('');
        setGeneratingFalse();
      }
    }
  }, [
    getAccessTokenSilently,
    memories,
    messages,
    organizationSlug,
    setGeneratingFalse,
    setGeneratingTrue,
  ]);

  useEffect(() => {
    // generate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      abortController.current?.abort();
    };
  }, []);

  useEffect(() => {
    if (
      messages &&
      messages.length &&
      messages[messages.length - 1].role === 'user'
    ) {
      generate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useScrollToBottom(contentRef, isGenerating);

  const gptOutputBoxClass = mergeStyles({
    height: '100%',
    maxHeight: `${maxHeightRem}rem`,
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.purpleUltraSuperLightish,

    borderTopLeftRadius: '.25rem',
    borderTopRightRadius: '.25rem',
    boxShadow: isDark
      ? '0px 0px 10px rgba(0, 0, 0, .5)'
      : '0px 0px 10px rgba(0, 0, 0, 0.0.25)',

    '.header': {
      boxSizing: 'border-box',
      lineHeight: '2rem',
      // fontFamily: 'Kalam',
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.smallPlus,
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
      gridTemplateAreas: `
      'title _ close-button'
      `,
      width: '100%',
      height: '2rem',
      backgroundColor: isDark
        ? NeutralColors.black
        : MEETINGFLOW_COLORS.purpleGrey,
      zIndex: 55,
      borderTopLeftRadius: '.25rem',
      borderTopRightRadius: '.25rem',
      borderBottom: 'none',
      padding: '0 .5rem',
      color: MEETINGFLOW_COLORS.purpleMedium,

      '.header-title': {
        gridArea: 'title',
      },
      '.header-close-button': {
        gridArea: 'close-button',
        transition: '.3s ease-in-out all',
        backgroundColor: 'transparent !important',
        color: MEETINGFLOW_COLORS.purpleSecondary,
        ':hover': {
          color: MEETINGFLOW_COLORS.tealMedium,
        },
        zIndex: 56,
      },
    },

    '.content': {
      height: 'auto',
      maxHeight: `calc(100% - ${FOOTER_HEIGHT_REM})`,
      overflowY: 'auto',
      '.messages': {
        '.system-message': {
          float: 'left',
        },
        '.assistant-message': {
          float: 'left',
          '.message-content, .current-thought-text': {
            paddingRight: isGenerating ? undefined : '2rem !important',
          },
        },
        '.user-message': {
          textAlign: 'right',
          float: 'right',

          '.message-content': {
            backgroundColor: `${
              isDark
                ? MEETINGFLOW_COLORS.orangeDark
                : MEETINGFLOW_COLORS.orangeLight
            } !important`,
          },
        },
        '.current-output': {
          float: 'left',
          '.message-content': {
            marginBottom: '1rem',
          },
        },
        '.current-thought': {
          display: 'block !important',
          margin: '0 auto !important',
          textAlign: 'center',
          float: 'none !important',
          maxWidth: '75%',
          '.message-content': {
            backgroundColor: `${
              isDark ? NeutralColors.gray220 : MEETINGFLOW_COLORS.white
            } !important`,
            fontStyle: 'italic',
            fontFamily: 'Georgia, serif',
            fontSize: FontSizes.small,
            color: isDark ? NeutralColors.gray50 : NeutralColors.gray160,
          },
        },
        '.message': {
          transition: '.3s ease-in-out all',
          clear: 'both',
          display: 'inline-block',
          margin: '.5rem',
          position: 'relative',
          '.message-label': {
            fontWeight: FontWeights.semibold,
            marginBottom: '.25rem',
            fontSize: FontSizes.small,
            textAlign: 'left',
          },
          '.message-content': {
            backgroundColor: isDark
              ? MEETINGFLOW_COLORS.tealDark
              : MEETINGFLOW_COLORS.tealVeryLight,
            animationName: 'fadeInAnimation',
            animationDuration: '.3s',
            transitionTimingFunction: 'linear',
            animationIterationCount: '1',
            animationFillMode: 'forwards',
            transition: '.5s ease-in-out all',
            padding: '.5rem',
            borderRadius: '.25rem',
            position: 'relative',

            '*': {
              color: isDark ? NeutralColors.gray30 : NeutralColors.gray150,
            },

            '&.generating': {
              backgroundColor: isDark
                ? MEETINGFLOW_COLORS.tealMedium
                : MEETINGFLOW_COLORS.teal,
              padding: '.5rem 1rem .5rem .5rem',

              '*': {
                color: `${MEETINGFLOW_COLORS.white} !important`,
              },

              a: {
                textDecoration: 'underline !important',
              },

              blockquote: {
                color: isDark
                  ? `${NeutralColors.gray40} !important`
                  : `${MEETINGFLOW_COLORS.black} !important`,

                '*': {
                  color: `${
                    isDark ? NeutralColors.gray40 : MEETINGFLOW_COLORS.black
                  } !important`,
                },
              },
            },

            h1: {
              display: 'none',
            },

            'h2, h3, h4, h5, h6': {
              margin: '.5rem 0 0 0',
              lineHeight: '1rem',
              fontWeight: FontWeights.semibold,
              color: isDark ? NeutralColors.white : MEETINGFLOW_COLORS.black,
            },

            h2: {
              display: 'inline-block',
              fontSize: FontSizes.medium,
              marginTop: '1rem',
            },

            h3: {
              fontSize: '13px',
              paddingBottom: '.25rem',
            },

            h4: {
              fontSize: FontSizes.small,
            },

            strong: {
              fontWeight: FontWeights.semibold,
              color: isDark ? NeutralColors.white : NeutralColors.black,
            },

            blockquote: {
              backgroundColor: isDark
                ? NeutralColors.black
                : NeutralColors.white,
              borderLeft: `3px solid ${
                isDark ? MEETINGFLOW_COLORS.tealLight : MEETINGFLOW_COLORS.teal
              }`,
              margin: '.5rem 0',
              padding: '.5rem',
              position: 'relative',
              borderRadius: '.25rem',
              fontFamily: 'Georgia, serif',
              fontStyle: 'italic',
              fontSizes: FontSizes.small,
              lineHeight: '1.25rem',

              '*': {
                fontSize: FontSizes.small,
                color: isDark ? NeutralColors.gray40 : MEETINGFLOW_COLORS.black,
                lineHeight: '1.25rem',
              },

              'p:only-child': {
                marginTop: 0,
                marginBottom: 0,
              },

              'ol:first-child, ul:first-child': {
                padding: `0 0 0 .75rem`,
              },

              'ol:only-child, ul:only-child': {
                marginTop: 0,
                marginBottom: 0,
              },
            },

            'span, p, ol, ul': {
              fontSize: '12px',
              lineHeight: '1rem',
              margin: '0 0 0 0',
              color: isDark ? NeutralColors.gray30 : NeutralColors.gray200,
            },

            'ol, ul': {
              padding: `0 0 0 1.5rem`,
              marginBottom: '.75rem',
              marginTop: '.25rem',
            },

            'ol ol, ul ul': {
              padding: `0 0 0 .5rem`,
            },

            li: {
              marginLeft: 0,
              lineHeight: '1.25rem',
            },

            img: {
              margin: '0',
            },

            br: {
              margin: '0 0 .5rem 0 !important',
            },

            hr: {
              border: 0,
              margin: '.5rem 0 ',
              height: `1px`,
              backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0))`,
            },

            'hr:last-child': {
              display: 'none',
            },

            a: {
              verticalAlign: 'text-bottom',
              display: 'inline-block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: 'calc(100% - 1rem)',
              color: isDark
                ? MEETINGFLOW_COLORS.tealSuperLight
                : MEETINGFLOW_COLORS.purpleDark,
              lineHeight: '1rem',
              transition: '.3s ease-in-out all',

              ':hover': {
                textDecoration: 'underline',
                color: isDark
                  ? MEETINGFLOW_COLORS.white
                  : MEETINGFLOW_COLORS.purpleDark,
              },
            },
          },
        },
      },
    },

    '.footer': {
      height: FOOTER_HEIGHT_REM,
      '.actions': {
        opacity: isGenerating ? 0 : 1,
        margin: '.25rem 0',
        height: '1.5rem',
        padding: '0 .5rem 1rem .5rem',
        transition: '.3s ease-in-out all',
        display: 'flex',
        overflowX: 'auto',
        overflowY: 'hidden',
        '.action': {
          backgroundColor: MEETINGFLOW_COLORS.tealDark,
          color: MEETINGFLOW_COLORS.white,
          FontWeights: FontWeights.semibold,
          display: 'inline-block',
          padding: '.25rem .75rem .25rem .25rem',
          fontSize: FontSizes.small,
          cursor: 'pointer',
          borderRadius: '1rem',
          marginRight: '.25rem',
          transition: '.3s ease-in-out all',
          height: '1rem',
          lineHeight: '.85rem',
          whiteSpace: 'nowrap',

          i: {
            margin: '0 .25rem 0 .25rem',
            position: 'relative',
            top: '1px',
          },

          ':hover': {
            backgroundColor: MEETINGFLOW_COLORS.teal,
          },
        },
        '.action-action': {
          backgroundColor: isDark
            ? NeutralColors.gray140
            : NeutralColors.gray100,
          color: NeutralColors.white,
        },
      },
      '.user-input': {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridTemplateAreas: `
        'input-box send-button'
        `,
        width: '100%',
        position: 'absolute',
        bottom: 0,

        '.user-input-box': {
          gridArea: 'input-box',
          margin: '.25rem',
          cursor: isGenerating ? 'not-allowed !important' : 'text',
        },
        '.user-input-send': {
          gridArea: 'send-button',
          margin: '.25rem .25rem .25rem 0',
          cursor: isGenerating ? 'not-allowed' : 'text',
        },
      },
    },
  });

  const linkProperties = {
    target: '_blank',
    rel: 'nofollow noopener noreferrer',
  };

  return (
    <div className={classNames(gptOutputBoxClass, 'gpt-chat-box')}>
      <div className="header">
        <span className="header-title">{title ?? 'Meetingflow Assistant'}</span>
        <IconButton
          className="header-close-button"
          iconProps={{
            iconName: 'ChromeClose',
          }}
          onClick={onClose}
        />
      </div>
      <div className="content" ref={contentRef}>
        <div className="messages">
          {messages
            .filter((message) => !!message.display)
            .map((message, index) => {
              const label =
                message.role === 'user' ? userName : titleCase(message.role);
              return (
                <div
                  key={`${message.content}_${index}`}
                  className={classNames(
                    'message',
                    `${message.role}-message`,
                    message.error ? 'error-message' : undefined,
                  )}
                >
                  <div className="message-label">{label}:</div>
                  {navigator.clipboard &&
                  !isGenerating &&
                  message.role !== 'user' &&
                  !message.error ? (
                    <IconButton
                      iconProps={{ iconName: 'Copy' }}
                      onClick={() => {
                        navigator.clipboard.writeText(message.content);
                        toast.success(`Copied message to the clipboard.`);
                      }}
                      styles={{
                        root: {
                          width: '1rem',
                          height: '1rem',
                          position: 'absolute',
                          top: '1.5rem',
                          right: '.25rem',
                          zIndex: '500',
                        },
                        icon: { fontSize: '10px' },
                      }}
                      title={`Copy message to clipboard`}
                    />
                  ) : null}
                  <ReactMarkdown
                    className="message-content"
                    rehypePlugins={[
                      [
                        rehypeExternalLinks,
                        {
                          target: '_blank',
                          rel: ['noopener', 'nofollow', 'noreferrer'],
                        } satisfies RehypeExternalLinksOptions,
                      ],
                    ]}
                    remarkPlugins={[remarkGfm]}
                  >
                    {message.displayText || message.content}
                  </ReactMarkdown>
                  {message.error ? (
                    <div className="message-error-retry">
                      <Link
                        onClick={() => {
                          setMessages((oldMessages) =>
                            oldMessages.filter((m) => m !== message),
                          );
                        }}
                      >
                        Try again
                      </Link>
                    </div>
                  ) : null}
                </div>
              );
            })}

          {currentOutput ? (
            <div className="current-output assistant-message message">
              <div className="message-label">Meetingflow AI:</div>
              <ReactMarkdown
                className="message-content"
                rehypePlugins={[
                  [
                    rehypeExternalLinks,
                    {
                      target: '_blank',
                      rel: ['noopener', 'nofollow', 'noreferrer'],
                    } satisfies RehypeExternalLinksOptions,
                  ],
                ]}
                remarkPlugins={[remarkGfm]}
              >
                {currentOutput}
              </ReactMarkdown>
            </div>
          ) : null}

          {currentThought ? (
            <div className="current-thought message">
              <div className="current-thought-text message-content">
                <Spinner
                  size={SpinnerSize.small}
                  styles={{
                    root: {
                      display: 'block',
                      margin: '0 auto .5rem auto',
                      position: 'relative',
                      top: '2px',
                      maxWidth: '1rem',
                    },
                  }}
                />
                {currentThought}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="footer">
        <div className="user-input">
          <TextField
            className="user-input-box"
            disabled={isGenerating}
            value={userInput}
            placeholder={chatInputPlaceholder}
            onChange={(_, newValue) => setUserInput(newValue || '')}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && userInput) {
                setUserInput('');
                setMessages([
                  ...messages,
                  { role: 'user', display: true, content: userInput },
                ]);
              }
            }}
          />
          <IconButton
            className="user-input-send"
            iconProps={{ iconName: 'Send' }}
            disabled={!userInput || isGenerating}
            onClick={() => {
              if (userInput) {
                setUserInput('');
                setMessages([
                  ...messages,
                  {
                    role: 'user',
                    display: true,
                    name: userName,
                    content: userInput,
                  },
                ]);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
