import { useAuth0 } from '@auth0/auth0-react';
import {
  ContextualMenu,
  DefaultButton,
  FontIcon,
  FontSizes,
  FontWeights,
  IContextualMenuItem,
  Link,
  NeutralColors,
  SelectionMode,
  Text,
  mergeStyles,
} from '@fluentui/react';
import {
  MeetingflowTemplate,
  PatchTaskPayload,
} from '@meetingflow/common/Api/data-contracts';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { AxiosResponse } from 'axios';
import { set } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import { EMPTY_ARRAY } from '../../../../Constants';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { OrganizationTemplatesQuery } from '../../../../QueryNames';
import { TemplatesApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { OrganizationSlugRouteParams } from '../../../../types/RouteParams';
import { useBoolean } from '@fluentui/react-hooks';

export const TemplateList = ({ showOnCard = true }) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const navigate = useNavigate();
  const { organizationSlug: orgSlugParam, objectId } = useParams<
    OrganizationSlugRouteParams & {
      objectId?: string;
    }
  >();

  const { organization, slug: orgSlug } = useOrganization();

  const organizationSlug = orgSlugParam || orgSlug;

  const templateId = objectId;

  const {
    data: templates,
    isLoading: templatesLoading,
    isRefetching: templatesRefetching,
  } = useQuery(OrganizationTemplatesQuery(organizationSlug!), async () => {
    const token = await getAccessTokenSilently();
    return TemplatesApiClient.getTemplates(
      { organizationSlug: organizationSlug! },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  useEffect(() => {
    if (
      !templateId &&
      templateId !== 'new' &&
      !templatesRefetching &&
      templates?.data?.length
    ) {
      navigate(
        `/organization/${organizationSlug}/library/templates/${templates?.data?.[0]?.id}`,
      );
    }
  }, [
    templates?.data,
    navigate,
    organizationSlug,
    templateId,
    templatesRefetching,
  ]);

  const items = useMemo(
    () => templates?.data.map((t) => ({ key: `${t.id}`, ...t })) || EMPTY_ARRAY,
    [templates?.data],
  );

  const templateDetailsList = items.length ? (
    <div>
      {items?.map((t) => (
        <TemplateCard
          key={t.id}
          template={t}
          organizationSlug={organizationSlug!}
        />
      ))}
    </div>
  ) : (
    <>There are no templates in your workspace yet. Create the first one!</>
  );

  return templateDetailsList;
};

interface TemplateCardProps {
  template: MeetingflowTemplate;
  organizationSlug: string;
}

const TemplateCard: React.FC<TemplateCardProps> = ({
  template: t,
  organizationSlug,
}) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { userId } = useUserProfile();
  const navigate = useNavigate();
  const { isDark } = useLightOrDarkMode();
  const client = useQueryClient();
  const [
    isContextMenuVisible,
    { setFalse: setContextMenuNotVisible, toggle: toggleContextMenuVisibility },
  ] = useBoolean(false);

  const { isAdmin } = useOrganization(organizationSlug);

  const { mutate: setDefaultTemplate } = useMutation({
    mutationKey: 'setDefaultTemplate',
    mutationFn: async (update: {
      id: MeetingflowTemplate['id'];
      defaultType: 'user' | 'organization';
    }) => {
      const token = await getAccessTokenSilently();
      switch (update.defaultType) {
        case 'organization':
          return TemplatesApiClient.setOrgDefaultTemplate(
            organizationSlug!,
            {
              templateId: update.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );

        case 'user':
          TemplatesApiClient.setUserDefaultTemplate(
            organizationSlug!,
            userId!,
            {
              templateId: update.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
      }
    },
    onMutate: async ({ id, defaultType }) => {
      const previousTemplates = client.getQueryData<
        AxiosResponse<MeetingflowTemplate[]>
      >(OrganizationTemplatesQuery(organizationSlug));

      client.setQueryData(
        OrganizationTemplatesQuery(organizationSlug),
        (old: AxiosResponse<MeetingflowTemplate[]> | undefined) => {
          if (!old) {
            return {
              config: {},
              headers: {},
              status: 404,
              statusText: '',
              request: {},
              data: [],
            };
          }
          return {
            ...old,
            data: old.data.map((template) => ({
              ...template,
              isOrgDefault:
                defaultType === 'organization'
                  ? template.id === id
                    ? true
                    : false
                  : template.isOrgDefault,
              isUserDefault:
                defaultType === 'user'
                  ? template.id === id
                    ? true
                    : false
                  : template.isUserDefault,
            })),
          } satisfies AxiosResponse<MeetingflowTemplate[]>;
        },
      );

      return { previousTemplates };
    },
    onError: (err, { defaultType }, context) => {
      if (context?.previousTemplates) {
        client.setQueryData(
          OrganizationTemplatesQuery(organizationSlug),
          context.previousTemplates,
        );
      }
      console.error(err);
      toast.error(
        `Something went wrong updating the default ${defaultType} template, please try again.`,
      );
    },
    onSuccess: (data, { defaultType }) => {
      const updatedTemplate = data?.data;
      if (updatedTemplate) {
        client.setQueryData(
          OrganizationTemplatesQuery(organizationSlug),
          (old: AxiosResponse<MeetingflowTemplate[]> | undefined) => {
            if (!old) {
              return {
                config: {},
                headers: {},
                status: 200,
                statusText: '',
                request: {},
                data: [updatedTemplate],
              };
            }

            return {
              ...old,
              data:
                old?.data?.map((template) =>
                  template.id === updatedTemplate.id
                    ? updatedTemplate
                    : template,
                ) || [],
            };
          },
        );
      }
      toast.success(`Default ${defaultType} template was updated`);
    },
    onSettled: () => {
      client.refetchQueries(OrganizationTemplatesQuery(organizationSlug));
    },
  });

  const { mutate: unsetDefaultTemplate } = useMutation({
    mutationKey: 'unsetDefaultTemplate',
    mutationFn: async (update: { defaultType: 'user' | 'organization' }) => {
      const token = await getAccessTokenSilently();
      switch (update.defaultType) {
        case 'organization':
          return TemplatesApiClient.unsetOrgDefaultTemplate(organizationSlug!, {
            headers: { Authorization: `Bearer ${token}` },
          });

        case 'user':
          TemplatesApiClient.unsetUserDefaultTemplate(
            organizationSlug!,
            userId!,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
      }
    },
    onMutate: async ({ defaultType }) => {
      const previousTemplates = client.getQueryData<
        AxiosResponse<MeetingflowTemplate[]>
      >(OrganizationTemplatesQuery(organizationSlug));

      client.setQueryData(
        OrganizationTemplatesQuery(organizationSlug),
        (old: AxiosResponse<MeetingflowTemplate[]> | undefined) => {
          if (!old) {
            return {
              config: {},
              headers: {},
              status: 404,
              statusText: '',
              request: {},
              data: [],
            };
          }
          return {
            ...old,
            data: old.data.map((template) => ({
              ...template,
              isOrgDefault:
                defaultType === 'organization' ? false : template.isOrgDefault,
              isUserDefault:
                defaultType === 'user' ? false : template.isUserDefault,
            })),
          } satisfies AxiosResponse<MeetingflowTemplate[]>;
        },
      );

      return { previousTemplates };
    },
    onError: (err, { defaultType }, context) => {
      if (context?.previousTemplates) {
        client.setQueryData(
          OrganizationTemplatesQuery(organizationSlug),
          context.previousTemplates,
        );
      }
      console.error(err);
      toast.error(
        `Something went wrong updating the default ${defaultType} template, please try again.`,
      );
    },
    onSuccess: (data, { defaultType }) => {
      const updatedTemplate = data?.data;
      if (updatedTemplate) {
        client.setQueryData(
          OrganizationTemplatesQuery(organizationSlug),
          (old: AxiosResponse<MeetingflowTemplate[]> | undefined) => {
            if (!old) {
              return {
                config: {},
                headers: {},
                status: 200,
                statusText: '',
                request: {},
                data: [updatedTemplate],
              };
            }

            return {
              ...old,
              data:
                old?.data?.map((template) =>
                  template.id === updatedTemplate.id
                    ? updatedTemplate
                    : template,
                ) || [],
            };
          },
        );
      }
      toast.success(`Default ${defaultType} template was updated`);
    },
    onSettled: () => {
      client.refetchQueries(OrganizationTemplatesQuery(organizationSlug));
    },
  });

  const templateCardClass = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '.5rem',
    padingBottom: '.5rem',
    borderBottom: `1px solid ${
      isDark ? NeutralColors.gray190 : MEETINGFLOW_COLORS.purpleGrey
    }`,

    '.template-title': {
      display: 'flex',
      alignItems: 'flex-start',
      fontSize: FontSizes.mediumPlus,
      fontWeight: FontWeights.semibold,
      textDeoration: 'none !important',
      columnGap: '.25rem',
      '*': {
        color: isDark ? NeutralColors.white : MEETINGFLOW_COLORS.purpleMedium,
        textDeoration: 'none !important',
      },

      '.title-text': {
        flexBasis: '100%',
        cursor: 'pointer',
      },
    },

    '.template-description': {
      display: 'flex',
      alignItems: 'flex-start',
      fontSize: FontSizes.small,
      fontWeight: FontWeights.semibold,
      paddingBottom: '.5rem',
    },

    '.default': {
      marginLeft: '.5rem',
      color: MEETINGFLOW_COLORS.purpleMedium,
      userSelect: 'none',
      WebkitUserSelect: 'none',
    },

    '.contextual-menu-trigger': {
      cursor: 'pointer',
      marginLeft: '.5rem',
    },
  });

  const contextualMenuItems = useMemo(
    () =>
      [
        {
          key: 'edit',
          text: 'Edit',
          onClick: (
            e:
              | React.MouseEvent<HTMLElement, MouseEvent>
              | React.KeyboardEvent<HTMLElement>
              | undefined,
            item: IContextualMenuItem,
          ) => {
            e?.preventDefault();
            setContextMenuNotVisible();
            navigate(
              `/organization/${organizationSlug}/library/templates/${t.id}`,
            );
          },
        },
        // {
        //   key: 'set-user-default',
        //   text: 'Set as your personal default',
        //   onClick: (
        //     e:
        //       | React.MouseEvent<HTMLElement, MouseEvent>
        //       | React.KeyboardEvent<HTMLElement>
        //       | undefined,
        //     item: IContextualMenuItem,
        //   ) => {
        //     e?.preventDefault();
        //     setIsContextMenuVisible(false);
        //     setDefaultTemplate({ id: t.id, defaultType: 'user' });
        //   },
        // },
        isAdmin
          ? {
              key: 'set-unser-org-default',
              text: t.isOrgDefault
                ? 'Unset as workspace default'
                : 'Set as workspace default',
              onClick: (
                e:
                  | React.MouseEvent<HTMLElement, MouseEvent>
                  | React.KeyboardEvent<HTMLElement>
                  | undefined,
                item: IContextualMenuItem,
              ) => {
                e?.preventDefault();
                setContextMenuNotVisible();
                if (t.isOrgDefault) {
                  unsetDefaultTemplate({ defaultType: 'organization' });
                } else {
                  setDefaultTemplate({ id: t.id, defaultType: 'organization' });
                }
              },
            }
          : undefined,
      ].filter(Truthy) as IContextualMenuItem[],
    [
      isAdmin,
      navigate,
      organizationSlug,
      setContextMenuNotVisible,
      setDefaultTemplate,
      t.id,
      t.isOrgDefault,
      unsetDefaultTemplate,
    ],
  );

  return (
    <div className={templateCardClass}>
      <span className="template-title">
        <div
          className="title-text"
          onClick={() => {
            navigate(
              `/organization/${organizationSlug}/library/templates/${t.id}`,
            );
          }}
        >
          {t.name}
        </div>
        {/* {t.isUserDefault ? (
          <FontIcon iconName="Contact" className="default user-default" />
        ) : null} */}
        {t.isOrgDefault ? (
          <FontIcon
            title="Workspace Default Template"
            iconName="Org"
            className="default org-default"
          />
        ) : null}
        <FontIcon
          iconName="More"
          id={`template-menu-${t.id}`}
          className="contextual-menu-trigger"
          onClick={toggleContextMenuVisibility}
        />
      </span>
      <Text className="template-description">{t.description}</Text>
      <ContextualMenu
        items={contextualMenuItems}
        target={`#template-menu-${t.id}`}
        hidden={!isContextMenuVisible}
      />
    </div>
  );
};
