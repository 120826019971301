import {
  DefaultButton,
  Dialog,
  DialogFooter,
  ITextField,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDeferredPromise } from '../useDeferredPromise';
import { useLightOrDarkMode } from '../useLightOrDarkMode';

export type CustomFieldPresetResult = {
  name: string;
  description: string | null;
};

type CustomFieldsetDialogProps = {
  initialName?: string;
  initalDescription?: string | null;
  onSubmit: (
    value: CustomFieldPresetResult | PromiseLike<CustomFieldPresetResult>,
  ) => void;
  onCancel: (reason?: unknown) => void;
};

const CustomFieldsetDialog = ({
  initialName,
  initalDescription,
  onSubmit,
  onCancel,
}: CustomFieldsetDialogProps) => {
  const { isDark } = useLightOrDarkMode();

  const [name, setName] = useState<string>(initialName || '');
  const [description, setDescription] = useState<string>(
    initalDescription || '',
  );

  useEffect(() => {
    setName(initialName || '');
    setDescription(initalDescription || '');
  }, [initialName, initalDescription]);

  const nameFieldRef = useRef<ITextField>(null);
  const descriptionFieldRef = useRef<ITextField>(null);

  useEffect(() => {
    // Effects run after the render pass, but before the DOM has necessarily finished updating, so the refs may be null
    // Use set immediate to execute after one tick, so the DOM has finished updating and the refs are bound
    setImmediate(() => {
      if (!initialName && nameFieldRef.current) {
        nameFieldRef.current.focus();
      } else if (descriptionFieldRef.current) {
        descriptionFieldRef.current.focus();
      }
    });
  }, [initialName, nameFieldRef, descriptionFieldRef]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && !!name) {
        onSubmit({ name, description: description || null });
      }
    },
    [name, onSubmit, description],
  );

  return (
    <Dialog
      dialogContentProps={{
        title: initialName
          ? 'Update Custom Field Preset'
          : 'Create Custom Field Preset',
        showCloseButton: true,
        styles: {},
      }}
      hidden={false}
      styles={{ main: { width: '60%' } }}
      onDismiss={onCancel}
    >
      <div>
        <TextField
          label="Name"
          value={name}
          componentRef={nameFieldRef}
          onKeyDown={handleKeyPress}
          onChange={(_e, newText) => setName(newText || '')}
        />
        <TextField
          label="Description"
          value={description}
          componentRef={descriptionFieldRef}
          onKeyDown={handleKeyPress}
          onChange={(_e, newDescription) =>
            setDescription(newDescription || '')
          }
        />
      </div>
      <DialogFooter>
        <PrimaryButton
          text={initialName ? 'Update' : 'Create'}
          disabled={!name}
          onClick={() => {
            onSubmit({ name, description: description || null });
          }}
          styles={{
            label: { color: isDark ? 'white' : undefined },
          }}
        />
        <DefaultButton text="Cancel" onClick={onCancel} />
      </DialogFooter>
    </Dialog>
  );
};

export const useCustomFieldsetDialog = () => {
  const { createDeferred, deferred, context, resolve, reject } =
    useDeferredPromise<
      CustomFieldPresetResult,
      Partial<CustomFieldPresetResult> | undefined
    >();

  const dialog = useMemo(() => {
    if (!deferred?.isPending) {
      return null;
    }

    return (
      <CustomFieldsetDialog
        initialName={context?.name}
        initalDescription={context?.description}
        onSubmit={resolve}
        onCancel={reject}
      />
    );
  }, [context, deferred?.isPending, reject, resolve]);

  return {
    createDeferred,
    dialog,
  };
};
