import { useAuth0 } from '@auth0/auth0-react';
import { mergeStyles, Spinner, Stack, Text, useTheme } from '@fluentui/react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { DEFAULT_STACK_TOKENS } from '../Helpers/Layout';
import { useLocalStorageState } from '../Hooks/useLocalStorageState';
import { useTitle } from '../Hooks/useTitle';
import { InvitesQuery, OrganizationsQuery } from '../QueryNames';
import {
  InvitesApiClient,
  OrganizationsApiClient,
} from '../Services/NetworkCommon';
import { Card } from './Card';
import { PageLayout } from './Layouts/PageLayout';
import { OrganizationInviteItem } from './OrganizationInviteItem';
import { OrganizationPersona } from './OrganizationPersona';

export const Home = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { name } = user!;
  const theme = useTheme();
  const navigate = useNavigate();

  const [activeOrgId] = useLocalStorageState<number | undefined>(
    'active-org-id',
    undefined,
  );

  useTitle();

  const { data: inviteData, refetch: refetchInvites } = useQuery(
    InvitesQuery('RECEIVED'),
    async () => {
      const token = await getAccessTokenSilently();
      return InvitesApiClient.getInvites(
        { type: 'RECEIVED' },
        { headers: { Authorization: `Bearer ${token}` } },
      );
    },
  );

  const { data: orgData, isLoading: orgDataIsLoading } = useQuery(
    OrganizationsQuery,
    async () => {
      const token = await getAccessTokenSilently();
      return OrganizationsApiClient.listOrganizations(
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  useEffect(() => {
    if (orgData?.data?.length) {
      if (activeOrgId) {
        const lastUsedOrg = orgData.data.filter(
          (org) => org.id === activeOrgId,
        )[0];
        if (!!lastUsedOrg) {
          navigate(`/organization/${lastUsedOrg.slug}`);
          return;
        }
      }

      if (orgData.data.length === 1) {
        navigate(`/organization/${orgData.data[0].slug}`);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrgId, orgData?.data]);

  return (
    <PageLayout
      primaryContent={
        <Card
          title={`Welcome to ${import.meta.env.VITE_SITE_NAME}, ${name!.split(' ')?.[0]}`}
          description={`To get started using ${import.meta.env.VITE_SITE_NAME}, view one of your workspaces.`}
          contentContainerPadding={'1rem'}
        >
          {inviteData?.data?.length ? (
            <div>
              <Text block variant="large" style={{ marginBottom: '.5rem' }}>
                Pending Invitations
              </Text>
              {inviteData.data.map((i) => (
                <OrganizationInviteItem
                  key={i.id}
                  invite={i}
                  refreshInvites={refetchInvites}
                />
              ))}
            </div>
          ) : undefined}
          <div>
            {orgDataIsLoading ? (
              <Spinner />
            ) : (
              <>
                <Text block variant="large" style={{ marginBottom: '.5rem' }}>
                  Your Workspaces
                </Text>
                <Stack horizontal tokens={DEFAULT_STACK_TOKENS}>
                  {orgData?.data?.map((organization) => (
                    <Stack.Item
                      grow={1}
                      key={organization.id}
                      onClick={() =>
                        navigate(`/organization/${organization.slug}`)
                      }
                      className={mergeStyles({
                        padding: '1rem',
                        ':hover': {
                          cursor: 'pointer',
                          backgroundColor: theme.palette.neutralLighter,
                        },
                      })}
                    >
                      <OrganizationPersona organization={organization} />
                    </Stack.Item>
                  ))}
                </Stack>
              </>
            )}
          </div>
        </Card>
      }
    />
  );
};

export default Home;
