import { FontWeights, mergeStyles, Text } from '@fluentui/react';
import { AsyncDefaultButton, AsyncPrimaryButton } from '../HOC/AsyncButton';
import toast from 'react-hot-toast';
import { DomainRule } from '@meetingflow/common/Api/data-contracts';
import { Organizations } from '@meetingflow/common/Api/OrganizationsRoute';
import { useAuth0 } from '@auth0/auth0-react';
import { RequestsApiClient } from '../../Services/NetworkCommon';

export type WelcomeOrganizationJoinProps = {
  organizationSlug?: string;
  onJoinOrg: () => Promise<unknown>;
  onRequestAccess: () => Promise<unknown>;
  onCancelRequest: () => Promise<unknown>;
  organization: Organizations.ListOrganizationSuggestions.ResponseBody[0];
};
export const WelcomeOrganizationJoin = ({
  organization,
  onJoinOrg,
  onRequestAccess,
  onCancelRequest,
}: WelcomeOrganizationJoinProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const joinOrg = async (domainRule: DomainRule) => {
    const token = await getAccessTokenSilently();
    await RequestsApiClient.postRequest(organization.slug, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (domainRule.ruleType === 'AUTO_APPROVE') {
      toast(`Successfully joined ${organization.name}`);
      onJoinOrg();
    }
    if (domainRule.ruleType === 'CAN_REQUEST_ACCESS') {
      toast(`Requested membership of ${organization.name}`);
      onRequestAccess();
    }
  };

  const cancelRequest = async (requestId: number) => {
    try {
      const token = await getAccessTokenSilently();
      await RequestsApiClient.deleteRequest(organization.slug, requestId, {
        headers: { Authorization: `Bearer ${token}` },
      });
      onCancelRequest();
    } catch {
      toast.error(
        `Something went wrong cancelling request, please try again later`,
      );
    }
  };

  const singleInviteClass = mergeStyles({
    textAlign: 'center',
    width: '75%',
    margin: '0 auto',
    padding: '.5rem',
  });

  if (organization.pendingRequests.length) {
    const request = organization.pendingRequests[0];
    return (
      <div className={singleInviteClass}>
        <Text
          variant="xxLarge"
          style={{
            fontWeight: FontWeights.regular,
          }}
        >
          Hey, welcome! The page you're trying to access requires membership of
          the <strong>{organization.name}</strong> workspace. You are not
          currently a member, but you have a pending request to join with{' '}
          <strong>{request.role}</strong> role. <br></br>
          We'll send you an email when your request is approved. If you'd like
          to cancel your pending request, you may do so below.
        </Text>

        <br />
        <br />

        <AsyncDefaultButton onClick={() => cancelRequest(request.id)}>
          <span>Cancel Request to Join {organization.name}</span>
        </AsyncDefaultButton>
      </div>
    );
  }

  if (organization.domainRules.length) {
    const domainRule = organization.domainRules[0];

    if (domainRule.ruleType === 'AUTO_APPROVE') {
      return (
        <div className={singleInviteClass}>
          <Text
            variant="xxLarge"
            style={{
              fontWeight: FontWeights.regular,
            }}
          >
            Hey, welcome! The page you're trying to access requires membership
            of the <strong>{organization.name}</strong> workspace. Would you
            like to join <strong>{organization.name}</strong> and access this
            page?
          </Text>

          <br />
          <br />

          <AsyncPrimaryButton onClick={() => joinOrg(domainRule)}>
            <span>
              Join <strong>{organization.name}</strong>
            </span>
          </AsyncPrimaryButton>
        </div>
      );
    } else if (domainRule.ruleType === 'CAN_REQUEST_ACCESS') {
      return (
        <div className={singleInviteClass}>
          <Text
            variant="xxLarge"
            style={{
              fontWeight: FontWeights.regular,
            }}
          >
            Hey, welcome! The page you're trying to access requires membership
            of the{' '}
            <strong>
              <strong>{organization.name}</strong>
            </strong>{' '}
            workspace. You're not currently a member, but you can request
            access. An administrator from <strong>{organization.name}</strong>{' '}
            will need to approve you're request. We'll let you know when they
            do!
          </Text>

          <br />
          <br />

          <AsyncPrimaryButton onClick={() => joinOrg(domainRule)}>
            <span>Request Access to {organization.name}</span>
          </AsyncPrimaryButton>
        </div>
      );
    }
  }

  return null;
};

export default WelcomeOrganizationJoin;
