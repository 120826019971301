import { useAuth0 } from '@auth0/auth0-react';
import { mergeStyles } from '@fluentui/react';
import {
  AsyncDefaultButton,
  AsyncIconButton,
  AsyncPrimaryButton,
} from './HOC/AsyncButton';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import toast from 'react-hot-toast';
import { OrganizationInvite } from '@meetingflow/common/Api/data-contracts';
import { InvitesApiClient } from '../Services/NetworkCommon';

export type OrganizationInviteProps = {
  invite: OrganizationInvite;
  refreshInvites?: () => void;
  onAcceptInvite?: () => void;
  onDeclineInvite?: () => void;
  iconsOnly?: boolean;
};

export const OrganizationInviteItem = ({
  invite,
  refreshInvites,
  onAcceptInvite,
  onDeclineInvite,
  iconsOnly = true,
}: OrganizationInviteProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const appInsights = useAppInsightsContext();
  const { email } = user!;
  const recipient = invite.invitee.email.toLowerCase() === email!.toLowerCase();

  const acceptInvite =
    recipient && onAcceptInvite
      ? async () => {
          try {
            const token = await getAccessTokenSilently();
            await InvitesApiClient.acceptInvite(invite.id, {
              headers: { Authorization: `Bearer ${token}` },
            });

            toast(
              `Accepted invitation from ${
                invite.inviter.name || invite.inviter.email
              } to join organization ${invite.organization.name}`,
            );

            onAcceptInvite?.();
            refreshInvites?.();

            appInsights.trackEvent({
              name: 'ACCEPT_INVITATION',
              properties: {
                organizationId: invite.organization.id,
                inviteId: invite.id,
                inviterId: invite.inviter.id,
                inviterEmail: invite.inviter.email,
              },
            });
          } catch {
            toast.error(
              `Something went wrong accepting invitation, please try again`,
            );
          }
        }
      : undefined;

  const deleteInvite = async () => {
    try {
      const token = await getAccessTokenSilently();
      await InvitesApiClient.deleteInvite(invite.id, {
        headers: { Authorization: `Bearer ${token}` },
      });

      onDeclineInvite?.();
      refreshInvites?.();

      appInsights.trackEvent({
        name: 'DECLINE_INVITATION',
        properties: {
          organizationId: invite.organization.id,
          inviteId: invite.id,
          inviterId: invite.inviter.id,
          inviterEmail: invite.inviter.email,
        },
      });
    } catch {
      toast.error(`Something went wrong deleting invite, please try again`);
    }
  };

  const ButtonComponent = iconsOnly ? AsyncIconButton : AsyncPrimaryButton;
  const SecondaryButtonComponent = iconsOnly
    ? AsyncIconButton
    : AsyncDefaultButton;

  const buttonGroupClass = mergeStyles({
    button: {
      marginRight: '1rem',
      ':last-child': {
        marginRight: 0,
      },
    },
  });

  return (
    <>
      {!acceptInvite ? (
        <>
          <ButtonComponent
            name="Delete"
            iconProps={{ iconName: 'Delete' }}
            onClick={deleteInvite}
            text="Delete"
          />
        </>
      ) : (
        <div className={buttonGroupClass}>
          <SecondaryButtonComponent
            name="Decline"
            iconProps={{ iconName: 'Delete' }}
            onClick={deleteInvite}
            text="Decline"
          />
          <ButtonComponent
            name="Accept"
            iconProps={{ iconName: 'Accept' }}
            onClick={acceptInvite}
            text="Accept"
            className="primary"
          />
        </div>
      )}
    </>
  );
};
