import {
  FontSizes,
  FontWeights,
  ISeparatorStyles,
  Separator,
} from '@fluentui/react';
import React from 'react';

interface StyledSeparatorProps {
  children?: React.ReactNode;
  marginTop?: string | number;
  marginBottom?: string | number;
  backgroundColor?: string;
  textColor?: string;
}

export const StyledSeparator: React.FunctionComponent<StyledSeparatorProps> = ({
  children,
  marginTop = undefined,
  marginBottom = undefined,
  backgroundColor = undefined,
  textColor = undefined,
}) => {
  const styles: ISeparatorStyles = {
    root: {
      fontSize: FontSizes.mediumPlus,
      fontWeight: FontWeights.semibold,
      height: '1rem',
      lineHeight: '1rem',
      marginTop: `${marginTop || '.5rem'} !important`,
      marginBottom: `${marginBottom || '.5rem'} !important`,
      ':before': {
        display: 'none',
      },
    },
    content: {
      height: '1rem',
      lineHeight: '1rem',
      borderRadius: '.25rem',
      margin: 0,
      color: `${textColor ? textColor : undefined} !important`,
      width: '100%',
      backgroundColor: 'transparent',
      textAlign: 'left',
      display: 'block',
      padding: 0,
    },
  };

  return <Separator styles={styles}>{children}</Separator>;
};
