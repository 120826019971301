type PricingAndFinancialsLogoProps = {
  variant?: 'NORMAL' | 'LARGE';
};

const variants = {
  NORMAL: {
    width: '28',
    height: '28',
    viewBox: '0 0 28 28',
    paths: [
      'M17.9031 8.23503V8.09696C17.9705 8.11721 18.0371 8.1402 18.103 8.16578C18.037 8.19158 17.9703 8.2147 17.9031 8.23503ZM19.898 10.0915H19.7574C19.778 10.0226 19.8014 9.95436 19.8275 9.88706C19.8537 9.95437 19.8773 10.0226 19.898 10.0915ZM21.7531 8.09581V8.23635C21.6842 8.21567 21.616 8.19211 21.5487 8.16584C21.616 8.13974 21.6842 8.11635 21.7531 8.09581ZM19.8967 6.2415C19.8763 6.30866 19.8532 6.37533 19.8274 6.44135C19.8018 6.37552 19.7788 6.30885 19.7586 6.2415H19.8967Z',
      'M17.9031 19.9015V19.7635C17.9705 19.7837 18.0371 19.8067 18.103 19.8323C18.037 19.8581 17.9703 19.8812 17.9031 19.9015ZM19.898 21.758H19.7574C19.778 21.6891 19.8014 21.6209 19.8275 21.5536C19.8537 21.6209 19.8773 21.6891 19.898 21.758ZM21.7531 19.7623V19.9029C21.6842 19.8822 21.616 19.8586 21.5487 19.8323C21.616 19.8062 21.6842 19.7829 21.7531 19.7623ZM19.8967 17.908C19.8763 17.9752 19.8532 18.0418 19.8274 18.1079C19.8018 18.042 19.7788 17.9754 19.7586 17.908H19.8967Z',
      'M9.6224 9.0415C9.6224 11.0832 7.58073 13.1248 5.53906 13.1248V14.8748C7.58073 14.8748 9.6224 16.9048 9.6224 18.9582H11.3724C11.3724 16.9048 13.4024 14.8748 15.4557 14.8748V13.1248C13.4024 13.1248 11.3724 11.0832 11.3724 9.0415H9.6224ZM11.3724 9.0415C11.3724 7.87484 9.6224 7.87484 9.6224 9.0415C9.6224 12.0515 12.4341 14.8748 15.4557 14.8748C16.6224 14.8748 16.6224 13.1248 15.4557 13.1248C12.4341 13.1248 9.6224 15.9365 9.6224 18.9582C9.6224 20.1248 11.3724 20.1248 11.3724 18.9582C11.3724 15.9365 8.54906 13.1248 5.53906 13.1248C4.3724 13.1248 4.3724 14.8748 5.53906 14.8748C8.54906 14.8748 11.3724 12.0515 11.3724 9.0415Z',
    ],
    strokeWidth: '1.5',
  },
  LARGE: {
    width: '48',
    height: '48',
    viewBox: '0 0 48 48',
    paths: [
      'M30.6922 14.1172V13.8805C30.8076 13.9152 30.9219 13.9547 31.0348 13.9985C30.9216 14.0428 30.8073 14.0824 30.6922 14.1172ZM34.1119 17.2998H33.871C33.9062 17.1816 33.9463 17.0647 33.991 16.9493C34.0361 17.0647 34.0765 17.1816 34.1119 17.2998ZM37.2922 13.8786V14.1195C37.174 14.084 37.0571 14.0437 36.9417 13.9986C37.0571 13.9539 37.174 13.9138 37.2922 13.8786ZM34.1097 10.6998C34.0748 10.8149 34.0352 10.9292 33.991 11.0424C33.9471 10.9295 33.9077 10.8152 33.873 10.6998H34.1097Z',
      'M30.6922 34.117V33.8803C30.8076 33.915 30.9219 33.9544 31.0348 33.9983C30.9216 34.0425 30.8073 34.0821 30.6922 34.117ZM34.1119 37.2995H33.871C33.9062 37.1813 33.9463 37.0644 33.991 36.949C34.0361 37.0644 34.0765 37.1813 34.1119 37.2995ZM37.2922 33.8783V34.1193C37.174 34.0838 37.0571 34.0434 36.9417 33.9984C37.0571 33.9536 37.174 33.9135 37.2922 33.8783ZM34.1097 30.6995C34.0748 30.8146 34.0352 30.9289 33.991 31.0421C33.9471 30.9292 33.9077 30.815 33.873 30.6995H34.1097Z',
      'M16.4961 15.4998C16.4961 18.9998 12.9961 22.4998 9.49609 22.4998V25.4998C12.9961 25.4998 16.4961 28.9798 16.4961 32.4998H19.4961C19.4961 28.9798 22.9761 25.4998 26.4961 25.4998V22.4998C22.9761 22.4998 19.4961 18.9998 19.4961 15.4998H16.4961ZM19.4961 15.4998C19.4961 13.4998 16.4961 13.4998 16.4961 15.4998C16.4961 20.6598 21.3161 25.4998 26.4961 25.4998C28.4961 25.4998 28.4961 22.4998 26.4961 22.4998C21.3161 22.4998 16.4961 27.3198 16.4961 32.4998C16.4961 34.4998 19.4961 34.4998 19.4961 32.4998C19.4961 27.3198 14.6561 22.4998 9.49609 22.4998C7.49609 22.4998 7.49609 25.4998 9.49609 25.4998C14.6561 25.4998 19.4961 20.6598 19.4961 15.4998Z',
    ],
    strokeWidth: '2.4',
  },
};

export const PricingAndFinancialsLogo = ({
  variant = 'NORMAL',
}: PricingAndFinancialsLogoProps) => {
  return (
    <svg
      width={variants[variant].width}
      height={variants[variant].height}
      viewBox={variants[variant].viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={variants[variant].paths[0]}
        fill="#AFAEB1"
        stroke="#AFAEB1"
        strokeWidth={variants[variant].strokeWidth}
      />
      <path
        d={variants[variant].paths[1]}
        fill="#AFAEB1"
        stroke="#AFAEB1"
        strokeWidth={variants[variant].strokeWidth}
      />
      <path d={variants[variant].paths[2]} fill="#AFAEB1" />
    </svg>
  );
};
