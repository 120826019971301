import { useAuth0 } from '@auth0/auth0-react';
import { DateRangeShortcut } from '@blueprintjs/datetime';
import { DateRange, DateRangeInput2 } from '@blueprintjs/datetime2';
import {
  Checkbox,
  ComboBox,
  FontIcon,
  FontWeights,
  IColumn,
  Link,
  mergeStyles,
  NeutralColors,
  SelectionMode,
  ShimmeredDetailsList,
} from '@fluentui/react';
import {
  AutomaticMeetingRecordingPreference,
  OrganizationUserRole,
} from '@meetingflow/common/Api/data-contracts';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { escapeCsvFormulas } from '@meetingflow/common/ObjectHelpers';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { EMPTY_ARRAY } from '../Constants';
import { isAxiosErrorResponse } from '../Helpers/AxiosHelpers';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';
import { useTitle } from '../Hooks/useTitle';
import { ApiClient } from '../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../Themes/Themes';
import { AsyncLink } from './HOC/AsyncLink';

type Usage = {
  id: number;
  email: string;
  name: string | null;
  timezone: string | null;
  membershipCreated: Date;
  role: OrganizationUserRole;
  autoRecordPreference: AutomaticMeetingRecordingPreference;
  hasCRM: boolean;
  hasChat: boolean;
  crmFieldSetName: string;
  calendarEvents: number;
  externalCalendarEvents: number;
  externalEventMeetingflows: number;
  externalEventMeetingflowsWithCallRecording: number;
  externalEventMeetingflowsLoggedToCRM: number;
  externalEventMeetingflowsWithCRMObjectUpdate: number;
  externalEventMeetingflowsSharedToChat: number;
};

const DatePickerShortcuts = [
  {
    label: 'Last 30 Days',
    dateRange: [
      DateTime.now().minus({ days: 30 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Month to Date',
    dateRange: [
      DateTime.now().startOf('month').startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Year to Date',
    dateRange: [
      DateTime.now().startOf('year').startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Last Month',
    dateRange: [
      DateTime.now().minus({ months: 1 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Last 3 Months',
    dateRange: [
      DateTime.now().minus({ months: 3 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Last 6 Months',
    dateRange: [
      DateTime.now().minus({ months: 6 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Last 12 Months',
    dateRange: [
      DateTime.now().minus({ months: 12 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
] satisfies DateRangeShortcut[];

export const AdminUsageList = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const navigate = useNavigate();

  const parentRef = useRef<HTMLDivElement>(null);

  const [activeOrganizationId, setActiveOrganizationId] = useState<
    number | undefined
  >();

  const [includeCollaborators, setIncludeCollaborators] =
    useState<boolean>(false);
  const [includeGuests, setIncludeGuests] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange>([
    DateTime.now().minus({ days: 30 }).startOf('day').toJSDate(),
    DateTime.now().endOf('day').toJSDate(),
  ]);

  useTitle('Admin Usage');

  const { data: organizationsData, isLoading: organizationsLoading } = useQuery(
    'ORGANIZATIONS',
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<
        {
          id: number;
          slug: string;
          name: string;
        }[]
      >('/admin/organizations?orderBy=name', {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    {
      onError: (err) => {
        if (isAxiosErrorResponse(err, 403)) {
          navigate('/');
        }
      },
    },
  );

  const activeOrganization = useMemo(
    () =>
      organizationsData?.data?.find(
        (organization) => organization.id === activeOrganizationId,
      ),
    [activeOrganizationId, organizationsData?.data],
  );

  const {
    data: organizationUsage,
    isLoading: organizationUsageLoading,
    refetch: refetchOrganizationUsage,
  } = useQuery(
    `ORGANIZATION_${activeOrganizationId}_USAGE_${dateRange[0]?.toISOString()}_${dateRange[1]?.toISOString()}_${
      includeCollaborators ? 'true' : 'false'
    }_${includeGuests ? 'true' : 'false'}`,
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<{
        minDateTime: string;
        maxDateTime: string;
        usage: Usage[];
      }>(
        `/admin/organization/${activeOrganizationId}/usage?minDate=${dateRange[0]?.toISOString()}&maxDate=${dateRange[1]?.toISOString()}&includeCollaborators=${includeCollaborators}&includeGuests=${includeGuests}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!activeOrganizationId,
      onError: (err) => {
        if (isAxiosErrorResponse(err, 403)) {
          navigate('/');
        }
      },
    },
  );

  useEffect(() => {
    if (!!activeOrganizationId) {
      refetchOrganizationUsage();
    }
  }, [
    activeOrganizationId,
    refetchOrganizationUsage,
    dateRange,
    includeCollaborators,
    includeGuests,
  ]);

  const onRenderBoolean = (value: boolean) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        backgroundColor: value
          ? MEETINGFLOW_COLORS.teal
          : MEETINGFLOW_COLORS.red,
      }}
    >
      {value ? (
        <FontIcon iconName="CheckMark" style={{ color: 'white' }} />
      ) : (
        <FontIcon iconName="Cancel" style={{ color: 'white' }} />
      )}
    </div>
  );

  const usageColumns: IColumn[] = useMemo(
    () =>
      [
        {
          key: 'id',
          name: 'User id',
          fieldName: 'id',
          headerClassName: 'numeric',
          className: 'numeric',
          onRender: (usage: Usage) => <div>{usage.id}</div>,
        },
        {
          key: 'name',
          name: 'Name',
          fieldName: 'name',
          onRender: (usage: Usage) => (
            <div>
              <strong>{usage.name}</strong>
              <span style={{ display: 'block' }}>
                <Link href={`mailto:${usage.email}`}>{usage.email}</Link>
              </span>
            </div>
          ),
        },
        // {
        //   key: 'email',
        //   name: 'Email',
        //   fieldName: 'email',
        //   onRender: (usage: Usage) => (
        //     <div>
        //       <Link href={`mailto:${usage.email}`}>{usage.email}</Link>
        //     </div>
        //   ),
        // },
        {
          key: 'timezone',
          name: 'Timezone',
          headerClassName: 'left-align',
          fieldName: 'timezone',
          onRender: (usage: Usage) => <div>{usage.timezone}</div>,
        },
        {
          key: 'membershipCreated',
          name: 'Membership Created',
          headerClassName: 'left-align',
          fieldName: 'membershipCreated',
          onRender: (usage: Usage) => (
            <div>{humanizeDateTime(usage.membershipCreated)}</div>
          ),
        },
        {
          key: 'role',
          name: 'Role',
          fieldName: 'role',
          onRender: (usage: Usage) => <div>{usage.role}</div>,
        },
        {
          key: 'autoRecordPreference',
          name: 'Auto Record Preference',
          headerClassName: 'left-align',
          fieldName: 'autoRecordPreference',
          onRender: (usage: Usage) => <div>{usage.autoRecordPreference}</div>,
        },
        {
          key: 'hasCRM',
          name: 'Has CRM?',
          headerClassName: 'left-align',
          fieldName: 'hasCRM',
          onRender: (usage: Usage) => onRenderBoolean(usage.hasCRM),
        },
        {
          key: 'hasChat',
          name: 'Has Chat?',
          headerClassName: 'left-align',
          fieldName: 'hasChat',
          onRender: (usage: Usage) => onRenderBoolean(usage.hasChat),
        },
        {
          key: 'crmFieldSetName',
          name: 'CRM Custom Fieldset Name',
          headerClassName: 'left-align',
          fieldName: 'crmFieldSetName',
          onRender: (usage: Usage) => <div>{usage.crmFieldSetName}</div>,
        },
        {
          key: 'calendarEvents',
          name: '# Events',
          fieldName: 'calendarEvents',
          headerClassName: 'numeric',
          className: 'numeric',
          onRender: (usage: Usage) => <div>{usage.calendarEvents}</div>,
        },
        {
          key: 'externalCalendarEvents',
          name: '# External Events',
          fieldName: 'externalCalendarEvents',
          headerClassName: 'numeric',
          className: 'numeric',
          onRender: (usage: Usage) => <div>{usage.externalCalendarEvents}</div>,
        },
        {
          key: 'externalEventMeetingflows',
          name: '# External Events w/Meetingflows',
          fieldName: 'externalEventMeetingflows',
          headerClassName: 'numeric',
          className: 'numeric',
          onRender: (usage: Usage) => (
            <div>{usage.externalEventMeetingflows}</div>
          ),
        },
        {
          key: 'externalEventMeetingflowsWithCallRecording',
          name: '# External Events w/Recording',
          fieldName: 'externalEventMeetingflowsWithCallRecording',
          headerClassName: 'numeric',
          className: 'numeric',
          onRender: (usage: Usage) => (
            <div>{usage.externalEventMeetingflowsWithCallRecording}</div>
          ),
        },
        {
          key: 'externalEventMeetingflowsLoggedToCRM',
          name: '# External Events Logged to CRM',
          fieldName: 'externalEventMeetingflowsLoggedToCRM',
          headerClassName: 'numeric',
          className: 'numeric',
          onRender: (usage: Usage) => (
            <div>{usage.externalEventMeetingflowsLoggedToCRM}</div>
          ),
        },
        {
          key: 'externalEventMeetingflowsWithCRMObjectUpdate',
          name: '# External Events W/CRM Object Updated',
          fieldName: 'externalEventMeetingflowsWithCRMObjectUpdate',
          headerClassName: 'numeric',
          className: 'numeric',
          onRender: (usage: Usage) => (
            <div>{usage.externalEventMeetingflowsWithCRMObjectUpdate}</div>
          ),
        },
        {
          key: 'externalEventMeetingflowsSharedToChat',
          name: '# External Events Shared to Chat',
          fieldName: 'externalEventMeetingflowsSharedToChat',
          headerClassName: 'numeric',
          className: 'numeric',
          onRender: (usage: Usage) => (
            <div>{usage.externalEventMeetingflowsSharedToChat}</div>
          ),
        },
      ] as IColumn[],
    [],
  );

  const usageReportWrapper = mergeStyles({
    height: 'calc(100% - 2rem)',
    width: 'auto',
    overflow: 'auto',
    margin: '1rem 0',
    padding: '1rem',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,

    '.controls': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      padding: '0',
      marginBottom: '.25rem',

      '.options': {
        display: 'flex',
        columnGap: '.5rem',
        alignItems: 'end',
        justifyItems: 'end',
        alignContent: 'end',

        '.timerange-date-picker': {
          boxSizing: 'border-box',
          // width: '360px',
          display: 'flex',
          columnGap: '.25rem',
          '.bp5-input-group': {
            // flexBasis: '50%',
          },
        },

        '.include-guest-checkbox': {
          boxSizing: 'border-box',
        },
      },

      '.export': {
        display: 'flex',

        '.export-link': {},
      },
    },

    '.left-align': {
      textAlign: 'left',
    },

    '.right-align': {
      textAlign: 'right',
    },

    '.numeric': {
      textAlign: 'right',
      '.ms-DetailsHeader-cellName': {
        width: '100%',
      },
    },

    '.ms-DetailsHeader': {
      height: '48px',
      backgroundColor: 'transparent',
    },

    '.ms-DetailsHeader-cell ': {
      height: 'auto',
      backgroundColor: 'transparent',
    },

    '.ms-DetailsHeader-cell * ': {
      fontSize: '.75rem',
      lineHeight: '1rem',
      whiteSpace: 'wrap',
    },

    '.ms-DetailsRow-cell.numeric': {
      fontSize: '1.25rem',
    },

    '.ms-DetailsRow': {
      borderBottom: `1px solid ${
        isDark ? NeutralColors.gray190 : MEETINGFLOW_COLORS.purpleGreyLight
      } `,
      backgroundColor: 'transparent',
    },

    strong: {
      fontWeight: FontWeights.semibold,
    },
  });

  return (
    <div className={usageReportWrapper}>
      <div ref={parentRef}>
        <div className="controls">
          <div className="options">
            <ComboBox
              label="Organization"
              selectedKey={activeOrganizationId}
              options={
                organizationsData?.data?.map((organization) => ({
                  key: organization.id,
                  text: organization.name,
                })) ?? []
              }
              onChange={(_e, opt) => {
                if (opt) {
                  setActiveOrganizationId(opt.key as number);
                }
              }}
              allowFreeInput
              placeholder="Select organization"
              disabled={organizationsLoading}
            />

            <DateRangeInput2
              className={classNames(
                'timerange-date-picker',
                'blueprint-component',
                isDark ? 'bp5-dark' : undefined,
              )}
              value={dateRange}
              shortcuts={DatePickerShortcuts}
              onChange={setDateRange}
              startInputProps={{ name: 'StartInput' }}
              endInputProps={{ name: 'EndInput' }}
              parseDate={(input) =>
                DateTime.fromMillis(Date.parse(input)).toJSDate()
              }
              formatDate={(date) =>
                DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT)
              }
            />

            <Checkbox
              label="Include Workspace Collaborators"
              className="include-collaborators-checkbox"
              checked={includeCollaborators}
              onChange={(_e, checked) => setIncludeCollaborators(!!checked)}
            />

            <Checkbox
              label="Include Workspace Guests"
              className="include-guest-checkbox"
              checked={includeGuests}
              onChange={(_e, checked) => setIncludeGuests(!!checked)}
            />
          </div>
          <div className="export">
            <AsyncLink
              className="export-link"
              onClick={async () => {
                if (!organizationUsage?.data?.usage?.length) {
                  return;
                }

                const json2csv = await import('@json2csv/plainjs');
                const fileSaver = await import('file-saver');

                const fields = usageColumns.map((column) => ({
                  label: column.name,
                  value: column.fieldName || column.key,
                }));
                fields.splice(1, 0, { label: 'Email', value: 'email' });

                const parser = new json2csv.Parser({
                  fields,
                  transforms: [escapeCsvFormulas],
                });

                const csvContent = parser.parse(organizationUsage.data.usage);
                const csvBlob = new Blob([csvContent], { type: 'text/csv' });

                fileSaver.saveAs(
                  csvBlob,
                  `${activeOrganization!.id}_usage_${DateTime.now().toISODate({
                    format: 'basic',
                  })}.csv`,
                );
              }}
            >
              Export to CSV
            </AsyncLink>
          </div>
        </div>
      </div>

      <ShimmeredDetailsList
        key={'userActivityList'}
        enableShimmer={organizationUsageLoading}
        shimmerLines={25}
        items={organizationUsage?.data?.usage || EMPTY_ARRAY}
        selectionMode={SelectionMode.none}
        columns={usageColumns}
        isHeaderVisible={true}
        compact
        className="long-headers"
      />
    </div>
  );
};
