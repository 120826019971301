import { useAuth0 } from '@auth0/auth0-react';
import {
  FontIcon,
  FontSizes,
  FontWeights,
  Link,
  mergeStyles,
  NeutralColors,
  Spinner,
  Text,
} from '@fluentui/react';
import {
  ExternalServiceObject,
  ExternalServiceObjectType,
} from '@meetingflow/common/Api/data-contracts';
import { CollectToMap } from '@meetingflow/common/ArrayHelpers';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useExternalServiceConfigurations } from '../../../../Hooks/useExternalServiceConfigurations';
import { HubSpotDeal } from '../../../../Models/HubSpot/HubSpotDeal';
import { HubSpotDealPipeline } from '../../../../Models/HubSpot/HubSpotDealPipeline';
import { HubSpotOwner } from '../../../../Models/HubSpot/HubSpotOwner';
import {
  HubSpotDealPipelinesQuery,
  HubSpotDealSuggestionsQuery,
  HubSpotOwnersQuery,
} from '../../../../QueryNames';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { HubSpotPanelContext } from '../../../../types/HubSpotPanelContext';
import { HubSpotDealTile } from './HubSpotDealTile';

export type MeetingPlanHubSpotSuggestedDealsListProps = {
  organizationSlug: string;
  meetingPlanId?: string;
  configurationId: number;
  externalDomains?: string[];
  onPickedObject: (
    object: ExternalServiceObject | Omit<ExternalServiceObject, 'id'>,
  ) => void;
  onPinnedObject?:
    | ((e?: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<unknown>)
    | undefined;
  pushHubSpotPanel: (context: HubSpotPanelContext) => void;
  associatedObjects?: ExternalServiceObject[];
  showTitle?: boolean;
  title?: string;
  showHubSpotObjectPicker?: (
    defaultTab?: ExternalServiceObjectType | 'PINNED',
  ) => void;
  maxItems?: number;
  hideIfNoContent?: boolean;
};
export const MeetingPlanHubSpotSuggestedDealsList = ({
  organizationSlug,
  meetingPlanId,
  configurationId,
  externalDomains,
  onPickedObject,
  onPinnedObject,
  pushHubSpotPanel,
  associatedObjects = [],
  showTitle = true,
  title = 'Suggested Deals',
  showHubSpotObjectPicker,
  maxItems,
  hideIfNoContent,
}: MeetingPlanHubSpotSuggestedDealsListProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { loading: configurationsLoading, configurationById } =
    useExternalServiceConfigurations({ app: 'HUBSPOT', withToken: true });

  const associatedHubSpotObjects = useMemo(
    () =>
      associatedObjects.filter(
        (o) =>
          o.serviceConfiguration.app === 'HUBSPOT' &&
          o.serviceConfigurationId === configurationId,
      ),
    [associatedObjects, configurationId],
  );

  const {
    data: hubspotOwners,
    isLoading: hubspotOwnersLoading,
    refetch: refetchHubspotOwners,
  } = useQuery(
    HubSpotOwnersQuery(organizationSlug!, configurationId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotOwner[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/owners`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 5 min
      staleTime: 300000,
      enabled: !!configurationId,
    },
  );

  const hubspotOwnersMap = useMemo(
    () => CollectToMap(hubspotOwners?.data ?? [], 'id'),
    [hubspotOwners?.data],
  );

  const {
    data: hubspotPipelines,
    isLoading: hubspotPipelinesLoading,
    refetch: refetchHubspotPipelines,
  } = useQuery(
    HubSpotDealPipelinesQuery(organizationSlug!, configurationId),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotDealPipeline[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/pipelines/deal`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: configurationId !== undefined,
    },
  );

  const hubspotPipelinesMap = useMemo(
    () => CollectToMap(hubspotPipelines?.data ?? [], 'id'),
    [hubspotPipelines?.data],
  );

  const {
    data: hubspotDealSuggestions,
    isLoading: hubspotDealSuggestionsLoading,
    isRefetching: hubspotDealSuggestionsRefetching,
    refetch: refetchHubspotDealSuggestions,
  } = useQuery(
    HubSpotDealSuggestionsQuery(
      organizationSlug!,
      configurationId,
      externalDomains,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotDeal[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/deals`,
        {
          params: {
            active: true,
            domains: externalDomains,
            limit: maxItems,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!externalDomains?.length,
    },
  );

  useEffect(() => {
    refetchHubspotDealSuggestions();
    refetchHubspotOwners();
    refetchHubspotPipelines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingPlanId, configurationId, organizationSlug, externalDomains]);

  const listClass = mergeStyles({
    position: 'relative',
    animationName: 'fadeInSlideAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    width: 'auto',
    transition: '.3s ease-in-out all',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    rowGap: '.125rem',
  });

  const slideSpinnerClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
  });

  if (
    configurationsLoading ||
    hubspotOwnersLoading ||
    hubspotPipelinesLoading
  ) {
    return (
      <div style={{ margin: '1rem 0' }}>
        <Spinner className={slideSpinnerClass} />
      </div>
    );
  }

  const sectionSubHeaderClass = mergeStyles({
    margin: '0 0 .5rem 0!important',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.semibold,
    color: NeutralColors.gray100,
    position: 'relative',
    // display: 'block',
    // width: '100%',
  });

  const slideSpinnerInHeaderClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
    position: 'absolute',
    top: showHubSpotObjectPicker ? '-.25rem' : 0,
    right: '0',
  });

  const dealSuggestions = hubspotDealSuggestions?.data
    ?.filter(
      (deal) => !associatedHubSpotObjects.some((o) => o.externalId === deal.id),
    )
    ?.slice(0, maxItems);

  return (
    <div
      className={listClass}
      style={{
        display:
          (hideIfNoContent && hubspotDealSuggestionsLoading) ||
          (hideIfNoContent &&
            !hubspotDealSuggestionsLoading &&
            dealSuggestions?.length === 0) ||
          !dealSuggestions
            ? 'none'
            : 'inline-flex',
      }}
    >
      {showTitle ? (
        <Text block className={sectionSubHeaderClass}>
          {title}
          {showHubSpotObjectPicker ? (
            <Link
              style={{
                display: 'block',
                float: 'right',
                textAlign: 'right',
                fontWeight: FontWeights.semilight,
                fontSize: FontSizes.small,
                marginRight:
                  hubspotDealSuggestionsLoading ||
                  hubspotDealSuggestionsRefetching
                    ? '2rem'
                    : 0,
                transition: '.3s ease-in-out all',
              }}
              onClick={() => showHubSpotObjectPicker('DEAL')}
            >
              All
              <FontIcon
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  top: '.15rem',
                  marginLeft: '.25rem',
                }}
                iconName="PageRight"
              />
            </Link>
          ) : null}
          {hubspotDealSuggestionsLoading || hubspotDealSuggestionsRefetching ? (
            <Spinner className={slideSpinnerInHeaderClass} />
          ) : null}
        </Text>
      ) : null}
      {dealSuggestions?.length ? (
        <>
          {dealSuggestions.map((deal) => {
            const externalServiceObject = {
              externalId: deal.id,
              objectType: 'DEAL',
              name: deal.properties.dealname,
              serviceConfigurationId: configurationId,
              serviceConfiguration: configurationById(configurationId)!,
            } as ExternalServiceObject;

            return (
              <HubSpotDealTile
                organizationSlug={organizationSlug}
                meetingPlanId={meetingPlanId}
                key={deal.id}
                id={deal.id}
                name={deal.properties.dealname}
                ownerName={
                  deal.properties.hubspot_owner_id
                    ? `${hubspotOwnersMap[deal.properties.hubspot_owner_id]
                        ?.firstName} ${hubspotOwnersMap[
                        deal.properties.hubspot_owner_id
                      ]?.lastName}`
                    : undefined
                }
                ownerEmail={
                  deal.properties.hubspot_owner_id
                    ? hubspotOwnersMap[deal.properties.hubspot_owner_id]?.email
                    : undefined
                }
                stage={
                  deal.properties.pipeline
                    ? hubspotPipelinesMap[
                        deal.properties.pipeline
                      ]?.stages?.find((s) => s.id === deal.properties.dealstage)
                        ?.label
                    : undefined
                }
                hubspotInstance={
                  configurationById(configurationId)!.instanceId!
                }
                onClick={() => onPickedObject(externalServiceObject)}
                isLoading={false}
                onPinClick={onPinnedObject}
                showArrow
                showExternalLink
              />
            );
          })}
        </>
      ) : (
        <>
          {hubspotDealSuggestionsLoading ||
          hubspotDealSuggestionsRefetching ? null : (
            <Text
              block
              variant="small"
              style={{ fontStyle: 'italic', margin: '.5rem 0' }}
            >
              There are no suggested deals.
            </Text>
          )}
        </>
      )}
    </div>
  );
};
