import { DateTime } from 'luxon';
import { SalesforceOpportunity } from '../../Models/Salesforce/SalesforceOpportunity';
import { SalesforceOrgOpportunityStats } from '../../Models/Salesforce/SalesforceOpportunityStats';
import RiskPhrases from '../RiskPhrases';
import { SalesforceFieldNameFieldMap } from './SalesforceFieldHelpers';
import {
  AgeInDays,
  DaysInStage,
  DaysSinceLastModified,
  DaysToCloseDate,
} from './SalesforceOpportunityHelpers';

export type SalesforceOpportunityInsightRule = (
  opportunity: SalesforceOpportunity,
  fieldNameFieldMap: SalesforceFieldNameFieldMap,
  orgOpportunityStats?: SalesforceOrgOpportunityStats,
) =>
  | {
      priority: number;
      name: string;
      insight: string;
      key: string;
      fieldName?: string;
    }
  | undefined;

export const closeDateInThePastRule = (
  opportunity: Pick<SalesforceOpportunity, 'CloseDate'>,
) => {
  const daysToCloseDate = DaysToCloseDate(opportunity);

  return daysToCloseDate < 0
    ? {
        priority: 3,
        name: 'Past Close Date',
        insight: `Close date was ${Math.abs(daysToCloseDate)} days ago`,
        key: 'closeDateInThePast',
        fieldName: 'CloseDate',
      }
    : undefined;
};

export const longTimeToClose = (
  opportunity: Pick<SalesforceOpportunity, 'CreatedDate'>,
  _fieldNameFieldMap: SalesforceFieldNameFieldMap,
  orgStats?: SalesforceOrgOpportunityStats,
) => {
  const age = AgeInDays(opportunity);

  if (!orgStats || !orgStats.owner?.DaysToClose) {
    return undefined;
  }

  return age > orgStats.owner.DaysToClose.upperQuartile
    ? {
        priority: 1,
        name: 'Old Opportunity',
        insight: `Opened ${age} days ago`,
        key: 'longTimeToClose',
        fieldName: 'CreatedDate',
      }
    : undefined;
};

export const missingNextStepsRule = (
  opportunity: Pick<SalesforceOpportunity, 'NextStep'>,
) => {
  return !opportunity.NextStep?.trim()
    ? {
        priority: 1,
        name: 'Missing Next Steps',
        insight: 'Missing Next Steps',
        key: 'missingNextSteps',
        fieldName: 'NextStep',
      }
    : undefined;
};

export const missingAmountRule = (
  opportunity: Pick<SalesforceOpportunity, 'Amount'>,
) => {
  return opportunity.Amount === null
    ? {
        priority: 1,
        name: 'Missing Amount',
        insight: 'Missing Amount',
        key: 'missingAmount',
        fieldName: 'Amount',
      }
    : undefined;
};

export const notUpdatedIn30DaysRules = (
  opportunity: Pick<SalesforceOpportunity, 'CreatedDate' | 'LastModifiedDate'>,
) => {
  const daysSinceLastModified = DaysSinceLastModified(opportunity);

  return daysSinceLastModified > 30
    ? {
        priority: 1,
        name: 'Not Updated In 30d',
        insight: `Not updated in ${daysSinceLastModified} days`,
        key: 'notUpdatedIn30Days',
        fieldName: 'LastModifiedDate',
      }
    : undefined;
};

export const nextStepsIndicateRiskRule = (
  opportunity: Pick<SalesforceOpportunity, 'NextStep'>,
) => {
  if (!opportunity.NextStep) {
    return undefined;
  }

  const nextSteps = opportunity.NextStep.trim().toLowerCase();

  const riskIndicators = RiskPhrases.filter((phrase) =>
    nextSteps.includes(phrase),
  );

  return riskIndicators.length
    ? {
        priority: 1,
        name: 'Next Steps Indicate Risk',
        insight: `Next Steps mention ${riskIndicators.join(', ')}`,
        key: 'nextStepsIndicateRisk',
        fieldName: 'NextStep',
      }
    : undefined;
};

export const highValueRule = (
  opportunity: Pick<SalesforceOpportunity, 'Amount'>,
  _fieldNameFieldMap: SalesforceFieldNameFieldMap,
  orgStats?: SalesforceOrgOpportunityStats,
) => {
  if (!opportunity.Amount || !orgStats || !orgStats.owner?.Amount) {
    return undefined;
  }

  const isOwnerHighValue =
    orgStats.owner?.Amount?.upperQuartile &&
    opportunity.Amount > orgStats.owner.Amount.upperQuartile;

  return isOwnerHighValue
    ? {
        priority: 1,
        name: 'High Value Opportunity',
        insight: `High Relative Value`,
        key: 'highValue',
        fieldName: 'Amount',
      }
    : undefined;
};

export const longTimeInStageRule = (
  opportunity: Pick<
    SalesforceOpportunity,
    'CreatedDate' | 'StageName' | 'history'
  >,
  _fieldNameFieldMap: SalesforceFieldNameFieldMap,
  orgStats?: SalesforceOrgOpportunityStats,
) => {
  if (
    !opportunity.history?.length ||
    !orgStats ||
    !orgStats.owner?.DaysInStage
  ) {
    return undefined;
  }

  const currentStage = opportunity.StageName;

  if (!orgStats.owner.DaysInStage?.[currentStage]) {
    return undefined;
  }

  const timeInStage = DaysInStage(opportunity);

  const isLongTimeInStageOwner =
    orgStats.owner.DaysInStage?.[currentStage] &&
    timeInStage > orgStats.owner.DaysInStage[currentStage].mean;

  return isLongTimeInStageOwner
    ? {
        priority: 1,
        name: 'Long Time In Stage',
        insight: `Opportunity has been in ${currentStage} for ${Math.abs(
          timeInStage,
        )} days`,
        key: 'longTimeInStage',
        fieldName: 'StageName',
      }
    : undefined;
};

export const noLastMeetingflowRule = (
  opportunity: Pick<SalesforceOpportunity, 'CreatedDate'> & {
    lastMeetingflow?: { startTime: string };
  },
) => {
  const age = AgeInDays(opportunity);

  return age > 14 && !opportunity.lastMeetingflow
    ? {
        priority: 1,
        name: 'No Meetingflow',
        insight: `No Recent Meetingflows`,
        key: 'noLastMeetingflow',
        fieldName: 'lastMeetingflow',
      }
    : undefined;
};

export const oldLastMeetingflow = (opportunity: {
  lastMeetingflow?: { startTime: string };
}) => {
  if (!opportunity.lastMeetingflow) {
    return undefined;
  }

  const meetingflowDaysAgo = Math.round(
    DateTime.fromISO(opportunity.lastMeetingflow.startTime).diffNow('days')
      .days,
  );

  return meetingflowDaysAgo > 30
    ? {
        priority: 1,
        name: 'No Recent Meetingflow',
        insight: `Last Meetingflow ${meetingflowDaysAgo} days ago`,
        key: 'meetingflowDaysAgo',
        fieldName: 'lastMeetingflow',
      }
    : undefined;
};

export const noSalesforceActivityRule = (
  opportunity: Pick<SalesforceOpportunity, 'CreatedDate'> & {
    lastActivity?: { CreatedDate: string };
  },
) => {
  const age = AgeInDays(opportunity);

  return age > 14 && !opportunity.lastActivity
    ? {
        priority: 1,
        name: 'No Salesforce Activity',
        insight: `No Activity in Salesforce`,
        key: 'noSalesforceActivity',
        fieldName: 'lastActivity',
      }
    : undefined;
};

export const oldSalesforceActivityRule = (opportunity: {
  lastActivity?: { CreatedDate: string };
}) => {
  if (!opportunity.lastActivity) {
    return undefined;
  }

  const activityDaysAgo = Math.round(
    DateTime.fromISO(opportunity.lastActivity.CreatedDate).diffNow('days').days,
  );

  return activityDaysAgo > 14
    ? {
        priority: 1,
        name: 'No Recent Salesforce activity',
        insight: `Last Salesforce Activity ${activityDaysAgo} Days Ago`,
        key: 'meetingflowDaysAgo',
        fieldName: 'lastMeetingflow',
      }
    : undefined;
};

export const SalesforceOpportunityInsightRules: SalesforceOpportunityInsightRule[] =
  [
    closeDateInThePastRule,
    notUpdatedIn30DaysRules,
    longTimeInStageRule,
    nextStepsIndicateRiskRule,
    longTimeToClose,
    highValueRule,
    noSalesforceActivityRule,
    oldSalesforceActivityRule,
    oldLastMeetingflow,
    noLastMeetingflowRule,
    missingNextStepsRule,
  ] satisfies SalesforceOpportunityInsightRule[];
