import React, { useMemo, useCallback } from 'react';
import useStore from '../../../../Stores/userSettingsStore';

import { TextField } from '@fluentui/react/lib/TextField';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import {
  Dropdown,
  IDropdownOption,
  IPersonaSharedProps,
  mergeStyles,
  Persona,
  PersonaSize,
  PrimaryButton,
} from '@fluentui/react';
import { DetailedUser } from '@meetingflow/common/Api/data-contracts';
import {
  IANA_TIMEZONES,
  isTimezone,
  Timezone,
} from '@meetingflow/common/Timezones';
import { TimeZonesIllustrations } from './UserSettingsIllustrations';
import { pickFile } from '../../../../Helpers/FileHelpers';

interface UserSettingsProfileProps {
  orgRole: string | undefined;
  profileData: DetailedUser | undefined;
  setSelectedAvatar: (arg0: File | null) => void;
  isSaving: boolean;
  setUploadedImgAsPath: (arg0: string | null) => void;
  userProfileDataState: { [key: string]: string | null };
}

const timezones = [...IANA_TIMEZONES];

const timezoneOptions: IDropdownOption[] = timezones.map((t) => {
  return {
    key: t,
    text: t,
  };
});

export const UserSettingsProfile = ({
  orgRole,
  profileData,
  setSelectedAvatar,
  isSaving,
  setUploadedImgAsPath,
  userProfileDataState,
}: UserSettingsProfileProps) => {
  const uploadedImgAsPath = useStore((state) => state.uploadedImgAsPath);
  const setUserProfileDataState = useStore((state) => state.setUserProfileData);

  const defaultTz = useMemo(() => {
    const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (timezones as string[]).includes(browserTz)
      ? (browserTz as Timezone)
      : undefined;
  }, []);

  const profileStyle = mergeStyles({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    '>div': {
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '0.25rem',
      backgroundColor: DEALROOMS_COLORS.neutralGray,
      padding: '1rem',
    },
    '.section_title': {
      fontSize: '1rem',
      color: DEALROOMS_COLORS.themePrimary,
      padding: '0 0 1rem 0',
    },
    '.name_input_container': {
      label: {
        fontSize: '0.8rem',
        fontWeight: 200,
        '::after': {
          color: DEALROOMS_COLORS.darkerRed,
        },
      },
      input: {
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
      },
      '*': {
        border: '0',
        outline: 'none',
      },
    },
    '.org_role': {
      backgroundColor: DEALROOMS_COLORS.artic,
      borderRadius: '0.25rem',
      color: DEALROOMS_COLORS.neutralDarker,
      fontSize: '0.75rem',
      padding: '0.1rem 0.3rem',
      margin: '0 0 0 1rem',
    },
    '.user_settings_avatar': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '>div:nth-child(1)': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '7rem',
        height: '7rem',
        borderRadius: '100%',
      },
      '>div:nth-child(2)': {
        padding: '0 0 0 0.5rem',
        '.change_picture_btn': {
          color: DEALROOMS_COLORS.notificationBubble,
          fontWeight: '600',
          fontSize: '0.9rem',
          cursor: 'pointer',
          width: 'fit-content',
          padding: '0.15rem 0.1rem',
          border: '1px solid transparent',
          outline: 'none',
          borderRadius: '0.3rem',
          backgroundColor: 'transparent',
          minHeight: 'auto',
          ':hover': {
            backgroundColor: DEALROOMS_COLORS.lighterGray,
          },
          ':focus-visible': {
            border: '1px solid ' + DEALROOMS_COLORS.sidebarTextSecondary,
            outline: 'none',
          },
        },
        '>div:nth-child(2)': {
          padding: '0.1rem 0.5rem',
          color: DEALROOMS_COLORS.sidebarHoverTextPrimary,
          fontSize: '0.75rem',
        },
      },
      input: {
        display: 'none',
        visibility: 'hidden',
      },
    },
    '.user_timezone_options': {
      '>div:nth-child(1)': {
        border: '0',
        outline: '0',
        ':hover': {},
        ':focus-visible': {
          ':after': {
            border: '1px solid ' + DEALROOMS_COLORS.sidebarTextSecondary,
            outline: 'none',
          },
        },
      },
      '>div:nth-child(1):after': {
        border: '0',
        outline: '0',
      },
      'span:nth-child(1)': {
        border: '0',
        outline: '0',
        backgroundColor: DEALROOMS_COLORS.inputLightGray,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        svg: {
          margin: '0 0.3rem 0 0',
        },
      },
    },
  });

  const userPersona: IPersonaSharedProps = {
    text: profileData?.name || undefined,
    imageUrl: uploadedImgAsPath || profileData?.avatarFileUrl || undefined,
  };

  const startAvatarChange = useCallback(async () => {
    const file = await pickFile({
      excludeAcceptAll: true,
      fileTypes: 'image',
    });

    if (!file || file.size > 2097152) {
      setSelectedAvatar(null);
      setUploadedImgAsPath(null);
      return;
    }

    setSelectedAvatar(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedImgAsPath(reader.result as string);
    };
    reader.readAsDataURL(file);
  }, [setSelectedAvatar, setUploadedImgAsPath]);

  const onChangeFirstName = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string,
    ) => {
      setUserProfileDataState('firstName', newValue || '');
    },
    [setUserProfileDataState],
  );

  const onChangeLastName = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string,
    ) => {
      setUserProfileDataState('lastName', newValue || '');
    },
    [setUserProfileDataState],
  );

  const onRenderTitle = (
    options: IDropdownOption[] | undefined,
  ): JSX.Element => {
    const option = options?.length ? options[0] : 'N/A';
    return (
      <>
        <TimeZonesIllustrations />
        <span>{typeof option !== 'string' ? option?.text : option}</span>
      </>
    );
  };

  return (
    <div className={profileStyle}>
      <div>
        <div className="section_title">Avatar</div>
        <div className="user_settings_avatar">
          <div>
            <Persona
              {...userPersona}
              size={PersonaSize.size100}
              hidePersonaDetails={true}
            />
          </div>
          <div>
            <PrimaryButton
              className="change_picture_btn"
              onClick={startAvatarChange}
            >
              Change Picture
            </PrimaryButton>
            <div>Square picture, jpeg, gif or png format, max 2MB</div>
          </div>
        </div>
      </div>
      <div>
        <div className="section_title">Name</div>
        <div>
          <TextField
            className="name_input_container"
            label="First Name "
            required
            defaultValue={
              userProfileDataState.firstName ||
              profileData?.firstName ||
              undefined
            }
            onChange={onChangeFirstName}
            disabled={isSaving}
          />
        </div>
        <div>
          <TextField
            className="name_input_container"
            label="Last Name "
            required
            defaultValue={
              userProfileDataState.lastName ||
              profileData?.lastName ||
              undefined
            }
            onChange={onChangeLastName}
            disabled={isSaving}
          />
        </div>
      </div>
      <div>
        <span className="section_title">Access level</span>
        <span className="org_role">{orgRole}</span>
      </div>
      <div>
        <div className="section_title">Timezone</div>
        <Dropdown
          className="user_timezone_options"
          errorMessage={
            !userProfileDataState.timezone && !profileData?.timezone
              ? 'Timezone is not set.'
              : ''
          }
          options={timezoneOptions}
          selectedKey={
            isTimezone(userProfileDataState.timezone)
              ? userProfileDataState.timezone
              : isTimezone(profileData?.timezone)
                ? profileData?.timezone
                : defaultTz
          }
          onChange={(_evt, option, _index) =>
            setUserProfileDataState('timezone', (option?.key as Timezone) || '')
          }
          onRenderTitle={onRenderTitle}
          disabled={isSaving}
        />
      </div>
    </div>
  );
};
