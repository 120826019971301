import { useAuth0 } from '@auth0/auth0-react';
import {
  DefaultButton,
  IColumn,
  SelectionMode,
  Stack,
  Text,
} from '@fluentui/react';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { StyledSpinner } from '../StyledSpinner';
import { OrganizationInviteItem } from '../OrganizationInviteItem';
import { OrganizationInvitesQuery } from '../../QueryNames';
import { PageLayout } from '../Layouts/PageLayout';
import { titleCase } from '../../Helpers/Typography';
import { StyledDetailsList } from '../StyledDetailsList';
import { useTitle } from '../../Hooks/useTitle';
import { Card } from '../Card';
import { useNavigate } from 'react-router';
import { EMPTY_ARRAY } from '../../Constants';
import { OrganizationInvite } from '@meetingflow/common/Api/data-contracts';
import { InvitesApiClient } from '../../Services/NetworkCommon';
import { useOrganization } from '../../Hooks/useOrganization';
import { DEFAULT_STACK_TOKENS } from '../../Helpers/Layout';
import { SettingsStyledDetailsList } from './Settings/SettingsStyledDetailList';
import StyledDateTime from '../StyledDateTime';

const StyledDetailsListStyles = {
  root: {
    marginBottom: '1rem',
  },
};

export const OrganizationInvites = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { slug, name, isAdmin, isFetched } = useOrganization();
  const navigate = useNavigate();

  useTitle('Workspace Invites');

  const {
    data: inviteData,
    isLoading: invitesLoading,
    refetch: refetchInvites,
  } = useQuery(
    OrganizationInvitesQuery(slug),
    async () => {
      const token = await getAccessTokenSilently();
      return InvitesApiClient.getOrgInvites(
        { organizationSlug: slug! },
        { headers: { Authorization: `Bearer ${token}` } },
      );
    },
    { enabled: isAdmin },
  );

  useEffect(() => {
    if (isFetched && !isAdmin) {
      navigate(`/organization/${slug}`);
    }
  }, [isAdmin, isFetched, navigate, slug]);

  const inviteColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'name',
        name: 'Name',
        minWidth: 140,
        maxWidth: 200,
        fieldName: 'name',
        onRender: (invite: OrganizationInvite) => (
          <Text>{invite.invitee.name}</Text>
        ),
      },
      {
        key: 'email',
        name: 'Email',
        minWidth: 140,
        maxWidth: 200,
        fieldName: 'invitee.email',
        onRender: (invite: OrganizationInvite) => (
          <Text>{invite.invitee.email}</Text>
        ),
      },
      {
        key: 'role',
        name: 'Role',
        minWidth: 100,
        maxWidth: 100,
        fieldName: 'role',
        onRender: (invite: OrganizationInvite) => (
          <Text>{titleCase(invite.role)}</Text>
        ),
      },
      {
        key: 'createdAt',
        name: 'Created',
        minWidth: 150,
        maxWidth: 150,
        fieldName: 'createdAt',
        onRender: (invite: OrganizationInvite) => (
          <Text>
            <StyledDateTime dateTime={invite.createdAt} useRelativeDates />
          </Text>
        ),
      },
      {
        key: 'inviter',
        name: 'Invited by',
        minWidth: 200,
        maxWidth: 250,
        fieldName: 'inviter',
        onRender: (invite: OrganizationInvite) => (
          <Text>
            {invite.inviter.name} ({invite.inviter.email})
          </Text>
        ),
      },
      {
        key: 'delete',
        name: 'Delete',
        minWidth: 100,
        maxWidth: 100,
        onRender: (invite: OrganizationInvite) => (
          <OrganizationInviteItem
            key={invite.id}
            invite={invite}
            refreshInvites={refetchInvites}
          />
        ),
      },
    ],
    [refetchInvites],
  );

  const invites = useMemo(() => inviteData?.data, [inviteData?.data]);

  if (invitesLoading) {
    return <StyledSpinner />;
  }

  return (
    <>
      {invites?.length ? (
        <SettingsStyledDetailsList
          items={invites || EMPTY_ARRAY}
          columns={inviteColumns}
          selectionMode={SelectionMode.none}
          styles={StyledDetailsListStyles}
        />
      ) : (
        <Text block style={{ fontStyle: 'italic' }}>
          There are no outstanding invites to join {name}.
        </Text>
      )}
    </>
  );
};

export default OrganizationInvites;
