import { mergeStyles } from '@fluentui/react';
import {
  DealRoom,
  DealRoomArtifact,
  DetailedDealRoom,
  Organization,
} from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { DealRoomArtifacts } from '../Artifacts/DealRoomArtifacts';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

type DealRoomBuyersArtifactsTabProps = {
  className?: string;
  organizationSlug: Organization['slug'];
  dealRoomId: DealRoom['id'];
  dealRoom: Pick<DetailedDealRoom, 'activity' | 'artifacts'>;
  refreshDealRoom: () => Promise<unknown>;
};

export const DealRoomBuyersArtifactsTab = ({
  className,
  organizationSlug,
  dealRoomId,
  dealRoom,
  refreshDealRoom,
}: DealRoomBuyersArtifactsTabProps) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const styles = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  });

  const handleClickArtifact = useCallback(
    (id: DealRoomArtifact['id']) => {
      const artifact = dealRoom.artifacts.find((a) => a.id === id);

      if (!artifact) {
        return;
      }

      switch (artifact.type) {
        case 'MEETINGFLOW': {
          navigate(
            `/organization/${organizationSlug}/dealroom/${dealRoomId}/plan/${artifact.meetingflowId}?buyerView=1`,
          );
          break;
        }
        default: {
          navigate(
            `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifact/${id}${
              !!searchParams.get('buyerView') ? '?buyerView=true' : ''
            }`,
          );
        }
      }
    },
    [dealRoom.artifacts, navigate, organizationSlug, dealRoomId, searchParams],
  );

  return (
    <div className={classNames(className, styles)}>
      <DealRoomArtifacts
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        activity={dealRoom.activity}
        showAdd={true}
        includeDeleted
        includeMeetingflows
        refreshDealRoom={refreshDealRoom}
        onClick={handleClickArtifact}
        onAdd={(_) => refreshDealRoom()}
        onUpdate={() => refreshDealRoom()}
        onDelete={(_) => refreshDealRoom()}
        useTableView
      />
    </div>
  );
};
