import React, { FormEvent, MouseEvent, useState } from 'react';
import {
  BaseButton,
  Button,
  FontWeights,
  mergeStyles,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { UserDetails } from '../../../../types/BuyerOrientationSurveyTypes';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { BuyerOrientationDialog } from '../../Dialogs/BuyerOrientationDialog';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from 'react-query';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import toast from 'react-hot-toast';
import { AsyncPrimaryButton } from '../../../../Components/HOC/AsyncButton';
import { useBoolean } from '@fluentui/react-hooks';
import { useNavigate, matchPath } from 'react-router';

interface UserSettingsInternalProps {
  currentUserDetails: UserDetails;
  refetchDealRoom: () => unknown | Promise<unknown>;
}

export const UserSettingsInternal = ({
  currentUserDetails,
  refetchDealRoom,
}: UserSettingsInternalProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const internalStyle = mergeStyles({
    display: 'flex',
    flexDirection: 'column',

    h4: {
      fontWeight: FontWeights.regular,
      margin: '0 0 .5rem 0',
    },

    '.section': {
      margin: '0 0 2rem 0',
      display: 'flex',
      gap: '1rem',
    },

    button: {
      backgroundColor: DEALROOMS_COLORS.themePrimary,
      color: DEALROOMS_COLORS.white,
      width: 'content',
      borderRadius: '0.25rem',
      margin: '0 0 0 0',
      fontSize: '0.85rem',
      padding: '0.3rem 1rem 0.3rem 0.7rem',
      ':hover': {
        backgroundColor: DEALROOMS_COLORS.neutralDarker,
      },
      svg: {
        margin: '0 0.3rem 0 0',
      },
      ':disabled': {
        backgroundColor: DEALROOMS_COLORS.sidebarTextSecondary,
      },
    },
  });

  return (
    <div className={internalStyle}>
      <h4>Developer Tools</h4>
      <div className="section">
        <UserSettingsInternalBuyerOrientation
          currentUserDetails={currentUserDetails}
          refetchDealRoom={refetchDealRoom}
        />
        <PrimaryButton
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        >
          Clear Local Storage
        </PrimaryButton>
        <AsyncPrimaryButton
          onClick={async () => {
            getAccessTokenSilently().then((token) => {
              navigator?.clipboard
                ?.writeText(token)
                .then(() => toast(`Bearer token copied to clipboard`));
            });
          }}
        >
          Copy Bearer Token
        </AsyncPrimaryButton>
      </div>

      <div className="section">
        <UserSettingsAddMeetingflow refetchDealRoom={refetchDealRoom} />
      </div>
    </div>
  );
};

interface UserSettingsInternalBuyerOrientationProps {
  currentUserDetails: UserDetails;
  refetchDealRoom: () => unknown | Promise<unknown>;
}

const UserSettingsInternalBuyerOrientation = ({
  currentUserDetails,
  refetchDealRoom,
}: UserSettingsInternalBuyerOrientationProps) => {
  const { user } = useUserProfile();
  const dealRoomId = useDealRoomId();
  const organizationSlug = useOrganizationSlug();
  const { dealRoom, refetch: refetchDealRoomData } = useDealRoom(
    organizationSlug,
    dealRoomId,
  );
  const [
    showDialog,
    { setFalse: setShowDialogFalse, setTrue: setShowDialogTrue },
  ] = useBoolean(false);

  if (!user || !dealRoomId || !organizationSlug || !currentUserDetails) {
    return null;
  }

  return (
    <>
      <PrimaryButton onClick={setShowDialogTrue}>
        Show Buyer Orientation Dialog
      </PrimaryButton>

      {showDialog && (
        <BuyerOrientationDialog
          handleDismiss={setShowDialogFalse}
          ownerAvatarUrl={
            dealRoom?.owner?.avatarFileUrl ||
            dealRoom?.owner?.avatarUrl ||
            undefined
          }
          ownerName={
            dealRoom?.owner?.firstName || dealRoom?.owner?.name || undefined
          }
          userName={user?.name || ''}
          currentUserDetails={
            {
              assistancePreference: null,
              consumptionPreference: null,
              communicationPreference: null,
              priorities: [] as string[],
              otherPriorityText: null,
              phoneNumber: null,
              role: currentUserDetails.role || undefined,
            } as UserDetails
          }
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          userEmail={user?.email || ''}
          userRole={currentUserDetails?.role || undefined}
          refetchDealRoomData={async () => {}}
        />
      )}
    </>
  );
};

interface UserSettingsAddMeetingflowProps {
  refetchDealRoom: () => unknown | Promise<unknown>;
}

const UserSettingsAddMeetingflow = ({
  refetchDealRoom,
}: UserSettingsAddMeetingflowProps) => {
  const [meetingflowUrl, setMeetingflowUrl] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: addArtifact } = useMutation(
    async (data: { meetingflowId: string }) => {
      const token = await getAccessTokenSilently();

      if (!dealRoomId || !organizationSlug) {
        throw new Error('DealRoomId or OrganizationSlug not found');
      }
      await DealRoomsApiClient.createLinkArtifact(
        organizationSlug,
        dealRoomId,
        {
          type: 'MEETINGFLOW',
          meetingflowId: data.meetingflowId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      onSuccess: () => {
        toast.success('Meetingflow added successfully');
      },
      onError: () => {
        toast.error('Failed to add Meetingflow');
      },
    },
  );

  if (!dealRoomId || !organizationSlug) {
    navigate(`/`);
  }

  // Example meetingflow URL: https://app.meetingflow.com/organization/example-co/plan/5aef782f-0fd9-4398-9b41-6f7f48eea422
  const parseMeetingflowUrl = React.useCallback(() => {
    const match = matchPath(
      '/organization/:organizationSlug/plan/:meetingPlanId',
      new URL(meetingflowUrl).pathname,
    );
    if (!match) {
      throw new Error('Invalid Meetingflow URL');
    }
    const { organizationSlug, meetingPlanId } = match.params;
    return { organizationSlug, meetingPlanId };
  }, [meetingflowUrl]);

  const handleMeetingflowURLChange = React.useCallback(
    (
      event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string | undefined,
    ) => {
      setMeetingflowUrl(newValue || '');
    },
    [],
  );

  const handleSubmit = React.useCallback(
    async (
      event: MouseEvent<
        | HTMLDivElement
        | HTMLAnchorElement
        | HTMLButtonElement
        | BaseButton
        | Button
        | HTMLSpanElement
      >,
    ) => {
      event.preventDefault();
      const meetingflowId = parseMeetingflowUrl().meetingPlanId;
      if (!meetingflowId) {
        throw new Error('Invalid Meetingflow URL');
      }
      await addArtifact({ meetingflowId });
      await queryClient.invalidateQueries({
        queryKey: ['artifacts', dealRoomId, organizationSlug],
      });
      refetchDealRoom();
    },
    [
      addArtifact,
      refetchDealRoom,
      parseMeetingflowUrl,
      dealRoomId,
      organizationSlug,
      queryClient,
    ],
  );

  const styles = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    h4: {
      marginBottom: '.5rem',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: DEALROOMS_COLORS.financialLighterGray,
      padding: '1rem',
      gap: '1rem',
      width: '100%',
      borderRadius: '0.25rem',
      boxSizing: 'border-box',

      button: {
        width: 'auto',
        maxWidth: '10rem',
      },
    },
  });

  return (
    <div className={styles}>
      <h4>Add a Meetingflow to this Decision Site</h4>
      <form>
        <TextField
          label="Meetingflow URL"
          value={meetingflowUrl}
          onChange={handleMeetingflowURLChange}
          placeholder="i.e. https://app.meetingflow.com/organization/augment-ai/plan/5aef782f-0fd9-4398-9b41-6f7f48eea422"
          description="Paste the URL of the Meetingflow you want to add to this Decision Site. It will become visible in the Artifacts section."
        />
        <PrimaryButton onClick={handleSubmit} disabled={!meetingflowUrl}>
          Add Meetingflow
        </PrimaryButton>
      </form>
    </div>
  );
};
