import { useAuth0 } from '@auth0/auth0-react';
import {
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  Stack,
  TextField,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DetailedUser,
  ThemePreference,
} from '@meetingflow/common/Api/data-contracts';
import { OmitValues } from '@meetingflow/common/ObjectHelpers';
import {
  IANA_TIMEZONES,
  isTimezone,
  Timezone,
} from '@meetingflow/common/Timezones';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import { DEFAULT_STACK_TOKENS } from '../../Helpers/Layout';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { ProfileQuery } from '../../QueryNames';
import { UserApiClient } from '../../Services/NetworkCommon';
import { Card } from '../Card';
import { PageLayout } from '../Layouts/PageLayout';

const timezones = [...IANA_TIMEZONES];

const timezoneOptions: IDropdownOption[] = timezones.map((t) => {
  return {
    key: t,
    text: t,
  };
});

type MeetingPlanUserProfileForm = {
  name: string;
  timezone?: Timezone;
  preferenceTheme: ThemePreference;
};

const formSchema = yup
  .object({
    name: yup
      .string()
      .min(1, 'Your name is required')
      .required('Your name is required'),
    timezone: yup.string().oneOf(timezones, 'Invalid timezone').optional(),
    themePreference: yup.string().oneOf(['SYSTEM', 'DARK', 'LIGHT']),
  })
  .required();

export type WelcomeProfileProps = {
  user?: DetailedUser;
  refetchUser: () => Promise<unknown>;
};

export const WelcomeProfile = ({ user, refetchUser }: WelcomeProfileProps) => {
  const { user: auth0User, getAccessTokenSilently } = useAuth0();
  const { setThemePreference } = useLightOrDarkMode();
  const client = useQueryClient();

  const defaultTz = useMemo(() => {
    const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (timezones as string[]).includes(browserTz)
      ? (browserTz as Timezone)
      : undefined;
  }, []);

  const {
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors },
  } = useForm<MeetingPlanUserProfileForm>({
    defaultValues: {
      name: user?.name || auth0User?.name,
      timezone: isTimezone(user?.timezone) ? user?.timezone : defaultTz,
      preferenceTheme: user?.preferenceTheme || 'SYSTEM',
    },
    resolver: yupResolver(formSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('name', user?.name || auth0User?.name || '');
    setValue(
      'timezone',
      isTimezone(user?.timezone) ? user?.timezone : defaultTz,
    );
    setValue('preferenceTheme', user?.preferenceTheme || 'SYSTEM');
  }, [user, defaultTz, auth0User?.name, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    if (isValid) {
      const token = await getAccessTokenSilently();
      const result = await UserApiClient.patchMe(data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (result.status === 200) {
        setThemePreference(data.preferenceTheme);
        reset({
          name: result.data.name || auth0User?.name,
          timezone: isTimezone(result.data.timezone)
            ? result.data.timezone
            : defaultTz,
          preferenceTheme: result.data.preferenceTheme,
        });
        client.setQueryData(ProfileQuery, result);
        refetchUser();
      }
    }
  });

  return (
    <div>
      <div className="welcome-wizard-form">
        <div className="elements row">
          <Controller
            name="name"
            control={control}
            render={({ field: { value, onBlur, onChange } }) => (
              <TextField
                value={value}
                onChange={(_e, newValue) => onChange(newValue || '')}
                onBlur={onBlur}
                errorMessage={errors.name?.message}
                label="Name"
                description={`Enter your name as you'd like it to appear to others in your Workspace.`}
              />
            )}
          />{' '}
          <div style={{ flex: '.75 1 0' }}>
            <Controller
              name="timezone"
              control={control}
              render={({ field }) => (
                <Dropdown
                  {...OmitValues(field, 'ref')}
                  errorMessage={errors.timezone?.message}
                  options={timezoneOptions}
                  selectedKey={field.value}
                  onBlur={field.onBlur}
                  onChange={(_evt, option, _index) => {
                    if (!option?.key) {
                      return;
                    }
                    field.onChange(option.key as Timezone);
                  }}
                  label="Local Timezone"
                />
              )}
            />
            <p className="help-text">
              We'll display all dates and times using the timezone you select.
            </p>
          </div>
        </div>
        <div className={'controls'}>
          <PrimaryButton disabled={!isValid} text="Next" onClick={onSubmit} />
        </div>
      </div>
    </div>
  );
};

export default WelcomeProfile;
