import { IconButton, mergeStyles, Modal, Stack } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import { BuyerOrientationSurveyHeader } from '../Components/BuyerOrientationSurvey/BuyerOrientationSurveyHeader';
import { BuyerOrientationSurveyStepIndicator } from '../Components/BuyerOrientationSurvey/BuyerOrientationSurveyStepIndicator';
import { BuyerOrientationSurveyActionButtons } from '../Components/BuyerOrientationSurvey/BuyerOrientationSurveyActionButtons';
import { BuyerOrientationSurveySecondaryHeader } from '../Components/BuyerOrientationSurvey/BuyerOrientationSurveySecondaryHeader';
import {
  ActionType,
  BuyerOrientationDialogProps,
  BuyerSelectedOptions,
} from '../../../types/BuyerOrientationSurveyTypes';
import {
  closeIconProps,
  optionValuesThatNeedPhoneNumber,
  stepIndexesBasedOnName,
  stepNames,
  stepNamesBasedOnIndex,
  surveyStepComponents,
} from '../Components/BuyerOrientationSurvey/buyerOrientationSurveyUtils';
import { useBuyerSurveyReducer } from '../../../Hooks/useBuyerSurveyReducer';
import { useBoolean } from '@fluentui/react-hooks';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AssistancePreference,
  BuyerPriorities,
  CommunicationPreference,
  ConsumptionPreference,
  UpdateContactPayload,
} from '@meetingflow/common/Api/data-contracts';
import toast from 'react-hot-toast';

const numberOfSteps = 4;

export const BuyerOrientationDialog = ({
  handleDismiss,
  ownerAvatarUrl,
  ownerName,
  userName,
  currentUserDetails,
  organizationSlug,
  dealRoomId,
  userEmail,
  userRole,
  refetchDealRoomData,
}: BuyerOrientationDialogProps) => {
  const [step, setStep] = useState(1);
  const [surveySelectedOptions, dispatchAction] = useBuyerSurveyReducer();

  const { getAccessTokenSilently } = useAuth0();

  const [showDialog, { setTrue: setShowDialog, setFalse: setNotShowDialog }] =
    useBoolean(false);

  const [isCurrentStepSelected, setIsCurrentStepSelected] = useState(false);
  const [
    isCurrentSelectedOptionSyncedWithBE,
    setIsCurrentSelectedOptionSyncedWithBE,
  ] = useState(false);

  const CurrentStepComponent = useMemo(() => {
    const stepName = stepNamesBasedOnIndex[`${step}`];
    return surveyStepComponents[stepName];
  }, [step]);

  const currentStepName = useMemo(
    () => stepNamesBasedOnIndex[`${step}`],
    [step],
  );

  const modalStyle = mergeStyles({
    '.ms-Dialog-main': {
      minHeight: 'auto',
      minWidth: 'auto',
      height: 'fit-content',
      width: 'calc(100% - 4rem)',
      maxWidth: '910px',
      maxHeight: 'calc(100% - 4rem)',
      boxShadow: '0px 2px 2px 0px #00000005',
      borderRadius: '4px',
      backgroundColor: 'transparent',

      '.ms-Modal-scrollableContent': {
        maxHeight: 'fit-content',
      },
    },

    '.buyer-orientation-wrapper': {
      width: '100%',
      '.close-button': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '1rem',
        backgroundColor: DEALROOMS_COLORS.neutralGray,
      },

      '.buyer-orientation-content': {
        padding: '2rem 4rem 3rem',
        backgroundColor: DEALROOMS_COLORS.neutralGray,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '2rem',
      },
    },
  });

  // Determines if the Next button should be disabled based on validation rules for each step
  const isNextButtonDisabled = useMemo(() => {
    const {
      assistancePreference,
      consumptionPreference,
      communicationPreference,
      phoneNumber,
      priorities,
      otherPriorityText,
    } = surveySelectedOptions;

    // Validation rules for each step
    const validationRules = {
      [stepNames.assistancePreference]: () => !assistancePreference,

      [stepNames.consumptionPreference]: () => !consumptionPreference,

      [stepNames.communicationPreference]: () => {
        const requiresPhone = optionValuesThatNeedPhoneNumber.includes(
          communicationPreference || '',
        );
        return !communicationPreference || (requiresPhone && !phoneNumber);
      },

      [stepNames.priorities]: () => {
        const hasOtherOption = priorities.includes('OTHER');
        return !priorities.length || (hasOtherOption && !otherPriorityText);
      },
    };

    // Return validation result for current step, or false if step not found
    return validationRules[currentStepName]?.() ?? false;
  }, [currentStepName, surveySelectedOptions]);

  useEffect(() => {
    // Skip if no user details or options already synced
    if (!currentUserDetails || isCurrentSelectedOptionSyncedWithBE) {
      return;
    }

    const {
      assistancePreference,
      consumptionPreference,
      communicationPreference,
      phoneNumber,
      priorities,
      otherPriorityText,
    } = currentUserDetails;

    // Check if all required preferences are completed
    const hasCompletedAllPreferences =
      assistancePreference &&
      consumptionPreference &&
      communicationPreference &&
      Array.isArray(priorities) &&
      priorities.length > 0;

    if (hasCompletedAllPreferences) {
      // Hide dialog if all preferences are set
      setNotShowDialog();
    } else {
      // Update form with existing preferences and show dialog
      dispatchAction({
        type: ActionType.UPDATE_MULTIPLE_SELECTIONS,
        payload: {
          assistancePreference,
          consumptionPreference,
          communicationPreference,
          phoneNumber,
          priorities,
          otherPriorityText,
        } as BuyerSelectedOptions,
      });
      setShowDialog();
    }

    // Mark options as synced with backend
    setIsCurrentSelectedOptionSyncedWithBE(true);
  }, [
    currentUserDetails,
    dispatchAction,
    isCurrentSelectedOptionSyncedWithBE,
    setNotShowDialog,
    setShowDialog,
  ]);

  useEffect(() => {
    // Skip if no user details or if step is already selected
    if (!currentUserDetails || isCurrentStepSelected) {
      return;
    }

    // Extract preferences from user details
    const {
      assistancePreference,
      consumptionPreference,
      communicationPreference,
      priorities,
    } = currentUserDetails;

    // Map of preferences to their corresponding step indexes
    const preferenceSteps = [
      { value: assistancePreference, step: stepNames.assistancePreference },
      { value: consumptionPreference, step: stepNames.consumptionPreference },
      {
        value: communicationPreference,
        step: stepNames.communicationPreference,
      },
      { value: priorities?.length, step: stepNames.priorities },
    ];

    // Find first uncompleted step
    const firstUncompletedStep = preferenceSteps.find(({ value }) => !value);

    if (firstUncompletedStep) {
      // Set step to first uncompleted preference
      setStep(stepIndexesBasedOnName[firstUncompletedStep.step]);
    }

    setIsCurrentStepSelected(true);
  }, [currentUserDetails, isCurrentStepSelected]);

  const handleUpdateOption = useCallback(
    async (step: number) => {
      // Validate required fields are present
      if (
        !organizationSlug ||
        !dealRoomId ||
        !userEmail ||
        !userRole ||
        !currentUserDetails
      ) {
        return;
      }

      const currentStepName = stepNamesBasedOnIndex[step];
      const token = await getAccessTokenSilently();

      // Initialize update payload with required role
      const dataToUpdate: UpdateContactPayload = {
        role: userRole,
      };

      // Helper function to check if value has changed
      const hasValueChanged = (
        newVal: string[] | string | null,
        oldVal: string[] | string | null,
      ) => {
        if (Array.isArray(newVal)) {
          return JSON.stringify(newVal) !== JSON.stringify(oldVal || []);
        }
        return newVal !== oldVal;
      };

      let needsPhone = false;
      let phoneChanged = false;

      let hasOtherPriority = false;
      let otherTextChanged = false;

      // Update data based on current step
      switch (currentStepName) {
        case stepNames.assistancePreference:
          if (
            hasValueChanged(
              surveySelectedOptions.assistancePreference,
              currentUserDetails.assistancePreference,
            )
          ) {
            dataToUpdate.assistancePreference =
              surveySelectedOptions.assistancePreference as AssistancePreference;
          }
          break;

        case stepNames.consumptionPreference:
          if (
            hasValueChanged(
              surveySelectedOptions.consumptionPreference,
              currentUserDetails.consumptionPreference,
            )
          ) {
            dataToUpdate.consumptionPreference =
              surveySelectedOptions.consumptionPreference as ConsumptionPreference;
          }
          break;

        case stepNames.communicationPreference:
          // Update communication preference if changed
          if (
            hasValueChanged(
              surveySelectedOptions.communicationPreference,
              currentUserDetails.communicationPreference,
            )
          ) {
            dataToUpdate.communicationPreference =
              surveySelectedOptions.communicationPreference as CommunicationPreference;
          }

          needsPhone = optionValuesThatNeedPhoneNumber.includes(
            surveySelectedOptions.communicationPreference || '',
          );
          phoneChanged =
            surveySelectedOptions.phoneNumber !==
            currentUserDetails.phoneNumber;

          // Add phone if needed and changed
          if (needsPhone && phoneChanged) {
            dataToUpdate.phoneNumber = surveySelectedOptions.phoneNumber;
          }
          // Remove phone if no longer needed
          else if (!needsPhone && currentUserDetails.phoneNumber) {
            dataToUpdate.phoneNumber = '';
          }
          break;

        case stepNames.priorities:
          // Update priorities if changed
          if (
            hasValueChanged(
              surveySelectedOptions.priorities,
              currentUserDetails.priorities,
            )
          ) {
            dataToUpdate.priorities =
              surveySelectedOptions.priorities as BuyerPriorities[];
          }

          hasOtherPriority = surveySelectedOptions.priorities.includes('OTHER');
          otherTextChanged =
            surveySelectedOptions.otherPriorityText !==
            currentUserDetails.otherPriorityText;

          // Add other priority text if needed and changed
          if (hasOtherPriority && otherTextChanged) {
            dataToUpdate.otherPriorityText =
              surveySelectedOptions.otherPriorityText;
          }
          // Remove other priority text if no longer needed
          else if (!hasOtherPriority && currentUserDetails.otherPriorityText) {
            dataToUpdate.otherPriorityText = '';
          }
          break;
      }

      // Only make API call if we have changes to update
      if (Object.keys(dataToUpdate).length <= 1) {
        // No changes needed, just move to next step
        setStep(step + 1);
        return;
      }

      await toast.promise(
        DealRoomsApiClient.updateContact(
          organizationSlug,
          dealRoomId,
          userEmail,
          dataToUpdate,
          { headers: { Authorization: `Bearer ${token}` } },
        ),
        {
          loading: 'Updating the option',
          success: () => {
            if (step < numberOfSteps) {
              setStep(step + 1);
            }
            if (refetchDealRoomData) {
              refetchDealRoomData().then();
            }
            return 'The option was successfully updated.';
          },
          error: 'Something went wrong. Please try again later',
        },
      );
    },
    [
      currentUserDetails,
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchDealRoomData,
      surveySelectedOptions.assistancePreference,
      surveySelectedOptions.communicationPreference,
      surveySelectedOptions.consumptionPreference,
      surveySelectedOptions.otherPriorityText,
      surveySelectedOptions.phoneNumber,
      surveySelectedOptions.priorities,
      userEmail,
      userRole,
    ],
  );

  const handleClickNext = useCallback(async () => {
    await handleUpdateOption(step);
  }, [handleUpdateOption, step]);

  const handleClickBack = useCallback(() => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      handleDismiss();
    }
  }, [handleDismiss, step]);

  if (!showDialog && currentStepName !== stepNames.invitations) {
    return null;
  }

  return (
    <Modal className={modalStyle} isOpen onDismiss={setNotShowDialog}>
      <Stack className="buyer-orientation-wrapper">
        <div className="close-button">
          <IconButton
            ariaLabel="Close"
            iconProps={closeIconProps}
            onClick={setNotShowDialog}
            alt="Close image"
          />
        </div>

        <BuyerOrientationSurveySecondaryHeader
          step={step}
          avatarUrl={ownerAvatarUrl}
          userName={userName}
          ownerName={ownerName}
        />
        <BuyerOrientationSurveyStepIndicator
          step={step}
          numberOfSteps={numberOfSteps}
        />
        <div className="buyer-orientation-content">
          <CurrentStepComponent
            surveySelectedOptions={surveySelectedOptions}
            dispatchAction={dispatchAction}
          />
          <BuyerOrientationSurveyActionButtons
            handleClickBack={handleClickBack}
            handleClickNext={handleClickNext}
            step={step}
            numberOfSteps={numberOfSteps}
            isNextButtonDisabled={isNextButtonDisabled}
          />
        </div>
      </Stack>
    </Modal>
  );
};
