import { useAuth0 } from '@auth0/auth0-react';
import {
  Dropdown,
  FontIcon,
  FontSizes,
  FontWeights,
  IDropdownOption,
  IconButton,
  NeutralColors,
  Text,
  TextField,
  mergeStyles,
  Spinner,
} from '@fluentui/react';
import { SummarizeContentPayload } from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { AsyncLink } from '../../HOC/AsyncLink';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../Hooks/useOrganization';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';

interface GPTGenerateButtonProps {
  organizationSlug: string;
  companyId: number;
  label?: string;
  generatingLabel?: string;
  regenerateLabel?: string;
  disabled?: boolean;
  onReset?: () => void;
  onToken?: (output: string) => void;
  onGenerating?: () => void;
  onSuccess?: (output: string) => void;
  onError?: (err: unknown) => void;
  onGeneratingChanged: (isGenerating: boolean) => void;
  surfaceName: string;
  maxTokens?: number;
}

const SummarizeButton = ({
  organizationSlug,
  companyId,
  label = 'Generate',
  regenerateLabel = 'Regenerate',
  disabled = false,
  onReset,
  onToken,
  onSuccess,
  onError,
  onGenerating,
  onGeneratingChanged,
  surfaceName,
  maxTokens,
}: GPTGenerateButtonProps) => {
  const abortController = useRef<AbortController>();
  const { getAccessTokenSilently } = useAuth0();
  const [generating, setGeneratingState] = useState<boolean>(false);
  const [hasGenerated, setHasGenerated] = useState<boolean>(false);
  const { isDark } = useLightOrDarkMode();
  const { hasEntitlement } = useOrganization();

  const setGenerating = useCallback(
    (isGenerating: boolean) => {
      onGeneratingChanged?.(isGenerating);
      setGeneratingState(isGenerating);
    },
    [onGeneratingChanged],
  );

  const gptGenerateButtonClass = mergeStyles({
    display: 'inline-block',
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semibold,
    height: '1.5rem',
    lineHeight: '1.5rem',

    ':disabled': {
      opacity: '.25',
      'i, span': {
        color: isDark ? NeutralColors.gray80 : NeutralColors.gray160,
      },
    },

    i: {
      float: 'right',
      display: 'inline-block',
      fontSize: FontSizes.large,
      color: MEETINGFLOW_COLORS.teal,
      fontWeight: FontWeights.regular,
      height: '1.5rem',
      width: '1.5rem',
      lineHeight: '1.5rem',
      marginLeft: '.25rem',
    },

    span: {
      display: 'inline-block',
      height: '1.5rem',
      lineHeight: '1.5rem',
      position: 'relative',
      top: '1px',
      color: isDark
        ? MEETINGFLOW_COLORS.purpleSecondary
        : MEETINGFLOW_COLORS.purplePrimary,
    },
  });

  const generate = async () => {
    abortController.current?.abort();

    const newController = new AbortController();
    abortController.current = newController;

    setGenerating(true);
    onReset?.();
    onGenerating?.();

    try {
      const token = await getAccessTokenSilently();
      const result = await new Promise<string>((resolve, reject) => {
        fetch(
          `/api/organization/${organizationSlug}/company/${companyId}/summarize`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'text/event-stream',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              maxTokens,
            }),
            signal: newController.signal,
          },
        )
          .then(async (res) => {
            if (res.status !== 200) {
              throw new Error(`Failed to start sumamrization`);
            }

            let generated = '';

            const reader = res.body
              ?.pipeThrough(new TextDecoderStream())
              .getReader();

            if (!reader) {
              throw new Error('Unable to get reader');
            }
            // eslint-disable-next-line no-constant-condition
            while (true) {
              // eslint-disable-next-line no-await-in-loop
              const read = await reader.read();
              if (read.done) {
                break;
              }
              generated += read.value;
              onToken?.(generated);
            }
            resolve(generated);
          })
          .catch((err) => reject(err));
      });

      if (result) {
        setHasGenerated(true);
      }
      onSuccess?.(result);
    } catch (err: unknown) {
      if (err instanceof DOMException && err.name === 'AbortError') {
        console.info('Stream summary cancelled');
        return;
      } else {
        toast.error('Something went wrong generating the insights');
      }
      console.error(typeof err, err);
      onError?.(err);
    } finally {
      if (abortController.current === newController) {
        setGenerating(false);
        abortController.current = undefined;
      }
    }
  };

  useEffect(() => {
    if (hasEntitlement('CHAT_GPT') && !disabled) {
      generate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, companyId, disabled]);

  return (
    <AsyncLink
      className={classNames(gptGenerateButtonClass, 'generate-button')}
      disabled={disabled}
      onClick={
        generating
          ? async () => {
              abortController.current?.abort();
              abortController.current = undefined;
            }
          : generate
      }
    >
      <FontIcon iconName="AISparkle" />
      <span>
        {generating ? 'Cancel' : hasGenerated ? regenerateLabel : label}
      </span>
    </AsyncLink>
  );
};

interface CompanySummaryCoachProps {
  organizationSlug: string;
  companyId: number;
  outputLabel?: string;
  generateLabel?: string;
  generatingLabel?: string;
  regenerateLabel?: string;
  disabled?: boolean;
  disableEditResult?: boolean;
  onGenerating?: () => void;
  onOutputChanged?: (value: string) => void;
  onGenerated?: (value: string) => void;
  onGeneratingChanged?: (isGenerating?: boolean) => void;
  surfaceName: string;
  hideLabels?: boolean;
  maxTokens?: number;
}

export const CompanySummaryCoach = ({
  organizationSlug,
  companyId,
  outputLabel = 'Output',
  generateLabel,
  generatingLabel,
  regenerateLabel,
  disabled,
  disableEditResult,
  onGenerating,
  onOutputChanged,
  onGenerated,
  onGeneratingChanged,
  surfaceName,
  hideLabels,
  maxTokens,
}: CompanySummaryCoachProps) => {
  const { hasEntitlement } = useOrganization();

  const [output, setOutput] = useState<string>('');
  const [generating, setGeneratingState] = useState<boolean>(false);

  const { isDark } = useLightOrDarkMode();

  const setGenerating = useCallback(
    (isGenerating: boolean) => {
      onGeneratingChanged?.(isGenerating);
      setGeneratingState(isGenerating);
    },
    [onGeneratingChanged],
  );

  const gptOutputBoxClass = mergeStyles({
    marginBottom: '.5rem',
    '.system.prompt': {
      paddingTop: '.5rem',
      transition: '.3s ease-in-out all',
      '.ms-Label': {
        fontSize: FontSizes.smallPlus,
      },
    },

    '.prompt': {
      paddingTop: '.5rem',
      transition: '.3s ease-in-out all',
      '.ms-Label': {
        fontSize: FontSizes.smallPlus,
      },
    },

    '.system-prompt': {
      paddingTop: '.5rem',
      transition: '.3s ease-in-out all',
      '.ms-Label': {
        fontSize: FontSizes.smallPlus,
      },
    },

    '.output': {
      position: 'relative',
      '.ms-TextField-wrapper label': {
        textAlign: 'center',
      },
      '.generate-button': {
        float: 'right',
      },
      '.output-input': {
        '.ms-TextField-fieldGroup': {
          width: '100%',
          border: 'none',

          textarea: {
            cursor: 'default !important',
            minHeight: '8rem',
            fontSize: '12px',
            lineHeight: '1.2rem',

            padding: '.5rem',
            width: '100%',
            height: 'auto',
            boxSizing: 'border-box',
            // fontFamily: 'Kalam',
            border: isDark
              ? `1px solid ${NeutralColors.gray180}`
              : `1px solid rgba(200, 198, 196)`,
            borderRadius: '.25rem',
            flexDirection: 'column',
            overflowWrap: 'break-word',
            overflow: 'auto',
            backgroundColor: isDark
              ? NeutralColors.gray220
              : MEETINGFLOW_COLORS.white,

            animationName: 'fadeInAnimation',
            animationDuration: '.3s',
            transitionTimingFunction: 'linear',
            animationIterationCount: '1',
            animationFillMode: 'forwards',
            transition: '.5s ease-in-out all',

            ':disabled': {
              color: 'white !important',
              backgroundColor: MEETINGFLOW_COLORS.teal,
              fontWeight: FontWeights.semibold,
              boxShadow: '0 0 10px inset rgba(0,0,0,.1)',
              cursor: 'not-allowed',

              border: isDark
                ? `5px solid ${MEETINGFLOW_COLORS.purpleGrey}`
                : `5px solid ${MEETINGFLOW_COLORS.purpleGrey}`,
            },
          },
        },
      },
    },
  });

  return (
    <div className={classNames(gptOutputBoxClass, 'gpt-output-box')}>
      {generating && !output ? (
        <Spinner style={{ margin: '1.5rem 0' }} label="Generating..." />
      ) : null}
      <div className="output">
        {navigator.clipboard && !disabled && !generating ? (
          <IconButton
            iconProps={{ iconName: 'Copy' }}
            onClick={() => {
              navigator.clipboard.writeText(output);
              toast.success(`Copied ${outputLabel} to the clipboard.`);
            }}
            styles={{
              root: {
                width: '1rem',
                height: '1rem',
                position: 'absolute',
                top: '2rem',
                right: '.25rem',
                zIndex: '500',
              },
              icon: { fontSize: '10px' },
            }}
            title={`Copy ${outputLabel} to clipboard`}
          />
        ) : null}
        <TextField
          disabled={generating || disableEditResult}
          className="output-input"
          // Ignore because I want some JSX here, which works fine, but TS wants it to be a string. - jcroft
          // @ts-ignore
          label={
            <div>
              {hideLabels ? undefined : outputLabel}
              {hasEntitlement('CHAT_GPT') ? (
                <SummarizeButton
                  organizationSlug={organizationSlug}
                  companyId={companyId}
                  onReset={() => {
                    setOutput('');
                    onOutputChanged?.('');
                  }}
                  onGenerating={onGenerating}
                  onToken={(generated) => {
                    setOutput(generated);
                    onOutputChanged?.(generated);
                  }}
                  onSuccess={(generated) => {
                    setOutput(generated);
                    onGenerated?.(generated);
                  }}
                  onGeneratingChanged={setGenerating}
                  disabled={disabled}
                  surfaceName={surfaceName}
                  label={generateLabel}
                  generatingLabel={generatingLabel}
                  regenerateLabel={regenerateLabel}
                  maxTokens={maxTokens}
                />
              ) : null}
            </div>
          }
          value={output}
          multiline
          readOnly
          onChange={(_e, newValue) => {
            setOutput(newValue ?? '');
          }}
        />
      </div>
    </div>
  );
};

export default CompanySummaryCoach;
