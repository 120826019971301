import { useAuth0 } from '@auth0/auth0-react';
import {
  ComboBox,
  DatePicker,
  DefaultButton,
  FontSizes,
  IColumn,
  IComboBoxStyles,
  IDatePickerStyles,
  ISearchBoxStyles,
  mergeStyles,
  NeutralColors,
  SearchBox,
  SelectionMode,
  Toggle,
} from '@fluentui/react';
import { Company, Meetingflow } from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import toast from 'react-hot-toast';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { EMPTY_ARRAY } from '../../../Constants';
import {
  addArrayValue,
  collectToRecord,
  deleteValue,
  getArrayValues,
  getDateValue,
  getValue,
  mergeSearchParams,
  removeAllArrayValues,
  removeArrayValue,
  setValue,
} from '../../../Helpers/SearchParamHelpers';
import { useConfirmationDialog } from '../../../Hooks/Modals/useConfirmationDialog';
import useBreakpoints from '../../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useTitle } from '../../../Hooks/useTitle';
import {
  OrganizationCompanies,
  OrganizationContactsQuery,
  OrganizationMeetingPlansQuery,
  OrganizationMembersQuery,
  OrganizationOrganizerContactsQuery,
  OrganizationTagsQuery,
} from '../../../QueryNames';
import {
  CompaniesApiClient,
  ContactsApiClient,
  MeetingflowsApiClient,
  MembersApiClient,
  TagsApiClient,
} from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { AsyncIconButton } from '../../HOC/AsyncButton';
import { MeetingflowCard } from '../../MeetingPlans/MeetingflowCard';
import { ContactCard } from '../../MeetingPlans/MeetingPlanAttendees';
import { StyledDetailsList } from '../../StyledDetailsList';

type AvailableColumns =
  | 'event'
  | 'organizer'
  | 'creator'
  | 'attendees'
  | 'recurring'
  | 'status'
  | 'delete';

interface PlansPaneProps {
  columns?: AvailableColumns[];
  limit?: number;
  displayFiltering?: boolean;
  allowScrolling?: boolean;
}

const DEFAULT_PLANS_PER_PAGE = 30;

export const PlansPane = ({
  columns,
  limit = DEFAULT_PLANS_PER_PAGE,
  displayFiltering = true,
  allowScrolling = true,
}: PlansPaneProps) => {
  const { user: currentUser, getAccessTokenSilently } = useAuth0();
  const { isAdmin, slug: organizationSlug, hasEntitlement } = useOrganization();

  const { isDark } = useLightOrDarkMode();

  const [searchParams, setSearchParams] = useSearchParams();

  const [searchFilters, filterString] = useMemo(() => {
    searchParams.sort();
    return [collectToRecord(searchParams), searchParams.toString()];
  }, [searchParams]);

  const [debouncedFilterString] = useDebounce(filterString, 500);

  const appInsights = useAppInsightsContext();

  useTitle('Plans - Library');

  const {
    createDeferred: createConfirmDeleteDeferred,
    dialog: confirmDeleteDialog,
  } = useConfirmationDialog({
    title: `Delete Meetingflow?`,
    subText:
      'Deleting this Meetingflow will delete it for all users and cannot be undone. Are you sure you want to delete this Meetingflow?',
    primaryAction: 'CANCEL',
  });

  const parentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { ref, inView } = useInView({ root: parentRef.current });

  const {
    userId,
    // user: userProfile,
    // refetchBackground: refetchUserProfile,
  } = useUserProfile();

  // const [showAttendees, setShowAttendees] = useState<boolean>(
  //   userProfile?.preferenceAgendaShowAttendees === 'COMPANY_AND_ATTENDEES' ??
  //     false,
  // );

  const navigate = useNavigate();

  const breakpoints = useBreakpoints();

  const {
    data: companyData,
    isLoading: companyIsLoading,
    isRefetching: companyIsRefetching,
  } = useQuery(
    OrganizationCompanies(organizationSlug!, true),
    async () => {
      const token = await getAccessTokenSilently();
      return CompaniesApiClient.listCompanies(
        {
          organizationSlug: organizationSlug!,
          hasPlans: true,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!organizationSlug },
  );

  const {
    data: membersData,
    isLoading: membersLoading,
    isRefetching: membersRefetching,
  } = useQuery(
    OrganizationMembersQuery(organizationSlug!, true),
    async () => {
      const token = await getAccessTokenSilently();
      return MembersApiClient.listMembers(
        { organizationSlug: organizationSlug!, hasPlans: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!organizationSlug },
  );

  const {
    data: contactsData,
    isLoading: contactsLoading,
    isRefetching: contactsRefetching,
  } = useQuery(
    OrganizationContactsQuery(organizationSlug!, true),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.listContacts(
        { organizationSlug: organizationSlug!, hasPlans: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!organizationSlug },
  );

  const {
    data: organizersData,
    isLoading: organizersLoading,
    isRefetching: organizersRefetching,
  } = useQuery(
    OrganizationOrganizerContactsQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.listContacts(
        { organizationSlug: organizationSlug!, organizedPlans: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { enabled: !!organizationSlug },
  );

  const {
    data: tagsData,
    isLoading: tagsLoading,
    isRefetching: tagsRefetching,
  } = useQuery(
    OrganizationTagsQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return TagsApiClient.listTags(
        { organizationSlug: organizationSlug! },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug,
    },
  );

  const {
    data: plansData,
    isLoading: plansLoading,
    isFetched: plansFetched,
    isFetching: plansFetching,
    isRefetching: plansRefetching,
    isFetchingPreviousPage,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    OrganizationMeetingPlansQuery(
      organizationSlug!,
      debouncedFilterString,
      limit,
    ),
    async ({ pageParam, queryKey, meta }) => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.listPlans(
        {
          ...searchFilters,
          organizationSlug: organizationSlug!,
          limit,
          skip: pageParam || 0,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug,
      getPreviousPageParam: (previousPage) => {
        if (!previousPage?.config?.params?.skip) {
          return undefined;
        }
        return Math.max((previousPage?.config?.params?.skip ?? 0) - limit, 0);
      },
      getNextPageParam: (previousPage) => {
        if (!previousPage?.data?.length || previousPage.data.length < limit) {
          return undefined;
        }

        return (previousPage?.config?.params?.skip ?? 0) + limit;
      },
    },
  );

  useEffect(() => {
    if (inView && allowScrolling && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const onRenderMeetingflow = useCallback(
    (meetingPlan: Meetingflow) => (
      <MeetingflowCard
        meetingflowId={meetingPlan.id}
        meetingflowObj={meetingPlan}
        refetchMeetingflow={refetch}
        organizationSlug={organizationSlug!}
        hideShowConferenceJoinButton
        showCompanies
        showCallRecordingButton
        onClick={() => {
          navigate(`/organization/${organizationSlug}/plan/${meetingPlan.id}`);
        }}
      />
    ),
    [navigate, organizationSlug, refetch],
  );

  const onRenderContact = useCallback(
    (meetingPlan: Meetingflow) =>
      organizationSlug && meetingPlan.owner ? (
        <ContactCard
          contact={meetingPlan.owner}
          organizationSlug={organizationSlug}
          domain={meetingPlan.owner.email.split('@')[0]}
          hideBottomBorder
          hideSocialIcons
          showOnlyAvatar={!breakpoints?.lg}
        />
      ) : (
        <></>
      ),
    [organizationSlug, breakpoints],
  );

  const onRenderDelete = useCallback(
    (meetingPlan: Meetingflow) => {
      const canDelete =
        (isAdmin ||
          meetingPlan.ownerId === userId ||
          (meetingPlan.creator &&
            meetingPlan.creator?.email === currentUser?.email) ||
          (meetingPlan.organizer &&
            meetingPlan.organizer?.email === currentUser?.email)) &&
        !meetingPlan?.sampleType;
      return (
        <AsyncIconButton
          name="Delete"
          iconProps={{ iconName: 'Delete' }}
          disabled={!canDelete}
          onClick={async () => {
            try {
              const confirmDelete = await createConfirmDeleteDeferred().promise;

              if (!confirmDelete || !organizationSlug) {
                return;
              }

              const token = await getAccessTokenSilently();
              await toast.promise(
                MeetingflowsApiClient.deleteMeetingflow(
                  organizationSlug!,
                  meetingPlan.id,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                ),
                {
                  loading: 'Deleting Meetingflow',
                  success: (_response) => {
                    refetch();

                    appInsights.trackEvent({
                      name: 'DELETE_PLAN',
                      properties: {
                        organizationSlug,
                        planId: meetingPlan.id,
                      },
                    });

                    return 'Successfully deleted Meetingflow';
                  },
                  error: (err: unknown) => {
                    return 'Something went wrong deleting Meetingflow, please try again later';
                  },
                },
              );
            } catch (err: unknown) {}
          }}
          text="Delete"
        />
      );
    },
    [
      appInsights,
      createConfirmDeleteDeferred,
      currentUser?.email,
      getAccessTokenSilently,
      isAdmin,
      organizationSlug,
      refetch,
      userId,
    ],
  );

  const meetingPlanColumns: IColumn[] = useMemo(
    () =>
      [
        {
          key: 'flow',
          name: 'Meetingflow',
          minWidth: 300,
          maxWidth: breakpoints?.lg ? 500 : 300,
          className: 'meetingflow-card',
          fieldName: 'title',
          onRender: onRenderMeetingflow,
        },
        {
          key: 'creator',
          name: 'Meetingflow Creator',
          minWidth: breakpoints?.lg ? 250 : 32,
          maxWidth: breakpoints?.lg ? 250 : 32,
          fieldName: 'creator',
          className: breakpoints?.md ? 'creator' : 'hidden',
          headerClassName: breakpoints?.md ? 'creator' : 'hidden',
          onRender: onRenderContact,
        },
        {
          key: 'delete',
          name: 'Delete',
          minWidth: 80,
          maxWidth: 80,
          fieldName: 'delete',
          className: 'delete',
          onRender: onRenderDelete,
        },
      ]
        .filter(Boolean)
        .filter((c) =>
          columns
            ? columns?.includes((c?.key || '') as AvailableColumns)
            : true,
        ) as IColumn[],
    [
      onRenderMeetingflow,
      onRenderContact,
      onRenderDelete,
      columns,
      breakpoints?.md,
      breakpoints?.lg,
    ],
  );

  const tableControlsClass = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    columnGap: '.25rem',
    margin: '1rem .25rem .5rem .25rem',
    overflowX: 'auto',
    overflowY: 'hidden',
  });

  const searchBoxStyles = useMemo(
    () =>
      ({
        root: {
          maxWidth: '15rem',
          marginBottom: '.5rem',
          flexGrow: 1,
          transition: '.3s ease-in-out all',
          height: '1.5rem',
          borderRadius: '.25rem',
          backgroundColor: isDark
            ? NeutralColors.gray180
            : MEETINGFLOW_COLORS.purpleGrey,
          border: 'none',
          ':after': {
            border: 'none',
          },
        },
        field: {
          height: '1.25rem',
          borderRadius: '.25rem',
          fontSize: FontSizes.small,
          color: isDark
            ? NeutralColors.gray100
            : MEETINGFLOW_COLORS.purpleMedium,

          '::placeholder': {
            color: isDark
              ? NeutralColors.gray100
              : MEETINGFLOW_COLORS.purpleMedium,
          },
        },
      }) as Partial<ISearchBoxStyles>,
    [isDark],
  );

  const comboBoxStyles = useMemo(
    () =>
      ({
        root: {
          marginBottom: '.5rem',
          flexGrow: 1,
          transition: '.3s ease-in-out all',
          height: '1.5rem',
          lineHeight: '1.5rem',
          fontSize: FontSizes.small,
          backgroundColor: isDark
            ? NeutralColors.gray200
            : MEETINGFLOW_COLORS.purpleGrey,
          border: 'none',
          borderRadius: '.25rem',
          minWidth: '5rem',

          ':after': {
            display: 'none !important',
          },

          '.ms-Icon': {
            color: MEETINGFLOW_COLORS.purpleMedium,
          },

          '.ms-ComboBox-CaretDown-button': {
            backgroundColor: isDark
              ? NeutralColors.gray200
              : MEETINGFLOW_COLORS.purpleGrey,
          },
        },
        input: {
          backgroundColor: 'transparent',
          color: isDark ? NeutralColors.gray100 : NeutralColors.gray130,
          '::placeholder': {
            color: isDark
              ? NeutralColors.gray100
              : MEETINGFLOW_COLORS.purpleMedium,
          },
        },
      }) as Partial<IComboBoxStyles>,
    [isDark],
  );

  const datePickerStyles = useMemo(
    () =>
      ({
        textField: {
          marginBottom: '.5rem',
          flexGrow: 1,
          transition: '.3s ease-in-out all',

          'div[role="combobox"]': {
            height: '1.5rem',
            lineHeight: '1.5rem',
            fontSize: FontSizes.small,
            backgroundColor: isDark
              ? NeutralColors.gray200
              : MEETINGFLOW_COLORS.purpleGrey,
            border: 'none',
            borderRadius: '.25rem',
            minWidth: '3rem',
            color: isDark
              ? NeutralColors.gray100
              : MEETINGFLOW_COLORS.purpleMedium,

            label: {
              color: isDark
                ? NeutralColors.gray100
                : MEETINGFLOW_COLORS.purpleMedium,
            },

            '*': {
              color: isDark
                ? NeutralColors.gray100
                : MEETINGFLOW_COLORS.purpleMedium,
            },
          },
        },
        root: {
          height: '1.5rem',
          div: {
            height: '1.5rem',
            border: 'none !important',
            borderRadius: '.25rem !important',
            backgroundColor: 'tnasparent',

            ':after': {
              display: 'none !important',
            },
          },
        },
        icon: {
          margin: '.2rem 0 0 0',
          padding: 0,
          color: MEETINGFLOW_COLORS.purpleMedium,
        },
      }) as Partial<IDatePickerStyles>,
    [isDark],
  );

  const clearButtonStyles = useMemo(
    () => ({
      root: {
        height: '1.5rem',
        backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
        border: 'none !important',
        transition: '.3s ease-in-out all',
        'div:after': {
          display: 'none !important',
        },
      },
      rootDisabled: {
        backgroundColor: 'transparent',
      },
      rootHovered: {
        backgroundColor: MEETINGFLOW_COLORS.purpleDarker,
      },
      icon: {
        color: 'white',
      },
    }),
    [],
  );

  return (
    <>
      {displayFiltering ? (
        <div className={tableControlsClass}>
          <SearchBox
            styles={searchBoxStyles}
            defaultValue={getValue(searchParams, 'q')}
            placeholder="Search..."
            onChange={(_event, text) => {
              if (!!text) {
                setSearchParams(setValue(searchParams, 'q', text));
              } else {
                setSearchParams(deleteValue(searchParams, 'q'));
              }
            }}
          />

          <DatePicker
            styles={datePickerStyles}
            value={getDateValue(searchParams, 'minDate')}
            placeholder="From date..."
            ariaLabel="Select a Minimum date"
            onSelectDate={(date) => {
              if (!!date) {
                setSearchParams(
                  setValue(
                    searchParams,
                    'minDate',
                    DateTime.fromJSDate(date).startOf('day').toISO()!,
                  ),
                );
              } else {
                setSearchParams(deleteValue(searchParams, 'minDate'));
              }
            }}
          />

          <DatePicker
            styles={datePickerStyles}
            value={getDateValue(searchParams, 'maxDate')}
            placeholder="To date..."
            ariaLabel="Select a maximum date"
            onSelectDate={(date) => {
              if (!!date) {
                setSearchParams(
                  setValue(
                    searchParams,
                    'maxDate',
                    DateTime.fromJSDate(date).endOf('day').toISO()!,
                  ),
                );
              } else {
                setSearchParams(deleteValue(searchParams, 'maxDate'));
              }
            }}
          />

          <ComboBox
            style={{ maxWidth: '10rem' }}
            styles={comboBoxStyles}
            multiSelect={!!membersData?.data?.length}
            options={
              membersData?.data?.map((user) => ({
                key: user.id.toString(),
                text: user.name || user.email,
              })) ?? [
                {
                  key: 'none',
                  text: 'No Meetingflow creators',
                  disabled: true,
                },
              ]
            }
            selectedKey={
              searchParams.has('owners') ? searchParams.getAll('owners') : []
            }
            placeholder="Meetingflow created by..."
            onChange={(_event, owner) => {
              if (!!owner) {
                if (!!owner.selected) {
                  setSearchParams(
                    addArrayValue(searchParams, 'owners', owner.key.toString()),
                  );
                } else {
                  setSearchParams(
                    removeArrayValue(
                      searchParams,
                      'owners',
                      owner.key.toString(),
                    ),
                  );
                }
              } else {
                setSearchParams(removeAllArrayValues(searchParams, 'owners'));
              }
            }}
          />

          <ComboBox
            styles={comboBoxStyles}
            multiSelect
            options={
              organizersData?.data?.map((contact) => ({
                key: contact.id.toString(),
                text: contact.name || contact.email,
              })) ?? [
                {
                  key: 'none',
                  text: 'No Meetingflow organizers',
                  disabled: true,
                },
              ]
            }
            selectedKey={
              searchParams.has('organizers')
                ? searchParams.getAll('organizers')
                : []
            }
            placeholder="Event organized by..."
            onChange={(_event, organizer) => {
              if (!!organizer) {
                if (!!organizer.selected) {
                  setSearchParams(
                    addArrayValue(
                      searchParams,
                      'organizers',
                      organizer.key.toString(),
                    ),
                  );
                } else {
                  setSearchParams(
                    removeArrayValue(
                      searchParams,
                      'organizers',
                      organizer.key.toString(),
                    ),
                  );
                }
              } else {
                setSearchParams(
                  removeAllArrayValues(searchParams, 'organizers'),
                );
              }
            }}
          />

          <ComboBox
            styles={comboBoxStyles}
            multiSelect
            allowFreeform
            disabled={companyIsLoading}
            options={
              companyData?.data?.map((company: Company) => ({
                key: company.id.toString(),
                text:
                  company.name?.slice(0, 25) ??
                  company?.legalName?.slice(0, 25) ??
                  company.id?.toString(),
              })) ?? [
                {
                  key: 'none',
                  text: 'No companies',
                  disabled: true,
                },
              ]
            }
            selectedKey={
              searchParams.has('companies')
                ? searchParams.getAll('companies')
                : []
            }
            placeholder="With companies..."
            onChange={(_event, company) => {
              if (!!company) {
                if (!!company.selected) {
                  setSearchParams(
                    addArrayValue(
                      searchParams,
                      'companies',
                      company.key.toString(),
                    ),
                  );
                } else {
                  setSearchParams(
                    removeArrayValue(
                      searchParams,
                      'companies',
                      company.key.toString(),
                    ),
                  );
                }
              } else {
                setSearchParams(
                  removeAllArrayValues(searchParams, 'companies'),
                );
              }
            }}
          />

          <ComboBox
            styles={comboBoxStyles}
            disabled={contactsLoading}
            multiSelect
            allowFreeform
            options={[
              ...(contactsData?.data
                ? (contactsData?.data?.map((contact) => ({
                    key: contact.id.toString(),
                    text: contact.name || contact.email,
                  })) ?? [])
                : [
                    {
                      key: 'none',
                      text: 'No attendees',
                      disabled: true,
                    },
                  ]),
            ]}
            selectedKey={getArrayValues(searchParams, 'attendees')}
            placeholder="With attendees..."
            onChange={(_event, attendee) => {
              if (!!attendee) {
                if (!!attendee.selected) {
                  const newParams = addArrayValue(
                    searchParams,
                    'attendees',
                    attendee.key.toString(),
                  );
                  setSearchParams(mergeSearchParams(searchParams, newParams));
                } else {
                  const newParams = removeArrayValue(
                    searchParams,
                    'attendees',
                    attendee.key.toString(),
                  );
                  setSearchParams(mergeSearchParams(searchParams, newParams));
                }
              } else {
                const newParams = removeAllArrayValues(
                  searchParams,
                  'attendees',
                );
                setSearchParams(mergeSearchParams(searchParams, newParams));
              }
            }}
          />

          <ComboBox
            styles={comboBoxStyles}
            disabled={tagsLoading}
            multiSelect
            allowFreeform
            options={[
              ...(tagsData?.data
                ? (tagsData?.data?.map((tag) => ({
                    key: tag.id,
                    text: `#${tag.label || tag.id}`,
                  })) ?? [])
                : [
                    {
                      key: 'none',
                      text: 'No Tags',
                      disabled: true,
                    },
                  ]),
            ]}
            selectedKey={getArrayValues(searchParams, 'tagIds')}
            placeholder="With tags..."
            onChange={(_event, tag) => {
              if (!!tag) {
                if (!!tag.selected) {
                  const newParams = addArrayValue(
                    searchParams,
                    'tagIds',
                    tag.key.toString(),
                  );
                  setSearchParams(mergeSearchParams(searchParams, newParams));
                } else {
                  const newParams = removeArrayValue(
                    searchParams,
                    'tagIds',
                    tag.key.toString(),
                  );
                  setSearchParams(mergeSearchParams(searchParams, newParams));
                }
              } else {
                const newParams = removeAllArrayValues(searchParams, 'tagIds');
                setSearchParams(mergeSearchParams(searchParams, newParams));
              }
            }}
          />

          {hasEntitlement('CALL_RECORDING') ? (
            <Toggle
              label={'Has Recording'}
              checked={
                searchParams.has('hasRecording') &&
                searchParams.get('hasRecording') === 'true'
              }
              onChange={(e, checked) => {
                if (checked) {
                  setSearchParams(
                    setValue(searchParams, 'hasRecording', 'true'),
                  );
                } else {
                  setSearchParams(deleteValue(searchParams, 'hasRecording'));
                }
              }}
              styles={{
                root: {
                  display: 'inline-block',
                  margin: 0,
                  flexGrow: 1,
                  transition: '.3s ease-in-out all',
                  height: '1.5rem',
                  position: 'relative',
                  minWidth: '7rem',

                  '.ms-Toggle-innerContainer': {
                    display: 'inline-block',
                    position: 'relative',
                    top: '.2rem',

                    button: {
                      transition: 'all .3s ease-in-out',
                      backgroundColor: isDark
                        ? NeutralColors.gray180
                        : MEETINGFLOW_COLORS.purpleGrey,
                    },
                  },
                  '.ms-Toggle-thumb': {
                    backgroundColor:
                      searchParams.has('hasRecording') &&
                      searchParams.get('hasRecording') === 'true'
                        ? MEETINGFLOW_COLORS.purpleTertiary
                        : isDark
                          ? NeutralColors.gray130
                          : NeutralColors.gray70,
                  },
                  'button:disabled .ms-Toggle-thumb': {
                    backgroundColor: NeutralColors.gray70,
                  },
                  'button:hover': {
                    backgroundColor: isDark
                      ? NeutralColors.gray190
                      : NeutralColors.gray40,
                  },
                  button: {},
                },
                label: {
                  padding: 0,
                  height: '1.5rem',
                  lineHeight: '1.5rem',
                  fontSize: FontSizes.mini,
                  color: NeutralColors.gray120,
                  maxWidth: '5rem',
                  marginRight: '.25rem',
                },
                pill: {
                  backgroundColor: NeutralColors.gray40,
                  outlineWidth: `0 !important`,
                  borderWidth: `0 !important`,
                },
              }}
            />
          ) : null}

          <DefaultButton
            iconProps={{ iconName: 'ClearFilter' }}
            label="Clear filters"
            alt="Clear filters"
            disabled={Array.from(searchParams.keys()).length === 0}
            onClick={() => setSearchParams(new URLSearchParams())}
            styles={clearButtonStyles}
          />
        </div>
      ) : null}

      <div
        ref={parentRef}
        style={{
          maxHeight: 'calc(100% - 4rem)',
          overflowY: 'auto',
          height: `calc(100% - 4rem)`,
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
            : MEETINGFLOW_COLORS.white,
        }}
      >
        {plansData?.pages?.length ? (
          plansData?.pages.map((page, index) => {
            return (
              <StyledDetailsList
                key={index}
                enableShimmer={plansFetching && !plansFetched}
                shimmerLines={25}
                items={page?.data || EMPTY_ARRAY}
                selectionMode={SelectionMode.none}
                columns={meetingPlanColumns}
                isHeaderVisible={!allowScrolling ? false : index === 0}
                className="small-header"
              />
            );
          })
        ) : (
          <StyledDetailsList
            enableShimmer
            shimmerLines={25}
            items={EMPTY_ARRAY}
            selectionMode={SelectionMode.none}
            columns={meetingPlanColumns}
            isHeaderVisible={false}
            className="small-header"
          />
        )}

        {allowScrolling && plansData?.pages ? (
          <div ref={ref}>
            <button
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
              style={{
                display: hasNextPage ? 'block' : 'none',
                margin: '0 auto',
              }}
            >
              {isFetchingNextPage
                ? 'Loading more...'
                : hasNextPage
                  ? 'Load More'
                  : 'Nothing more to load'}
            </button>
            <div>
              {plansFetching && !isFetchingNextPage
                ? 'Background Updating...'
                : null}
            </div>
          </div>
        ) : null}
      </div>
      {confirmDeleteDialog}
    </>
  );
};
