import {
  Icon,
  IContextualMenuItemProps,
  mergeStyleSets,
} from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const ArtifactContextualMenuCustomItem = (
  props: IContextualMenuItemProps,
) => {
  const styles = mergeStyleSets({
    customItemContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '.25rem',
      paddingLeft: '.25rem',
    },
    customItemIcon: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '1rem',
    },
    customItemText: {
      fontSize: '.875rem',
      fontWeight: '400',
      lineHeight: '1.25rem',
      color: DEALROOMS_COLORS.neutralDarker,
    },
  });

  return (
    <div className={styles.customItemContainer}>
      {props.item.iconProps?.iconName && (
        <Icon
          className={styles.customItemIcon}
          iconName={props.item.iconProps?.iconName}
        />
      )}
      <span className={styles.customItemText}>{props.item.text}</span>
    </div>
  );
};
