import { useAuth0 } from '@auth0/auth0-react';
import {
  FontIcon,
  ISearchBox,
  ISearchBoxStyles,
  IToggleStyles,
  ITooltipHostStyles,
  PrimaryButton,
  SearchBox,
  Spinner,
  Text,
  Toggle,
  TooltipHost,
  mergeStyles,
  Link,
} from '@fluentui/react';
import { FontSizes, FontWeights, NeutralColors } from '@fluentui/theme';
import {
  CalendarEvent,
  Meetingflow,
  MeetingflowTemplate,
  PostMeetingflowPayload,
} from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AsyncLink } from '../../Components/HOC/AsyncLink';
import { BaseModal } from '../../Components/MeetingPlans/Dialogs/BaseModal';
import EventCard from '../../Components/Organization/Library/EventCard';
import { EMPTY_ARRAY } from '../../Constants';
import { isAxiosErrorResponse } from '../../Helpers/AxiosHelpers';
import { isGoogleUser } from '../../Helpers/IdentityHelpers';
import {
  MeetingPlanQuery,
  OrganizationMeetingPlansQuery,
  OrganizationMeetingsHappeningSoon,
  OrganizationUpcomingMeetings,
} from '../../QueryNames';
import {
  ApiClient,
  EventsApiClient,
  MeetingflowsApiClient,
} from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { useDeferredPromise } from '../useDeferredPromise';
import { useLightOrDarkMode } from '../useLightOrDarkMode';
import { useOrganization } from '../useOrganization';
import { useUserProfile } from '../useProfile';
import toast from 'react-hot-toast';
import { isExternalEventOrMeetingflow } from '../../Helpers/MeetingPlanHelpers';

export type RecordUpcomingMeetingsResult = {
  events: CalendarEvent[];
};

export type RecordingUpcomingMeetingsDialogOptions = {};

export const useRecordUpcomingMeetingsDialog = () => {
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();
  const { getAccessTokenSilently, user } = useAuth0();
  const {
    slug: organizationSlug,
    organization,
    canCreatePlans,
    internalDomains,
  } = useOrganization();

  const { createDeferred, resolve, reject, deferred } = useDeferredPromise<
    RecordUpcomingMeetingsResult | undefined,
    RecordingUpcomingMeetingsDialogOptions | undefined
  >();

  const [selectedEvents, setSelectedEvents] = useState<
    CalendarEvent[] | undefined
  >(undefined);

  const { isDark } = useLightOrDarkMode();

  const client = useQueryClient();

  const {
    user: mfUser,
    workspacePreferences,
    updateWorkspacePreferencesAsync,
  } = useUserProfile();

  const [searchParams] = useSearchParams();
  const firstVisit = searchParams.get('firstVisit') === 'true';

  const createMeetingflow = async (
    event: CalendarEvent | undefined,
    scheduleCallRecording = false,
  ) => {
    if (organizationSlug && canCreatePlans) {
      const token = await getAccessTokenSilently();

      let reqBody: PostMeetingflowPayload = { record: scheduleCallRecording };
      if (event) {
        reqBody = {
          source: event.source,
          eventId: event.externalId,
          eventTime: event.startTime,
          record: scheduleCallRecording,
        };
      }

      // TO DO: This currently only creates meetingflows, but we need to add the recording scheduling for each selected event
      await toast.promise(
        MeetingflowsApiClient.postMeetingflow(organizationSlug, reqBody, {
          headers: { Authorization: `Bearer ${token}` },
          validateStatus: (code) => code === 201 || code === 302,
        }),
        {
          loading: 'Creating Meetingflow',
          success: (result) => {
            Promise.all([
              client.invalidateQueries(
                OrganizationMeetingPlansQuery(organizationSlug),
              ),
              client.invalidateQueries(
                OrganizationMeetingsHappeningSoon(organizationSlug),
              ),
              client.invalidateQueries(
                OrganizationUpcomingMeetings(organizationSlug),
              ),
            ]);

            client.setQueryData(
              MeetingPlanQuery(organizationSlug, result.data.id),
              result,
            );

            if (result.status === 201) {
              appInsights.trackEvent({
                name: `CREATE_PLAN${event ? '_FOR_EVENT' : '_ADHOC'}`,
                properties: {
                  organizationSlug,
                  source: event ? event?.source : '',
                  eventId: event ? event?.externalId : '',
                  surface: 'RECORD_UPCOMING_MEETINGS_DIALOG',
                },
              });

              scheduleCallRecordingForMeetingflow(result.data);

              return `A Meetingflow ${
                event ? `for ${event?.title}` : ''
              } has been created!`;
            } else if (result.status === 302) {
              return `A Meetingflow ${
                event ? `for ${event?.title}` : ''
              } has been created!`;
            }

            return null;
          },
          error: (err) => {
            if (err && isAxiosErrorResponse(err, 403)) {
              return `You don't have permission to create Meetingflows in this workspace`;
            }

            appInsights.trackException({
              exception: err instanceof Error ? err : new Error(err),
              properties: {
                organizationSlug,
                eventSource: event?.source,
                eventId: event?.externalId,
                surface: 'RECORD_UPCOMING_MEETINGS_DIALOG',
                status: isAxiosErrorResponse(err)
                  ? err.response?.status
                  : undefined,
                statusText: isAxiosErrorResponse(err)
                  ? err.response?.statusText
                  : undefined,
              },
            });

            appInsights.trackEvent({
              name: 'CREATE_PLAN_FAILED',
              properties: {
                organizationSlug,
                source: event?.source,
                eventId: event?.externalId,
                surface: 'RECORD_UPCOMING_MEETINGS_DIALOG',
                status: isAxiosErrorResponse(err)
                  ? err.response?.status
                  : undefined,
                statusText: isAxiosErrorResponse(err)
                  ? err.response?.statusText
                  : undefined,
              },
            });

            return 'Something went wrong creating the Meetingflow, please try again';
          },
        },
      );
    }
  };

  const scheduleCallRecordingForMeetingflow = useCallback(
    (meetingflow: Meetingflow) => {
      if (!meetingflow) return;

      if (!meetingflow.conferenceInfo) return;

      if (
        ['ciscoWebEx', 'skypeForBusiness', 'skype'].includes(
          meetingflow.conferenceInfo.type,
        )
      ) {
        switch (meetingflow.conferenceInfo.type) {
          case 'ciscoWebEx':
            toast.error(`WebEx is not a supported platform`);
            return;
          case 'skypeForBusiness':
            toast.error(`Skype For Business is not a supported platform`);
            return;
          case 'skype':
            toast.error(`Skype is not a supported platform`);
            return;
        }
        return;
      }

      const timeToStart = DateTime.fromISO(meetingflow.startTime).diffNow()
        .milliseconds;
      const timeToEnd = DateTime.fromISO(meetingflow.endTime).diffNow()
        .milliseconds;
      const beforeStart = timeToStart > 0;
      const beforeEnd = timeToEnd > 0;

      if (!meetingflow.callRecording && !beforeEnd) return;

      const botStatus = !meetingflow.callRecording
        ? ''
        : meetingflow.callRecording?.lastStatus || 'unknown';

      const headers = (token: string) => ({ Authorization: `Bearer ${token}` });
      const validateStatus = (status: number) => [200, 201].includes(status);
      const deleteStatus = (status: number) => [204].includes(status);

      if (
        organizationSlug &&
        beforeEnd &&
        (!meetingflow.callRecording || botStatus === 'deleted')
      ) {
        getAccessTokenSilently().then((token) =>
          toast.promise(
            MeetingflowsApiClient.startOrScheduleCallRecording(
              organizationSlug,
              meetingflow.id,
              undefined,
              {
                headers: headers(token),
                validateStatus,
              },
            ),
            {
              loading: beforeStart
                ? `Scheduling Call Recording`
                : `Starting Call Recording`,
              error: (err) => {
                console.error(err);
                return beforeStart
                  ? `Something went wrong scheduling call recording`
                  : `Something went wrong starting call recording`;
              },
              success: () => {
                appInsights.trackEvent({
                  name: 'CALL_RECORDING_BOT_STATUS_CHANGED',
                  properties: {
                    organizationSlug,
                    meetingPlanId: meetingflow.id,
                    change: beforeStart ? 'scheduled' : 'started',
                    surface: 'RECORD_UPCOMING_MEETINGS_DIALOG',
                  },
                });

                if (
                  workspacePreferences?.orgPreferenceAutomaticCallRecording ===
                    'NONE' &&
                  ['PAID', 'VIP', 'INTERNAL'].includes(organization?.type || '')
                ) {
                  const isExternal = isExternalEventOrMeetingflow(
                    meetingflow,
                    internalDomains,
                  );

                  return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {beforeStart
                        ? `Successfully scheduled call recording`
                        : `Successfully started call recording`}
                      <br />
                      <br />
                      <Link
                        style={{ display: 'contents' }}
                        as={'a'}
                        onClick={() => {
                          if (!updateWorkspacePreferencesAsync) {
                            return;
                          }

                          toast.promise(
                            (async () => {
                              const token = await getAccessTokenSilently();
                              return updateWorkspacePreferencesAsync({
                                orgPreferenceAutomaticCallRecording: isExternal
                                  ? 'EXTERNAL'
                                  : 'ALL',
                              });
                            })(),
                            {
                              loading: 'Enabling automatic call recording',
                              error:
                                'Something went wrong enabling automatic call recording, please try again later',
                              success: () => {
                                appInsights.trackEvent({
                                  name: 'ENABLE_AUTOMATIC_CALL_RECORDING_FROM_TOAST',
                                  properties: {
                                    organizationSlug,
                                    type: isExternal ? 'EXTERNAL' : 'ALL',
                                  },
                                });

                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    Successfully enabled automatic call
                                    recording.
                                    <br />
                                    <br />
                                    You can change this setting at any time in
                                    your{' '}
                                    <Link
                                      style={{ display: 'contents' }}
                                      as={'a'}
                                      onClick={() => {
                                        navigate(
                                          `/organization/${organizationSlug}/settings?tab=user`,
                                        );
                                      }}
                                    >
                                      workspace preferences
                                    </Link>
                                    .
                                  </div>
                                );
                              },
                            },
                          );
                        }}
                      >
                        Click here
                      </Link>{' '}
                      to automatically record all
                      {isExternal ? 'external ' : ''}meetings on your calendar.
                    </div>
                  );
                }

                return beforeStart
                  ? `Successfully scheduled call recording`
                  : `Successfully started call recording`;
              },
            },
            {
              success: {
                duration:
                  workspacePreferences?.orgPreferenceAutomaticCallRecording ===
                    'NONE' &&
                  ['PAID', 'VIP', 'INTERNAL'].includes(organization?.type || '')
                    ? 10000
                    : undefined,
              },
            },
          ),
        );
      } else if (
        meetingflow.callRecording &&
        [
          'scheduled',
          'ready',
          'joining_call',
          'in_waiting_room',
          'in_call_not_recording',
          'in_call_recording',
        ].includes(botStatus) &&
        organizationSlug
      ) {
        getAccessTokenSilently().then((token) => {
          toast.promise(
            MeetingflowsApiClient.stopOrCancelCallRecording(
              organizationSlug,
              meetingflow.id,
              {
                headers: headers(token),
                validateStatus: deleteStatus,
              },
            ),
            {
              loading: beforeStart
                ? `Cancelling scheduled call recording`
                : `Stopping call recording`,
              error: (err) => {
                console.error(err);
                return beforeStart
                  ? `Something went wrong cancelling scheduled call recording`
                  : `Something went wrong stopping call recording`;
              },
              success: () => {
                appInsights.trackEvent({
                  name: 'CALL_RECORDING_BOT_STATUS_CHANGED',
                  properties: {
                    organizationSlug,
                    meetingPlanId: meetingflow.id,
                    change: beforeStart ? 'cancelled' : 'stopped',
                    surface: 'RECORD_UPCOMING_MEETINGS_DIALOG',
                  },
                });
                return beforeStart
                  ? `Successfully cancelled scheduled call recording`
                  : `Successfully stopped call recording`;
              },
            },
          );
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      appInsights,
      getAccessTokenSilently,
      internalDomains,
      navigate,
      organization?.type,
      organizationSlug,
      updateWorkspacePreferencesAsync,
      workspacePreferences?.orgPreferenceAutomaticCallRecording,
    ],
  );

  const createAndRecordAllSelectedEvents = async () => {
    if (selectedEvents) {
      for (const e of selectedEvents) {
        await createMeetingflow(e);
      }
    }
    resolve?.({ events: selectedEvents || [] });
  };

  const newMeetingflowDialogContentClass = mergeStyles({
    width: '80vw',
    maxWidth: event ? '500px' : '980px',
    padding: '1.5rem 1.5rem 0 1.5rem',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.purpleMediumDark
      : MEETINGFLOW_COLORS.purpleMedium,
    containerType: 'inline-size',

    '.select-event, .select-template': {
      flexDirection: 'column',
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.purpleMedium
        : MEETINGFLOW_COLORS.purpleGreyLight,
      flex: '1 1 0',
      height: '100%',
      width: 'calc(100% - 1rem)',
      padding: '.5rem',
      borderRadius: '.25rem',
      marginBottom: '1rem',
      position: 'relative',
      animationName: 'fadeInAnimation',
      animationDuration: '.3s',
      transitionTimingFunction: 'linear',
      animationIterationCount: '1',
      animationFillMode: 'forwards',
      transition: '.3s ease-in-out all',

      '.no-content': {
        textAlign: 'center',
        maxWidth: '80%',
        margin: '.5rem auto',
        padding: '.25rem',
        backgroundColor: 'white',
        borderRadius: '.25rem',
        color: isDark
          ? MEETINGFLOW_COLORS.magentaDark
          : MEETINGFLOW_COLORS.magenta,
      },

      '.filter-controls': {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '.5rem',

        '.search-calendar': {
          flex: '1 1 0',
        },
        '.toggle-external': {
          position: 'relative',
          top: '0',
        },
      },

      '.step-number': {
        display: event ? 'none' : 'block',
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.bold,
        color: MEETINGFLOW_COLORS.white,
        position: 'absolute',
        top: 'calc(-.75rem - 1.5px)',
        left: 'calc(-.75rem - 1.5px)',
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        textAlign: 'center',
        lineHeight: '22px',
        backgroundColor: MEETINGFLOW_COLORS.teal,
        border: `1.5px solid ${MEETINGFLOW_COLORS.white}`,
      },

      h3: {
        fontSize: FontSizes.size20,
        margin: '0 0 .5rem 0',
        fontWeight: FontWeights.semibold,
        color: isDark
          ? MEETINGFLOW_COLORS.white
          : MEETINGFLOW_COLORS.purpleMedium,

        i: {
          fontSize: FontSizes.size14,
          display: 'inline-block',
          position: 'relative',
          top: '-.25rem',
          marginLeft: '.5rem',
          color: MEETINGFLOW_COLORS.purpleMedium,
        },
      },

      h4: {
        fontSize: FontSizes.size12,
        margin: '.5rem 0',
        fontWeight: FontWeights.semibold,
      },

      p: {
        fontSize: FontSizes.size12,
        fontWeight: FontWeights.semibold,
        margin: '0 0 .5rem 0',
        color: MEETINGFLOW_COLORS.purpleMedium,
      },

      '.suggested-events, .suggested-templates': {
        margin: '.5rem 0',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        columnGap: '.25rem',
        rowGap: '.25rem',
        backgroundColor: isDark
          ? MEETINGFLOW_COLORS.purpleMedium
          : MEETINGFLOW_COLORS.purpleGreyLight,
        borderRadius: '.25rem',
        height: '152px',
        overflowY: 'auto',
        alignContent: 'baseline',
        containerType: 'inline-size',
        borderTop: `1px solid ${
          isDark
            ? MEETINGFLOW_COLORS.purpleMediumDark
            : MEETINGFLOW_COLORS.purpleGrey
        }`,
        paddingTop: '.5rem',

        '.event-card, .template-card': {
          '@container (width < 25rem)': {
            width: '100% !important',
            minWidth: '100% !important',
            maxWidth: '100% !important',
          },
        },
      },

      '.options': {
        display: 'flex',
        padding: '1rem 0 0 0',
        flexDirection: 'column',
        borderTop: `1px solid ${
          isDark
            ? MEETINGFLOW_COLORS.purpleMediumDark
            : MEETINGFLOW_COLORS.purpleGrey
        }`,

        '.ms-Checkbox-text': {
          fontWeight: FontWeights.semibold,
          fontSize: FontSizes.size12,
          color: MEETINGFLOW_COLORS.black,
        },

        p: {
          fontSize: FontSizes.size12,
          fontWeight: FontWeights.regular,
          margin: '0 0 .5rem 1.75rem',
          color: isDark
            ? MEETINGFLOW_COLORS.purpleDarkest
            : NeutralColors.gray100,
        },
      },

      '.options-calendar': {
        position: 'relative',

        '.or-highlight': {
          display: 'inline-block',
          width: '32px',
          height: '24px',
          lineHeight: '24px',
          textAlign: 'center',
          fontSize: FontSizes.size12,
          fontWeight: FontWeights.bold,
          textTransform: 'uppercase',
          color: MEETINGFLOW_COLORS.white,
          backgroundColor: MEETINGFLOW_COLORS.teal,
          borderRadius: '.25rem',
          position: 'absolute',
          top: '-12px',
          left: 'calc(50% - 12px)',
          border: `1.25px solid ${MEETINGFLOW_COLORS.white}`,
        },
      },
    },
    '.select-event': {
      display: 'flex',
      '.event-selector': {
        '.suggested-events': {
          '.event-card': {
            flex: '1 1 0',
            width: '100%',
            maxWidth: 'calc(50% - 2px)',
            minWidth: 'calc(50% - 2px)',
            boxSizing: 'border-box',
            borderRadius: '.25rem',

            '> div': {
              padding: '.5rem',
              backgroundColor: isDark
                ? `rgba(0,0,0,.25)`
                : MEETINGFLOW_COLORS.white,
              transition: '.3s ease-in-out all',

              ':hover': {
                backgroundColor: isDark
                  ? MEETINGFLOW_COLORS.purpleMediumDark
                  : MEETINGFLOW_COLORS.purpleLighter,
              },

              '.event-title': {
                display: 'block',
                fontSize: FontSizes.medium,
                fontWeight: FontWeights.semibold,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                lineHeight: '20px',
              },

              '.event-datetime': {
                display: 'block',
                fontSize: FontSizes.size12,
                fontWeight: FontWeights.regular,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                height: '16px',
                color: isDark
                  ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
                  : NeutralColors.gray100,

                span: {
                  color: isDark ? NeutralColors.gray50 : NeutralColors.gray100,
                },
              },
            },
          },

          '.selected': {
            position: 'relative',
            '.will-record': {
              display: 'flex',
              position: 'absolute',
              bottom: '0',
              right: '0',
              backgroundColor: `${MEETINGFLOW_COLORS.magenta} !important`,
              color: MEETINGFLOW_COLORS.white,
              zIndex: 1,
              borderRadius: '.25rem 0 .25rem 0',
              gap: '.25rem',
            },
            '> div': {
              backgroundColor: `${
                isDark ? `rgba(0,0,0,.5)` : MEETINGFLOW_COLORS.purpleMedium
              } !important`,

              span: {
                color: `${MEETINGFLOW_COLORS.white} !important`,
              },
            },
          },
        },
      },
    },

    '.intro': {
      position: 'relative',
      top: '-.5rem',

      h2: {
        display: 'none',
        fontSize: FontSizes.size24,
        fontWeight: FontWeights.semibold,
        margin: '0 0 .5rem 0',
        color: MEETINGFLOW_COLORS.white,
        textAlign: 'left',
      },
      p: {
        fontWeight: FontWeights.regular,
        fontSize: FontSizes.size16,
        margin: '0 0 1rem 0',
        maxWidth: event ? undefined : '75%',
        textAlign: 'left',
        color: MEETINGFLOW_COLORS.purpleGrey,

        strong: {
          color: MEETINGFLOW_COLORS.white,
          fontWeight: FontWeights.semibold,
        },

        span: {
          color: MEETINGFLOW_COLORS.white,
        },
      },
    },

    '.main': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      columnGap: '1.5rem',
      containerType: 'inline-size',

      '@container (width < 45rem)': {
        flexDirection: 'column',
        columnGap: '0',
      },
    },
    '.calls-to-action': {
      height: '2.5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0',
      width: '100%',
      flexBasis: '100%',
      marginBottom: '1rem',

      '.ms-Button--primary': {
        color: `${MEETINGFLOW_COLORS.white} !important`,
        backgroundColor: `${MEETINGFLOW_COLORS.tealMedium} !important`,
        border: `2px solid ${MEETINGFLOW_COLORS.tealMedium} !important`,

        ':hover': {
          backgroundColor: `${MEETINGFLOW_COLORS.tealDark} !important`,
          border: `2px solid ${MEETINGFLOW_COLORS.tealDark} !important`,
        },
      },

      '.ms-Button--primary[disabled]': {
        backgroundColor: `${MEETINGFLOW_COLORS.purpleMediumDark} !important`,
        border: `2px solid ${MEETINGFLOW_COLORS.purpleMediumDark} !important`,
        cursor: 'not-allowed',
      },

      '.ms-Button--default': {
        color: `${MEETINGFLOW_COLORS.purpleGrey} !important`,
        backgroundColor: `${MEETINGFLOW_COLORS.purpleGreyMediumAlt2} !important`,
      },

      button: {
        borderRadius: '.25rem',
        whiteSpace: 'nowrap',
        border: 'none',
        transition: '.3s ease-in-out all',
        padding: '0 .5rem',
        height: '2.5rem',
      },

      '.primary': {
        flexBasis: '50%',
        display: 'flex',
        justifyContent: 'flex-end',
        columnGap: '1rem',
      },

      '.secondary': {
        flexBasis: '50%',
        display: 'flex',
        justifyContent: 'flex-start',
        columnGap: '1rem',

        '.ms-Link': {
          color: MEETINGFLOW_COLORS.purpleGrey,
          transition: '.3s ease-in-out all',
          padding: '0 .5rem',
          height: '2.5rem',

          ':hover': {
            textDecoration: 'none',
            backgroundColor: MEETINGFLOW_COLORS.purpleLighter,
            color: MEETINGFLOW_COLORS.purpleMedium,
          },
        },
      },
    },
    '.tertiary': {
      flexBasis: '100%',
      display: 'flex',
      justifyContent: 'center',
      columnGap: '1rem',
      position: 'absolute',
      top: '-2rem',
      right: '3rem',
      paddingRight: '.75rem',
      borderRight: `1px solid rgba(255,255,255,.15)`,

      '.ms-Checkbox': {
        backgroundColor: isDark
          ? MEETINGFLOW_COLORS.purpleMedium
          : MEETINGFLOW_COLORS.purpleMediumDark,
        maxWidth: '350px',
        padding: '.25rem',
        paddingLeft: '.5rem',
        borderTopLeftRadius: '.25rem',
        borderTopRightRadius: '.25rem',
      },
    },
  });

  const toolTipHostStyles = useMemo(
    () =>
      ({
        root: {
          display: 'inline-block',
          height: '24px',
          width: '24px',
          marginLeft: '.25rem',

          p: {
            margin: 0,
          },
        },
      }) as ITooltipHostStyles,
    [],
  );

  const tooltipCalloutProps = useMemo(
    () => ({
      styles: {
        root: {
          padding: 0,
          background: MEETINGFLOW_COLORS.purpleDarker,
          color: isDark ? undefined : MEETINGFLOW_COLORS.white,
        },
        beak: {
          background: MEETINGFLOW_COLORS.purpleDarker,
        },
        calloutMain: {
          padding: '1rem',
          backgroundColor: isDark ? undefined : MEETINGFLOW_COLORS.purpleDarker,
          p: {
            margin: 0,
            borderBottomRightRadius: '.5rem',
            borderBottomLeftRadius: '.5rem',
            color: MEETINGFLOW_COLORS.white,
          },
        },
      },
    }),
    [isDark],
  );

  const checkboxStyles = {
    root: {
      '&.is-disabled': {
        opacity: '.35',
        cursor: 'not-allowed !important',
      },

      ':hover': {
        '.ms-Checkbox-text': {
          color: `${
            isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.purpleDark
          } !important`,
        },
      },

      '.ms-Checkbox-text': {
        transition: '.3s ease-in-out all',
        fontSize: FontSizes.size12,
        color: `${
          isDark ? MEETINGFLOW_COLORS.purpleGrey : MEETINGFLOW_COLORS.purpleDark
        } !important`,
      },
      '.ms-Checkbox-checkbox': {
        transition: '.3s ease-in-out all',
        backgroundColor: `${
          isDark ? `rgba(0,0,0,.25)` : MEETINGFLOW_COLORS.purpleMedium
        } !important`,
        borderColor: `${MEETINGFLOW_COLORS.purpleMedium} !important`,
      },
      '.ms-Checkbox-checkmark': {
        transition: '.3s ease-in-out all',
        color: `${MEETINGFLOW_COLORS.white} !important`,
      },
    },
  };

  const dialog = (
    <BaseModal
      title={`Record your first meeting!`}
      isOpen={deferred?.isPending}
      contentPadding="0"
      headerPadding=".25rem 1rem"
      headerBackgroundColor={
        isDark
          ? MEETINGFLOW_COLORS.purpleMedium
          : MEETINGFLOW_COLORS.purpleMediumDark
      }
      layerProps={{
        onLayerDidMount: () => {
          appInsights.trackEvent({
            name: 'VIEW_RECORD_UPCOMING_MEETINGS_DIALOG',
            properties: {
              poppedBecauseFirstVist: firstVisit,
            },
          });
        },
      }}
      onDismiss={() => {
        setSelectedEvents(undefined);
        resolve?.(undefined);
      }}
    >
      <div className={newMeetingflowDialogContentClass}>
        <div className="intro">
          <h2>Record Upcoming Meetings</h2>

          <p>
            {firstVisit ? "Let's set up some meetings to be recorded." : ''}{' '}
            When recorded meetings are finished, you'll receive{' '}
            <strong>AI-generated summaries</strong> of what was discussed,{' '}
            <strong>suggested action items</strong> to follow up on, as well as
            the <strong>video and transcript</strong> of the meeting. In
            addition, our powerful AI Chat assistant will learn from your
            meetings and help you be more productive.
          </p>
        </div>
        <div className="main">
          <div className="select-event">
            <h3>
              Select Event
              <TooltipHost
                styles={toolTipHostStyles}
                calloutProps={tooltipCalloutProps}
                onMouseOver={() => {
                  appInsights.trackEvent({
                    name: 'VIEW_TOOLTIP',
                    properties: {
                      surface: 'RECORD_UPCOMING_MEETINGS_DIALOG',
                    },
                  });
                }}
                content={
                  <>
                    <p>
                      Select the event on your calendar you'd like to record.
                      Don't worry — your calendar won't be modified, and other
                      attendees won't be notified.
                    </p>
                  </>
                }
              >
                <FontIcon iconName="Info" />
              </TooltipHost>
            </h3>
            <RecordUpcomingMeetingsDialogEventSelector
              selectedEvents={selectedEvents}
              setSelectedEvents={setSelectedEvents}
            />
          </div>
        </div>
        <div className="calls-to-action">
          <div className="secondary"></div>
          <div className="primary">
            <PrimaryButton
              text={
                selectedEvents?.length
                  ? `Record ${selectedEvents?.length} Meeting${selectedEvents?.length === 1 ? '' : 's'}`
                  : 'Select Events to Record'
              }
              disabled={!selectedEvents?.length}
              onClick={createAndRecordAllSelectedEvents}
            />
          </div>
        </div>
        <div className="tertiary"></div>
      </div>
    </BaseModal>
  );

  return {
    createDeferred,
    resolve,
    reject,
    deferred,
    dialog,
  };
};

interface RecordUpcomingMeetingsDialogEventSelectorProps {
  selectedEvents: CalendarEvent[] | undefined;
  setSelectedEvents: (events: CalendarEvent[] | undefined) => void;
}

export const RecordUpcomingMeetingsDialogEventSelector = ({
  selectedEvents,
  setSelectedEvents,
}: RecordUpcomingMeetingsDialogEventSelectorProps) => {
  const { getAccessTokenSilently, user, getAccessTokenWithPopup } = useAuth0();

  const { organization, slug: organizationSlug } = useOrganization();
  const { user: userProfile } = useUserProfile();
  const appInsights = useAppInsightsContext();

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const searchBoxRef = useRef<ISearchBox>(null);

  const [externalOnly, setExternalOnly] = useState<boolean>(true);

  const { isDark } = useLightOrDarkMode();
  const client = useQueryClient();

  const now = DateTime.now();

  // Use the user's preference for externalOnly to set the default
  useEffect(() => {
    if (userProfile?.preferenceAgendaMeetingFilter === 'EXTERNAL_ONLY') {
      setExternalOnly(false);
    } else {
      setExternalOnly(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: eventsData,
    isLoading: eventsLoading,
    isRefetching: eventsRefetching,
    refetch: refetchEvents,
    isError: eventsErrored,
    error: eventsError,
  } = useQuery(
    OrganizationUpcomingMeetings(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return EventsApiClient.listEvents(
        {
          organizationSlug: organizationSlug!,
          q: searchTerm || undefined,
          externalOnly: externalOnly,
          recordableOnly: true,
          minDate: now.startOf('day').toISO()!,
          maxDate: searchTerm
            ? undefined
            : now.plus({ days: 30 }).endOf('day').toISO()!,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      // 15 minutes
      refetchInterval: 900000,
      staleTime: 900000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      retry: 1,
      enabled: !!organizationSlug,
      queryHash: `${OrganizationUpcomingMeetings(
        organizationSlug!,
      )}_NewMeetingflowDialog_${searchTerm}_${now
        .startOf('week')
        .startOf('day')
        .toISO()}`,
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(debounce(refetchEvents, 500), [
    refetchEvents,
  ]);

  useEffect(() => {
    debouncedRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchTerm,
    organizationSlug,
    userProfile?.preferenceAgendaMeetingFilter,
  ]);

  // refetch calendar events when the externalOnly filter changes
  useEffect(() => {
    refetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalOnly]);

  const events = useMemo(
    () =>
      eventsData?.data.filter(
        (e) => !e.meetingplanId && e.conferenceInfo?.joinUri,
      ) || EMPTY_ARRAY,
    [eventsData?.data],
  );

  const fadeInClass = mergeStyles({
    position: 'relative',
    animationName: 'fadeInSlideAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    width: 'auto',
    transition: '.3s ease-in-out all',
  });

  const errorComponent = useMemo(() => {
    if (!eventsErrored) {
      return null;
    }
    if (isAxiosErrorResponse(eventsError)) {
      return (
        <div
          className={fadeInClass}
          style={{
            backgroundColor: MEETINGFLOW_COLORS.teal,
            margin: 0,
            width: '100%',
            height: '100%',
            border: `5px solid ${MEETINGFLOW_COLORS.white}`,
            boxSizing: 'border-box',
          }}
        >
          <div
            className={fadeInClass}
            style={{
              padding: '1rem 1.5rem',
              borderRadius: '.25rem',
              textAlign: 'center',
              fontSize: FontSizes.mediumPlus,
              color: 'white',
              margin: '.5rem',
            }}
          >
            <AsyncLink
              styles={{
                root: {
                  display: 'inline-block',
                  textDecoration: 'none !important',
                  backgroundColor: MEETINGFLOW_COLORS.white,
                  color: MEETINGFLOW_COLORS.purpleSecondary,
                  fontSize: FontSizes.mediumPlus,
                  borderRadius: '1.5rem',
                  padding: '.25rem .5rem',
                  margin: '.5rem auto',
                  fontWeight: FontWeights.semibold,
                  transition: 'all .3s ease-in-out',
                  textAlign: 'center',

                  ':hover': {
                    backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
                    color: MEETINGFLOW_COLORS.white,
                  },
                },
              }}
              onClick={async () => {
                appInsights.trackEvent({
                  name: 'CONNECT_CALENDAR_CLICK',
                  properties: {
                    organizationSlug,
                    emailAddress: user?.email,
                    provider: isGoogleUser(user!.sub!) ? 'GOOGLE' : 'M365',
                    surface: 'RECORD_UPCOMING_MEETINGS_DIALOG',
                  },
                });
                const token = await getAccessTokenWithPopup({
                  cacheMode: 'off',
                  authorizationParams: {
                    display: 'popup',
                    login_hint: user?.email,
                    connection: isGoogleUser(user!.sub!)
                      ? 'google-oauth2'
                      : 'Microsoft',
                  },
                });

                await ApiClient.delete(
                  `/token/${isGoogleUser(user!.sub!) ? 'GOOGLE' : 'M365'}`,
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );

                client.invalidateQueries(
                  OrganizationMeetingsHappeningSoon(organizationSlug!),
                );

                refetchEvents();
              }}
            >
              Connect your {isGoogleUser(user!.sub!) ? 'Google' : 'Microsoft'}{' '}
              Calendar
            </AsyncLink>
            <Text
              block
              style={{
                color: `rgba(255,255,255,.75)`,
                fontSize: FontSizes.small,
                margin: '0 1rem 0 1rem',
              }}
            >
              Alternatively, create a Meetingflow without an event.
            </Text>
          </div>
        </div>
      );
    }
    return (
      <div>
        Something went wrong fetching your agenda, refresh the page to try again
      </div>
    );
  }, [
    eventsErrored,
    eventsError,
    refetchEvents,
    organizationSlug,
    user,
    appInsights,
    client,
    getAccessTokenWithPopup,
    fadeInClass,
  ]);

  const searchBoxStyles = {
    root: {
      backgroundColor: isDark ? MEETINGFLOW_COLORS.purpleMediumDark : undefined,
      borderColor: `${MEETINGFLOW_COLORS.purpleMedium} !important`,
      ':after': {
        borderColor: isDark
          ? MEETINGFLOW_COLORS.purpleMediumDark
          : MEETINGFLOW_COLORS.purpleMedium,
        outlineColor: isDark
          ? MEETINGFLOW_COLORS.purpleMediumDark
          : MEETINGFLOW_COLORS.purpleMedium,
      },
    },
    field: {
      color: isDark ? MEETINGFLOW_COLORS.white : undefined,

      '::placeholder': {
        color: isDark
          ? `rgba(255,255,255,.5)`
          : MEETINGFLOW_COLORS.purpleMedium,
      },
    },
    icon: {
      color: isDark ? MEETINGFLOW_COLORS.white : undefined,
    },
  } as Partial<ISearchBoxStyles>;

  const toggleStyles = {
    root: {
      display: 'inline-block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      position: 'relative',
      top: '-.4rem',
      marginBottom: '0',
      minWidth: '6rem',
      '.ms-Toggle-innerContainer': {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        marginTop: '.25rem',
        button: {
          transition: 'all .3s ease-in-out',
          backgroundColor: isDark
            ? `rgba(0,0,0,.25)`
            : MEETINGFLOW_COLORS.purpleGrey,
        },
      },
      'label, button': {
        marginRight: '.25rem',
        textAlign: 'left',
        fontSize: FontSizes.small,
        color: isDark ? MEETINGFLOW_COLORS.white : NeutralColors.gray120,
        border: 'none !important',
      },
      label: {
        height: '.75rem',
        lineHeight: '.75rem',
        fontSize: FontSizes.mini,
        marginBottom: '.5rem',
        color: isDark ? MEETINGFLOW_COLORS.white : NeutralColors.gray120,
      },
      button: {
        position: 'relative',
        top: '.1rem',
      },

      'button[aria-checked="false"]': {
        '.ms-Toggle-thumb': {
          backgroundColor: MEETINGFLOW_COLORS.purpleLight,
        },
      },

      '.ms-Toggle-thumb': {
        backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
      },
      'button:disabled .ms-Toggle-thumb': {
        backgroundColor: isDark ? NeutralColors.gray170 : NeutralColors.gray70,
      },
      'button:hover': {
        backgroundColor: isDark
          ? NeutralColors.gray190
          : MEETINGFLOW_COLORS.purpleLight,

        '.ms-Toggle-thumb': {
          backgroundColor: MEETINGFLOW_COLORS.purpleSecondary,
        },
      },
    },
  } as Partial<IToggleStyles>;

  const toolTipHostStyles = useMemo(
    () =>
      ({
        root: {
          display: 'inline-block',
          height: '16px',
          width: '16px',
          marginLeft: '.25rem',

          p: {
            margin: 0,
          },
        },
      }) as ITooltipHostStyles,
    [],
  );

  const tooltipCalloutProps = useMemo(
    () => ({
      styles: {
        root: {
          padding: 0,
          background: MEETINGFLOW_COLORS.purpleDarker,
          color: isDark ? undefined : MEETINGFLOW_COLORS.white,
        },
        beak: {
          background: MEETINGFLOW_COLORS.purpleDarker,
        },
        calloutMain: {
          padding: '1rem',
          backgroundColor: isDark ? undefined : MEETINGFLOW_COLORS.purpleDarker,
          p: {
            margin: 0,
            borderBottomRightRadius: '.5rem',
            borderBottomLeftRadius: '.5rem',
            color: MEETINGFLOW_COLORS.white,
          },
        },
      },
    }),
    [isDark],
  );

  return (
    <div className="event-selector">
      <div className="filter-controls">
        <SearchBox
          className="search-calendar"
          componentRef={searchBoxRef}
          autoFocus
          placeholder="Search your calendar by title or attendee..."
          onClear={() => {
            setSearchTerm(undefined);
          }}
          onChange={(e, nv) => {
            setSearchTerm(nv || '');
          }}
          onSearch={(nv) => {
            appInsights?.trackEvent({
              name: 'REC_UPCOMING_MEETINGS_EVENTS_SEARCH',
              properties: {
                organizationId: organization?.id,
                organizationSlug: organizationSlug,
                userId: user?.sub,
                searchTerm: nv,
              },
            });
          }}
          styles={searchBoxStyles}
        />

        <Toggle
          label={
            <>
              Only External{' '}
              <TooltipHost
                styles={toolTipHostStyles}
                calloutProps={tooltipCalloutProps}
                onMouseOver={() => {
                  appInsights.trackEvent({
                    name: 'VIEW_TOOLTIP',
                    properties: {
                      surface: 'RECORD_UPCOMING_MEETINGS_DIALOG',
                      name: 'SELECT_EVENT_EXTERNAL_ONLY_INFO',
                    },
                  });
                }}
                content={
                  <>
                    <p>
                      Meetingflow classifies events as external when they have
                      attendees with email addresses from domains outside of
                      your organization. Enabling this filter will exclude
                      internal events.
                    </p>
                  </>
                }
              >
                <FontIcon iconName="Info" />
              </TooltipHost>
            </>
          }
          styles={toggleStyles}
          checked={externalOnly}
          className="toggle-external"
          onChange={(_e, checked) => {
            appInsights.trackEvent({
              name: 'RECORD_UPCOMING_MEETINGS_DIALOG_EVENTS_CHANGE_CONTROLS',
              properties: {
                property: 'externalOnly',
                organizationSlug,
                emailAddress: user?.email,
                externalOnly: !!checked,
              },
            });

            setExternalOnly(!!checked);
          }}
          role="checkbox"
        />
      </div>

      <div className="suggested-events">
        {eventsLoading || eventsRefetching ? (
          <Spinner styles={{ root: { margin: '1rem auto' } }} />
        ) : (
          <>
            {eventsErrored && !events?.length && organizationSlug
              ? errorComponent
              : null}
            {!eventsErrored && events?.length && organizationSlug ? (
              events?.map((event: CalendarEvent) => (
                <div
                  className={`event-card ${
                    selectedEvents?.find(
                      (e) => e.externalId === event.externalId,
                    )?.externalId
                      ? 'selected'
                      : ''
                  }`}
                  key={event.externalId}
                >
                  {selectedEvents?.find(
                    (e) => e.externalId === event.externalId,
                  )?.externalId ? (
                    <div className="will-record">
                      <FontIcon
                        iconName="SkypeCircleCheck"
                        style={{
                          color: MEETINGFLOW_COLORS.white,
                          fontSize: FontSizes.large,
                        }}
                      />{' '}
                      Record
                    </div>
                  ) : null}
                  <EventCard
                    event={event}
                    organizationSlug={organizationSlug}
                    createMeetingflowOnClick={false}
                    hideNewMeetingflowButton
                    hideConferenceIcon
                    allowScheduleCallRecording={false}
                    disableCreateViewMeetingflowOnClick
                    showCompanies
                    onClick={() => {
                      event.externalId ===
                      selectedEvents?.find(
                        (e) => e.externalId === event.externalId,
                      )?.externalId
                        ? setSelectedEvents(
                            selectedEvents?.filter(
                              (e) => e.externalId !== event.externalId,
                            ),
                          )
                        : setSelectedEvents(
                            selectedEvents
                              ? [...selectedEvents, event]
                              : [event],
                          );
                    }}
                  />
                </div>
              ))
            ) : (
              <p className="no-content">
                {!eventsErrored
                  ? searchTerm
                    ? `No recordable ${
                        externalOnly ? 'external ' : ''
                      }events that match "${searchTerm}" were found. Try searching for a specific event${
                        externalOnly
                          ? ', or remove the "External Only" filter'
                          : ''
                      }.`
                    : `No recordable ${
                        externalOnly ? 'external ' : ''
                      }events were found on your calendar in the next month! Try searching for a specific event${
                        externalOnly
                          ? ', or remove the "External Only" filter.'
                          : ''
                      }.`
                  : null}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default useRecordUpcomingMeetingsDialog;
