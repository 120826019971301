// Import required dependencies
import { useAuth0 } from '@auth0/auth0-react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import {
  FontWeights,
  getTheme,
  Icon,
  IconButton,
  mergeStyleSets,
  Pivot,
  PivotItem,
  Spinner,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import classNames from 'classnames';
import { formatDistanceToNow } from 'date-fns';
import saveAs from 'file-saver';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useShareDealRoomArtifactDialog } from '../../Hooks/Modals/DealRoom/useShareDealRoomArtifactDialog';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { useDealRoomArtifactId } from '../../Hooks/useDealRoomArtifactId';
import { useOrganization } from '../../Hooks/useOrganization';
import { useUserProfile } from '../../Hooks/useProfile';
import { OrganizationDealRoomArtifactQuery } from '../../QueryNames';
import { DealRoomsApiClient } from '../../Services/NetworkCommon';
import '../../Styles/DealRoom/core.scss';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { ImageWithFallback } from '../Common/ImageWithFallback';
import { SplitViewLayout } from '../Layouts/SplitViewLayout';
import { ArtifactIconPreview } from './Artifacts/ArtifactIconPreview';
import { ArtifactsSidebar } from './Artifacts/ArtifactsSidebar/ArtifactsSidebar';
import { DealRoomArtifactCommentsPanel } from './Artifacts/DealRoomArtifactCommentsPanel';
import { OfficeArtifactPreview } from './Artifacts/OfficeArtifactPreview';
import { UpdateArtifact } from './Artifacts/UpdateArtifact';
import { DealRoomArtifactAssistantPanel } from './Assistant/DealRoomArtifactAssistantPanel';
import { DealRoomAsyncCommandBarButton } from './Components/DealRoomButton';
import { DealRoomUpDownVote } from './Components/DealRoomUpDownVote';

/**
 * DealRoomArtifactDetailView Component
 *
 * This component displays detailed view of a deal room artifact including:
 * - Artifact preview (documents, images, videos etc)
 * - Metadata (creation date, last update etc)
 * - Actions (download, share, voting)
 * - Comments and AI assistant panels
 */
export const DealRoomArtifactDetailView = () => {
  // Auth and navigation hooks
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { userId } = useUserProfile();
  const { slug: organizationSlug } = useOrganization();

  // State management
  const [sidePanelTab, setSidePanelTab] = useState<'ask-ai' | 'comment'>(
    'ask-ai',
  );
  const [currentThreadId, setCurrentThreadId] = useState<number | undefined>(
    undefined,
  );
  const dealRoomPanelRef = useRef<{ backToConvList: Function }>(null);

  // Theme and styling
  const theme = getTheme();

  // Get deal room and artifact IDs from URL
  const artifactId = useDealRoomArtifactId();

  const { dealRoomId, dealRoomRole } = useDealRoom();

  // Redirect if IDs are missing
  useEffect(() => {
    if (!dealRoomId && !artifactId) {
      navigate(`/organization/${organizationSlug}/library/dealrooms`);
    } else if (!artifactId) {
      navigate(`/organization/${organizationSlug}/dealroom/${dealRoomId}`);
    } else if (!dealRoomId) {
      navigate(`/organization/${organizationSlug}/library/dealrooms`);
    }
  }, [artifactId, dealRoomId, navigate, organizationSlug]);

  // Fetch artifact data
  const {
    data: artifactResponse,
    isLoading: artifactLoading,
    refetch: refetchArtifact,
  } = useQuery(
    OrganizationDealRoomArtifactQuery(
      organizationSlug!,
      dealRoomId!,
      artifactId!,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.getArtifact(
        organizationSlug!,
        dealRoomId!,
        artifactId!,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug && !!dealRoomId && !!artifactId,
    },
  );

  const artifact = useMemo(() => artifactResponse?.data, [artifactResponse]);

  // Share dialog setup
  const {
    createDeferred: createConfirmShareDeferred,
    dialog: confirmShareDialog,
  } = useShareDealRoomArtifactDialog({
    organizationSlug: organizationSlug!,
    artifactName: artifact?.name,
  });

  // Record artifact view
  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        DealRoomsApiClient.viewArtifact(
          organizationSlug!,
          dealRoomId!,
          artifactId!,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      })
      .then(() => refetchArtifact());
  }, [
    artifactId,
    dealRoomId,
    getAccessTokenSilently,
    organizationSlug,
    refetchArtifact,
  ]);

  // Styles definition
  const styles = mergeStyleSets({
    wrapper: {
      display: 'flex',
      height: '100%',
    },
    viewContainer: {
      padding: '2rem',
      width: 'calc(100% - 4rem)',
      height: 'calc(100vh - 9.75rem)',
      backgroundColor: DEALROOMS_COLORS.neutralLighter,
    },
    splitViewRootLayout: {
      columnGap: '1rem',
    },
    pivotContainer: {
      marginTop: '1rem',
      padding: '0 1rem',
      borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      '[role=tablist]': {
        whiteSpace: 'initial',
      },
      '.ms-Pivot-text': {
        fontSize: '15px',
        fontWeight: '400',
        color: DEALROOMS_COLORS.neutralDarker,
      },
      '.ms-Pivot-link.is-selected::before': {
        backgroundColor: DEALROOMS_COLORS.neutralDark,
        borderRadius: '999px',
      },
      '.ms-Pivot-link.is-selected .ms-Pivot-text': {
        fontWeight: '500',
        color: DEALROOMS_COLORS.darkerGray,
      },
    },
    sidebarRootLayout: {
      borderRadius: '4px',
      backgroundColor: DEALROOMS_COLORS.white,
      width: 'calc(100% - 2px) !important',
      height: 'calc(100% - 2px) !important',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      boxShadow: '0 2px 2px 0 #00000005',
      '.backToConvsButton': {
        border: 'none',
        padding: '0.8rem 0.75rem;',
        i: {
          margin: '0 1rem 0 0',
        },
        ':hover': {
          color: 'inherit',
          i: {
            color: 'inherit',
          },
        },
      },
      '.backToConvsContainer': {},
    },
    header: [
      theme.fonts.xLarge,
      {
        display: 'flex',
        alignItems: 'center',
        gap: '1.5rem',
        paddingBottom: '1.5rem',
        '.back': {
          color: DEALROOMS_COLORS.themeSecondary,
          width: '1.5rem',
          height: '1.5rem',
          i: {
            fontSize: '1rem',
          },
        },

        '.title': {
          fontSize: '40px',
          lineHeight: '48px',
          fontWeight: '400',
          color: DEALROOMS_COLORS.themePrimary,
        },

        '.description': {},

        '.close': {},
      },
    ],
    content: [
      theme.fonts.medium,
      {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        backgroundColor: DEALROOMS_COLORS.white,
        borderRadius: '4px',
        border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
        boxShadow: '0 2px 2px 0 #00000005',
        flex: 'auto 1',

        '.content-title': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: '0.5rem',
          padding: '1.25rem 1.5rem',
          '.icon': {
            marginRight: '6px',
            fontSize: '18px',
            color: DEALROOMS_COLORS.neutralDark,
          },
          borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,

          '.metadata': {
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
            alignItems: 'center',
            justifyContent: 'flex-start',
            '.info-icon': {
              color: DEALROOMS_COLORS.themeSecondary,
              fontSize: '16px',
            },
            '.edited-at': {
              color: DEALROOMS_COLORS.themeSecondary,
              fontSize: '13px',
              paddingBottom: '0.25rem',
            },
          },

          '.actions': {
            justifySelf: 'end',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
            gap: '0.25rem',
            '.action-button': {
              '&.featured': {
                color: DEALROOMS_COLORS.darkerRed,
              },
            },
          },
        },
        '.preview-container': {
          padding: '1.5rem',
          width: 'calc(100% - 3rem)',
          height: 'calc(100% - 3rem)',
          background: DEALROOMS_COLORS.neutralGray,
          overflow: 'auto',

          '.audio-artifact-preview': {
            width: '100%',
            maxWidth: 'calc(100% - 2rem - 2px)',
            maxHeight: 'calc(100% - 2rem - 2px)',
            padding: '1rem',
            border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
            borderRadius: '4px',
          },

          '.image-artifact-preview': {
            height: 'auto',
            maxWidth: 'calc(100% - 2rem - 2px)',
            maxHeight: 'calc(100% - 2rem - 2px)',
            padding: '1rem',
            border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
            borderRadius: '4px',
          },

          '.video-artifact-preview': {
            width: '100%',
            maxWidth: 'calc(100% - 2rem - 2px)',
            maxHeight: 'calc(100% - 2rem - 2px)',
            padding: '1rem',
            border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
            borderRadius: '4px',
          },

          '.document-artifact-preview': {
            width: '100%',
            height: '100%',
            backgroundColor: DEALROOMS_COLORS.white,
            color: DEALROOMS_COLORS.black,
            maxWidth: 'calc(100% - 2rem - 2px)',
            maxHeight: 'calc(100% - 2rem - 2px)',
            padding: '1rem',
            border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
            borderRadius: '4px',
          },

          '.link-artifact-preview': {
            width: '100%',
            height: '100%',
            maxWidth: 'calc(100% - 2rem - 2px)',
            maxHeight: 'calc(100% - 2rem - 2px)',
            padding: '1rem',
            border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
            borderRadius: '4px',
          },
        },
      },
    ],
    chat: {
      flex: '0 0 300px',
      borderLeft: '1px solid #ccc',
      padding: '20px',
    },
    '.title': {
      fontSize: '40px',
    },
  });

  // Render side panel content based on selected tab
  const sidePanelContent = useMemo(() => {
    if (!organizationSlug || !dealRoomId || !artifactId) {
      return null;
    }

    switch (sidePanelTab) {
      case 'ask-ai':
        return (
          <DealRoomArtifactAssistantPanel
            organizationSlug={organizationSlug!}
            dealRoomId={dealRoomId}
            artifactId={artifactId}
          />
        );
      case 'comment':
        return (
          <DealRoomArtifactCommentsPanel
            ref={dealRoomPanelRef}
            organizationSlug={organizationSlug!}
            dealRoomId={dealRoomId}
            artifactId={artifactId}
            currentThreadId={currentThreadId}
            setCurrentThreadId={(convId: number) => setCurrentThreadId(convId)}
          />
        );
    }
  }, [artifactId, dealRoomId, organizationSlug, sidePanelTab, currentThreadId]);

  // Generate preview based on artifact type
  const { preview } = useMemo(() => {
    if (!organizationSlug || !dealRoomId || !artifactId || !artifact) {
      return {
        preview: null,
      };
    }

    switch (artifact.type) {
      case 'AUDIO': {
        return {
          preview: (
            <audio controls className="audio-artifact-preview">
              <source src={artifact.fileUrl} />
            </audio>
          ),
        };
      }
      case 'IMAGE': {
        return {
          preview: (
            <ImageWithFallback
              className="image-artifact-preview"
              src={artifact.fileUrl}
              alt={artifact.label || artifact.name}
              fallbackSrc={artifact.thumbnailUrl}
              fallback={<ArtifactIconPreview type="IMAGE" />}
              objectFit="contain"
            />
          ),
        };
      }
      case 'VIDEO': {
        return {
          preview: (
            <video
              controls
              className="video-artifact-preview"
              poster={artifact.thumbnailUrl || undefined}
            >
              <source src={artifact.fileUrl} />
            </video>
          ),
        };
      }
      case 'DOCUMENT': {
        switch (artifact.mimeType) {
          case 'application/vnd.oasis.opendocument.text':
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          case 'application/vnd.ms-powerpoint':
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
            return {
              preview: (
                <OfficeArtifactPreview
                  className="document-artifact-preview"
                  organizationSlug={organizationSlug!}
                  dealRoomId={dealRoomId}
                  fileName={artifact.fileName}
                  label={artifact.label}
                  name={artifact.name}
                  fileUrl={artifact.fileUrl}
                  thumbnailUrl={artifact.thumbnailUrl}
                />
              ),
            };
          }
          default: {
            return {
              preview: (
                <DocViewer
                  className="document-artifact-preview"
                  pluginRenderers={DocViewerRenderers}
                  documents={[
                    {
                      uri: artifact.fileUrl,
                    },
                  ]}
                  config={{
                    header: {
                      disableHeader: true,
                    },
                    pdfVerticalScrollByDefault: true,
                  }}
                />
              ),
            };
          }
        }
      }
      case 'LINK': {
        return {
          preview: (
            <iframe
              className="link-artifact-preview"
              src={artifact.url}
              title={artifact.label || artifact.name}
            />
          ),
        };
      }
      case 'MEETINGFLOW':
      case 'FILE':
      default: {
        return {
          preview: null,
        };
      }
    }
  }, [artifact, artifactId, dealRoomId, organizationSlug]);

  // Format title with file extension
  const titleEl = useMemo(() => {
    if (!artifact) {
      return <></>;
    }
    const name = artifact.label || artifact.name;
    const parts = name.split('.');
    if (parts.length > 1) {
      const extension = parts.pop();
      return (
        <>
          {parts.join('.')}
          <span style={{ color: DEALROOMS_COLORS.themeSecondary }}>
            .{extension}
          </span>
        </>
      );
    }
    return <span>{name}</span>;
  }, [artifact]);

  // Format dates
  const { createdAt, updatedAt, editedAt } = useMemo(() => {
    if (!artifact) {
      return {
        createdAt: undefined,
        updatedAt: undefined,
        editedAt: undefined,
      };
    }

    return {
      createdAt: humanizeDateTime(artifact.createdAt, {
        useRelativeDates: false,
      }),
      updatedAt: humanizeDateTime(artifact.updatedAt, {
        useRelativeDates: false,
      }),
      editedAt: formatDistanceToNow(new Date(artifact.updatedAt), {
        addSuffix: true,
      }),
    };
  }, [artifact]);

  const handleOnFeature = useCallback(
    async (feature: boolean) => {
      try {
        if (
          !organizationSlug ||
          !dealRoomId ||
          !artifactId ||
          !getAccessTokenSilently
        ) {
          return;
        }

        const token = await getAccessTokenSilently();
        if (feature) {
          await DealRoomsApiClient.featureArtifact(
            {
              organizationSlug,
              dealRoomId,
              artifactId,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        } else {
          await DealRoomsApiClient.unfeatureArtifact(
            organizationSlug,
            dealRoomId,
            artifactId,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        }
        await refetchArtifact();
      } catch (err: unknown) {}
    },
    [
      artifactId,
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchArtifact,
    ],
  );

  // Handle voting actions
  const handleOnUpVote = useCallback(async () => {
    try {
      if (
        !artifact ||
        typeof organizationSlug !== 'string' ||
        typeof dealRoomId !== 'number' ||
        typeof artifactId !== 'number' ||
        !getAccessTokenSilently ||
        !refetchArtifact
      ) {
        return;
      }

      const token = await getAccessTokenSilently();
      if (artifact.upvoted) {
        await DealRoomsApiClient.removeArtifactUpvote(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      } else {
        await DealRoomsApiClient.upvoteArtifact(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      }
      await refetchArtifact();
    } catch (err: unknown) {}
  }, [
    artifact,
    organizationSlug,
    dealRoomId,
    artifactId,
    getAccessTokenSilently,
    refetchArtifact,
  ]);

  const handleOnDownVote = useCallback(async () => {
    try {
      if (
        !artifact ||
        typeof organizationSlug !== 'string' ||
        typeof dealRoomId !== 'number' ||
        typeof artifactId !== 'number' ||
        !getAccessTokenSilently ||
        !refetchArtifact
      ) {
        return;
      }

      const token = await getAccessTokenSilently();
      if (artifact.downvoted) {
        await DealRoomsApiClient.removeArtifactDownvote(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      } else {
        await DealRoomsApiClient.downvoteArtifact(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      }
      await refetchArtifact();
    } catch (err: unknown) {}
  }, [
    artifact,
    organizationSlug,
    dealRoomId,
    artifactId,
    getAccessTokenSilently,
    refetchArtifact,
  ]);

  // Handle download action
  const handleOnDownloadArtifact = useCallback(async () => {
    try {
      if (artifact && 'fileUrl' in artifact) {
        saveAs(artifact.fileUrl, artifact.name);
      }
    } catch (err: unknown) {}
  }, [artifact]);

  // Handle share action
  const handleOnShare = useCallback(async () => {
    try {
      if (
        !createConfirmShareDeferred ||
        typeof organizationSlug !== 'string' ||
        !organizationSlug ||
        typeof dealRoomId !== 'number' ||
        typeof artifactId !== 'number' ||
        !getAccessTokenSilently
      ) {
        return;
      }

      const confirmShare = await createConfirmShareDeferred().promise;

      if (!confirmShare) {
        return;
      }

      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.shareArtifact(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            subject: confirmShare.subject,
            message: confirmShare.message,
            contacts: confirmShare.contacts,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Sharing Artifact',
          success: (_response) => {
            return 'Successfully shared Artifact';
          },
          error: (err: unknown) => {
            return 'Something went wrong sharing Artifact, please try again later';
          },
        },
      );
    } catch (err: unknown) {}
  }, [
    organizationSlug,
    dealRoomId,
    artifactId,
    createConfirmShareDeferred,
    getAccessTokenSilently,
  ]);

  if (artifactLoading) {
    return <Spinner />;
  }

  if (!organizationSlug || !dealRoomId || !artifactId || !artifact) {
    return null;
  }

  const backToConvs = () => {
    if (dealRoomPanelRef?.current?.backToConvList) {
      dealRoomPanelRef.current.backToConvList();
    }
  };

  return (
    <div className={styles.wrapper}>
      <ArtifactsSidebar
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        artifactId={artifactId}
      />
      <div className={styles.viewContainer}>
        <SplitViewLayout
          rootClassName={styles.splitViewRootLayout}
          layoutName="DealRoomArtifactDetailView"
          fullWidthHeader
          header={
            <div className={styles.header}>
              <IconButton
                className="back"
                iconProps={{ iconName: 'Back' }}
                onClick={() => {
                  navigate(
                    `/organization/${organizationSlug}/dealroom/${dealRoomId}?buyerView=true`,
                  );
                }}
              />
              <Text variant="superLarge" className="title">
                {titleEl}
              </Text>
              <UpdateArtifact
                organizationSlug={organizationSlug}
                dealRoomId={dealRoomId}
                artifact={artifact}
              />
            </div>
          }
          sidebar={
            <SplitViewLayout
              rootClassName={styles.sidebarRootLayout}
              layoutName="DealRoomArtifactDetailViewSidePanel"
              header={
                // currentThreadId === undefined ?
                <Pivot
                  className={styles.pivotContainer}
                  selectedKey={sidePanelTab}
                  onLinkClick={(i) =>
                    setSidePanelTab(
                      (i?.props?.itemKey as 'ask-ai' | 'comment' | undefined) ||
                        'ask-ai',
                    )
                  }
                >
                  <PivotItem itemKey="ask-ai" headerText="Ask AI" />
                  {/* <PivotItem itemKey="comment" headerText="Conversations" /> */}
                  {/* <PivotItem
                  itemKey="leave-message"
                  headerText="Leave a message"
                /> */}
                </Pivot>
                // : (
                //   <div
                //     className="backToConvsContainer"
                //     style={{
                //       padding: '1rem 1rem 0.5rem 1rem',
                //       borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
                //     }}
                //   >
                //     <DealRoomCommandBarButton
                //       className="backToConvsButton"
                //       buttonStyleType="COMMAND_BAR"
                //       iconProps={{ iconName: 'ChromeBack' }}
                //       onClick={backToConvs}
                //     >
                //       Conversation
                //     </DealRoomCommandBarButton>
                //   </div>
                // )
              }
            >
              {sidePanelContent}
            </SplitViewLayout>
          }
          contentClassName={styles.content}
          sidebarWidthPercent={35}
        >
          <>
            <div className="content-title">
              <div className="metadata">
                <TooltipHost
                  content={
                    <>
                      <div>
                        Uploaded by{' '}
                        {/* TODO: Should not say unknown user - need to find a better way to handle null case */}
                        <span className="creator">
                          {artifact.creator?.name ?? 'an unknown user'}
                        </span>{' '}
                        on <span>{createdAt}</span>
                      </div>
                      {artifact.createdAt !== artifact.updatedAt && (
                        <div>
                          {/* TODO: Should not say unknown user - need to find a better way to handle null case */}
                          {/* TODO: When we support most recent updater, display name here */}
                          Last updated by{' '}
                          <span className="creator">
                            {artifact.creator?.name ?? 'an unknown user'}
                          </span>{' '}
                          on <span>{updatedAt}</span>
                        </div>
                      )}
                    </>
                  }
                  directionalHint={4}
                  calloutProps={{
                    backgroundColor: '#6F7288',
                    gapSpace: -2,
                    styles: {
                      root: {
                        padding: 0,
                        margin: 0,
                      },
                      beak: { display: 'none' }, // Hide the triangle/beak
                      calloutMain: {
                        padding: '.5rem',
                        borderRadius: '.25rem',
                        selectors: {
                          '*': {
                            color: 'white',
                          },
                          span: {
                            color: '#D7D7D8',
                          },
                          'span.creator': {
                            fontWeight: FontWeights.bold,
                          },
                        },
                      },
                    },
                  }}
                  styles={{
                    root: { cursor: 'default' },
                  }}
                >
                  <Icon iconName="InfoSolid" className="info-icon" />
                </TooltipHost>
                <Text variant="xSmall" className="edited-at">
                  {artifact.createdAt === artifact.updatedAt
                    ? 'Created'
                    : 'Edited'}{' '}
                  {editedAt}
                </Text>
              </div>

              <div className="actions">
                {dealRoomRole === 'SELLER' ? (
                  <DealRoomAsyncCommandBarButton
                    customClasses={classNames('action-button', {
                      featured: !!artifact?.featuredAt,
                    })}
                    name={!!artifact?.featuredAt ? 'Unfeature' : 'Feature'}
                    iconProps={{
                      iconName: !!artifact.featuredAt
                        ? 'FavoriteStarFill'
                        : 'FavoriteStar',
                    }}
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      await handleOnFeature(!artifact?.featuredAt);
                    }}
                    buttonStyleType="COMMAND_BAR"
                  />
                ) : null}
                {'fileName' in artifact ? (
                  <DealRoomAsyncCommandBarButton
                    customClasses="action-button"
                    name="Download"
                    iconProps={{ iconName: 'Download' }}
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      await handleOnDownloadArtifact();
                    }}
                    buttonStyleType="COMMAND_BAR"
                  />
                ) : undefined}
                <DealRoomAsyncCommandBarButton
                  customClasses="action-button"
                  name="Share"
                  iconProps={{ iconName: 'Share' }}
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await handleOnShare();
                  }}
                  buttonStyleType="COMMAND_BAR"
                />
                <DealRoomUpDownVote
                  upvoted={artifact.upvoted}
                  downvoted={artifact.downvoted}
                  onUpvote={handleOnUpVote}
                  onDownvote={handleOnDownVote}
                />
              </div>
            </div>
            <Suspense fallback={<Spinner />}>
              <div className="preview-container">{preview}</div>
            </Suspense>
          </>
        </SplitViewLayout>
        {confirmShareDialog}
      </div>
    </div>
  );
};
