import { useAuth0 } from '@auth0/auth0-react';
import {
  DirectionalHint,
  Dropdown,
  FontIcon,
  FontSizes,
  FontWeights,
  ICalloutContentStyles,
  Icon,
  IconButton,
  ITooltipHostStyles,
  Link,
  mergeStyles,
  NeutralColors,
  PrimaryButton,
  SearchBox,
  Spinner,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { SortOrder } from '@meetingflow/common/Api/data-contracts';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { TypedEventEmitter } from '@meetingflow/common/EventHelpers';
import {
  fieldDirty,
  getDirtyFields,
  objectIsDirty,
  PickValues,
} from '@meetingflow/common/ObjectHelpers';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AxiosResponse } from 'axios';
import { default as classNames } from 'classnames';
import { debounce, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PRIORITY_COLORS } from '../../Constants';
import { isAxiosErrorResponse } from '../../Helpers/AxiosHelpers';
import { hexToRGB } from '../../Helpers/ColorHelpers';
import {
  AgeInDays,
  DaysInStage,
  DaysSinceLastModified,
  DaysToCloseDate,
} from '../../Helpers/HubSpot/HubSpotDealHelpers';
import { HubSpotDealInsightRules } from '../../Helpers/HubSpot/HubSpotDealInsightsRules';
import {
  getFieldNameFieldMap,
  getYupObjectValidator,
} from '../../Helpers/HubSpot/HubSpotFieldHelpers';
import { validateData } from '../../Helpers/HubSpot/HubSpotYupValidators';
import { parseLocaleFloat } from '../../Helpers/NumberHelpers';
import { setValue } from '../../Helpers/SearchParamHelpers';
import { useExternalServiceConfigurations } from '../../Hooks/useExternalServiceConfigurations';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../Hooks/useOrganization';
import { useTitle } from '../../Hooks/useTitle';
import { HubSpotDeal } from '../../Models/HubSpot/HubSpotDeal';
import { HubSpotDealPipeline } from '../../Models/HubSpot/HubSpotDealPipeline';
import { HubSpotUserDealStats } from '../../Models/HubSpot/HubSpotDealStats';
import { HubSpotOwner } from '../../Models/HubSpot/HubSpotOwner';
import { HubSpotSchemaResponse } from '../../Models/HubSpot/HubSpotSchema';
import {
  HubSpotDealPipelineQuery,
  HubSpotDealsQuery,
  HubSpotDealStatsQuery,
  HubSpotObjectQuery,
  HubSpotObjectSchemaQuery,
  HubSpotOwnersQuery,
} from '../../QueryNames';
import { ApiClient } from '../../Services/NetworkCommon';
import HSIcon from '../../Static/Images/hubspot.png';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { GPTOutputBox } from '../Common/GPTOutputBox';
import { AsyncPrimaryButton } from '../HOC/AsyncButton';
import { InsightChip } from '../InsightChip';
import { MeetingflowCard } from '../MeetingPlans/MeetingflowCard';
import { HubSpotDealTile } from '../MeetingPlans/SidePanels/HubSpot/HubSpotDealTile';
import { HubSpotFormComponent } from '../MeetingPlans/SidePanels/HubSpot/HubSpotFormComponent';

const DEFAULT_EDITABLE_FIELDS = [
  'amount',
  'closedate',
  'dealstage',
  'hs_next_step',
];

const PANDA_WORKSPACES = ['panda-ai', 'meetingflow', 'happycust', 'kyle-zone2'];

type PipelineStateEvents = {
  isDirty: () => boolean;
  isSaving: () => boolean;
  save: () => Promise<unknown>;
};

type TableSort = { sortBy: string; sortOrder: SortOrder };

type SortableTableHeaderProps = {
  fieldLabel: string | React.ReactNode;
  fieldName?: string;
  isSortable?: boolean;
  sortBy: string;
  sortOrder: SortOrder;
  onSetSort?: (sort: TableSort) => void;
  loading?: boolean;
  showLoadingSpinner?: boolean;
};

const SortableTableHeader = ({
  fieldLabel,
  fieldName,
  isSortable,
  sortBy,
  sortOrder,
  onSetSort,
  loading,
  showLoadingSpinner,
}: SortableTableHeaderProps) => {
  const tableHeaderStyle = mergeStyles({
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: '.25rem',
    cursor: 'default',
    transition: 'all .3s ease-in-out',

    '.sort-asc': {
      transform: 'rotate(180deg)',
    },
    '.sort-desc': {
      transform: 'rotate(0deg)',
    },
  });

  if (!isSortable || sortBy !== fieldName) {
    return (
      <div
        className={classNames(
          tableHeaderStyle,
          isSortable ? 'sortable' : undefined,
        )}
        // @ts-ignore - We show an icon here, so we need to allow the title to be a ReactNode
        title={isSortable ? `Sort by ${fieldLabel}` : ''}
        onClick={
          isSortable && fieldName && onSetSort
            ? () => {
                onSetSort({ sortBy: fieldName, sortOrder: 'asc' });
              }
            : undefined
        }
      >
        <div className="label">{fieldLabel}</div>
        <div className="sortIcon">
          {loading && showLoadingSpinner ? (
            <Icon
              style={{
                animationName: 'fadeInSpinAnimation',
                animationDuration: '2000ms',
                animationIterationCount: 'infinite',
                animationTimingFunction: 'linear',
                opacity: 1,
              }}
              iconName={'ProgressRingDots'}
            />
          ) : null}
        </div>
      </div>
    );
  }
  return (
    <div
      className={tableHeaderStyle}
      title={`Sorting by ${fieldLabel} ${
        sortOrder === 'asc' ? 'ascending' : 'descending'
      }`}
      onClick={
        isSortable && fieldName && onSetSort
          ? () => {
              onSetSort({
                sortBy: fieldName,
                sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
              });
            }
          : undefined
      }
    >
      <div className="label">{fieldLabel}</div>
      <div className="sortIcon">
        {loading ? (
          <Icon
            className={`sort-${sortOrder}`}
            style={{
              animationName: 'fadeInSpinAnimation',
              animationDuration: '2000ms',
              animationIterationCount: 'infinite',
              animationTimingFunction: 'linear',
              opacity: 1,
            }}
            iconName={'ProgressRingDots'}
          />
        ) : (
          <Icon
            className={`sort-${sortOrder}`}
            iconName={sortOrder === 'asc' ? 'SortLines' : 'SortLines'}
          />
        )}
      </div>
    </div>
  );
};

type HubSpotPipelineDealRowProps = {
  organizationSlug: string;
  configurationId: number;
  hubspotInstance: string;
  schema: HubSpotSchemaResponse;
  deal: HubSpotDeal;
  activeInsightFilters: string[];
  dealStats?: HubSpotUserDealStats;
  showNextMeetingflow?: boolean;
  onDirtyChanged: (isDirty: boolean) => void;
  onSavingChanged: (isSaving: boolean) => void;
  stateEventEmitter: TypedEventEmitter<PipelineStateEvents>;
};

const HubSpotPipelineOpportunityRow = ({
  organizationSlug,
  configurationId,
  hubspotInstance,
  schema,
  deal,
  activeInsightFilters,
  dealStats,
  showNextMeetingflow,
  onDirtyChanged,
  onSavingChanged,
  stateEventEmitter,
}: HubSpotPipelineDealRowProps) => {
  const client = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const appInsights = useAppInsightsContext();

  const { isDark } = useLightOrDarkMode();

  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);

  const [updateErrors, setUpdateErrors] = useState<
    Record<string, string[] | undefined>
  >({});
  const [objectState, setObjectState] = useState<
    Record<string, string | boolean | number | undefined | null>
  >({});

  const { data: hubspotObject, refetch: refetchHubSpotObject } = useQuery(
    HubSpotObjectQuery(organizationSlug!, configurationId, 'deals', deal.id),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotDeal>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/deals/${deal.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: false,
    },
  );

  useEffect(() => {
    client.setQueryData(
      HubSpotObjectQuery(organizationSlug!, configurationId, 'deals', deal.id),
      { data: deal, status: 200 } satisfies Pick<
        AxiosResponse<HubSpotDeal, unknown>,
        'data' | 'status'
      >,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deal]);

  const opportunity = useMemo(
    () =>
      hubspotObject?.data
        ? {
            ...deal,
            ...hubspotObject.data,
          }
        : deal,
    [deal, hubspotObject?.data],
  );

  const { data: hubspotDealPipeline, refetch: refetchHubspotDealPipeline } =
    useQuery(
      HubSpotDealPipelineQuery(
        organizationSlug!,
        configurationId,
        hubspotObject?.data?.properties?.pipeline,
      ),
      async () => {
        const token = await getAccessTokenSilently();
        return ApiClient.get<HubSpotDealPipeline>(
          `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/pipelines/${hubspotObject?.data.properties.pipeline}/deal`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      },
      {
        enabled: !!opportunity.id && !!opportunity?.properties?.pipeline,
      },
    );

  useEffect(() => {
    if (opportunity?.properties?.pipeline) {
      refetchHubspotDealPipeline();
    }
  }, [opportunity?.properties?.pipeline, refetchHubspotDealPipeline]);

  const fieldNameFieldMap = useMemo(
    () => getFieldNameFieldMap(schema.hubspotSchema, hubspotDealPipeline?.data),
    [hubspotDealPipeline?.data, schema.hubspotSchema],
  );

  const fields = useMemo(() => {
    return (
      schema?.customFields?.length
        ? schema.customFields.map((f) => f.fieldName)
        : DEFAULT_EDITABLE_FIELDS
    )
      .filter((f) => !['dealname', 'hs_lastmodifieddate'].includes(f))
      .filter((f) => !fieldNameFieldMap[f]?.hidden);
  }, [fieldNameFieldMap, schema?.customFields]);

  const readOnlyFields = useMemo(() => {
    return fields.filter(
      (name) =>
        !!fieldNameFieldMap[name] &&
        (!!fieldNameFieldMap[name]?.calculated ||
          !!fieldNameFieldMap[name]?.modificationMetadata.readOnlyValue),
    );
  }, [fieldNameFieldMap, fields]);

  const editableFields = useMemo(() => {
    return fields.filter(
      (name) => !!fieldNameFieldMap[name] && !readOnlyFields.includes(name),
    );
  }, [fieldNameFieldMap, fields, readOnlyFields]);

  useEffect(() => {
    if (opportunity.properties && editableFields) {
      setObjectState(PickValues(opportunity.properties, editableFields));
    }
  }, [editableFields, opportunity.properties]);

  const formSchema = useMemo(
    () =>
      getYupObjectValidator(
        schema.hubspotSchema,
        editableFields,
        fieldNameFieldMap,
      ),
    [editableFields, fieldNameFieldMap, schema.hubspotSchema],
  );

  const [isValid, errors] = useMemo(
    () => validateData(formSchema, objectState),
    [formSchema, objectState],
  );

  const isDirty = useMemo(
    () => objectIsDirty(objectState, opportunity.properties || {}),
    [objectState, opportunity.properties],
  );

  const onSubmit = useCallback(async () => {
    if (!isValid || !isDirty) {
      return;
    }

    const dirtyFields = getDirtyFields(
      objectState,
      hubspotObject?.data?.properties || {},
    );

    const updateBody = Object.fromEntries(
      Object.entries(dirtyFields).map(([name, value]) => {
        const property = fieldNameFieldMap[name];

        if (!property) {
          return [name, value];
        }

        switch (property.type) {
          case 'number': {
            if (property.showCurrencySymbol) {
              return [name, parseLocaleFloat(value as string).toFixed(2)];
            }
            return [name, (value as number).toString()];
          }
          case 'boolean': {
            return [name, (value as boolean) ? 'True' : 'False'];
          }
          default: {
            return [name, value];
          }
        }
      }),
    );

    setFormIsSubmitting(true);
    setUpdateErrors({});

    await toast
      .promise(
        (async () => {
          const token = await getAccessTokenSilently();

          ApiClient.patch<HubSpotDeal>(
            `/organization/${organizationSlug}/external/hubspot/configuration/${configurationId}/deals/${opportunity.id}`,
            updateBody,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
        })(),
        {
          loading: 'Updating HubSpot deal',
          success: () => {
            appInsights.trackEvent({
              name: `HUBSPOT_DEAL_UPDATED`,
              properties: {
                oppName: hubspotObject?.data?.properties?.dealname,
                surface: 'PIPELINE_VIEW',
              },
            });
            refetchHubSpotObject();

            return 'HubSpot deal successfully updated';
          },
          error: (err) => {
            appInsights.trackEvent({
              name: `HUBSPOT_DEAL_UPDATE_ERROR`,
              properties: {
                oppName: hubspotObject?.data?.properties?.dealname,
                status: isAxiosErrorResponse(err)
                  ? err.response?.status
                  : undefined,
                statusText: isAxiosErrorResponse(err)
                  ? err.response?.statusText
                  : undefined,
              },
            });

            return 'Unable to update HubSpot deal. Please try again';
          },
        },
      )
      .finally(() => setFormIsSubmitting(false));
  }, [
    appInsights,
    configurationId,
    fieldNameFieldMap,
    getAccessTokenSilently,
    hubspotObject?.data?.properties,
    isDirty,
    isValid,
    objectState,
    opportunity.id,
    organizationSlug,
    refetchHubSpotObject,
  ]);

  useEffect(() => {
    const isDirtylistener = () => isDirty;
    const isSavingListener = () => formIsSubmitting;
    stateEventEmitter.addListener('isDirty', isDirtylistener);
    stateEventEmitter.addListener('isSaving', isSavingListener);
    stateEventEmitter.addListener('save', onSubmit);
    return () => {
      stateEventEmitter.removeListener('isDirty', isDirtylistener);
      stateEventEmitter.removeListener('isSaving', isSavingListener);
      stateEventEmitter.removeListener('save', onSubmit);
    };
  }, [formIsSubmitting, isDirty, onSubmit, stateEventEmitter]);

  useEffect(() => {
    onDirtyChanged(isDirty);
  }, [isDirty, onDirtyChanged]);

  useEffect(() => {
    onSavingChanged(formIsSubmitting);
  }, [formIsSubmitting, onSavingChanged]);

  const insights = useMemo(
    () =>
      HubSpotDealInsightRules.map((rule) =>
        rule(opportunity, fieldNameFieldMap, dealStats),
      ).filter(Truthy),
    [fieldNameFieldMap, opportunity, dealStats],
  );

  const getInsightTooltip = useMemo(
    () => (insightKeysOrFieldNames: string[]) => {
      const toolTipHostStyles = {
        root: {
          position: 'absolute',
          bottom: '.25rem',
          right: '.25rem',
          zIndex: 8,
        },
      } as Partial<ITooltipHostStyles>;

      const insightsIconWrapperStyles = {
        backgroundColor: isDark ? NeutralColors.gray220 : NeutralColors.white,
        height: '14px',
        width: '14px',
        borderRadius: '50%',
        textAlign: 'center' as string,
        lineHeight: '14px',
        cursor: 'pointer',
        border: `1px solid ${
          isDark ? NeutralColors.gray130 : NeutralColors.gray50
        }`,

        boxShadow: `0 0 0 0 rgba(0, 0, 0, 1)`,
        transform: `scale(1)`,
        animation: `fadeInAnimation 3s`,
        transition: `all 0.3s ease-in-out`,
      } as React.CSSProperties;

      const insightsIconStyle = {
        position: 'relative' as string,
        display: 'block',
      } as React.CSSProperties;

      const insightsCalloutStyles = {
        root: {
          maxWidth: '18rem',
          color: MEETINGFLOW_COLORS.white,
        },
        calloutMain: {
          padding: 0,
          color: MEETINGFLOW_COLORS.white,
          FontWeights: FontWeights.semibold,
        },
        calloutContainer: {
          padding: 0,
        },
      } as Partial<ICalloutContentStyles>;

      return insights
        .filter(
          (i) =>
            insightKeysOrFieldNames?.includes(i.key) ||
            (i?.fieldName && insightKeysOrFieldNames?.includes(i?.fieldName)),
        )
        .map(
          (i) =>
            (
              <TooltipHost
                content={
                  <Text
                    style={{
                      fontWeight: FontWeights.semibold,
                      fontSize: FontSizes.small,
                      color: MEETINGFLOW_COLORS.white,
                    }}
                  >
                    {i.insight}
                  </Text>
                }
                onTooltipToggle={(isTooltipVisible) => {
                  if (isTooltipVisible) {
                    appInsights.trackEvent({
                      name: 'PIPELINE_INSIGHTS_TOOLTIP_VIEWED',
                      properties: {
                        rule: i.key,
                        fieldName: i.fieldName,
                        priority: i.priority,
                        opportunityId: opportunity.id,
                        crm: 'SALESFORCE',
                      },
                    });
                  }
                }}
                styles={toolTipHostStyles}
                calloutProps={{
                  backgroundColor:
                    PRIORITY_COLORS[i.priority.toString() as '1' | '2' | '3'],
                  styles: {
                    ...insightsCalloutStyles,
                    beakCurtain: {
                      backgroundColor:
                        PRIORITY_COLORS[
                          i.priority.toString() as '1' | '2' | '3'
                        ],
                    },
                  },
                }}
                directionalHint={DirectionalHint.bottomCenter}
              >
                <div
                  style={insightsIconWrapperStyles}
                  id={`${opportunity.id}-insight-icon-lastMeetingflow`}
                >
                  <FontIcon
                    iconName={'AlertSolid'}
                    style={{
                      ...insightsIconStyle,
                      color:
                        PRIORITY_COLORS[
                          i.priority.toString() as '1' | '2' | '3'
                        ],
                    }}
                  />
                </div>
              </TooltipHost>
            ) as unknown as JSX.Element,
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [insights, opportunity.id, isDark],
  );

  if (
    activeInsightFilters.length &&
    !insights.some((i) => activeInsightFilters.includes(i.key))
  ) {
    return null;
  }

  return (
    <tr
      className={classNames(
        isDirty ? 'row-dirty' : '',
        isValid ? '' : 'row-invalid',
      )}
    >
      <td className="first-column">
        <HubSpotDealTile
          organizationSlug={organizationSlug}
          hubspotInstance={hubspotInstance}
          id={deal.id}
          name={opportunity.properties.dealname}
          companyName={opportunity.companies[0]?.properties.name}
          ownerName={
            opportunity.owner
              ? `${opportunity.owner.firstName} ${opportunity.owner.lastName}`
              : undefined
          }
          showArrow={false}
          showExternalLink
          showObjectType={false}
          // showViewAllMeetingflows
        />
      </td>
      <td className="field-meetingflow">
        {insights.filter((i) =>
          ['noLastMeetingflow', 'meetingflowDaysAgo'].includes(i.key),
        )
          ? getInsightTooltip(['noLastMeetingflow', 'meetingflowDaysAgo'])
          : null}
        {opportunity?.properties?.lastMeetingflow ? (
          <MeetingflowCard
            meetingflowId={opportunity.properties.lastMeetingflow.id}
            organizationSlug={organizationSlug}
            showCallRecordingButton
          />
        ) : null}
      </td>
      {showNextMeetingflow ? (
        <td className="field-meetingflow">
          {opportunity?.properties?.nextMeetingflow ? (
            <MeetingflowCard
              meetingflowId={opportunity?.properties?.nextMeetingflow.id}
              organizationSlug={organizationSlug}
              showCallRecordingButton
            />
          ) : null}
        </td>
      ) : null}
      {fields.map((fieldName) => {
        const property = fieldNameFieldMap[fieldName];
        if (!property) {
          return null;
        }

        const isReadonly = readOnlyFields.includes(fieldName);

        return (
          <td
            key={fieldName}
            className={`field-${fieldName} field-${property.type}`}
          >
            {insights.filter((i) => fieldName === i.fieldName)
              ? getInsightTooltip([fieldName])
              : null}
            <HubSpotFormComponent
              key={property.name}
              schema={schema.hubspotSchema}
              property={property}
              errors={{ ...errors, ...updateErrors }}
              readonly={isReadonly}
              isDirty={
                isReadonly
                  ? false
                  : fieldDirty(objectState, opportunity, property.name)
              }
              state={isReadonly ? opportunity?.properties : objectState}
              setState={setObjectState}
              hideLabel
            />
          </td>
        );
      })}
      <td>
        {insights.filter((i) => 'hs_lastmodifieddate' === i.fieldName)
          ? getInsightTooltip(['hs_lastmodifieddate'])
          : null}
        {opportunity.properties.hs_lastmodifieddate
          ? humanizeDateTime(opportunity.properties.hs_lastmodifieddate)
          : null}
      </td>
    </tr>
  );
};

export const HubSpotPipelineView = () => {
  const { slug: organizationSlug, role } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [pipelineDirty, setPipelineDirty] = useState<boolean>(false);
  const [pipelineSaving, setPipelineSaving] = useState<boolean>(false);

  const [stateEmmitter] = useState<TypedEventEmitter<PipelineStateEvents>>(
    new TypedEventEmitter<PipelineStateEvents>(),
  );

  const appInsights = useAppInsightsContext();

  useTitle(`HubSpot Deal Pipeline`);

  const [ownerId, setOwnerId] = useState<string>();
  const [q, setQ] = useState<string>('');
  const [sort, setSort] = useState<{ sortBy: string; sortOrder: SortOrder }>({
    sortBy: 'dealname',
    sortOrder: 'asc',
  });
  const [activeInsightFilters, setActiveInsightFilters] = useState<string[]>(
    [],
  );

  const { isDark } = useLightOrDarkMode();

  const [pipelineCoachVisible, { toggle: togglePipelineCoachVisible }] =
    useBoolean(false);

  const {
    loading: configurationsLoading,
    configurationsWithToken,
    configurationById,
  } = useExternalServiceConfigurations({ app: 'HUBSPOT', withToken: true });

  const queryParamConfigurationId = useMemo(() => {
    if (searchParams.has('configuration')) {
      const externalServiceConfigurationId = Number.parseInt(
        searchParams.get('configuration')!,
      );

      return !isNaN(externalServiceConfigurationId)
        ? externalServiceConfigurationId
        : undefined;
    }
    return undefined;
  }, [searchParams]);

  const activeConfiguration = useMemo(() => {
    if (configurationsLoading && !configurationsWithToken.length) {
      return undefined;
    }

    if (
      queryParamConfigurationId &&
      !!configurationById(queryParamConfigurationId)
    ) {
      return configurationById(queryParamConfigurationId);
    }

    const firstConfiguration = configurationsWithToken[0];

    // If the user has no HubSpot token, navigate back to home
    if (!firstConfiguration) {
      navigate(`/organization/${organizationSlug}`);
      return undefined;
    }

    setSearchParams(
      setValue(searchParams, 'configuration', firstConfiguration.id.toString()),
    );
    return firstConfiguration;
  }, [
    configurationById,
    configurationsLoading,
    configurationsWithToken,
    navigate,
    organizationSlug,
    queryParamConfigurationId,
    searchParams,
    setSearchParams,
  ]);

  const {
    data: objectSchema,
    isLoading: objectSchemaLoading,
    refetch: refetchObjectSchema,
  } = useQuery(
    HubSpotObjectSchemaQuery(
      organizationSlug!,
      activeConfiguration?.id,
      'deals',
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotSchemaResponse>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${activeConfiguration?.id}/schema/deals`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled:
        activeConfiguration && !!configurationById(activeConfiguration.id),
    },
  );

  const { data: dealStats, refetch: refetchDealStats } = useQuery(
    HubSpotDealStatsQuery(organizationSlug!, activeConfiguration?.id),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotUserDealStats>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${activeConfiguration?.id}/deal-stats`,
        {
          params: {
            ownerId,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled:
        activeConfiguration && !!configurationById(activeConfiguration.id),
    },
  );

  const {
    data: hubspotOwners,
    isLoading: hubspotOwnersLoading,
    refetch: refetchHubSpotOwners,
  } = useQuery(
    HubSpotOwnersQuery(organizationSlug!, activeConfiguration?.id),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotOwner[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${activeConfiguration?.id}/owners`,
        {
          params: {
            active: true,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: false,
    },
  );

  const {
    data: hubspotDeals,
    isLoading: hubspotDealsLoading,
    isRefetching: hubspotDealsRefetching,
    refetch: refetchHubSpotDeals,
  } = useQuery(
    HubSpotDealsQuery(organizationSlug!, activeConfiguration?.id),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<HubSpotDeal[]>(
        `/organization/${organizationSlug}/external/hubspot/configuration/${activeConfiguration?.id}/deals`,
        {
          params: {
            q,
            active: true,
            my: !ownerId,
            ownerIds: ownerId || undefined,
            sortBy: sort.sortBy,
            sortOrder: sort.sortOrder,
            includeMeetingflows: true,
          },
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (
      activeConfiguration?.externalServiceUserTokens?.length &&
      activeConfiguration.externalServiceUserTokens[0].externalUserId
    ) {
      setOwnerId(
        activeConfiguration.externalServiceUserTokens[0].externalUserId,
      );
    }
  }, [activeConfiguration?.externalServiceUserTokens]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefetch = useCallback(debounce(refetchHubSpotDeals, 250), [
    refetchHubSpotDeals,
  ]);

  useEffect(() => {
    if (!!organizationSlug && !!activeConfiguration) {
      if (role === 'ADMIN') {
        refetchHubSpotOwners();
      }
      refetchObjectSchema();
      refetchDealStats(), refetchHubSpotDeals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConfiguration, organizationSlug, role]);

  useEffect(() => {
    debouncedRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, sort, ownerId]);

  useEffect(() => {
    appInsights.trackEvent({
      name: 'HUBSPOT_PIPELINE_VIEWED',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldNameFieldMap = useMemo(
    () => getFieldNameFieldMap(objectSchema?.data?.hubspotSchema),
    [objectSchema?.data],
  );

  const fields = useMemo(() => {
    return (
      objectSchema?.data?.customFields?.length
        ? objectSchema.data.customFields.map((f) => f.fieldName)
        : DEFAULT_EDITABLE_FIELDS
    )
      .filter((f) => !['dealname', 'hs_lastmodifieddate'].includes(f))
      .filter((f) => !fieldNameFieldMap[f]?.hidden);
  }, [fieldNameFieldMap, objectSchema?.data?.customFields]);

  const REFERENCE_FIELD_MAX_WIDTH_REM = 14;

  const slideSpinnerClass = mergeStyles({
    height: '2rem',
    animationName: 'slideDownSpinnerAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '.5',
    animationFillMode: 'forwards',
  });

  const tableStyles = mergeStyles({
    width: '100%',
    height: '100%',
    overflow: 'auto',

    table: {
      borderCollapse: 'collapse',
      tableLayout: 'fixed',
      marginBottom: '2.5rem',

      'th, td': {
        position: 'relative',
        minWidth: '7rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        padding: '.25rem .5rem',
        transition: 'background-color .3s ease-in-out',
        borderBottom: `1px solid ${
          isDark ? NeutralColors.gray210 : NeutralColors.gray20
        }`,
        borderRight: `1px solid ${
          isDark ? NeutralColors.gray210 : NeutralColors.gray20
        }`,
        maxHeight: '3.5rem',

        'p.ms-TextField-errorMessage': {
          fontSize: FontSizes.mini,
          padding: 0,
        },
      },

      'tr.row-dirty td': {
        backgroundColor: `${
          isDark
            ? MEETINGFLOW_COLORS.purpleDarkest
            : MEETINGFLOW_COLORS.purpleGrey
        } !important`,
      },

      'tr.row-invalid td': {
        backgroundColor: `${
          isDark
            ? hexToRGB(MEETINGFLOW_COLORS.red, 0.05)
            : hexToRGB(MEETINGFLOW_COLORS.red, 0.25)
        } !important`,
      },

      'tr:nth-child(even)': {
        backgroundColor: isDark
          ? NeutralColors.gray210
          : MEETINGFLOW_COLORS.white,
      },

      'tr:nth-child(odd)': {
        backgroundColor: isDark ? NeutralColors.gray200 : NeutralColors.gray10,
      },

      'td.has-insight': {
        backgroundColor: MEETINGFLOW_COLORS.purpleLightest,
      },

      '.field-meetingflow': {
        minWidth: '10rem',
      },

      '.field-currency': {
        minWidth: '7rem',
        maxWidth: '8rem',
      },

      '.field-date': {
        minWidth: '10rem',
        maxWidth: '12rem',

        '.ms-DatePicker': {
          position: 'relative',
          top: '3px !important',
        },
      },

      '.field-datetime': {
        minWidth: '12rem',
        maxWidth: '15rem',
      },

      '.field-string': {
        minWidth: '15rem',
        maxWidth: '15rem',
      },

      '.field-enumeration': {
        minWidth: '10rem',
      },

      '.field-reference': {
        minWidth: '10rem',
        maxWidth: `${REFERENCE_FIELD_MAX_WIDTH_REM}rem`,

        '.account-lookup-field, .opportunity-lookup-field, .lead-lookup-field, .contact-lookup-field':
          {
            height: '22px !important',
            borderRadius: '3px',

            '.content span': {
              boxSizing: 'border-box',
              lineHeight: '1rem',
              top: '-0.25rem !important',
              maxWidth: `${REFERENCE_FIELD_MAX_WIDTH_REM}rem !important`,
              paddingRight: '4.5rem !important',
            },

            '.content div': {
              display: 'none !important',
            },

            '.choose-label': {
              top: '.5rem',
            },

            button: {
              lineHeight: '22px !important',
              fontSize: FontSizes.small,
            },
          },
      },

      '.clear-button': {
        top: '.4rem !important',
        right: '1.75rem !important',
      },

      '.lookup-field .clear-button': {
        top: '.4rem !important',
        right: '3.75rem !important',
      },

      '.header-first-column': {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 12,
        backgroundColor: isDark
          ? NeutralColors.gray220
          : MEETINGFLOW_COLORS.white,
        borderRight: `1px solid ${
          isDark ? NeutralColors.gray210 : MEETINGFLOW_COLORS.purpleLighter
        } `,
        borderBottom: `1px solid ${
          isDark ? NeutralColors.gray210 : MEETINGFLOW_COLORS.purpleLighter
        } `,
        fontSize: FontSizes.small,
        fontWeight: FontWeights.semibold,
        '*': {
          cursor: 'pointer',
        },
        cursor: 'pointer',
      },

      '.header-insights': {
        minWidth: 0,
        padding: '.25rem',
        position: 'sticky',
        backgroundColor: isDark
          ? NeutralColors.gray220
          : MEETINGFLOW_COLORS.white,
        top: 0,
        left: 0,
        zIndex: 12,

        '> div': {
          columnGap: 0,
        },
      },

      '.header': {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: isDark
          ? NeutralColors.gray220
          : MEETINGFLOW_COLORS.white,
        borderRight: `1px solid ${
          isDark ? NeutralColors.gray210 : MEETINGFLOW_COLORS.purpleLighter
        } `,
        borderBottom: `1px solid ${
          isDark ? NeutralColors.gray210 : MEETINGFLOW_COLORS.purpleLighter
        } `,
        fontSize: FontSizes.small,
        fontWeight: FontWeights.semibold,

        '.sortable': {
          '*': {
            cursor: 'pointer',
          },
          cursor: 'pointer',
        },
      },

      '.sort-active': {
        backgroundColor: `${
          isDark ? NeutralColors.gray160 : MEETINGFLOW_COLORS.purpleUltraLight
        } !important`,
      },

      '.first-column': {
        position: 'sticky',
        minWidth: '12rem',
        left: 0,
        zIndex: 10,
        padding: 0,
        borderBottom: `none !important`,
        backgroundColor: isDark
          ? NeutralColors.gray210
          : MEETINGFLOW_COLORS.purpleUltraSuperLight,

        '.HubSpot-opportunity-tile': {
          height: '3.5rem',
          maxHeight: '3.5rem',
          position: 'relative',
          top: '-.5px !important',
          padding: '.25rem .5rem',
          borderTop: `1px solid ${
            isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleLighter
          } `,
          borderRight: `1px solid ${
            isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleLight
          }`,

          '.content': {
            maxWidth: '15rem',
            span: {
              // paddingRight: '0 !important',
              maxWidth: '100% !important',

              '.ms-Icon-imageContainer': {
                position: 'absolute',
                top: 0,
                right: 0,

                '.ms-Image': {
                  top: 0,
                },
              },
            },
            div: {
              position: 'relative',
              top: '-.35rem',
              maxWidth: '15rem',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',

              span: {
                paddingRight: 0,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              },
            },
          },
        },
      },

      // Styles for the lookup fields, which are a bit big in their default styles for this context
      '.lookup-field > div.no-selection': {
        height: '1.5rem',

        button: {
          lineHeight: '1.5rem',
        },
      },
    },
  });

  const deals = useMemo(() => {
    if (!hubspotDeals?.data?.length) {
      return [];
    }

    return hubspotDeals.data.map((deal) => {
      const ageInDays = AgeInDays(deal);
      const daysSinceLastModified = DaysSinceLastModified(deal);
      const daysToClose = DaysToCloseDate(deal);
      const daysInStage = DaysInStage(deal);

      const insights = HubSpotDealInsightRules.map((rule) =>
        rule(deal, fieldNameFieldMap, dealStats?.data),
      ).filter(Truthy);

      return {
        ...deal,
        ageInDays,
        daysSinceLastModified,
        daysToClose,
        daysInStage,
        insights,
      };
    });
  }, [fieldNameFieldMap, dealStats?.data, hubspotDeals?.data]);

  const showNextMeetingflow = useMemo(
    () => deals?.some((deal) => !!deal.properties.nextMeetingflow),
    [deals],
  );

  const insights = useMemo(() => {
    const activeInsights = deals.flatMap((deal) => deal.insights);
    const distinctInsightTypes = DeduplicateArray(
      activeInsights.map((i) => i.key),
    );
    return Object.fromEntries(
      distinctInsightTypes.map((key) => [
        key,
        {
          name: activeInsights.find((i) => i.key === key)!.name,
          priority: activeInsights.find((i) => i.key === key)!.priority,
          count: activeInsights.filter((i) => i.key === key).length,
        },
      ]),
    );
  }, [deals]);

  useEffect(() => {
    setActiveInsightFilters([]);
  }, [insights]);

  const coachPrompt = useMemo(() => {
    if (!deals.length) {
      return undefined;
    }

    return `Read this pipeline of opportunities.

    ${JSON.stringify(
      deals.map((deal) =>
        Object.fromEntries(
          Object.entries({
            ...deal.properties,
            insights: deal.insights.map((i) => i.insight),
            'Last Meetingflow Date':
              deal.properties.lastMeetingflow?.startTime ?? null,
            'Next Meetingflow Date': deal.properties.nextMeetingflow?.startTime
              ? deal.properties.nextMeetingflow.startTime
              : showNextMeetingflow
                ? null
                : undefined,
            lastMeetingflow: undefined,
            nextMeetingflow: undefined,
          })
            .filter(([key]) => {
              if (['dealStageHistory'].includes(key)) {
                return false;
              }

              if (fieldNameFieldMap && key in fieldNameFieldMap) {
                const field = fieldNameFieldMap[key]!;
                return !field.referencedObjectType;
              }

              return true;
            })
            .map(([key, value]) =>
              fieldNameFieldMap && key in fieldNameFieldMap && key !== 'id'
                ? [fieldNameFieldMap[key]!.label, value]
                : [key, value],
            ),
        ),
      ),
    )}

    Give your top insights or tips, no more than 6, based on the opportunities above with focus on the text you read and anything particularly promising, concerning, surprising or that needs attention. Give tips you think will help win. Please make these based on what you read in the pipeline, do not make things up and do not try to add up values. Make the output formatted as follows: Each insight should be extremely brief as if written by a computer program, each should have a line break after them, each should lead with an appropriate emoji.`;
  }, [deals, fieldNameFieldMap, showNextMeetingflow]);

  const onDirtyChanged = useCallback(
    (isDirty: boolean) => {
      if (isDirty) {
        setPipelineDirty(true);
      }
      setPipelineDirty(stateEmmitter.emit('isDirty', undefined).some(Truthy));
    },
    [stateEmmitter],
  );

  const onSavingChanged = useCallback(
    (isSaving: boolean) => {
      if (isSaving) {
        setPipelineSaving(true);
      }
      setPipelineSaving(stateEmmitter.emit('isSaving', undefined).some(Truthy));
    },
    [stateEmmitter],
  );

  const sortedInsights = useMemo(
    () =>
      orderBy(
        Object.entries(insights).map(([key, value]) => ({ ...value, key })),
        'priority',
        'desc',
      ),
    [insights],
  );

  if (objectSchemaLoading) {
    return (
      <div style={{ width: '100%', textAlign: 'center', margin: '2rem' }}>
        <Spinner />
      </div>
    );
  }

  if (!activeConfiguration || !objectSchema?.data) {
    return null;
  }

  const DISPLAY_GPT_CONTROLS =
    (organizationSlug && PANDA_WORKSPACES.includes(organizationSlug)) || false;
  const PIPELINE_COACH_WIDTH = '25rem';
  const PIPELINE_COACH_HEIGHT = DISPLAY_GPT_CONTROLS ? `45rem` : `27rem`;
  const PIPELINE_COACH_TEXTAREA_HEIGHT = `25rem`;
  const PIPELINE_COACH_MIN_HEIGHT = '10rem';
  const PIPELINE_COACH_MAX_HEIGHT = `calc(100vh - 10rem)`; // 3.75rem is the height of the header

  const pageContainerClass = mergeStyles({
    display: 'flex',
    flexBasis: '100%',
    flexDirection: 'row',
    height: `calc(100vh - 7.75rem)`,
    columnGap: '0',
    overflow: 'hidden',
    margin: '1rem .5rem',
    padding: '.5rem',
    borderRadius: '.5rem',
    justifyContent: 'center',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,
  });

  const coachButtonClass = mergeStyles({
    position: 'absolute',
    bottom: '.5rem',
    right: '1rem',
    zIndex: 51,
    // fontFamily: 'Kalam',
    transition: '.3s ease-in-out all',

    span: {
      color: `${MEETINGFLOW_COLORS.white} !important`,
      position: 'relative',
      top: '-1px',
    },

    '&:hover': {
      backgroundColor: MEETINGFLOW_COLORS.teal,
      borderColor: MEETINGFLOW_COLORS.teal,
    },

    i: {
      fontSize: '1.5rem',
      position: 'relative',
      top: '6px',
      right: 0,
      color: `${MEETINGFLOW_COLORS.white} !important`,
    },
  });

  const coachContainerClass = mergeStyles({
    animationName: 'fadeInAnimation',
    animationDuration: '.3s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    transition: '.5s ease-in-out all',
    position: 'absolute',
    bottom: '4rem',
    right: '1rem',
    width: PIPELINE_COACH_WIDTH,
    maxWidth: PIPELINE_COACH_WIDTH,
    height: PIPELINE_COACH_HEIGHT,
    minHeight: PIPELINE_COACH_MIN_HEIGHT,
    maxHeight: PIPELINE_COACH_MAX_HEIGHT,
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.purpleUltraSuperLightish,
    zIndex: 51,
    borderRadius: '.5rem',
    paddingBottom: '.5rem',
    boxShadow: isDark
      ? '0px 0px 10px rgba(0, 0, 0, .5)'
      : '0px 0px 10px rgba(0, 0, 0, 0.125)',

    '.coach-header': {
      lineHeight: '2rem',
      // fontFamily: 'Kalam',
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.smallPlus,
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 'calc(100% - 1rem - 2px)',
      height: '2rem',
      backgroundColor: isDark
        ? NeutralColors.gray210
        : MEETINGFLOW_COLORS.purpleGrey,
      zIndex: 55,
      borderTopLeftRadius: '.25rem',
      borderTopRightRadius: '.25rem',
      borderBottom: 'none',
      padding: '0 .5rem',
      color: MEETINGFLOW_COLORS.purpleMedium,
    },

    '.coach-content': {
      position: 'relative',
      overflow: 'auto',
      height: `calc(100% - 3rem)`,
      top: '2rem',
      padding: '.5rem',
    },

    '.close-button': {
      position: 'absolute',
      top: '.25rem',
      right: '.25rem',
      height: '1.5rem',
      transition: '.3s ease-in-out all',
      backgroundColor: 'transparent !important',
      color: MEETINGFLOW_COLORS.purpleSecondary,
      ':hover': {
        color: MEETINGFLOW_COLORS.teal,
      },
      zIndex: 56,
    },

    '.gpt-output-box': {
      display: 'flex',
      flexDirection: 'column',
      height: `calc(100% - 3rem)`,

      '.ms-TextField-wrapper label': {
        textAlign: 'center',
      },

      '.generate-button': {
        position: 'relative',
        backgroundColor: 'transparent',
        padding: '0 0 0 .5rem',
        borderRadius: '.25rem',
        top: '-0 !important',
        zIndex: '56',
        float: 'none !important',
        transition: '.3s ease-in-out all',
        span: {
          color: isDark ? NeutralColors.gray100 : NeutralColors.gray100,
          position: 'relative',
          top: '-1px',
        },
        outline: `1px solid transparent`,
        ':hover': {
          outline: `1px solid ${
            isDark ? NeutralColors.gray170 : MEETINGFLOW_COLORS.purpleLight
          }`,
          backgroundColor: isDark
            ? NeutralColors.gray160
            : MEETINGFLOW_COLORS.white,
        },
      },

      '.gpt-controls': {
        marginBottom: '.5rem',
      },

      '.output': {
        position: 'relative',
        '.generate-button': {
          float: 'right',
        },
        '.output-input': {
          '.ms-TextField-wrapper': {
            '.ms-TextField-fieldGroup': {
              backgroundColor: 'transparent !important',
              textarea: {
                resize: 'none',
                height: `${PIPELINE_COACH_TEXTAREA_HEIGHT} !important`,
                overflow: 'auto',
                fontSize: `${FontSizes.medium} !important`,
                fontWeight: FontWeights.semibold,
                border: 'none !important',
                backgroundColor: 'transparent !important',
                paddingTop: '.5rem !important',
                paddingRight: '1rem !important',
                color: isDark ? NeutralColors.gray70 : NeutralColors.gray130,

                ':disabled': {
                  color: `${
                    isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black
                  } !important`,
                  boxShadow: 'none !important',
                  cursor: 'unset',

                  border: `none !important`,
                },
              },
            },
          },
        },
      },
    },
  });

  const tableWrapperClass = mergeStyles({
    maxWidth: '100%',
  });

  const tableControlsClass = mergeStyles({
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    padding: '.25rem 0',
    columnGap: '.5rem',
    marginTop: '1rem',
    marginBottom: '.5rem',
  });

  const tableContainerClass = mergeStyles({
    overflow: 'auto',
    height: `calc(100% - 4rem)`,
  });

  const saveBarClass = mergeStyles({
    position: 'absolute',
    bottom: 0,
    left: 0,
    boxSizing: 'border-box',
    width: '100%',
    textAlign: 'left',
    padding: '.5rem 1rem',
    zIndex: 50,
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.purpleDarkest
      : MEETINGFLOW_COLORS.purpleLight,
    animationName: 'fadeInSlideUpAnimation',
    animationDuration: '.5s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
  });

  const hubSpotLinkClass = mergeStyles({
    height: '2rem',
    lineHeight: '2rem',
    fontWeight: FontWeights.semibold,
    display: 'block',
    marginBottom: '.5rem',
    position: 'relative',
    span: {
      position: 'absolute',
      top: 0,
      display: 'inline-block',
    },
  });

  const hubSpotIconClass = mergeStyles({
    height: '2rem',
    width: '2rem',
    borderRadius: '1rem',
    display: 'inline-block',
    position: 'relative',
    marginRight: '.5rem',
    img: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '2rem',
      width: '2rem',
      display: 'block',
    },
  });

  const hubspotDealDashboardUrl = activeConfiguration
    ? `https://app.hubspot.com/contacts/${activeConfiguration.instanceId}/deals`
    : undefined;

  return (
    <div className={pageContainerClass}>
      <div className={tableWrapperClass}>
        <div className={tableControlsClass}>
          <SearchBox
            disabled={hubspotDealsLoading || hubspotDealsRefetching}
            placeholder="Search opportunities or companies..."
            value={q}
            onChange={(e, newValue) => setQ(newValue ?? '')}
            styles={{
              root: {
                transition: '.3s all ease-in-out',
                width: '15rem',
                marginRight: '.5rem',
              },
            }}
          />

          {role === 'ADMIN' ? (
            <div
              style={{
                width: '12.25rem',
                position: 'relative',
                marginRight: '.5rem',
                display: 'flex',
              }}
            >
              <FontIcon
                iconName="FollowUser"
                title="Filter by Opportunity Owner"
                style={{
                  display: 'block',
                  backgroundColor: isDark
                    ? NeutralColors.gray160
                    : NeutralColors.gray90,
                  color: MEETINGFLOW_COLORS.white,
                  borderRadius: '.75rem',
                  width: '1.5rem',
                  height: '1.5rem',
                  lineHeight: '1.5rem',
                  fontSize: '1rem',
                  textAlign: 'center',
                  marginRight: '.5rem',
                  position: 'relative',
                  top: '.25rem',
                }}
              />
              <Dropdown
                disabled={hubspotOwnersLoading}
                selectedKey={ownerId}
                options={
                  hubspotOwners?.data?.map((owner) => ({
                    key: owner.id,
                    text: `${owner.firstName} ${owner.lastName}`,
                  })) ?? []
                }
                onChange={(_e, option) => {
                  if (option?.key) {
                    setOwnerId(option.key as string);
                  }
                }}
              />
            </div>
          ) : null}

          <Link
            className={hubSpotLinkClass}
            href={hubspotDealDashboardUrl}
            target="_blank"
            as={'a'}
            styles={{
              root: {
                width: '13rem',
                marginBottom: 0,
              },
            }}
          >
            <div
              className={hubSpotIconClass}
              style={{ backgroundColor: 'transparent' }}
            >
              <img alt="Create New Account" src={HSIcon} />
            </div>
            <span>HubSpot Deals</span>
          </Link>

          <div
            style={{
              width: '32px',
              position: 'relative',
              top: '.25rem',
            }}
          >
            {hubspotDealsLoading || hubspotDealsRefetching ? (
              <Spinner className={slideSpinnerClass} />
            ) : null}
          </div>
        </div>

        {Object.keys(insights).length ? (
          <div style={{ display: 'flex', padding: '.25rem 0 .5rem 0' }}>
            {sortedInsights.map((i) => (
              <InsightChip
                key={i.key}
                isActive={activeInsightFilters.includes(i.key)}
                priority={i.priority}
                count={i.count}
                text={i.name}
                onClick={() => {
                  if (activeInsightFilters.includes(i.key)) {
                    setActiveInsightFilters(
                      activeInsightFilters.filter((f) => f !== i.key),
                    );
                  } else {
                    setActiveInsightFilters(
                      DeduplicateArray([...activeInsightFilters, i.key]),
                    );
                  }
                }}
              />
            ))}
          </div>
        ) : null}

        <div className={tableContainerClass}>
          <div className={tableStyles}>
            <table>
              <thead>
                <tr>
                  <th className="header-first-column">
                    <SortableTableHeader
                      fieldLabel="Deal"
                      fieldName="dealname"
                      sortBy={sort.sortBy}
                      sortOrder={sort.sortOrder}
                      isSortable
                      onSetSort={setSort}
                      loading={hubspotDealsLoading || hubspotDealsRefetching}
                    />
                  </th>
                  <th
                    className={`header ${
                      sort.sortBy === 'lastMeetingflow' ? 'sort-active' : ''
                    }`}
                  >
                    <SortableTableHeader
                      fieldLabel="Last Logged Meetingflow"
                      fieldName="lastMeetingflow"
                      sortBy={sort.sortBy}
                      sortOrder={sort.sortOrder}
                      isSortable
                      onSetSort={setSort}
                      loading={hubspotDealsLoading || hubspotDealsRefetching}
                    />
                  </th>
                  {showNextMeetingflow ? (
                    <th
                      className={`header ${
                        sort.sortBy === 'nextMeetingflow' ? 'sort-active' : ''
                      }`}
                    >
                      <SortableTableHeader
                        fieldLabel="Next Meetingflow"
                        fieldName="nextMeetingflow"
                        sortBy={sort.sortBy}
                        sortOrder={sort.sortOrder}
                        isSortable
                        onSetSort={setSort}
                        loading={hubspotDealsLoading || hubspotDealsRefetching}
                      />
                    </th>
                  ) : null}
                  {fields.map((f) => (
                    <th
                      key={f}
                      className={`header ${
                        sort.sortBy === fieldNameFieldMap[f]!.name
                          ? 'sort-active'
                          : ''
                      } `}
                    >
                      <SortableTableHeader
                        fieldLabel={fieldNameFieldMap[f]!.label}
                        fieldName={fieldNameFieldMap[f]!.name}
                        sortBy={sort.sortBy}
                        sortOrder={sort.sortOrder}
                        isSortable
                        onSetSort={setSort}
                        loading={hubspotDealsLoading || hubspotDealsRefetching}
                      />
                    </th>
                  ))}
                  <th
                    className={`header ${
                      sort.sortBy === 'hs_lastmodifieddate' ? 'sort-active' : ''
                    }`}
                  >
                    <SortableTableHeader
                      fieldLabel="Last Modified"
                      fieldName="hs_lastmodifieddate"
                      sortBy={sort.sortBy}
                      sortOrder={sort.sortOrder}
                      isSortable
                      onSetSort={setSort}
                      loading={hubspotDealsLoading || hubspotDealsRefetching}
                    />
                  </th>
                </tr>
              </thead>

              {deals.length ? (
                <tbody>
                  {deals.map((deal) => (
                    <HubSpotPipelineOpportunityRow
                      key={deal.id}
                      organizationSlug={organizationSlug!}
                      configurationId={activeConfiguration.id}
                      hubspotInstance={activeConfiguration.instanceId}
                      deal={deal}
                      activeInsightFilters={activeInsightFilters}
                      dealStats={dealStats?.data}
                      schema={objectSchema.data}
                      showNextMeetingflow={showNextMeetingflow}
                      onDirtyChanged={onDirtyChanged}
                      onSavingChanged={onSavingChanged}
                      stateEventEmitter={stateEmmitter}
                    />
                  ))}
                </tbody>
              ) : null}
            </table>

            {!deals.length ? (
              hubspotDealsLoading ? (
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: FontSizes.medium,
                    fontWeight: FontWeights.semibold,
                    padding: '2rem',
                    width: '100%',
                  }}
                >
                  <Spinner />
                </div>
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: FontSizes.medium,
                    fontWeight: FontWeights.semibold,
                    padding: '2rem',
                    width: '100%',
                  }}
                >
                  There are no deals owned by this user.
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>

      <PrimaryButton
        // @ts-ignore - Need JSX here for icon
        text={
          <>
            Pipeline Coach{' '}
            <FontIcon
              iconName="AISparkle"
              style={{ color: MEETINGFLOW_COLORS.teal }}
            />
          </>
        }
        onClick={() => {
          appInsights.trackEvent({
            name: `PIPELINE_COACH_BUTTON_CLICKED`,
            properties: {
              // If we're clicking the button, we're doing the opposite of the current state
              open: pipelineCoachVisible ? false : true,
            },
          });
          togglePipelineCoachVisible();
        }}
        className={coachButtonClass}
      />

      {pipelineDirty ? (
        <div className={saveBarClass}>
          <AsyncPrimaryButton
            style={{
              marginRight: '2rem',
            }}
            styles={{
              root: {
                backgroundColor: MEETINGFLOW_COLORS.orange,
                borderColor: MEETINGFLOW_COLORS.orange,
              },
              rootHovered: {
                backgroundColor: MEETINGFLOW_COLORS.orange,
                borderColor: MEETINGFLOW_COLORS.orange,
              },
            }}
            disabled={!pipelineDirty || pipelineSaving}
            text={pipelineSaving ? 'Saving' : 'Save All'}
            label={pipelineSaving ? 'Saving' : 'Save All'}
            onClick={async () => {
              setPipelineSaving(true);
              await Promise.allSettled(stateEmmitter.emit('save', undefined));
              setPipelineSaving(false);
              setPipelineDirty(
                stateEmmitter.emit('isDirty', undefined).some(Truthy),
              );
            }}
          />
        </div>
      ) : null}

      {pipelineCoachVisible ? (
        <div className={coachContainerClass}>
          <div className="coach-header">
            <span>Deal Pipeline Coach</span>
            <IconButton
              className="close-button"
              iconProps={{
                iconName: 'ChromeClose',
              }}
              onClick={togglePipelineCoachVisible}
            />
          </div>
          <div className="coach-content">
            <GPTOutputBox
              outputLabel="Pipeline Coach (Preview)"
              hideLabels
              organizationSlug={organizationSlug!}
              surfaceName="Pipeline Coach"
              defaultModel="gpt-4"
              disabled={!coachPrompt}
              defaultSystemPrompt={`You are a software system designed to analyze HubSpot sales pipelines as if you are an expert and insightful sales manager. You're always very brief and to the point, yet warm and often a little humorous. The date is currently ${DateTime.now().toISODate()}.`}
              defaultPrompt={coachPrompt}
              generateImmediately
              displayGPTControls={DISPLAY_GPT_CONTROLS}
              regenerateOnPromptChange
              maxTokens={512}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
