import {
  FontIcon,
  FontSizes,
  Link,
  mergeStyleSets,
  NeutralColors,
  Persona,
  PersonaCoin,
  PersonaSize,
} from '@fluentui/react';
import {
  GPTChatMessage,
  ResponseRating,
} from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks, {
  Options as RehypeExternalLinksOptions,
} from 'rehype-external-links';
import remarkGfm from 'remark-gfm';
import { useMemo } from 'react';
import toast from 'react-hot-toast';

type ChatMessageProps = {
  className?: string;
  id?: number;
  userName?: string;
  role: GPTChatMessage['role'];
  displayText?: string;
  content: string;
  rating?: ResponseRating;
  allowCopy?: boolean;
  allowFeedback?: boolean;
  display?: boolean;
  error?: boolean;
  onUpvote?: () => void | Promise<unknown>;
  onDownvote?: () => void | Promise<unknown>;
  onRetry?: () => void | Promise<unknown>;
};

export const ChatMessage = ({
  className,
  id,
  userName,
  role,
  displayText,
  content,
  rating,
  allowCopy,
  allowFeedback,
  display,
  error,
  onUpvote,
  onDownvote,
  onRetry,
}: ChatMessageProps) => {
  const { isDark } = useLightOrDarkMode();

  const label = useMemo(() => {
    if (role === 'user') {
      return <PersonaCoin text={userName || 'You'} size={PersonaSize.size24} />;
    }
    return (
      <PersonaCoin
        text="Meetingflow AI"
        styles={{
          initials: {
            color: 'white',
          },
          imageArea: {},
          coin: {},
        }}
        onRenderInitials={() => (
          <FontIcon
            iconName={'ChatBot'}
            style={{
              fontSize: FontSizes.mediumPlus,
            }}
          />
        )}
        size={PersonaSize.size24}
      />
    );
  }, [role, userName]);

  const styles = mergeStyleSets({
    message: {
      transition: '.3s ease-in-out all',
      clear: 'both',
      margin: '00',
      position: 'relative',
      boxSizing: 'border-box',
      maxWidth: '85%',

      display: 'flex',
      flexDirection: 'row',
      gap: '.5rem',

      '.message-label': {
        marginTop: '.25rem',
      },
      '.message-content': {
        border: `1px solid ${isDark ? NeutralColors.gray160 : NeutralColors.gray30}`,
        borderRadius: '.5rem',
        padding: '.5rem 1rem',

        '*': {
          margin: '0 0 .5rem 0',
          color: isDark ? NeutralColors.gray30 : NeutralColors.gray200,

          ':only-child, :last-child': {
            margin: 0,
          },
        },
      },

      '.message-error-retry button': {
        backgroundColor: isDark ? NeutralColors.gray160 : NeutralColors.gray20,
        padding: '.25rem .5rem',
        borderRadius: '.25rem',
        fontSize: FontSizes.xSmall,
        transition: '.3s ease-in-out all',
        cursor: 'pointer',
        color: isDark ? NeutralColors.gray30 : NeutralColors.gray150,

        ':hover': {
          textDecoration: 'none',
          backgroundColor: isDark
            ? NeutralColors.gray130
            : NeutralColors.gray40,
        },
      },

      '.message-actions': {
        display: 'flex',
        flexDirection: 'row',
        gap: '.5rem',
        opacity: 0,
        position: 'relative',
        top: '.5rem',
        transition: '.3s ease-in-out all',
        fontSize: FontSizes.small,

        '.feedback': {
          display: 'flex',
          gap: '.25rem',
        },
      },

      ':hover': {
        '.message-actions': {
          opacity: 1,
          transition: '.3s ease-in-out all',

          '.copy-button': {
            cursor: 'pointer',
            color: isDark ? NeutralColors.gray130 : NeutralColors.gray120,
            transition: '.1s ease-in-out all',

            ':hover': {
              color: isDark ? MEETINGFLOW_COLORS.white : NeutralColors.gray160,
              opacity: 1,
            },
          },

          '.feedback': {
            display: 'flex',
            gap: '.25rem',
            '.feedback-rating-button': {
              color: isDark ? NeutralColors.gray130 : NeutralColors.gray120,
              cursor: 'pointer',
              transition: '.1s ease-in-out all',

              ':hover': {
                color: isDark
                  ? MEETINGFLOW_COLORS.white
                  : NeutralColors.gray160,
                opacity: 1,
              },
            },
          },
        },
      },
    },

    ['user-message']: {
      textAlign: 'right',
      alignSelf: 'flex-end',
      flexDirection: 'row-reverse',
    },

    ['assistant-message']: {
      maxWidth: '100%',

      '.message-content': {
        paddingRight: '2.25rem',
      },

      '.ms-Persona-initials': {
        backgroundColor: '#920061',
      },
    },

    ['system-message']: {},

    error: {},
  });

  if (!display) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.message,
        styles[`${role}-message`],
        {
          [styles.error]: error,
        },
        className,
      )}
    >
      <div className="message-label">{label}</div>
      <div className="message-content">
        <ReactMarkdown
          rehypePlugins={[
            [
              rehypeExternalLinks,
              {
                target: '_blank',
                rel: ['noopener', 'nofollow', 'noreferrer'],
              } satisfies RehypeExternalLinksOptions,
            ],
          ]}
          remarkPlugins={[remarkGfm]}
        >
          {displayText || content}
        </ReactMarkdown>
        {error ? (
          <div className="message-error-retry">
            <Link onClick={onRetry}>Try again</Link>
          </div>
        ) : null}
      </div>
      <div className="message-actions">
        {allowCopy && role !== 'user' && !error && navigator.clipboard ? (
          <FontIcon
            iconName={'Copy'}
            onClick={() => {
              navigator.clipboard.writeText(content);
              toast.success(`Copied message to the clipboard.`);
            }}
            className={'copy-button'}
            title={`Copy message to clipboard`}
          />
        ) : null}
        {allowFeedback && role === 'assistant' && id && !error ? (
          <div className="feedback">
            <FontIcon
              iconName={rating === 'GOOD_RESPONSE' ? 'LikeSolid' : 'Like'}
              className="feedback-rating-button"
              title="Was this message helpful? Thumbs Up"
              onClick={onUpvote}
            />
            <FontIcon
              iconName={rating === 'BAD_RESPONSE' ? 'DislikeSolid' : 'Dislike'}
              className="feedback-rating-button"
              title="Was this message helpful? Thumbs Down"
              onClick={onDownvote}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
