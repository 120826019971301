import { useAuth0 } from '@auth0/auth0-react';
import { mergeStyles } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { Company, Contact } from '@meetingflow/common/Api/data-contracts';
import { useQuery } from 'react-query';
import { HubSpotPlanContext } from '../../..//Models/HubSpot/HubSpotPlanContext';
import { SalesforcePlanContext } from '../../..//Models/Salesforce/SalesforcePlanContext';
import { PresenceState } from '../../..//types/MeetingPlanDocument';
import useBreakpoints from '../../../Hooks/useBreakpoints';
import { OrganizationContactsQuery } from '../../../QueryNames';
import { ContactsApiClient } from '../../../Services/NetworkCommon';
import {
  CompanyCard,
  ContactCard,
} from '../../MeetingPlans/MeetingPlanAttendees';

const NUM_ATTENDEES_FOR_FULL_WIDTH_LAYOUT = 5;

interface CompanyContactsProps {
  organizationSlug: string;
  salesforcePlanContext?: SalesforcePlanContext;
  hubspotPlanContext?: HubSpotPlanContext;
  domain: string;
  internalDomains: string[];
  company: Company;
  onContactClick?: (c: Contact['id']) => void;
  onContactInviteClick?: (c: Contact) => void;
  onCompanyClick?: (companyId: Company['id']) => void;
  companyMaxHeight?: string;
  displayContactNotes?: boolean;
  allowEditingContactNotes?: boolean;
  contactNotesEditableBackgroundColor?: string;
  smallWidthLayout?: boolean;
  rowGap?: string;
  displayAsBoxes?: boolean;
  showAttendeeCount?: boolean;
  soloCompany?: boolean;
  contactCardBackgroundColor?: string;
  showAvatars?: boolean;
  awarenessStates?: Map<number, PresenceState>;
  showEmailDomains?: boolean;
  hideCompanyHeader?: boolean;
  allowEditAttendees?: boolean;
}

const CompanyContacts = ({
  organizationSlug,

  salesforcePlanContext,
  hubspotPlanContext,
  internalDomains,
  domain,
  company,

  onContactClick,
  onContactInviteClick,
  onCompanyClick,

  companyMaxHeight,
  displayContactNotes = false,

  rowGap,
  displayAsBoxes,
  showAttendeeCount = false,
  soloCompany = true,
  allowEditingContactNotes = true,
  contactNotesEditableBackgroundColor,
  contactCardBackgroundColor,
  showAvatars,
  awarenessStates,
  showEmailDomains,
  hideCompanyHeader,
  allowEditAttendees,
}: CompanyContactsProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const breakpoints = useBreakpoints();

  const ROW_GAP_OFFSET = '.25rem';

  const { data: contactsData } = useQuery(
    OrganizationContactsQuery(
      organizationSlug!,
      undefined,
      undefined,
      undefined,
      company.domains.map((d) => d.domain),
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return ContactsApiClient.listContacts(
        {
          organizationSlug: organizationSlug!,
          hasPlans: true,
          domains: company.domains.map((d) => d.domain),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  if (!contactsData?.data) {
    return null;
  }

  const panelVisible = true;
  const smallWidthLayout = false;

  const companyAttendeeGroupClass = mergeStyles({
    position: 'relative',
    flexGrow: 1,
    boxSizing: 'border-box',
    width:
      smallWidthLayout ||
      contactsData?.data.length >= NUM_ATTENDEES_FOR_FULL_WIDTH_LAYOUT - 1
        ? `calc(100%)`
        : (breakpoints.xxl && !panelVisible) ||
          (breakpoints.xxxl && panelVisible)
        ? `calc(20% - ${ROW_GAP_OFFSET})`
        : (breakpoints.xl && !panelVisible) || (breakpoints.xxl && panelVisible)
        ? `calc(25% - ${ROW_GAP_OFFSET})`
        : (breakpoints.lg && !panelVisible) || (breakpoints.xl && panelVisible)
        ? `calc(33% - ${ROW_GAP_OFFSET})`
        : (breakpoints.md && !panelVisible) || (breakpoints.lg && panelVisible)
        ? `calc(50% - ${ROW_GAP_OFFSET})`
        : breakpoints.md && !panelVisible
        ? `calc(50% - ${ROW_GAP_OFFSET})`
        : `calc(100%)`,
    borderRadius: '.25rem',
    border: displayAsBoxes ? `1px solid ${NeutralColors.gray40}` : undefined,
    backgroundColor: 'transparent',
    transition: '.3s ease-in-out all',
    '&:only-child': {
      width: '100% !important',
      maxWidth: '100% !important',
    },
  });

  return (
    <div className={companyAttendeeGroupClass}>
      {!hideCompanyHeader ? (
        <CompanyCard
          organizationSlug={organizationSlug}
          company={company}
          salesforcePlanContext={salesforcePlanContext}
          hubspotPlanContext={hubspotPlanContext}
          onClick={onCompanyClick}
          attendeeListHeaderStyle
          emailAllAddressList={contactsData?.data?.map((c) => {
            return {
              email: c?.email || '',
              name: c?.name || '',
            };
          })}
          emailAllSubject={`An email to all from ${company.name}`}
        />
      ) : null}

      <div
        style={{
          maxHeight: companyMaxHeight ? companyMaxHeight : undefined,
          // overflowY: companyMaxHeight ? 'auto' : undefined,
          transition: '.3s ease-in-out all',
          height: 'auto',
          width: 'auto',
          display: soloCompany ? 'flex' : undefined,
          flexWrap: soloCompany ? 'wrap' : undefined,
        }}
      >
        {contactsData?.data?.map((contact, idx) => {
          return (
            <ContactCard
              organizationSlug={organizationSlug}
              key={`contact-${contact.id}`}
              contact={contact}
              company={company}
              salesforcePlanContext={salesforcePlanContext}
              hubspotPlanContext={hubspotPlanContext}
              domain={domain}
              internalDomains={internalDomains}
              displayContactNotes={displayContactNotes}
              onContactClick={onContactClick}
              onContactInviteClick={onContactInviteClick}
              contactNotesEditableBackgroundColor={
                contactNotesEditableBackgroundColor
              }
              allowEditingContactNotes={allowEditingContactNotes}
              width={
                smallWidthLayout
                  ? '100%'
                  : !smallWidthLayout &&
                    soloCompany &&
                    (breakpoints.lg || (breakpoints.md && !panelVisible))
                  ? '32%'
                  : '100%'
              }
              backgroundColor={contactCardBackgroundColor}
              showAvatar={showAvatars}
              showEmailDomain={showEmailDomains}
              hideBottomBorder={idx === contactsData?.data?.length - 1}
              allowEditAttendees={allowEditAttendees}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CompanyContacts;
