export const ChevronDownIconProps = { iconName: 'ChevronDown' };

export const ChromeCloseIconProps = { iconName: 'ChromeClose' };

export const CheckMarkIconProps = { iconName: 'CheckMark' };

export const ChevronRightMedIconProps = { iconName: 'ChevronRightMed' };

export const ZoomIconProps = { iconName: 'Zoom' };

export const DownloadIconProps = { iconName: 'Download' };

export const DeleteIconProps = { iconName: 'Delete' };

export const AddArtifactIconProps = { iconName: 'AddArtifact' };

export const UpdateArtifactIconProps = { iconName: 'UpdateArtifact' };

export const BackIconProps = { iconName: 'Back' };
