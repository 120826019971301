import { useAuth0 } from '@auth0/auth0-react';
import { AsyncLink } from '../HOC/AsyncLink';
import { FontSizes, FontWeights, NeutralColors } from '@fluentui/theme';
import { FontIcon, mergeStyles } from '@fluentui/react';
import classNames from 'classnames';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { SummarizeContentPayload } from '@meetingflow/common/Api/data-contracts';
import { useOrganization } from '../../Hooks/useOrganization';
import toast from 'react-hot-toast';

interface GPTGenerateButtonProps {
  organizationSlug: string;
  model?: SummarizeContentPayload['model'];
  label?: string;
  generatingLabel?: string;
  regenerateLabel?: string;
  disabled?: boolean;
  systemPrompt: string;
  prompt: string;
  onReset?: () => void;
  onToken?: (output: string) => void;
  onTokenValue?: (token: string) => void;
  onGenerating?: () => void;
  onSuccess?: (output: string) => void;
  onError?: (err: unknown) => void;
  onGeneratingChanged?: (isGenerating: boolean) => void;
  surfaceName: string;
  generateImmediately?: boolean;
  regenerateOnSystemPromptChange?: boolean;
  regenerateOnPromptChange?: boolean;
  maxTokens?: number;
}

const GPTGenerateButton = ({
  organizationSlug,
  label = 'Generate',
  regenerateLabel = 'Regenerate',
  disabled = false,
  model,
  systemPrompt,
  prompt,
  onReset,
  onToken,
  onTokenValue,
  onSuccess,
  onError,
  onGenerating,
  onGeneratingChanged,
  surfaceName,
  generateImmediately,
  regenerateOnPromptChange,
  regenerateOnSystemPromptChange,
  maxTokens,
}: GPTGenerateButtonProps) => {
  const abortController = useRef<AbortController>();
  const { getAccessTokenSilently } = useAuth0();
  const [generating, setGeneratingState] = useState<boolean>(false);
  const [hasGenerated, setHasGenerated] = useState<boolean>(false);
  const { isDark } = useLightOrDarkMode();
  const { hasEntitlement } = useOrganization();

  const setGenerating = useCallback(
    (isGenerating: boolean) => {
      onGeneratingChanged?.(isGenerating);
      setGeneratingState(isGenerating);
    },
    [onGeneratingChanged],
  );

  const gptGenerateButtonClass = mergeStyles({
    display: 'inline-block',
    fontSize: FontSizes.small,
    fontWeight: FontWeights.semibold,
    height: '1.5rem',
    lineHeight: '1.5rem',

    ':disabled': {
      opacity: '.25',
      'i, span': {
        color: isDark ? NeutralColors.gray80 : NeutralColors.gray160,
      },
    },

    i: {
      float: 'right',
      display: 'inline-block',
      fontSize: FontSizes.large,
      color: MEETINGFLOW_COLORS.teal,
      fontWeight: FontWeights.regular,
      height: '1.5rem',
      width: '1.5rem',
      lineHeight: '1.5rem',
      marginLeft: '.25rem',
    },

    span: {
      display: 'inline-block',
      height: '1.5rem',
      lineHeight: '1.5rem',
      position: 'relative',
      top: '1px',
      color: isDark
        ? MEETINGFLOW_COLORS.purpleSecondary
        : MEETINGFLOW_COLORS.purplePrimary,
    },
  });

  const generate = async () => {
    abortController.current?.abort();

    const newController = new AbortController();
    abortController.current = newController;

    setGenerating(true);
    onReset?.();
    onGenerating?.();

    console.info(`Generating with model ${model}`);
    console.info(`System Prompt:`);
    console.info('===');
    console.info(systemPrompt);
    console.info('===');
    console.info(`Prompt:`);
    console.info('===');
    console.info(prompt);
    console.info('===');

    try {
      const token = await getAccessTokenSilently();
      const result = await new Promise<string>((resolve, reject) => {
        fetch(`/api/organization/${organizationSlug}/stream-summary`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'text/event-stream',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            model,
            systemPrompt,
            prompt,
            maxTokens,
          }),
          signal: newController.signal,
        })
          .then(async (res) => {
            if (res.status !== 200) {
              throw new Error(`Failed to start sumamrization`);
            }

            let generated = '';

            const reader = res.body
              ?.pipeThrough(new TextDecoderStream())
              .getReader();

            if (!reader) {
              throw new Error('Unable to get reader');
            }
            // eslint-disable-next-line no-constant-condition
            while (true) {
              // eslint-disable-next-line no-await-in-loop
              const read = await reader.read();
              if (read.done) {
                break;
              }
              const token = read.value;
              generated += token;
              onTokenValue?.(token);
              onToken?.(generated);
            }
            resolve(generated);
          })
          .catch((err) => reject(err));
      });

      if (result) {
        setHasGenerated(true);
      }
      onSuccess?.(result);
    } catch (err: unknown) {
      if (err instanceof DOMException && err.name === 'AbortError') {
        console.info('Stream summary cancelled');
        return;
      } else {
        toast.error('Something went wrong generating the insights');
      }
      console.error(typeof err, err);
      onError?.(err);
    } finally {
      if (abortController.current === newController) {
        setGenerating(false);
        abortController.current = undefined;
      }
    }
  };

  useEffect(() => {
    if (
      hasEntitlement('CHAT_GPT') &&
      !disabled &&
      !!systemPrompt &&
      !!prompt &&
      generateImmediately
    ) {
      generate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      hasEntitlement('CHAT_GPT') &&
      !disabled &&
      !!systemPrompt &&
      !!prompt &&
      !!regenerateOnSystemPromptChange
    ) {
      generate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemPrompt]);

  useEffect(() => {
    if (
      hasEntitlement('CHAT_GPT') &&
      !disabled &&
      !!systemPrompt &&
      !!prompt &&
      !!regenerateOnPromptChange
    ) {
      generate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prompt]);

  return (
    <AsyncLink
      className={classNames(gptGenerateButtonClass, 'generate-button')}
      disabled={disabled}
      onClick={
        generating
          ? async () => {
              abortController.current?.abort();
              abortController.current = undefined;
            }
          : generate
      }
    >
      <FontIcon iconName="AISparkle" />
      <span>
        {generating ? 'Cancel' : hasGenerated ? regenerateLabel : label}
      </span>
    </AsyncLink>
  );
};

export default GPTGenerateButton;
