import {
  FontSizes,
  FontWeights,
  PrimaryButton,
  Text,
  mergeStyles,
} from '@fluentui/react';
import { useNavigate, useParams } from 'react-router';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import { OrganizationSlugRouteParams } from '../../../../types/RouteParams';
import { TemplateList } from './TemplateList';
import { TemplatePreview } from './TemplatePreview';

const TemplateEditor = () => {
  const { objectId, organizationSlug } = useParams<
    OrganizationSlugRouteParams & {
      objectId?: string;
    }
  >();
  const navigate = useNavigate();

  const templateEditorClass = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    columnGap: '1rem',
    position: 'relative',
    maxHeight: '100%',
    margin: '1rem 0 0 0',
    padding: '0 1rem',

    '.list': {
      flexBasis: '33%',
      maxWidth: '300px',
      minWidth: '150px',
      position: 'relative',
      overflowY: 'auto',
      maxHeight: '100%',

      '> div': {
        marginTop: '1rem',
      },
    },

    '.preview': {
      flexBasis: '100%',
      position: 'relative',

      '.editor-toolbar': {
        paddingLeft: '0',
        left: 0,
        top: 0,
        width: '100%',
      },

      '[role=textbox]': {
        // paddingLeft: '.5rem',
      },
    },
  });

  const sectionHeaderClass = mergeStyles({
    margin: '0 0 .25rem 0',
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semibold,
    color: MEETINGFLOW_COLORS.teal,
    position: 'relative',
    display: 'block',
    lineHeight: '2rem',
  });

  return (
    <div className={templateEditorClass}>
      <div className="list">
        <Text className={sectionHeaderClass}>Templates</Text>
        <PrimaryButton
          onClick={() => {
            navigate(`/organization/${organizationSlug}/library/templates/new`);
          }}
          styles={{
            root: {
              position: 'absolute',
              top: '0',
              right: 0,
            },
          }}
        >
          New
        </PrimaryButton>
        <TemplateList showOnCard={false} />
      </div>
      <div className="preview">
        {!!objectId ? <TemplatePreview key={objectId} /> : null}
      </div>
    </div>
  );
};

export default TemplateEditor;
