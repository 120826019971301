import {
  FontIcon,
  FontSizes,
  FontWeights,
  Link,
  mergeStyles,
  NeutralColors,
  PersonaSize,
  Text,
} from '@fluentui/react';
import { Contact } from '@meetingflow/common/Api/data-contracts';
import randomColor from 'randomcolor';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { Card } from '../../Card';
import { ContactCard } from '../../MeetingPlans/MeetingPlanAttendees';
import { ContactNoteEditor } from '../../MeetingPlans/SidePanels/ContactNoteEditor';
import { ContactRecentPlansList } from '../../MeetingPlans/SidePanels/ContactRecentPlansList';
import ContactResourcesDoc from './ContactResourcesDoc';

interface ContactDetailProps {
  organizationSlug: string;
  contact: Pick<
    Contact,
    'id' | 'name' | 'email' | 'emailDomain' | 'avatarUrl' | 'avatarFileUrl'
  >;
  onContactClick: (c: Contact['id']) => void;
}

const ContactDetail = ({
  organizationSlug,
  contact,
  onContactClick,
}: ContactDetailProps) => {
  const { isDark } = useLightOrDarkMode();
  const navigate = useNavigate();

  const contactId = contact.id;

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  const wrapperStyles = mergeStyles({
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 5.25rem)',
    padding: '0',
    backgroundColor: isDark
      ? NeutralColors.gray220
      : MEETINGFLOW_COLORS.purpleUltraSuperLight,
    borderLeft: `.25rem solid ${
      isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleUltraLight
    }`,
    overflowY: 'hidden',
  });

  const primaryContentStyles = mergeStyles({
    width: `calc(100% - 25rem)`,
    padding: '.5rem',
    overflowX: 'hidden',
    overflowY: 'auto',
  });

  const sidebarStyles = mergeStyles({
    width: '25rem',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '.5rem',
    borderLeft: `.25rem solid ${
      isDark ? NeutralColors.gray180 : MEETINGFLOW_COLORS.purpleUltraLight
    }`,

    // Style overrides for where companies/contacts differs from Meetingflow side panels
    '.base-side-panel-content': {
      height: 'unset !important',
      minHeight: 'unset !important',
    },
  });

  const sectionHeaderClass = mergeStyles({
    ':first-child': {
      margin: '.5rem 0 .5rem 0',
    },

    margin: '1rem 0 .5rem 0 ',
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semibold,
    color: MEETINGFLOW_COLORS.teal,
    position: 'relative',
    display: 'block',
  });

  return (
    <div className={wrapperStyles}>
      <div className={primaryContentStyles}>
        <ContactCard
          key={`contact-card-${contactId}`}
          organizationSlug={organizationSlug}
          contact={contact}
          domain={contact.emailDomain}
          personaSize={PersonaSize.size56}
          socialIconSize={24}
          hideBottomBorder
        />
        <div style={{ marginTop: '.5rem' }}>
          <Card
            key={`contact-card-wrapper-${contactId}`}
            smallHeader
            allowCollapsing
            title={`Notes for ${contact.name || contact.email}`}
            contentContainerPadding={'.25rem'}
            noBottomPadding
          >
            <ContactNoteEditor
              contact={contact}
              organizationSlug={organizationSlug}
              color={color}
              alwaysEditing
              fullWidth
            />
            <ContactResourcesDoc
              contact={contact}
              organizationSlug={organizationSlug}
            />
          </Card>
        </div>
      </div>
      <div className={sidebarStyles}>
        <Text block className={sectionHeaderClass}>
          Recent Meetingflows
          <Link
            style={{
              display: 'block',
              float: 'right',
              textAlign: 'right',
              fontWeight: FontWeights.semilight,
              fontSize: FontSizes.small,
              marginRight: 0,
              transition: '.3s ease-in-out all',
            }}
            onClick={() =>
              navigate(
                `/organization/${organizationSlug}/library/plans?attendees=${contactId}`,
              )
            }
          >
            All Meetingflows
            <FontIcon
              style={{
                display: 'inline-block',
                position: 'relative',
                top: '.15rem',
                marginLeft: '.25rem',
              }}
              iconName="PageRight"
            />
          </Link>
        </Text>
        <div>
          <ContactRecentPlansList
            key={`contact-recent-plans-${contactId}`}
            organizationSlug={organizationSlug}
            contact={contact}
            maxCount={10}
            onContactClick={onContactClick}
            color={color}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactDetail;
