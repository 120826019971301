import { useAuth0 } from '@auth0/auth0-react';
import {
  Stack,
  TextField,
  DefaultButton,
  Text,
  useTheme,
  IStackItemStyles,
  PrimaryButton,
} from '@fluentui/react';
import { FontWeights } from '@fluentui/theme';
import { DEFAULT_STACK_TOKENS } from '../../../../Helpers/Layout';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { OrganizationsApiClient } from '../../../../Services/NetworkCommon';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import {
  settingsIndentedControlClass,
  settingsPrimaryButtonClass,
  settingsRootStackStyles,
  settingsSectionClass,
  settingsSubHeadClass,
} from '../SettingsPageLayout';
import { MEETINGFLOW_COLORS } from '../../../../Themes/Themes';
import useBreakpoints from '../../../../Hooks/useBreakpoints';
import toast from 'react-hot-toast';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useExportOrganizationDialog } from '../../../../Hooks/Modals/useExportOrganizationDialog';
import { AsyncPrimaryButton } from '../../../../Components/HOC/AsyncButton';

export const DeleteOrganizationTabContent = () => {
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();
  const client = useQueryClient();
  const theme = useTheme();

  const { name: orgName, slug: orgSlug, isAdmin } = useOrganization();

  const breakpoints = useBreakpoints();
  const [deleteConfirmValue, setDeleteConfirmValue] = useState<
    string | undefined
  >();

  const appInsights = useAppInsightsContext();

  const {
    createDeferred: createExportOrganizationDeferred,
    dialog: ExportOrganizationDialog,
  } = useExportOrganizationDialog({
    organizationSlug: orgSlug!,
  });

  const halfWidthStackItemStyles = {
    root: {
      maxWidth: breakpoints.md ? 'calc(50% - 1rem)' : 'calc(100% - 1rem)',
    },
  } as IStackItemStyles;

  return (
    <>
      <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
        <div className={settingsSectionClass}>
          <h2 className={settingsSubHeadClass}>Export Workspace Content</h2>

          <div className={settingsIndentedControlClass}>
            <Stack.Item grow={1} styles={halfWidthStackItemStyles}>
              <Text>
                Export all Meetingflows, call transcripts, attachments, and AI
                notes from the {orgName || orgSlug} workspace.
              </Text>
            </Stack.Item>

            <Stack.Item>
              <PrimaryButton
                className={settingsPrimaryButtonClass}
                disabled={!isAdmin}
                text="Export"
                onClick={async () => {
                  if (!orgSlug) {
                    return;
                  }
                  try {
                    const token = await getAccessTokenSilently();

                    const headers = new Headers();
                    headers.append('Authorization', `Bearer ${token}`);
                    headers.append(
                      'X-Tz',
                      Intl.DateTimeFormat().resolvedOptions().timeZone,
                    );
                    const fetchPromise = fetch(
                      `/api/organization/${orgSlug}/export`,
                      {
                        method: 'GET',
                        headers,
                      },
                    );

                    const response = await fetchPromise;

                    if (response.ok) {
                      const blobPromise = response.blob();

                      toast.promise(blobPromise, {
                        loading:
                          'Exporting workspace content, this might take a while...',
                        success: 'Successfully exported workspace content',
                        error:
                          'Something went wrong exporting workspace content',
                      });

                      const downloadLink = document.createElement('a');
                      downloadLink.href = window.URL.createObjectURL(
                        await blobPromise,
                      );
                      downloadLink.download = `${orgName || orgSlug}.zip`;
                      document.body.appendChild(downloadLink);
                      downloadLink.click();
                      document.body.removeChild(downloadLink);
                      appInsights.trackEvent({
                        name: 'EXPORT_WORKSPACE',
                        properties: {
                          organizationSlug: orgSlug,
                        },
                      });
                    }
                  } catch (e: unknown) {
                    toast.error('Failed to export Meetingflow to docx');
                    console.error(e);
                  }
                }}
              />
              <AsyncPrimaryButton
                className={settingsPrimaryButtonClass}
                style={{ marginLeft: '0.5rem' }}
                disabled={!isAdmin}
                text="Export With Media"
                onClick={() => createExportOrganizationDeferred().promise}
              />
            </Stack.Item>
          </div>
        </div>
        <div className={settingsSectionClass}>
          <Text
            style={{
              fontWeight: FontWeights.semibold,
              margin: '1rem 0 0 0',
              backgroundColor: MEETINGFLOW_COLORS.red,
              padding: '.5rem',
              borderRadius: '.25rem',
              color: MEETINGFLOW_COLORS.white,
              textAlign: 'center',
              display: 'block',
              marginBottom: '1rem',
            }}
          >
            Warning! This action is permanent and will delete all data
            associated with the workspace.
          </Text>

          <h2 className={settingsSubHeadClass}>Delete Workspace</h2>

          <div className={settingsIndentedControlClass}>
            <Stack.Item grow={1} styles={halfWidthStackItemStyles}>
              <TextField
                label={`To delete the "${orgName}" workspace, type the workspace's identifier ("${orgSlug}") for confirmation.`}
                value={deleteConfirmValue}
                onChange={(_evt, value) => setDeleteConfirmValue(value)}
              />
            </Stack.Item>

            <Stack.Item>
              <DefaultButton
                className={settingsPrimaryButtonClass}
                disabled={!isAdmin || deleteConfirmValue !== orgSlug}
                text="Delete"
                onClick={async () => {
                  const token = await getAccessTokenSilently();
                  const result =
                    await OrganizationsApiClient.deleteOrganization(orgSlug!, {
                      headers: { Authorization: `Bearer ${token}` },
                    });
                  if (result.status === 204) {
                    navigate('/');
                    client.invalidateQueries();
                  }
                }}
              />
            </Stack.Item>
          </div>
        </div>
      </Stack>
      {ExportOrganizationDialog}
    </>
  );
};
