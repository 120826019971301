import { DealRoomAudioArtifact } from '@meetingflow/common/Api/data-contracts';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';

export type AudioArtifactCardProps = BaseArtifactCardProps &
  Pick<DealRoomAudioArtifact, 'fileName' | 'mimeType'>;

export const AudioArtifactCard = ({
  fileName,
  orgSlug,
  dealRoomId,
  mimeType,
  ...rest
}: AudioArtifactCardProps) => {
  return (
    <BaseArtifactCard
      className="audio-artifact"
      orgSlug={orgSlug}
      dealRoomId={dealRoomId}
      {...rest}
    >
      <ArtifactIconPreview
        type="AUDIO"
        fileExtension={fileName.split('.').pop()}
        mimeType={mimeType}
      />
    </BaseArtifactCard>
  );
};
