import { BaseSidePanel } from './BaseSidePanel';
import { Meetingflow, Task } from '@meetingflow/common/Api/data-contracts';
import {
  MeetingPlanEditDetailsForm,
  MeetingPlanEditDetailsFormData,
} from '../Dialogs/EditDetailsForm';
import { EMPTY_ARRAY } from '../../../Constants';
import { Y } from '@syncedstore/core';
import {
  Checkbox,
  ComboBox,
  FontSizes,
  FontWeights,
  IComboBoxOption,
  NeutralColors,
  Text,
} from '@fluentui/react';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { SlackChannelsQuery } from '../../../QueryNames';
import { SlackChannel } from './SlackSidePanel';
import { ApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useExternalServiceConfigurations } from '../../../Hooks/useExternalServiceConfigurations';
import { orderBy } from 'lodash';
import toast from 'react-hot-toast';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { hasContent } from '../../../Helpers/yjsHelpers';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useLocalStorageState } from '../../../Hooks/useLocalStorageState';

export interface SummarySidePanelProps {
  meetingPlan: Meetingflow;
  organizationSlug: string;
  notesYArray: Y.XmlText;
  tasks: Task[];
  internalDomains: string[];
  allowShareToSlack?: boolean;
  initialSummary?: string;
  onBack?: () => void;
  onClose: () => void;
  onSaveSummary: () => void;
}
export const SummarySidePanel = ({
  meetingPlan,
  organizationSlug,
  notesYArray,
  tasks,
  internalDomains,
  initialSummary,
  allowShareToSlack = true,
  onBack,
  onClose,
  onSaveSummary,
}: SummarySidePanelProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const appInsights = useAppInsightsContext();

  const { hasToken } = useExternalServiceConfigurations({
    app: 'SLACK',
    withToken: true,
  });

  const { isDark } = useLightOrDarkMode();

  const [shareToSlack, setShareToSlack] = useState<boolean>(false);

  const [slackChannelSearchTerm, setSlackChannelSearchTerm] =
    useState<string>('');

  const [slackChannel, setSlackChannel] = useLocalStorageState<string>(
    `SLACK_SUMMARY_CHANNEL_${organizationSlug}`,
    '',
  );

  const [includeCallInsights, setIncludeCallInsights] =
    useLocalStorageState<boolean>('slack-include-call-insights', true);

  const {
    data: channels,
    // isLoading: channelsLoading,
    // refetch: refetchChannels,
  } = useQuery(
    SlackChannelsQuery(organizationSlug),
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<SlackChannel[]>(
        `/organization/${organizationSlug}/external/slack/channels`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    { cacheTime: 0, enabled: hasToken() },
  );

  const channelOptions: IComboBoxOption[] = useMemo(
    () =>
      channels?.data
        ? !!slackChannelSearchTerm
          ? orderBy(
              channels.data.filter((c) =>
                c.name.startsWith(slackChannelSearchTerm),
              ),
              'name',
            ).map((c) => {
              return {
                key: c.name,
                text: c.name,
              };
            })
          : orderBy(channels.data, 'name').map((c) => {
              return {
                key: c.name,
                text: c.name,
              };
            })
        : slackChannel
          ? [{ key: slackChannel, text: slackChannel }]
          : [],
    [slackChannel, channels?.data, slackChannelSearchTerm],
  );

  const selectedChannel = useMemo(
    () => channels?.data?.find((c) => c.name === slackChannel),
    [slackChannel, channels?.data],
  );

  const handleSave = async (formValues: MeetingPlanEditDetailsFormData) => {
    if (shareToSlack && selectedChannel && formValues.summary) {
      try {
        const token = await getAccessTokenSilently();

        await toast.promise(
          ApiClient.post(
            `/organization/${organizationSlug}/external/slack/send-summary`,
            {
              message: formValues.summary,
              meetingPlanId: meetingPlan.id,
              channel: selectedChannel.name,
              includeCallInsights,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              validateStatus: (status) => [200, 201].includes(status),
            },
          ),
          {
            loading: 'Sharing to slack',
            success: () => {
              appInsights.trackEvent({
                name: 'SEND_SLACK_SUMMARY',
                properties: {
                  organizationSlug,
                  meetingPlanId: meetingPlan.id,
                },
              });

              return `Shared the summary with ${selectedChannel.name} via slack!`;
            },
            error: (err) => {
              return 'Something went wrong sharing the summary to slack, please try again';
            },
          },
        );
      } catch {}
    }

    onSaveSummary && onSaveSummary();
  };

  const slackJSX = (
    <>
      {allowShareToSlack && hasToken('SLACK') ? (
        <div
          style={{
            marginTop: '0',
            padding: '.25rem',
            backgroundColor: isDark
              ? NeutralColors.gray200
              : MEETINGFLOW_COLORS.purpleUltraLight,
            minHeight: '32px',
          }}
        >
          <Checkbox
            title="Slack"
            label={'Post to Slack Channel'}
            checked={shareToSlack}
            styles={{
              root: {
                display: 'inline-block',
                width: '15rem',
                marginTop: '.35rem',
              },
              label: {
                fontWeight: FontWeights.semibold,
                fontSize: FontSizes.small,
              },
              text: {
                fontSize: FontSizes.small,
              },
            }}
            onChange={(_e, checked) => setShareToSlack(!!checked)}
          />
          {shareToSlack ? (
            <div
              style={{
                marginTop: '1rem',
                marginLeft: '1.5rem',
                display: 'flex',
                flexDirection: 'row',
                gap: '.5rem',
              }}
            >
              <ComboBox
                errorMessage={
                  slackChannel &&
                  channels?.data !== undefined &&
                  !selectedChannel
                    ? 'Please select a valid channel'
                    : undefined
                }
                allowFreeform
                placeholder={'Select a channel...'}
                selectedKey={slackChannel || null}
                prefix="#"
                options={channelOptions}
                autoComplete={'on'}
                styles={{
                  label: {
                    fontSize: FontSizes.small,
                  },
                }}
                onInputValueChange={setSlackChannelSearchTerm}
                openOnKeyboardFocus
                calloutProps={{ alignTargetEdge: true }}
                onChange={(_e, o) => {
                  if (o) {
                    setSlackChannel(o.key.toString());
                  } else if (slackChannelSearchTerm) {
                    const matchingOption = channelOptions?.find(
                      (c) => c.key.toString() === slackChannelSearchTerm,
                    );
                    if (matchingOption) {
                      setSlackChannel(matchingOption.key.toString());
                    }
                  }
                }}
              />

              {meetingPlan?.callRecording?.transcriptAnalysis?.topics
                ?.length ? (
                <div style={{ paddingTop: '.375rem' }}>
                  <Checkbox
                    title="Include Call Insights"
                    label="Include Call Insights"
                    checked={includeCallInsights}
                    onChange={(_e, checked) =>
                      setIncludeCallInsights(!!checked)
                    }
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );

  return (
    <BaseSidePanel title="Summarize and Post" onBack={onBack} onClose={onClose}>
      <Text
        block
        style={{
          fontSize: FontSizes.small,
          color: NeutralColors.gray120,
        }}
      >
        <strong style={{ fontWeight: FontWeights.semibold }}>
          Summarizing your Meetingflow helps keep everyone on the same page
        </strong>{' '}
        — including your future self!{' '}
        {!meetingPlan?.textSummary
          ? hasContent(notesYArray)
            ? `We've asked GPT to create a draft of your Meetingflow summary. You can use it, edit it, or delete it entirely.`
            : `We will ask GPT to draft a summary of your Meetingflow for you once you've taken some notes!`
          : `A summary for this meeting was already created. You may update it, or ask GPT to generate a new summary.`}
      </Text>
      {meetingPlan ? (
        <>
          <MeetingPlanEditDetailsForm
            meetingPlan={meetingPlan}
            organizationSlug={organizationSlug}
            notesYArray={notesYArray}
            tasks={tasks || EMPTY_ARRAY}
            internalDomains={internalDomains}
            fields={['summary']}
            onSuccess={handleSave}
            submitButtonLabel={
              meetingPlan.textSummary
                ? `Update ${shareToSlack ? ' and Post' : ''} Summary`
                : `Save ${shareToSlack ? ' and Post' : ''} Summary`
            }
            insertBeforeButtons={slackJSX}
            hideLabels
            initialSummary={initialSummary}
          />
        </>
      ) : null}
    </BaseSidePanel>
  );
};
