import { FontWeights, NeutralColors, Text, mergeStyles } from '@fluentui/react';
import { inflect } from 'inflection';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { AsyncDefaultButton } from '../HOC/AsyncButton';

export type IntegrationTileProps = {
  configurationId: number;
  iconSrc: string;
  iconAlt: string;
  iconWidth?: string;
  iconHeight?: string;
  title: string;
  instanceId: string;
  userTokenCount?: number;
  hasToken?: boolean;
  showConfigure?: boolean;
  onRemove: () => Promise<unknown>;
  onConfigure?: () => Promise<unknown>;
};
export const ManageIntegrationTile = ({
  iconSrc,
  iconAlt,
  iconWidth,
  iconHeight,
  title,
  instanceId,
  userTokenCount,
  hasToken,
  showConfigure,
  onConfigure,
  onRemove,
}: IntegrationTileProps) => {
  const { isDark } = useLightOrDarkMode();

  const integrationTileClass = mergeStyles({
    display: 'grid',
    columnGap: '.5rem',
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: 'auto 1fr auto auto auto auto',
    gridTemplateAreas: `
    'icon title ws usercount modify remove'
    `,
    borderBottom: `1px solid ${
      isDark ? NeutralColors.gray170 : NeutralColors.gray20
    } `,
    paddingBottom: '.5rem',
    marginBottom: '.5rem',
    alignContent: 'center',

    '&:last-child': {
      borderBottom: 'none',
    },

    '.title': {
      display: 'block',
      fontWeight: FontWeights.semibold,
    },

    button: {
      marginTop: '.25rem',
    },
  });

  return (
    <div className={integrationTileClass}>
      <div style={{ gridArea: 'icon', marginTop: '.25rem' }}>
        <img
          src={iconSrc}
          width={iconWidth ?? '2rem'}
          height={iconHeight ?? '2rem'}
          alt={iconAlt}
        />
      </div>
      <div
        style={{
          gridArea: 'title',
          padding: '0 0 0 .75rem',
        }}
      >
        <Text className="title">{title}</Text>
        <Text>{instanceId}</Text>
      </div>
      <div style={{ gridArea: 'usercount' }}>
        <Text
          style={{
            lineHeight: '35px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {userTokenCount} current {inflect('user', userTokenCount || 0)}
        </Text>
      </div>
      {showConfigure && onConfigure ? (
        <div style={{ gridArea: 'modify' }}>
          <AsyncDefaultButton
            disabled={!hasToken}
            title={
              hasToken
                ? `Configure ${title}`
                : `You need to add a ${title} token to configure`
            }
            onClick={onConfigure}
          >
            Configure
          </AsyncDefaultButton>
        </div>
      ) : null}
      <div style={{ gridArea: 'remove' }}>
        <AsyncDefaultButton onClick={onRemove}>Remove</AsyncDefaultButton>
      </div>
    </div>
  );
};
