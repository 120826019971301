import { useAuth0 } from '@auth0/auth0-react';
import { DateRangeShortcut } from '@blueprintjs/datetime';
import { DateRange, DateRangeInput2 } from '@blueprintjs/datetime2';
import {
  FontIcon,
  FontWeights,
  IColumn,
  mergeStyles,
  NeutralColors,
  SelectionMode,
  ShimmeredDetailsList,
} from '@fluentui/react';
import { escapeCsvFormulas } from '@meetingflow/common/ObjectHelpers';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { EMPTY_ARRAY } from '../Constants';
import { isAxiosErrorResponse } from '../Helpers/AxiosHelpers';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';
import { useTitle } from '../Hooks/useTitle';
import { ApiClient } from '../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../Themes/Themes';
import { AsyncLink } from './HOC/AsyncLink';

type UsageSummaryResult = {
  minDate: Date;
  maxDate: Date;
  count: number;
  usage: {
    OrganizationId: number;
    OrganizationSlug: string;
    OrganizationName: string;
    CreatorDomain: string;
    DomainCount: number;
    UserLastSeen: Date;
    AdminCount: number;
    CreatorCount: number;
    CollaboratorCount: number;
    GuestCount: number;
    MeetingflowCount: number;
    InternalMeetingflowCount: number;
    ExternalMeetingflowCount: number;
    CallRecordingCount: number;
    InternalCallRecordingCount: number;
    ExternalCallRecordingCount: number;
    CallRecordingTimeHours: number;
  }[];
};

type Usage = UsageSummaryResult['usage'][0];

const DatePickerShortcuts = [
  {
    label: 'Last 30 Days',
    dateRange: [
      DateTime.now().minus({ days: 30 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Month to Date',
    dateRange: [
      DateTime.now().startOf('month').startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Year to Date',
    dateRange: [
      DateTime.now().startOf('year').startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Last Month',
    dateRange: [
      DateTime.now().minus({ months: 1 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Last 3 Months',
    dateRange: [
      DateTime.now().minus({ months: 3 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Last 6 Months',
    dateRange: [
      DateTime.now().minus({ months: 6 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
  {
    label: 'Last 12 Months',
    dateRange: [
      DateTime.now().minus({ months: 12 }).startOf('day').toJSDate(),
      DateTime.now().endOf('day').toJSDate(),
    ],
  },
] satisfies DateRangeShortcut[];

export const AdminUsageSummaryList = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();

  const navigate = useNavigate();

  const [dateRange, setDateRange] = useState<DateRange>([
    DateTime.now().minus({ days: 30 }).startOf('day').toJSDate(),
    DateTime.now().endOf('day').toJSDate(),
  ]);

  useTitle('Admin Usage Summary');

  const {
    data: organizationUsage,
    isLoading: organizationUsageLoading,
    refetch: refetchOrganizationUsage,
  } = useQuery(
    `USAGE_SUMMARY_${dateRange[0]?.toISOString()}_${dateRange[1]?.toISOString()}`,
    async () => {
      const token = await getAccessTokenSilently();
      return ApiClient.get<UsageSummaryResult>(
        `/admin/usage/summary?minDate=${dateRange[0]?.toISOString()}&maxDate=${dateRange[1]?.toISOString()}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      onError: (err) => {
        if (isAxiosErrorResponse(err, [401, 403])) {
          navigate('/');
        }
      },
    },
  );

  useEffect(() => {
    refetchOrganizationUsage();
  }, [refetchOrganizationUsage, dateRange]);

  const onRenderBoolean = (value: boolean) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        backgroundColor: value
          ? MEETINGFLOW_COLORS.teal
          : MEETINGFLOW_COLORS.red,
      }}
    >
      {value ? (
        <FontIcon iconName="CheckMark" style={{ color: 'white' }} />
      ) : (
        <FontIcon iconName="Cancel" style={{ color: 'white' }} />
      )}
    </div>
  );

  const usageColumns: IColumn[] = useMemo(
    () =>
      [
        {
          key: 'id',
          name: 'Id',
          fieldName: 'OrganizationId',
          headerClassName: 'numeric',
          className: 'numeric',
        },
        {
          key: 'slug',
          name: 'Slug',
          fieldName: 'OrganizationSlug',
        },
        {
          key: 'name',
          name: 'Name',
          fieldName: 'OrganizationName',
        },
        {
          key: 'creatorDomain',
          name: 'Creator Domain',
          fieldName: 'CreatorDomain',
        },
        {
          key: 'domainCount',
          name: 'Domain Count',
          fieldName: 'DomainCount',
          headerClassName: 'numeric',
          className: 'numeric',
        },
        {
          key: 'userLastSeen',
          name: 'User Last Seen',
          fieldName: 'UserLastSeen',
        },
        {
          key: 'adminCount',
          name: 'Admin Count',
          fieldName: 'AdminCount',
          headerClassName: 'numeric',
          className: 'numeric',
        },
        {
          key: 'creatorCount',
          name: 'Creator Count',
          fieldName: 'CreatorCount',
          headerClassName: 'numeric',
          className: 'numeric',
        },
        {
          key: 'collaboratorCount',
          name: 'Collaborator Count',
          fieldName: 'CollaboratorCount',
          headerClassName: 'numeric',
          className: 'numeric',
        },
        {
          key: 'guestCount',
          name: 'Guest Count',
          fieldName: 'GuestCount',
          headerClassName: 'numeric',
          className: 'numeric',
        },
        {
          key: 'meetingflowCount',
          name: 'Meetingflow Count',
          fieldName: 'MeetingflowCount',
          headerClassName: 'numeric',
          className: 'numeric',
        },
        {
          key: 'internalMeetingflowCount',
          name: 'Internal Meetingflow Count',
          fieldName: 'InternalMeetingflowCount',
          headerClassName: 'numeric',
          className: 'numeric',
        },
        {
          key: 'externalMeetingflowCount',
          name: 'External Meetingflow Count',
          fieldName: 'ExternalMeetingflowCount',
          headerClassName: 'numeric',
          className: 'numeric',
        },
        {
          key: 'callRecordingCount',
          name: 'Call Recording Count',
          fieldName: 'CallRecordingCount',
          headerClassName: 'numeric',
          className: 'numeric',
        },
        {
          key: 'internalCallRecordingCount',
          name: 'Internal Call Recording Count',
          fieldName: 'InternalCallRecordingCount',
          headerClassName: 'numeric',
          className: 'numeric',
        },
        {
          key: 'externalCallRecordingCount',
          name: 'External Call Recording Count',
          fieldName: 'ExternalCallRecordingCount',
          headerClassName: 'numeric',
          className: 'numeric',
        },
        {
          key: 'callRecordingTimeHours',
          name: 'Call Recording Time Hours',
          fieldName: 'CallRecordingTimeHours',
          headerClassName: 'numeric',
          className: 'numeric',
        },
      ] as IColumn[],
    [],
  );

  const usageReportWrapper = mergeStyles({
    height: 'calc(100% - 2rem)',
    width: 'auto',
    overflow: 'auto',
    margin: '1rem 0',
    padding: '1rem',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,

    '.controls': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      padding: '0',
      marginBottom: '.25rem',

      '.options': {
        display: 'flex',
        columnGap: '.5rem',
        alignItems: 'end',
        justifyItems: 'end',
        alignContent: 'end',

        '.timerange-date-picker': {
          boxSizing: 'border-box',
          // width: '360px',
          display: 'flex',
          columnGap: '.25rem',
          '.bp5-input-group': {
            // flexBasis: '50%',
          },
        },
      },

      '.export': {
        display: 'flex',

        '.export-link': {},
      },
    },

    '.left-align': {
      textAlign: 'left',
    },

    '.right-align': {
      textAlign: 'right',
    },

    '.numeric': {
      textAlign: 'right',
      '.ms-DetailsHeader-cellName': {
        width: '100%',
      },
    },

    '.ms-DetailsHeader': {
      height: '48px',
      backgroundColor: 'transparent',
    },

    '.ms-DetailsHeader-cell ': {
      height: 'auto',
      backgroundColor: 'transparent',
    },

    '.ms-DetailsHeader-cell * ': {
      fontSize: '.75rem',
      lineHeight: '1rem',
      whiteSpace: 'wrap',
    },

    '.ms-DetailsRow-cell.numeric': {
      fontSize: '1.25rem',
    },

    '.ms-DetailsRow': {
      borderBottom: `1px solid ${
        isDark ? NeutralColors.gray190 : MEETINGFLOW_COLORS.purpleGreyLight
      } `,
      backgroundColor: 'transparent',
    },

    strong: {
      fontWeight: FontWeights.semibold,
    },
  });

  return (
    <div className={usageReportWrapper}>
      <div>
        <div className="controls">
          <div className="options">
            <DateRangeInput2
              className={classNames(
                'timerange-date-picker',
                'blueprint-component',
                isDark ? 'bp5-dark' : undefined,
              )}
              value={dateRange}
              shortcuts={DatePickerShortcuts}
              onChange={setDateRange}
              startInputProps={{ name: 'StartInput' }}
              endInputProps={{ name: 'EndInput' }}
              parseDate={(input) =>
                DateTime.fromMillis(Date.parse(input)).toJSDate()
              }
              formatDate={(date) =>
                DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT)
              }
            />
          </div>
          <div className="export">
            <AsyncLink
              className="export-link"
              onClick={async () => {
                if (!organizationUsage?.data?.usage?.length) {
                  return;
                }

                const json2csv = await import('@json2csv/plainjs');
                const fileSaver = await import('file-saver');

                const fields = usageColumns.map((column) => ({
                  label: column.name,
                  value: column.fieldName || column.key,
                }));

                const parser = new json2csv.Parser({
                  fields,
                  transforms: [escapeCsvFormulas],
                });

                const csvContent = parser.parse(organizationUsage.data.usage);
                const csvBlob = new Blob([csvContent], { type: 'text/csv' });

                fileSaver.saveAs(
                  csvBlob,
                  `usage_summary_${DateTime.now().toISODate({
                    format: 'basic',
                  })}.csv`,
                );
              }}
            >
              Export to CSV
            </AsyncLink>
          </div>
        </div>
      </div>

      <ShimmeredDetailsList
        key={'organization-usage-list'}
        enableShimmer={organizationUsageLoading}
        shimmerLines={25}
        items={organizationUsage?.data?.usage || EMPTY_ARRAY}
        selectionMode={SelectionMode.none}
        columns={usageColumns}
        isHeaderVisible={true}
        compact
        className="long-headers"
      />
    </div>
  );
};
