import { useAuth0 } from '@auth0/auth0-react';
import {
  DatePicker,
  FontSizes,
  FontWeights,
  Stack,
  Text,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Meetingflow, Task } from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Y } from '@syncedstore/core';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import TimePicker, { TimePickerValue } from 'react-time-picker';
import * as yup from 'yup';
import { DEFAULT_STACK_TOKENS } from '../../../Helpers/Layout';
import { hasContent } from '../../../Helpers/yjsHelpers';
import useSummarizeMeetingflowDialog from '../../../Hooks/Modals/useSummarizeMeetingflowDialog';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useUserProfile } from '../../../Hooks/useProfile';
import { ApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import MeetingPlanNotesSummaryForm from '../../Common/NotesSummaryForm';
import { AsyncDefaultButton, AsyncPrimaryButton } from '../../HOC/AsyncButton';

interface MeetingPlanEditDetailsFormProps {
  meetingPlan: Meetingflow;
  organizationSlug: string;
  notesYArray: Y.XmlText;
  tasks: Task[];
  internalDomains: string[];
  fields?: ('title' | 'date' | 'summary')[];
  submitButtonLabel?: string;
  insertBeforeButtons?: React.ReactNode;
  hideLabels?: boolean;
  initialSummary?: string;
  onSuccess?: (formValues: MeetingPlanEditDetailsFormData) => void;
  onCancel?: () => void;
}

export type MeetingPlanEditDetailsFormData = {
  title: string;
  date: Date;
  startTime: TimePickerValue;
  endTime: TimePickerValue;
  summary: string;
};

const mergeDateAndTime = function (date: Date, time: TimePickerValue) {
  let timeString = time;
  if (time instanceof Date) {
    timeString = time.getHours() + ':' + time.getMinutes();
  }
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  // NOTE: For reasons that are unexplanainable to me, Safari will not parse a date string unless it's propoerly padded with leading zeros. So, we do that here. - jcroft
  const monthString = '' + month;
  const monthFormatted =
    monthString.length === 1 ? '0' + monthString : monthString;
  const day = date.getDate();
  const dayString = '' + day;
  const dayFormatted = dayString.length === 1 ? '0' + dayString : dayString;
  const dateString = '' + year + '-' + monthFormatted + '-' + dayFormatted;

  const combined = new Date(dateString + ' ' + timeString);
  return combined;
};

export const MeetingPlanEditDetailsForm = ({
  meetingPlan,
  organizationSlug,
  onSuccess,
  onCancel,
  notesYArray,
  tasks,
  internalDomains,
  fields = ['title', 'date', 'summary'],
  submitButtonLabel = 'Update',
  insertBeforeButtons,
  hideLabels = false,
  initialSummary,
}: MeetingPlanEditDetailsFormProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const appInsights = useAppInsightsContext();
  const { isDark } = useLightOrDarkMode();
  const { user } = useUserProfile();

  const {
    createDeferred: createSummarizeMeetingflowDeferred,
    dialog: summarizeMeetingflowDialog,
  } = useSummarizeMeetingflowDialog({
    organizationSlug,
    meetingflowId: meetingPlan.id,
  });

  const formSchema = useMemo(
    () =>
      yup
        .object({
          title: yup.string().min(1, 'The title cannot be empty').required(),
          date: yup.date().required(),
          startTime: yup.string().required(),
          endTime: yup.string().required(),
          summary: yup.string(),
        })
        .required(),
    [],
  );

  const formDefaultValues = {
    title: meetingPlan.title,
    date: new Date(new Date(meetingPlan.startTime).toLocaleDateString()),
    startTime: new Date(meetingPlan.startTime),
    endTime: new Date(meetingPlan.endTime),
    summary: initialSummary || (meetingPlan.textSummary as string) || '',
  };

  const { getValues, handleSubmit, control, formState, reset, setValue } =
    useForm<MeetingPlanEditDetailsFormData>({
      defaultValues: formDefaultValues,
      resolver: yupResolver(formSchema, { abortEarly: false }),
      reValidateMode: 'onChange',
      mode: 'onChange',
    });

  const onSubmit = handleSubmit(async (data) => {
    if (formState.isValid) {
      const token = await getAccessTokenSilently();
      const result = await ApiClient.patch(
        `/organization/${organizationSlug}/plan/${meetingPlan.id}`,
        {
          organizationSlug,
          title: fields.includes('date') ? getValues('title') : undefined,
          startTime: fields.includes('date')
            ? mergeDateAndTime(
                getValues('date'),
                getValues('startTime') as string,
              )
            : undefined,
          endTime: fields.includes('date')
            ? mergeDateAndTime(
                getValues('date'),
                getValues('endTime') as string,
              )
            : undefined,
          textSummary: fields.includes('summary')
            ? (getValues('summary') as string) || ''
            : undefined,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (result.status === 200) {
        toast.success(`Meetingflow details updated.`);
        appInsights.trackEvent({
          name: 'UPDATE_MEETINGPLAN_DETAILS',
          properties: {
            organizationSlug,
            title: getValues('title'),
            startTime: getValues('startTime'),
            endTime: getValues('endTime'),
            summary: getValues('summary'),
          },
        });
        onSuccess && onSuccess(getValues());
        reset();
      } else {
        toast.error('There was a problem updating the Meetingflow details.');
      }
    } else {
      toast.error(
        `Meetingflow details could not be updated because the form values are invalid. Please check them and try again.`,
      );
    }
  });

  useEffect(() => {
    /// @ts-ignore
    document.querySelector('#edit-title-field')?.select();
  }, []);

  useEffect(() => {
    if (initialSummary) {
      setValue('summary', initialSummary);
    } else if (meetingPlan?.textSummary) {
      setValue('summary', meetingPlan?.textSummary);
    }
  }, [initialSummary, meetingPlan.textSummary, setValue]);

  const reactTimePickerWrapperClass = mergeStyles({
    '.react-time-picker-label': {
      display: 'block',
      padding: '5px 0',
      fontWeight: FontWeights.semibold,
    },
    '.react-time-picker__inputGroup': {},
    '.react-time-picker__wrapper': {
      height: '32px',
      border: '1px solid rgb(200, 198, 196)',
      transition: '.3s ease-in-out all',
      borderRadius: '2px',
      ':hover': {
        border: `1px solid ${MEETINGFLOW_COLORS.purplePrimary}`,
      },
    },
    'input, select': {
      color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,
      border: `1px solid transparent`,
      position: 'relative',
      top: '-1px',
    },
    'input:focus, select:focus, input:focus-visible, select::focus-visible ': {
      outlineColor: `${MEETINGFLOW_COLORS.purplePrimary} `,
      outline: 'none !important',
      border: `1px solid ${MEETINGFLOW_COLORS.purplePrimary}`,
      borderRadius: '2px',
    },
    '.react-time-picker__inputGroup__divider': {
      position: 'relative',
      top: '-2px',
    },
    '.react-time-picker__inputGroup__leadingZero': {
      position: 'relative',
      top: '-1px',
    },
  });

  return (
    <>
      <Stack tokens={DEFAULT_STACK_TOKENS}>
        <Stack tokens={DEFAULT_STACK_TOKENS} style={{ marginBottom: '1rem' }}>
          {fields.includes('title') ? (
            <Stack.Item grow={1}>
              <Controller
                name="title"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    errorMessage={formState.errors.title?.message}
                    autoFocus
                    label={hideLabels ? undefined : 'Title'}
                    id="edit-title-field"
                    value={value}
                    onBlur={onBlur}
                    onChange={(_e, newValue) => onChange(newValue || '')}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onSubmit();
                      }
                    }}
                  />
                )}
              />
            </Stack.Item>
          ) : undefined}

          {fields.includes('date') ? (
            <Stack.Item>
              <Stack horizontal tokens={DEFAULT_STACK_TOKENS}>
                <Stack.Item grow={1}>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        placeholder="Select date..."
                        ariaLabel="Select date"
                        label={hideLabels ? undefined : 'Date'}
                        value={formDefaultValues.date}
                        onSelectDate={(date: Date | null | undefined) => {
                          if (!!date) {
                            setValue('date', date);
                          }
                        }}
                      />
                    )}
                  />
                </Stack.Item>
                <>
                  <Stack.Item
                    grow={1}
                    styles={{ root: { maxWidth: '6.65rem' } }}
                  >
                    <Controller
                      name="startTime"
                      control={control}
                      render={({ field }) => {
                        return (
                          <div className={reactTimePickerWrapperClass}>
                            <Text className={'react-time-picker-label'}>
                              Start Time
                            </Text>
                            <TimePicker
                              disableClock={true}
                              value={formDefaultValues.startTime}
                              clearIcon={null}
                              onChange={(value) => {
                                const merged = mergeDateAndTime(
                                  getValues('date'),
                                  value as string,
                                );
                                setValue(
                                  'startTime',
                                  merged as TimePickerValue,
                                );
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                  </Stack.Item>
                  <Stack.Item
                    grow={1}
                    styles={{ root: { maxWidth: '6.65rem' } }}
                  >
                    <Controller
                      name="endTime"
                      control={control}
                      render={({ field }) => {
                        return (
                          <div className={reactTimePickerWrapperClass}>
                            <Text className={'react-time-picker-label'}>
                              End Time
                            </Text>
                            <TimePicker
                              disableClock={true}
                              value={formDefaultValues.endTime}
                              clearIcon={null}
                              onChange={(value) => {
                                const merged = mergeDateAndTime(
                                  getValues('date'),
                                  value as string,
                                );
                                setValue('endTime', merged as TimePickerValue);
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                  </Stack.Item>
                </>
              </Stack>
            </Stack.Item>
          ) : null}

          {fields.includes('summary') ? (
            <Stack.Item>
              <MeetingPlanNotesSummaryForm
                summaryType="MEETING_SUMMARY"
                summarizeDisabled={
                  !hasContent(notesYArray) &&
                  !meetingPlan?.callRecording?.transcript?.length
                }
                meetingplan={meetingPlan}
                organizationSlug={organizationSlug}
                displaySubject={false}
                onSummaryChange={(value) => setValue('summary', value)}
                onSummaryGenerated={(value) => setValue('summary', value)}
                surfaceName={'MEETINGPLAN_SUMMARY'}
                bodyLabel={'Summary'}
                initialSummaryValue={meetingPlan.textSummary || ''}
                hideLabels={hideLabels}
              />
            </Stack.Item>
          ) : null}
        </Stack>
        {insertBeforeButtons ? insertBeforeButtons : null}
        <Stack horizontal horizontalAlign={'end'} tokens={DEFAULT_STACK_TOKENS}>
          {onCancel ? (
            <Stack.Item>
              <AsyncDefaultButton
                text="Cancel"
                // @ts-ignore
                onClick={onCancel}
                styles={{
                  root: {
                    height: '24px !important',
                    border: 'none !important',
                    position: 'relative',
                    top: insertBeforeButtons ? undefined : '-.75rem',
                  },
                  label: {
                    fontSize: FontSizes.small,
                  },
                }}
              />
            </Stack.Item>
          ) : null}
          <Stack.Item>
            <AsyncPrimaryButton
              disabled={!formState.isValid}
              text={submitButtonLabel}
              onClick={onSubmit}
              type="submit"
              styles={{
                root: {
                  height: '24px !important',
                  backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
                  border: 'none !important',
                  position: 'relative',
                  top: insertBeforeButtons ? undefined : '-.75rem',
                },
                label: {
                  fontSize: FontSizes.small,
                },
              }}
            />
          </Stack.Item>
        </Stack>
      </Stack>
      {summarizeMeetingflowDialog}
    </>
  );
};
