import {
  ConstrainMode,
  DetailsRow,
  FontSizes,
  IDetailsList,
  IDetailsListProps,
  IDetailsRowProps,
  IDetailsRowStyles,
  IRefObject,
  IShimmeredDetailsListProps,
  NeutralColors,
  ShimmeredDetailsList,
  Text,
  useTheme,
} from '@fluentui/react';
import React, { createRef, useCallback, useMemo } from 'react';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { BulletedListTextIcon } from '@fluentui/react-icons-mdl2';

interface StyledDetailsListProps {
  activeIndex?: number;
  maxHeight?: string | number;
  maxRowHeight?: string | number;
  minWidth?: string | number;
  noDataMessage?: string;
  onItemDrop?: (dropSourceId: string, dropTargetId: string) => void;
}

const ROW_HEIGHT = '43px';

export const SettingsStyledDetailsList = React.memo(
  ({
    activeIndex = 0,
    maxHeight = 'auto',
    maxRowHeight = ROW_HEIGHT,
    minWidth,
    noDataMessage,
    onItemDrop,
    ...detailsListProps
  }: StyledDetailsListProps & IShimmeredDetailsListProps) => {
    const theme = useTheme();
    const { isDark } = useLightOrDarkMode();

    const tableRef: IRefObject<IDetailsList> | undefined = createRef();

    const tableStyles = useMemo(
      () => ({
        headerWrapper: {
          flex: '0 0 auto',
        },
        root: {
          width: 'auto !important',
          maxHeight: maxHeight,
          minWidth: minWidth,
          margin: '0 auto',
          overflowX: 'auto',

          '.ms-List-cell': {
            minHeight: '0',
            maxHeight: maxRowHeight,
          },

          '.ms-DetailsList-headerWrapper > .ms-DetailsHeader': {
            paddingTop: '0 !important',
          },

          '.ms-GroupHeader': {
            maxHeight: '32px',
            backgroundColor: isDark
              ? NeutralColors.gray200
              : MEETINGFLOW_COLORS.purpleUltraLight,

            '> div': {
              maxHeight: '32px',
            },

            '.ms-GroupHeader-expand': {
              maxHeight: '32px',
              width: '24px',
            },

            '.ms-GroupHeader-title': {
              fontSize: '12px',
            },
          },

          '.ms-DetailsHeader': {
            backgroundColor: isDark ? NeutralColors.gray210 : undefined,
            borderColor: isDark
              ? NeutralColors.gray170
              : MEETINGFLOW_COLORS.purpleLighter,
            height: '1.5rem',
          },

          '.ms-DetailsHeader-cell': {
            padding: '0',
            height: '1.5rem',
            backgroundColor: isDark
              ? `${NeutralColors.gray210} !important`
              : 'transparent !important',

            '.ms-DetailsHeader-cellTitle': {
              padding: '0 .25rem',
              fontSize: FontSizes.small,
              height: '1.5rem',
              backgroundColor: isDark
                ? `${NeutralColors.gray210} !important`
                : 'transparent !important',

              span: {
                fontSize: FontSizes.small,
                height: '1.5rem',
                lineHeight: '1.5rem',
              },
            },
          },

          '.ms-DetailsHeader-cell[aria-expanded]': { width: '24px' },

          '.ms-DetailsRow': {
            borderColor: isDark
              ? NeutralColors.gray180
              : MEETINGFLOW_COLORS.white,
            minHeight: '0',
            maxHeight: maxRowHeight,
          },

          '.draggable-row': {
            position: 'relative',
            '.draggable-handle': {
              zIndex: 100,
              position: 'absolute',
              left: '0.5rem',
              top: '50%',
              transform: 'translateY(-50%)',
              fontSize: '1.5em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'grab',
            },

            /* Add a hover effect for better UX */
            '.draggable-handle:hover': {
              /* Your hover effect styles, e.g., change color or background */
            },
          },

          '.ms-DetailsRow-cell': {
            background: isDark
              ? NeutralColors.gray220
              : MEETINGFLOW_COLORS.purpleUltraSuperLight,
            padding: 'calc(.25rem + 2px) .25rem .25rem .25rem .25rem',

            '> span': {
              lineHeight: '2rem',
            },

            '.ms-Checkbox': {
              marginTop: '6px',
            },

            '.ms-Checkbox.is-disabled input': {
              opacity: 0,
            },
          },

          '.ms-GroupSpacer': {
            backgroundColor: isDark
              ? NeutralColors.gray220
              : MEETINGFLOW_COLORS.purpleUltraSuperLight,
          },

          '.center-align span.ms-DetailsHeader-cellName': {
            display: 'block',
            width: '100%',
            textAlign: 'center !important',
          },

          '.center-align.ms-DetailsRow-cell ': {
            textAlign: 'center !important',
          },

          '.center-align.ms-DetailsRow-cell > span': {
            display: 'block',
            width: '100%',
            textAlign: 'center !important',
          },
        },
      }),
      [maxHeight, minWidth, maxRowHeight, isDark],
    ) as IDetailsListProps['styles'];

    // @ts-ignore
    const onRenderRow: IDetailsListProps['onRenderRow'] = useCallback(
      (detailsRowProps: IDetailsRowProps) => {
        const { supportDrag, supportDrop } = detailsRowProps.item;

        const rowStyles: Partial<IDetailsRowStyles> = {};
        if (detailsRowProps) {
          rowStyles.cell = {
            backgroundColor: isDark ? 'transparent' : NeutralColors.white,
          };

          rowStyles.root = {
            borderBottomColor: isDark
              ? NeutralColors.gray180
              : NeutralColors.gray20,
          };

          const detailsRow = (
            <DetailsRow
              key={
                detailsListProps.getKey?.(
                  detailsRowProps.item,
                  detailsRowProps.itemIndex,
                ) || detailsRowProps.itemIndex
              }
              {...detailsRowProps}
              styles={rowStyles}
            />
          );

          if (
            !!onItemDrop &&
            !!detailsListProps.getKey &&
            detailsListProps.getKey(
              detailsRowProps.item,
              detailsRowProps.itemIndex,
            )
          ) {
            const itemKey = detailsListProps.getKey(
              detailsRowProps.item,
              detailsRowProps.itemIndex,
            );
            return (
              <div
                className="draggable-row"
                key={itemKey}
                draggable={supportDrag}
                onDragStart={
                  supportDrag
                    ? (event: React.DragEvent<HTMLDivElement>) => {
                        event.dataTransfer.setData('text/plain', itemKey);
                      }
                    : undefined
                }
                onDragOver={
                  itemKey && supportDrop
                    ? (event: React.DragEvent<HTMLDivElement>) => {
                        event.preventDefault(); // Necessary to allow dropping
                      }
                    : undefined
                }
                onDrop={
                  itemKey && supportDrop
                    ? (event: React.DragEvent<HTMLDivElement>) => {
                        event.preventDefault();
                        const draggedKey =
                          event.dataTransfer.getData('text/plain');
                        if (onItemDrop && draggedKey) {
                          onItemDrop(draggedKey, itemKey);
                        }
                      }
                    : undefined
                }
              >
                {supportDrag && (
                  <div className="draggable-handle" title="Drag to reorder">
                    <BulletedListTextIcon />
                  </div>
                )}
                {detailsRow}
              </div>
            );
          }

          return detailsRow;
        }

        return null;
      },
      [detailsListProps, isDark, onItemDrop],
    );

    const { enableShimmer } = detailsListProps;

    return (
      <>
        <ShimmeredDetailsList
          detailsListStyles={tableStyles}
          onRenderRow={onRenderRow}
          componentRef={tableRef}
          constrainMode={ConstrainMode.unconstrained}
          shimmerLines={25}
          {...detailsListProps}
        />
        {!enableShimmer && detailsListProps.items.length === 0 ? (
          <Text
            variant="large"
            block
            style={{ margin: '1rem 0', textAlign: 'center' }}
          >
            {noDataMessage || 'There are no results.'}
          </Text>
        ) : null}
      </>
    );
  },
);
