import { createTheme, NeutralColors } from '@fluentui/react/lib/Theme';

export const MEETINGFLOW_COLORS = {
  black: '#333333',
  purplePrimary: '#6045e2',
  purpleSecondary: '#725ae6',
  purpleTertiary: '#9c8cee',
  purpleDarkest: '#36277f',
  purpleDarker: '#4935ac',
  purpleDark: '#573fcc',
  purpleLight: '#ccc4f7',
  purpleLighter: '#e3dffb',
  purpleLightest: '#f8f7fe',
  purpleUltraLight: '#f4f3fe',
  purpleUltraSuperLight: '#fbfbff',
  purpleUltraSuperLightish: '#f8f7ff',
  purpleGreyer: '#dfdbf5',
  purpleGrey: '#e5e1f8',
  purpleGreyLight: '#f2f2ff',
  purpleGreyMediumLight: '#e8e8fa',
  purpleGreyMedium: '#c7c1e1',
  purpleGreyMediumAlt: '#d3cfe9',
  purpleGreyMediumAlt2: '#a69cd5',
  purpleMedium: '#9489cd',
  purpleMediumDark: '#867bc0',
  purpleMediumDarker: '#7268a6',
  purpleMediumDarkest: '#211e2d',
  magentaSuperLight: '#ffd4ef',
  magentaHighlightDarkMode: '#b294a7',
  magenta: '#e3008c',
  magentaDark: '#880054',
  magentaMedium: '#b00870',
  magentaLight: '#e274b8',
  white: '#ffffff',
  red: '#d21c20',
  teal: '#44a890',
  tealLight: '#91ccbe',
  tealVeryLight: '#d7ebe9',
  tealSuperLight: '#c1fff9',
  tealMediumLight: '#45927f',
  tealMedium: '#188067',
  tealDark: '#076b53',
  tealDarker: '#03392c',
  orangeSuperLight: '#ffe0c5',
  orangeHighlightDarkMode: '#d8c5b5',
  orangeLight: '#fdc08e',
  orangeHighlight: 'rgba(227,109,11,0.75)',
  orange: '#e36d0b',
  orangeDark: '#e3440b',
  orangeDarker: '#c03908',
  backgroundLightGrey: '#fdfdfd',
  greenHighlight: 'rgba(68,168,44,0.75)',
  darkModeMeetingflowBackgroundGrey: '#0b0b0a',
  lightModeMeetingflowSidebarMenuBackgroundGrey: '#f2f0ff',
};

export const DEALROOMS_COLORS = {
  lightGray: '#D7D7D8',
  lighterGray: '#D1D1D1',
  darkerGray: '#242424',
  neutralGray: '#FCFCFD',
  neutralDark: '#AFAEB1',
  neutralDarker: '#424242',
  neutralLighter: '#F4F4F6',
  neutralLight: '#E8E8EC',
  neutralSecondary: '#605E5C',
  themePrimary: '#2B2D39',
  themeSecondary: '#6F7288',
  white: '#ffffff',
  black: '#000000',
  inputLightGray: '#F5F5F5',
  darkerRed: '#B10E1C',
  primaryButton: '#796AD1',
  sidebarTextPrimary: '#F6F6F6',
  sidebarTextSecondary: '#95969C',
  sidebarHoverTextPrimary: '#40424F',
  financialPrimaryGray: '#DFE0E7',
  financialSecondaryGray: '#EBEBF0',
  financialLighterGray: '#F6F6F8',
  financialLightGray: '#E3E3E8',
  buttonBorder: '#D9D9D9',
  notificationBubble: '#920061',
  userSurveyPrimary: '#72003F',
  userSurveyInputPlaceholder: '#707070',
  artifactsSidePanelBorder: '#D3D0D9',
  artic: '#aee3e4',
  uploadAttachmentHighLightedText: '#C60047',
  neutralLighterAlt: '#FAFBFB',
  toolbarLight: '#F6F7F9',
  peach: '#FBD6A9',
  orchid: '#621087',
  frost: '#B4C1FA',
  peacock: '#006A69',
  oink: '#F7C5DA',
  woodsmoke: '#2B2D39',
  cloudburst: '#6F7288',
  bone: '#E8E0E0',
  crimson: '#C60047',
  plum100: '#72003F',
  plum75: '#920061',
  plum50: '#BB5B8E',
  plum25: '#E1B5C7',
  meetingflowSidePanelVeryLightGray: '#FCFCFD',
};

export const lightTheme = createTheme({
  palette: {
    themePrimary: '#6045e2',
    themeLighterAlt: '#f8f7fe',
    themeLighter: '#e3dffb',
    themeLight: '#ccc4f7',
    themeTertiary: '#9c8cee',
    themeSecondary: '#725ae6',
    themeDarkAlt: '#573fcc',
    themeDark: '#4935ac',
    themeDarker: '#36277f',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
  semanticColors: {
    inputBorder: NeutralColors.gray60,
    inputBorderHovered: '#9c8cee',
    inputFocusBorderAlt: '#6045e2',
    primaryButtonBackground: MEETINGFLOW_COLORS.purpleSecondary,
    inputPlaceholderText: NeutralColors.gray80,
    // buttonTextDisabled: NeutralColors.gray100,
    // primaryButtonText: NeutralColors.white,
    // primaryButtonTextDisabled: NeutralColors.gray100,
    // link: '#9c8cee',
    // linkHovered: '#725ae6',
  },
});

export const darkTheme = createTheme({
  palette: {
    themePrimary: '#6045e2',
    themeLighterAlt: '#f8f7fe',
    themeLighter: '#e3dffb',
    themeLight: '#ccc4f7',
    themeTertiary: '#9c8cee',
    themeSecondary: '#725ae6',
    themeDarkAlt: '#573fcc',
    themeDark: '#4935ac',
    themeDarker: '#36277f',
    neutralLighterAlt: '#323130',
    neutralLighter: '#31302f',
    neutralLight: '#2f2e2d',
    neutralQuaternaryAlt: '#2c2b2a',
    neutralQuaternary: '#2a2928',
    neutralTertiaryAlt: '#282726',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#323130',
  },
  semanticColors: {
    inputBorder: NeutralColors.gray140,
    inputBorderHovered: '#9c8cee',
    inputFocusBorderAlt: '#6045e2',
    buttonTextDisabled: NeutralColors.gray100,
    primaryButtonText: NeutralColors.white,
    primaryButtonTextDisabled: NeutralColors.gray100,
    link: '#9c8cee',
    linkHovered: '#725ae6',
    inputPlaceholderText: NeutralColors.gray120,
  },
});

export const demoTheme = createTheme({
  palette: {
    themePrimary: '#2B2D39',
    themeLighterAlt: '#f8f7fe',
    themeLighter: '#e3dffb',
    themeLight: '#ccc4f7',
    themeTertiary: '#9c8cee',
    themeSecondary: '#6F7288',
    themeDarkAlt: '#573fcc',
    themeDark: '#4935ac',
    themeDarker: '#36277f',
    neutralLighterAlt: '#FAFBFB',
    neutralLighter: '#F4F4F6',
    neutralLight: '#E8E8EC',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#AFAEB1',
    black: '#000000',
    white: '#ffffff',
  },
  semanticColors: {
    inputBorder: NeutralColors.gray60,
    inputBorderHovered: '#9c8cee',
    inputFocusBorderAlt: '#6045e2',
    primaryButtonBackground: '#2B2D39',
    primaryButtonBorder: '#2B2D39',
    primaryButtonText: 'white',
    inputPlaceholderText: NeutralColors.gray80,
    // buttonTextDisabled: NeutralColors.gray100,
    // primaryButtonText: NeutralColors.white,
    // primaryButtonTextDisabled: NeutralColors.gray100,
    // link: '#9c8cee',
    // linkHovered: '#725ae6',
  },
});
