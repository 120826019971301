import { uuidv4 } from 'lib0/random';
import { useMemo } from 'react';
import { ExportOrganizationDialog } from '../../Components/Organization/ExportOrganizationDialog';
import { useDeferredPromise } from '../useDeferredPromise';
import { useOrganization } from '../useOrganization';

export type ExportOrganizationOptions = {
  organizationSlug: string;
};

export const useExportOrganizationDialog = ({
  organizationSlug,
}: ExportOrganizationOptions) => {
  const { createDeferred, resolve, reject, deferred } = useDeferredPromise();

  const { organization } = useOrganization(organizationSlug);

  const dialog = useMemo(() => {
    if (!deferred || !deferred.isPending) {
      return null;
    }

    return (
      <ExportOrganizationDialog
        key={uuidv4()}
        organizationSlug={organizationSlug}
        organizationName={organization?.name || organizationSlug}
        onDismiss={() => resolve({})}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deferred,
    deferred?.isPending,
    organization?.name,
    organizationSlug,
    resolve,
  ]);

  return {
    createDeferred,
    resolve,
    reject,
    deferred,
    dialog,
  };
};

export default useExportOrganizationDialog;
